/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";
import { produceColumnChartForValue, produceColumnChartForPercent } from "./ChartBasic/ColumnChart";

exportingModule(Highcharts);
const Pdtwhm = (props) => {
  const { s_PNChartData, s_chartData } = props;

  //X軸標題
  const xAxisDataForAmountA = ["實際工時(分)", "實際機時(分)"];
  const xAxisDataForAmountB = ["良品數量", "報廢數量"];
  const xAxisDataForAmountC = ["UPH"];
  const xAxisDataForAmountD = ["人機比"];
  const xAxisDataForPercentA = ["良率"];

  //  各圖外觀設定
  const chartAppearance = {
    value: { width: null, height: "50%" },
    percent: { width: null, height: "50%" },
  };

  //生成數值圖表 data
  function generateChartData(charData, dataProps) {
    return charData.map((data) => ({
      name: data?.mtypes ? `${data.mtypes}機種` : data.PN,
      data: dataProps.map((prop) => parseFloat(data[prop], 10)),
      // color: data.PN ? "" : "#88CBFF",
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.y, 0, ".", ",");
        },
      },
    }));
  }

  //帶入各圖所需欄位
  function generateChartDataA(charData) {
    const dataProps = ["realwhC", "realmhC"];
    return generateChartData(charData, dataProps);
  }
  //帶入各圖所需欄位
  function generateChartDataB(charData) {
    const dataProps = ["okC", "ngC"];
    return generateChartData(charData, dataProps);
  }
  //帶入各圖所需欄位
  function generateChartDataC(charData) {
    const dataProps = ["UPH"];
    return generateChartData(charData, dataProps);
  }
  //帶入各圖所需欄位
  function generateChartDataD(charData) {
    const dataProps = ["MMR"];
    return generateChartData(charData, dataProps);
  }

  //生成百分比圖表 data
  function generatePercentChartData4(charData) {
    return charData.map((data) => ({
      name: data?.mtypes ? `${data.mtypes}機種` : data.PN,
      data: [data.okRate],
      tooltip: {
        valueSuffix: "%",
      },
      color: data.PN ? "" : "#88CBFF",
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.y) + "%";
        },
      },
    }));
  }

  //數值圖
  const optionsA = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountA,
    generateChartDataA(s_chartData),
    "工時"
  );
  const optionsB = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountB,
    generateChartDataB(s_chartData),
    "數量"
  );
  const optionsC = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountC,
    generateChartDataC(s_chartData),
    "UPH"
  );
  const optionsD = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountD,
    generateChartDataD(s_chartData),
    "人機比"
  );
  const optionsPNA = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountA,
    generateChartDataA(s_PNChartData),
    "工時"
  );
  const optionsPNB = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountB,
    generateChartDataB(s_PNChartData),
    "數量"
  );
  const optionsPNC = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountC,
    generateChartDataC(s_PNChartData),
    "UPH"
  );
  const optionsPND = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmountD,
    generateChartDataD(s_PNChartData),
    "人機比"
  );

  //百分比圖
  const optionsPercentA = produceColumnChartForPercent(
    chartAppearance.percent.width,
    chartAppearance.percent.height,
    xAxisDataForPercentA,
    generatePercentChartData4(s_chartData),
    "良率"
  );
  const optionsPercentPNA = produceColumnChartForPercent(
    chartAppearance.percent.width,
    chartAppearance.percent.height,
    xAxisDataForPercentA,
    generatePercentChartData4(s_PNChartData),
    "良率"
  );

  return (
    <>
      {
        // s_chartData.length!==0 &&
        <div className="flex flex-wrap">
          <>
            <div className="w-[20%]">
              <HighchartsReact highcharts={Highcharts} options={optionsA} />
            </div>
            <div className="w-[20%]">
              <HighchartsReact highcharts={Highcharts} options={optionsB} />{" "}
            </div>
            <div className="w-[20%]">
              <HighchartsReact highcharts={Highcharts} options={optionsC} />{" "}
            </div>
            <div className="w-[20%]">
              {" "}
              <HighchartsReact highcharts={Highcharts} options={optionsD} />{" "}
            </div>
            <div className="w-[20%]">
              {" "}
              <HighchartsReact highcharts={Highcharts} options={optionsPercentA} />{" "}
            </div>
          </>
        </div>
      }

      {s_PNChartData.length !== 0 && s_PNChartData[0].PN !== "" && (
        <div className="flex flex-wrap  mt-3">
          <>
            <div className="w-[20%]">
              <HighchartsReact highcharts={Highcharts} options={optionsPNA} />
            </div>
            <div className="w-[20%]">
              <HighchartsReact highcharts={Highcharts} options={optionsPNB} />
            </div>
            <div className="w-[20%]">
              {" "}
              <HighchartsReact highcharts={Highcharts} options={optionsPNC} />
            </div>
            <div className="w-[20%]">
              {" "}
              <HighchartsReact highcharts={Highcharts} options={optionsPND} />
            </div>
            <div className="w-[20%]">
              {" "}
              <HighchartsReact highcharts={Highcharts} options={optionsPercentPNA} />
            </div>
          </>
        </div>
      )}
    </>
  );
};
export default Pdtwhm;
