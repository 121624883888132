/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconPDF, IconPen } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import PDFview from "@/pages/TestPage/QMS/QMFM/ModalContainer/PDF/PDFview";
import apiBasic from "@/service/APIBasic";
import { getAqlHd } from "@/service/apis/QMS/aql";
import { createPlanImage, deletePlanTb, getPlanTb, updatePlanTb } from "@/service/apis/QMS/plan";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer/index";

const TB = ({ c_lightData, set_c_lightData }) => {
  const { c_userData } = useContext(SystemContext);
  const { t } = useTranslation();

  const call_getPlanTb = useAPI(getPlanTb);
  const call_updatePlanTb = useAPI(updatePlanTb);
  const call_deletePlanTb = useAPI(deletePlanTb);
  const call_createPlanImage = useAPI(createPlanImage);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const [s_isShowPDF, set_s_isShowPDF] = useState(false);
  const [s_editData, set_s_editData] = useState({});
  const call_getAqlHd = useAPI(getAqlHd);
  const [s_PDFurl, set_s_PDFurl] = useState([]);
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_updatePlanTb.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };
  const AqlOptions = (text) => {
    const data = call_getAqlHd?.data?.map((item, index) => {
      // console.log(call_getAqlHd?.data[index + 1]);
      const label = `${item.sizecode} : ${item.size} ~ ${
        call_getAqlHd?.data[index + 1]?.size ?? ""
      }`;

      return {
        label,
        value: item.sizecode,
      };
    });
    console.log(text);
    console.log(data);

    return data.find((item) => item.value === text)?.label;
  };

  const tableColumns = [
    // {
    //   title: "計劃ID",
    //   dataIndex: "planID",
    //   align: "center",

    //   width: 100,
    //   // render: () => <MenuOutlined />,
    // },
    // {
    //   title: "抽樣表序", // 序
    //   dataIndex: "itemno",
    //   align: "right",
    //   width: 100,
    // },
    {
      title: "批量始值",
      dataIndex: "batchstart",
      align: "right",
      width: "6%",
    },
    {
      title: "批量迄值",
      dataIndex: "batchend",
      align: "right",
      width: "8%",
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      width: "2%",
    },
    {
      title: "正常抽樣計劃 I 級",
      dataIndex: "G1",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "正常抽樣計劃 II級",
      dataIndex: "G2",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "正常抽樣計劃 III級",
      dataIndex: "G3",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "特殊抽樣計劃 S1級",
      dataIndex: "S1",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "特殊抽樣計劃 S2級",
      dataIndex: "S2",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "特殊抽樣計劃 S3級",
      dataIndex: "S3",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    {
      title: "特殊抽樣計劃 S4級",
      dataIndex: "S4",
      align: "left",
      render: (text) => AqlOptions(text),
    },
    // {
    //   title: t("util.util.note"),
    //   dataIndex: "note",
    //   align: "left",
    // },
    // {
    //   title: t("util.util.updtT"),
    //   dataIndex: "updtT",
    //   align: "center",
    //   width: "5%",
    //   render: (text) => dayjs(text).format("YYYY-MM-DD"),
    // },

    // {
    //   title: t("util.util.action"),
    //   dataIndex: "action",
    //   align: "center",
    //   width: "3%",
    //   render: (_, rowData, all) => (
    //     <Space>
    //       <IconPen
    //         onClick={(e) => {
    //           // console.log(s_tableData);
    //           set_s_editData(s_tableData);
    //           set_s_isShowModal(true);
    //           e.stopPropagation();
    //         }}
    //       />
    //       <IconGarbage
    //         onClick={(e) => {
    //           removeData(rowData);
    //           e.stopPropagation();
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
  ];
  function onSearch() {
    call_getPlanTb.request({ ...c_lightData });
  }

  useEffect(() => {
    onSearch();
  }, [c_lightData]);

  useEffect(() => {
    onSearch();
    call_getAqlHd.request();
  }, []);
  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getPlanTb;
    if (status === "suc") {
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getPlanTb.status]);

  useEffect(() => {
    const { status, msg } = call_updatePlanTb;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updatePlanTb.status]);

  useEffect(() => {
    const { status, data, msg } = call_deletePlanTb;

    if (status === "suc") {
      message.success(msg);
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deletePlanTb.status]);

  // 產生抽樣計劃明細圖檔
  useEffect(() => {
    const { status, msg, data } = call_createPlanImage;
    if (status === "suc") {
      message.success(msg);
      console.log(data);
      set_s_PDFurl(data);
      set_s_isShowPDF(true);
      // window.open(data, "_blank");
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_createPlanImage.status]);

  const searchBasic = [
    // <ExpandButton key="ExpandButton" onClick={() => set_s_isShowDndModal(true)} />,
    // <AddButton onClick={() => set_s_isShowModal(true)} />,
    <IconPDF
      type="primary"
      key="pdf"
      className="text-red-500 text-[40px]"
      onClick={() => {
        call_createPlanImage.request({ ...c_lightData });
      }}
    />,
    <Button
      shape="circle"
      type="primary"
      onClick={() => {
        set_s_editData(s_tableData);
        set_s_isShowModal(true);
        //           e.stopPropagation();
      }}
      className="bg-[#4AC6F5] disabled:bg-slate-300"
    >
      {s_tableData.length > 0 ? <IconPen className="text-white font-bold " /> : <PlusOutlined />}
    </Button>,
  ];

  return (
    <>
      <PageHeader title={"抽樣計劃明細"} extra={searchBasic} />
      <CustomTable
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={s_tableData}
        pagination={false}
        rowClassName={() => "editable-row"}
      />

      <CustomModal
        title={type === "create" ? "新增抽樣計劃明細" : "編輯抽樣計劃明細"}
        width={"100%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getPlanTb.request(c_lightData);
        }}
      >
        <ModalContainer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      <CustomModal
        title={"抽樣計劃總表"}
        width={"80%"}
        open={s_isShowPDF}
        onCancel={() => set_s_isShowPDF(false)}
        afterClose={() => {}}
      >
        <PDFview url={`${apiBasic.conn}://${apiBasic.url}/${s_PDFurl}`} />
      </CustomModal>
    </>
  );
};

export default TB;
