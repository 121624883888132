/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Space, message } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deleteMnsAppGp, getMnsAppGp, sortMnsAppGp } from "@/service/apis/MNS/notificationSetting";
import { ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer/index";

const AppButton = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const call_getMnsAppGp = useAPI(getMnsAppGp);
  const call_deleteMnsAppGp = useAPI(deleteMnsAppGp);
  const call_sortMnsAppGp = useAPI(sortMnsAppGp);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getMnsAppGp.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      dataIndex: "sort",
      width: "80px",
      align: "center",
      render: () => <MenuOutlined />,
    },
    // {
    //   title: "序",
    //   dataIndex: "itemno",
    //   align: "center",
    // },
    {
      title: "按鍵分群",
      dataIndex: "buttongp",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
      align: "left",
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "120px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => {
        call_deleteMnsAppGp.request(rowData);
      },
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getMnsAppGp.status === "suc") {
      message.success(call_getMnsAppGp.msg);
      set_s_tableData(
        call_getMnsAppGp.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getMnsAppGp.status === "err") {
      message.error(call_getMnsAppGp.msg);
      set_s_tableData([]);
    }
  }, [call_getMnsAppGp.status]);

  useEffect(() => {
    if (call_deleteMnsAppGp.status === "suc") {
      message.success(call_deleteMnsAppGp.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteMnsAppGp.status === "err") {
      message.error(call_deleteMnsAppGp.msg);
    }
  }, [call_deleteMnsAppGp.status]);

  useEffect(() => {
    const { status, msg, data } = call_sortMnsAppGp;
    if (status === "suc") {
      console.log("data = ", data);
      call_getMnsAppGp.request(s_searchData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_sortMnsAppGp.status]);

  return (
    <>
      <DragSortingTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getMnsAppGp.status === "load"}
        c_lightData={s_editData}
        afterSorting={(_, dragRow, destination) => {
          call_sortMnsAppGp.request({ ...dragRow, itemno: destination });
          // console.log("data = ", data);
          // return data;
        }}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.util.edit") + t("ADM.function-params-manage.functions")
            : t("util.util.add") + t("ADM.function-params-manage.functions")
        }
        width={"50%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default AppButton;
