import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getWhReport = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getWhReport`, {
    params: data,
  });
};

export const createWhmData = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/createWhmData`, data);
};
