import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { addZ1Label, updateZ1Label } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addZ1Label = useAPI(addZ1Label);
  const call_updateZ1Label = useAPI(updateZ1Label);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addZ1Label.request(postData);
    }

    if (type === "edit") {
      call_updateZ1Label.request(postData);
    }
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    if (call_addZ1Label.status === "suc") {
      message.success(call_addZ1Label.msg);
      set_is_showModal(false);
    } else if (call_addZ1Label.status === "err") {
      message.error(call_addZ1Label.msg);
    }
  }, [call_addZ1Label.status]);

  useEffect(() => {
    if (call_updateZ1Label.status === "suc") {
      message.success(call_updateZ1Label.msg);
      set_is_showModal(false);
    } else if (call_updateZ1Label.status === "err") {
      message.error(t(`error.error-code.${call_updateZ1Label.msg}`));
    }
  }, [call_updateZ1Label.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        wh: 0,
        length: 0,
        qty: 0,
        ...s_editData,
      }}
      onValuesChange={(_, allValues) => {
        if (!allValues.type) return;
        console.log(allValues);
        const f1 = allValues.pastetime * allValues.qty;
        const f2 = allValues.takelabeltime * allValues.length;

        form.setFieldsValue({
          wh: parseFloat((allValues.taketime + f1 + f2).toPrecision(12)),
        });
      }}
    >
      <Form.Item name="wlNM" hidden />
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label="料號" rules={[{ required: true }]}>
            {Object.keys(s_editData).length > 0 ? (
              <Input disabled={Object.keys(s_editData).length > 0} />
            ) : (
              <DebounceSelect
                fetchOptions={fetchPNList}
                maxTagCount={10}
                showSearch
                onChange={(value) => {
                  form.setFieldsValue({ PN: value.value, pdtNM: value.title });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="type" label="貼標類型" rules={[{ required: true }]}>
            <Select
              options={[
                { label: "一般", value: "1" },
                { label: "水標", value: "2" },
              ]}
              onChange={(value) => {
                const formData = form.getFieldsValue();

                if (value === "1") {
                  const f1 = 7.4 * formData.qty;
                  const f2 = 0.11 * formData.length;
                  console.log(9.8, f1, f2, formData.qty);
                  form.setFieldsValue({
                    takelabeltime: 0.11,
                    taketime: 9.8,
                    pastetime: 7.4,
                    wh: parseFloat((9.8 + f1 + f2).toPrecision(12)),
                  });
                }
                if (value === "2") {
                  const f1 = 21 * formData.qty;
                  const f2 = 0.11 * formData.length;
                  console.log(9.8, f1, f2, formData.qty);
                  form.setFieldsValue({
                    takelabeltime: 0.11,
                    taketime: 9.8,
                    pastetime: 21,
                    wh: parseFloat((9.8 + f1 + f2).toPrecision(12)),
                  });
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="length" label="標籤總長度(cm)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="qty" label="標籤數量" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="taketime" label="輪圈拿取時間 (S)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="pastetime" label="計算參數-黏貼" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="takelabeltime" label="計算參數-貼紙拿取" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="wh" label="C/T值(S/PCS)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addZ1Label.status === "load" || call_updateZ1Label.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
