/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
// import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";
import { produceColumnChartForMom } from "./ChartBasic/ColumnChart/momChart";
import { Button } from "antd";
import Highcharts from "highcharts/highstock";
import dayjs from "dayjs";

const MomInstockOkRate = (props) => {
  const { s_PNChartData, s_chartData, s_compareItem, s_chartColumn ,s_timeUnit} = props;
  // 日期轉換成 "mm/dd" 格式

  const generateXaxisTitle = (data) => {
    if (data.length === 0) return [];
   
    if(s_timeUnit==="month"){
      const allDate=data[0].date?.map(week => `${week}周`);
      // console.log(...allDate);
      return allDate
    }
    if(s_timeUnit==="week" || s_timeUnit==="Interval"){
      const weekdays = ['(Sun)', '(Mon)', '(Tue)', '(Wed)', '(Thu)', '(Fri)', '(Sat)'];
      return data[0]?.data[0]?.data.map((item) => {
        const date = new Date(item[0]);
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const week =date.getDay()
        return `${month}/${day} ${weekdays[week]}`;
      });

    }
    if(s_timeUnit==="day"){
      const allTime=data[0].date?.map(time => `${time}:00`);
   
      return allTime
    
    }
    if(s_timeUnit==="year"){
      const allMonth=data[0].date?.map(month => `${month}月`);
   
      return allMonth
    }
   
  };

  //X軸標題
  const xAxisDataForAmountA =  generateXaxisTitle(s_PNChartData===undefined?s_chartData:s_PNChartData);
  


  const produceChartOptions = (data) => {
    if (data===undefined)return
   return data.map((item) => {
      return (
        <div className={`relative ${s_chartColumn}`}>
          <HighchartsReact
            highcharts={Highcharts}
            options={produceColumnChartForMom(
              null,null,xAxisDataForAmountA,
               [...item.data],
               item.mgmtcat,
               item.PN ? "#FFFFFF":"#FFFFF3")
            }

            // ref={incChartRef}
          />
          {/* <Button
            onClick={() => {
              chartStateHandleChange(set_s_inC);
            }}
            className={` absolute top-10 left-6  border-none ${buttonStyle[s_inC]}`}
          >
            {s_inC ? "各數" : "總和"}
          </Button> */}
        </div>
      );
    });
  };
  //生成數值圖表 對象項分類 data格式包含顏色設定柱體顯示
  // function generateChartData(dataProps, type = "mtypes") {
  //   if (s_chartData.length===0) return []
  //   if (type === "mtypes") {
  //     return s_chartData?.map((data) => ({
  //       name: `${data.mtypes}機種`,
  //       // data:checknull(data[dataProps]) ,
  //       data: data[dataProps]?.map((value, index) => [
  //         new Date(data.instockT[index]).getTime(),
  //         value,
  //       ]),
  //       // x:new Date(data.instockT).getTime(),
  //       //   mtype.map(data => dataProps.map(prop => parseFloat(data[prop], 10),
  //       // ))
  //       color: data.PN ? "" : "#88CBFF",
  //       dataLabels: {
  //         enabled: true,
  //         formatter: function () {
  //           return HighchartsStock.numberFormat(this.y, 0, ".", ",");
  //         },
  //       },
  //     }));
  //   } else if (type === "PN") {
  //     return s_PNChartData?.map((data) => ({
  //       name: data.PN,
  //       data: data[dataProps]?.map((value, index) => [
  //         new Date(data.instockT[index]).getTime(),
  //         value,
  //       ]),
  //       //   mtype.map(data => dataProps.map(prop => parseFloat(data[prop], 10),
  //       // ))
  //       // color: PN.PN ? "" : "#88CBFF",
  //       dataLabels: {
  //         enabled: true,
  //         formatter: function () {
  //           return HighchartsStock.numberFormat(this.y, 0, ".", ",");
  //         },
  //       },
  //     }));
  //   }
  //   // console.log(charData);
  // }
  //生成數值圖表 對象項明細 data格式包含顏色設定柱體顯示
  // function generatePercentChartData(dataProps, type = "mtypes") {
  //   if (type === "mtypes") {
  //     return s_chartData.map((data) => ({
  //       name: `${data.mtypes}機種`,
  //       data: data[dataProps]?.map((value, index) => [
  //         new Date(data.instockT[index]).getTime(),
  //         value,
  //       ]),
  //       tooltip: {
  //         valueSuffix: "%",
  //       },
  //       color: data.PN ? "" : "#88CBFF",
  //       dataLabels: {
  //         enabled: true,
  //         formatter: function () {
  //           return HighchartsStock.numberFormat(this.y) + "%";
  //         },
  //       },
  //     }));
  //   }
  //   return s_PNChartData.map((data) => ({
  //     name: data.PN,
  //     data: data[dataProps]?.map((value, index) => [
  //       new Date(data.instockT[index]).getTime(),
  //       value,
  //     ]),
  //     tooltip: {
  //       valueSuffix: "%",
  //     },
  //     // color: mtype.PN ?"":"#88CBFF",
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function () {
  //         return HighchartsStock.numberFormat(this.y) + "%";
  //       },
  //     },
  //   }));
  // }

  // //帶入各圖所需欄位
  // function generateIncChartData(type = "mtypes") {
  //   const dataProps = "inC";
  //   if (type === "mtypes") {
  //     return generateChartData(dataProps, "mtypes");
  //   }
  //   return generateChartData(dataProps, "PN");
  // }
  // //帶入各圖所需欄位
  // function generateOkcChartData(type = "mtypes") {
  //   const dataProps = "okC";
  //   if (type === "mtypes") {
  //     return generateChartData(dataProps, "mtypes");
  //   }
  //   return generateChartData(dataProps, "PN");
  // }
  // //帶入各圖所需欄位
  // function generateNgcChartData(type = "mtypes") {
  //   const dataProps = "ngC";
  //   if (type === "mtypes") {
  //     return generateChartData(dataProps, "mtypes");
  //   }
  //   return generateChartData(dataProps, "PN");
  // }
  // function generateOkRateChartData(type = "mtypes") {
  //   const dataProps = "okRate";
  //   if (type === "mtypes") {
  //     return generatePercentChartData(dataProps, "mtypes");
  //   }
  //   return generatePercentChartData(dataProps, "PN");
  // }

  const chartStateHandleChange = (setState) => {
    setState((prev) => !prev);
  };
  // // //帶入各圖所需欄位
  // // function generateChartDataD(charData) {
  // //   const dataProps = ["okRate"];
  // //   return generateChartData(charData, dataProps);
  // // }

  // //生成 良率百分比圖表 data

  // //   if (incChartRef.current) {
  // //     const chart = incChartRef.current.chart;

  // //     const button = chart.renderer.button
  // // ('總和', chart.plotLeft, chart.plotTop - 50, () => {
  // //       alert('You clicked the button on Chart 1!');
  // //     }).add();

  // //     return () => {
  // //       button.destroy();
  // //     };
  // //   }
  // // }, [incChartRef]);

  // // useEffect(() => {
  // //   if (okcChartRef.current) {
  // //     const chart = okcChartRef.current.chart;

  // //     const button = chart.renderer.button('總和', chart.plotLeft, chart.plotTop - 50, () => {
  // //       alert('You clicked the button on Chart 2!');
  // //     }).add();

  // //     return () => {
  // //       button.destroy();
  // //     };
  // //   }
  // // }, [okcChartRef]);

  // // useEffect(() => {
  // //   if (ngcChartRef.current) {
  // //     const chart = ngcChartRef.current.chart;

  // //     const button = chart.renderer.button('總和', chart.plotLeft, chart.plotTop - 50, () => {
  // //       alert('You clicked the button on Chart 3!');
  // //     }).add();

  // //     return () => {
  // //       button.destroy();
  // //     };
  // //   }
  // // }, [ngcChartRef]);

  // // useEffect(() => {
  // //   const generateButton = (chartRef,state, setState) => {
  // //     if (chartRef.current) {

  // //       const chart = chartRef.current.chart;
  // //       const buttonYaxisOffset =40
  // //       const buttonXaxisOffset =26
  // //       const bgColor = state ? "#ffefcf" : "#dbeaff"; // 根据 state 状态设置背景颜色
  // //       const textColor = state ? "#ffaa64" : "#62a4ff"; // 字体颜色相同
  // //       const button = chart.renderer.button(
  // //         state?"各數":'總和', buttonXaxisOffset,buttonYaxisOffset ,
  // //        () => {
  // //         setState(prev=>!prev)
  // //       }).css({
  // //         width: 200 + "px",
  // //         padding: 100 + "px",
  // //         color: textColor,
  // //         ':hover': { // 指定 hover 樣式
  // //           backgroundColor: bgColor,
  // //           color: 'white'
  // //         }
  // //       }).attr({
  // //       fill:bgColor, // 根据 state 状态设置背景颜色

  // //     })
  // //       .add()
  // //       .on('mouseover', function() { // 當滑鼠移到按鈕上時
  // //         this.setState({
  // //           fill: '#ff0000', // 按鈕背景顏色
  // //           style: {
  // //             color: '#ffffff' // 按鈕文字顏色
  // //           }
  // //         });
  // //       })
  // //       .on('mouseout', function() { // 當滑鼠移出按鈕時
  // //         this.setState({
  // //           fill: bgColor,
  // //           style: {
  // //             color: textColor
  // //           }
  // //         });
  // //       });;
  // //       return () => {
  // //         button.destroy();
  // //       };
  // //     }
  // //   };

  // //   const cleanupFunctions = [
  // //     generateButton(incChartRef,s_inC,set_s_inC),
  // //     generateButton(okcChartRef,s_okC,set_s_okC),
  // //     generateButton(ngcChartRef,s_ngC,set_s_ngC),
  // //     generateButton(okRateChartRef,s_okRate,set_s_okRate)
  // //   ];

  // //   return () => {
  // //     cleanupFunctions.forEach(cleanup => cleanup && cleanup());
  // //   };
  // // }, [s_compareItem,s_inC,s_ngC,s_okC,s_okRate]);

  // //數值圖
  // const optionInc = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateIncChartData("mtypes"),
  //   "投入數量"
  // );
  // const optionsOkc = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateOkcChartData("mtypes"),
  //   "良品數"
  // );
  // const optionsNgc = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateNgcChartData("mtypes"),
  //   "報廢品量"
  // );
  // const optionsOkRate = produceLineChartForPercent(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateOkRateChartData("mtypes"),
  //   "良率"
  // );

  // //明細數值圖
  // const optionPNInc = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateIncChartData("PN"),
  //   "投入數量"
  // );
  // const optionPNOkc = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateOkcChartData("PN"),
  //   "良品數"
  // );
  // const optionPNNgC = produceLineChartForValue(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateNgcChartData("PN"),
  //   "報廢品量"
  // );
  // const optionsPNOkRate = produceLineChartForPercent(
  //   chartAppearance.value.width,
  //   chartAppearance.value.height,
  //   xAxisDataForAmountA,
  //   generateOkRateChartData("PN"),
  //   "良率"
  // );
  // console.log(instockTArray);
  // console.log(s_chartData);
  // console.log(MtypesArray);
  // console.log(mtypesData);
  // console.log(PNData);
  // console.log(instockTArray);
  // console.log(optionInc);
  return (
    <>
      {
        // s_chartData.length!==0 &&
        <div className="flex flex-wrap w-full   items-center    ">
          {produceChartOptions(s_chartData)}
          {produceChartOptions(s_PNChartData)}
          {/* {(s_compareItem.includes("inC") ||
            s_compareItem.includes("all") ||
            s_compareItem.length === 0) && (
            <div className={`relative ${s_chartColumn}`}>
              <HighchartsReact
                highcharts={HighchartsStock}
                options={s_inC ? optionPNInc : optionInc}
                constructorType={"stockChart"}
                // ref={incChartRef}
              />
              <Button
                onClick={() => {
                  chartStateHandleChange(set_s_inC);
                }}
                className={` absolute top-10 left-6  border-none ${buttonStyle[s_inC]}`}
              >
                {s_inC ? "各數" : "總和"}
              </Button>
            </div>
          )}
          {(s_compareItem.includes("okC") ||
            s_compareItem.includes("all") ||
            s_compareItem.length === 0) && (
            <div className={`relative ${s_chartColumn}`}>
              <HighchartsReact
                highcharts={HighchartsStock}
                options={s_okC ? optionPNOkc : optionsOkc}
                constructorType={"stockChart"}
              />
              <Button
                onClick={() => {
                  chartStateHandleChange(set_s_okC);
                }}
                className={` absolute top-10 left-6  border-none ${buttonStyle[s_okC]}`}
              >
                {s_okC ? "各數" : "總和"}
              </Button>
            </div>
          )}
          {(s_compareItem.includes("ngC") ||
            s_compareItem.includes("all") ||
            s_compareItem.length === 0) && (
            <div className={`relative ${s_chartColumn}`}>
              <HighchartsReact
                highcharts={HighchartsStock}
                constructorType={"stockChart"}
                options={s_ngC ? optionPNNgC : optionsNgc}
              />
              <Button
                onClick={() => {
                  chartStateHandleChange(set_s_ngC);
                }}
                className={` absolute top-10 left-6  border-none ${buttonStyle[s_ngC]}
    `}
              >
                {s_ngC ? "各數" : "總和"}
              </Button>
            </div>
          )}
          {(s_compareItem.includes("okRate") ||
            s_compareItem.includes("all") ||
            s_compareItem.length === 0) && (
            <div className={`relative ${s_chartColumn}`}>
              <HighchartsReact
                highcharts={HighchartsStock}
                options={s_okRate ? optionsPNOkRate : optionsOkRate}
                constructorType={"stockChart"}
              />
              <Button
                onClick={() => {
                  chartStateHandleChange(set_s_okRate);
                }}
                className={` absolute top-10 left-6  border-none ${buttonStyle[s_okRate]}
    `}
              >
                {s_okRate ? "各數" : "總和"}
              </Button>
            </div>
          )} */}
        </div>
      }
    </>
  );
};
export default MomInstockOkRate;

// import React, { useRef, useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// const MyChart = () => {

//   const options = {
//     chart: {
//       type: 'column'
//     },
//     title: {
//       text: 'My Chart'
//     },
//     // 其他圖表選項
//   };

//   return (
//     <div style={{ width: '100%' }}>
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={options}

//       />
//     </div>
//   );
// };

// // export default MyChart;
// import React from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// const Chart = () => {

//   const data= [
//   {
//       mgmtcat:"A",
//       data:
//     [
//     {
//       name: 'okc',
//       data: [
//         [Date.UTC(2022, 0, 1), 10],
//         [Date.UTC(2022, 0, 2), 15],
//         [Date.UTC(2022, 0, 3), 8],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'inc',
//       data: [
//         [Date.UTC(2022, 0, 1), 5],
//         [Date.UTC(2022, 0, 2), 12],
//         [Date.UTC(2022, 0, 3), 6],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'ngc',
//       data: [
//         [Date.UTC(2022, 0, 1), 8],
//         [Date.UTC(2022, 0, 2), 10],
//         [Date.UTC(2022, 0, 3), 4],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'OKrate',
//       type: 'line',
//       yAxis: 1,
//       tooltip: {
//         valueSuffix: '%', // 自定義數據序列 B 的 tooltip 後綴
//       },
//       data: [
//         [Date.UTC(2022, 0, 1), 80.80],
//         [Date.UTC(2022, 0, 2), 90.35],
//         [Date.UTC(2022, 0, 3), 70.4],
//         // Add more data points as needed
//       ],
//     }]
//   },
//   {
//     mgmtcat:"B",
//     data:
//     [
//     {
//       name: 'okc',
//       data: [
//         [Date.UTC(2022, 0, 1), 10],
//         [Date.UTC(2022, 0, 2), 15],
//         [Date.UTC(2022, 0, 3), 8],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'inc',
//       data: [
//         [Date.UTC(2022, 0, 1), 5],
//         [Date.UTC(2022, 0, 2), 12],
//         [Date.UTC(2022, 0, 3), 6],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'ngc',
//       data: [
//         [Date.UTC(2022, 0, 1), 8],
//         [Date.UTC(2022, 0, 2), 10],
//         [Date.UTC(2022, 0, 3), 4],
//         // Add more data points as needed
//       ],
//     },
//     {
//       name: 'OKrate',
//       type: 'line',
//       yAxis: 1,
//       tooltip: {
//         valueSuffix: '%', // 自定義數據序列 B 的 tooltip 後綴
//       },
//       data: [
//         [Date.UTC(2022, 0, 1), 80.70],
//         [Date.UTC(2022, 0, 2), 9.65],
//         [Date.UTC(2022, 0, 3), 10.4],
//         // Add more data points as needed
//       ],
//     }
//     ]

//   }

//   ]

//   // console.log(data.map(item=>item.data).flat());
//   const options = (data)=>{
//     console.log([...data.data]);
//     return ({
//       chart: {
//         type: 'column',
//       },
//       title: {
//         text: `${data.mgmtcat}`,
//       },
//       xAxis: {
//         type: 'datetime',
//         title: {
//           text: '日期',
//         },
//       },
//       yAxis: [
//         {
//           title: {
//             text: '數值',
//           },
//           labels: {
//             formatter: function () {
//               return Highcharts.numberFormat(this.value, 0, ',', ',');
//             },
//           },
//           tickAmount: 5,

//         },
//         {
//           labels: {
//             format: '{value}%',
//           },
//           tickPositions: [0, 25, 50, 75, 100],
//           title: {
//             text: '百分比',
//           },
//           opposite: true,
//         },
//       ],
//       legend: {
//         layout: 'vertical',
//         align: 'right',
//         verticalAlign: 'top',
//       },
//       series:[...data.data]
//     })
//   }

//   return(
//     <>
//     {data.map(item=>{
//       return  <div style={{ width: '100%' }}>
//             <HighchartsReact
//             highcharts={Highcharts}
//            options={options(item)}
      
//            />
//      </div>
//     })}

//     </>
//   )
// };

// export default Chart;
