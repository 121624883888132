import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const createAQLimage = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/createAQLimage`, data);
};

export const getAqlHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAqlHd`, {
    params: _params,
  });
};
export const addAqlHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addAqlHd`, data);
};

export const updateAqlHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateAqlHd`, data);
};

export const deleteAqlHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteAqlHd`, data);
};

// -----------------------------------------------------------------------------------------------
export const getAqlTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAqlTb`, {
    params: _params,
  });
};
export const addAqlTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addAqlTb`, data);
};

export const updateAqlTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateAqlTb`, data);
};

export const deleteAqlTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteAqlTb`, data);
};
