import * as React from "react";
import { FormListFieldData } from "antd";
import { Select, Form, InputNumber, Col } from "antd";

const textColor = [
  { label: "黑", value: "#000000" },
  { label: "白", value: "#ffffff" },
];

const roundLabel = (field: FormListFieldData) => (
  <>
    <Col span={6}>
      <Form.Item label="半徑" name={[field.name, "radius"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item label="顏色" name={[field.name, "c"]} rules={[{ required: true }]}>
        <Select options={textColor} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const LabelRound = { roundLabel };

export default LabelRound;
