import { addMnsEvent, updateMnsEvent } from "@/service/apis/MNS/notificationSetting";
import { Button, Col, Form, Input, Radio, InputNumber, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const selectAfter = (
  <Select
    className="w-[80px]"
    options={[
      { value: "sec" },
      { value: "min" },
      { value: "hrs" },
      { value: "%" },
      { value: "NA" },
      { value: "hd" },
    ]}
  />
);

const varOptions = [
  { value: "${PN}", label: "工單料號" },
  { value: "${prePN}", label: "料號前碼" },
  { value: "${postPN}", label: "料號末四碼" },
  { value: "${batchID}", label: "批號" },
  { value: "${SN}", label: "流水號" },
  { value: "${date}", label: "日期" },
  { value: "${woN}", label: "工單號" },
  { value: "${orderN}", label: "工單訂單號" },
  { value: "${orderitemno}", label: "工單訂單項次" },
  { value: "${pdtNM}", label: "品名" },
  { value: "${peopleNM}", label: "員工姓名" },
  { value: "${cardID}", label: "卡勤識別碼" },
  { value: "${staffID}", label: "員工工號" },
  { value: "${mtype}", label: "機種" },
  { value: "${mtypeNM}", label: "機種名稱" },
  { value: "${itemID}", label: "項目ID" },
  { value: "${itemNM}", label: "項目名稱" },
  { value: "${orderqty}", label: "訂單數量" },
  { value: "${batchbom}", label: "每批數量" },
  { value: "${dealPN}", label: "客戶料號" },
  { value: "${dealalias}", label: "客戶名稱" },
  { value: "${pdtspec}", label: "規格" },
  { value: "${opcode}", label: "單別" },
  { value: "${carSN}", label: "車號" },
  { value: "${duestart}", label: "預完工日(起)" },
  { value: "${issueday}", label: "發佈日期" },
  { value: "${planqty}", label: "計畫數量" },
  { value: "${inC}", label: "投產數量" },
  { value: "${okC}", label: "良品數量" },
  { value: "${ngC}", label: "不良數量" },
  { value: "${pwcID}", label: "工作中心代碼" },
  { value: "${pwcNM}", label: "工作中心名稱" },
];

const situF_BGcolor = {
  Y: "bg-yellow-300",
  R: "bg-red-300",
  NA: "bg-yellow-50",
};

export default function ModalConintaer({
  s_editData,
  set_s_isShowModal,
  s_arrMap,
  set_s_editData,
}) {
  const [form] = Form.useForm();
  const f_interval = Form.useWatch("interval", form);
  const f_situF = Form.useWatch("situF", form);
  const f_rptcode = Form.useWatch("rptcode", form);

  const { t } = useTranslation();
  const [s_lastMousePosition, set_s_lastMousePosition] = useState(0);
  const [s_var, set_s_var] = useState(undefined);

  const call_addMnsEvent = useAPI(addMnsEvent);
  const call_updateMnsEvent = useAPI(updateMnsEvent);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.funcode && values.funcode.length > 0) {
      values.funcode = values.funcode.join("|");
    }

    if (type === "create") {
      call_addMnsEvent.request(values);
    }
    if (type === "edit") {
      call_updateMnsEvent.request({ ...s_editData, ...values });
    }
  };

  useEffect(() => {
    if (call_addMnsEvent.status === "suc") {
      message.success(call_addMnsEvent.msg);
      set_s_isShowModal(false);
    } else if (call_addMnsEvent.status === "err") {
      message.error(call_addMnsEvent.msg);
    }
  }, [call_addMnsEvent.status]);

  useEffect(() => {
    if (call_updateMnsEvent.status === "suc") {
      message.success(call_updateMnsEvent.msg);
      set_s_isShowModal(false);
    } else if (call_updateMnsEvent.status === "err") {
      message.error(call_updateMnsEvent.msg);
    }
  }, [call_updateMnsEvent.status]);

  const timesetComponent = (target) => {
    switch (target) {
      case "1":
        return <Input disabled />;
      case "2":
        return (
          <InputNumber placeholder="1~24" className="w-full" min={1} max={24} addonAfter="點整" />
        );
      case "3":
        return (
          <InputNumber
            placeholder="1~N"
            className="w-full"
            min={1}
            addonBefore="每"
            addonAfter="日"
          />
        );
      case "4":
        return (
          <InputNumber placeholder="1~7" className="w-full" min={1} max={7} addonBefore="週" />
        );
      case "5":
        return (
          <InputNumber
            placeholder="1~31"
            className="w-full"
            min={1}
            max={31}
            addonAfter="號"
            addonBefore="每月"
          />
        );
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        interval: "1",
        setlvl: 1,
        rptcode: "B0",
        pusher: "WOS",
        ...s_editData,
      }}
    >
      <Row gutter={[24, 0]}>
        {/* "事件ID" */}
        <Col span={8}>
          <Form.Item
            label={t("ADM.function-params-manage.eventID")}
            rules={[{ required: true }]}
            name="eventID"
          >
            <Input
              disabled={Object.keys(s_editData).length > 0}
              onChange={(e) => form.setFieldsValue({ control: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "事件名稱" */}
          <Form.Item
            label={t("ADM.function-params-manage.eventNM")}
            rules={[{ required: true }]}
            name="eventNM"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 事件型態 */}
          <Form.Item label="事件型態" rules={[{ required: true }]} name="type">
            <Select
              options={[
                { value: "abn", label: "異常提報" },
                { value: "mgm", label: "管理提報" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={8}>
          <Form.Item
            label="控項" // 內容
            name="control"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="控值(單位)" // 內容
            name="stdvalue"
          >
            <InputNumber
              className="w-full"
              addonAfter={
                <Form.Item name="unit" className="m-0 p-0">
                  {selectAfter}
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="提報條件" // 內容
            name="condition"
          >
            <Select options={[{ value: ">" }, { value: "<" }, { value: "NA" }]} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]} className="bg-yellow-50">
        <Col span={8}>
          {/* "控點" */}
          <Form.Item label="控點" name="ctrlspot">
            <Select
              allowClear
              options={s_arrMap.ctrlspot}
              disabled={Object.keys(s_editData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* "提報類別" */}
          <Form.Item label={t("ADM.function-params-manage.rptcode")} name="rptcode">
            <Select
              allowClear
              options={s_arrMap.rptcodeMap}
              onChange={(value) => {
                form.setFieldsValue({
                  pusher: value === "A0" ? "WOS" : value === "C0" ? "IOT" : undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "發報系統" */}
          <Form.Item label="發報機制" name="pusher">
            <Select
              disabled={f_rptcode === "D0"}
              allowClear
              options={s_arrMap.pusherMap.filter((item) => {
                if (f_rptcode === "A0") {
                  return item.value === "WOS";
                } else if (f_rptcode === "C0") {
                  return item.value === "IOT";
                } else if (f_rptcode === "B0") {
                  return ["WOS", "PMS", "OMS", "IMS", "WMS"].includes(item.value);
                }
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8} className={situF_BGcolor[f_situF]}>
          {/* "警訊燈號" */}
          <Form.Item label={t("ADM.function-params-manage.situF")} name="situF">
            <Select allowClear options={s_arrMap.situFMap} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "推播群組" */}
          <Form.Item label="推播群組" name="funcode">
            <Select mode="multiple" allowClear options={s_arrMap.funcodeMap} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*首推層級*/}
          <Form.Item label="首推層級" name="startlvl">
            <Select allowClear options={s_arrMap.startlvlMap} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*連推級數*/}
          <Form.Item label="連推級數" name="setlvl">
            <InputNumber min={1} max={4} className="w-full" />
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item label={t("ADM.function-params-manage.cruisebot")} name="cruisebot">
            <Input />
          </Form.Item>
        </Col> */}

        <Col span={8}>
          <Form.Item label="報時類型" name="interval" shouldUpdate>
            <Select
              onChange={() => {
                form.setFieldsValue({ timeset: undefined });
              }}
              options={[
                { value: "1", label: "立即" },
                { value: "2", label: "整點" },
                { value: "3", label: "每日" },
                { value: "4", label: "每週" },
                { value: "5", label: "每月" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="提報時點" // 內容
            name="timeset"
          >
            {timesetComponent(f_interval)}
          </Form.Item>
        </Col>

        {/* <Col span={8}>
          <Form.Item label="哨兵程式" name="cruisebot">
            <Select
              options={[
                { value: "cruiserA(IOT)" },
                { value: "cruiserB(WOS)" },
                { value: "cruiserC(WO)" },
                { value: "cruiserD(SFC)" },
                { value: "歷程觸發" },
                { value: "採集觸發" },
              ]}
            />
          </Form.Item>
        </Col> */}

        <Col span={8}>
          <Form.Item label="按鍵分群" name="group">
            <Select allowClear options={s_arrMap.appGp} />
          </Form.Item>
        </Col>

        {/* <Col span={8}>
          <Form.Item label="微信機器人" name="wechat">
            <Input />
          </Form.Item>
        </Col> */}
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]} className="">
        <Col span={18}>
          {/* 推播訊息模版 */}
          <Form.Item
            label={t("ADM.function-params-manage.msgmodel")}
            name="msgmodel"
            className="mb-1"
          >
            <Input.TextArea
              rows={3}
              onSelect={(e) => set_s_lastMousePosition(e.target.selectionStart)}
            />
          </Form.Item>
        </Col>

        <Col span={6} className="mt-3">
          <Row gutter={[8, 12]}>
            <Col span={24} className="mt-3">
              <Select
                className="w-full text-center"
                showSearch
                placeholder="新增變數"
                value={s_var}
                options={varOptions}
                onChange={(value) => set_s_var(value)}
              />
            </Col>
            <Col span={24}>
              <Button
                disabled={!s_var}
                type="primary"
                block
                onClick={() => {
                  let formData = form.getFieldsValue();
                  let strArr = formData.msgmodel?.split("");
                  strArr.splice(s_lastMousePosition, 0, s_var);
                  formData.msgmodel = strArr.join("");
                  form.setFieldsValue(formData);
                }}
              >
                {t("util.util.add")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Col span={24} className="flex justify-between mt-4">
        <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        <Space>
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Space>
      </Col>
    </Form>
  );
}
