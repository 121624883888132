import { Button, Col, Divider, Form, Input, Radio, Row, Select, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// util component
// api
import useAPI from "@/hooks/useAPI";
import { addSampleSet, updateSampleSet } from "@/service/apis/QMS/SampleSet";
import { getPlanHd } from "@/service/apis/QMS/plan";

export default function ModalConintaer({ s_editData, set_s_isShowModal, s_arrMap }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  // 新增表頭資料
  const call_addSampleSet = useAPI(addSampleSet);
  // 編輯表頭資料
  const call_updateSampleSet = useAPI(updateSampleSet);

  const call_getPlanHd = useAPI(getPlanHd);

  const planIDOptions =
    call_getPlanHd.data?.map((item) => ({
      value: item?.planID,
      label: item?.planID,
      planNM: item?.planNM,
    })) || [];

  useEffect(() => {
    call_getPlanHd.request();
  }, []);

  const onSubmit = (values) => {
    if (type === "create") {
      console.log(values);
      call_addSampleSet.request({ ...values });
    }
    if (type === "edit") {
      console.log(values);

      call_updateSampleSet.request({ ...values });
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      // 检查是否按下 Enter 键
      e.preventDefault();
    }
  };
  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addSampleSet;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addSampleSet.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateSampleSet;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateSampleSet.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        ...s_editData,
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
      onKeyDown={handleEnterKey}
      onFinish={onSubmit}
    >
      <div>
        <Form.Item label={"抽樣ID"} name="sampleID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label={"抽樣條件"} name="sampleNM">
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label={"計劃ID"} name="planID">
            <Select
              options={planIDOptions}
              className="w-full"
              onChange={(value) => {
                console.log(value);
                console.log(planIDOptions);
                const resultLabel = planIDOptions.find((item) => {
                  console.log(item);
                  return item.value === value;
                }).planNM;
                console.log(resultLabel);
                form.setFieldsValue({ planNM: resultLabel });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label={"計劃名稱"} name="planNM">
            <Input disabled className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label={"抽樣計劃"} name="samplelevel">
            <Select
              options={[
                { value: "G1", label: "G1" },
                { value: "G2", label: "G2" },
                { value: "G3", label: "G3" },
                { value: "S1", label: "S1" },
                { value: "S2", label: "S2" },
                { value: "S3", label: "S3" },
                { value: "S4", label: "S4" },
              ]}
              className="w-full"
              // onChange={(value) => {

              // }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label={"允收水準"} name="AQL">
            <Select
              options={[
                { value: 0.065, label: "0.065" },
                { value: 0.1, label: "0.1" },
                { value: 0.15, label: "0.15" },
                { value: 0.25, label: "0.25" },
                { value: 0.4, label: "0.4" },
                { value: 0.65, label: "0.65" },
                { value: 1, label: "1" },
                { value: 1.5, label: "1.5" },
                { value: 2.5, label: "2.5" },
                { value: 4, label: "4" },
                { value: 6.5, label: "6.5" },
              ]}
              className="w-full"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 是否有效 */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        {/* <Col span={4}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col> */}
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            loading={call_addSampleSet.status === "load" || call_updateSampleSet.status === "load"}
            type="primary"
            htmlType="submit"
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
