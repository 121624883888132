import { Modal, Space, message } from "antd";
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import Preview from "@/components/Global/Preview";
import { IconCheck, IconClose, IconFile, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deleteQmsSipHd, getQmsSipHd, updateQmsSipHd } from "@/service/apis/QMS/QMSManage";
import { getSampleSet } from "@/service/apis/QMS/SampleSet";
import { formatAmount } from "@/util/format";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import FileModal from "../../File/FileModal";
import ModalConintaer from "./ModalContainer";

const HD = forwardRef((props, ref) => {
  const { c_userData } = useContext(SystemContext);
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap, call_addSpcN, s_isFixed } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  // useAPI 取得表頭資料
  const call_getQmsSipHd = useAPI(getQmsSipHd);
  // useAPI 刪除表頭資料
  const call_deleteQmsSipHd = useAPI(deleteQmsSipHd);

  const call_updateQmsSipHd = useAPI(updateQmsSipHd);
  const call_getSampleSet = useAPI(getSampleSet);
  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  const ctrlrequire_map = useMemo(() => {
    return s_arrMap.ctrlrequire.reduce((prev, curr) => {
      prev[curr.value] = curr.label;
      return prev;
    }, []);
  }, []);

  // 搜尋
  const onSearch = () => {
    call_getQmsSipHd.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteQmsSipHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const removeDrawing = (rowData) => {
    Modal.confirm({
      title: "刪除圖檔確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_updateQmsSipHd.request({ ...rowData, drawing: "" }),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "180px",
      fixed: "left",
      render: (_, rowData) => {
        const isDrawing =
          rowData.drawing?.length > 0 ? (
            <CloseOutlined
              onClick={(e) => {
                removeDrawing(rowData);
                e.stopPropagation();
              }}
            />
          ) : (
            <IconFile
              onClick={(e) => {
                set_s_showFileModal(true);
                set_s_editData(rowData);
                e.stopPropagation();
              }}
            />
          );
        return (
          <Space>
            {isDrawing}
            <IconPen
              onClick={(e) => {
                set_s_isShowModal(true);
                set_s_editData(rowData);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          </Space>
        );
      },
    },

    {
      title: "SIP ID",
      dataIndex: "sipID",
      width: "400px",
      fixed: "left",
      sorter: (a, b) => a.sipID.localeCompare(b.sipID),
      render: (text) => text.substr(0, text.length - 4),
    },
    {
      title: "版次",
      dataIndex: "version",
      align: "center",
      fixed: "left",
      width: "100px",
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      align: "center",
      width: "100px",
      fixed: "left",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "left",
      width: "250px",
      sorter: (a, b) => a.PN.localeCompare(b.PN),
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "類型",
      dataIndex: "siptype",
      width: "250px",
      align: "left",
      sorter: (a, b) => a.siptype.localeCompare(b.siptype),
      // 等i18N寫法
      render: (text) => {
        const map = {
          "IQC": "進料檢驗",
          "PSI": "生產自檢",
          "FAI": "首件檢驗",
          "IPQC": "製中檢驗",
          "SQC": "過站檢驗",
          "FQC": "終品檢驗",
          "OQC": "出貨檢驗",
          "ICS": "儀校標準",
          "SPC": "統計製程管制",
        };
        return map[text];
      },
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      width: "250px",
      align: "left",
      sorter: (a, b) => a.ctrlspot.localeCompare(b.ctrlspot),
      render: (text) => {
        const map = {
          wp: "WP全品比對",
          wo: "WO工單比對",
          pf: "PF製程比對",
          ws: "WS工站比對",
          wl: "WL工線比對",
          wc: "WC工作中心比對",
          qa: "QA品管控制比對",
          cr: "現品票比對",
        };
        return <ShowTooltip title={map[text]} />;
      },
    },
    {
      title: "控點ID",
      dataIndex: "spotID",
      width: "250px",
      sorter: (a, b) => a.spotID.localeCompare(b.spotID),
      render: (text, rowData, _) => {
        return (
          <ShowTooltip title={s_arrMap[rowData.ctrlspot]?.find((x) => x.value === text)?.label} />
        );
      },
    },
    {
      title: "抽樣程序",
      dataIndex: "sampleproc",
      width: "250px",
      align: "center",
      // sorter: (a, b) => a.spotID.localeCompare(b.spotID),
      render: (text, rowData, _) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: "檢驗要求",
      dataIndex: "ctrlrequire",
      align: "left",
      width: "350px",
      onCell: (rowData) => ({ className: `truncate ` }),
      render: (text) => ctrlrequire_map[text],
    },
    {
      title: "週期",
      dataIndex: "ctrlCT",
      align: "center",
      width: "100px",
      render: (text) => formatAmount(text) + " hr",
    },
    {
      title: "工程圖檔",
      dataIndex: "drawing",
      width: "100px",
      align: "center",
      render: (text) => text && <Preview url={text} type="PDF" />,
    },
    {
      title: "文件版次",
      dataIndex: "docver",
      width: "100px",
      hidden: s_isFixed,
      className: "bg-[#f9e9d7ac]",
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "發行日期",
      dataIndex: "pubdata",
      width: "100px",
      hidden: s_isFixed,
      className: "bg-[#f9e9d7ac]",
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "圖面版次",
      dataIndex: "drawver",
      width: "100px",
      hidden: s_isFixed,
      className: "bg-[#f9e9d7ac]",
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "引用規格",
      dataIndex: "datafm",
      width: "100px",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      hidden: s_isFixed,
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "導入SPC",
      dataIndex: "datato",
      width: "100px",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      hidden: s_isFixed,
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "100px",
      render: (text) => <ShowTooltip title={text} />,
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
    call_getSampleSet.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      const copydata = [...data];
      set_s_tableData(
        copydata?.map((x, i) => ({
          ...x,
          index: i,
          key: i + 1,
        }))
      );
      set_c_lightData({});
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getQmsSipHd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteQmsSipHd.status]);

  useEffect(() => {
    const { status, msg } = call_updateQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      onSearch();
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipHd.status]);

  // 配合 Hammer 給客戶 demo, 動態生成 Modal title
  const generateTitle = () => {
    // 判斷是否要顯示「編輯」或「新增」
    const isEditMode = Object.keys(s_editData).length > 0;
    const type = isEditMode ? t("util.util.edit") : t("util.util.add");
    let actionText = "";

    if (c_userData.peopleNM === "superman1") {
      actionText = type + "檢驗項目主檔設定";
    } else {
      actionText = type + "檢驗標準";
    }

    return (
      <span>
        {actionText}
        {isEditMode && (
          <>
            <span className="ml-2 text-blue-500 font-semibold">{s_editData.sipID}</span>
            <span className="ml-2 text-blue-500 font-semibold">版次: {s_editData.version}</span>
          </>
        )}
      </span>
    );
  };

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        scroll_x="max-content"
        loading={
          call_getQmsSipHd.status === "load" ||
          call_deleteQmsSipHd.status === "load" ||
          call_addSpcN.status === "load" ||
          call_updateQmsSipHd.status === "load"
        }
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={generateTitle()}
        width={"80%"}
        style={{ top: 40 }}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          s_tableData={s_tableData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <FileModal type="sipHd" s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
});

export default HD;
