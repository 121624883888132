import { Button, Modal, Select, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
import Preview from "@/components/Global/Preview";
import {
  IconBatchImport,
  IconCheck,
  IconClose,
  IconFile,
  IconGarbage,
  IconPen,
} from "@/components/Icon/Action";
import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import FileModal from "@/pages/TestPage/QMS/QMSManage/File/FileModal";
import BatchImport from "@/pages/TestPage/QMS/QMSManage/SIP/TB/ModalContainer/BatchImport";
import { getInspreport } from "@/service/apis/QMS/Inspreport";
import {
  deleteQmsSipTb,
  getQmsSipTb,
  updateQmsSipTb,
  updateQmsSipTbItemno,
} from "@/service/apis/QMS/QMSManage";
import { CloseOutlined, ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";

// component
import DndModal from "./ModalContainer/DndModal";
import ModalContainer from "./ModalContainer/index";

const TB = ({ c_lightData }) => {
  const { c_userData } = useContext(SystemContext);
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowDndModal, set_s_isShowDndModal] = useState(false);

  const [s_batchImportModal, set_s_batchImportModal] = useState(false);

  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const [s_dataFilter, set_s_dataFilter] = useState("");

  const call_getQmsSipTb = useAPI(getQmsSipTb);
  const call_updateQmsSipTb = useAPI(updateQmsSipTb);
  const call_deleteQmsSipTb = useAPI(deleteQmsSipTb);
  const call_updateQmsSipTbItemno = useAPI(updateQmsSipTbItemno);
  const call_getInspreport = useAPI(getInspreport);

  const drawmark3Options = call_getInspreport.data?.map((item) => ({
    value: item.reportID,
    label: item.reportNM,
  }));

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteQmsSipTb.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };
  const removeDrawing = (rowData) => {
    Modal.confirm({
      title: "刪除圖檔確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_updateQmsSipTb.request({ ...rowData, drawing: "" }),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      // dataIndex: "sort",
      width: "2.5%",
      align: "center",
      render: () => <MenuOutlined />,
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "5%",
    },
    {
      title: "檢驗型態",
      dataIndex: "insptype",
      align: "center",
      width: "7%",
      // 等 i18N
      render: (text) => {
        const map = {
          count: "計數型(定性)",
          measure: "計量型(定量)",
        };
        return map[text];
      },
    },
    {
      title: "檢驗規格",
      dataIndex: "inspspec",
      width: "8%",
      align: "center",
    },
    {
      title: "缺陷類別",
      dataIndex: "itemtype",
      width: "4%",
      // width: "10%",
      align: "center",
      render: (text) => {
        const name = [
          { value: "A", label: "主要缺陷" },
          { value: "B", label: "次要缺陷" },
          { value: "C", label: "嚴重缺陷" },
        ];
        return name.find((item) => item.value === text)?.label;
      },
    },
    {
      title: "管理表單",
      dataIndex: "drawmark3",
      align: "left",
      width: "6.5%",
      render: (text) => {
        const result = drawmark3Options.find((item) => item.value === text)?.label;
        return text && <ShowTooltip title={result} />;
      },
    },

    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      width: "5%",
      // render: (text) => <ShowTooltip title={text} />,
      // width: "4%",
    },
    {
      title: "圖註/規格要求",
      dataIndex: "drawitem",
      align: "left",
      width: "6.5%",
      render: (text) => text && <ShowTooltip title={text} />,
      // width: "3%",
    },

    {
      title: "圖區",
      dataIndex: "drawarea",
      align: "center",
      width: "3%",
      // width: "4%",
    },
    {
      title: "檢驗方法",
      dataIndex: "gpID",
      align: "center",
      render: (text, rowData) => {
        if (!text) return "";
        if (!rowData.gpNM) return text;
        return <ShowTooltip title={`${text}_${rowData.gpNM}`} />;
      },
      width: "5%",
      // width: "4%",
    },
    {
      title: "圖標1",
      dataIndex: "drawmark1",
      align: "center",
      width: "3%",
    },
    {
      title: "圖標2",
      dataIndex: "drawmark2",
      align: "center",
      width: "3%",
    },
    {
      title: "作業群組",
      dataIndex: "inspgroup",
      align: "center",
      width: "4%",
      render: (text) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: "標準值",
      dataIndex: "SL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: "4%",
      render: (text) => text.toFixed(2),
    },
    {
      title: "規格上限",
      dataIndex: "USL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => text.toFixed(2),
    },
    {
      title: "規格下限",
      dataIndex: "LSL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => text.toFixed(2),
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => text.toFixed(2),
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: "4%",
      render: (text) => text.toFixed(2),
    },
    {
      title: "單位",
      dataIndex: "UOM",
      align: "center",
      className: "bg-[#f9e9d7ac]",
      width: "3%",
    },
    {
      title: "工程圖檔",
      dataIndex: "drawing",
      width: "5%",
      align: "center",
      render: (text) => text && <Preview url={text} type="PDF" />,
    },

    {
      title: "有效",
      dataIndex: "isvalid",
      align: "center",
      width: "3.5%",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "4%",
      render: (_, rowData) => {
        const isDrawing =
          rowData.drawing.length > 0 ? (
            <CloseOutlined
              onClick={() => {
                removeDrawing(rowData);
              }}
            />
          ) : (
            <IconFile
              onClick={(e) => {
                set_s_showFileModal(true);
                set_s_editData(rowData);
                e.stopPropagation();
              }}
            />
          );

        return (
          <Space>
            {isDrawing}
            <IconPen
              onClick={(e) => {
                set_s_editData(rowData);
                set_s_isShowModal(true);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          </Space>
        );
      },
    },
  ];

  // 第一次渲染及每次 c_lightData 改變時執行
  useEffect(() => {
    call_getQmsSipTb.request(c_lightData);
    call_getInspreport.request();
  }, [c_lightData]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_deleteQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      call_getQmsSipTb.request(c_lightData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteQmsSipTb.status]);

  useEffect(() => {
    const { status, msg, data } = call_getQmsSipTb;
    if (status === "suc") {
      set_s_tableData([...data?.map((item, index) => ({ ...item, key: index }))] || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getQmsSipTb.status]);

  useEffect(() => {
    const { status, msg } = call_updateQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      call_getQmsSipTb.request(c_lightData);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipTb.status]);

  useEffect(() => {
    if (call_updateQmsSipTbItemno.status === "suc") {
      message.success(call_updateQmsSipTbItemno.msg);
    }
    if (call_updateQmsSipTbItemno.status === "err") {
      message.error(call_updateQmsSipTbItemno.msg);
    }
  }, [call_updateQmsSipTbItemno.status]);

  const searchBasic = [
    <>
      <Select
        className="w-[150px]"
        defaultValue=""
        options={[
          { label: "計數型(定性)", value: "count" },
          { label: "計量型(定量)", value: "measure" },
        ]}
        onChange={(value) => {
          set_s_dataFilter(value);
        }}
      />
      <Button
        disabled={c_lightData.datafm === ""}
        shape="circle"
        type="primary"
        className="bg-[#4AC6F5] disabled:bg-slate-300"
        onClick={() => set_s_batchImportModal(true)}
      >
        <IconBatchImport className="text-white font-bold " />
      </Button>
    </>,
    <AddButton onClick={() => set_s_isShowModal(true)} />,
  ];

  // 配合 Hammer 給客戶 demo, 動態生成 Modal title
  const generateTitle = () => {
    const titleText = c_userData.peopleNM === "superman1" ? "檢驗項目明細" : "檢驗項目明細";
    return <span>{titleText}</span>;
  };

  return (
    <>
      <PageHeader title={generateTitle()} extra={searchBasic} />
      <DragSortingTable
        loading={
          call_deleteQmsSipTb.status === "load" ||
          call_updateQmsSipTb.status === "load" ||
          call_getQmsSipTb.status === "load"
        }
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={
          s_dataFilter === ""
            ? s_tableData
            : s_tableData.filter((item) => item.insptype === s_dataFilter)
        }
        set_s_tableData={set_s_tableData}
        afterSorting={(data) => {
          console.log(data);
          const newSorting = data.map((item, index) => ({
            ...item,
            itemno: index + 1,
          }));
          call_updateQmsSipTbItemno.request(newSorting);
          return newSorting;
        }}
        pagination={false}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}標準檢驗明細`
            : `${t("util.util.add")}標準檢驗明細`
        }
        width={"90%"}
        open={s_isShowModal}
        keyboard={false}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <ModalContainer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
      <CustomModal
        title={"檢驗項目明細設定"}
        width={"50%"}
        style={{ top: 250 }}
        open={s_batchImportModal}
        onCancel={() => set_s_batchImportModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <BatchImport c_lightData={c_lightData} set_s_batchImportModal={set_s_batchImportModal} />
      </CustomModal>

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <FileModal type="sipTb" s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>

      <CustomModal
        title={generateTitle()}
        width="100%"
        open={s_isShowDndModal}
        onCancel={() => set_s_isShowDndModal(false)}
        afterClose={() => {
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <DndModal
          c_lightData={c_lightData}
          tableColumns={tableColumns}
          s_tableData={s_tableData}
          set_s_tableData={set_s_tableData}
          call_getQmsSipTb={call_getQmsSipTb}
        />
      </CustomModal>
    </>
  );
};

export default TB;
