import { Button, Divider, Space, message } from "antd";
import React, { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import { addOpTb, modifyOpTb } from "@/service/apis/ADM/optype";
import { PlusOutlined } from "@ant-design/icons";

import { DataContext } from "../../index";
import SecModalContainer from "./SecModalContainer";

// import { Form, Button, Row, Col, Input, Select, Divider, message } from 'antd';
// import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
const innerWidth = window.innerWidth;

const FirstModalContainer = ({ s_tableData, set_s_showModal }) => {
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
  });

  const { c_lightData } = useContext(DataContext);

  // 不直接使用 s_tableData 是因為在確認送出後才會真的編輯 s_tableData
  const [s_dataSource, set_s_dataSource] = useState([]);

  // 是否開啟編輯作業科目明細跳顯的開關
  const [is_EditSecModal, set_is_EditSecModal] = useState(false);

  // 要被編輯的作業科目明細
  const [s_EditData, set_s_EditData] = useState({});

  // 編輯該筆作業科目明細
  const handleEdit = (rowData) => {
    set_is_EditSecModal(true); // 開啟編輯作業科目明細的跳顯
    set_s_EditData(rowData); // 要被編輯的作業科目明細
  };

  // 刪除該筆作業科目明細
  const handleDelete = (rowData) => {
    const tmpData = s_dataSource
      .filter((item) => item.index !== rowData.index)
      .map((item, index) => {
        return { ...item, index: index };
      });

    set_s_dataSource(tmpData);
  };

  // 新增一筆作業科目明細
  const handleCreate = () => {
    // 直接新增一筆空的當新的作業科目明細
    if (s_dataSource.length > 0) {
      const tmpData1 = s_dataSource.concat([
        {
          "index": 0,
          "optype": c_lightData.optype,
          "opcode": "",
          "opNM": "",
          "postype": null,
        },
      ]);
      const tmpData2 = tmpData1.map((item, index) => {
        return {
          ...item,
          index: index,
        };
      });
      console.log("tmpData2 = ", tmpData2);
      set_s_dataSource(tmpData2);
    } else {
      set_s_dataSource([
        {
          "index": 0,
          "optype": c_lightData.optype,
          "opcode": "",
          "opNM": "",
          "postype": null,
        },
      ]);
    }
  };

  // 確定送出
  const handleSubmit = () => {
    const call_modifyOpTb = async () => {
      console.log("要傳給後端的資料 = ", s_dataSource);

      // 卡控作業科目明細不可為空
      if (s_dataSource.length === 0) {
        message.error("作業科目明細不得為空");
        return;
      }

      // 卡控 作業單別(opcode) 不可重複
      const result = s_dataSource
        .map((item) => item.opcode)
        .filter((element, index, arr) => arr.indexOf(element) !== index);

      if (result.length > 0) {
        message.error("作業單別不可重複");
        return;
      }

      // 都沒問題就call api 更新資料
      const res = await modifyOpTb(s_dataSource);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
    call_modifyOpTb();
  };

  useEffect(() => {
    if (s_tableData.length === 0) return;

    set_s_dataSource(
      s_tableData.map((item, index) => {
        return { ...item, index: index };
      })
    );
  }, []);

  const tableColumns = [
    {
      title: "作業類別",
      dataIndex: "optype",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: "作業單別",
      dataIndex: "opcode",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${300}px`,
    },
    {
      title: "單別名稱",
      dataIndex: "opNM",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${300}px`,
    },
    {
      title: "過帳類型",
      dataIndex: "postype",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
      render: (text, rowData, index) => {
        const map = {
          "1": "借",
          "2": "貸",
          "3": "先借後貸",
        };
        return map[text];
      },
    },
    {
      title: "操作",
      dataIndex: "render",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: "center",
      render: (text, rowData, index) => (
        <Space>
          <IconPen
            onClick={(e) => {
              handleEdit(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              handleDelete(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
        <Button
          onClick={handleCreate}
          type="primary"
          style={{ borderRadius: "5px", marginRight: "0px" }}
          icon={<PlusOutlined />}
          size="large"
        >
          新增
        </Button>
      </div>
      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content", y: 400 }}
        columns={tableColumns}
        dataSource={s_dataSource.map((item, index) => {
          return { ...item, key: index };
        })}
      />
      <Divider style={{ margin: "12px" }} />
      <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "15px" }}>
        <Button
          onClick={handleSubmit}
          type="primary"
          style={{ borderRadius: "5px", marginRight: "0px" }}
          icon={<PlusOutlined />}
          size="large"
        >
          確定送出
        </Button>
      </div>

      {is_EditSecModal ? (
        <CustomModal
          width="60%"
          title="作業科目明細編輯"
          visible={is_EditSecModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_EditSecModal(false)}
        >
          <SecModalContainer
            set_is_EditSecModal={set_is_EditSecModal}
            s_dataSource={s_dataSource}
            set_s_dataSource={set_s_dataSource}
            s_EditData={s_EditData}
            set_s_EditData={set_s_EditData}
          />
        </CustomModal>
      ) : null}
    </div>
  );
};

export default FirstModalContainer;
