import {} from "@ant-design/icons";

import { Button, Col, Form, Row, Space, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
// API
import { uploadInspHd } from "@/service/apis/QMS/meds";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const FileModalContainer = ({ s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  // 上傳料號圖片
  const call_uploadInspHd = useAPI(uploadInspHd);
  const [form] = Form.useForm();
  // 刪除料號圖檔案
  // const call_deleteUploadPN = useAPI(deleteUploadPN);
  // 是否開啟預覽的開關
  const f_dwgNM = Form.useWatch("dwgNM", form);
  const f_ver = Form.useWatch("ver", form);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_file, set_s_file] = useState(null);

  // const [s_editData,set_s_editData]=[useState({ver:null,dwgNM:null})]

  // 上傳檔案時執行
  const handleChange = (props) => {
    const { file, doctype } = props;

    // 新建
    let formData = new FormData();
    formData.append("file", file);
    formData.append("dcUUID", s_editData.dcUUID);
    formData.append("group", s_editData.group);
    formData.append("groupNM", s_editData.groupNM);
    formData.append("PN", s_editData.PN);
    // formData.append("dwgNM", f_dwgNM);
    // formData.append("ver", f_ver);

    set_s_file(formData);
  };
  const onSubmit = async (values) => {
    // if (!values.dwgNM) return;
    // if (values.ver===) return;

    console.log(values);

    s_file.forEach(function (value, key) {
      console.log(key, value);
    });

    call_uploadInspHd.request(s_file);
  };

  const types = ["drawno"];

  useEffect(() => {
    const _data = types.map((item) => {
      const fileList = s_editData[item]?.length > 0 ? [{ url: s_editData[item] }] : [];
      return {
        doctype: item,
        fileList: fileList,
      };
    });
    set_s_tableData(_data);
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_uploadInspHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_uploadInspHd.status]);

  console.log(s_editData);
  return (
    <div className="flex justify-around gap-2 ">
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          ...s_editData,
        }}
      >
        <Row gutter={[16, 0]}>
          {/* <Col span={24}>
            <Form.Item label={"上傳檔名"} name="dwgNM">
              <Input />
            </Form.Item>
          </Col> */}

          {/* <Col span={12}>
            <Form.Item label={"圖版(版本)"} name="ver">
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item label={""} name="upload">
              {s_tableData.map((item) => {
                return (
                  <section key={item.doctype} className="flex flex-col gap-2 w-[100%]">
                    <Dragger
                      key={item.doctype}
                      className=""
                      accept=".pdf"
                      // listType="picture-card"
                      maxCount={1}
                      beforeUpload={() => false}
                      // fileList={item.fileList}
                      // onPreview={handlePreview}
                      onChange={(props) => handleChange({ ...props, doctype: item.doctype })}
                    >
                      {/* {item.fileList.length >= 1 ? null : uploadButton} */}
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading
                        company data or other banned files.
                      </p>
                    </Dragger>
                    {/* <Divider className="my-0" /> */}
                  </section>
                );
              })}
            </Form.Item>
          </Col>
          <Col span={24} className="flex justify-end">
            <Space>
              <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
              <Button type="primary" htmlType="submit">
                {t("util.util.ok")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FileModalContainer;
