import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const HeaderLine = () => {
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const [s_wlID, set_s_wlID] = useState("");

  useEffect(() => {
    const wlID = searchParams.get("wlID") || "";
    set_s_wlID(wlID);
  }, []);

  return (
    <div className="w-full h-full flex-center text-[100px] font-semibold text-gray-500">
      目前工線: {s_wlID}
    </div>
  );
};

export default HeaderLine;
