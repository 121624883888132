import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { addZ1RimWl, updateZ1RimWl } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { getLine } from "@/service/apis/ADM/publicAPI";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addZ1RimWl = useAPI(addZ1RimWl);
  const call_updateZ1RimWl = useAPI(updateZ1RimWl);
  const call_getLine = useAPI(getLine);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addZ1RimWl.request(postData);
    }

    if (type === "edit") {
      call_updateZ1RimWl.request(postData);
    }
  };

  useEffect(() => {
    call_getLine.request();
  }, []);

  useEffect(() => {
    if (call_addZ1RimWl.status === "suc") {
      message.success(call_addZ1RimWl.msg);
      set_is_showModal(false);
    } else if (call_addZ1RimWl.status === "err") {
      message.error(call_addZ1RimWl.msg);
    }
  }, [call_addZ1RimWl.status]);

  useEffect(() => {
    if (call_updateZ1RimWl.status === "suc") {
      message.success(call_updateZ1RimWl.msg);
      set_is_showModal(false);
    } else if (call_updateZ1RimWl.status === "err") {
      message.error(t(`error.error-code.${call_updateZ1RimWl.msg}`));
    }
  }, [call_updateZ1RimWl.status]);

  useEffect(() => {
    if (call_getLine.status === "err") {
      message.error(t(`error.error-code.${call_getLine.msg}`));
    }
  }, [call_getLine.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ wh: 0, ...s_editData }}
    >
      <Form.Item name="wlNM" hidden />
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="wlID" label="機碼" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              optionFilterProp="label"
              showSearch
              options={
                call_getLine?.data?.map((x) => ({
                  label: x.wlID + "_" + x.wlNM,
                  value: x.wlID,
                  data: x,
                })) || []
              }
              onChange={(_, data) => form.setFieldsValue({ wlNM: data.data.wlNM })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wh" label="C/T值(S/PCS)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="divider" label="除數" rules={[{ required: true }]}>
            <InputNumber min={1} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addZ1RimWl.status === "load" || call_updateZ1RimWl.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
