import React from "react";
import MyCard from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/Alert/Card/index";
import { useTranslation } from "react-i18next";
import { IconException } from "@/components/Icon/Navbar";

import styled from "styled-components";
import showDecimalPoint from "../../../../util/showDecimalPoint";
import displayString from "../../../../util/displayString";

const StyledContent = styled.div`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.textColor};

  display: flex;
  flex-direction: column;
  ${(props) => (props.flex ? "flex: 1 1 0%;" : "flex: none; width: 71px; height: 71px;")}
  justify-content: ${(props) => props.justify};
  /* align-items: ${(props) => props.items}; */
  align-items: center;
  padding: 2px;
  border-radius: 8px;
`;

const Text = styled.div`
  font-size: ${(props) => props.size};
  color: ${(props) => props.textColor};
`;

const Content = ({
  bgcolor = "#FFFFFF",
  title,
  value,
  textColor,
  flex,
  justify = "space-around",
}) => {
  return (
    <StyledContent bgcolor={bgcolor} textColor={textColor} flex={flex} justify={justify}>
      <div className="bg-[#2F67A8] w-[90%] rounded flex justify-center items-center">
        <Text size="20px" textColor={textColor}>
          {title}
        </Text>
      </div>

      <Text size="20px" textColor={textColor}>
        {value}
      </Text>
    </StyledContent>
  );
};

const InformationBoard = ({ s_eazythisDrawno }) => {
  const { t } = useTranslation();
  const data = [
    { title: "Cpk", value: 35.2, level: "C+", state: "無缺點考慮降低成本" },
    { title: "Cp", value: 35.2, level: "D+", state: "無缺點考慮降低成本" },
    { title: "Ca", value: 35.2, level: "D+", state: "無缺點考慮降低成本" },
    { title: "Pp", value: 35.2, level: "B+", state: "無缺點考慮降低成本" },
    { title: "Ppk", value: 35.2, level: "E+", state: "無缺點考慮降低成本" },
  ];

  return (
    <div>
      <div className="w-full h-full  bg-[#ffffff] rounded-lg flex flex-col py-[10px]  gap-[10px]">
        {["Cpk", "Cp", "Ca", "Ppk", "Pp"].map((item, index) => {
          return (
            <div className="flex px-[15px] py-[15px] gap-5 justify-around" key={index}>
              <Content
                title={item}
                value={showDecimalPoint(s_eazythisDrawno[item], 2)}
                bgcolor={"#4D8BD3"}
                textColor={"#FFFFFF"}
              />
              <Content
                value={displayString("C+")}
                bgcolor={"#ECF5FF"}
                textColor={"#343434"}
                justify={"center"}
              />
              <Content
                // title={item.title}
                value={displayString("無")}
                bgcolor={"#ECF5FF"}
                textColor={"#343434"}
                justify={"center"}
                flex={true}
              />
              {/* <Content title={item.title} value={item.level} color="#4D8BD3" /> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InformationBoard;
