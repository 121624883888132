import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//----------------------------------------- 取得每日員工產出 ----------------------------------------
export const getDailyStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDailyStaff`, {
    params: data,
  });
};
export const getBearDailyStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBearDailyStaff`, {
    params: data,
  });
};
