// DraggableComponent.js
import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableComponent = ({ id, identifier, children }) => {
  const [, drag] = useDrag({
    type: 'DIV',
    item: { id, identifier },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }), // Pass id and key properties to the item object
  });

  return <div ref={drag}>{children}</div>;
};

export default DraggableComponent;