import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  message,
  Space,
  Radio,
} from "antd";
import { modifyContact, addContact } from "@/service/apis/ADM/connection";

import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addContact = useAPI(addContact);
  const call_modifyContact = useAPI(modifyContact);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addContact.request(values);
    }
    if (type === "edit") {
      call_modifyContact.request(values);
    }
  };

  useEffect(() => {
    if (call_addContact.status === "suc") {
      message.success(call_addContact.msg);
      set_s_isShowModal(false);
    } else if (call_addContact.status === "err") {
      message.error(call_addContact.msg);
    }
  }, [call_addContact.status]);

  useEffect(() => {
    if (call_modifyContact.status === "suc") {
      message.success(call_modifyContact.msg);
      set_s_isShowModal(false);
    } else if (call_modifyContact.status === "err") {
      message.error(call_modifyContact.msg);
    }
  }, [call_modifyContact.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("ADM.trading-pattern.contactitem")}
            rules={[{ required: true }]}
            name="contactitem"
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("ADM.trading-pattern.itemNM")}
            rules={[{ required: true }]}
            name="itemNM"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.trading-pattern.department")} name="department">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.contact")} name="contact">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.tel")} name="tel">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.trading-pattern.mobile")} name="mobile">
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label={t("ADM.util.mail")} name="mail">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("util.util.isvalid")}
            name="isvalid"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("ADM.trading-pattern.addr")} name="addr">
            <Input.TextArea p row={4} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>{" "}
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
