import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const addInspHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInspHd`, data);
};

// 上傳工程圖檔
export const uploadInspHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadInspHd`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 上傳公差符號上傳圖檔
export const uploadQmsSign = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadQmsSign`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getInspHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInspHd`, {
    params: _params,
  });
};

export const updateInspHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInspHd`, data);
};

export const deleteInspHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInspHd`, data);
};

/// Td
export const addInspTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInspTb`, data);
};

export const uploadInspTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadInspTb`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getInspTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInspTb`, {
    params: _params,
  });
};

export const updateInspTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInspTb`, data);
};

export const deleteInspTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInspTb`, data);
};
export const updateInspTbItemno = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInspTbItemno`, data);
};


export const getQmsSign = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSign`, {
    params: _params,
  });
};
export const getUnitSelector = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getUnitSelector`, {
    params: _params,
  });
};

export const addQmsSign = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSign`, data);
};

export const deleteQmsSign = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSign`, data);
};
export const updateQmsSign= (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSign`, data);
};
