import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";

import { addStock, updateStock } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addStock = useAPI(addStock);
  const call_updateStock = useAPI(updateStock);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addStock.request(postData);
    }

    if (type === "edit") {
      call_updateStock.request(postData);
    }
  };

  useEffect(() => {
    if (call_addStock.status === "suc") {
      message.success(call_addStock.msg);
      set_s_isShowModal(false);
    } else if (call_addStock.status === "err") {
      message.error(t(`error.error-code.${call_addStock.msg}`));
    }
  }, [call_addStock.status]);

  useEffect(() => {
    if (call_updateStock.status === "suc") {
      message.success(call_updateStock.msg);
      set_s_isShowModal(false);
    } else if (call_updateStock.status === "err") {
      message.error(t(`error.error-code.${call_updateStock.msg}`));
    }
  }, [call_updateStock.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              ...c_lightData,
              stdstock: 0,
              safestock: 0,
              minstock: 0,
              maxcost: 0,
              stocklife: 0,
              note: "",
            }
          : s_editData
      }
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label={t("util.util.PN")}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="replacePN" label={t("ADM.pn-manage.replacePN")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="newPN" label={t("ADM.pn-manage.newPN")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stdstock" label={t("ADM.pn-manage.stdstock")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="safestock" label={t("ADM.pn-manage.safestock")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stocklife" label={t("ADM.pn-manage.stocklife")}>
            <InputNumber
              className="w-full"
              min={0}
              initialValue={0}
              formatter={(value) => `${value} day`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="minstock" label={t("ADM.pn-manage.minstock")}>
            <InputNumber
              className="w-full"
              min={0}
              initialValue={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="maxcost" label={t("ADM.pn-manage.maxcost")}>
            <InputNumber
              className="w-full"
              min={0}
              initialValue={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
