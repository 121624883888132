import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { getInspmethod } from "@/service/apis/QMS/Instrument";
import { addQmsSipHd, addSpcN, updateQmsSipHd } from "@/service/apis/QMS/QMSManage";
import { getSampleSet } from "@/service/apis/QMS/SampleSet";
import { getInspHd } from "@/service/apis/QMS/meds";
import { getProc } from "@/service/apis/QMS/sampling";

const StyledInputTextArea = styled(Input)`
  .ant-input .ant-input-sm {
    height: 10px !important;
    border-radius: 5px;
  }
`;

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
  s_tableData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const f_siptype = Form.useWatch("siptype", form);
  const f_ctrlspot = Form.useWatch("ctrlspot", form);
  const f_ctrlrequire = Form.useWatch("ctrlrequire", form);
  const f_sampletype = Form.useWatch("sampletype", form);
  const f_ssampletypeForjudgement = Form.useWatch("sampletypeForjudgement", form);
  const f_setvalue1 = Form.useWatch("setvalue1", form);
  const f_sampleproc = Form.useWatch("sampleproc", form);
  const f_PN = Form.useWatch("PN", form);
  // 新增表頭資料
  const call_addQmsSipHd = useAPI(addQmsSipHd);
  // 編輯表頭資料
  const call_updateQmsSipHd = useAPI(updateQmsSipHd);

  const call_getInspHd = useAPI(getInspHd);

  const call_getInspmethod = useAPI(getInspmethod);
  const call_getProc = useAPI(getProc);

  const call_addSpcN = useAPI(addSpcN);

  const call_getSampleSet = useAPI(getSampleSet);

  //看到的人幫忙修一下這個垃圾 現在很忙 cào nǐ mā------
  const newaaaOptions = [...s_arrMap.ctrlrequire];
  newaaaOptions.shift();
  const aaaOptions = [{ value: "A", label: "每次抽樣創建(人工開立檢驗單)" }, ...newaaaOptions];
  //*************************************************************** */
  // 移除引用規格版號
  const datafmOptions = call_getInspHd.data?.map((data, index) => {
    console.log("data?.specID", data?.specID);
    // data?.specID.slice(-4, 4);

    // newLable = data?.specID.length - 4;
    const newLabel = data?.specID.substr(0, data?.specID.length - 4);

    return {
      label: `${newLabel} 版本:${data?.version}`,
      value: data?.specID,
      version: data?.version,
      drawing: data?.drawing,
    };
  });
  const setvalue1Options = call_getSampleSet.data?.map((item) => ({
    label: item.sampleNM,
    value: item.sampleID,
  }));

  const datatoOptions = genDataoOptions();

  function genDataoOptions() {
    if (f_PN === "All") return [];
    return s_tableData
      ?.filter((item) => {
        return item.siptype === "SPC" && item.isvalid && item.PN === f_PN;
      })
      .map((data, index) => {
        return {
          label: `${data?.sipID}`,
          value: data?.sipID,
        };
      });
  }

  const sampleprocOptions = call_getProc.data?.map((data, index) => {
    return {
      label: `${data?.procID}_${data?.procNM}`,
      value: data?.procID,
      data: { ...data },
    };
  });
  // const samplelevelOptions = call_getSampleSet.data?.map((item) => ({
  //   label: item.planNM,
  //   value: item.planID,
  // }));

  console.log("D-1 ==============================================");
  console.log("s_editData =", s_editData);

  const onSubmit = (values) => {
    if (type === "create") {
      call_addQmsSipHd.request(values);
    }
    if (type === "edit") {
      call_updateQmsSipHd.request(values);
    }
  };

  // 遠程搜索料號
  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then((e) => {
        const result =
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || [];

        return [{ value: "All", label: "All" }, ...result];
      })
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    const { msg, status } = call_addSpcN;
    if (status === "suc") {
      message.success(msg);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_addSpcN.status]);
  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipHd.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipHd.status]);

  useEffect(() => {
    if (type === "edit") {
      if (!s_editData?.PN) return;
      call_getInspHd.request({ PN: s_editData?.PN });
      if (s_editData.sampleproc === "07") {
        call_getSampleSet.request({ sampleID: s_editData.setvalue1 });
      }
    }
    call_getInspmethod.request();
    call_getProc.request();
  }, []);
  useEffect(() => {
    if (call_getInspHd.status === "suc") {
      message.success(call_getInspHd.msg);
      // set_s_tableData(
      //   call_getInspHd.data.map((item) => ({ ...item, updtT: dayjs(item.updtT), key: item.dcUUID }))
      // );
    }
    if (call_getInspHd.status === "err") {
      message.error(call_getInspHd.msg);
      // set_s_tableData([]);
    }
  }, [call_getInspHd.status]);

  useEffect(() => {
    const { status, msg, data } = call_getSampleSet;
    if (status === "suc") {
      message.success(msg);
      form.setFieldsValue({
        AQL: data[0]?.AQL,
        samplelevel: data[0]?.samplelevel,
        setvalue1: data[0]?.sampleID,
      });
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getSampleSet.status]);
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        PN: "All",
        ctrlrequire: "A",
        ...s_editData,
        pubdata: s_editData?.pubdata ? dayjs(s_editData.pubdata) : null,
        drawing: type === "edit" && s_editData.drawing ? s_editData.drawing.split("/").pop() : "",
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="sampletype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="sampletypeForjudgement" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          {/* 料號 */}
          <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
            <DebounceSelect
              disabled={type === "edit"}
              // fetchOptions={[{ value: "", label: "All" }, ...fetchPNList]}
              fetchOptions={fetchPNList}
              maxTagCount={10}
              showSearch
              onChange={(value) => {
                form.setFieldsValue({ PN: value.value, pdtNM: value.title });
                call_getInspHd.request({ PN: value.value });
                console.log(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 品名 */}
          <Form.Item label={t("util.util.pdtNM")} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="檢驗型態" name="siptype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              disabled={type === "edit"}
              allowClear
              options={[
                // { label: "首件檢驗(FAI)", value: "FAI" },
                // { label: "量產試做", value: "FAI" },
                // { label: "進料檢驗(IQC)", value: "IQC" },
                // { label: "生產自檢(PSI)", value: "PSI" },
                // { label: "首件檢驗(FAI)", value: "FAI" },
                { label: "製中檢驗(IPQC)", value: "IPQC" },
                // { label: "過站檢驗(SQC)", value: "SQC" },
                // { label: "終品檢驗(FQC)", value: "FQC" },
                // { label: "出貨檢驗(OQC)", value: "OQC" },
                // { label: "儀校標準(ICS)", value: "ICS" },
                // { label: "統計製程管制(SPC)", value: "SPC" },
              ]}
              onChange={(value) => {
                if (value === "SPC") {
                  form.setFieldsValue({
                    inspmethod: null,
                    sampleproc: null,
                    AQL: null,
                    samplelevel: null,
                    datato: null,
                    toversion: null,
                    ctrlspot: "wp",
                  });
                }
                if (value !== "SPC") {
                  form.setFieldsValue({
                    datafm: null,
                    fmversion: null,
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 是否有效 */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="控點" name="ctrlspot" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              disabled={type === "edit" || f_siptype === "SPC"}
              options={[
                { label: "WP全品比對", value: "wp" },
                { label: "WO工單比對", value: "wo" },
                { label: "PF製程比對", value: "pf" },
                { label: "WS工站比對", value: "ws" },
                { label: "WL工線比對", value: "wl" },
                { label: "WC工作中心比對", value: "pwc" },
                { label: "QA品管控制比對", value: "qa", disabled: true },
                { label: "GP通用群組比對", value: "gp" },
                // { label: "現品票比對", value: "cr" },
              ]}
              onChange={() => form.setFieldsValue({ spotID: null })}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="控點ID" name="spotID">
            {f_ctrlspot === "gp" ? (
              <Input placeholder="請定義方法群組名稱" />
            ) : (
              <Select
                disabled={form.getFieldValue("ctrlspot") === "wp" || type === "edit"}
                className="w-full"
                allowClear
                options={s_arrMap[f_ctrlspot]}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="檢驗開單時機" name="ctrlrequire">
            <Select
              disabled={f_siptype === "SPC"}
              allowClear
              options={aaaOptions}
              onChange={(value) => {
                if (value === "B") {
                  form.setFieldsValue({
                    "ctrlCT": 2,
                  });
                } else {
                  form.setFieldsValue({
                    "ctrlCT": 0,
                  });
                }
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="檢驗週期" name="ctrlCT">
            <InputNumber disabled={f_ctrlrequire !== "B"} className="w-full" addonAfter="hr" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="抽樣程序" name="sampleproc">
            <Select
              allowClear
              disabled={f_siptype === "SPC"}
              // options={s_arrMap.ctrlrequire}
              options={sampleprocOptions}
              onChange={(value, allData) => {
                console.log(value);
                console.log(allData.data);

                if (allData.data.sampletype === "9" || allData.data.sampletype === "0") {
                  call_getSampleSet.request({ sampleID: allData.data.setvalue1 });
                  form.setFieldsValue({ sampletypeForjudgement: allData.data.sampletype });
                  return;
                }

                form.setFieldsValue({
                  AQL: null,
                  samplelevel: null,
                  setvalue1: allData.data.setvalue1,
                  sampletype: allData.data.sampletype,
                  sampletypeForjudgement: allData.data.sampletype,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="抽樣條件" name="setvalue1">
            {f_ssampletypeForjudgement === "9" || f_ssampletypeForjudgement === "0" ? (
              <Select disabled options={setvalue1Options} />
            ) : (
              <Input
                allowClear
                addonAfter={["C", "E"].includes(f_sampletype) ? "%" : null}
                // options={sampleprocOptions}
                disabled
                // options={s_arrMap.ctrlrequire}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="抽樣計劃" name="samplelevel">
            <Input allowClear disabled />
            {/* {f_sampleproc === "07" ? (
              // <Select disabled options={samplelevelOptions} />
            ) : (
              <Input allowClear disabled />
            )} */}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="允收水準" name="AQL">
            <Select
              allowClear
              // disabled={f_siptype === "SPC"}
              disabled
              // options={s_arrMap.ctrlrequire}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider className="  bg-gray-700" />
      <Row gutter={[24, 12]}>
        {/* 引用檢驗規格 */}
        <Col span={6}>
          <Form.Item label="引用檢驗規格" name="datafm">
            <Select
              options={datafmOptions}
              onChange={(value, data) => {
                console.log(value);
                console.log(data);
                form.setFieldsValue({
                  fmversion: data?.version,
                  drawing: data?.drawing,
                });
              }}
              disabled={f_ctrlrequire === "D" ? true : false}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="引用版本" name="fmversion">
            <Input
              options={[]}
              // disabled={f_ctrlrequire === "D" || f_siptype === "SPC" ? false : true}
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="導入SPC標準" name="datato">
            <Select
              options={datatoOptions}
              disabled={f_ctrlrequire === "D" || f_siptype === "SPC"}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="導入版本" name="toversion">
            <Input options={[]} disabled />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item name="drawing" label="工程圖檔">
            <Input
              disabled
              suffix={
                <IconGarbage
                  disabled={type !== "edit"}
                  onClick={(e) => {
                    form.setFieldsValue({ drawing: "" });
                  }}
                />
              }
            />
          </Form.Item>
        </Col> */}
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="文件版次" name="docver">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="圖面版次" name="drawver">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="發行日期" name="pubdata">
            <DatePicker format="YYYY-MM-DD" className="w-full" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t("util.util.note")} name="note">
            <StyledInputTextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={4}>
          <CreateFromItem
            afterCopy={() => {
              form.setFieldsValue({ datafm: null, datato: null, drawing: "" });
            }}
            set_s_editData={set_s_editData}
            s_editData={s_editData}
            form={form}
          />
        </Col>
        <Col span={20} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
