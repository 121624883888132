const en_US = {
  "util": {
    "util": {
      "itemID": "Item Number ",
      "itemno": "Item Order",
      "sign_out": "Sign Out ",
      "add": "Add ",
      "edit": "Edit ",
      "delete": "Delete ",
      "search": "Search ",
      "cancel": "Cancel ",
      "close": "Close ",
      "ok": "Confirm ",
      "render": "Select ",
      "check": "Check ",
      "success": "Success ",
      "all": "ALL ",
      "yes": "Yes",
      "no": "No",
      "name": "Name ",
      "total": "Total ",
      "SN": "Sequence ",
      "detail": "Detail ",
      "action": "Operation ",
      "size_big": "Big Size ",
      "size_large": "Large Size ",
      "size_middle": "Middle Size ",
      "size_small": "Small Size ",
      "hide_detail": "Hide Detail File ",
      "del_confirm": "Delete Commit Confirmation ",
      "del_confirm_msg":
        "Please Confirm That The Transaction Data Is Correct Before Confirming The Deletion ",
      "note": "Remark ",
      "no_data": "No Data ",
      "data": "data",
      "header_cancel_create": "Cancel Create Based On",
      "header_create": "Create entry from this",
      "placeholder_select": "Choose ",
      "placeholder_input": "Input ",
      "or": "Or ",
      "order": "Order ",
      "showTotal": "{{total}} Items In Total, Displaying Item {{rangeFirst}} To {{rangeEnd}} ",
      "itemNM": "Item Name",
      "excluding_tax": "(excluding tax)",
      "start": "start",
      "end": "end",
      "wl": "Work Line ",
      "ws": "Work Station ",
      "woN": "woN",
      "situF": "State ",
      "PN": "Part No ",
      "whID": "Warehouse ID",
      "idmtype": "Posting Type ",
      "custID": "Customer ID",
      "custPN": "Customer Part No ",
      "pdtNM": "Product Name ",
      "pdtspec": "Specification ",
      "planqty": "Number Of Projects ",
      "inqty": "Production Quantity ",
      "tqty": "Total ",
      "sqty": "Manual Input ",
      "dqty": "Barcode Scanning ",
      "batchno": "Batch Number",
      "okC": "quantity of good items ",
      "inC": "production quantity ",
      "ngC": "quantity of defects ",
      "lifeF": "Course ",
      "isvalid": "validation ",
      "isfocus": "focused ",
      "dept": "Department ",
      "updtT": "Updated time ",
      "pdtclass": "sell group",
      "mtype": "Machine Type",
      "ver": "version ",
      "qty": "number of BOM",
      "attribute": "attribute ",
      "unit": "UOM",
      "opunit": "UOP",
      "unitwt": "Unit Weight ",
      "setting": "Setting",
      "mgmtcat": "Access Group ",
      "querycat": "Query Group ",
      "drawno": "Drawing No. ",
      "matl": "Material ",
      "moldID": "mold ID",
      "heirPN": "Inherited Item Number",
      "heirdealID": "Inheritance Object",
      "moldC": "Number Of Mold Cycles",
      "moldcavity": "Number Of Mold Cavities",
      "searchPN": "Search PN",
      "dealPN": "Transaction Item Number",
      "edit_detect_msg1": "The system has detected that you have edited the following field",
      "edit_detect_msg2": "Please save the system data before editing the details",
      "msg_chose_data": "Please select the data to be referenced",
      "msg_chose_whichType_toThrow": "Please select the document type to be transferred",
      "msg_chose_atleast_one_Detail": "Please select at least one detail",
      "UploadSignature": "Upload Signature",
      "UploadSign": "Upload Signed Copy",
      "removeSearch": "Clear Search History",
      "setAdvanced": "Advanced Setting",
      "exportExcel": "Export Excel",
      "table_close": "Force Majeure",
      "table_more": "View more",
      "header_next": "Next Record",
      "header_prev": "Previous Record",
      "header_print": "Print",
      "header_return": "Go back to main table",
      "header_save": "Save",
      "header_tour": "Return to navigation",
      "take": "Take",
      "throw": "Transfer",
      "accord": "Accordingly",
      "create": "Create",
      "cancel_Check": "Cancel Signature Return",
      "sysMsg": "System Message",
      "advanceSearch": "Advanced Query",
      "window": "Window",
      "contact": "Contact Person",
      "tel": "Phone Contact",
      "itemno_total": "Total Number Of Items",
      "get_prev_data_suc": "Get The Last Data Successfully",
      "get_next_data_suc": "Get The Next Data Successfully",
      "get_prev_data_err": "Get The Last Data Failed",
      "get_next_data_err": "Get The Next Data Failed",
      "prev_data_errMsg":
        "The Current Information Is The First One, And There Is No Previous Information",
      "next_data_errMsg": "The Current Information Is The Last One, And There Is No Next One",
      "opcode": "Order Type",
      "N": "No.",
      "currencytype": "Currency",
      "taxtype": "Tax Category",
      "taxrate": "Tax Rate",
      "utype": "Unit Type",
      "custalias": "Nick Name",
      "custNM": "Client'S Name",
      "exchangerate": "Exchange Rate",
      "exchangeD": "Exchange Rate Date",
      "confirmID": "Reviewer",
      "lifeFT": "Elapsed Time",
      "createID": "Creator",
      "createT": "Creation Date",
      "confirmT": "Review Date",
      "signT": "Check Back Date",
      "approveT": "Approval Date",
      "updtID": "Update Staff",
      "EDT": "Estimated Delivery Date ",
      "startEDT": "Estimated Delivery Date (From)",
      "endEDT": "Estimated Delivery Date (To Date)",
      "pbom": "Combination Quantity",
      "punit": "Unit",
      "tax": "Total Tax",
      "itemsum": "Single Amount ",
      "itemsumtax": "Individual Tax ",
      "subtotal": "Subtotal Including Tax",
      "batchdate": "Batch Number Date",
      "balqty": "The Remaining Amount",
      "suppID": "Supplier Number",
      "suppalias": "Nick Name",
      "suppNM": "Supplier Names",
      "stockinT": "Transfer time",
      "idmtype_s": "Manual Counting ",
      "idmtype_d": "Barcode Counting ",
      "idmtype_m": "Mixed Count ",
      "attribute_0": "0 Raw Matls",
      "attribute_1": "1 Consumables ",
      "attribute_2": "2 Non-Std Parts ",
      "attribute_3": "3 Standard Parts ",
      "attribute_4": "4 Semi-Manufactures ",
      "attribute_5": "5 Finished Goods ",
      "attribute_6": "6 Customer Offerings ",
      "pdtclass_X": "N/A",
      "pdtclass_M": "Commodity ",
      "pdtclass_A": "Accessory ",
      "pdtclass_P": "Packaging ",
      "packtype_0": "(0)Operational_Packaging_Use of operational units",
      "packtype_1": "(1)Shipping_Use for packaging_use for packaging and shipping",
      "packtype_2": "(2)Turnover Packaging_Use for production line turnover",
      "assetkind": "asset classification ",
      "assetkind_TI": "injection molding",
      "assetkind_TS": "punching",
      "assetkind_TD": "die casting",
      "assetkind_TF": "forging",
      "fileType_SOP": "SOP",
      "fileType_SIP": "SIP",
      "fileType_PACK": "PACK",
      "fileType_DRAW": "DWG",
      "fileType_PIC": "PIC",
      "fileType_ECN": "ECN_工程變更通知",
      "nav_pds_dashboard": "Digital Production Kanban",
      "nav_pds_abc-board": "Exception Management Kanban",
      "nav_pds_overview-lines": "Line Body Operation Summary Table",
      "nav_BDM": "Material Data Management",
      "nav_bdm_pn-manage": "Master Data Maintenance",
      "nav_bdm_edm": "Technical Data Maintenance",
      "nav_EDM": "Equipment Data Management",
      "nav_edm_mold": "Mold Data Maintenance",
      "nav_TDM": "Plant Data Management",
      "nav_tdm_plant-manage": "Factory Master File Setting",
      "nav_tdm_procedure-manage": "Process Planning Maintenance",
      "nav_tdm_production-line-manage": "Production Line Planning and Maintenance",
      "nav_tdm_warehouse-manage": "Warehouse Planning and Maintenance",
      "nav_tdm_string-manage": "Capability string management",
      "nav_tdm_plant-params-manage": "Factory Parameter Settings",
      "nav_SDM": "System Data Management",
      "nav_sdm_user-manage": "Employee Data Maintenance",
      "nav_sdm_company": "Supplier Data Maintenance",
      "nav_sdm_client": "Customer Data Maintenance",
      "nav_sdm_category-manage": "Query Item Settings",
      "nav_sdm_data-auth-manage": "Function access settings",
      "nav_sdm_trading-pattern": "Item Keywords Setting",
      "nav_sdm_function-params-manage": "System Parameter Setting",
      "nav_WMS": "Inventory Management System",
      "nav_wms_plan-in": "Warehouse Storage Operations",
      "nav_wms_plan-out": "Warehouse Outbound Operations",
      "nav_wms_stock-search": "Inventory Check",
      "nav_wms_inv-history": "Monthly Inventory Record",
      "nav_wms_pro-instock": "Agent Inbound Operation",
      "nav_PMS": "Purchasing Management System",
      "nav_pms_plan": "Quotation plan",
      "nav_pms_req": "Requisition Job",
      "nav_pms_pro": "Procurement",
      "nav_pms_instock": "Incoming Operation",
      "nav_pms_gostock": "Incoming Inspection Query",
      "nav_pms_return": "Incoming Returns Operation",
      "nav_pms_ap-manage": "Accounts Payable Management",
      "nav_pms_report": "Report querying",
      "nav_pms_report-unpurchase": "Purchase outstanding details report",
      "nav_pms_report-diff": "採購價格調幅表",
      "nav_pms_report-instocksummary": "庫存彙總表",
      "nav_pms_report-inventorydiff": "庫存異動明細帳",
      "nav_pms_report-psisummary": "進銷存彙總表",
      "nav_pms_report-purchasesummary": "進退貨彙總表",
      "nav_pms_report-purchaselist": "進退貨明細表",
      "nav_pms_report-purchasestatistics": "進退貨統計表",
      "nav_OMS": "Sales Management System",
      "nav_oms_quo": "Quotation Job",
      "nav_oms_fod": "Book Homework",
      "nav_oms_ord": "Sales Operation",
      "nav_oms_outstock": "Shipping Job",
      "nav_oms_check": "Shipping Inspection",
      "nav_oms_return": "Sales Return Operation",
      "nav_oms_ap-manage": "Accounts Receivable Management",
      "nav_oms_report": "Report querying ",
      "nav_oms_report-unshipped": "訂單未出明細表",
      "nav_oms_report-salesummary": "銷退貨彙總表",
      "nav_oms_report-salelist": "銷退貨明細表",
    },
  },
  "error": {
    "error-code": {
      "403": "無此權限",
      "404": "查無資料",
      "409": "資料重複",
      "500": "操作失敗",
    },
  },
  "WOS": {
    "util": {},
    "login": {
      "pageTitle": "Login Account",
    },
    "mc": {
      "pageTitle": "Material Work",
    },
    "parts-binding": {
      "pageTitle": "Parts Scan Code",
    },
    "ppidp": {
      "pageTitle": "Finished Product Binding",
    },
    "qc": {},
    "tk": {
      "pageTitle": "Production Kanban",
    },
    "wl-woc": {
      "pageTitle": "Production Control",
    },
    "wpc-search": {
      "pageTitle": "Waybill Query",
    },
  },
  "IMS": {
    "util": {
      "lifeF_0": "Created",
      "lifeF_1": "In Execution",
      "lifeF_2": "Submitted",
      "lifeF_A": "All ",
      "lifeF_T": "Already Changed",
      "opcode_4110": "Production Storage",
      "opcode_4120": "Purchase Storage",
      "opcode_4130": "Sales Return",
      "opcode_4141": "Warehouse Transfer",
      "opcode_4142": "Treasury Borrowing",
      "opcode_4143": "Loan Return",
      "opcode_4160": "Production Returns",
      "opcode_4010": "Accounting",
      "opcode_4020": "Cycle Count",
      "opcode_4030": "Project Inventory",
      "opcode_4210": "生產領用",
      "opcode_4260": "生產超領",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4243": "借入歸還",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "來源單號",
      "preN_4120": "來源單號",
      "preN_4130": "來源單號",
      "preN_4220": "來源單號",
      "preN_4230": "來源單號",
      "preN_4141": "來源單號",
      "preN_4241": "來源單號",
      "preN_4242": "來源單號",
      "preN_4143": "來源單號",
      "preN_4142": "來源單號",
      "preN_4243": "來源單號",
      "preN_4210": "來源單號",
      "preN_4160": "來源單號",
      "preN_4260": "來源單號",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "operN_4299": "領用單號",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "dueday_4299": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "batchno_4299": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "Transaction Submission Confirmation",
      "txn_confirm_msg":
        "Please Confirm That The Transaction Data Is Correct Before Confirming The Deletion",
      "opcode": "Job Order",
      "jobN": "Job Order Number",
      "planqty": "Number Of Projects",
      "realtqty": "The Actual Amount",
      "bal": "Account Difference",
    },
    "plan-in": {
      "pageHeader": "Warehouse Storage Operations",
    },
    "plan-out": {
      "pageHeader": "Warehouse Outbound Operations",
    },
    "stock-search": {
      "searchBatchno": "Batch Number Inquiry",
      "pageHeader": "Inventory Check",
      "searchPN": "Material Number Query",
      "searchWh": "Warehouse Query",
      "whDetails": "Warehouse Details",
      "changeDetails": "Transaction Details",
      "borrow": "Borrow",
      "inTransit": "In Transit",
      "loan": "Loan",
      "lockqty": "Blocked Number",
      "onhandqty": "Effective Inventory",
      "planin": "Inbound Planning",
      "planout": "Outbound Planning",
      "posted": "Posted",
      "scanin": "Ordering And Warehousing",
      "scanout": "Pick Out",
      "timeSeries": "Time Series",
      "total": "Estimated Availability",
      "totalIn": "Total Storage",
      "totalOut": "Total Delivery",
      "tqty": "Inventory",
    },
    "inv-history": {
      "pageHeader": "Monthly Inventory Record",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "Thick",
      "inCBM": "進\n(CBM)",
      "l": "Long",
      "level": "Grade",
      "suppalias": "Supplier",
      "w": "Width",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "代理入庫作業",
      "lifeF_T": "已規劃",
      "wmsopUUID": "庫儲作業單號",
      "inchargeID": "負責人員",
    },
  },
  "ADM": {
    "util": {
      "pfNM": "Process Name",
      "pushlayer": "Push Level",
      "pf": "Process",
      "pfID": "Process Number",
      "pwc": "Work Center",
      "wpcSearch": "Waybill Query",
      "dispachManage": "Dispatch Management",
      "ppidp": "Finished Product Binding",
      "partsBinding": "Parts Scan Code",
      "mail": "Mail",
      "postID": "Job Title",
      "sectID": "工類",
      "sectID_C": "間接人力",
      "sectID_D": "直接人力",
    },
    "oem-manage": {
      "type": "外包類別",
      "oem_item_manage": "委外項目設定",
      "oem_item": "委外項目",
      "type_A": "人力",
      "type_B": "表處",
      "type_C": "機工",
      "oem_supp_manage": "委外供應維護",
      "oem_supp": "委外供應",
      "vendorcode": "供應代碼",
      "oemitemNM": "外包項目名稱",
    },
    "function-params-manage": {
      "pageHeader": "System Parameter Setting",
      "searchPlaceholder": "Search ID, unit, code, function code, and level name",
      "functions": "Pushing Group",
      "unitNM1": "Unit Name",
      "type": "Measurement Type",
      "category": "Measurement Category",
      "countType": "Count Type",
      "quantitative": "Quantitative",
      "count": "計數",
      "weight": "weight",
      "length": "length",
      "capacity": "capacity",
      "area": "area",
      "volume": "volume",
      "sort": "To Sort",
      "currencyitem": "Currency Code",
      "itemNM": "Currency Name",
      "currency": "Currency",
      "ismycurrency": "Local Currency",
      "funcode": "Organization Function Code",
      "funNM": "Function Name",
      "function": "Push-Group Setting",
      "lvlcode": "Level Code",
      "lvlNM": "Hierarchy Name",
      "team": "1 (Team)",
      "class": "2 (Class Level)",
      "management": "3 (Management Level)",
      "total": "4 (Total Level)",
      "eventID": "Event Id",
      "eventNM": "Event Name",
      "rptcode": "Submission Category",
      "pusher": "Sending System",
      "situF": "Warning Light",
      "audience": "Push Audience",
      "startlvl": "Starting Level",
      "setlvl": "Push Series",
      "cruisebot": "Monitoring Program",
      "msgmodel": "Push Message Template",
      "pushEvent": "Pushing Event",
      "rptcode_A0": "Personnel Report",
      "rptcode_B0": "System Notification (Wos)",
      "rptcode_C0": "Equipment Report",
      "rptcode_D0": "Management Report_Remote",
      "rptcode_E0": "Management Report_Cloud",
      "situF_Y": "Yellow",
      "situF_R": "Red",
      "startlvl_0": "Full Staff",
      "startlvl_1": "Team Cadres",
      "startlvl_2": "Class Cadres",
      "startlvl_3": "Manager",
      "startlvl_4": "Chief Supervisor",
      "add_string": "Add String",
      "add_number": "Add Number",
      "add_point": "Add decimal point",
      "1_decimal_place": "One decimal places",
      "2_decimal_place": "Two decimal places",
    },
    "procedure-manage": {
      "pageHeader": "Process Planning Maintenance",
      "pccID": "Cost Center Number",
      "pccNM": "Cost Center Name",
      "pwcID": "Work Center Number",
      "pwcNM": "Work Center Name",
      "tabCost": "Cost Center Setting",
      "tabFlow": "Process Master File Setting",
      "tabWork": "Work Center Settings",
    },
    "user-manage": {
      "dashboard": "Digital Production Kanban",
      "abc-board": "Exception Management Kanban",
      "BDM": "Material Data Management",
      "SDM": "System Data Management",
      "TDM": "Plant Data Management",
      "EDM": "Equipment Data Management",
      "WMS": "Inventory Management System",
      "OMS": "Sales Management System",
      "PMS": "Purchasing Management System",
      "WOC": "Production Control",
      "MC": "Material Work",
      "QC": "Quality Control Work",
      "TK": "Production Kanban",
      "account": "Staff Account",
      "pwd": "Password",
      "role": "Role Permissions",
      "roleID": "Role ID",
      "roleNM": "Role Name",
      "systemAuth": "System Login Authority",
      "autoReport": "Receive Automatic Reports",
      "APP": "Mobile APP",
      "PDA": "PDA Permission",
      "dealmgmt": "Object access group",
      "pdtmgmt": "Material access group",
      "whmgmt": "Warehouse access group",
      "cardID": "Card Number",
      "function": "Organizational Function",
      "pageHeader": "Employee Data Maintenance",
      "peopleNM": "Employee'S Name",
      "personalID": "Id Number",
      "proxyNM": "Contact Name",
      "proxytel": "Contact Number",
      "staffAuth": "Role permission setting",
      "staffData": "Employee Data Management",
      "staffID": "Employee Number",
    },
    "trading-pattern": {
      "OMS": "Order System",
      "PMS": "Purchasing System",
      "adda": "Terms And Conditions",
      "addb": "Additional Deduction",
      "addc": "Prepaid Assignment",
      "addd": "Payment Method",
      "adde": "Terms Of Trade",
      "addf": "Return Operation",
      "addg": "Cancel Order",
      "addh": "Payment Terms",
      "addi": "Payment terms",
      "additem": "Item Number",
      "addr": "Contact Address",
      "addtype": "Transaction Terms",
      "contactitem": "Contact Window Code",
      "department": "Department",
      "itemNM": "Contact Window Name",
      "mobile": "Mobile Phone",
      "pageHeader": "Item Keywords Setting",
      "tabAdded": "Additional Item Settings",
      "tabWindow": "External Window Settings",
      "tabPMSAdded": "Purchase Additional Terms And Conditions",
      "tabOMSAdded": "Sales Additional Terms And Conditions",
    },
    "data-auth-manage": {
      "mgmtitem": "Category Item",
      "pageHeader": "Function access settings",
      "tabCust": "Customer access group",
      "tabPdt": "Material access group",
      "tabSupp": "Factory Capital access group",
      "tabWh": "Warehouse access group",
    },
    "category-manage": {
      "mgmtitem": "Category Item",
      "pageHeader": "Query Item Settings",
      "tabCust": "Customer Query",
      "tabQuery": "Materials Query",
      "tabSupp": "Supplier Query",
      "tabMtype": "Material model classification",
    },
    "warehouse-manage": {
      "cancelClose": "Cancellation",
      "close": "Close Account",
      "isClose": "Whether To Close The Account",
      "mgmtcat": "Warehouse Authorization",
      "pageHeader": "Warehouse Planning and Maintenance",
      "pdtclass": "BOM level",
      "ps": "Workshop",
      "tabWareHouse": "Warehouse Data Setting",
      "whID": "Warehouse ID",
      "whNM": "Warehouse Name",
      "psNM": "Workshop Name",
    },
    "string-manage": {
      "pageHeader": "Capability string management",
      "tabPause": "shutdown project",
      "tabOkItem": "good product",
      "tabNgItem": "Defective item",
      "tabDri": "Responsible unit",
      "tabOffLine": "offline project",
      "driItemID": "Unit ID",
      "driItemNM": "company name",
    },
    "client": {
      "groupalias": "Nick Name",
      "location": "Country",
      "account": "Bank Account",
      "addr": "Company Address",
      "bankAddrOS": "Bank Address",
      "bankCodeOS": "Branch Code",
      "branchOS": "Branch Name",
      "classificationOfRestrictions": "Classification Of Restrictions",
      "country": "Country",
      "customerBasicInformation": "Customer Basic Information",
      "customerContactInformation": "Customer Contact Information",
      "department": "Department",
      "domesticBankName": "Domestic Bank Name",
      "domestic_OverseasBankInformation": "Domestic/Overseas Bank Information",
      "item": "10 Items/Page",
      "itemNM": "Window Function",
      "maxcredit": "Credit Terms",
      "mobile": "Mobile Phone",
      "overseasBankName": "Overseas Bank Name",
      "pageHeader": "Customer Data Maintenance",
      "pageHeader2": "Customer Profile Management",
      "payterm": "Payment Terms",
      "showTotal": "1 Items In Total, Displaying Item 1 To 1",
      "swiftCode": "Swift Code",
      "taxID": "Tax ID",
      "username": "Account Name",
    },
    "company": {
      "manufacturerBasicInformation": "Supplier Basic Information",
      "manufacturerContactInformation": "Supplier Contact Information",
      "pageHeader": "Supplier Data Maintenance",
      "groupalias": "Group abbreviation",
      "location": "Country",
      "account": "Bank Account",
      "addr": "Company Address",
      "bankAddrOS": "Bank Address",
      "bankCodeOS": "Branch Code",
      "branchOS": "Branch Name",
      "classificationOfRestrictions": "Classification Of Restrictions",
      "country": "Country",
      "department": "Department",
      "domesticBankName": "Domestic Bank Name",
      "domestic_OverseasBankInformation": "Domestic/Overseas Bank Information",
      "item": "10 Items/Page",
      "itemNM": "Window Function",
      "maxcredit": "Credit Terms",
      "mobile": "Mobile Phone",
      "overseasBankName": "Overseas Bank Name",
      "payterm": "Payment Terms",
      "showTotal": "1 Items In Total, Displaying Item 1 To 1",
      "swiftCode": "Swift Code",
      "taxID": "Tax ID",
      "username": "Account Name",
    },
    "edm": {
      "alertspeed": "Production Rate Alarm",
      "alertstop": "Over-Stop Alarm",
      "warnyield": "Yield Warnning",
      "alertyield": "Yield Alarm",
      "attribute": "Material Attribute",
      "bom": "BOM maintenance",
      "errorPleaseFull": "Please Fill In The Complete Information",
      "ieUUID": "Bill Of Exchange Number",
      "isrework": "Whether To Rework The Route",
      "level": "BOM level",
      "nextSN": "Second Trip Number",
      "pPN": "Parent Item Number",
      "pageHeader": "Technical Data Maintenance",
      "pdtclass": "sell group",
      "pfNM": "Process",
      "planhead": "Planned Number Of People On Duty",
      "ppdtNM": "Parent Product Name",
      "productionScheduleDetails": "Process routing details",
      "qty": "Number of BOM",
      "stdlt": "Production Lead Time",
      "stdmh": "Standard Machine Time",
      "stdwh": "Standard Working Hours",
      "batchbom": "Batch number full package",
      "ver": "Version",
      "wpcManage": "Process routing maintenance",
      "wpc": "生產途程",
      "wpcSN": "Journey Number",
      "wpcSetting": "Journey Number Setting",
      "wpcset": "Travel Group",
      "wpcsetNM": "Note For Journey Group",
      "whID": "Receiving warehouse",
      "alternative_wpc": "Alternative process",
      "main_wpc": "Main process",
      "edit_all_wpc": "Process global editing",
      "Pfc": "Project File Version Control",
      "doc": "Standard Document Issue",
      "ECN": "Engineering Change Notification",
      "pdftype": "File Type",
      "docUpload": "File Upload",
      "Upload": "Upload",
      "docName": "File Name",
      "docVer": "File Version",
    },
    "pn-manage": {
      "bom": "單位轉換",
      "basicProduct": "Basic Product Information",
      "dealPNSearch": "Transaction Number Query",
      "boxH": "Package Size (H)",
      "boxL": "Package Size (L)",
      "boxW": "Package Size (Width)",
      "dealID": "Object Number",
      "dealNM": "Trade Name",
      "dealPN": "Transaction Item Number/Sku",
      "dealalias": "Nick Name",
      "dealpid": "Barcode",
      "idkw": "Packaging Barcode Key Words",
      "inititalcost": "Initial Schedule",
      "isfocus": "isfocus",
      "isvalid": "validation",
      "maxcost": "Inventory Amount Limit",
      "maxstock": "Inventory Limit",
      "minstock": "Inventory Lower Limit",
      "moq": "Moq",
      "newPN": "New Version Number",
      "nextpacksn": "Layers Of Outer Packaging",
      "pack": "Packaging Unit",
      "packsn": "Packing Layers",
      "packtype": "Packaging Purpose",
      "pageHeader": "Master Data  Maintenance",
      "palH": "Pallet (High)",
      "palL": "Pallet (Long)",
      "palW": "Pallet (Width)",
      "pbom": "Package Inner Group Number",
      "pdtH": "Product Dimensions (H)",
      "pdtL": "Product Size (L)",
      "pdtW": "Product Size (Width)",
      "punit": "Inner Unit",
      "purchasecost": "Current Purchase Cost",
      "purchaseqty": "Feed Amount In This Period",
      "replacePN": "Alternate Part Number",
      "safestock": "Safety Stock",
      "salecurrency": "Price Currency",
      "setbatch": "Use Lot Number",
      "setidm": "Barcode Setting",
      "setpack": "Package Settings",
      "settlemonth": "Change Billing Month",
      "shipV": "Packaging Volume Cuft",
      "shipW": "Shipping Weight",
      "sizeunit": "Measurement Unit",
      "stdcost": "Standard Cost",
      "stdsale": "Standard Price",
      "stdstock": "Standard Stock",
      "stocklife": "Inventory Cycle",
      "tabCost": "Standard Cost Setting",
      "tabDeal": "Transaction Number Setting",
      "tabExtra": "Product Size Setting",
      "tabIdkw": "Production Binding Settings",
      "tabPack": "Packaging Binding Settings",
      "tabPrice": "Standard Price Setting",
      "tabStock": "Inventory Condition Setting",
      "unitwt": "unit  weight",
      "varcost": "Variable Costs",
      "ver": "Packaging Standard Version",
      "weightunit": "Unit Of Weight",
    },
    "mold": {
      "pageHeader": "Mold Data Maintenance",
      "mold": "Mold information",
      "assetkind": "Mold type",
      "assetID": "Mold No.",
      "assetNM": "Asset Name",
      "moldtype": "Moldtype",
      "cavity": "Real Cavity",
      "stdcavity": "Standard Cavity",
      "validcavity": "Valid Cavity",
      "wsID": "Work Station",
      "property": "Ownership",
      "company": "Company",
      "gatesystem": "Gate Type",
      "moldlife": "Design Life",
      "draw": "Mold drawing",
      "pdtpic": "Finished photo",
      "malepic": "Male model photos",
      "femalepic": "Master model photo",
      "wholepic": "Mold photo",
      "advance": "Mold advanced information",
      "length": "Length",
      "width": "Width",
      "height": "Height",
      "weight": "Weight",
      "openC": "Beginning Mode",
      "openT": "Get Date",
      "cummoldC": "Final Cumulative Modulus",
      "precummoldC": "Last Cumulative Modulus",
      "status": "Status",
      "note": "Note",
    },
    "plant-manage": {
      "pageHeader": "Factory Master File Setting",
      "plantID": "Site",
      "deptID": "Department",
      "push1": "First-Level Broadcast Time Interval (Minutes)",
      "push2": "Secondary Push Time Interval (Minutes)",
      "push3": "Three-Level Push Time Interval (Minutes)",
      "tabDept": "Department Master File",
      "tabPlant": "Factory Master File",
      "tabPost": "Title Master File",
    },
    "production-line-manage": {
      "MC": "Material Handling",
      "QC": "Quality Control Operation",
      "TK": "Production Dashboard",
      "WOC": "Production Control",
      "auth_setting": "Authorization Settings",
      "batch": "Lot Management",
      "mold": "Mold Management",
      "oem": "Outsourcing Reporting",
      "pageHeader": "Production Line Planning and Maintenance",
      "pcc": "Cost Center",
      "ps": "Workshop",
      "psID": "Workshop Id",
      "psNM": "Workshop Name",
      "tabLine": "Line Data Setting",
      "tabPS": "Workshop Data Setting",
      "tabStation": "Workstation Data Settings",
      "tbHeader_PS": "Workstation Data",
      "tbHeader_Station": "Workstation Data",
      "whm": "Time Management",
      "wlID": "Line Id",
      "wlNM": "Line Name",
      "ws": "Workstation",
      "wsID": "Workstation Id",
      "wsNM": "Station Name",
    },
  },
  "APS": {
    "util": {
      "opcode": "作業單別",
      "opcode_3310": "正常工單",
      "opcode_3320": "重工工單",
      "opcode_3330": "試產工單",
      "opcode_3340": "工程工單",
      "duestart": "預計完成起日",
      "dueend": "預計完成迄日",
      "realqty": "實際數量",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "生管派單作業",
      "wo": "工單",
      "tabWo": "工單主檔",
      "mo": "料單",
      "jobN": "工單號",
      "tabMo": "料單主檔",
      "orderN": "客戶訂購單號",
      "ETDstart": "預計交貨起日",
      "ETDend": "預計交貨迄日",
      "issueID": "發佈人員",
      "issueday": "發佈日期",
    },
  },
  "PMS": {
    "util": {
      "opcode_2010": "Inquiry Planning",
      "opcode_2210": "Domestic Requisition ",
      "opcode_2220": "Foreign Requisition ",
      "opcode_2230": "Outsourcing Application ",
      "opcode_2310": "Domestic Purchase",
      "opcode_2320": "Foreign Purchase",
      "opcode_2330": "Outsourcing",
      "opcode_2410": "Domestic Incoming",
      "opcode_2420": "Foreign Incoming",
      "opcode_2430": "Outsourcing",
      "opcode_2610": "Warehouse Exit",
      "opcode_2620": "Quality Assurance Withdrawal",
      "opcode_2710": " Purchase of materials",
      "opcode_2810": "Offsetting Payable",
      "opcode_4120": "Acceptance Receipt",
      "opcode_4230": "Check Out Form",
      "plan": "RFQ",
      "req": "Requisitions",
      "pro": "Purchase Order ",
      "instock": "Bill Of Material",
      "gostock": "Incoming Inspection List",
      "return": "Incoming Returns",
      "pay": "Accounts payable inquiry",
      "ap": "Reconciliation Job",
      "strike": "Strike A Balance",
      "N22": "Requisition Number",
      "N23": "Purchase Order No",
      "N24": "Bill Of Material No.",
      "N27": "Statement Number",
      "N28": "Billing Number",
      "outN": "Refund Order Number",
      "planSearch": "Inquiry Record Inquiry ",
      "reqSearch": "Purchase Requisition Record Query",
      "proSearch": "Purchasing Record Inquiry ",
      "instockSearch": "Feed Record Inquiry",
      "stockSearch": "Acceptance Record Query",
      "strikeSearch": "Reversal Record Inquiry",
      "apSearch": "Reconciliation Data Inquiry",
      "drawno": "Drawing Number",
      "unitprice": "採購單價",
      "localprice": "Local Currency Unit Price",
    },
    "plan": {
      "pageHeader": "詢價計畫",
      "hd_delete_confirm_text": "請先確認要刪除的詢價單資料無誤，再進行刪除",
      "demandqty": "需求數量",
      "tb": "計畫明細",
      "tb_drawer_title": "詢價計畫明細",
      "rec_drawer_title": "規格評估",
    },
    "req": {
      "pageHeader": "Requisition Job ",
      "create_header": "Create New Requisition Master File ",
      "edit_header": "Edit Requisition Master File ",
      "hd_note": "Note",
      "driID": "Responsible For Requisition ",
      "punit": "Unit",
      "unitprice": "Unit Price (Tax Excluded)",
      "demandqty": "Requisition Quantity ",
      "demandDate": "Demand Date ",
      "itemtotal": "Total Untaxed Amount ",
      "local_subtotal": "本幣含稅總金額",
      "total": "Total Amount Including Tax (Transaction Currency)",
      "lifeF_0": "Release ",
      "lifeF_2": "Purchase Review ",
      "lifeF_7": "Review And Signing Completed ",
      "lifeF_T": "Close",
      "del_hd_confirm_text": "請先確認要刪除的請購單資料無誤，再進行刪除",
      "audit_start": "Review The Official Signature ",
      "audit_finish": "Review Completed ",
      "reqCheck": "請購單覆核",
      "cancel_reqCheck": "取消請購覆核",
      "check_confirm": "Please Purchase Review Confirmation ",
      "check_confirm_text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review ",
      "cancel_check_confirm": "Cancel Requisition Review Confirmation ",
      "cancel_check_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Review ",
      "reqCheck_lifeF_0_okText": "Confirm Review ",
      "reqCheck_lifeF_2_okText": "Cancel Review ",
      "audit_confirm": "Review And Sign Off ",
      "audit_confirm_text":
        "This Purchase Requisition Cannot Be Edited After Review And Approval. Are You Sure You Want To Complete The Review?",
      "audit_okText": "Ok Review ",
      "driID_placeholder": "Please Select The Purchaser ",
      "demandqty_placeholder": "Please Fill In The Requisition Quantity ",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax ",
      "tb_delete_confirm": "Purchase Requisition Details Delete Confirmation ",
      "tb_title": "Purchase Details ",
      "create_tb_title": "Add Purchase Requisition Details",
      "edit_tb_title": "Edit Requisition Details ",
      "throw_modal_submit": "Confirm The Transfer [Purchase Order] ",
    },
    "pro": {
      "pageHeader": "Procurement",
      "create_header": "New Purchase Master File",
      "edit_header": "Edit Purchase Master File",
      "hd_drawer_title": "Purchase Order Master",
      "driID": "Responsible Procurement",
      "sampleID": "抽樣人員",
      "supp_history": "Supplier Transaction History Query",
      "trade": "Terms Of Trade",
      "dealaddr": "Supplier Address",
      "contact": "Supplier Delivery Window",
      "dealcontact": "Supplier Business Window",
      "payment": "Payment Method",
      "sumtotal": "Total Untaxed Amount",
      "local_subtotal": "Total Amount Including Tax In Local Currency",
      "total": "Total Amount Including Tax",
      "local_total": "Total Amount Including Tax (Transaction Currency)",
      "note": "Note",
      "demandqty": "Purchase Quantity",
      "punit": "Unit",
      "confirmqty": "The Supplier Confirms The Quantity",
      "dealN": "Supplier's Order Number",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "charge": "Additional Deduction",
      "ReviewProcess": "Review Process",
      "tb_drawer_title": "Purchase Order Details",
      "lifeF_0": "Release",
      "lifeF_2": "Procurement Review",
      "lifeF_7": "Supplier Sign Back",
      "lifeF_T": "Close",
      "del_hd_confirm_text":
        "Please Confirm That The Purchase Order Information To Be Deleted Is Correct Before Deleting",
      "hd_close_confirm_text":
        "Please Confirm That The Information On The Purchase Order To Be Closed Is Correct Before Proceeding To Force The Order To Be Closed",
      "proT": "採購日期",
      "dealN_placeholder": "請輸入廠商單號",
      "payment_placeholder": "請選擇付款方式",
      "trade_placeholder": "請選擇貿易條件",
      "dealcontact_placeholder": "選擇廠商業務窗口",
      "contact_placeholder": "選擇廠商交貨窗口",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax",
      "demandqty_placeholder": "Please Fill In The Purchase Quantity",
      "note_placeholder": "Please Fill In The Note ",
      "statement_placeholder": "Please Enter A Project Description",
      "amount_placeholder": "Please Enter The Amount",
      "proCheck": "採購覆核(1)",
      "cancel_proCheck": "取消覆核",
      "suppCheck": "廠商簽回(2)",
      "proCheck_confirm": "採購覆核確認",
      "proCheck_confirm_text": "請先確認要採購覆核的資料無誤，再進行採購覆核",
      "cancel_proCheck_confirm_text":
        "Please Confirm That The Information You Want To Cancel The Purchase Review Is Correct, And Then Cancel The Purchase Review",
      "suppCheck_confirm": "廠商簽回確認",
      "suppCheck_confirm_text": "請先確認要簽回的資料無誤，再進行簽回",
      "cancel_suppCheck_confirm": "Cancel The Supplier's Sign-Back Confirmation",
      "cancel_suppCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "tb": "Purchase Details",
      "adda": "Terms And Conditions",
      "adda_delete_confirm_text": "請先確認要刪除的條款資料無誤，再進行刪除",
      "addb": "加扣款項",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc": "Prepaid Assignment",
      "addc_delete_confirm_text": "請先確認要刪除的預付作業無誤，再進行刪除",
      "statement": "Project Instruction",
      "amount": "Untaxed Amount",
      "selectstatement": "Please Select The Deduction Payment",
      "totalStart": "Minimum Value",
      "totalEnd": "Maximum Value",
      "deal_history": "Product Transaction History Query",
      "transferqty": "Transfered Quantity",
      "throwqty": "The Number Of Transfer This Time",
      "message_transfer_small_than_0": "拋轉數量不可小於0",
      "exit_Disposal": "Exit Disposal",
    },
    "instock": {
      "pageHeader": "Incoming Operation",
      "create_header": "新建進料主檔",
      "edit_header": "編輯進料主檔",
      "hd_drawer_title": "Bill Of Material Master File",
      "sampleID": "Sampling Staff",
      "sampleT": "Sampling Date",
      "note": "Notes",
      "del_hd_confirm_text":
        "Please Confirm That The Data Of The Bill Of Materials To Be Deleted Is Correct Before Deleting",
      "tb": "Incoming Details",
      "goqty": "Acceptance Quantity",
      "rejectqty": "Quantity",
      "indqty": "Suspended Quantity",
      "inspectID": "Inspection Personnel",
      "inspectT": "Completion Time",
      "qalID": "QC Staff",
      "qalT": "Release Time",
      "lifeF_0": "Release",
      "lifeF_2": "進料覆核",
      "lifeF_3": "Iqc Sampling Test",
      "lifeF_4": "Iqc Inspection",
      "lifeF_7": "Quality Assurance Review",
      "lifeF_T": "結案",
      "IQCstart": "Incoming Inspection And Sampling",
      "QAcheck": "Quality Assurance Release",
      "punit": "Unit Of Use",
      "inbatchno": "進料批號",
      "judgeT": "Quality Assurance Judgment Date",
      "instockCheck_confirm": "Incoming Material Review And Confirmation",
      "instockCheck_confirm_text": "請先確認要進料覆核的資料無誤，再進行進料覆核",
      "cancel_instockCheck_confirm_text": "請先確認要取消進料覆核的資料無誤，再進行取消進料覆核",
      "IQCcheck_confirm": "進檢抽樣確認",
      "IQCcheck_confirm_text": "請先確認要進檢抽樣的資料無誤，再進行進檢抽樣",
      "cancel_IQCcheck_confirm_text": "請先確認要取消進檢抽樣的資料無誤，再進行取消進檢抽樣",
      "IQCfinish_confirm": "IQC完驗確認",
      "IQCfinish_confirm_text": "請先確認要IQC完驗的資料無誤，再進行IQC完驗",
      "cancel_IQCfinish_confirm_text": "請先確認要取消IQC完驗的資料無誤，再進行取消IQC完驗",
      "QAcheck_confirm": "品保放行確認",
      "QAcheck_confirm_text": "請先確認要品保放行的資料無誤，再進行品保放行",
      "cancel_QAcheck_confirm_text": "請先確認要取消品保放行的資料無誤，再進行取消品保放行",
      "QAQTYcheck":
        "Incoming Material Details [Quality Assurance Refund Quantity] + [Quality Assurance Release Quantity] Must Be Equal To [Incoming Material Quantity]",
      "throwStock_message": "即將進入拋轉進料檢驗單作業，是否確定拋轉?",
      "dealN": "Supplier's Order Number",
      "takeqty": "Number Of Quote",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax",
      "Tooltip_disable_take_balqty_0": "剩餘數量為0不可引用",
      "message_take_before_choose": "請先選擇引用再輸入引用數量",
      "message_transfer_small_than_0": "拋轉數量不可小於0",
      "message_take_same_detail": "不可引用相同明細",
    },
    "gostock": {
      "pageHeader": "Incoming Inspection Query",
      "4120_header": "驗收主檔",
      "4230_header": "驗退主檔",
      "total": "Total Amount Transferred",
      "planqty": "Transfer Amount",
      "jobN": "Source Tracking Number",
      "batchno_placeholder": "Please Enter The Batch Number",
      "note": "Note",
      "tb": "Acceptance Details",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "Accounts Payable Management",
      "ap_create_header": "Create A New Statement Master",
      "ap_edit_header": "Edit Statement Master",
      "ap_sumtotal": "Total Amount",
      "strike_sumtotal": "Total Chargeback Amount",
      "takeAp": "Quote Statement",
      "contactWindow": "Contact Window Information",
      "chooseWindow": "Select Window",
      "ap_tb": "Statement Details",
      "strike_tb": "Offset Bill Details",
      "inbatchno": "Feed Batch Number",
      "itemtotal": "Amount Payable Including Tax",
      "hubqty": "Receipt",
      "balance": "Account Difference",
      "recon": "Reconciliation Data",
      "addr": "廠商地址",
      "addr_placeholder": "請輸入聯絡地址",
      "strike_tb_delete_confirm": "沖帳明細刪除確認",
      "paytotal": "Charged Amount",
      "ap_total": "Total Amount Payable Including Tax",
      "apCheck": "Reconciliation Review",
      "cancel_apCheck": "取消覆核",
      "apCheck_confirm": "對帳覆核確認",
      "apCheck_confirm_text": "即將進入對帳覆核作業，是否儲存對帳單資料?",
      "releaseAP": "出對帳單",
      "payment_placeholder": "Please Choose A Payment Method",
      "cancel_releaseAP": "Cancel Statement",
      "releaseAP_confirm": "出對帳單確認",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck": "Reverse Account Review",
      "strike_create_header": "Create A New Reverse Bill Master File",
      "strike_edit_header": "編輯沖帳單主檔",
      "hd_drawer_title": "Reconciliation Data Master",
      "itemNM": "Note",
      "paytype": "Payment Method",
      "strikeCheck_confirm": "Reverse Account Review Confirmation",
      "strikeCheck_confirm_text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review",
      "cancel_strikeCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Review",
      "confirmStrike": "Confirm Chargeback",
      "confirmStrike_confirm": "Chargeback Confirmation",
      "confirmStrike_confirm_text":
        "After Confirming The Chargeback, The Chargeback Bill Will Be Closed, Please Confirm Whether To Charge The Account",
      "strike_paytotal": "Payment Amount",
      "payT": "Payment Time",
      "note": "Note",
      "PN": "Erp Material Number",
      "inqty": "Transfer Amount",
      "ap_del_hd_confirm_text":
        "Please Confirm That The Statement Information To Be Deleted Is Correct Before Deleting",
      "strike_del_hd_confirm_text":
        "Please Confirm That The Billing Information To Be Deleted Is Correct Before Deleting",
      "AP_lifeF_0": "Create",
      "AP_lifeF_1": "覆核",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "沖帳(付款)未結案",
      "AP_lifeF_T": "結案",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "完成沖帳",
      "message_chose_take_which_ap": "Please Select The Reconciliation Data To Quote",
      "message_only_take_one_ap": "只可引用一張對帳單",
    },
    "return": {
      "pageHeader": "Incoming Returns Operation",
      "create_header": "New Feed Exits Main File",
      "edit_header": "Edit Feed Exit Master File",
      "addr": "Contact Address",
      "del_hd_confirm_text": "請先確認要刪除的進料退出單資料無誤，再進行刪除",
      "rtnqty": "Return Quantity",
      "inbatchno": "Feed Batch Number",
      "note": "Note",
      "tb": "Feed And Exit Details",
      "itemNM": "Note",
      "itemNM_placeholder": "Please Fill In The Note ",
      "lifeF_0": "Release",
      "lifeF_T": "Close",
      "message_at_least_chose_one": "Please Select At Least One Inspection And Refund Details",
      "planqty": "Quantity",
    },
  },
  "OMS": {
    "util": {
      "opcode_1110": "國內報價",
      "opcode_1120": "國外報價",
      "opcode_1210": "預估訂單",
      "opcode_1310": "國內訂單",
      "opcode_1320": "國外訂單",
      "opcode_1410": "國內訂單",
      "opcode_1420": "國外訂單",
      "opcode_4220": "驗出單",
      "opcode_4130": "驗退單",
      "opcode_1610": "銷貨退回",
      "opcode_1710": "銷售出貨",
      "opcode_1810": "應收沖帳",
      "plan": "計畫單",
      "quo": "報價單",
      "fod": "預訂單",
      "ord": "銷貨單",
      "outstock": "發貨單",
      "return": "銷貨退回單",
      "ap": "對帳單",
      "outN": "驗退單號",
      "N11": "報價單號",
      "N12": "預訂單號",
      "N13": "銷貨單號",
      "N14": "發貨單號",
      "N17": "應收單號",
      "N18": "沖帳單號",
      "planSearch": "Project Record Inquiry",
      "quoSearch": "Quotation Record Inquiry",
      "fodSearch": "Reservation Record Inquiry",
      "ordSearch": "Sales Record Inquiry",
      "stockSearch": "Acceptance Record Query",
      "outstockSearch": "Shipping Record Inquiry",
      "apSearch": "Reconciliation Data Inquiry",
      "strikeSearch": "Reversal Record Inquiry",
      "dealN": "Customer Order Number",
      "drawno": "Drawing Number",
      "attribute": "Material Attribute",
      "pdtclass": "Sell Group",
      "pdttype": "Management Classification",
      "localprice": "Local Currency Amount",
    },
    "quo": {
      "pageHeader": "Quotation Job",
      "quoID": "Quotation Personnel",
      "quoT": "Quotation Date",
      "validdays": "Valid Date",
      "isvalid": "validation",
      "hd_drawer_title": "報價單主檔",
      "tb_drawer_title": "Quotation Details",
      "demand": "Quotation Quantity",
      "punit": "Quotation Unit",
      "unitprice": "Quotation Amount",
      "isapprove": "Single Transaction",
      "itemtotal": "未稅總金額",
      "local_subtotal": "本幣含稅總金額",
      "total": "含稅總金額(交易幣別)",
      "note": "報價註記",
      "hd_delete_confirm_text":
        "Please Confirm That The Quotation Information To Be Deleted Is Correct Before Deleting",
      "lifeF_0": "發佈",
      "lifeF_2": "Quotation Review",
      "lifeF_7": "Review And Sign Off",
      "lifeF_T": "結案",
      "quoCheck_confirm_Text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review",
      "cancel_quoCheck_confirm_Text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Review",
      "Audit_confirm": "Review And Sign Off",
      "Audit_confirm_text":
        "This Quotation Cannot Be Edited After Review And Approval. Are You Sure You Want To Complete The Review?",
      "tb": "Offer Details",
    },
    "fod": {
      "pageHeader": "Book Homework",
      "hd_drawer_title": "預訂單主檔",
      "tb_drawer_title": "預訂單明細",
      "driID": "Responsible For Business",
      "trade": "Terms Of Trade",
      "dealcontact": "Customer Purchasing Window",
      "payment": "Payment Method",
      "dealaddr": "Customer Address",
      "custHistory": "Customer Transaction History Inquiry",
      "contact": "客戶交貨窗口",
      "sumtotal": "Total Untaxed Amount",
      "note": "Note",
      "total": "含稅總金額",
      "demandqty": "Number Of Customers",
      "punit": "Order Unit",
      "unitprice": "Order Unit Price",
      "confirmqty": "Confirm The Quantity",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "transferqty": "Transfered Quantity",
      "qty": "Estimated Number Of Transfer",
      "hd_delete_confirm_text":
        "Please Confirm That The Reservation Information To Be Deleted Is Correct Before Deleting",
      "lifeF_0": "Release",
      "lifeF_2": "Booking Review",
      "lifeF_7": "Customer Sign Back",
      "lifeF_T": "Close The Case",
      "local_total": "Total Amount Including Tax (Local Currency)",
      "fodCheck_confirm_text": "請先確認要預訂覆核的資料無誤，再進行預訂覆核",
      "cancel_fodCheck_confirm_text": "請先確認要取消預訂覆核的資料無誤，再進行取消預訂覆核",
      "custCheck_confirm": "Customer Sign Back Confirmation",
      "custCheck_confirm_text":
        "Please Confirm That The Information To Be Signed Back Is Correct Before Signing Back",
      "cancel_custCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "reviewFlow": "審核流程(3)",
      "tb": "預訂明細",
      "deal_history": "Product Transaction History Query",
    },
    "ord": {
      "pageHeader": "Sales Operation",
      "hd_drawer_title": "銷貨單主檔",
      "tb": "銷貨單明細",
      "adda": "Terms And Conditions",
      "addb": "Additional Deduction",
      "addc": "Prepaid Assignment",
      "lifeF_0": "Release",
      "lifeF_2": "Order Review",
      "lifeF_7": "Customer Sign Back",
      "lifeF_T": "結令",
      "driID": "Responsible For Business",
      "sumtotal": "Total Untaxed Amount",
      "total": "Total Amount Including Tax (Transaction Currency)",
      "dealaddr": "Customer Address",
      "contact": "客戶交貨窗口",
      "dealcontact": "客戶業務窗口",
      "payment": "Payment Method",
      "trade": "Terms Of Trade",
      "note": "Note",
      "reviewFlow": "審核流程(3)",
      "demandqty": "銷貨數量",
      "transferqty": "Transfered Quantity",
      "qty": "本次拋轉數量",
      "punit": "銷貨單位",
      "unitprice": "銷貨單價",
      "confirmqty": "Customer Confirmation Quantity",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "charge": "Total Deductions",
      "statement": "Project Instruction",
      "amount": "未稅金額",
      "local_total": "Total Amount Including Tax (Local Currency)",
      "closeData_confirm_text": "請先確認要結令的銷貨單資料無誤，再進行強制結令",
      "removeData_confirm_text": "請先確認要刪除的銷貨單資料無誤，再進行刪除",
      "orderCheck_text":
        "Please Confirm That The Information To Be Ordered For Review Is Correct Before Proceeding With The Order Review",
      "cancel_orderCheck_text": "請先確認要取消銷貨覆核的資料無誤，再進行取消銷貨覆核",
      "cancel_custCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "custCheck_confirm_text":
        "Please Confirm That The Information To Be Signed Back Is Correct Before Signing Back",
      "adda_delete_confirm_text": "請先確認要刪除的條款資料無誤，再進行刪除",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text": "請先確認要刪除的預付作業無誤，再進行刪除",
      "deal_history": "Product Transaction History Query",
    },
    "out-stock": {
      "pageHeader": "Shipping Job",
      "hd_drawer_title": "發貨單主檔",
      "tb_drawer_title": "發貨單明細",
      "tb": "Shipping Details",
      "lifeF_0": "發佈",
      "lifeF_2": "Shipping Review",
      "lifeF_3": "IQC抽驗",
      "lifeF_4": "IQC完驗",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "sampleID": "抽樣人員",
      "sampleT": "抽樣日期",
      "note": "發貨註記",
      "punit": "發貨單位",
      "indqty": "The Number Of Shipments",
      "goqty": "Acceptance Quantity",
      "unitprice": "銷貨單價",
      "rejectqty": "Quantity",
      "IQCcheck": "Incoming Inspection And Sampling",
      "inspectID": "Inspection Personnel",
      "inspectT": "Completion Time",
      "qalID": "QC Staff",
      "qalT": "Release Time",
      "judgeT": "Quality Assurance Judgment Date",
      "qty": "Quantity",
      "choose": "選擇",
      "choose_err": "剩餘數量為0不可引用",
      "throw_save_msg": "請先選擇引用再輸入引用數量",
      "throwQty_msg": "拋轉數量不可小於0",
      "useDetail_msg": "不可引用相同明細",
      "IQCcheck_text":
        "Is About To Enter The Inspection And Sampling Operation, Do You Want To Save The Invoice Data?",
      "closeData_confirm_text": "請先確認要刪除的發貨單資料無誤，再進行刪除",
      "outstockCheck_text": "請先確認要發貨覆核的資料無誤，再進行發貨覆核",
      "cancel_outstockCheck_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "IQCfinish_text": "即將進入IQC完驗作業，是否儲存發貨單資料?",
      "QAcheck_err": "發貨明細 [驗收數量]+[驗退數量] 要等於 [發貨數量]",
      "QAcheck": "Quality Assurance Release",
      "QAcheck_text":
        "Is About To Enter The Quality Assurance Release Operation, Do You Want To Save The Invoice Data?",
      "throwStock_text": "即將進入拋轉發貨單作業，是否確定拋轉?",
    },
    "check": {
      "pageHeader": "Shipping Inspection",
      "4220_header": "銷售發貨單主檔",
      "4220_tb": "發貨明細",
      "4130_header": "銷售退回單主檔",
      "4130_tb": "Return Details",
      "jobN": "Source Tracking Number",
      "note": "Note",
      "total": "Total Amount Transferred",
      "planqty": "Transfer Quantity",
      "punit": "銷貨單位",
      "lifeF_0": "Release",
      "lifeF_T": "結案",
    },
    "return": {
      "pageHeader": "Sales Return Operation",
      "tb": "Sales Return Details",
      "hd_remove_text": "請先確認要刪除的銷貨退回單資料無誤，再進行刪除",
      "unitprice": "出貨單價",
      "rtnqty": "Return Quantity",
      "inbatchno": "進料批號",
      "contact_window": "客戶業務窗口",
      "note": "Note",
      "addr": "Contact Address",
      "itemNM": "Note",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "Accounts Receivable Management",
      "rcv": "Accounts receivable inquiry",
      "rcvData": "Receivable Data",
      "rcv_drawer_title": "應收帳款主檔",
      "inbatchno": "Feed Batch Number",
      "contactWindow": "聯絡窗口資訊",
      "ap_tb": "對帳單明細",
      "strike": "Reverse Operation",
      "strike_tb": "沖帳單明細",
      "unitprice": "銷貨單價",
      "rcv_inqty": "Transfer Quantity",
      "rcv_itemsum": "Single Amounts",
      "rcv_itemsumtax": "Single Tax",
      "rcv_subtotal": "Subtotal Including Tax",
      "ap_remove_text": "請先確認要刪除的對帳單資料無誤，再進行刪除",
      "strike_remove_text": "請先確認要刪除的沖帳單資料無誤，再進行刪除",
      "ap_total": "Total Amount Receivable Including Tax",
      "ap_itemtotal": "Amount Receivable Including Tax",
      "ap_paytotal": "Charged Amount",
      "ap_sumtotal": "Total Amount",
      "strike_sumtotal": "Total Chargeback Amount",
      "ap_hubqty": "Outbound Quantity",
      "ap_addr": "Customer Address",
      "ap_balance": "Account Difference",
      "ap_lifeF_0": "Create",
      "ap_lifeF_1": "應收覆核",
      "ap_lifeF_2": "Issue The Bill Of Receivable",
      "ap_lifeF_3": "沖帳(收款)未結案",
      "ap_lifeF_T": "結案",
      "ap_contact": "Customer Business Window",
      "ap_itemNM": "Note",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "現金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "Note",
      "confirmStrike": "確認沖帳",
      "apCheck_confirm_text": "即將進入對帳覆核作業，是否儲存對帳單資料",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck_confirm_text": "請先確認要覆核的資料無誤，再進行覆核",
      "cancel_strikeCheck_confirm_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "confirmStrike_confirm_text": "確認沖帳後此沖帳單將結案，請確認是否沖帳",
    },
  },
  "BPM": {
    "util": {
      "signmode": "簽核層級",
      "level": "階",
      "opfunction_1": "訂單系統",
      "opfunction_2": "採購系統",
      "opfunction_3": "生產系統",
      "opfunction_4": "庫儲系統",
      "opfunction_5": "財務系統",
      "optype_11": "訂單專案",
      "optype_12": "採購專案",
      "optype_40": "庫儲專案",
      "optype_41": "出庫專案",
      "permissions": "權限",
      "open": "立案",
      "check": "查權",
      "review": "審權",
      "approve": "核權",
    },
    "sign-level": {
      "pageHeader": "簽核權限設定",
      "opfunction": "營運功能",
      "optype": "作業類別",
    },
    "dsevent": {
      "system": "系統模組",
      "keynote": "主旨",
    },
  },
  "PDS": {
    "util": {
      "wlID": "Line",
    },
    "wpc-search": {
      "hdC": "高峰人數",
      "issueday": "發佈時間",
      "lifeF_0": "已發佈",
      "lifeF_1": "已登錄",
      "lifeF_2": "生產中",
      "lifeF_3": "報停工",
      "lifeF_4": "報復工",
      "lifeF_7": "報完工",
      "lifeF_B": "報開工",
      "lifeF_G": "過站",
      "lifeF_L": "物控覆核",
      "lifeF_P": "質控合格",
      "lifeF_aps": "生管發佈",
      "linemix": "混線",
      "pageHeader": "工站途單查詢",
      "wlID": "線別",
    },
    "overview-lines": {
      "pageHeader": "Line Body Operation Summary Table",
      "act": "Utilization Rate",
      "shiftD": "Day Shift",
      "shiftN": "Night Shift",
      "allDay": "Left",
    },
    "dashboard": {
      "pageHeader": "Digital Production Kanban",
    },
    "abc-board": {
      "pageHeader": "Exception Management Kanban",
    },
  },
};

export default en_US;
