import { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import SystemContext from "@/components/Context/SystemContext";

import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import DailyReport_Employee from "./DailyReport_Employee";
import AirTransfer from "./AirTransfer";
import BearTransfer from "./BearTransfer";

const DailyReport = () => {
  const { t } = useTranslation();
  const { c_systemConfig } = useContext(SystemContext);

  const [s_tabKey, set_s_tabKey] = useState("DailyReport_Employee"); // 目前是在哪個Tab上

  const tabsData = [
    {
      key: "DailyReport_Employee",
      label: "每日員工產出",
      children: <DailyReport_Employee />,
      auth: c_systemConfig.auth["PIS-daily-report-DailyReport_Employee"],
    },
    {
      key: "AirTransfer",
      label: "航太移轉報表",
      children: <AirTransfer />,
      auth: c_systemConfig.auth["PIS-daily-report-AirTransfer"],
    },
    {
      key: "BearTransfer",
      label: "大造熊移轉報表",
      children: <BearTransfer />,
      auth: c_systemConfig.auth["PIS-daily-report-BearTransfer"],
    },
  ];

  return (
    <>
      <PageHeader title={"每日報表查詢"} />
      <CustomTabs
        items={tabsData.filter((x) => x.auth)}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
        }}
      />
    </>
  );
};

export default DailyReport;
