import { Col, Empty, Grid, Row, Select } from "antd";
import dayjs from "dayjs";
import Cookies from "js-cookie";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import useWebSocket from "@/hooks/useWebSocket";
import { getPS } from "@/service/apis/ADM/publicAPI";
import { dashboardSocket } from "@/service/ws/ws";
import { formatAmount } from "@/util/format";

import Card from "./Card";
import DashBox from "./DashBox";

const DashBoardCard = () => {
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);

  const call_dashboardSocket = useWebSocket(dashboardSocket);

  const [s_boxData, set_s_boxData] = useState({});

  const [s_psID, set_s_psID] = useState(Cookies.get("plantID") === "CPT" ? "B3" : ""); // 所有工站
  const call_getPS = useAPI(getPS);

  // websocket 連線
  useEffect(() => {
    // console.log("s_psID === ", s_psID);
    call_dashboardSocket.changeParams({ psID: s_psID });
    call_dashboardSocket.setIsOpen(true);
  }, [s_psID]);

  // 第一次渲染取得所有車間資料, 並塞值給 s_searchData
  useEffect(() => {
    call_getPS.request();
  }, []);

  useEffect(() => {
    console.log("s_psID === ", s_psID);
    call_dashboardSocket.changeParams({ psID: s_psID });
    call_dashboardSocket.setIsOpen(true);
  }, [s_psID]);

  useEffect(() => {
    if (call_dashboardSocket.socketData.status) {
      console.log("call_dashboardSocket.socketData.data = ", call_dashboardSocket.socketData.data);
      set_s_tableData(
        call_dashboardSocket.socketData.data?.map((x, i) => {
          return {
            ...x,
            key: i,
            index: i,
            openT: sortTime(x.openT),
            closeT: sortTime(x.closeT),
            lifeFT: sortTime(x.lifeFT),
            sliceT: sortTime(x.sliceT),
            allPlanqty: formatAmount(x.allPlanqty),
            inC: formatAmount(x.inC),
            okC: formatAmount(x.okC),
            ngC: formatAmount(x.ngC),
            mhC: formatAmount(x.mhC / 3600),
            emhC: formatAmount(x.emhC / 3600),
            whC: formatAmount(x.whC / 3600),
          };
        }) || []
      );
      if (call_dashboardSocket.socketData.data && call_dashboardSocket.socketData.data.length > 0) {
        call_dashboardSocket.socketData.data.forEach((item) =>
          console.log("item.situF = ", item.situF !== "")
        );
        // 開線數
        const openLine = call_dashboardSocket.socketData.data.filter(
          (item) => item.situF !== "" && item.situF !== "W"
        ).length;
        console.log("openLine = ", openLine);
        // 總線數
        const allLine = call_dashboardSocket.socketData.data.length;
        // 開線率
        const openLineRate = `${((openLine / allLine) * 100).toFixed(1)}%`;

        set_s_boxData({ openLine, allLine, openLineRate });
      }
    } else {
      set_s_boxData({});
      set_s_tableData([]);
    }
  }, [call_dashboardSocket.socketData]);

  const sortTime = (time) => {
    var newTime = dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    if (dayjs(newTime).year() < 2000) {
      return null;
    } else {
      return newTime;
    }
  };

  let psOptions = (call_getPS?.data ?? []).map((x) => {
    return { value: x.psID, label: x.psNM };
  });
  psOptions.push({ value: "", label: t("util.util.all") });

  const DashBoxData = [
    { header: "開線率", value: s_boxData?.openLineRate || "0%" },
    { header: "開線數", value: s_boxData?.openLine || 0 },
    { header: "總線數", value: s_boxData?.allLine || 0 },
    { header: "總人數", value: s_boxData?.allUpHead || 0 },
  ];

  const DashBoxs = DashBoxData.map((item) => <DashBox header={item.header} value={item.value} />);

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="數位生產圖框"
        extra={[
          screens.xl && DashBoxs,
          <Select
            key="ps"
            className="w-[200px]"
            size="large"
            value={s_psID}
            onChange={(value) => set_s_psID(value)}
            options={psOptions}
          />,
        ]}
      />

      {!screens.xl && <div className="flex gap-2 justify-end mb-5">{DashBoxs}</div>}

      <Row gutter={[12, 12]} className="overflow-auto">
        {s_tableData?.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            <Card data={item} />
          </Col>
        ))}

        {s_tableData.length === 0 && (
          <Col span={24} className="w-full flex-center">
            <Empty
              imageStyle={{
                height: 200,
              }}
              description={<span className="text-[#D9D9D9] text-3xl">查無資料</span>}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DashBoardCard;
