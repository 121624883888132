import { useState, useEffect, useRef } from "react";
import { Row, Col, Input, InputNumber, Select, Form, message, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { unitArr, sapTypeOptions } from "@/pages/LMS/util/anyMap";
import useAPI from "@/hooks/useAPI";
import { getLabel } from "@/service/apis/LMS/LabelManage";
import { getPF, getStation, getLine, getPWC, getCustHd } from "@/service/apis/ADM/publicAPI";

const initialValues = {
  width: 20,
  height: 20,
  unit: "mm",
  note: "",
  isvalid: true,
};

const ctrlspotOptions = [
  { label: "CUST客戶", value: "CUST" },
  { label: "PF製程", value: "PF" },
  { label: "WC工作中心", value: "PWC" },
  { label: "WS工站", value: "WS" },
  { label: "WL工線", value: "WL" },
];

const useOptions = [
  { label: "出貨標籤", value: "1A" },
  { label: "嘜頭標籤", value: "1B" },
  { label: "進料標籤", value: "2A" },
  { label: "製程標籤", value: "2B" },
  { label: "入庫標籤", value: "2C" },
  { label: "內箱標籤", value: "3A" },
  { label: "外箱標籤", value: "3B" },
  { label: "棧板標籤", value: "3C" },
];

export default function FormHD(props) {
  const { form_hd_ref, c_lightData, ...otherProps } = props;
  const { t } = useTranslation();

  const f_ctrlspot = Form.useWatch("ctrlspot", form_hd_ref);

  const [s_paperOptions, set_s_paperOptions] = useState([]);

  const call_getLabel = useAPI(getLabel);
  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);
  // 客戶
  const call_getCustHd = useAPI(getCustHd);

  const s_arrMap = {
    PF: call_getPF?.data?.map?.((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],
    WS:
      call_getStation?.data?.map?.((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) ||
      [],
    WL:
      call_getLine?.data?.map?.((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    PWC:
      call_getPWC?.data?.map?.((pwc) => ({
        label: `${pwc.pwcID}_${pwc.pwcNM}`,
        value: pwc.pwcID,
      })) || [],
    CUST:
      call_getCustHd?.data?.map?.((cust) => ({
        label: `${cust.custID}_${cust.custNM}`,
        value: cust.custID,
      })) || [],
  };

  useEffect(() => {
    call_getLabel.request();
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
    call_getCustHd.request();
  }, []);

  useEffect(() => {
    if (call_getLabel.status === "suc") {
      set_s_paperOptions(
        call_getLabel.data.map((x) => ({
          label: x.labelNM,
          value: x.labelID,
          data: x,
        }))
      );
    } else if (call_getLabel.status === "err") {
      message.error(call_getLabel.msg);
    }
  }, [call_getLabel.status]);

  return (
    <Form {...otherProps} form={form_hd_ref} layout="vertical" initialValues={initialValues}>
      <Row gutter={[12, 0]}>
        <Col span={3}>
          <Form.Item label="標籤名稱" name="modelNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="SAP標籤類型" name="saptype">
            <Select allowClear showSearch options={sapTypeOptions} />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="SAP標籤ID" name="sapSNstr">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤ID" name="modelID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="版次" name="ver">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤用途" name="usage" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              options={useOptions}
              disabled={Object.keys(c_lightData).length > 0}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="控點" name="ctrlspot" rules={[{ required: true }]}>
            <Select
              allowClear
              showSearch
              options={ctrlspotOptions}
              disabled={Object.keys(c_lightData).length > 0}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="控點ID" name="spotID">
            <Select
              allowClear
              showSearch
              options={s_arrMap[f_ctrlspot]}
              disabled={Object.keys(c_lightData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={2}>
          <Form.Item label="是否啟用" name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item label="紙張快速帶入" name="labelID">
            <Select
              onChange={(_, data) =>
                form_hd_ref.setFieldsValue({
                  width: data.data.width,
                  height: data.data.high,
                  unit: data.data.widthUnit,
                  labelID: data.data.labelID,
                })
              }
              options={s_paperOptions}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤寬度" name="width">
            <InputNumber min={0.001} className="w-full" disabled />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤高度" name="height">
            <InputNumber min={0.001} className="w-full" disabled />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤單位" name="unit">
            <Select options={unitArr} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="備註" name="note">
            <Input.TextArea rows={1} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
