import { Button, Col, Form, Input, Radio, Row, Select, Space, message } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { addQmsSign, updateQmsSign } from "@/service/apis/QMS/meds";

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const f_group = Form.useWatch("group", form);
  console.log(s_editData);
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_addQmsSign = useAPI(addQmsSign);
  const call_updateQmsSign = useAPI(updateQmsSign);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    console.log(values);

    if (type === "create") {
      call_addQmsSign.request({ ...values });
    }

    if (type === "edit") {
      call_updateQmsSign.request({ ...values, icon: s_editData.icon });
    }
  };

  useEffect(() => {
    if (call_addQmsSign.status === "suc") {
      message.success(call_addQmsSign.msg);
      set_s_isShowModal(false);
    }
    if (call_addQmsSign.status === "err") {
      message.error(call_addQmsSign.msg);
    }
  }, [call_addQmsSign.status]);
  useEffect(() => {
    if (call_updateQmsSign.status === "suc") {
      message.success(call_updateQmsSign.msg);
      set_s_isShowModal(false);
    }
    if (call_updateQmsSign.status === "err") {
      message.error(call_updateQmsSign.msg);
    }
  }, [call_updateQmsSign.status]);
  console.log(f_group);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
    >
      <div>
        <Form.Item name="updtT" hidden>
          <Input placeholder="updtT" disabled />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="符號ID" name="signID">
            <Input disabled></Input>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"本文說明"} name="twNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"群組"} name="group">
            <Select
              allowClear
              options={[
                { label: "尺寸公差", value: "尺寸公差" },
                { label: "幾何公差", value: "幾何公差" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6} className="flex justify-start ">
          <Form.Item label={t("ADM.pn-manage.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"越文說明"} name="viNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"泰文說明"} name="thNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"英文說明"} name="enNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"印文說明"} name="idNM">
            <Input />
          </Form.Item>
        </Col>

        <Row gutter={[24, 12]} className="w-full">
          <Col span={12} className="">
            <CreateFromItem
              afterCopy={() => {
                form.setFieldsValue({ signID: null });
              }}
              set_s_editData={set_s_editData}
              s_editData={s_editData}
              form={form}
            />
          </Col>
          <Col span={12} className="flex justify-end">
            <Space>
              <Button
                onClick={() => set_s_isShowModal(false)}
                loading={call_addQmsSign.status === "load" || call_updateQmsSign.status === "load"}
              >
                {t("util.util.cancel")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={call_addQmsSign.status === "load" || call_updateQmsSign.status === "load"}
              >
                {t("util.util.ok")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
