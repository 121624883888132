import React, { useState, useEffect } from "react";
import { Select, Typography, message, Button, Input, Space } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import Light from "@/components/Light";
import { getAllAbc, getAbcSelect, judge } from "@/service/apis/ADM/abcBoard";
import useAPI from "@/hooks/useAPI";
import { formatNumber } from "@/util/format";

const { Title, Text, Link } = Typography;

const colorMap = {
  0: "main",
  1: "success",
  2: "error",
  3: "warning",
};

const lightMap = {
  0: "人工解除",
  1: "系統解除",
  2: "異常停工",
  3: "異常提報",
};

export default function AbcBoard() {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_allWsWl, set_s_allWsWl] = useState([]);
  const [s_event, set_s_event] = useState([]);
  const [s_light, set_s_light] = useState([]);
  const [s_dri, set_s_dri] = useState([]);
  const [s_wlOptions, set_s_wlOptions] = useState([]);

  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  // 送給後端的資料
  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 200,
    abnSN: null,
    woN: null,
    PN: null,
    light: "All", //狀況
    event: "All", //項目
    abnormDri: "All", //責任單位
    wlID: "All", //工線
    wsID: "All", //工站
  });

  const call_getAllAbc = useAPI(getAllAbc);
  const call_getAbcSelect = useAPI(getAbcSelect);

  const tableColumns = [
    {
      title: "異常ID",
      dataIndex: "abnSN",
      align: "center",
    },
    {
      title: "燈號",
      dataIndex: "light",
      align: "center",
      render: (text) => <Light type={colorMap[text]} />,
    },
    {
      title: "站別",
      dataIndex: "wsNM",
      align: "center",
    },
    {
      title: "線名",
      dataIndex: "wlNM",
      align: "center",
    },
    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "提報時間",
      dataIndex: "rpt_time",
      align: "center",
      render: (value) => dayjs(value).format("MM-DD HH:mm:ss"),
    },
    {
      title: "項目",
      dataIndex: "rptItemNM",
      align: "center",
    },
    {
      title: "異常信息",
      dataIndex: "rpt_msg",
      align: "center",
    },
    {
      title: "提報人員",
      dataIndex: "rptNM",
      align: "center",
    },
    {
      title: "解除人員",
      dataIndex: "rlsNM",
      align: "center",
    },
    // {
    //   title: "責任單位",
    //   dataIndex: "abnDriNM",
    //   align: "center",
    // },
    {
      title: "判責意見",
      dataIndex: "judge_msg",
      align: "center",
      render: (value, recode) => (value === "" ? <Link>填寫意見</Link> : value),
    },
    {
      title: "異常工時(秒)",
      dataIndex: "cumworkH",
      align: "center",
    },
    {
      title: "提報單位",
      dataIndex: "rptUnitNM",
      align: "center",
    },
  ];

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      call_getAllAbc.request(s_searchData);
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const searchBasic = [
    <Input
      key="s_searchData.abnSN"
      value={s_searchData.abnSN}
      onChange={(e) => handlChange("abnSN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}查詢異常編號`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.woN"
      value={s_searchData.woN}
      onChange={(e) => handlChange("woN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}查詢製令工單`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Select
      key="s_searchData.light"
      className="w-[180px]"
      options={s_light.reduce(
        (acc, option) => {
          acc.push({
            label: lightMap[option],
            value: option,
          });
          return acc;
        },
        [{ value: "All", label: "全部狀況" }]
      )}
      value={s_searchData.light}
      onChange={(e) => handlChange("light", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <Select
      key="s_searchData.event"
      className="w-[180px]"
      options={s_event.reduce(
        (acc, option) => {
          acc.push({
            label: option?.eventNM,
            value: option?.eventID,
          });
          return acc;
        },
        [{ value: "All", label: "全部項目" }]
      )}
      value={s_searchData.event}
      onChange={(e) => handlChange("event", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <Select
      key="s_searchData.abnormDri"
      className="w-[180px]"
      options={s_dri.reduce(
        (acc, option) => {
          acc.push({
            label: option?.itemNM,
            value: option?.itemID,
          });
          return acc;
        },
        [{ value: "All", label: "全部單位" }]
      )}
      value={s_searchData.abnormDri}
      onChange={(e) => handlChange("abnormDri", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <Select
      key="s_searchData.wsID"
      className="w-[180px]"
      options={s_allWsWl.reduce(
        (acc, option) => {
          acc.push({
            label: option?.wsID.wsNM,
            value: option?.wsID.wsID,
          });
          return acc;
        },
        [{ value: "All", label: "全部工站" }]
      )}
      value={s_searchData.wsID}
      onChange={(e) => handlChange("wsID", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <Select
      key="s_searchData.wlID"
      className="w-[180px]"
      options={s_wlOptions?.reduce(
        (acc, option) => {
          acc.push({
            label: option?.wlNM,
            value: option?.wlID,
          });
          return acc;
        },
        [{ value: "All", label: "全部線別" }]
      )}
      value={s_searchData.wlID}
      onChange={(e) => handlChange("wlID", e)}
      onKeyDown={handleKeyDownEnter}
    />,
  ];

  //根據工站變化設定對應的工線選單
  const wsOption = () => {
    if (s_searchData.wsID === "All") {
      let tmparr = [];
      s_allWsWl.forEach((item) => {
        tmparr.push(...item.wlIDs);
        set_s_wlOptions(tmparr);
      });
    } else {
      let tmp = s_allWsWl.find((element) => element.wsID.wsID === s_searchData.wsID);
      set_s_wlOptions(tmp.wlIDs);
    }
  };

  useEffect(() => {
    call_getAbcSelect.request();
  }, []);

  useEffect(() => {
    call_getAllAbc.request(s_searchData);
  }, []);

  useEffect(() => {
    if (call_getAllAbc.status === "suc") {
      message.success(call_getAllAbc.msg);
      set_s_tableData(call_getAllAbc.data);
    } else if (call_getAllAbc.status === "err") {
      message.error(t(`error.error-code.${call_getAllAbc.msg}`));
    }
  }, [call_getAllAbc.status]);

  useEffect(() => {
    if (call_getAbcSelect.status === "suc") {
      message.success(call_getAbcSelect.msg);
      set_s_allWsWl(call_getAbcSelect.data.allWsWl);
      set_s_light(call_getAbcSelect.data.light);
      set_s_dri(call_getAbcSelect.data.dri);
      set_s_event(call_getAbcSelect.data.event);
    } else if (call_getAbcSelect.status === "err") {
      message.error(t(`error.error-code.${call_getAbcSelect.msg}`));
    }
  }, [call_getAbcSelect.status]);

  //工站有變化或是一開始拿到s_allWsWl的時候要設定工線，不然工線只有全部工線
  useEffect(() => {
    wsOption();
  }, [s_searchData.wsID, s_allWsWl]);

  return (
    <>
      <PageHeader title="異常管理看板" />
      <Space className="w-full flex justify-end mb-4">{searchBasic}</Space>
      <CustomTable
        // pagination={false}
        scroll={{x:100}}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
      />
    </>
  );
}
