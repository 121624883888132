/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";

import useAPI from "@/hooks/useAPI";
import { getLine, getPS } from "@/service/apis/ADM/publicAPI";
import { getStaff } from "@/service/apis/ADM/userManage";
import { addWC, modifyWC } from "@/service/apis/PIS/CaptainLineManage";

const WorkCaptainModal = ({ s_editData, set_s_editData, set_is_showModal }) => {
  const [s_wlID, set_s_wlID] = useState([]);
  const [s_AllStaff, set_s_AllStaff] = useState([]);
  const [s_AllPS, set_s_AllPS] = useState([]);

  const call_getLine = useAPI(getLine);
  const call_getStaff = useAPI(getStaff);
  const [form] = Form.useForm();

  const call_addWC = useAPI(addWC);
  const call_modifyWC = useAPI(modifyWC);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "modify";

    const tmpObj = {
      ...values,
      wlID: values.wlID.join(),
    };

    console.log("要傳給後端的資料 = ", tmpObj);

    if (type === "create") {
      call_addWC.request(tmpObj);
    }

    if (type === "modify") {
      call_modifyWC.request(tmpObj);
    }
  };

  const handleCancel = () => {
    set_is_showModal(false);
  };

  useEffect(() => {
    if (call_getLine.status === "suc") {
      set_s_wlID(call_getLine.data);
    }
    if (call_getLine.status === "err") {
    }
    if (call_getLine.status === "load") {
    }
  }, [call_getLine.status]);

  // 員工資料
  useEffect(() => {
    const { status, msg, data } = call_getStaff;
    if (status === "suc") {
      set_s_AllStaff(data);
      console.log("call_getStaff.data", data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getStaff.status]);

  //第一次渲染取得全部車間資料
  useEffect(() => {
    const call_getPS = async () => {
      try {
        const res = await getPS("");
        if (res.status) {
          res.data.unshift({ psID: "Z99", psNM: "全車間" });
          set_s_AllPS(res.data);
        }
      } catch (e) {
        console.log("取得全部車間資料錯誤");
        set_s_AllPS([]);
      }
    };
    call_getPS();
  }, []);

  // 新增
  useEffect(() => {
    const { status, msg } = call_addWC;
    if (status === "suc") {
      message.success(msg);
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_is_showModal(false);
    }
  }, [call_addWC.status]);

  // 修改
  useEffect(() => {
    const { status, msg } = call_modifyWC;
    if (status === "suc") {
      message.success(msg);
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_is_showModal(false);
    }
  }, [call_modifyWC.status]);

  useEffect(() => {
    form.resetFields();
  }, [form, s_editData]);

  // 取得API資料
  useEffect(() => {
    call_getLine.request();
    call_getStaff.request();
  }, []);

  return (
    <>
      <Form
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          ...s_editData,
          leaderID: Object.keys(s_editData).length > 0 ? s_editData.leaderID : null,
          leaderNM: Object.keys(s_editData).length > 0 ? s_editData.leaderNM : null,
          sethead: Object.keys(s_editData).length > 0 ? s_editData.sethead : 0,
          wlID: Object.keys(s_editData).length > 0 ? s_editData.wlID.split(",") : [],
          note: Object.keys(s_editData).length > 0 ? s_editData.note : null, //備註
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              label="領班線別"
              name="captainID"
              rules={[
                {
                  required: true,
                  message: "請輸入領班線別!",
                },
              ]}
            >
              <Input
                name="captainID"
                placeholder="請輸入領班線別"
                disabled={Object.keys(s_editData).length > 0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="實體線別"
              name="wlID"
              rules={[
                {
                  required: true,
                  message: "請輸入實體線別!",
                },
              ]}
            >
              <Select
                name="wlID"
                placeholder="請選擇實體線別"
                disabled={
                  Object.keys(s_editData).length > 0
                    ? s_editData.captainID.startsWith("PS") || s_editData.captainID.startsWith("Z")
                    : false
                }
                mode="tags"
                allowClear
                options={
                  call_getLine?.data?.map((x) => ({
                    label: x.wlID + "_" + x.wlNM,
                    value: x.wlID,
                    data: x,
                  })) || []
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}></Col>

          <Col span={8}>
            <Form.Item
              label="領班"
              name="leaderID"
              // rules={[
              //   {
              //     required: true,
              //     message: "請選擇領班!",
              //   },
              // ]}
            >
              <Select
                placeholder="請選擇領班"
                allowClear
                options={
                  s_AllStaff?.map((item) => ({
                    label: item.peopleNM,
                    value: item.staffID,
                  })) || []
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="車間"
              name="psID"
              // rules={[
              //   {
              //     required: true,
              //     message: "請選擇車間!",
              //   },
              // ]}
            >
              <Select
                placeholder="請選擇車間"
                allowClear
                options={
                  s_AllPS?.map((item) => ({
                    label: item.psNM,
                    value: item.psID,
                  })) || []
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="應到人數" name="sethead">
              <InputNumber name="sethead" placeholder="請輸入應到人數" min={0} className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="備註" name="note">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: "6px 0px 12px 0px" }} />

        <Row>
          <Col offset={12} span={12}>
            <div className="flex justify-end">
              <Button size="large" className="mr-4" onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default WorkCaptainModal;
