import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Collapse, Form, Space } from "antd";
import { useTranslation } from "react-i18next";
import { IconGarbage } from "@/components/Icon/Action";
import { EyeOutlined, EyeInvisibleOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { keyTextOptions } from "../../../util/anyMap";
import LabelText from "./FormTbItem/LabelText";
import LabelRound from "./FormTbItem/LabelRound";
import LabelImage from "./FormTbItem/LabelImage";
import LabelBorder from "./FormTbItem/LabelBoorder";
import LabelBarcode from "./FormTbItem/LabelBarcode";
import LabelRect from "./FormTbItem/LabelRect";

const { Panel } = Collapse;
const CustomPanel = styled(Panel)`
  .ant-form-item {
    margin-bottom: 6px;
  }
  .ant-collapse-header {
    align-items: center !important;
    background-color: ${({ isChoose }) => isChoose && "rgb(207 250 254)"};
  }
`;

const initialValue = [
  {
    isvalid: true,
    type: "text",
    text: "Hello",
    groupType: "text",
    x: 10,
    y: 10,
    c: "#000000",
    fontSize: 10,
    boxWidth: 600,
    boxAlign: "left",
    bold: " ",
    fontFamily: "Noto Sans TC",
    fontWidth: 100,
  },
];

const typeMapping = {
  "text": "文字",
  "keyText": "關鍵文字",
  "quoteText": "引用文字",
  "border": "線條",
  "rect": "四方型",
  "round": "圓型",
  "img": "圖片",
};

const FormTB = forwardRef((props, ref) => {
  const { t } = useTranslation();
  // renderDraw 是重新繪製畫布的函式
  const { form_tb_ref, renderDraw } = props;
  // 用於監聽表單字段變化的結果
  const f_details = Form.useWatch("details", form_tb_ref);
  //用於記錄折疊面板的開啟狀態
  const [s_collapseOpenKey, set_s_collapseOpenKey] = useState([]);

  // 切換折疊面板的開啟狀態
  const changeCollapseOpenKey = (id) => {
    set_s_collapseOpenKey((prev) => {
      const findIDIndex = prev.findIndex((x) => x === id);
      if (findIDIndex === -1) {
        return [...prev, id];
      } else {
        return [...prev.slice(0, findIDIndex), ...prev.slice(findIDIndex + 1)];
      }
    });
    //並且取消choose 狀態
    form_tb_ref.setFieldsValue({ details: f_details.map((x) => ({ ...x, choose: false })) });
  };

  // 刪除折疊面板的開啟狀態
  const delCollapseOpenKey = (id) => {
    set_s_collapseOpenKey((prev) => {
      const index = prev.findIndex((x) => x === id);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  // 打開所有折疊面板
  const openAllTbCollapseKey = () => {
    set_s_collapseOpenKey(f_details.map((x) => x.id - 1));
    //並且取消choose 狀態
    form_tb_ref.setFieldsValue({ details: f_details.map((x) => ({ ...x, choose: false })) });
  };

  // 關閉所有折疊面板
  const closeAllTbCollapseKey = () => {
    set_s_collapseOpenKey([]);
    //並且取消choose 狀態
    form_tb_ref.setFieldsValue({ details: f_details.map((x) => ({ ...x, choose: false })) });
  };

  // 根據傳入的參數打開指定的折疊面板
  const openTbCollapseKey = (openArr = []) => {
    set_s_collapseOpenKey(openArr);
  };

  // 傳遞函式給父組件使用
  useImperativeHandle(ref, () => ({
    openAllTbCollapseKey,
    openTbCollapseKey,
    closeAllTbCollapseKey,
  }));

  return (
    <Form {...props} form={form_tb_ref} layout="vertical">
      <Form.List name="details" shouldUpdate initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <Collapse
            className="h-[500px] overflow-y-scroll LMS-TB-Collapse"
            expandIcon={(e) => {
              const { panelKey, isActive } = e;
              return isActive ? (
                <DownOutlined onClick={() => changeCollapseOpenKey(parseInt(panelKey))} />
              ) : (
                <RightOutlined onClick={() => changeCollapseOpenKey(parseInt(panelKey))} />
              );
            }}
            activeKey={s_collapseOpenKey}
          >
            {fields.map((field) => {
              // 獲取該折疊面板的資料
              const rowData = form_tb_ref.getFieldValue(["details", field.name]);
              const titleQuoteText = rowData.quoteText ?? rowData.text ?? rowData.keyText;

              // 生成折疊面板的標題
              const typeValue = typeMapping[rowData.type] || rowData.type;
              const keySubtitle =
                rowData.type === "keyText"
                  ? "_" + keyTextOptions.find((x) => x.key === titleQuoteText).text
                  : "";
              const title = (
                <span onClick={() => changeCollapseOpenKey(field.name)}>
                  {field.name + 1} {typeValue}
                  {titleQuoteText ? " - " + titleQuoteText + keySubtitle : ""}
                </span>
              );

              // 如果畫面有指定元素，滑道到指定位置
              if (rowData.choose) {
                const ele = document.querySelector(".LMS-TB-Collapse");
                const eleHeight = 47.5;
                //計算指定高度後 - eleHeight*2的高度 避免太貼合
                ele.scrollTo({ top: eleHeight * field.name - 47.5 * 2, behavior: "smooth" });
              }

              // 切換折疊面板的有效狀態
              const changeIsvaild = () => {
                const newDetails = f_details.map((item) => {
                  if (item.id === field.name + 1) {
                    return {
                      ...item,
                      isvalid: !item.isvalid,
                    };
                  }
                  return item;
                });
                form_tb_ref.setFieldsValue({ details: newDetails });
                renderDraw();
              };

              // 生成折疊面板的附加元素
              const genExtra = () => (
                <Space>
                  <IconGarbage
                    className="text-cyan-500"
                    onClick={(e) => {
                      remove(field.name);
                      delCollapseOpenKey(field.name);
                      e.stopPropagation();
                    }}
                  />
                  {!rowData.isvalid ? (
                    <EyeInvisibleOutlined className="text-cyan-500" onClick={changeIsvaild} />
                  ) : (
                    <EyeOutlined className="text-cyan-500" onClick={changeIsvaild} />
                  )}
                </Space>
              );

              // 根據不同的標籤類型生成相應的表單項目
              const isTextGroupType = rowData.groupType === "text";
              const isBorderType = rowData.type === "border";
              const isRoundType = rowData.type === "round";
              const isRectType = rowData.type === "rect";
              const isImageType = rowData.type === "img";
              const isBarcodeGroupType = rowData.groupType === "barcode";

              return (
                <CustomPanel
                  collapsible="header"
                  key={field.name}
                  header={title}
                  extra={genExtra()}
                  isChoose={rowData.choose}
                  forceRender
                >
                  <Form.Item name={[field.name, "type"]} hidden children={<></>} />
                  <Form.Item name={[field.name, "isvalid"]} hidden children={<></>} />

                  <Row gutter={[12, 0]}>
                    {isBorderType && LabelBorder.borderLabel(field)}

                    {isRoundType && LabelRound.roundLabel(field)}

                    {isRectType && LabelRect.rectLabel(field)}

                    {isImageType && LabelImage(field, rowData, renderDraw)}

                    {isBarcodeGroupType && LabelBarcode.barcodeLabel(field, rowData)}

                    {isTextGroupType && (
                      <>
                        {rowData.quoteText !== undefined && LabelText.quoteTextLabel(field)}
                        {rowData.text !== undefined && LabelText.textLabel(field)}
                        {rowData.keyText !== undefined && LabelText.keyTextLabel(field)}
                        {LabelText.textUtilLabel(field)}
                      </>
                    )}
                  </Row>
                </CustomPanel>
              );
            })}
          </Collapse>
        )}
      </Form.List>
    </Form>
  );
});
export default FormTB;
