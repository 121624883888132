import { useState, useEffect } from "react";
import { Modal, message, Space, Button, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { getSuppTb, deleteSuppTb } from "@/service/apis/ADM/company";

import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { AddButton } from "@/components/PDS/Buttons";

import ModalConintaer from "./ModalContainer/index";
import PageHeader from "@/components/PageHeader/index";

const TB = (props) => {
  const { t } = useTranslation();
  const { c_lightData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_editData, set_s_editData] = useState({});

  const call_getSuppTb = useAPI(getSuppTb);
  const call_deleteSuppTb = useAPI(deleteSuppTb);

  const tableColumns = [
    {
      title: t("util.util.itemno"),
      dataIndex: "itemno",
      align: "center",
      width: "4%",
    },
    {
      title: t("ADM.company.itemNM"),
      dataIndex: "itemNM",
      width: "8%",
    },
    {
      title: t("util.util.contact"),
      dataIndex: "contact",
      width: "10%",
    },
    {
      title: t("ADM.util.postID"),
      dataIndex: "postID",
      width: "5%",
    },
    {
      title: t("util.util.dept"),
      dataIndex: "department",
      width: "7%",
    },
    {
      title: t("util.util.tel"),
      dataIndex: "tel",
      width: "8%",
    },
    {
      title: t("ADM.company.mobile"),
      dataIndex: "mobile",
      width: "7%",
    },
    {
      title: t("ADM.util.mail"),
      dataIndex: "mail",
      width: "16%",
    },
    {
      title: t("ADM.company.addr"),
      dataIndex: "addr",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      cancelText: t("util.util.cancel"),
      onOk: () => call_deleteSuppTb.request(rowData),
    });
  };

  useEffect(() => {
    call_getSuppTb.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getSuppTb.status === "suc") {
      message.success(call_getSuppTb.msg);
      set_s_tableData(
        call_getSuppTb.data.map((x, i) => ({
          ...x,
          key: i + 1,
        }))
      );
    } else if (call_getSuppTb.status === "err") {
      message.error(t(`error.error-code.${call_getSuppTb.msg}`));
      set_s_tableData([]);
    }
  }, [call_getSuppTb.status]);

  useEffect(() => {
    if (call_deleteSuppTb.status === "suc") {
      message.success(call_deleteSuppTb.msg);
    } else if (call_deleteSuppTb.status === "err") {
      message.error(t(`error.error-code.${call_deleteSuppTb.msg}`));
    }
    // delete之後 重call表格資料
    call_getSuppTb.request(c_lightData);
  }, [call_deleteSuppTb.status]);

  return (
    <>
      <PageHeader
        title={t("ADM.company.manufacturerContactInformation")}
        extra={[
          // <Button type="primary" key="create" onClick={() => set_s_isShowModal(true)}>
          //   {t("util.util.add")}
          // </Button>,
          <AddButton type="primary" key="create" onClick={() => set_s_isShowModal(true)} />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getSuppTb.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.company.manufacturerContactInformation")}`
            : `${t("util.util.add")}${t("ADM.company.manufacturerContactInformation")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getSuppTb.request(c_lightData);
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default TB;
