import { useState, useEffect } from "react";
import { message, DatePicker, Space, Button, Select } from "antd";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import exportExcel from "@/util/exportExcel";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { getBearDailyStaff } from "@/service/apis/PDT/dailyStaff";
import { getStation } from "@/service/apis/ADM/publicAPI";

const DATE_FORMAT = "YYYY-MM-DD HH:00:00";

const BearTransfer = () => {
  const { t } = useTranslation();

  const [s_searchData, set_s_searchData] = useState({
    startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    wsID: [],
  });
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getBearDailyStaff = useAPI(getBearDailyStaff);
  const call_getStation = useAPI(getStation);

  const onSearch = () => {
    const searchData = {
      ...s_searchData,
      wsID: s_searchData.wsID?.length > 0 ? s_searchData.wsID.join("|") : undefined,
    };
    call_getBearDailyStaff.request(searchData);
  };

  // 更改搜尋項目
  const handleChange = (type, value) => {
    set_s_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }
      return { ...prev };
    });
  };

  const tableColumns = [
    {
      title: "部門別",
      dataIndex: "wsNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "工作中心編號[工作中心]",
      dataIndex: "pwcIDNM",
      align: "center",
      width: 200,
      isExcel: true,
    },
    {
      title: "工單單號",
      dataIndex: "woN",
      align: "center",
      isExcel: true,
    },
    {
      title: "產品品名",
      dataIndex: "pdtNM",
      align: "left",
      width: 200,

      isExcel: true,
    },
    {
      title: "產品規格",
      dataIndex: "pdtspec",
      align: "left",
      width: 200,

      isExcel: true,
    },
    {
      title: "機器名稱",
      dataIndex: "wlNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "移轉日期",
      dataIndex: "lifeFT",
      align: "center",
      isExcel: true,
    },
    {
      title: "投入量",
      dataIndex: "count",
      align: "right",
      isExcel: true,
    },
    {
      title: "移出工序",
      dataIndex: "SAPwpc",
      align: "center",
      isExcel: true,
    },
    {
      title: "移出製程",
      dataIndex: "pwcNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "不良數量",
      dataIndex: "ngC",
      align: "right",
      isExcel: true,
    },
    {
      title: "不良原因",
      dataIndex: "ngnote",
      align: "center",
      isExcel: true,
    },
    {
      title: "作業員姓名",
      dataIndex: "peopleNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "作業員編號",
      dataIndex: "staffID",
      align: "center",
      isExcel: true,
    },
    {
      title: "車次",
      dataIndex: "batchID",
      isExcel: true,
    },
  ];

  useEffect(() => {
    onSearch();
    call_getStation.request();
  }, []);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getBearDailyStaff.status === "suc") {
      message.success(call_getBearDailyStaff.msg);
      set_s_tableData(
        call_getBearDailyStaff.data.map((x, i) => ({
          ...x,
          lifeFT: dayjs(x.lifeFT).format("YYYY.MM.DD"),
          closeT: dayjs(x.closeT).format("YYYY.MM.DD"),
          count: x.okC + x.ngC,
          pwcIDNM: x.pwcID + `( ${x.pwcNM} )`,
          key: i,
        }))
      );
    } else if (call_getBearDailyStaff.status === "err") {
      message.error(t(`error.error-code.${call_getBearDailyStaff.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBearDailyStaff.status]);

  return (
    <Space direction="vertical">
      <Space>
        轉移日期：
        <DatePicker.RangePicker
          key="time"
          allowClear={false}
          showTime="HH"
          value={[dayjs(s_searchData.startDate), dayjs(s_searchData.endDate)]}
          format={DATE_FORMAT}
          onChange={(dates) => handleChange("time", dates)}
          onOpenChange={(value) => !value && onSearch()}
        />
        <Select
          className="w-[250px]"
          showSearch
          allowClear
          optionFilterProp="label"
          placeholder="請選擇工站"
          mode="multiple"
          value={s_searchData.wsID}
          options={
            call_getStation?.data?.map((x) => ({
              label: x.wsID + "_" + x.wsNM,
              value: x.wsID,
            })) || []
          }
          onChange={(value) => handleChange("wsID", value)}
        />
        <Button type="primary" onClick={onSearch}>
          查詢
        </Button>
        <Button
          danger
          type="primary"
          onClick={() =>
            exportExcel(
              tableColumns,
              s_tableData,
              `大造熊移轉報表${s_searchData.startDate}-${s_searchData.endDate}.xlsx`
            )
          }
        >
          匯出Excel
        </Button>
      </Space>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBearDailyStaff.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
    </Space>
  );
};

export default BearTransfer;
