import React, { useState, useEffect, useContext } from "react";

import { Table } from "antd";
import styled from "styled-components";
import { fontSize } from "@/styles/BasicSetting";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
import { getSpcDataSocket } from "@/service/ws/ws";
import useWebSocket from "@/hooks/useWebSocket";
import { getDrawno } from "@/service/apis/QMS/spc";
import useAPI from "@/hooks/useAPI";
import displayString from "../util/displayString";
import showDecimalPoint from "../util/showDecimalPoint";

const CustomTableStyle = styled(Table)`
  /* table 表投靠中 */
  .ant-table-thead > tr > th {
    text-align: center !important;
    background-color: #e2e1ff;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: ${fontSize.h6};
    white-space: nowrap;
    font-weight: 700;
    color: #625ecf;
    font-size: 16px;
    /* border-radius: 8px; */
  }
  .ant-table-cell {
    font-size: ${fontSize.h5};
    font-weight: 600;
    border: none;
  }

  /* // table 顯示頁碼的地方 */
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.h4};
  }

  /* // table pagination center */
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  .ant-table {
    border-radius: 8px !important;

    /* margin: 15px 0px; */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
  .ant-table-row td {
    padding: 5px !important;
  }
  .ant-table-thead {
    border-radius: 8px !important;
    color: #625ecf;
  }
  .ant-table-container {
    border-radius: 8px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:first-child {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    // border-right: 1px solid #c4c4c4;
    border-right: none;
  }

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border: none !important;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: none !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    ):before {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .once {
    background-color: ${({ theme }) => theme.tableOnce};
  }
  .twoe {
    background-color: ${({ theme }) => theme.tableTwoe};
  }

  &:hover .clickRowStyle .ant-table-cell {
    background-color: #67349a !important;
  }

  .ant-table-row-selected {
    background-color: #67349a !important;
  }
  /* .ant-table-row-selected:hover {
    background-color: #67349a !important;
  } */
  .clickRowStyle {
    background-color: #67349a !important;
    color: white;
  }
  .ant-table-row ant-table-row-level-0 clickRowStyle {
    background-color: #67349a !important;
    color: white;
  }
`;

const tableColumns = [
  {
    title: "圖號",
    dataIndex: "drawno",
    align: "center",
    width: 30,
  },
  {
    title: "CL",
    dataIndex: "CL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "2"100px"px",
  },
  {
    title: "UCL",
    dataIndex: "UCL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "250px",
  },
  {
    title: "LCL",
    dataIndex: "LCL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "250px",
  },
];

const ControlTable = (props) => {
  const { s_thisDrawno, set_s_thisDrawno, s_thisSpcNS } = useContext(WpcContext);
  const { s_DrawnoTableData, set_s_DrawnoTableData, call_getDrawno } = props;

  // call_getDrawno.request();

  // useEffect(() => {
  //   const { status, data, msg } = call_getDrawno;
  //   if (status === "suc") {
  //     // setTableData(data.map((item) => ({ ...item, key: item.sipID })));
  //   } else if (status === "err") {
  //     // setTableData([]);
  //   }
  // }, [call_getDrawno.status]);

  // const call_getSpcDataSocket = useWebSocket(getSpcDataSocket);

  // useEffect(() => {
  //   // const {status,data}

  //   if (call_getSpcDataSocket.socketData.status) {
  //     // set_s_SPCtableData([]);
  //   } else {
  //     // set_s_SPCtableData([]);
  //   }
  // }, [call_getSpcDataSocket.socketData]);

  // useEffect(() => {
  //   call_getSpcDataSocket.setIsOpen(true);
  // }, []);

  return (
    <CustomTableStyle
      loading={call_getDrawno.status === "load"}
      scroll={{ y: 410 }}
      pagination={false}
      onRow={(record) => {
        return {
          onClick: () => {
            return record.key === s_thisDrawno.key
              ? set_s_thisDrawno({})
              : set_s_thisDrawno(record);
          },
        };
      }}
      columns={tableColumns}
      dataSource={s_DrawnoTableData}
      rowClassName={(record, index) => {
        if (record.key === s_thisDrawno.key) {
          return "clickRowStyle";
        } else if ((index + 1) % 2 === 1) {
          return "bg-[#FFFFFF]"; // 奇數行
        } else {
          return "bg-[#F2F3F9]"; // 偶數行
        }
      }}
      bordered={props.Bordered}
      {...props}
    />
  );
};

export default ControlTable;
