import { Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
// api
import useAPI from "@/hooks/useAPI";
import { deleteSampleSet, getSampleSet } from "@/service/apis/QMS/SampleSet";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// component
import ModalContainer from "./ModalContainer";

// util

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  // useAPI 取得表頭資料
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_getSampleSet = useAPI(getSampleSet);
  // useAPI 刪除表頭資料
  const call_deleteSampleSet = useAPI(deleteSampleSet);
  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  // 搜尋
  const onSearch = () => {
    call_getSampleSet.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteSampleSet.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  function checkPlane0X(planID) {
    // 建立正規表示式來符合 'plane0X' 的模式，但不符合 'plane9X'
    var pattern = /^plan0\d$/;

    // 使用正規表示式的測試方法檢查變數是否符合模式
    if (pattern.test(planID)) {
      // 匹配模式
      return { style: { backgroundColor: "rgba(226, 233, 255, 1)" } };
    } else {
      // 不匹配模式
      return { style: { backgroundColor: "rgba(246, 226, 255, 1)" } };
    }
  }

  const tableColumns = [
    {
      title: "抽樣ID",
      dataIndex: "sampleID",
      align: "center",
      width: "4%",
    },
    {
      title: "抽樣條件",
      dataIndex: "sampleNM",
      align: "center",
      width: "7%",

      // width: "40%",
    },
    {
      title: "計劃ID",
      dataIndex: "planID",
      align: "center",
      width: "4%",
      onCell: (text) => checkPlane0X(text?.planID),
    },
    {
      title: "計劃名稱",
      dataIndex: "planNM",
      align: "center",
      width: "10%",
      onCell: (text) => checkPlane0X(text?.planID),
    },
    {
      title: "抽樣等級",
      dataIndex: "samplelevel",
      align: "center",
      width: "4%",
    },

    {
      title: "允收水準",
      dataIndex: "AQL",
      align: "right",
      width: "4%",
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "center",
    },

    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "4%",

      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "7%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },

    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "3%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          {/* <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          /> */}
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getSampleSet;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getSampleSet.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteSampleSet;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteSampleSet.status]);
  return (
    <>
      <CustomTable
        scroll={{ y: 850 }}
        className="mb-4"
        columns={tableColumns.filter((x) => !x.hidden)}
        // dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        dataSource={s_tableData}
        // dataSource={[]}
        loading={call_getSampleSet.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={type === "create" ? "新增抽樣計劃" : "編輯抽樣計劃"}
        width={"70%"}
        // style={{ top: 150 }}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
