import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Input, Button, Alert, Spin } from "antd";
import { CARD_DATA } from "../ControlArea/Mock/CardData";
import Card from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/Card";
import { getSpcDataSocket, getSpcOverview } from "@/service/ws/ws";
import useWebSocket from "@/hooks/useWebSocket";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
const SpcOverview = ({ s_selectedDrawno, call_getSpcOverviewSocket }) => {
  const { s_thisDrawno, set_s_thisDrawno, s_thisSpcNS } = useContext(WpcContext);

  const [s_overViewData, set_s_overViewData] = useState([]);

  useEffect(() => {
    // const {status,data}
    if (call_getSpcOverviewSocket.socketData.status) {
      const new_Data = [...call_getSpcOverviewSocket.socketData.data];
      set_s_overViewData(new_Data);
      //   set_s_XBarChartData([...new_XBarChartData]);
      //   set_s_isLoading(false);

      return;
    } else {
      // set_s_SPCtableData([]);
    }
  }, [call_getSpcOverviewSocket.socketData]);
  useEffect(() => {
    if (Object.keys(s_thisSpcNS).length === 0) {
      call_getSpcOverviewSocket.setIsOpen(false);
      return;
    }
    call_getSpcOverviewSocket.setIsOpen(true);
    call_getSpcOverviewSocket.changeParams({ ...s_thisSpcNS });
  }, [s_thisSpcNS]);
  return (
    <div className=" w-full  py-5 overflow-scroll overflow-x-hidden  ">
      <Row gutter={[12, 12]} className=" w-full">
        {s_overViewData
          .filter((item) => {
            return s_selectedDrawno.length === 0 ? item : s_selectedDrawno.includes(item.drawno);
          })
          .map((x, i) => (
            <Col span={6} key={i}>
              <Card
                data={{ ...x, key: x.drawno }}
                boardData={x.spcData}
                s_selectedDrawno={s_selectedDrawno}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default SpcOverview;
