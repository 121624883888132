import { useState, useEffect, useContext } from "react";
import { Row, Col, Input, message, Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
import apiBasic from "@/service/APIBasic";

import styled from "styled-components";

import CustomTable from "@/pages/TestPage/QMS/SPC/WpcTable/Table/Qclist";
import Light from "@/components/Light";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import Text from "@/components/MC/Text";
import Preview from "@/pages/TestPage/QMS/QMSManage/SIP/HD/ModalContainer/Preview";
import { getQmsSipHd } from "@/service/apis/QMS/QMSManage";

import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
const CustomSearch = styled(Input.Search)`
  .ant-input-search-button {
    background-color: #7c79d2;
    border: #7c79d2;
  }
  .ant-input-group-addon {
    background-color: #7c79d2;
    border: #7c79d2;
  }
`;
export default function QCList({ className, call_getQmsSipHd }) {
  const { t } = useTranslation();
  const { s_thisSpcNS, set_s_thisDrawno, set_s_thisSpcNS } = useContext(WpcContext);

  const [tableData, setTableData] = useState([]);

  const [isWpcDatailModal, setIsWpcDatailModal] = useState(false);
  const [s_queryString, set_s_QueryString] = useState("");
  const tableColumns = [
    {
      title: "控制單號", // "線別",
      dataIndex: "spcN",
      align: "center",
      width: 200,
    },
    {
      title: "sip文編",
      dataIndex: "sipID",
      align: "center",
      width: 380,
    },
    {
      title: "SIP版次",
      dataIndex: "version",
      align: "center",
      width: 80,
    },
    {
      title: t("util.util.PN"), //"料號",
      dataIndex: "PN",
      align: "center",
      width: 220,

      // className: "row-min-width col-white-space",
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      width: 300,
      // className: "row-min-width col-white-space",
    },
    // {
    //   title: t(`util.util.woN`), //工單號
    //   dataIndex: "woN",
    //   align: "center",
    // },
    // {
    //   title: t("util.util.ws"), //"工站"
    //   dataIndex: "wsID",
    //   align: "right",
    // },
    // {
    //   title: "工線",
    //   dataIndex: "wlID",
    //   align: "left",
    // },
    // {
    //   title: t("WOS.util.pwcID"), //"工作中心",
    //   dataIndex: "pwcID",
    //   align: "center",
    // },
    {
      title: t("WOS.util.typeInspection"), // "線別",
      dataIndex: "siptype",
      align: "center",
      width: 100,
    },

    {
      title: t("util.util.ctrlspot"),
      dataIndex: "ctrlspot",
      align: "center",
      width: 80,
    },
    {
      title: t("util.util.spotID"),
      dataIndex: "spotID",
    },

    {
      title: "樣本大小",
      dataIndex: "samplesize",
      align: "center",
      render: (text) => formatAmount(text),
    },
    {
      title: "工程圖檔", // "線別",
      dataIndex: "drawing",
      align: "center",
      width: 300,
      render: (text) => text && <Preview url={`${apiBasic.conn}://${apiBasic.url}/${text}`} />,
    },
  ];

  useEffect(() => {
    call_getQmsSipHd.request({ siptype: "SPC" });
  }, []);

  useEffect(() => {
    const { status, data, msg } = call_getQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      setTableData(data.map((item, index) => ({ ...item, key: item.sipID + item.version })));
      if (Object.keys(s_thisSpcNS).length === 0) return;
      const findThisSpcNS = data.find((item) => {
        return item.sipID + item.version === s_thisSpcNS.key;
      });
      set_s_thisSpcNS(findThisSpcNS || {});
      // set_s_thisSpcNS()
    } else if (status === "err") {
      message.error(msg);
      set_s_thisSpcNS({});
      set_s_thisDrawno({});
      setTableData([]);
    }
  }, [call_getQmsSipHd.status]);

  return (
    <Row className={className}>
      <Col span={20}>
        <Text level="h2" type="primary" className=" text-[#7c79d2]">
          {"檢驗標準主檔"}
        </Text>
      </Col>
      <Col span={4} className="flex-center mb-2 ">
        <CustomSearch
          placeholder={
            t("util.util.placeholder_input") + t("WOS.util.complete") + t("util.util.PN")
            //請輸入完整個工單/批號
          }
          allowClear
          enterButton
          size="large"
          // onChange={(e) => set_s_QueryString(e.target.value)}
          onSearch={(value) => {
            console.log(value);
            set_s_thisSpcNS({});
            set_s_thisDrawno({});
            call_getQmsSipHd.request({ siptype: "SPC", PN: value });
          }}
        />
        <Button
          className="bg-[#7c79d2] border-[#7c79d2] ml-2"
          size="large"
          type="primary"
          icon={<FullscreenOutlined />}
          onClick={() => {
            setIsWpcDatailModal(true);
          }}
        />
      </Col>
      {/* <Col span={1} className="flex-end ">
        <Button className="bg-[#7c79d2] border-[#7c79d2]" size="large" type="primary" icon={<FullscreenOutlined />} onClick={() => {}} />
      </Col> */}
      <Col span={24}>
        <CustomTable
          loading={call_getQmsSipHd.status === "load"}
          columns={tableColumns}
          dataSource={tableData}
          // dataSource={mock}
          size="small"
          pagination={false}
          bordered={true}
          // loading={call_getAllWpc.status === "load"}
          scroll={{ y: 210 }}
          onRow={(record) => {
            return {
              onClick: () => {
                return record.key === s_thisSpcNS.key
                  ? set_s_thisSpcNS({})
                  : set_s_thisSpcNS(record);
              },
            };
          }}
          rowClassName={(record, index) => {
            return record.key === s_thisSpcNS.key
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe";
          }}
        />
      </Col>

      {/* detailModal */}
      <CustomModal
        key="isWpcDatailModal"
        title={"檢驗標準主檔" + t("util.util.detail")}
        // title="途單索引詳情"
        visible={isWpcDatailModal}
        onCancel={() => setIsWpcDatailModal(false)}
        footer={null}
        width="100%"
      >
        <CustomTable
          loading={call_getQmsSipHd.status === "load"}
          columns={tableColumns}
          dataSource={tableData}
          // dataSource={mock}
          size="large"
          pagination={false}
          bordered={true}
          // loading={call_getAllWpc.status === "load"}
          scroll={{ y: 210 }}
          onRow={(record) => {
            return {
              onClick: () => {
                return record.key === s_thisSpcNS.key
                  ? set_s_thisSpcNS({})
                  : set_s_thisSpcNS(record);
              },
            };
          }}
          rowClassName={(record, index) => {
            return record.key === s_thisSpcNS.key
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe";
          }}
        />
      </CustomModal>
    </Row>
  );
}
