import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";

import { deleteContact, getContact } from "@/service/apis/ADM/connection";

import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const Window = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getContact = useAPI(getContact);
  const call_deleteContact = useAPI(deleteContact);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getContact.request({ item: s_searchData.query });

  const tableColumns = [
    {
      title: t("ADM.trading-pattern.contactitem"),
      dataIndex: "contactitem",
    },
    {
      title: t("ADM.trading-pattern.itemNM"),
      dataIndex: "itemNM",
    },
    {
      title: t("util.util.contact"),
      dataIndex: "contact",
    },
    {
      title: t("ADM.trading-pattern.department"),
      dataIndex: "department",
    },
    {
      title: t("util.util.tel"),
      dataIndex: "tel",
    },
    {
      title: t("ADM.trading-pattern.mobile"),
      dataIndex: "mobile",
    },
    {
      title: t("ADM.util.mail"),
      dataIndex: "mail",
      width: "10%",
    },
    {
      title: t("ADM.trading-pattern.addr"),
      dataIndex: "addr",
      width: "30%",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteContact.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getContact.status === "suc") {
      message.success(call_getContact.msg);
      console.log("call_getContact.data = ", call_getContact.data);
      set_s_tableData(
        call_getContact.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getContact.status === "err") {
      message.error(call_getContact.msg);
      set_s_tableData([]);
    }
  }, [call_getContact.status]);

  useEffect(() => {
    if (call_deleteContact.status === "suc") {
      message.success(call_deleteContact.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteContact.status === "err") {
      message.error(call_deleteContact.msg);
    }
  }, [call_deleteContact.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getContact.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.trading-pattern.tabWindow")}`
            : `${t("util.util.add")}${t("ADM.trading-pattern.tabWindow")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Window;
