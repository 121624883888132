import React, { useState, useEffect } from "react";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getCost, deleteCost } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import { formatAmount } from "@/util/format";
import ModalConintaer from "./ModalContainer";
import dayjs from "dayjs";

const Pack = ({ s_isShowModal, set_s_isShowModal, c_lightData }) => {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const call_getCost = useAPI(getCost);
  const call_deleteCost = useAPI(deleteCost);

  const tableColumns = [
    {
      title: t("ADM.pn-manage.stdcost"),
      dataIndex: "stdcost",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.varcost"),
      dataIndex: "varcost",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.inititalcost"),
      dataIndex: "inititalcost",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.purchaseqty"),
      dataIndex: "purchaseqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.purchasecost"),
      dataIndex: "purchasecost",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.settlemonth"),
      dataIndex: "settlemonth",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              e.stopPropagation();
              set_s_isShowModal(true);
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteCost.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    if (Object.keys(c_lightData).length === 0) return;
    call_getCost.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    const { status, msg, data } = call_getCost;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x) => {
          return { ...x, key: x.PN + x.psopno };
        })
      );
    } else if (status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getCost.status]);

  useEffect(() => {
    const { status, msg } = call_deleteCost;
    if (status === "suc") {
      message.success(msg);
    } else if (status === "err") {
      message.error(msg);
      message.error(t(`error.error-code.${msg}`));
    }
    call_getCost.request(c_lightData);
  }, [call_deleteCost.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.pn-manage.tabCost")}`
            : `${t("util.util.add")}${t("ADM.pn-manage.tabCost")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getCost.request(c_lightData);
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default Pack;
