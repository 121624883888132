import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";

import ProccessFlow from "./ProccessFlow"; //製程
import ProductWork from "./ProductWork";
import ProductCost from "./ProductCost";

export default function ProcedureManage() {
  const proccessFlow_ref = useRef(null);
  const productWork_ref = useRef(null);
  const productCost_ref = useRef(null);
  const { t } = useTranslation();
  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 200,
  });
  const [s_tabKey, set_s_tabKey] = useState("proccessFlow"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      item: undefined,
      page: 1,
      pageSize: 200,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    s_tabKey === "proccessFlow" && proccessFlow_ref.current.onSearch();
    s_tabKey === "productWork" && productWork_ref.current.onSearch();
    s_tabKey === "productCost" && productCost_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "proccessFlow" && proccessFlow_ref.current.onCreate();
    s_tabKey === "productWork" && productWork_ref.current.onCreate();
    s_tabKey === "productCost" && productCost_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "proccessFlow",
      label: t("ADM.procedure-manage.tabFlow"), //"製程主檔設定",
      children: (
        <ProccessFlow
          ref={proccessFlow_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "productWork",
      label: t("ADM.procedure-manage.tabWork"), //"工作中心設定",
      children: (
        <ProductWork
          ref={productWork_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "productCost",
      label: t("ADM.procedure-manage.tabCost"), //"成本中心設定",
      children: (
        <ProductCost
          ref={productCost_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("item", e.target.value)}
      placeholder={t("util.util.search")}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   查詢
    // </Button>,

    // s_tabKey !== "productCost" && (
    //   <Button type="primary" key="create" onClick={onCreate}>
    //     新增
    //   </Button>
    // ),

    <SearchButton type="primary" key="search" onClick={onSearch} />,

    s_tabKey !== "productCost" && <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.procedure-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
