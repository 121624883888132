import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { getPCC, deletePCC } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";
import dayjs from "dayjs";

const ProductCost = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getPCC = useAPI(getPCC);
  const call_deletePCC = useAPI(deletePCC);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      console.log(123);
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getPCC.request({ pccID: s_searchData.item });

  const tableColumns = [
    {
      title: t("util.util.SN"), //"序",
      dataIndex: "SN",
      align: "center",
      width: "6%",
    },
    {
      title: t("ADM.procedure-manage.pccID"), //"成本中心ID",
      dataIndex: "pccID",
      align: "center",
      sorter: (a, b) => a.pccID.localeCompare(b.pccID),
      width: "10%",
    },
    {
      title: t("util.util.name"), //"名稱",
      dataIndex: "pccNM",
      align: "left",
      width: "10%",
    },
    {
      title: t("ADM.util.pwc"), //工作中心
      dataIndex: "pwcID",
      className: "bg-[#d7f9e9ac]",
      align: "left",
      width: "15%",
      render: (_, rowData) => `${rowData.pwcID}_${rowData.pwcNM}`, //工作中心ID + 工作中心ID名稱
    },
    {
      title: t("ADM.util.pf"), //"製程",
      dataIndex: "pfID",
      className: "bg-[#f9e9d7ac]",
      align: "left",
      width: "15%",
      render: (_, rowData) => `${rowData.pfID}_${rowData.pfNM}`, //製程ID + 製程名稱
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
      // width: "10%",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "8%",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      width: "10%",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "9%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪除資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deletePCC.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_s_editData({});
    if (call_getPCC.status === "suc") {
      // message.success(call_getPCC.msg);
      set_s_tableData(
        call_getPCC.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getPCC.status === "err") {
      message.error(t(`error.error-code.${call_getPCC.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPCC.status]);

  useEffect(() => {
    if (call_deletePCC.status === "suc") {
      message.success(call_deletePCC.msg);
    } else if (call_deletePCC.status === "err") {
      message.error(t(`error.error-code.${call_deletePCC.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deletePCC.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.map((item, index) => {
          return { ...item, key: index, SN: index + 1 };
        })}
        loading={call_getPCC.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_s_editData(record);
              set_s_isShowModal(true);
            },
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.procedure-manage.tabCost")}`
            : `${t("util.util.add")}${t("ADM.procedure-manage.tabCost")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default ProductCost;
