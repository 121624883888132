/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Select, Divider, message, Space, Form } from "antd";
import { IconPlus, IconMinus } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addBdmWl, updateBdmWl } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({
  s_editData,
  set_s_isShowModal,
  s_wlOptions,
  s_pwcOptions,
}) {
  console.log("s_wlOptions = ", s_wlOptions);
  console.log("s_pwcOptions = ", s_pwcOptions);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const call_addBdmWl = useAPI(addBdmWl);
  const call_updateBdmWl = useAPI(updateBdmWl);

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat.join("|");
    }

    if (type === "create") {
      call_addBdmWl.request(values);
    }

    if (type === "edit") {
      call_updateBdmWl.request(values);
    }
  };

  useEffect(() => {
    console.log(s_editData);
  }, []);

  useEffect(() => {
    if (call_addBdmWl.status === "suc") {
      message.success(call_addBdmWl.msg);
      set_s_isShowModal(false);
    } else if (call_addBdmWl.status === "err") {
      message.error(t(`error.error-code.${call_addBdmWl.msg}`));
    }
  }, [call_addBdmWl.status]);

  useEffect(() => {
    if (call_updateBdmWl.status === "suc") {
      message.success(call_updateBdmWl.msg);
      set_s_isShowModal(false);
    } else if (call_updateBdmWl.status === "err") {
      message.error(t(`error.error-code.${call_updateBdmWl.msg}`));
    }
  }, [call_updateBdmWl.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
            <DebounceSelect
              fetchOptions={fetchPNList}
              maxTagCount={10}
              showSearch
              onChange={(value) => {
                form.setFieldsValue({ PN: value.value, pdtNM: value.title });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("WOS.util.pwcID")} name="pwcID">
            <Select options={s_pwcOptions} showSearch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("WOS.wlwoc.We")} name="wlID">
            <Select options={s_wlOptions} showSearch />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addBdmWl.status === "load" || call_updateBdmWl.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
