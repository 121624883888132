import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Space, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import apiBasic from "@/service/APIBasic";
import { getInspreport } from "@/service/apis/QMS/Inspreport";
import { getInspmethod } from "@/service/apis/QMS/Instrument";
import { addQmsSipTb, updateQmsSipTb } from "@/service/apis/QMS/QMSManage";
import { getQmsSign } from "@/service/apis/QMS/meds";
import { CheckOutlined } from "@ant-design/icons";

// 標準檢驗明細
const BlueInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: #1677ff;
  }
`;
const RedInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: rgb(220 38 38);
  }
`;

// 單位
const StyleSelect = styled(Select)`
  height: 100% !important;
  /* min-height: 90px !important; */

  .ant-select-selection-item {
    line-height: 50px !important;
  }

  .ant-select-selector {
    height: 100% !important;
    min-height: 90px !important;
    background-color: #93c5fd !important;
    text-align: center;
    font-weight: 600 !important;
    font-size: 40px !important;
    color: #1677ff;
  }

  &.ant-select-disabled .ant-select-selector {
    background-color: #93c5fd !important;
  }
`;

// 標準值
const StyleInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-input-number-input {
    /* height: 100%; */
    height: 50px !important;
    padding-right: 10px !important;
    text-align: right;
    font-weight: 700;
    font-size: 43px;
    color: #1677ff;
    &:disabled {
      background-color: #919191 !important;
    }
  }
`;

const CountInputNumber = styled(InputNumber)`
  .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffe4b5;
  }
`;

const ModalContainer = ({ s_editData, set_s_editData, c_lightData, set_s_isShowModal }) => {
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_insptype = Form.useWatch("insptype", form); // 檢驗型態
  const f_inspitem = Form.useWatch("inspitem", form); // 檢驗項目
  const f_icon = Form.useWatch("icon", form); // 圖示
  const f_isdraw = Form.useWatch("isdraw", form); // 是否工程圖

  const f_SL = Form.useWatch("SL", form); // 標準值
  const f_toleranceValue = Form.useWatch("toleranceValue", form); // 雙向公差
  const f_MAXtoleranceValue = Form.useWatch("MAXtoleranceValue", form); // 單向公差(上)
  const f_MINtoleranceValue = Form.useWatch("MINtoleranceValue", form); // 單向公差(下)

  // 新增表身資料
  const callddQmsSipTb = useAPI(addQmsSipTb);
  // 編輯表身資料
  const call_updateQmsSipTb = useAPI(updateQmsSipTb);
  const call_getQmsSign = useAPI(getQmsSign);
  const call_getInspreport = useAPI(getInspreport);
  const call_getInspmethod = useAPI(getInspmethod);

  // 從 call_getInspreport.data 中提取報告數據並生成選項列表
  const drawmark3Options = call_getInspreport.data?.map((item) => ({
    value: item.reportID, // 將 reportID 作為選項的值
    label: item.reportNM, // 將 reportNM 作為選項的標籤
  }));

  // 從 call_getQmsSign.data 中提取符號數據並生成選項列表
  const symbolOptions = call_getQmsSign.data?.map((item) => ({
    value: item?.signID, // 將 signID 作為選項的值
    label: item.twNM, // 將 twNM 作為選項的標籤
    icon: item?.icon, // 將 icon 作為選項的圖標
  }));

  // 處理按鍵事件的函數
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 阻止回車鍵的默認行為
    }
  };

  // 判斷某個字段是否禁用的邏輯函數
  const disabledLogic = (name, insptype) => {
    if (!f_inspitem) return true; // 如果 f_inspitem 為空，則返回 true（禁用）
    if (!f_insptype) return true; // 如果 f_insptype 為空，則返回 true（禁用）

    // 檢查字段和度量類型的有效性
    if (!name || typeof name !== "string" || !insptype) {
      return false;
    }

    // 定義每個字段的禁用邏輯
    const result = {
      inspgroup: { count: true, measure: !f_isdraw }, // 作業群組
      drawno: { count: true, measure: !f_isdraw }, // 圖號
      drawitem: { measure: !f_isdraw }, // 圖住
      drawarea: { count: true, measure: !f_isdraw }, // 圖區
      inspspec: { measure: !f_isdraw }, // 檢驗規格
      drawmark1: { count: true, measure: !f_isdraw }, // 圖標1
      drawmark2: { count: true, measure: !f_isdraw }, // 圖標2
      sipcond: { measure: !f_isdraw }, // 檢驗條件
      symbol: { count: true, measure: !f_isdraw }, // 尺寸符號
      UOM: { measure: f_isdraw }, // 單位
    };

    // 檢查字段是否存在於 result 中
    if (!result.hasOwnProperty(name)) {
      return false;
    }

    // 檢查度量類型是否有效
    if (!["count", "measure"].includes(insptype)) {
      return false;
    }

    // 返回該字段對應度量類型的值，默認為 false
    return result[name][insptype] || false;
  };

  // 提交表單的處理函數
  const onSubmit = (values) => {
    if (type === "create") {
      callddQmsSipTb.request({ ...values, drawing: c_lightData.drawing }); // 發送創建請求
    }
    if (type === "edit") {
      call_updateQmsSipTb.request({ ...values }); // 發送編輯請求
    }
  };

  useEffect(() => {
    call_getQmsSign.request();
    call_getInspreport.request();
    call_getInspmethod.request();

    form.setFieldsValue({ sipID: c_lightData.sipID, dcUUID: c_lightData.dcUUID });
  }, []);

  useEffect(() => {
    const { status, msg } = callddQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [callddQmsSipTb.status]);

  useEffect(() => {
    const { status, msg } = call_updateQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipTb.status]);

  // 根據檢驗方法帶入單位
  useEffect(() => {
    const { status, msg, data } = call_getInspmethod;
    if (status === "suc") {
      message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getInspmethod.status]);

  return (
    <Form
      form={form}
      onKeyDown={handleKeyDown}
      layout="vertical"
      initialValues={{
        isvalid: true,
        isdraw: true,
        // sipcond: "常溫常壓",
        symbol: "",
        itemtype: "A",
        sipcond: "NA",
        isdraw: false,
        ...s_editData,
        UOM: s_editData?.UOM !== "" ? s_editData?.UOM : "mm",
        icon:
          type === "edit" && s_editData.icon !== ""
            ? `${apiBasic.conn}://${apiBasic.url}/${s_editData.icon}`
            : "",
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
        drawing: type === "edit" && s_editData.drawing ? s_editData.drawing.split("/").pop() : "",
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="drawing" hidden>
          <Input />
        </Form.Item>
        <Form.Item label={t("util.util.itemno")} name="itemno" hidden>
          <Input placeholder="系統預設" disabled />
        </Form.Item>
      </div>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]} label={"檢驗項目"} name="inspitem">
            <Input
              onChange={(e) => {
                if (f_insptype === "measure" && type === "create") {
                  form.setFieldsValue({ drawno: e.target.value });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={f_insptype === "measure" ? 4 : 6}>
          <Form.Item rules={[{ required: true }]} label={"檢驗型態"} name="insptype">
            <Select
              className="w-full"
              onChange={(value) => {
                if (value === "count") {
                  form.setFieldsValue({
                    drawno: "",
                    drawitem: "",
                    drawarea: "",
                    drawmark1: "",
                    drawmark2: "",
                    drawmark3: "",
                    SL: null,
                    UOM: "",
                    toleranceValue: null,
                    MAXtoleranceValue: null,
                    MINtoleranceValue: null,
                    USL: null,
                    LSL: null,
                    UCL: null,
                    LCL: null,
                  });
                  return;
                }
                if (value === "measure") {
                  form.setFieldsValue({ UOM: "mm", drawno: f_inspitem });
                  return;
                }
                form.setFieldsValue({ drawno: "" });
              }}
              disabled={!f_inspitem}
              allowClear
              options={[
                { label: "計數型(定性)", value: "count" },
                { label: "計量型(定量)", value: "measure" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={f_insptype === "measure" ? 2 : 0}>
          <Form.Item label="是否工程圖" name="isdraw" hidden={f_insptype !== "measure"}>
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"檢驗規格"} name="inspspec">
            <Input disabled={disabledLogic("inspspec", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="缺陷類別" name="itemtype">
            <Select
              disabled={disabledLogic("itemtype", f_insptype)}
              className="w-full"
              allowClear
              options={[
                { value: "A", label: "主要缺陷" },
                { value: "B", label: "次要缺陷" },
                { value: "C", label: "嚴重缺陷" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"管理表單"} name="drawmark3">
            <Select disabled={disabledLogic("drawmark3", f_insptype)} options={drawmark3Options} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"圖號"} name="drawno">
            <Input disabled={disabledLogic("drawno", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={f_insptype === "count" ? "規格要求" : "圖註"} name="drawitem">
            <Input disabled={disabledLogic("drawitem", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖區"} name="drawarea">
            <Input disabled={disabledLogic("drawarea", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="檢驗方法" name="gpID">
            <Select
              showSearch
              optionFilterProp="label"
              disabled={disabledLogic("gpID", f_insptype)}
              allowClear
              options={
                call_getInspmethod?.data.map((x) => ({
                  label: x.gpID + "_" + x.UOM,
                  value: x.gpID,
                  data: x,
                })) || []
              }
              onChange={(_, data) => {
                form.setFieldsValue({ UOM: data?.data?.UOM !== "" ? data?.data?.UOM : "mm" });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖標1"} name="drawmark1">
            <Input disabled={disabledLogic("drawmark1", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖標2"} name="drawmark2">
            <Input disabled={disabledLogic("drawmark2", f_insptype)} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="inspgroup" label="作業群組">
            <Input disabled={disabledLogic("inspgroup", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row
            gutter={[12, 12]}
            className={`bg-yellow-50 pb-2 ${(f_insptype === "count" || !f_insptype) && "hidden"}`}
          >
            <Col span={12}>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Row>
                    {/* 圖示 */}
                    <Col span={4}>
                      <Form.Item
                        className="h-full mb-[0px]"
                        label={<span className="text-blue-600 font-bold ">圖示</span>}
                        name="icon"
                      >
                        {f_icon === "" ? null : (
                          <div className=" flex justify-center items-center h-[88px]">
                            <img
                              className="object-contain block w-full h-full rounded-md "
                              src={f_icon}
                              alt="無圖"
                            />
                          </div>
                        )}
                      </Form.Item>
                    </Col>

                    {/* 標準值 */}
                    <Col span={12}>
                      <Form.Item
                        className="h-full mb-[0px] "
                        label={<span className="text-blue-600 font-bold">標準值</span>}
                        name="SL"
                      >
                        <StyleInputNumber
                          precision={2}
                          className="w-full h-[90px] font-bold bg-blue-300"
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // 移除格式中的非數字字符
                          disabled={f_insptype === "count"}
                          onChange={(e) => {
                            form.setFieldsValue({ SL: e, USL: e, LSL: e, UCL: e, LCL: e });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {/* 單位 */}
                    <Col span={8} className="w-full">
                      <Form.Item
                        name="UOM"
                        label={<span className="text-blue-600 font-bold">單位</span>}
                        className="h-full mb-[0px]"
                      >
                        <StyleSelect
                          options={[
                            { label: "mm", value: "mm" },
                            { label: "µm", value: "µm" },
                            { label: "Kg", value: "Kg" },
                            { label: "g", value: "g" },
                            { label: "°C", value: "°C" },
                            { label: "°F", value: "°F" },
                            { label: "Mpa", value: "Mpa" },
                            { label: "HB", value: "HB" },
                            { label: "HR", value: "HR" },
                            { label: "HV", value: "HV" },
                            { label: "%", value: "%" },
                          ]}
                          disabled={disabledLogic("UOM", f_insptype)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item name="symbol" label="尺寸符號" className="mb-[0px]">
                        <Select
                          disabled={disabledLogic("symbol", f_insptype)}
                          options={[{ label: "NA", value: "", icon: "" }, ...symbolOptions]}
                          onChange={(value, rowData) => {
                            if (value === "") {
                              form.setFieldsValue({
                                icon: "",
                              });
                              return;
                            }
                            form.setFieldsValue({
                              icon: `${apiBasic.conn}://${apiBasic.url}/${rowData.icon}`,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={12} className="flex justify-between flex-col ">
                  <div className="flex w-full justify-between items-end">
                    <Form.Item name="toleranceValue" label="雙向公差" className="mb-[0px] w-[80%] ">
                      <CountInputNumber
                        precision={2}
                        step={0.1}
                        className="w-full bg-[#FFE4B5]"
                        size="large"
                        controls={false}
                        disabled={f_insptype === "count"}
                      />
                    </Form.Item>

                    <Button
                      type="text"
                      stringMode={true}
                      className=" bg-[#FFE4B5] h-[65%]"
                      onClick={() => {
                        console.log(f_toleranceValue);
                        if (f_toleranceValue === null || f_toleranceValue === 0) {
                          form.setFieldsValue({
                            USL: f_SL,
                            LSL: f_SL,
                            UCL: f_SL,
                            LCL: f_SL,
                            MAXtoleranceValue: 0,
                            MINtoleranceValue: 0,
                          });
                          return;
                        }
                        form.setFieldsValue({
                          USL: f_SL + f_toleranceValue,
                          LSL: f_SL - f_toleranceValue,
                          UCL: f_SL + f_toleranceValue,
                          LCL: f_SL - f_toleranceValue,
                          MAXtoleranceValue: 0,
                          MINtoleranceValue: 0,
                        });
                      }}
                    >
                      <CheckOutlined className="text-[20px]" />
                    </Button>
                  </div>

                  <div className="flex w-full justify-between  items-end">
                    <Form.Item
                      name="MAXtoleranceValue"
                      label="單向公差(上)"
                      className="mb-[0px] w-[37%] "
                    >
                      <CountInputNumber
                        precision={2}
                        className="w-full "
                        size="large"
                        controls={false}
                        disabled={f_insptype === "count"}
                      />
                    </Form.Item>

                    <Form.Item
                      name="MINtoleranceValue"
                      label="單向公差(下)"
                      className="mb-[0px] w-[37%] "
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            value > f_MAXtoleranceValue
                              ? callback("不可大於單向公差(上)")
                              : callback();
                          },
                        },
                      ]}
                    >
                      <CountInputNumber
                        precision={2}
                        className=" w-full bg-[#FFE4B5]"
                        size="large"
                        controls={false}
                        disabled={f_insptype === "count"}
                      />
                    </Form.Item>

                    <Button
                      type="text"
                      className=" bg-[#FFE4B5] h-[65%]"
                      onClick={() => {
                        console.log(f_MAXtoleranceValue);
                        console.log(f_MINtoleranceValue);
                        const max_value = f_MAXtoleranceValue ? f_MAXtoleranceValue : 0;
                        const min_value = f_MINtoleranceValue ? f_MINtoleranceValue : 0;

                        form.setFieldsValue({
                          USL: f_SL + max_value,
                          UCL: f_SL + max_value,
                          LSL: f_SL + min_value,
                          LCL: f_SL + min_value,
                          toleranceValue: 0,
                        });
                      }}
                    >
                      <CheckOutlined className="text-[20px]" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[12, 12]} className=" h-full">
                <Col span={12}>
                  <Form.Item
                    label={<span className="text-blue-600 font-bold">規格上限</span>}
                    name="USL"
                    className="mb-[8px]"
                  >
                    <BlueInputNumber
                      size="large"
                      precision={2}
                      step={0.1}
                      className="w-full mb-[0px] text-blue-600 font-bold"
                      onChange={(value) => form.setFieldValue("UCL", value)}
                      placeholder="請輸入規格上限"
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={<span className="text-red-600 font-bold">管制上限</span>}
                    name="UCL"
                    className="mb-[0px]"
                  >
                    <RedInputNumber
                      size="large"
                      precision={2}
                      step={0.1}
                      className="w-full"
                      placeholder="請輸入管制上限"
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="flex items-end ">
                  <Form.Item
                    label={<span className="text-blue-600 font-bold">規格下限</span>}
                    name="LSL"
                    className="mb-[0px] w-full"
                  >
                    <BlueInputNumber
                      size="large"
                      precision={2}
                      step={0.1}
                      // formatter={(value) => formatAmount(value)}
                      className="w-full"
                      onChange={(value) => form.setFieldValue("LCL", value)}
                      placeholder="請輸入規格下限"
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className="flex items-end ">
                  <Form.Item
                    label={<span className="text-red-600 font-bold">管制下限</span>}
                    name="LCL"
                    className="mb-[0px] w-full"
                  >
                    <RedInputNumber
                      size="large"
                      precision={2}
                      step={0.1}
                      className="w-full"
                      placeholder="請輸入規格下限"
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Form.Item label="檢測環境要求" name="sipcond">
            <Input disabled={f_insptype === "measure"} placeholder="請輸入檢測環境要求" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea className="w-full" rows={1} />
          </Form.Item>
        </Col>
        <Col span={6} className="flex justify-start ">
          <Form.Item label={t("ADM.pn-manage.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={12} className="flex justify-end">
          <Space>
            <Button
              onClick={() => set_s_isShowModal(false)}
              loading={callddQmsSipTb.status === "load" || call_updateQmsSipTb.status === "load"}
            >
              {t("util.util.cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={callddQmsSipTb.status === "load" || call_updateQmsSipTb.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
