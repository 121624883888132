import { Modal, Select, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util component
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader/index";
// API
import useAPI from "@/hooks/useAPI";
import { getPWC } from "@/service/apis/ADM/publicAPI";
import { deleteQmsApPwcHd, getQmsApPwcHd } from "@/service/apis/QMS/Apui";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// component
import ModalConintaer from "./ModalContainer/index";

const HD = (props) => {
  const { c_lightData, set_c_lightData } = props;
  const { t } = useTranslation();
  const call_getQmsApPwcHd = useAPI(getQmsApPwcHd);
  const call_deleteQmsApPwcHd = useAPI(deleteQmsApPwcHd);

  const call_getPWC = useAPI(getPWC);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const ctrlspotOptions = {
    "PN": "料號",
    "GP": "群組",
    "PG": "料號+群組",
    "WC": "工作中心",
  };
  const [s_searchData, set_s_searchData] = useState({
    pwcID: "",
  });

  // 刪除資料
  const remove = (rowData) => {
    set_c_lightData({});
    Modal.confirm({
      title: "製程標準資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的製程標準資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteQmsApPwcHd.request(rowData),
      cancelText: "取消",
    });
  };

  const tableColumns = [
    {
      title: t("ADM.procedure-manage.pwcID"), // 工作中心ID
      dataIndex: "pwcID",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.pwcID - b.pwcID,
    },
    {
      title: t("ADM.procedure-manage.pwcNM"), // 工作中心名稱
      dataIndex: "pwcNM",
      width: "15%",
      sorter: (a, b) => a.pwcNM - b.pwcNM,
    },
    {
      title: "介面ID",
      dataIndex: "uiID",
      width: "10%",
    },
    {
      title: "版次",
      dataIndex: "ver",
      width: "5%",
      align: "center",
    },
    {
      title: "介面名稱",
      dataIndex: "uiNM",
      width: "20%",
    },
    // {
    //   title: "控點",
    //   dataIndex: "ctrlspot",
    //   align: "center",
    //   width: "7%",
    //   render: (text) => (ctrlspotOptions[text]),
    // },
    // {
    //   title: "控點編號",
    //   dataIndex: "spotID",
    //   width: "8%",
    //   align: "center",
    //   // render: (text) => {
    //   //   const map = {
    //   //     "model": "機種",
    //   //     "querycat": "查詢分類",
    //   //     "attribute": "物料屬性",
    //   //     "mgmtcat": "授權分類",
    //   //   };
    //   //   return map[text];
    //   // },
    // },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "20%",
    },
    {
      title: t("util.util.updtT"), //"更新時間",
      dataIndex: "updtT",
      width: "8%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    // {
    //   title: t("util.util.isvalid"), // 是否有效
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: "5%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              remove(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getQmsApPwcHd.request();
    call_getPWC.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getQmsApPwcHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
          index: i,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getQmsApPwcHd.status]);

  useEffect(() => {
    if (call_deleteQmsApPwcHd.status === "suc") {
      message.success(call_deleteQmsApPwcHd.msg);
      //刪除後要更新資料
      call_getQmsApPwcHd.request();
    }
    if (call_deleteQmsApPwcHd.status === "err") {
      message.error(call_deleteQmsApPwcHd.msg);
    }
  }, [call_deleteQmsApPwcHd.status]);
  console.log(s_searchData);
  return (
    <>
      <PageHeader
        // title={t("ADM.client.pageHeader")}
        title="製程管制項目維護"
        extra={[
          <Select
            // disabled={!f_wsID}
            allowClear
            defaultValue={""}
            size="large"
            className="w-[400px]"
            onChange={(value) => {
              set_s_searchData((pre) => {
                return { ...pre, pwcID: value };
              });
              // call_getQmsApPwcHd.request({ pwcID: value });
            }}
            options={
              call_getPWC.data.reduce(
                (data, current) => {
                  data.push({
                    label: `${current.pwcID}_${current.pwcNM}`,
                    value: current.pwcID,
                    data: current,
                  });
                  return data;
                },
                [{ label: "All_全部", value: "" }]
              ) || []
            }
          />,
          <SearchButton
            type="primary"
            key="search"
            onClick={() => {
              call_getQmsApPwcHd.request(s_searchData);
            }}
          />,
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getQmsApPwcHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key
              ? set_c_lightData({})
              : set_c_lightData({ ...record, itemno: 1 }),
        })}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}製程管制項目`
            : `${t("util.util.add")}製程管制項目
            `
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          set_s_editData({});
          call_getQmsApPwcHd.request();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default HD;
