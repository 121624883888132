/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getStation, getPF } from "@/service/apis/ADM/publicAPI";
import { getNgItem, deleteNgItem } from "@/service/apis/ADM/StringManage";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalContainer from "./ModalContainer";
import { multipleIDtoNameWithFullWidth } from "@/util/format";

const NgItem = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // useAPI 取得表頭資料
  const call_getNgItem = useAPI(getNgItem);
  // useAPI 刪除表頭資料
  const call_deleteNgItem = useAPI(deleteNgItem);
  // useAPI 取得工站資料
  const call_getStation = useAPI(getStation);
  //useAPI 取得製程資料
  const call_getPF = useAPI(getPF);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const pfArr = [{ pfID: "All", pfNM: t("util.util.all") }]?.concat(call_getPF?.data)

  const onSearch = () => call_getNgItem.request({ item: s_searchData.query });

  const tableColumns = [
    {
      title: t("util.util.itemID"), // 項目ID
      dataIndex: "itemID",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.itemNM"), // 項目名稱
      dataIndex: "itemNM",
      width: "20%",
    },
    {
      title: t("ADM.util.pfID"), // 製程
      dataIndex: "pfID",
      width: "20%",
      // render: (text) => findpfNM(text)?.join(","),
      render: (text) => multipleIDtoNameWithFullWidth(pfArr, text, "pfID", "pfNM")
    },
    {
      title: t("ADM.mold.wsID"), // 工站
      dataIndex: "wsID",
      width: "20%",
      // render: (text) => text?.map((item) => ws_map[item])?.join(","),
      render: (text) => multipleIDtoNameWithFullWidth(call_getStation?.data, text, "wsID", "wsNM")
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "10%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.note"), // 註記
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];


  const ws_map = useMemo(() => {
    return call_getStation.data?.reduce(
      (prev, curr) => {
        prev[curr.wsID] = curr.wsNM;
        return prev;
      },
      { "All": t("util.util.all") }
    );
  }, [call_getStation.status]);

  //找出製程名稱
  // const findpfNM = (arr) => {
  //   let pfNM = []
  //   for (let index = 0; index < arr.length; index++) {
  //     pfArr?.find((item) => {
  //       if (item.pfID === arr[index]) {
  //         pfNM.push(item.pfNM)
  //       }
  //     })
  //   }
  //   return pfNM
  // }


  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => { call_deleteNgItem.request(rowData) },
      cancelText: t("util.util.cancel"),
    });
  };
  useEffect(() => {
    call_getStation.request();
    call_getPF.request();
  }, []);

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getNgItem.status === "suc") {
      message.success(call_getNgItem.msg);
      set_s_tableData(
        call_getNgItem.data.map((x, i) => {
          const wsID = x.wsID?.split("|")?.filter((x) => x !== "") || [];
          const pfID = x.pfID?.split("|")?.filter((x) => x !== "") || [];
          return {
            ...x,
            key: i,
            wsID: wsID,
            pfID: pfID
          };
        })
      );
    } else if (call_getNgItem.status === "err") {
      message.error(t(`error.error-code.${call_getNgItem.msg}`));
      set_s_tableData([]);
    }
  }, [call_getNgItem.status]);

  useEffect(() => {
    if (call_deleteNgItem.status === "suc") {
      message.success(call_deleteNgItem.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteNgItem.status === "err") {
      message.error(t(`error.error-code.${call_deleteNgItem.msg}`));
    }
  }, [call_deleteNgItem.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getNgItem.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.string-manage.tabNgItem")}`
            : `${t("util.util.add")}${t("ADM.string-manage.tabNgItem")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          call_getStation={call_getStation}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          pfArr={pfArr}
        />
      </CustomModal>
    </>
  );
});

export default NgItem;
