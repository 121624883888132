import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const getEqgp = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getEqgp`, {
    params: _params,
  });
};

export const getEqgpCompare = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getEqgpCompare`, {
    params: _params,
  });
};

export const addEqgp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addEqgp`, data);
};

export const updateEqgp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateEqgp`, data);
};

export const deleteEqgp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteEqgp`, data);
};
export const deleteEqDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteEqDoc`, data);
};

export const uploadEq = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadEq`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
