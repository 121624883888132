import React from "react";
import { Upload, Button, Input, Form, InputNumber, message, Col } from "antd";
import { FormListFieldData } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { LabelItemObj } from "../../../../types/type";

// 將圖片轉換為 Base64 格式
const getBase64 = (img: File, callback: (imageUrl: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const handleChange = (info: any) => {
  if (info.fileList[0]?.originFileObj) {
    getBase64(info.fileList[0].originFileObj, (imageUrl) => imageUrl);
  } else {
    message.error("上傳失敗");
  }
};

const beforeUpload = (file: File) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("你只能上傳 JPG/PNG 圖片檔案!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("圖片必須小於 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const normFile = (e: any) => e;

const LabelImage = (field: FormListFieldData, rowData: LabelItemObj, renderDraw: any) => {
  const uploadProps = {
    name: "file",
    accept: ".jpg,.png",
    showUploadList: false,
    customRequest: async (option: any) => {
      renderDraw();
      option.onSuccess("");
    },
    maxCount: 1,
    onChange: handleChange,
    beforeUpload: beforeUpload,
  };
  return (
    <>
      <Form.Item name="imgName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="imgUrl" hidden>
        <Input />
      </Form.Item>
      <Col span={6}>
        <Form.Item label="長度" name={[field.name, "len"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="寬度" name={[field.name, "boxWidth"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>

      {rowData.imgName === "" ? (
        <Col span={6}>
          <Form.Item
            label="圖片上傳 (.jpg , .png)"
            name={[field.name, "img"]}
            shouldUpdate
            valuePropName="file"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />} block>
                圖片上傳
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={6}>
            <Form.Item label="圖片名稱" name={[field.name, "imgName"]}>
              <Input disabled className="text-black" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="圖片上傳 (.jpg , .png)"
              name={[field.name, "img"]}
              shouldUpdate
              valuePropName="file"
              getValueFromEvent={normFile}
              rules={[{ required: true }]}
            >
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} block>
                  重新上傳
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
};

export default LabelImage;
