/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getBdmAir, deleteBdmAir } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer";

const Air = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getBdmAir = useAPI(getBdmAir);
  const call_deleteBdmAir = useAPI(deleteBdmAir);

  const onSearch = () => {
    call_getBdmAir.request(s_searchData);
  };

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
    onSearch: onSearch,
  }));

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      // align: "center",
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: "品名簡碼",
      dataIndex: "ailas",
      align: "center",
      // width: "8%",
    },
    {
      title: "圖面版次",
      dataIndex: "version",
      align: "center",
      // width: "4%",
    },
    {
      title: "鍛胚",
      dataIndex: "forging",
      align: "center",
      // width: "4%",
    },
    {
      title: "鏇胚",
      dataIndex: "spin",
      align: "center",
      // width: "4%",
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
      // width: "4%",
    },

    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteBdmAir.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getBdmAir.status === "suc") {
      message.success(call_getBdmAir.msg);
      set_s_tableData(
        call_getBdmAir.data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getBdmAir.status === "err") {
      message.error(t(`error.error-code.${call_getBdmAir.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBdmAir.status]);

  useEffect(() => {
    if (call_deleteBdmAir.status === "suc") {
      message.success(call_deleteBdmAir.msg);
      onSearch();
    } else if (call_deleteBdmAir.status === "err") {
      message.error(t(`error.error-code.${call_deleteBdmAir.msg}`));
      onSearch();
    }
    // delete之後 重call表格資料
  }, [call_deleteBdmAir.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBdmAir.status === "load"}
        s_editData={s_editData}
        indentSize={50}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}航太廠`
            : `${t("util.util.add")}航太廠`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Air;
