/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getBdmTech, deleteBdmTech } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer";

const Air = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getBdmTech = useAPI(getBdmTech);
  const call_deleteBdmTech = useAPI(deleteBdmTech);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      width: "20%",
    },
    {
      title: "標準重量1",
      dataIndex: "stdweight1",
    },
    {
      title: "標準重量2",
      dataIndex: "stdweight2",
    },
    {
      title: "標準重量3",
      dataIndex: "stdweight3",
    },
    {
      title: "標準重量4",
      dataIndex: "stdweight4",
    },
    {
      title: "標準重量5",
      dataIndex: "stdweight5",
    },
    {
      title: "標準重量6",
      dataIndex: "stdweight6",
    },
    {
      title: "標準重量7",
      dataIndex: "stdweight7",
    },

    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteBdmTech.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getBdmTech.status === "suc") {
      message.success(call_getBdmTech.msg);
      set_s_tableData(
        call_getBdmTech.data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getBdmTech.status === "err") {
      message.error(t(`error.error-code.${call_getBdmTech.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBdmTech.status]);

  useEffect(() => {
    if (call_deleteBdmTech.status === "suc") {
      message.success(call_deleteBdmTech.msg);
      // delete之後 重call表格資料
      call_getBdmTech.request();
    } else if (call_deleteBdmTech.status === "err") {
      message.error(t(`error.error-code.${call_deleteBdmTech.msg}`));
      // delete之後 重call表格資料
      call_getBdmTech.request();
    }
  }, [call_deleteBdmTech.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBdmTech.status === "load"}
        s_editData={s_editData}
        indentSize={50}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}科技廠`
            : `${t("util.util.add")}科技廠`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getBdmTech.request();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Air;
