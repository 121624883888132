import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Form, Button, Row, Col, Input, Select, Divider, message, Space, InputNumber } from "antd";
import { getAllWhID, updateTxnTb } from "@/service/apis/WMS/WMS";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
const ModalContainer = ({ s_editData, set_s_editData, set_s_showModal, c_lightData }) => {
  const [form] = Form.useForm();

  const [s_AllWh, set_s_AllWh] = useState([]); // 全部倉別資料 options
  const { t } = useTranslation();
  const call_updateTxnTb = useAPI(updateTxnTb);
  const call_getAllWhID = useAPI(getAllWhID);

  useEffect(() => {
    call_getAllWhID.request();
    form.setFieldsValue({ ...s_editData, sqty: null });
    return () => {
      set_s_editData({});
    };
  }, []);

  useEffect(() => {
    if (call_updateTxnTb.status === "suc") {
      message.success(call_updateTxnTb.msg);
      set_s_showModal("false");
    } else if (call_updateTxnTb.status === "err") {
      message.error(call_updateTxnTb.err);
    }
  }, [call_updateTxnTb.status]);

  useEffect(() => {
    if (call_getAllWhID.status === "suc") {
      set_s_AllWh(
        call_getAllWhID.data.map((x) => (
          <Select.Option key={x.whID} value={x.whID}>
            {x.whNM}
          </Select.Option>
        ))
      );
      message.success(call_getAllWhID.msg);
    } else if (call_getAllWhID.status === "err") {
      message.error(call_getAllWhID.msg);
    }
  }, [call_getAllWhID.status]);

  const handleCancel = () => {
    set_s_showModal("false");
  };

  const onSubmit = async (values) => {
    const tmpValues = {
      ...values,
      ...c_lightData,
      ...s_editData,
      // opcode: c_lightData.opcode,
      sqty: parseInt(values.sqty),
    };
    call_updateTxnTb.request(tmpValues);
  };

  return (
    <>
      <Form onFinish={onSubmit} name="basic" form={form} autoComplete="off" layout="vertical">
        <Row gutter={[12, 12]}>
          <Col span={6}>
            {/* <Form.Item label={<label style={{ color: "#6C6C6C" }}>料號</label>} name="PN">
              <Input disabled />
            </Form.Item> */}
            <Form.Item
              //料號
              label={t("util.util.PN")} name="PN">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            {/* <Form.Item label={<label style={{ color: "#6C6C6C" }}>過帳型態</label>} name="idmtype">
              <Select disabled placeholder="請選擇作業單別">
                <Select.Option value="S">人工計數</Select.Option>
                <Select.Option value="D">條碼計數</Select.Option>
                <Select.Option value="M">混合計數</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              //過帳型態
              label={t("util.util.idmtype")} name="idmtype">
              <Select
                disabled
                options={[
                  { label: t("util.util.idmtype_s"), value: "S" },
                  { label: t("util.util.idmtype_d"), value: "D" },
                  { label: t("util.util.idmtype_m"), value: "M" },
                ]}
              />
            </Form.Item>
          </Col>


          <Col span={6}>
            {/* <Form.Item
              label={<label style={{ color: "#6C6C6C" }}>倉別</label>}
              name="whID"
              rules={[{ required: true, message: "請選擇倉別!" }]}
            >
              <Select placeholder="選擇倉別" allowClear>
                {s_AllWh}
              </Select>
            </Form.Item> */}
             <Form.Item
            //倉別
            label={t("util.util.whID")} name="whID" rules={[{ required: true }]}>
            <Select
              allowClear
              options={
                call_getAllWhID.data?.map((x) => ({
                  label: x.whNM,
                  value: x.whID,
                })) || []
              }
            />
          </Form.Item>
          </Col>

          <Col span={6}>
            {/* <Form.Item
              label={<label style={{ color: "#6C6C6C" }}>人工輸入</label>}
              name="sqty"
              rules={[{ required: true, message: "請人工輸入數量!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item> */}
            <Form.Item
            //人工輸入
            label={t("util.util.sqty")} name="sqty" rules={[{ required: true }]}>
            <InputNumber className="w-full" />
          </Form.Item>
          </Col>

          <Divider />
          <Col span={24} className="flex justify-end">
            <Space>
              <Button size="large" onClick={handleCancel}>
              {t("util.util.cancel")}
              </Button>
              <Button size="large" type="primary" htmlType="submit"
               loading={call_updateTxnTb.status==="load"}>
              {t("util.util.ok")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ModalContainer;
