import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Input, Button, Alert, Spin, Upload, message } from "antd";
import PageHeader from "@/components/PageHeader";
import ControlTable from "@/pages/TestPage/QMS/SPC/ControlTable";
import { useTranslation } from "react-i18next";
import QCList from "@/pages/TestPage/QMS/SPC/WpcTable/index";
import { FullscreenOutlined, UploadOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { CARD_DATA } from "./ControlArea/Mock/CardData";
import {
  IconExcel,
  IconSetting,
  IconExcelUpload,
  IconOpenModal,
  IconFoldedit,
} from "@/components/Icon/Action";
import Card from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/Card";
import SpcOverview from "./ModalContainer/SpcOverview";
import XBarChart from "@/pages/TestPage/QMS/SPC/ModalContainer/XBarChart";
import AlertCard from "@/pages/TestPage/QMS/SPC/Alert/index";
import ControlArea from "@/pages/TestPage/QMS/SPC/ControlArea";
import InformationBoard from "@/pages/TestPage/QMS/SPC/InformationBoard";
import Text from "@/components/MC/Text";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
import { deleteQmsSipHd, getQmsSipHd, updateQmsSipHd } from "@/service/apis/QMS/QMSManage";
import { getSpcDataSocket, getSpcOverview } from "@/service/ws/ws";

import useWebSocket from "@/hooks/useWebSocket";
import { getDrawno, importData } from "@/service/apis/QMS/spc";
import useAPI from "@/hooks/useAPI";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import displayString from "./util/displayString";
import BatchImport from "./ModalContainer/BatchImport";

const CustomSearch = styled(Input.Search)`
  .ant-input-search-button {
    background-color: #7c79d2;
    border: #7c79d2;
  }
  .ant-input-group-addon {
    background-color: #7c79d2;
    border: #7c79d2;
  }
`;

export default function SPC() {
  const { t } = useTranslation();
  const [s_thisDrawno, set_s_thisDrawno] = useState({});
  const [s_thisSpcNS, set_s_thisSpcNS] = useState({});

  const [s_checked, set_s_checked] = useState(false);

  const [s_selectedDrawno, set_s_selectedDrawno] = useState([]);

  const [s_cardModal, set_s_cardModal] = useState(false);
  const [s_transferModal, set_s_transferModal] = useState(false);

  const [s_DrawnoTableData, set_s_DrawnoTableData] = useState([]);

  const [s_XBarChartData, set_s_XBarChartData] = useState([]);

  const call_getSpcDataSocket = useWebSocket(getSpcDataSocket);
  const call_getSpcOverviewSocket = useWebSocket(getSpcOverview);

  const call_getDrawno = useAPI(getDrawno);
  const call_importData = useAPI(importData);

  const call_getQmsSipHd = useAPI(getQmsSipHd);

  const [s_isLoading, set_s_isLoading] = useState(false);

  const props = {
    beforeUpload: () => false,
    name: "file",
    showUploadList: false,
    maxCount: 1,
    onChange: ({ file }) => {
      console.log(file);
      set_s_isLoading(true);
      call_importData.request({ ...s_thisDrawno, merge: s_checked, file });
    },
  };

  useEffect(() => {
    // const {status,data}
    if (call_getSpcDataSocket.socketData.status) {
      const new_XBarChartData = [
        ...call_getSpcDataSocket.socketData?.data?.map((item) => ({
          ...item,
          data: item.data.map((data) => ({
            ...data,
            CL: s_thisDrawno["CL"],
            LCL: s_thisDrawno["LCL"],
            UCL: s_thisDrawno["UCL"],
            LSL: s_thisDrawno["LSL"],
            USL: s_thisDrawno["USL"],
          })),
        })),
      ];

      set_s_XBarChartData(new_XBarChartData);
      set_s_isLoading(false);

      return;
    } else {
      // set_s_SPCtableData([]);
    }
  }, [call_getSpcDataSocket.socketData]);

  useEffect(() => {
    // call_getDrawno.request();
  }, []);

  useEffect(() => {
    if (Object.keys(s_thisDrawno).length === 0) {
      call_getSpcDataSocket.setIsOpen(false);
      set_s_XBarChartData([]);
      return;
    }
    set_s_isLoading(true);
    call_getSpcDataSocket.setIsOpen(true);
    call_getSpcDataSocket.changeParams({ ...s_thisDrawno, queryType: "", query: "" });
  }, [s_thisDrawno]);
  useEffect(() => {
    if (Object.keys(s_thisSpcNS).length === 0) {
      call_getSpcOverviewSocket.setIsOpen(false);
      set_s_DrawnoTableData([]);
      set_s_thisDrawno({});
      return;
    }
    set_s_thisDrawno({});
    //獲取  call_getDrawno
    call_getDrawno.request({ ...s_thisSpcNS });
  }, [s_thisSpcNS]);


  useEffect(() => {
    const { status, data, msg } = call_importData;
    if (status === "suc") {
      message.success(msg);
      set_s_isLoading(false);
      set_s_thisDrawno({});
      call_getDrawno.request({ ...s_thisSpcNS });
    } else if (status === "err") {
    }
  }, [call_importData.status]);
  useEffect(() => {
    const { status, data, msg } = call_getDrawno;
    if (status === "suc") {
      set_s_DrawnoTableData(data.map((item) => ({ ...item, key: item.drawno })));
      if (data.length === 0) return;
      if (Object.keys(s_thisDrawno).length === 0) {
        console.log(data[0]);
        set_s_thisDrawno({ ...data[0], key: data[0].drawno });
      }
    } else if (status === "err") {
      set_s_DrawnoTableData([]);
    }
  }, [call_getDrawno.status]);

  return (
    <WpcContext.Provider
      value={{ s_thisSpcNS, set_s_thisSpcNS, s_thisDrawno, set_s_thisDrawno, s_DrawnoTableData }}
    >
      <PageHeader
        title={<div className="bg-[#7a71f8]  text-white rounded-lg p-2">統計製程管制</div>}
        className={"mb-0 relative"}
        extra={[
          <div className="w-[25vw] absolute  top-0 right-0   flex justify-between">
            <Upload {...props}>
              <Button
                className="bg-[#7a71f8] border-[#7a71f8] ml-2"
                size="large"
                type="primary"
                disabled={Object.keys(s_thisDrawno).length === 0}
                icon={<IconExcel className="text-[28px] text-[#109555]" />}
                onClick={() => {}}
              />
            </Upload>
            <Button
              className="bg-[#559EE2] border-[#559EE2] ml-2 "
              size="large"
              type="primary"
              disabled={Object.keys(s_thisSpcNS).length === 0}
              icon={<FullscreenOutlined className="text-[28px]  text-white" />}
              onClick={() => {
                set_s_cardModal(true);
              }}
            />
            <CustomSearch
              placeholder={
                t("util.util.placeholder_input") +
                t("WOS.util.complete") +
                t("util.util.wo") +
                "/" +
                t("util.util.batchno")
              }
              allowClear
              enterButton
              size="large"
              className="w-[75.5%]"
            />
          </div>,
        ]}
      />

      <div className="flex  justify-between my-2 p-0 gap-2">
        <div className="w-1/5   flex flex-col justify-between gap-6">
          {/* <Spin
            // wrapperClassName="w-full flex flex-col justify-between gap-6"
            // className="w-full flex flex-col justify-between gap-6"
            spinning={s_isLoading}
          > */}
          <ControlTable
            s_DrawnoTableData={s_DrawnoTableData}
            set_s_DrawnoTableData={set_s_DrawnoTableData}
            call_getDrawno={call_getDrawno}
          />
          <AlertCard call_getDrawno={call_getDrawno} />
          {/* </Spin> */}
        </div>

        <div className="w-3/5  bg-white rounded-lg shadow-md z-10 flex items-center">
          <Spin wrapperClassName="w-full" className="w-full" spinning={s_isLoading}>
            <XBarChart s_XBarChartData={s_XBarChartData} />
          </Spin>
        </div>

        <div className="flex flex-col flex-1 gap-4">
          <ControlArea
            call_getQmsSipHd={call_getQmsSipHd}
            s_checked={s_checked}
            set_s_checked={set_s_checked}
          />
          <InformationBoard />
        </div>
      </div>

      <div>
        <QCList call_getQmsSipHd={call_getQmsSipHd} />
      </div>
      <CustomModal
        title={
          <div className="  flex justify-between items-center">
            <div>{`SPC預覽設定`}</div>
          </div>
        }
        visible={s_transferModal}
        centered
        onCancel={() => set_s_transferModal(false)}
        width="60%"
        footer={null}
        // closable={false}
        afterClose={() => {
          set_s_transferModal(false);
          // call_getSpcOverviewSocket.setIsOpen(false);
        }}
        wrapClassName={`w-full h-full`}
      >
        <BatchImport
          call_getSpcOverviewSocket={call_getSpcOverviewSocket}
          set_modal={set_s_transferModal}
          set_s_selectedDrawno={set_s_selectedDrawno}
          s_selectedDrawno={s_selectedDrawno}
        />
      </CustomModal>

      <CustomModal
        title={
          <div className="  flex justify-between items-center">
            <div>{`SPC 全覽 ${displayString(s_thisSpcNS.sipID)} ${displayString(
              s_thisSpcNS.PN
            )} ${displayString(s_thisSpcNS.pdtNM)}`}</div>
            <div className="flex justify-start items-start gap-2">
              <Button
                className="bg-[#63DAD3] h-[30px] w-[30px]"
                icon={<IconOpenModal />}
                onClick={() => {
                  set_s_transferModal(true);
                }}
              />
              <Button
                className="h-[30px] w-[30px] "
                type="primary"
                onClick={() => {
                  set_s_cardModal(false);
                }}
                icon={<IconFoldedit className={"text-[22px] flex justify-center items-center"} />}
              />
            </div>
          </div>
        }
        visible={s_cardModal}
        style={{ top: 128 }}
        onCancel={() => set_s_cardModal(false)}
        width="95%"
        footer={null}
        closable={false}
        afterClose={() => {
          call_getSpcOverviewSocket.setIsOpen(false);
          set_s_selectedDrawno([]);
        }}
        wrapClassName={`w-full h-full`}
      >
        <SpcOverview
          s_selectedDrawno={s_selectedDrawno}
          call_getSpcOverviewSocket={call_getSpcOverviewSocket}
        />
      </CustomModal>
    </WpcContext.Provider>
  );
}
