import { Button, Modal, Space, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
// import Preview from "@/pages/QMS/QMSManage/File/Preview";
import Preview from "@/components/Global/Preview/index";
import {
  IconBatchImport,
  IconCheck,
  IconClose,
  IconFile,
  IconGarbage,
  IconPen,
} from "@/components/Icon/Action";
import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import showDecimalPoint from "@/pages/TestPage/QMS/SPC/util/showDecimalPoint";
import PDFview from "@/pages/TestPage/meds/IMS/PDF/PDFview";
import BatchImport from "@/pages/TestPage/meds/IMS/TB/ModalContainer/BatchImport";
import apiBasic from "@/service/APIBasic";
import { deleteInspTb, getInspTb, updateInspTb, updateInspTbItemno } from "@/service/apis/QMS/meds";
import { formatAmount } from "@/util/format";
import { CloseOutlined, ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer";
import UploadModalConintaer from "./ModalContainer/FileModalContainer";

export default function TB({ c_lightData, set_c_lightData, s_isHorizontalLayout }) {
  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isShowUploadModal, set_s_isShowUploadModal] = useState(false);
  const [s_editData, set_s_editData] = useState({});
  const [s_batchImportModal, set_s_batchImportModal] = useState(false);

  const deleteTb = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteInspTb.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  const horizontalTableColumns = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      dataIndex: "sort",
      width: "3%",
      align: "center",
      render: () => <MenuOutlined />,
    },
    {
      title: t("util.util.itemno"),
      dataIndex: "itemno",
      align: "center",
      width: "3%",
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "4%",
    },
    {
      title: "檢驗型態",
      dataIndex: "insptype",
      align: "center",
      width: "8%",
      render: (text) => {
        const result = {
          count: "計數型(定性)",
          measure: "計量型(定量)",
        };
        return result[text];
      },
    },
    {
      title: "檢驗規格",
      dataIndex: "inspspec",
      align: "center",
      width: "11%",
    },

    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      className: "bg-[#fdd7d0b0]",
      width: "3%",
    },
    {
      title: "圖註 / 規格要求",
      dataIndex: "drawitem",
      className: "bg-[#fdd7d0b0]",

      align: "left",
      width: "11%",
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: "圖標1",
      dataIndex: "drawmark1",
      align: "center",
      className: "bg-[#fdd7d0b0]",
      width: "3%",
    },
    {
      title: "圖標2",

      dataIndex: "drawmark2",
      className: "bg-[#fdd7d0b0]",
      align: "center",
      width: "3%",
    },
    {
      title: "圖標3",

      dataIndex: "drawmark3",
      className: "bg-[#fdd7d0b0]",
      align: "center",
      width: "3%",
      render: (text) => <ShowTooltip title={text} />,
    },

    {
      title: "單位",
      dataIndex: "UOM",
      align: "center",
      className: "bg-[#cea97fac]",
      width: "3%",
    },
    {
      title: "標準值",
      dataIndex: "SL",
      align: "right",
      className: "bg-[#cea97fac]",
      width: "4%",
      render: (text) => {
        // return text===0 ? "NA":text.toFixed(2)
        return text;
      },
      // render: (text) => formatAmount(text),
    },
    {
      title: "USL",
      dataIndex: "USL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => showDecimalPoint(text),
    },
    {
      title: "LSL",
      dataIndex: "LSL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => showDecimalPoint(text),
    },
    {
      title: "UCL",
      dataIndex: "UCL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "4%",
      render: (text) => showDecimalPoint(text),
    },
    {
      title: "LCL",
      dataIndex: "LCL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: "4%",
      render: (text) => showDecimalPoint(text),
    },
    {
      title: "局部圖檔",
      dataIndex: "drawing",
      width: "8%",
      align: "center",
      //  render: (text) => text && <Preview url={`${apiBasic.conn}://${apiBasic.url}/${text}`} />,
      render: (text) => text && <Preview url={text} />,
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      align: "center",
      width: "2.5%",

      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "5%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "4%",
      render: (_, rowData) => {
        const isDrawing =
          rowData.drawing.length > 0 ? (
            <CloseOutlined
              onClick={() => {
                removeDrawing(rowData);
              }}
            />
          ) : (
            <IconFile
              onClick={(e) => {
                set_s_editData(rowData);
                set_s_isShowUploadModal(true);
                e.stopPropagation();
              }}
            />
          );

        return (
          <Space>
            {isDrawing}
            <IconPen
              onClick={(e) => {
                set_s_editData(rowData);
                set_s_isShowModal(true);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              onClick={(e) => {
                deleteTb(e, rowData);
                // e.stopPropagation();
              }}
            />
          </Space>
        );
      },
    },
  ];

  const verticalTableColumns = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      dataIndex: "sort",
      width: 35,
      align: "center",
      render: () => <MenuOutlined />,
    },
    {
      title: t("util.util.itemno"),
      dataIndex: "itemno",
      align: "center",
      width: 30,
    },
    {
      title: "項目(球號)",
      dataIndex: "inspitem",
      align: "center",
      width: 65,
    },
    {
      title: "型態",
      dataIndex: "insptype",
      align: "center",
      width: 40,
      render: (text) => {
        const result = {
          count: "計數型(定性)",
          measure: "計量型(定量)",
        };
        return result[text];
      },
    },

    {
      title: "規格",
      dataIndex: "inspspec",
      align: "center",
      width: 85,
    },
    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      width: "7%",
    },

    {
      title: "圖標1",
      dataIndex: "drawmark1",
      align: "center",
      width: "7%",
    },
    {
      title: "單位",
      dataIndex: "UOM",
      align: "center",
      className: "bg-[#f9e9d7ac]",
      width: 30,
    },
    {
      title: "中值",
      dataIndex: "CL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: 45,

      // render: (text) => formatAmount(text),
    },
    {
      title: "USL",
      dataIndex: "USL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: 40,
      render: (text) => formatAmount(text, 2),
    },

    {
      title: "LSL",
      dataIndex: "LSL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: 40,
      render: (text) => formatAmount(text, 2),
    },

    {
      title: "UCL",
      dataIndex: "UCL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: 40,
      render: (text) => showDecimalPoint(text),
    },

    {
      title: "LCL",
      dataIndex: "LCL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: 40,
      render: (text) => showDecimalPoint(text),
    },

    {
      title: "有效",
      dataIndex: "isvalid",
      align: "center",
      width: "4.8%",

      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
  ];
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowPDF, set_s_isShowPDF] = useState(false);

  const call_getInspTb = useAPI(getInspTb);
  const call_deleteInspTb = useAPI(deleteInspTb);
  const call_updateInspTbItemno = useAPI(updateInspTbItemno);

  const call_updateInspTb = useAPI(updateInspTb);

  const removeDrawing = (rowData) => {
    Modal.confirm({
      title: "刪除圖檔確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_updateInspTb.request({ ...rowData, drawing: "" }),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getInspTb.request({ ...c_lightData });
  }, []);

  useEffect(() => {
    if (call_deleteInspTb.status === "suc") {
      message.success(call_deleteInspTb.msg);
      call_getInspTb.request({ ...c_lightData });
    }
    if (call_deleteInspTb.status === "err") {
      message.error(call_deleteInspTb.msg);
    }
  }, [call_deleteInspTb.status]);

  useEffect(() => {
    if (call_updateInspTbItemno.status === "suc") {
      message.success(call_updateInspTbItemno.msg);
    }
    if (call_updateInspTbItemno.status === "err") {
      message.error(call_updateInspTbItemno.msg);
    }
  }, [call_updateInspTbItemno.status]);
  useEffect(() => {
    if (call_getInspTb.status === "suc") {
      message.success(call_getInspTb.msg);
      set_s_tableData(
        call_getInspTb.data.map((item, index) => ({
          ...item,
          updtT: dayjs(item.updtT),
          key: item.itemno,
        }))
      );
    }
    if (call_getInspTb.status === "err") {
      message.error(call_getInspTb.msg);
      set_s_tableData([]);
    }
  }, [call_getInspTb.status]);

  useEffect(() => {
    const { status, msg, data } = call_updateInspTb;
    if (status === "suc") {
      message.success(msg);
      call_getInspTb.request({ ...c_lightData });
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateInspTb.status]);

  console.log(s_isShowPDF);
  return (
    <>
      {/* <CustomTable columns={tableColumns} dataSource={c_lightData} pagination={false} /> */}
      <div className={`${s_isHorizontalLayout && "flex-col "} flex   justify-between gap-5`}>
        <div className={`${s_isHorizontalLayout ? "w-full" : "w-1/2  "}`}>
          <PageHeader
            title={"檢驗項目明細"}
            extra={[
              <Button
                disabled={c_lightData.datafm === ""}
                shape="circle"
                type="primary"
                className="bg-[#4AC6F5] disabled:bg-slate-300"
                onClick={() => set_s_batchImportModal(true)}
              >
                <IconBatchImport className="text-white font-bold " />
              </Button>,
              <AddButton
                type="primary"
                key="create"
                onClick={() => {
                  console.log("add");
                  set_s_editData({});
                  set_s_isShowModal(true);
                }}
              />,
              <Button
                type="default"
                // key="create"
                onClick={() => {
                  set_s_isShowPDF((prev) => !prev);
                }}
              >
                {s_isShowPDF ? " 關閉圖檔" : "打開圖檔"}
              </Button>,
            ]}
          />

          <DragSortingTable
            // className={`${s_isHorizontalLayout ? "w-full" : "w-1/2  "}`}
            className={`w-full`}
            loading={call_updateInspTbItemno.status === "load"}
            columns={s_isHorizontalLayout ? horizontalTableColumns : verticalTableColumns}
            dataSource={s_tableData}
            set_s_tableData={set_s_tableData}
            // dataSource={fakeTableData}
            pagination={false}
            scroll={s_isShowPDF ? { y: 350, x: true } : { y: 705, x: true }}
            s_editData={s_editData}
            afterSorting={(data) => {
              console.log(data);
              const newSorting = data.map((item, index) => ({
                ...item,
                updtT: dayjs(item.updtT),
                key: item.itemno,
                itemno: index + 1,
              }));
              console.log(newSorting);
              call_updateInspTbItemno.request(newSorting);
              return newSorting;
            }}
            onRow={(record) => {
              return {
                onClick: () =>
                  record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
              };
            }}
          />
        </div>
        {s_isShowPDF ? (
          <div
            className={`
           ${s_isHorizontalLayout ? "h-[50vh]   w-full  " : "w-1/2   h-[70vh] "}  `}
          >
            {/* <Image width="w-full" height="h-full" src={TestImage} /> */}
            {/* <Preview url={`${apiBasic.conn}://${apiBasic.url}/${text}`} /> */}
            <PDFview c_lightData={`${apiBasic.conn}://${apiBasic.url}/${c_lightData.drawing}`} />
          </div>
        ) : null}
      </div>
      <CustomModal
        title={
          Object.keys(s_editData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        width={"60%"}
        open={s_isShowUploadModal}
        onCancel={() => set_s_isShowUploadModal(false)}
        afterClose={() => {
          // set_c_lightData({});
          call_getInspTb.request({ ...c_lightData });
        }}
      >
        <UploadModalConintaer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_isShowModal={set_s_isShowUploadModal}
        />
      </CustomModal>
      <CustomModal
        title={
          Object.keys(s_editData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        style={{ top: 275 }}
        width={"85%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getInspTb.request({ ...c_lightData });
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
      <CustomModal
        title={"檢驗項目明細設定"}
        width={"50%"}
        style={{ top: 250 }}
        open={s_batchImportModal}
        onCancel={() => set_s_batchImportModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getInspTb.request(c_lightData);
        }}
      >
        <BatchImport c_lightData={c_lightData} set_s_batchImportModal={set_s_batchImportModal} />
      </CustomModal>
    </>
  );
}
