import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  message,
  Radio,
  Space,
  InputNumber,
  DatePicker,
  Collapse,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { addMold, updateMold } from "@/service/apis/ADM/mold";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";

import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);
  const f_property = Form.useWatch("property", form);

  const call_addMold = useAPI(addMold);
  const call_updateMold = useAPI(updateMold);
  const call_getProduct = useAPI(getProductSelect);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    // if (values.PN && values.PN.length > 0) {
    //   values.PN = values.PN.join("|");
    // }

    // if (values.wsID && values.wsID.length > 0) {
    //   values.wsID = 
    // }

    const tmpValue = {
      ...s_editData,
      ...values,
      wsID: values.wsID.join("|"),
      PN: values.PN.join("|"),
    }

    if (type === "create") {
      call_addMold.request(tmpValue);
    }

    if (type === "edit") {
      call_updateMold.request(tmpValue);
    }
  };

  useEffect(() => {
    // call_getProduct.request({assetID:Object.keys(s_editData).length > 0 ? s_editData.assetID : "All"});
    call_getProduct.request();
  }, []);

  useEffect(() => {
    if (call_addMold.status === "suc") {
      message.success(call_addMold.msg);
      set_s_isShowModal(false);
    } else if (call_addMold.status === "err") {
      message.error(call_addMold.msg);
    }
  }, [call_addMold.status]);

  useEffect(() => {
    if (call_updateMold.status === "suc") {
      message.success(call_updateMold.msg);
      set_s_isShowModal(false);
    } else if (call_updateMold.status === "err") {
      message.error(call_updateMold.msg);
    }
  }, [call_updateMold.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        moldtype: 1,
        cavity: 1,
        stdcavity: 1,
        validcavity: 1,
        ...s_editData,
        openT: dayjs(s_editData.openT),
        assetkind: "TI",
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Row gutter={[24, 0]}>
            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.assetID")} // 模具ID
                name="assetID"
                rules={[{ required: true }]}
              >
                <Input disabled={Object.keys(s_editData).length > 0} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("util.util.name")} // 資產名稱
                name="assetNM"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t("util.util.PN")} // 料號
                name="PN"
              >
                <Select
                  mode="multiple"
                  allowClear
                  options={
                    call_getProduct.data
                      ?.filter((item) => item.attribute === "4" || item.attribute === "5")
                      ?.map((x) => ({
                        label: `${x.PN}_${x.pdtNM}`,
                        value: x.PN,
                        data: x,
                      })) || []
                  }
                />
              </Form.Item>
            </Col>

            {/* <Col span={6} /> */}

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.assetkind")} // 模具類型
                name="assetkind"
                rules={[{ required: true }]}
              >
                <Select allowClear options={arrMap.assetkind} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.util.pfID")} // 製程ID
                name="pfID"
              >
                <Select
                  allowClear
                  onChange={() => {
                    form.setFieldsValue({ wsID: [] });
                  }}
                  options={
                    arrMap.pfArr?.map((x) => ({
                      label: `${x.pfID}_${x.pfNM}`,
                      value: x.pfID,
                      data: x,
                    })) || []
                  }
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.wsID")} // 工站ID
                name="wsID"
              >
                <Select
                  mode="multiple"
                  allowClear
                  disabled={!f_pfID}
                  options={arrMap.wsArr?.reduce((options, curr) => {
                    curr.pfID.includes(f_pfID) &&
                      options.push({
                        label: curr.wsID + "_" + curr.wsNM,
                        value: curr.wsID,
                        data: curr,
                      });
                    return options;
                  }, [])}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("util.util.note")} // 資產名稱
                name="note"
              // rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              {/* <Form.Item
                label={t("ADM.mold.moldtype")} // 共模
                name="moldtype"
                rules={[{ required: true }]}
              >
                <InputNumber
                  onChange={(value) => {
                    let formData = form.getFieldsValue();
                    const stdcavity = formData.cavity / value;
                    form.setFieldsValue({ stdcavity: stdcavity, validcavity: stdcavity });
                  }}
                  min={1}
                  className="w-full"
                />
              </Form.Item> */}
              <Form.Item label={t("ADM.mold.moldtype")} // 共模
                name="moldtype" rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  options={[
                    { label: "1_單料", value: 1 },
                    { label: "2_雙料號", value: 2 },
                    { label: "3_叁料號", value: 3 },
                  ]}
                  onChange={(value) => {
                    let formData = form.getFieldsValue();
                    const stdcavity = formData.cavity / value;
                    form.setFieldsValue({ stdcavity: stdcavity, validcavity: stdcavity });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.cavity")} // 實體穴數
                name="cavity"
                rules={[{ required: true }]}
              >
                <InputNumber
                  onChange={(value) => {
                    let formData = form.getFieldsValue();
                    const stdcavity = value / formData.moldtype;
                    form.setFieldsValue({ stdcavity: stdcavity, validcavity: stdcavity });
                  }}
                  min={1}
                  className="w-full"
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.stdcavity")} // 標準穴數
                name="stdcavity"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} className="w-full" disabled />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.validcavity")} // 有效穴數
                name="validcavity"
                rules={[{ required: true }]}
              >
                <InputNumber min={1} className="w-full" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.openT")} // 取得日期
                name="openT"
              >
                <DatePicker format="YYYY-MM-DD" className="w-full" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.openC")} // 開帳生產模數
                name="openC"
              >
                <InputNumber min={0} className="w-full" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("ADM.mold.cummoldC")} // 開帳生產模數
                name="cummoldC"
              >
                <InputNumber min={0} className="w-full" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("util.util.isvalid")} // 是否有效
                name="isvalid"
              >
                <Radio.Group
                  options={[
                    { value: true, label: t("util.util.yes") },
                    { value: false, label: t("util.util.no") },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Collapse defaultActiveKey={["1"]}>
                <Panel header={t("ADM.mold.advance")} key="1" className="bg-[#C0DCF0]">
                  <Row gutter={[24, 0]}>
                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.moldlife")} // 模具設計壽命
                        name="moldlife"
                      >
                        <InputNumber min={0} className="w-full" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.property")} // 模具財產歸屬
                        name="property"
                      >
                        <Select
                          allowClear
                          options={arrMap.property}
                          onChange={() => {
                            form.setFieldsValue({ custID: null });
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.custID")} // 客戶ID
                        name="custID"
                      >
                        <Select
                          options={
                            arrMap.custArr?.map((x) => ({
                              label: `${x.custID}_${x.custalias}`,
                              value: x.custID,
                              data: x,
                            })) || []
                          }
                          disabled={f_property !== "customer"}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.gatesystem")} // 澆口形式
                        name="gatesystem"
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.length")} // 模具總長
                        name="length"
                      >
                        <InputNumber min={0} className="w-full" addonAfter="mm" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.width")} // 模具總寬
                        name="width"
                      >
                        <InputNumber min={0} className="w-full" addonAfter="mm" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.height")} // 模具總高
                        name="height"
                      >
                        <InputNumber min={0} className="w-full" addonAfter="mm" />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.mold.weight")} // 模具總重
                        name="weight"
                      >
                        <InputNumber min={0} className="w-full" addonAfter="kg" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addMold.status === "load" || call_updateMold.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
