import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { addLabelWheel, updateLabelWheel } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";

import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addLabelWheel = useAPI(addLabelWheel);
  const call_updateLabelWheel = useAPI(updateLabelWheel);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addLabelWheel.request(postData);
    }

    if (type === "edit") {
      call_updateLabelWheel.request(postData);
    }
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    if (call_addLabelWheel.status === "suc") {
      message.success(call_addLabelWheel.msg);
      set_is_showModal(false);
    } else if (call_addLabelWheel.status === "err") {
      message.error(call_addLabelWheel.msg);
    }
  }, [call_addLabelWheel.status]);

  useEffect(() => {
    if (call_updateLabelWheel.status === "suc") {
      message.success(call_updateLabelWheel.msg);
      set_is_showModal(false);
    } else if (call_updateLabelWheel.status === "err") {
      message.error(t(`error.error-code.${call_updateLabelWheel.msg}`));
    }
  }, [call_updateLabelWheel.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ wh: 0, ...s_editData }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label="貼標料號" rules={[{ required: true }]}>
            {Object.keys(s_editData).length > 0 ? (
              <Input disabled={Object.keys(s_editData).length > 0} />
            ) : (
              <DebounceSelect
                fetchOptions={fetchPNList}
                maxTagCount={10}
                showSearch
                onChange={(value) => {
                  form.setFieldsValue({ PN: value.value, pdtNM: value.title });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wh" label="貼標1002W301(S/PCS)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                call_addLabelWheel.status === "load" || call_updateLabelWheel.status === "load"
              }
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
