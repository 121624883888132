import { Input, Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { deleteQmsSopHd, getQmsSopHd } from "@/service/apis/QMS/QMSManage";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer";

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;
  const [s_searchData, set_s_searchData] = useState({
    ID: undefined,
    PN: undefined,
    pdtNM: undefined,
  });
  const [s_showModal, set_s_showModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const [s_tableData, set_s_tableData] = useState([]);

  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);
  const call_getQmsSopHd = useAPI(getQmsSopHd);
  const call_deleteQmsSopHd = useAPI(deleteQmsSopHd);

  // 搜尋途程標準管理資料
  const onSearch = () => {
    call_getQmsSopHd.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteQmsSopHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev };
    });
  };

  const onCreate = () => set_s_showModal(true);

  const tableColumns = [
    {
      title: "SOP ID",
      dataIndex: "sopID",
      key: "sopID",
      align: "left",
      width: "18%",
    },
    {
      title: "版次",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: "3%",
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      align: "center",
      width: "10%",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      align: "left",
      width: "15%",
    },
    {
      title: "控制型態",
      dataIndex: "soptype",
      key: "soptype",
      align: "center",
      width: "5%",
      render: (text) => {
        const soptypes = {
          "OPE": "操作型",
          "OPC": "流控型",
          "WPC": "途控型",
        };
        return soptypes[text];
      },
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      key: "ctrlspot",
      align: "center",
      width: "8%",
      render: (text) => {
        const ctrlspots = {
          "wo": "工單比對",
          "pf": "製程比對",
          "ws": "工站比對",
          "wl": "工線比對",
          "wp": "全品比對",
          "pwc": "工作中心比對",
          "qa": "品管比對",
        };
        return ctrlspots[text];
      },
    },
    {
      title: "控點ID",
      dataIndex: "spotID",
      key: "spotID",
      width: "15%",
      render: (text, rowData, _) => {
        return s_arrMap[rowData.ctrlspot]?.find((x) => x.value === text)?.label;
      },
    },
    {
      title: "總工位數",
      dataIndex: "sopno",
      key: "sopno",
      align: "center",
      width: "5%",
    },
    {
      title: "作業序",
      dataIndex: "assyno",
      key: "assyno",
      align: "center",
      width: "4%",
    },
    {
      title: "檢測序",
      dataIndex: "inspno",
      key: "inspno",
      align: "center",
      width: "4%",
    },
    {
      title: "包裝序",
      dataIndex: "packno",
      key: "packno",
      align: "center",
      width: "4%",
    },
    {
      title: t("util.util.note"), //"備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.isvalid"), //"是否有效",
      dataIndex: "isvalid",
      key: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },

    {
      title: t("util.util.action"), //"操作",
      dataIndex: "render",
      key: "render",
      width: "5%",
      align: "center",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_showModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],
    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) || [],
    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    pwc:
      call_getPWC?.data.map((pwc) => ({ label: `${pwc.pwcID}_${pwc.pwcNM}`, value: pwc.pwcID })) ||
      [],
    ctrlrequire: [
      { value: "A", label: "每次取樣創建檢驗單" },
      { value: "B", label: "固定週期自創檢驗單" },
      { value: "C", label: "物控覆核後自創檢單" },
      { value: "D", label: "自動班別創建檢檢單" },
    ],
  };

  const searchBasic = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
  }, []);

  useEffect(() => {
    if (call_getQmsSopHd.status === "suc") {
      message.success(call_getQmsSopHd.msg);
      set_s_tableData(
        call_getQmsSopHd.data?.tableData?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getQmsSopHd.status === "err") {
      message.error(call_getQmsSopHd.msg);
      set_s_tableData([]);
    }
  }, [call_getQmsSopHd.status]);

  useEffect(() => {
    if (call_deleteQmsSopHd.status === "suc") {
      onSearch();
    }
  }, [call_deleteQmsSopHd.status]);
  console.log(s_tableData);
  return (
    <>
      <PageHeader title="作業標準主檔(SOP)" extra={searchBasic} />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        loading={call_getQmsSopHd.status === "load"}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.sopID === c_lightData.sopID ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          <span>
            {Object.keys(s_editData).length > 0
              ? `${t("util.util.edit")}作業標準`
              : `${t("util.util.add")}作業標準`}
            {Object.keys(s_editData).length > 0 && (
              <>
                <span className="ml-2 text-blue-500 font-semibold">SOP ID: {s_editData.sopID}</span>
                <span className="ml-2 text-blue-500 font-semibold">版次: {s_editData.version}</span>
              </>
            )}
          </span>
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          onSearch();
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
        />
      </CustomModal>
    </>
  );
};

export default HD;
