import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  position: relative;
  /* width: 200px;
  height: 100px; */
  /* width: 130px;
  height: 65px; */
  width: 110px;
  height: 55px;
  overflow: hidden;

  .box1 {
    /* width: 200px;
    height: 100px; */
    /* width: 130px;
    height: 65px; */
    width: 110px;
    height: 55px;
    background-color: ${({ bgColor }) => bgColor};
    box-shadow: inset 1px 1px 6px #00000029;
    border: 1px solid #c0c0c0;
    border-radius: 200px 200px 0 0;
    /* border-radius: 130px 130px 0 0; */
    /* z-index: 10; */
    position: absolute;
  }
  .box2 {
    /* width: 200px;
    height: 100px; */
    /* width: 130px;
    height: 65px; */
    width: 110px;
    height: 55px;
    background-color: #f0f0f0;
    /* border-radius: 200px 200px 0 0; */
    border-radius: 130px 130px 0 0;
    /* transform: rotate(${({ deg }) => deg}); */
    transform: rotate(${({ deg }) => deg});
    /* z-index: 50; */
    position: absolute;
    /* transform-origin: 100px 100px; */
    /* transform-origin: 65px 65px; */
    transform-origin: 55px 55px;
  }
  // 要調整紅色棒子的粗細請修改box3, height須為width的一半
  .box3 {
    /* width: 150px;
    height: 75px; */
    /* width: 100px;
    height: 50px; */
    width: 85px;
    height: 41px;
    background-color: #fff;
    /* border-radius: 150px 150px 0 0; */
    /* border-radius: 100px 100px 0 0; */
    border-radius: 85px 85px 0 0;
    /* z-index: 99; */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 18px;
    font-weight: 500;
  }
`;

const OvalChart = ({ percentage = 0 }) => {
  // 一個百分比佔1.8度
  const deg = (180 * percentage) / 100 || 0;

  const bgColor = percentage >= 100 ? "#50C9A4" : percentage >= 70 ? "#F9B27B" : "#FC9191";
  //alert(deg)
  console.log("percentage = ", percentage);
  console.log("deg = ", deg);
  return (
    <Wrapper bgColor={bgColor} deg={`${deg}deg`}>
      {/* <div className='wrapperx'> */}
      <div className="box1"></div>
      <div className="box2"></div>
      <div className="box3">{`${percentage}%`}</div>
      {/* </div> */}
    </Wrapper>
  );
};

export default OvalChart;
