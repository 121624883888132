import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Form, Button, Row, Col, Input, Select, Divider, message } from 'antd';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const SecModalContainer = ({set_is_EditSecModal, s_EditData, set_s_EditData, s_dataSource, set_s_dataSource}) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, s_EditData]);

  // 卸載時看空被編輯的資料
  useEffect(() => {

    return () => {
      set_s_EditData({})
    }
  }, []);

  // 取消
  const handleCancel = () => {
    set_s_EditData({});
    set_is_EditSecModal(false)
  }

  const onSubmit = async values => {

    // 用 s_EditData.index 當key編輯指定Object
    const newData = s_dataSource.map((item, index) => {
      if(item.index === s_EditData.index) {
        return {
          ...values,
          index: item.index
        }
      } else {
        return item
      }
    });

    console.log('newData = ', newData)

    set_s_dataSource(newData);
    set_is_EditSecModal(false);

  }

  return (
    <Form.Provider>
      <CustomForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          optype: Object.keys(s_EditData).length > 0 ? s_EditData.optype : s_EditData.optype,  // 作業類別 
          opNM: Object.keys(s_EditData).length > 0 ? s_EditData.opNM : '',  // 類別說明
          opcode: Object.keys(s_EditData).length > 0 ? s_EditData.opcode : '',  // 作業單別 
          postype: Object.keys(s_EditData).length > 0 ? s_EditData.postype : null,  // 過帳類型
        }}
      >
        <Row gutter={[36, 12]}>
          <Col span={6}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>作業類別</label>}
              name="optype"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          
          <Col span={6}>
            <Form.Item 
              name="opcode"
              label={<label style={{color: '#6C6C6C'}}>作業單別</label>}
              rules={[
                { required: true, message: '請輸入作業單別!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {

                    const optype = getFieldValue('optype');
                    
                    if(value.length !== 4) {
                      return Promise.reject(new Error('作業單別只可為4位數'));
                    } 

                    if(value.slice(0,2) !== optype) {
                      return Promise.reject(new Error('單別前兩碼須符合該類別'));
                    } 

                    return Promise.resolve()
                    
                    // else {
                    //   return Promise.resolve();
                    // }
                  },
                })
              ]}
            >
              <Input placeholder='請輸入作業單別' />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              name="opNM"
              label={<label style={{color: '#6C6C6C'}}>單別名稱</label>}
              rules={[{ required: true, message: '請輸入單別名稱!' }]}
            >
              <Input placeholder='請輸入單別名稱' />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item 
              name='postype'
              label={<label style={{color: '#6C6C6C'}}>過帳類型</label>}
              rules={[{ required: true, message: '請輸入過帳類型!' }]}
            >
              <Select 
                placeholder="選擇過帳類型"
              >
                <Select.Option value="1">借</Select.Option>
                <Select.Option value="2">貸</Select.Option>
                <Select.Option value="3">先借後貸</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px'}} />

        <Row>
          <Col offset={12} span={12}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </CustomForm>
    </Form.Provider>
  )
};

export default SecModalContainer;