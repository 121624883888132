import React from "react";
// component
import Container from "./Container";
import Header from "./Header";


const Page = (props) => {

  return (
    <div className="flex flex-col h-[100vh] min-h-[100vh]  w-full relative ">
      <Header {...props} />
      <Container {...props} />
    </div>
  );
};

export default Page;
