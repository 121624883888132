import React from "react";
import MyCard from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/Alert/Card/index";
import { useTranslation } from "react-i18next";
import { IconException } from "@/components/Icon/Navbar";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const WarringTextColor = "text-[#FA5D51]";
const AlertCard = (props) => {
  const { t } = useTranslation();
  const alertData = [
    { date: "12-04 10:54", title: "異常警報-231204000002" },
    { date: "12-04 10:54", title: "異常警報-231204000003" },
    { date: "12-04 10:54", title: "異常警報-231204000004" },
  ];
  return (
    <div>
      <MyCard
        // title={
        //   t("WOS.util.ngC")
        //   // {/*不良品數量*/}
        // }
        type="QualityControl"
        content={
          <div className="w-full flex justify-around items-center py-[10px] bg-[#FFFFFF] ">
            <div className=" ">
              <IconException className={"text-[50px]  text-red-600 "} />
            </div>
            <div className="">
              {alertData.map((item, index) => (
                <div key={index} className="flex justify-around mb-[20px] gap-5 items-center    ">
                  <span className={`${WarringTextColor} text-[15px]`}>{item.title}</span>
                  <span className={`text-[10px]`}> {item.date}</span>
                </div>
              ))}
            </div>
            <div className="h-full flex items-center ">
              <CaretDownOutlined />
            </div>
          </div>
        }
        titleAlign="center"
        align="center"
        borderAlign="top"
        // align="right"
        color="#FA5D51"
        // content={<LineChart percent={thisWpc?.ACT ?? 0} />}
        // icon={<WarningOutlined />}
      />
    </div>
  );
};

export default AlertCard;
