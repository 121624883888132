import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

// 新增 Sip 表頭
export const addQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipHd`, data);
};
export const addSpcN = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addSpcN`, data);
};

// 上傳工程圖檔
export const addQmsSipHdImg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipHdImg`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 取得 Sip 表頭
export const getQmsSipHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSipHd`, {
    params: _params,
  });
};

// 更新 Sip 表頭
export const updateQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipHd`, data);
};

// 刪除 Sip 表頭
export const deleteQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSipHd`, data);
};

// ------------------------------------------------------------------------------------

// 新增 Sip 表身
export const addQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipTb`, data);
};

// 取得 Sip 表身
export const getQmsSipTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSipTb`, {
    params: _params,
  });
};

// 更新 Sip 表身
export const updateQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipTb`, data);
};

// 上傳工程圖檔
export const addQmsSipTbImg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipTbImg`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 刪除 Sip 表身
export const deleteQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSipTb`, data);
};

// 拖動表身
export const updateQmsSipTbItemno = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipTbItemno`, data);
};

// 新增 Sop 表頭
export const addQmsSopHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSopHd`, data);
};

// 取得 Sop 表頭
export const getQmsSopHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSopHd`, {
    params: _params,
  });
};

// 更新 Sop 表頭
export const updateQmsSopHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSopHd`, data);
};

// 刪除 Sop 表頭
export const deleteQmsSopHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSopHd`, data);
};

// 據此創建 Sop
export const copyQmsSop = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/copyQmsSop`, data);
};

// 取得 Sop 表身
export const getQmsSopTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSopTb`, {
    params: _params,
  });
};

// 更新 Sop 表身
export const updateQmsSopTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSopTb`, data);
};

// 取得 Sop rec
export const getQmsSopRec = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSopRec`, {
    params: _params,
  });
};

// 更新 Sop rec
export const updateQmsSopRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSopRec`, data);
};

// 上傳工程圖檔
export const addQmsSopRecImg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSopRecImg`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getRcphd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRcphd`, {
    params: _params,
  });
};

export const addRcphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRcphd`, data);
};

export const deleteRcphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteRcphd`, data);
};
export const modifyRcphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyRcphd`, data);
};

export const getRcptb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRcptb`, {
    params: _params,
  });
};

export const modifyRcptb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyRcptb`, data);
};

export const getQmsSipCompare = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSipCompare`, {
    params: _params,
  });
};
export const importQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/importQmsSipTb`, data);
};

export const importInspmethod = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/importInspmethod`, data);
};

export const getQmsSpecCompare = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSpecCompare`, {
    params: _params,
  });
};
export const importQmsSpecTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/importQmsSpecTb`, data);
};
