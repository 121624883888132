import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Input, Select, message, Radio, Space, } from "antd";

//HOOK
import useAPI from "@/hooks/useAPI";

//API
import { addOemitem, updateOemitem } from "@/service/apis/ADM/oem";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  console.log("s_editData ===", s_editData);
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addOemitem = useAPI(addOemitem);
  const call_updateOemitem = useAPI(updateOemitem);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addOemitem.request({ ...values });
    }

    if (type === "edit") {
      call_updateOemitem.request({ ...values, itemID: s_editData.itemID });
    }
  };

  useEffect(() => {
    if (call_addOemitem.status === "suc") {
      message.success(call_addOemitem.msg);
      set_s_isShowModal(false);
    } else if (call_addOemitem.status === "err") {
      message.error(call_addOemitem.msg);
    }
  }, [call_addOemitem.status]);

  useEffect(() => {
    if (call_updateOemitem.status === "suc") {
      message.success(call_updateOemitem.msg);
      set_s_isShowModal(false);
    } else if (call_updateOemitem.status === "err") {
      message.error(call_updateOemitem.msg);
    }
  }, [call_updateOemitem.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true, // 是否有效
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* "外包類別" */}
          {/*  
            { label: "人力", value: "A" },
            { label: "表處", value: "B" },
            { label: "機工", value: "C" },
         */}
          <Form.Item
            label={t("ADM.oem-manage.type")}
            name="type"
            rules={[{ required: true }]}
          >
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={
                [
                  { label: t("ADM.oem-manage.type_A"), value: "A" },
                  { label: t("ADM.oem-manage.type_B"), value: "B" },
                  { label: t("ADM.oem-manage.type_C"), value: "C" },
                ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.itemNM")} name="itemNM" rules={[{ required: true }]}>
            <Input placeholder={`${t("util.util.placeholder_input")}${t("util.util.itemNM")}`} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="isvalid" label={t("util.util.isvalid")}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>

    </Form >
  );
}
