import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { debounce } from "lodash";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// util component
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import { IconExpand, IconShrink } from "@/components/Icon/Action";
// api
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { getUnit } from "@/service/apis/ADM/systemParamsManage";
import { addRcphd, getRcptb, modifyRcphd, modifyRcptb } from "@/service/apis/QMS/QMSManage";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;
export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
  c_lightData,
  set_c_lightData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const f_siptype = Form.useWatch("siptype", form);
  const f_ctrlspot = Form.useWatch("ctrlspot", form);
  const f_ctrlrequire = Form.useWatch("ctrlrequire", form);

  const f_rcptype = Form.useWatch("rcptype", form);
  const f_UOM = Form.useWatch("UOM", form);
  // 新增表頭資料
  const call_addRcphd = useAPI(addRcphd);
  //獲取紀錄類型
  const call_getUnit = useAPI(getUnit);
  // 編輯表頭資料
  const call_modifyRcphd = useAPI(modifyRcphd);
  // 編輯表身資料
  const call_modifyRcptb = useAPI(modifyRcptb);
  //獲取表身資料
  const call_getRcptb = useAPI(getRcptb);

  const [s_UOMDisplayOption, set_s_UOMDisplayOption] = useState([]);
  const [s_activeKey, set_s_activeKey] = useState([]);
  const [s_isPanelOpen, set_s_isPanelOpen] = useState(false);
  const [s_isPanelAllOpen, set_s_isPanelAllOpen] = useState(false);

  const pct_ref = useRef(null);
  const onSubmit = (values) => {
    if (type === "create") {
      const payload = {
        hd: values,
        tb: values.details,
      };

      call_addRcphd.request(payload);
    }
    if (type === "edit") {
      const tb = values.details;
      call_modifyRcptb.request(tb);
      call_modifyRcphd.request({ ...values, rcpID: s_editData.rcpID });
      set_c_lightData({});
    }
  };

  // 遠程搜索料號
  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };
  const handlePanelClick = (key) => {
    set_s_activeKey(key);
  };
  const handleInputChange = (e, formData, listIndex) => {
    if (formData.details?.length > 0) {
      formData.details = formData.details.map((x, detailIndex) => {
        if (detailIndex === listIndex) {
          return { ...x, rcpitem: e.target.value };
        }
        return { ...x };
      });

      form.setFieldsValue({ ...formData });
    }
  };

  const debouncedHandleInputChange = debounce((e, formData) => {
    handleInputChange(e, formData);
  }, 500);
  useEffect(() => {
    call_getUnit.request();

    if (type === "edit") {
      call_getRcptb.request(s_editData);
    }
  }, []);
  // useAPI 新建 RCP
  useEffect(() => {
    const { status, msg, data } = call_getUnit;
    if (status === "suc") {
      // message.success(msg);

      const rcptype = data.map((UOM) => ({ label: UOM.unit, value: UOM.unit }));
      set_s_UOMDisplayOption(rcptype);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getUnit.status]);
  useEffect(() => {
    const { status, msg } = call_addRcphd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addRcphd.status]);

  useEffect(() => {
    if (f_rcptype === "pct") {
      form.setFieldValue("UOM", "pct");
    }
  }, [f_rcptype]);

  // useAPI 修改 RCP
  useEffect(() => {
    const { status, msg } = call_modifyRcphd;
    if (status === "suc") {
      message.success(msg);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_modifyRcphd.status]);

  useEffect(() => {
    const { status, msg } = call_modifyRcptb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_modifyRcptb.status]);

  useEffect(() => {
    let formData = form.getFieldValue();
    const newKey =
      formData.details?.reduce((maxIndex, _, detailIndex) => {
        // We don't need the second parameter (_) as we are only interested in detailIndex.
        return Math.max(maxIndex, detailIndex);
      }, 0) ?? 0;
    set_s_activeKey((pre) => {
      return [...pre, newKey];
    });
  }, [s_isPanelOpen]);

  useEffect(() => {
    if (s_isPanelAllOpen) {
      let formData = form.getFieldValue();
      set_s_activeKey(formData.details?.map((_, index) => index) || []);
    } else {
      set_s_activeKey([]);
    }
  }, [s_isPanelAllOpen]);

  useEffect(() => {
    const { status, msg, data } = call_getRcptb;
    if (status === "suc") {
      form.setFieldsValue({ details: data });
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getRcptb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
      onFinish={onSubmit}
      scrollToFirstError={true}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          {/* 料號 */}
          <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
            <DebounceSelect
              disabled={type === "edit"}
              fetchOptions={fetchPNList}
              maxTagCount={10}
              showSearch
              onChange={(value) => {
                form.setFieldsValue({ PN: value.value, pdtNM: value.title });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 品名 */}
          <Form.Item label={t("util.util.pdtNM")} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="記錄類型" name="rcptype" rules={[{ required: true }]}>
            <Select
              disabled={type === "edit"}
              className="w-full"
              allowClear
              options={[
                { label: "百分比", value: "pct" },
                { label: "固定量", value: "nwt" },
              ]}
              onChange={(e) => {
                let formData = form.getFieldValue();
                if (formData.details?.length > 0) {
                  formData.details = formData.details.map((x) => {
                    if (e === "nwt") {
                      return { ...x, rcptype: e };
                    }
                    // 因 百分比時 UOM無法 click 固直接設定
                    return { ...x, rcptype: e, UOM: e };
                  });
                  form.setFieldsValue({ ...formData });
                }
              }}
              onClick={() => {
                form.setFieldValue("UOM", null);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="使用單位" name="UOM" rules={[{ required: true }]}>
            <Select
              disabled={form.getFieldValue("ctrlspot") === "wp" || f_rcptype === "pct"}
              className="w-full"
              allowClear
              options={f_rcptype === "pct" ? [{ label: "%", value: "pct" }] : s_UOMDisplayOption}
              onChange={(e) => {
                let formData = form.getFieldValue();
                if (formData.details?.length > 0) {
                  formData.details = formData.details.map((x) => {
                    return { ...x, UOM: e };
                  });
                  form.setFieldsValue({ ...formData });
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="控點" name="ctrlspot" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "WP全品比對", value: "wp" },
                { label: "WO工單比對", value: "wo" },
                { label: "PF製程比對", value: "pf" },
                { label: "WS工站比對", value: "ws" },
                { label: "WL工線比對", value: "wl" },
                { label: "WC工作中心比對", value: "pwc" },
                { label: "QA品管控制比對", value: "qa" },
              ]}
              onChange={() => form.setFieldsValue({ spotID: null })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="控點ID" name="spotID">
            <Select
              disabled={!f_ctrlspot || ["wp", "wo", "qa"].includes(f_ctrlspot)}
              className="w-full"
              allowClear
              options={s_arrMap[f_ctrlspot]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="分量總重"
            name="nwt"
            rules={[
              {
                required: f_rcptype === "nwt",
                message: "欄位尚有空缺",
                validator: (_, value) => {
                  return new Promise(async (resolve, reject) => {
                    if (f_rcptype === "pct") {
                      resolve();
                    }
                    // console.log(value);
                    if (!value) {
                      await reject("欄位尚有空缺");
                      // pct_ref.current.scrollIntoView({ behavior: 'smooth' });
                    } else {
                      resolve();
                    }
                  });
                },
              },
            ]}
          >
            {/* <Select
              allowClear
              options={s_arrMap.ctrlrequire}
              onChange={(value) => {
                if (value === "B") {
                  form.setFieldsValue({
                    "ctrlCT": 2,
                  });
                } else {
                  form.setFieldsValue({
                    "ctrlCT": 0,
                  });
                }
              }}
            /> */}
            <InputNumber disabled min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="總百分比"
            name="pct"
            rules={[
              {
                required: f_rcptype === "pct",
                message: "百分比超過100%",
                validator: (_, value) => {
                  return new Promise(async (resolve, reject) => {
                    // console.log(value);
                    if (value > 100) {
                      await reject("百分比超過100");
                      // pct_ref.current.scrollIntoView({ behavior: "smooth" });
                    } else if (!value) {
                      await reject("欄位尚有空缺");
                      // pct_ref.current.scrollIntoView({ behavior: "smooth" });
                    } else {
                      resolve();
                    }
                  });
                },
              },
            ]}
          >
            <InputNumber
              disabled={f_ctrlrequire !== "B"}
              className="w-full"
              addonAfter="%"
              // ref={pct_ref}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 是否有效 */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {
        <div className="tbWrapper ">
          <Divider style={{ margin: "6px" }} />
          <Row gutter={[24, 12]}>
            <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
              {"配方標準明細"}
            </Col>
            <Col span={16} className="flex justify-end">
              <Space>
                <Button type="primary" onClick={() => set_s_isPanelAllOpen(!s_isPanelAllOpen)}>
                  {s_isPanelAllOpen ? <IconExpand /> : <IconShrink />}
                </Button>
              </Space>
            </Col>
          </Row>

          <Form.List name="details" shouldUpdate>
            {(fields, { add, remove }) => (
              <>
                <Collapse
                  className=" mb-3"
                  activeKey={s_activeKey}
                  collapsible="header"
                  onChange={handlePanelClick}
                  items={fields.map((field, index) => {
                    let formData = form.getFieldValue();
                    const rcpitemNM = formData.details[index].rcpitem;

                    return {
                      key: index,
                      label: `${index + 1}.${rcpitemNM || ""} `,
                      extra: (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                            let formData = form.getFieldValue();
                            formData.details = formData.details.map((x, index) => {
                              return { ...x, itemno: index + 1 };
                            });
                            form.setFieldsValue(formData);
                          }}
                          style={{ fontSize: "20px", marginLeft: "5px" }}
                        />
                      ),
                      children: (
                        <div className="w-full h-full  bg-[#FFFFD9]">
                          <Row key={field.key} gutter={[12, 12]}>
                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                //序
                                label="投料序"
                                name={[field.name, "itemno"]}
                              >
                                <Input disabled value={index + 1} />
                              </Form.Item>
                            </Col>

                            <Col span={1} className="flex-center"></Col>
                          </Row>
                          <Row gutter={[12, 12]}>
                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 配方項目
                                label={"配方項目"}
                                name={[field.name, "rcpitem"]}
                                rules={[{ required: true }]}
                              >
                                <Input
                                  onChange={(e) =>
                                    debouncedHandleInputChange(e, form.getFieldValue(), index)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 過帳型態
                                label={"測量設備"}
                                name={[field.name, "device"]}
                                rules={[{ required: true }]}
                              >
                                <Input
                                  onChange={(e) =>
                                    debouncedHandleInputChange(e, form.getFieldValue(), index)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                {...field}
                                // 記錄類型
                                label={"記錄類型"}
                                name={[field.name, "rcptype"]}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  className="w-full"
                                  allowClear
                                  value={f_rcptype}
                                  disabled
                                  options={[
                                    { label: "百分比", value: "pct" },
                                    { label: "固定量", value: "nwt" },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                //入庫批號
                                label={"使用單位"}
                                name={[field.name, "UOM"]}
                                disabled
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Select
                                  disabled
                                  className="w-full"
                                  // allowClear
                                  // options={s_arrMap[f_ctrlspot]}
                                  options={
                                    f_rcptype === "pct"
                                      ? [{ label: "%", value: "pct" }]
                                      : s_UOMDisplayOption
                                  }
                                  // onChange={(e) => {
                                  //   let formData = form.getFieldValue();
                                  //   if (formData.details?.length > 0) {
                                  //     formData.details = formData.details.map((x) => {
                                  //       return { ...x, UOM: e };
                                  //     });
                                  //     form.setFieldsValue({ ...formData });
                                  //   }
                                  // }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[12, 12]}>
                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 計劃數量
                                label={"分量凈重"}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "請輸入大於0的數量!",
                                //     validator: (_, value) => {
                                //       return new Promise(async (resolve, reject) => {
                                //         if (!value) {
                                //           await reject("請輸入數量");
                                //         } else if (value - 0 === NaN) {
                                //           await reject("請輸入大於0的數字");
                                //         } else {
                                //           await resolve();
                                //         }
                                //       });
                                //     },
                                //   },
                                // ]}
                                name={[field.name, "nwt"]}
                              >
                                <InputNumber
                                  onChange={(value) => {
                                    // const totalNwt = form.getFieldValue("nwt")
                                    let formData = form.getFieldValue();
                                    let total = 0;
                                    formData.details.forEach((element) => {
                                      total += element.nwt;
                                    });
                                    form.setFieldValue("nwt", total);

                                    if (formData.details?.length > 0) {
                                      formData.details = formData.details.map((x) => {
                                        return {
                                          ...x,
                                          pct: Math.round((x.nwt / total) * 10000) / 100,
                                        };
                                      });
                                      form.setFieldsValue({ ...formData });
                                    }
                                    form.setFieldValue("nwt", total);
                                    form.setFieldValue("pct", 100);
                                  }}
                                  disabled={f_rcptype === "pct"}
                                  min={0}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 計劃數量
                                label={"分量百分比"}
                                name={[field.name, "pct"]}
                              >
                                <InputNumber
                                  onChange={(value) => {
                                    form.setFieldValue("nwt", null);
                                    let formData = form.getFieldValue();

                                    let total = 0;
                                    // console.log(formData.details);
                                    formData.details.forEach((element) => {
                                      total += element.pct;
                                    });

                                    if (formData.details?.length > 0) {
                                      formData.details = formData.details.map((x) => {
                                        return { ...x, nwt: null };
                                      });
                                      form.setFieldsValue({ ...formData });
                                    }
                                    form.setFieldValue("pct", total);
                                  }}
                                  disabled={f_rcptype === "nwt"}
                                  min={0}
                                  style={{ width: "100%" }}
                                  addonAfter="%"
                                />
                              </Form.Item>
                            </Col>

                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 管制上限
                                label={"管制上限"}
                                rules={[
                                  {
                                    required: true,
                                    // message: "請輸入大於0的數量!",
                                    // validator: (_, value) => {
                                    //   return new Promise(async (resolve, reject) => {
                                    //     if (!value) {
                                    //       await reject("請輸入數量");
                                    //     } else if (value - 0 === NaN) {
                                    //       await reject("請輸入大於0的數字");
                                    //     } else {
                                    //       await resolve();
                                    //     }
                                    //   });
                                    // },
                                  },
                                ]}
                                name={[field.name, "UCL"]}
                              >
                                <InputNumber
                                  min={-100}
                                  max={100}
                                  style={{ width: "100%" }}
                                  addonAfter="%"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6} className="mb12">
                              <Form.Item
                                {...field}
                                // 計劃數量
                                label={"管制下限"}
                                rules={[
                                  {
                                    required: true,
                                    // message: "請輸入大於0的數量!",
                                    // validator: (_, value) => {
                                    //   return new Promise(async (resolve, reject) => {
                                    //     if (!value) {
                                    //       await reject("請輸入數量");
                                    //     } else if (value - 0 === NaN) {
                                    //       await reject("請輸入大於0的數字");
                                    //     } else {
                                    //       await resolve();
                                    //     }
                                    //   });
                                    // },
                                  },
                                ]}
                                name={[field.name, "LCL"]}
                              >
                                <InputNumber
                                  min={-100}
                                  max={100}
                                  style={{ width: "100%" }}
                                  addonAfter="%"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[20, 12]}>
                            <Col span={18}>
                              <Form.Item label={t("util.util.note")} name={[field.name, "note"]}>
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              {/* 是否有效 */}
                              <Form.Item
                                label={t("util.util.isvalid")}
                                name={[field.name, "isvalid"]}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group
                                  options={[
                                    { value: true, label: t("util.util.yes") },
                                    { value: false, label: t("util.util.no") },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ),
                    };
                  })}
                />

                <Form.Item>
                  <Button
                    //新增生產入庫詳情
                    type="dashed"
                    onClick={() => {
                      set_s_isPanelOpen((pre) => !pre);
                      let formData = form.getFieldsValue();
                      add({
                        itemno: fields.length + 1,
                        UOM: formData.UOM,
                        LCL: 1,
                        UCL: 1,
                        rcptype: formData.rcptype,
                        isvalid: true,
                        nwt: 0,
                        pct: 0,
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                    className=" bg-[#3FC6E2] text-white"
                  >
                    {t("util.util.add") + "配方標準" + t("util.util.detail")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      }

      <Row gutter={[12, 12]}>
        <Col span={4}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={20} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={
              call_addRcphd.status === "load" ||
              call_modifyRcptb.status === "load" ||
              call_modifyRcphd.status === "load"
            }
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
