import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const countRun20Wh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/countRun20Wh`, data);
};

export const getWh = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getWh`, {
    params: data,
  });
};

export const addWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addWh`, data);
};

export const updateWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateWh`, data);
};

export const deleteWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteWh`, data);
};

export const getWheel = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getWheel`, {
    params: data,
  });
};

export const addWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addWheel`, data);
};

export const updateWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateWheel`, data);
};

export const deleteWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteWheel`, data);
};

export const getLabelWheel = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getLabelWheel`, {
    params: data,
  });
};

export const addLabelWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addLabelWheel`, data);
};

export const updateLabelWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateLabelWheel`, data);
};

export const deleteLabelWheel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteLabelWheel`, data);
};

export const getRun20Wh = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getRun20Wh`, {
    params: data,
  });
};

export const addRun20Wh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addRun20Wh`, data);
};

export const updateRun20Wh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateRun20Wh`, data);
};

export const deleteRun20Wh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteRun20Wh`, data);
};

export const getPnWl = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getPnWl`, {
    params: data,
  });
};

export const addPnWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addPnWl`, data);
};

export const updatePnWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updatePnWl`, data);
};

export const deletePnWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deletePnWl`, data);
};

export const getZ1RimWl = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getZ1RimWl`, {
    params: data,
  });
};

export const addZ1RimWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addZ1RimWl`, data);
};

export const updateZ1RimWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateZ1RimWl`, data);
};

export const deleteZ1RimWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteZ1RimWl`, data);
};

export const getZ1Label = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getZ1Label`, {
    params: data,
  });
};

export const addZ1Label = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addZ1Label`, data);
};

export const updateZ1Label = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/updateZ1Label`, data);
};

export const deleteZ1Label = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/deleteZ1Label`, data);
};
