/**
 * 分組函數，用於將一個大陣列分成多個子陣列，每個子陣列的元素數量為 subGroupLength
 * @param array 要進行分組的陣列
 * @param subGroupLength 每個子陣列的元素數量
 * @returns 分組後的二維陣列
 */
export default function group<T>(array: T[], subGroupLength: number): T[][] {
  const newArray: T[][] = [];
  for (let i = 0; i < array.length; i += subGroupLength) {
    const subgroup = array.slice(i, i + subGroupLength);
    newArray.push(subgroup);
  }
  return newArray;
}
