/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import PDFview from "@/pages/TestPage/meds/IMS/PDF/PDFview";

const Preview = ({ url }) => {
  const [s_showModal, set_s_showModal] = useState(false);
  const filePath = url?.split("/");

  return (
    <>
      <div
        className="underline text-blue-500 cursor-pointer"
        onClick={(e) => {
          set_s_showModal(true);
          e.stopPropagation();
        }}
      >
        {filePath[filePath?.length - 1]}
      </div>

      <CustomModal
        open={s_showModal}
        title="preview"
        width="80%"
        footer={null}
        onCancel={() => set_s_showModal(false)}
      >
        <div className="w-full">
          <PDFview c_lightData={{ drawing: url }} />
        </div>
      </CustomModal>
    </>
  );
};

export default Preview;
