import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

//--------------取得--------------
export const getMnsPushLvl = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMnsPushLvl`, {
    params: data,
  });
};

//--------------新增--------------
export const addMnsPushLvl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMnsPushLvl`, data);
};
export const sortMnsAppGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/sortMnsAppGp`, data);
};

//--------------編輯--------------
export const updateMnsPushLvl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMnsPushLvl`, data);
};

//--------------刪除--------------
export const deleteMnsPushLvl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteMnsPushLvl`, data);
};

//--------------取得--------------
export const getMnsFunGp = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMnsFunGp`, {
    params: data,
  });
};

//--------------新增--------------
export const addMnsFunGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMnsFunGp`, data);
};

//--------------編輯--------------
export const updateMnsFunGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMnsFunGp`, data);
};

//--------------刪除--------------
export const deleteMnsFunGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteMnsFunGp`, data);
};

//--------------取得--------------
export const getMnsAppGp = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMnsAppGp`, {
    params: data,
  });
};

//--------------新增--------------
export const addMnsAppGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMnsAppGp`, data);
};

//--------------編輯--------------
export const updateMnsAppGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMnsAppGp`, data);
};

//--------------刪除--------------
export const deleteMnsAppGp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteMnsAppGp`, data);
};

//--------------取得--------------
export const getMnsEvent = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMnsEvent`, {
    params: data,
  });
};

//--------------新增--------------
export const addMnsEvent = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMnsEvent`, data);
};

//--------------編輯--------------
export const updateMnsEvent = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMnsEvent`, data);
};

//--------------刪除--------------
export const deleteMnsEvent = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteMnsEvent`, data);
};

//--------------取得--------------
export const getMnsEventSet = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMnsEventSet`, {
    params: data,
  });
};

//--------------新增--------------
export const addMnsEventSet = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMnsEventSet`, data);
};

//--------------編輯--------------
export const updateMnsEventSet = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMnsEventSet`, data);
};
