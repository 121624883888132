/* eslint-disable react-hooks/exhaustive-deps */

import { message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import useAPI from "@/hooks/useAPI";
import { getWoProcTab, getWoProcTb } from "@/service/apis/PIS/wonSearch";

export default function TB({ c_lightData }) {
  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_tabKey, set_s_tabKey] = useState("basic"); // 目前是在哪個Tab上
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getWoProcTab = useAPI(getWoProcTab);
  const call_getWoProcTb = useAPI(getWoProcTb);

  const tableColumns = [
    {
      title: "工序",
      dataIndex: "SAPwpc",
      align: "center",
      sorter: (a, b) => a.SAPwpc.localeCompare(b.SAPwpc),
    },
    {
      title: "工作中心",
      dataIndex: "pwcID",
      sorter: (a, b) => a.pwcID.localeCompare(b.pwcID),
      render: (_, rowData) => (rowData.pwcID ? rowData.pwcID + "_" + rowData.pwcNM : ""),
    },
    {
      title: "車號",
      dataIndex: "batchID",
      align: "center",
      sorter: (a, b) => a.batchID.localeCompare(b.batchID),
    },

    {
      title: "開工時間",
      dataIndex: "openT",
      align: "center",
      render: (time) => dayjs(time).format("YY-MM-DD HH:mm"),
    },
    {
      title: "開工人員",
      dataIndex: "peopleNM",
      align: "center",
    },
    {
      title: "完成時間",
      dataIndex: "closeT",
      align: "center",
      render: (time) => dayjs(time).format("YY-MM-DD HH:mm"),
    },
    {
      title: "完工人員",
      dataIndex: "closepeopleNM",
      align: "center",
    },
    {
      title: "生產數量",
      dataIndex: "inC",
      align: "right",
      width: 100,
    },
    {
      title: "良品",
      dataIndex: "okC",
      align: "right",
      width: 100,
    },
    {
      title: "不良品",
      dataIndex: "ngC",
      align: "right",
      width: 100,
    },
  ];

  useEffect(() => {
    call_getWoProcTab.request(c_lightData);
  }, []);

  useEffect(() => {
    call_getWoProcTb.request({ ...c_lightData, SAPwpc: s_tabKey });
  }, [c_lightData, s_tabKey]);

  useEffect(() => {
    if (call_getWoProcTab.status === "suc") {
      const tabkey = call_getWoProcTab.data[0]?.SAPwpc || "";
      console.log("tabkey = ", tabkey);
      set_s_tabKey(tabkey);
    }
    if (call_getWoProcTab.status === "err") {
      message.error(call_getWoProcTab.msg);
    }
  }, [call_getWoProcTab.status]);

  useEffect(() => {
    if (call_getWoProcTb.status === "suc") {
      set_s_tableData(call_getWoProcTb.data.map((x, i) => ({ ...x, key: i })) || []);
    }
    if (call_getWoProcTb.status === "err") {
      set_s_tableData([]);
    }
  }, [call_getWoProcTb.status]);

  return (
    <>
      <CustomTabs
        items={
          call_getWoProcTab?.data?.map((x) => ({
            key: x.SAPwpc,
            label: x.pwcID + "_" + x.pwcNM,
            children: (
              <CustomTable
                columns={tableColumns}
                dataSource={s_tableData}
                loading={call_getWoProcTb.status === "load"}
              />
            ),
          })) || []
        }
        activeKey={s_tabKey}
        onChange={(tabkey) => {
          set_s_tabKey(tabkey);
        }}
      />
    </>
  );
}
