import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Space, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import apiBasic from "@/service/APIBasic";
import { getInspreport } from "@/service/apis/QMS/Inspreport";
import { getEqgp } from "@/service/apis/QMS/eqgp";
import { getInspmethod } from "@/service/apis/QMS/Instrument";
import { addInspTb, getQmsSign, getUnitSelector, updateInspTb } from "@/service/apis/QMS/meds";
import { CheckOutlined } from "@ant-design/icons";

const BlueInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: #1677ff;
  }
`;
const RedInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    color: rgb(220 38 38);
  }
`;

const StyleSelect = styled(Select)`
  height: 100% !important;
  /* min-height: 90px !important; */

  .ant-select-selection-item {
    line-height: 50px !important;
  }
  .ant-select-selector {
    height: 100% !important;
    min-height: 90px !important;
    background-color: #93c5fd !important;
    text-align: center;
    font-weight: 600 !important;
    font-size: 40px !important;
    color: #1677ff;
  }

  &.ant-select-disabled .ant-select-selector {
    background-color: #93c5fd !important;
  }

  .ant-select-selection-item {
    height: 100% !important;
    font-size: 3rem;
  }
`;
// 標準值
const StyleInputNumber = styled(InputNumber)`
  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-input-number-input {
    /* height: 100%; */
    height: 50px !important;
    padding-right: 10px !important;
    text-align: right;
    font-weight: 700;
    font-size: 43px;
    color: #1677ff;
    &:disabled {
      background-color: #919191 !important;
    }
  }
`;

const CountInputNumber = styled(InputNumber)`
  .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffe4b5;
  }
`;

export default function ModalConintaer({
  c_lightData,
  s_editData,
  set_s_editData,
  set_s_isShowModal,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  console.log(c_lightData.dcUUID);
  const f_insptype = Form.useWatch("insptype", form);
  const f_inspitem = Form.useWatch("inspitem", form);
  const f_icon = Form.useWatch("icon", form);

  const f_SL = Form.useWatch("SL", form);
  const f_toleranceValue = Form.useWatch("toleranceValue", form);
  const f_MAXtoleranceValue = Form.useWatch("MAXtoleranceValue", form);
  const f_MINtoleranceValue = Form.useWatch("MINtoleranceValue", form);

  const [s_eq, set_s_eq] = useState([]);
  // 檢驗方法帶出相應的單位
  const [uomOptions, setUomOptions] = useState([]);

  // try ============================================================

  // bnddebut
  const handleGpIDChange = (value) => {
    // 重置 UOM 的值
    form.setFieldsValue({ UOM: call_getInspmethod?.data?.unit ?? "mm" });
  };

  // try =====================end===================================

  // // useAPI 取得表頭資料
  const call_getInspmethod = useAPI(getInspmethod);

  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_addInspTb = useAPI(addInspTb);
  const call_updateInspTb = useAPI(updateInspTb);

  const call_getInspreport = useAPI(getInspreport);

  const call_getQmsSign = useAPI(getQmsSign);
  const call_getUnitSelector = useAPI(getUnitSelector);

  const call_getEqgp = useAPI(getEqgp);

  const symbolOptions = call_getQmsSign.data?.map((item) => ({
    value: item?.signID,
    label: item?.twNM,
    icon: item?.icon,
  }));

  const drawmark3Options = call_getInspreport.data?.map((item) => ({
    value: item.reportID,
    label: item.reportNM,
  }));

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 阻止默认行为
    }
  };

  const onSubmit = async (values) => {
    if (type === "create") {
      console.log(c_lightData.dcUUID);
      console.log({ ...values, dcUUID: c_lightData.dcUUID, drawing: c_lightData.drawing });
      call_addInspTb.request({
        ...values,
        dcUUID: c_lightData.dcUUID,
      });
    }

    if (type === "edit") {
      console.log({ ...values, dcUUID: c_lightData.dcUUID });
      call_updateInspTb.request({
        ...values,
        dcUUID: c_lightData.dcUUID,
      });
    }
  };

  const disabledLogic = (field, measureType) => {
    if (!f_inspitem) return true;
    if (!f_insptype) return true;

    if (!field || typeof field !== "string" || !measureType) {
      console.error("Invalid input parameters");
      return false;
    }

    const result = {
      inspgroup: { count: true, measure_A: true, measure_B: true }, //作業群組
      drawno: { count: true, measure_B: true }, //圖號
      drawitem: { measure_B: true }, //圖住
      drawarea: { count: true, measure_B: true }, //圖區
      inspspec: { measure_A: true }, //檢驗規格
      drawmark1: { count: true, measure_A: false, measure_B: true }, //圖標1
      drawmark2: { count: true, measure_A: true, measure_B: true }, //圖標2
      sipcond: { measure_A: true, measure_B: true },
      UOM: { measure_A: true },
    };

    if (!result.hasOwnProperty(field)) {
      console.error(`Field "${field}" not found`);
      return false;
    }

    if (!["count", "measure_A", "measure_B"].includes(measureType)) {
      console.error(`Invalid measure type "${measureType}"`);
      return false;
    }

    return result[field][measureType] || false;
  };

  useEffect(() => {
    call_getEqgp.request({ assetkind: "" });
    call_getInspreport.request();
    call_getQmsSign.request();
    call_getUnitSelector.request();
    call_getInspmethod.request();
  }, []);

  useEffect(() => {
    if (call_addInspTb.status === "suc") {
      message.success(call_addInspTb.msg);
      set_s_isShowModal(false);
    }
    if (call_addInspTb.status === "err") {
      message.error(call_addInspTb.msg);
    }
  }, [call_addInspTb.status]);
  useEffect(() => {
    if (call_updateInspTb.status === "suc") {
      message.success(call_updateInspTb.msg);
      set_s_isShowModal(false);
    }
    if (call_updateInspTb.status === "err") {
      message.error(call_updateInspTb.msg);
    }
  }, [call_updateInspTb.status]);

  useEffect(() => {
    if (call_getQmsSign.status === "suc") {
    }
    if (call_getQmsSign.status === "err") {
      message.error(call_getQmsSign.msg);
      set_s_eq([]);
    }
  }, [call_getQmsSign.status]);
  useEffect(() => {
    if (call_getEqgp.status === "suc") {
      console.log(call_getEqgp.data);
      const options =
        call_getEqgp.data?.map((item) => ({
          label: `${item.gpID}_${item.gpNM}`,
          value: item.gpID,
          insptype: item.insptype,
          isvalid: item.isvalid,
          // type: index % 2 === 0 ? "count" : "measure",
        })) || [];
      set_s_eq(options);
    }
    if (call_getEqgp.status === "err") {
      message.error(call_getEqgp.msg);
      set_s_eq([]);
    }
  }, [call_getEqgp.status]);

  // 根據檢驗方法帶入單位
  useEffect(() => {
    const { status, msg, data } = call_getInspmethod;
    if (status === "suc") {
      message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getInspmethod.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onKeyDown={handleKeyDown}
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        symbol: "",
        sipcond: type === "create" ? "NA" : s_editData.sipcond,
        UOM: type === "create" ? "mm" : s_editData?.UOM,
        itemtype: "A",
        ...s_editData,
        icon:
          type === "edit" && s_editData.icon !== ""
            ? `${apiBasic.conn}://${apiBasic.url}/${s_editData.icon}`
            : "",

        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
    >
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="drawing" hidden>
          <Input />
        </Form.Item>
        <Form.Item label={t("util.util.itemno")} name="itemno" hidden>
          <Input placeholder="系統預設" disabled />
        </Form.Item>
      </div>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]} label={"檢驗項目"} name="inspitem">
            <Input
              onChange={(e) => {
                if (
                  (f_insptype === "measure_A" || f_insptype === "measure_B") &&
                  type === "create"
                ) {
                  form.setFieldsValue({ drawno: e.target.value });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]} label={"檢驗型態"} name="insptype">
            <Select
              className="w-full"
              onChange={(value) => {
                console.log(value);
                if (value === "count") {
                  form.setFieldsValue({
                    drawarea: "",
                    drawno: "",
                    drawitem: "",
                    drawarea: "",
                    drawmark1: "",
                    drawmark2: "",
                    drawmark3: "",
                    SL: null,
                    UOM: "",
                    toleranceValue: null,
                    MAXtoleranceValue: null,
                    MINtoleranceValue: null,
                    USL: null,
                    LSL: null,
                    UCL: null,
                    LCL: null,
                  });
                  return;
                }
                if (value === "measure_A") {
                  form.setFieldsValue({ UOM: "mm", drawno: f_inspitem });
                  return;
                }
                form.setFieldsValue({ drawno: "" });
              }}
              disabled={!f_inspitem}
              allowClear
              options={[
                { label: "計數型(定性)", value: "count" },
                // { label: "計量型(定量)", value: "measure" },
                { label: "計量型_工程圖面", value: "measure_A" },
                { label: "計量型_非工程圖", value: "measure_B" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"檢驗規格"} name="inspspec">
            <Input disabled={disabledLogic("inspspec", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="缺陷類別" name="itemtype">
            <Select
              disabled={disabledLogic("itemtype", f_insptype)}
              className="w-full"
              allowClear
              options={[
                { value: "A", label: "主要缺陷" },
                { value: "B", label: "次要缺陷" },
                { value: "C", label: "嚴重缺陷" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"管理表單"} name="drawmark3">
            <Select disabled={disabledLogic("drawmark3", f_insptype)} options={drawmark3Options} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"圖號"} name="drawno">
            <Input disabled={disabledLogic("drawno", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={f_insptype === "count" ? "規格要求" : "圖註"} name="drawitem">
            <Input disabled={disabledLogic("drawitem", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖區"} name="drawarea">
            <Input disabled={disabledLogic("drawarea", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="檢驗方法" name="gpID">
            <Select
              onChange={(_, data) => {
                // 在陣列尋找相同gpID的unit
                form.setFieldsValue({ UOM: data.data.unit ?? "mm" });
              }}
              disabled={disabledLogic("gpID", f_insptype)}
              allowClear
              options={
                call_getInspmethod?.data.map((x) => ({ label: x.gpID, value: x.gpID, data: x })) ||
                []
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖標1"} name="drawmark1">
            <Input disabled={disabledLogic("drawmark1", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"圖標2"} name="drawmark2">
            <Input disabled={disabledLogic("drawmark2", f_insptype)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="inspgroup" label="作業群組">
            <Input disabled={disabledLogic("inspgroup", f_insptype)} />
          </Form.Item>
        </Col>

        <Row
          gutter={[24, 12]}
          className={`bg-yellow-50 pb-2 ${f_insptype === "count" && "hidden"}`}
        >
          <Col span={12} className="">
            <Row gutter={[24, 0]} className="mb-[12px]">
              <Col span={12} className="">
                <Row>
                  <Col span={4}>
                    <Form.Item
                      className="h-full  mb-[0px] "
                      label={<span className="text-blue-600 font-bold">圖示</span>}
                      name="icon"
                    >
                      {f_icon === "" ? null : (
                        <div className=" flex justify-center items-center h-[88px] ">
                          <img
                            className="object-contain block w-full h-full rounded-md"
                            src={f_icon}
                            alt="無圖"
                          />
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="h-full mb-[0px]"
                      label={<span className="text-blue-600 font-bold">標準值</span>}
                      name="SL"
                    >
                      <StyleInputNumber
                        precision={2}
                        className="w-full h-[90px] font-bold bg-blue-300 "
                        // formatter={formatValue}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // 移除格式中的非數字字符
                        disabled={f_insptype === "count"}
                        onChange={(e) => {
                          console.log("onChange " + e);
                          form.setFieldsValue({ SL: e, USL: e, LSL: e, UCL: e, LCL: e });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="w-full">
                    <Form.Item
                      name="UOM"
                      label={<span className="text-blue-600 font-bold">單位</span>}
                      className="h-full mb-[0px]"
                    >
                      <StyleSelect
                        options={[
                          { label: "mm", value: "mm" },
                          { label: "µm", value: "µm" },
                          { label: "Kg", value: "Kg" },
                          { label: "g", value: "g" },
                          { label: "°C", value: "°C" },
                          { label: "°F", value: "°F" },
                          { label: "Mpa", value: "Mpa" },
                          { label: "HB", value: "HB" },
                          { label: "HR", value: "HR" },
                          { label: "HV", value: "HV" },
                          { label: "%", value: "%" },
                        ]}
                        // prefix={<span className="z-[1]">單位</span>}
                        // disabled={disabledLogic("UOM", f_insptype)}
                      />
                    </Form.Item>
                  </Col>
                  {/* 尺寸符號 */}
                  <Col span={24}>
                    <Form.Item name="symbol" label="尺寸符號" className="mb-[0px]">
                      <Select
                        disabled={f_insptype === "count"}
                        options={[{ label: "NA", value: "", icon: "" }, ...symbolOptions]}
                        style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
                        onChange={(value, rowData) => {
                          if (value === "") {
                            form.setFieldsValue({
                              icon: "",
                            });
                            return;
                          }
                          form.setFieldsValue({
                            icon: `${apiBasic.conn}://${apiBasic.url}/${rowData.icon}`,
                          });
                          console.log(value);
                          console.log(rowData);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={12} className="flex   justify-between flex-col ">
                <div className="flex w-full   justify-between items-end">
                  <Form.Item name="toleranceValue" label="雙向公差" className="mb-[0px] w-[80%] ">
                    <CountInputNumber
                      precision={2}
                      step={0.1}
                      className="w-full bg-[#FFE4B5]"
                      size="large"
                      controls={false}
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>

                  <Button
                    type="text"
                    stringMode={true}
                    className=" bg-[#FFE4B5] h-[65%]"
                    onClick={() => {
                      console.log(f_toleranceValue);
                      if (f_toleranceValue === null || f_toleranceValue === 0) {
                        form.setFieldsValue({
                          USL: f_SL,
                          LSL: f_SL,
                          UCL: f_SL,
                          LCL: f_SL,
                          MAXtoleranceValue: 0,
                          MINtoleranceValue: 0,
                        });
                        return;
                      }
                      form.setFieldsValue({
                        USL: f_SL + f_toleranceValue,
                        LSL: f_SL - f_toleranceValue,
                        UCL: f_SL + f_toleranceValue,
                        LCL: f_SL - f_toleranceValue,
                        MAXtoleranceValue: 0,
                        MINtoleranceValue: 0,
                      });
                    }}
                  >
                    <CheckOutlined className="text-[20px]" />
                  </Button>
                </div>

                <div className="flex w-full justify-between  items-end">
                  <Form.Item
                    name="MAXtoleranceValue"
                    label="單向公差(上)"
                    className="mb-[0px] w-[37%] "
                  >
                    <CountInputNumber
                      precision={2}
                      className="w-full "
                      size="large"
                      controls={false}
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>

                  <Form.Item
                    name="MINtoleranceValue"
                    label="單向公差(下)"
                    className="mb-[0px] w-[37%] "
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          value > f_MAXtoleranceValue
                            ? callback("不可大於單向公差(上)")
                            : callback();
                        },
                      },
                    ]}
                  >
                    <CountInputNumber
                      precision={2}
                      className=" w-full bg-[#FFE4B5]"
                      size="large"
                      controls={false}
                      disabled={f_insptype === "count"}
                    />
                  </Form.Item>

                  <Button
                    type="text"
                    className=" bg-[#FFE4B5] h-[65%]"
                    onClick={() => {
                      console.log(f_MAXtoleranceValue);
                      console.log(f_MINtoleranceValue);
                      const max_value = f_MAXtoleranceValue ? f_MAXtoleranceValue : 0;
                      const min_value = f_MINtoleranceValue ? f_MINtoleranceValue : 0;

                      form.setFieldsValue({
                        USL: f_SL + max_value,
                        UCL: f_SL + max_value,
                        LSL: f_SL + min_value,
                        LCL: f_SL + min_value,
                        toleranceValue: 0,
                      });
                    }}
                  >
                    <CheckOutlined className="text-[20px]" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={12} className="mb-[12px]">
            <Row gutter={[12, 12]} className=" h-full ">
              <Col span={12}>
                <Form.Item
                  label={<span className="text-blue-600 font-bold">規格上限</span>}
                  name="USL"
                  className="mb-[8px]"
                >
                  <BlueInputNumber
                    size="large"
                    precision={2}
                    step={0.1}
                    // formatter={(value) => formatAmount(value)}
                    className="w-full mb-[0px] text-blue-600 font-bold"
                    onChange={(value) => form.setFieldValue("UCL", value)}
                    placeholder="請輸入規格上限"
                    disabled={f_insptype === "count"}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={<span className="text-red-600 font-bold">管制上限</span>}
                  name="UCL"
                  className="mb-[0px]"
                >
                  <RedInputNumber
                    size="large"
                    precision={2}
                    step={0.1}
                    className="w-full"
                    placeholder="請輸入管制上限"
                    disabled={f_insptype === "count"}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="flex items-end ">
                <Form.Item
                  label={<span className="text-blue-600 font-bold">規格下限</span>}
                  name="LSL"
                  className="mb-[0px] w-full"
                >
                  <BlueInputNumber
                    size="large"
                    precision={2}
                    step={0.1}
                    // formatter={(value) => formatAmount(value)}
                    className="w-full"
                    onChange={(value) => form.setFieldValue("LCL", value)}
                    placeholder="請輸入規格下限"
                    disabled={f_insptype === "count"}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="flex items-end ">
                <Form.Item
                  label={<span className="text-red-600 font-bold">管制下限</span>}
                  name="LCL"
                  className="mb-[0px] w-full"
                >
                  <RedInputNumber
                    size="large"
                    precision={2}
                    step={0.1}
                    className="w-full"
                    placeholder="請輸入規格下限"
                    disabled={f_insptype === "count"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[24, 12]} className="w-full">
          <Col span={6}>
            <Form.Item label="檢測環境要求" name="sipcond">
              <Input
                disabled={disabledLogic("sipcond", f_insptype)}
                placeholder="請輸入檢測環境要求"
              />
            </Form.Item>
          </Col>
          <Col span={12} className="">
            <Form.Item label={t("util.util.note")} name="note">
              <Input.TextArea className="w-full" rows={1} />
            </Form.Item>
          </Col>
          <Col span={6} className="flex justify-start ">
            <Form.Item label={t("ADM.pn-manage.isvalid")} name="isvalid">
              <Radio.Group
                options={[
                  { value: true, label: t("util.util.yes") },
                  { value: false, label: t("util.util.no") },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 12]} className="w-full">
          <Col span={12} className="">
            <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          </Col>
          <Col span={12} className="flex justify-end">
            <Space>
              <Button
                onClick={() => set_s_isShowModal(false)}
                loading={call_addInspTb.status === "load" || call_updateInspTb.status === "load"}
              >
                {t("util.util.cancel")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={call_addInspTb.status === "load" || call_updateInspTb.status === "load"}
              >
                {t("util.util.ok")}
              </Button>
            </Space>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
