/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose, IconFile } from "@/components/Icon/Action";
import { multipleIDtoName } from "@/util/format";
import { getLine, deleteLine, modifyLine } from "@/service/apis/ADM/publicAPI";
import apiBasic from "@/service/APIBasic";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import FileModal from "./ModalContainer/FileModal";

import ModalConintaer from "./ModalContainer/index";

const HD = forwardRef((props, ref) => {
  const { s_searchData, arrMap } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);
  const { t } = useTranslation();
  const call_getLine = useAPI(getLine);
  const call_deleteLine = useAPI(deleteLine);
  const call_modifyLine = useAPI(modifyLine);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      // set_c_lightData({});
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getLine.request({ wlID: s_searchData.item });

  const tableColumns = [
    {
      title: t("util.util.SN"), //"序",
      dataIndex: "SN",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.production-line-manage.wlID"), //"工線ID",
      dataIndex: "wlID",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.name"), //"名稱",
      dataIndex: "wlNM",
      align: "center",
      width: "10%",
    },
    {
      title: t("ADM.production-line-manage.ws"), //"工站名稱",
      dataIndex: "wsNM",
      align: "center",
      width: "13%",
    },
    {
      title: t("ADM.production-line-manage.ps"), //"車間",
      dataIndex: "psID",
      width: "12%",
      render: (text) => multipleIDtoName(arrMap.psArr, text, "psID", "psNM"),
    },
    {
      title: t("ADM.util.pf"), //"製程",
      dataIndex: "pfID",
      width: "10%",
      render: (text) => multipleIDtoName(arrMap.pfArr, text, "pfID", "pfNM"),
    },
    {
      title: t("ADM.util.pwc"), //"工作中心",
      dataIndex: "pwcID",
      // width: "15%",
      render: (text) => multipleIDtoName(arrMap.pwcArr, text, "pwcID", "pwcNM"),
    },
    // {
    //   title: "成本中心",
    //   dataIndex: "pccID",
    //   render: (text) => multipleIDtoName(arrMap.pccArr, text, "pccID", "pccNM"),
    // },
    {
      title: t("ADM.production-line-manage.isdashboard"), // 是否有效
      dataIndex: "isdashboard",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "圖照",
      dataIndex: "wlpic",
      align: "center",
      width: "7%",
      render: (text, rowData) => {
        if (!text) return;
        const splitArr = text?.split("/");
        return (
          <span className="flex gap-3">
            <a
              className="underline"
              onClick={(e) => {
                window.open(text, "_blank");
                e.stopPropagation();
              }}
            >
              {splitArr[splitArr?.length - 1]}
            </a>
            <IconGarbage
              onClick={(e) => {
                removePic(rowData);
                e.stopPropagation();
              }}
            />
          </span>
        );
      },
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "10%",
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconFile
            onClick={(e) => {
              set_s_showFileModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteLine.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  // 確認是否刪圖照
  const removePic = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_modifyLine.request({ ...rowData, wlpic: "" }),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    // set_c_lightData({});
    if (call_getLine.status === "suc") {
      // message.success(call_getLine.msg);
      set_s_tableData(
        call_getLine.data.map((x, i) => ({
          ...x,
          key: i,
          index: i,
          // psID: x.psID.split("|").filter((x) => x !== ""),
          pwcID: x.pwcID.split("|").filter((x) => x !== ""),
          pccID: x.pccID.split("|").filter((x) => x !== ""),
        }))
      );
    } else if (call_getLine.status === "err") {
      message.error(t(`error.error-code.${call_getLine.msg}`));
      set_s_tableData([]);
    }
  }, [call_getLine.status]);

  useEffect(() => {
    if (call_deleteLine.status === "suc") {
      message.success(call_deleteLine.msg);
    } else if (call_deleteLine.status === "err") {
      message.error(t(`error.error-code.${call_deleteLine.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deleteLine.status]);

  useEffect(() => {
    if (call_modifyLine.status === "suc") {
      message.success(call_modifyLine.msg);
    } else if (call_modifyLine.status === "err") {
      message.error(t(`error.error-code.${call_modifyLine.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_modifyLine.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData?.map((item, index) => {
          return { ...item, key: index, SN: index + 1 };
        })}
        loading={call_getLine.status === "load"}
        s_editData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_s_editData(record);
              set_s_isShowModal(true);
            },
          };
        }}
        // tbMode={Object.keys(c_lightData).length > 0}
        // c_lightData={c_lightData}
        // onRow={(record) => {
        //   return {
        //     onClick: () =>
        //       record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        //   };
        // }}
      />

      <CustomModal
        // title={Object.keys(c_lightData).length > 0 ? "編輯資料" : "新增資料"}
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.production-line-manage.tabLine")}`
            : `${t("util.util.add")}${t("ADM.production-line-manage.tabLine")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          // set_c_lightData({});
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={arrMap}
        />
      </CustomModal>

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <FileModal type="sipHd" s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
});

export default HD;
