import React from "react";
import {
  IconPDSFontSizeSm,
  IconERPFontSizeSm,
  IconPDSFontSizeMd,
  IconERPFontSizeMd,
  IconPDSFontSizeLg,
  IconERPFontSizeLg,
} from "@/components/Icon/Header";

const FontIcon = ({ c_systemConfig, handlerSystemConfigChange }) => {
  const changeSize = () => {
    let size = c_systemConfig.size;
    switch (size) {
      case "small":
        // set_c_fontSizeType('middle');
        handlerSystemConfigChange("size", "middle");
        break;
      case "middle":
        // set_c_fontSizeType('large');
        handlerSystemConfigChange("size", "large");
        break;
      case "large":
        // set_c_fontSizeType('small');
        handlerSystemConfigChange("size", "small");
        break;
      default:
        // set_c_fontSizeType('middle');
        handlerSystemConfigChange("size", "middle");
    }
  };

  return c_systemConfig.theme === "pms" ||
    c_systemConfig.theme === "oms" ||
    c_systemConfig.theme === "bpm" ? (
    <div className="flex-center" onClick={changeSize}>
      {c_systemConfig.size === "small" && <IconERPFontSizeSm className="text-[40px]" />}
      {c_systemConfig.size === "middle" && <IconERPFontSizeMd className="text-[40px]" />}
      {c_systemConfig.size === "large" && <IconERPFontSizeLg className="text-[40px]" />}
    </div>
  ) : (
    <div className="flex-center" onClick={changeSize}>
      {c_systemConfig.size === "small" && <IconPDSFontSizeSm className="text-[40px]" />}
      {c_systemConfig.size === "middle" && <IconPDSFontSizeMd className="text-[40px]" />}
      {c_systemConfig.size === "large" && <IconPDSFontSizeLg className="text-[40px]" />}
    </div>
  );
};

export default FontIcon;
