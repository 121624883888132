import { DatePicker, Spin, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import PageHeader from "@/components/PageHeader";
import useWebSocket from "@/hooks/useWebSocket";
import { efficiencySocket } from "@/service/ws/ws";

import BarChart from "./BarChart";
import OvalChart from "./OvalChart";

const { Title } = Typography;

const Wrapper = styled.div`
  padding: 0px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
  height: calc(100vh - 120px);
  //background-color: green;

  // 折線圖跟水波圖的區域
  .ChartContainer {
    display: flex;
    justify-content: space-between;
    height: 350px;
  }

  // 稼動
  .FacetChart {
    //height: calc(100% - 350px - 100px);
    height: calc(100% - 400px);
    margin-top: 30px;
    //border: 1px solid red;
    //margin-bottom: 20px;
    //background-color: red
    /* z-index: 99; */
    //overflow: auto;
  }

  // 稼動率的區塊
  .FacetChartContainer {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
      0px 1px 18px 0px rgb(0 0 0 / 12%);
    height: 50px;
    //margin-top: 20px;
    display: flex;
    //background-color: pink
  }

  .Container {
    height: calc(100% - 70px);
    overflow: auto;
  }

  .FacetChartContainer1 {
    //box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    //height: 50px;
    /* margin-top: 20px; */
    display: flex;
    padding: 5px;
    box-shadow: 5px 1px 1px rgb(0 0 0 / 20%);
  }

  // 稼動率的 header title
  .colHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #3089c7;
    //padding-top: 10px;
    //padding-bottom: 10px;
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // 線別的按鈕
  .wsBtn {
    color: rgba(0, 0, 0, 0.87);
    //width: 60px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    padding: 6px 16px;
  }
`;

const Efficiency = () => {
  const [s_overView, set_s_overView] = useState([]);

  const [s_searchData, set_s_searchData] = useState({
    "date": dayjs(new Date()).format("YYYY-MM-DD"),
  });
  const call_efficiencySocket = useWebSocket(efficiencySocket);

  //第一次渲染及每次 searchData 變動時都重抓資料
  useEffect(() => {
    call_efficiencySocket.changeParams(s_searchData);
    call_efficiencySocket.setIsOpen(true);

    return () => {
      call_efficiencySocket.setIsOpen(false);
    };
  }, [s_searchData]);

  // 取得表頭資料
  useEffect(() => {
    if (call_efficiencySocket.socketData.status) {
      console.log("data = ", call_efficiencySocket.socketData.data);
      set_s_overView(call_efficiencySocket.socketData.data);
    }
  }, [call_efficiencySocket.socketData]);

  // 切換搜尋項目(工站、時間)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    set_s_searchData(tmp);
  };

  return (
    <Wrapper>
      <PageHeader
        title="綜合效能總表"
        extra={[
          <DatePicker
            placeholder="請選擇日期"
            onChange={(value) => handleChange("date", dayjs(value).format("YYYY-MM-DD"))}
            size="large"
            allowClear={false}
            style={{ width: "150px" }}
            value={dayjs(s_searchData.date)}
          />,
        ]}
      />
      <Spin spinning={!call_efficiencySocket.socketData.status}>
        <div className="FacetChart">
          <div className="FacetChartContainer">
            <div className="colHeader" style={{ width: "10%" }}>
              線別
            </div>
            <div className="colHeader" style={{ width: "25%" }}>
              稼動率
            </div>
            <div className="colHeader" style={{ width: "22.5%" }}>
              直通良率
            </div>
            <div className="colHeader" style={{ width: "22.5%" }}>
              生產效率
            </div>
            <div className="colHeader" style={{ width: "20%" }}>
              綜合效能
            </div>
          </div>

          <div className="Container">
            {s_overView.lineData &&
              s_overView.lineData.map((item, index) => {
                return (
                  <div key={index} className="FacetChartContainer1">
                    <div className="colHeader" style={{ width: "10%" }}>
                      <div className="wsBtn">
                        {item.wlID +
                          (item.linemix === 0 ? "-單" : item.linemix === 1 ? "-左" : "-右")}
                      </div>
                    </div>
                    <div className="col" style={{ width: "25%", overflow: "hidden" }}>
                      <BarChart data={item.ACT.toFixed(2)} />
                    </div>
                    <div className="col" style={{ width: "22.5%", overflow: "hidden" }}>
                      <BarChart data={item.QTY.toFixed(2)} />
                    </div>
                    <div className="col" style={{ width: "22.5%", overflow: "hidden" }}>
                      <BarChart data={item.PPE.toFixed(2)} />
                    </div>
                    <div className="col" style={{ width: "20%" }}>
                      <OvalChart percentage={item.EFF.toFixed(2)} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Spin>
    </Wrapper>
  );
};

export default Efficiency;
