import { message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose } from "@/components/Icon/Action";
import { ExpandButton } from "@/components/PDS/Buttons";
// util component
import PageHeader from "@/components/PageHeader";
// API
import useAPI from "@/hooks/useAPI";
import { getRcptb } from "@/service/apis/QMS/QMSManage";
// import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import { MenuOutlined } from "@ant-design/icons";

// component
import DndModal from "./ModalContainer/DndModal";

const TB = ({ c_lightData, set_c_lightData }) => {
  const { t } = useTranslation();
  const call_getRcptb = useAPI(getRcptb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowDndModal, set_s_isShowDndModal] = useState(false);

  const AQL_options = [
    { label: "正常檢驗計劃 I ", value: "1" },
    { label: "正常檢驗計劃 II", value: "2" },
    { label: "正常檢驗計劃 III", value: "3" },
    { label: "特殊抽樣計劃 S-1", value: "S1" },
    { label: "特殊抽樣計劃 S-2", value: "S2" },
    { label: "特殊抽樣計劃 S-3", value: "S3" },
    { label: "特殊抽樣計劃 S-4", value: "S4" },
  ];

  // table 用來顯示的
  const AQL_map = useMemo(() => {
    return AQL_options.reduce((prev, curr) => {
      prev[curr.value] = curr.label;
      return prev;
    }, {});
  }, []);

  const tableColumns = [
    {
      title: "",
      dataIndex: "sort",
      align: "center",
      width: "2%",
      render: () => <MenuOutlined />,
    },
    // {
    //   title: "rcp文編",
    //   dataIndex: "rcpID",
    //   width: "18%",
    //   sorter: (a, b) => a.sipID.localeCompare(b.sipID),
    // },
    // {
    //   title: "文編碼",
    //   dataIndex: "dcUUID",
    //   width: "25%",
    //   // sorter: (a, b) => a.sipID.localeCompare(b.sipID),
    // },
    {
      title: "項序",
      dataIndex: "itemno",
      align: "center",
      width: "5%",
    },
    // {
    //   title: t("util.util.PN"), // 料號
    //   dataIndex: "PN",
    //   width: "12%",
    //   // sorter: (a, b) => a.PN.localeCompare(b.PN),
    // },
    {
      title: "配方項目",
      dataIndex: "rcpitem",
      width: "12%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "量測設備",
      dataIndex: "device",
      width: "12%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "記錄類型",
      dataIndex: "rcptype",
      width: "8%",
      align: "center",
      // sorter: (a, b) => a.siptype.localeCompare(b.siptype),
      // 等i18N寫法
      render: (text) => {
        const map = {
          "nwt": "固定量",
          "pct": "百分比",
        };
        return map[text];
      },
    },

    {
      title: "分量凈重",
      dataIndex: "nwt",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "使用單位",
      dataIndex: "UOM",
      width: "8%",
      align: "center",
      render: (text) => {
        if (text === "pct") {
          return "%";
        }
        return text;
      },
      // sorter: (a, b) => a.siptype.localeCompare(b.siptype),
    },
    {
      title: "分量百分比(%)",
      dataIndex: "pct",
      align: "right",
      width: "8%",
    },
    {
      title: "管制上限(%)",
      dataIndex: "UCL",
      className: "bg-[#f9e9d7ac]",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "管制下限(%)",
      dataIndex: "LCL",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      width: "10%",
      // render: (text) => ctrlrequire_map[text],
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      width: "90px",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },

    // {
    //   title: "控點",
    //   dataIndex: "ctrlspot",
    //   width: "8%",
    //   align: "center",
    //   // sorter: (a, b) => a.ctrlspot.localeCompare(b.ctrlspot),
    //   render: (text) => {
    //     const map = {
    //       ws: "工站作業",
    //       wl: "工線作業",
    //       wg: "工段作業",
    //       wh: "庫房作業",
    //     };
    //     return map[text];
    //   },
    // },
    // {
    //   title: "控點ID",
    //   dataIndex: "spotID",
    //   width: "18%",
    //   sorter: (a, b) => a.spotID.localeCompare(b.spotID),
    //   render: (text, rowData, _) => {
    //     // return multipleIDtoName(s_arrMap[rowData.ctrlspot], text, "value", "label");
    //   },
    // },

    // {
    //   title: "檢驗週期",
    //   dataIndex: "ctrlCT",
    //   align: "center",
    //   width: "10%",
    //   render: (text) => formatAmount(text) + " hr",
    // },

    // {
    //   title: "工程圖檔",
    //   dataIndex: "drawing",
    //   width: "8%",
    //   align: "center",
    //   render: (text) => text && <Preview url={text} />,
    // },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "90px",
      // render: (_, rowData) => (
      //   <Space>
      //     {/* <IconFile
      //       onClick={(e) => {
      //         set_s_showFileModal(true);
      //         set_s_editData(rowData);
      //         e.stopPropagation();
      //       }}
      //     /> */}
      //     <IconPen
      //       onClick={(e) => {
      //         set_s_isShowModal(true);
      //         console.log(rowData);
      //         set_s_editData(rowData);
      //         e.stopPropagation();
      //       }}
      //     />
      //     <IconGarbage
      //       onClick={(e) => {
      //         removeData(rowData);
      //         e.stopPropagation();
      //       }}
      //     />
      //   </Space>
      // ),
    },
  ];

  // 第一次渲染及每次 c_lightData 改變時執行
  useEffect(() => {
    call_getRcptb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getRcptb;
    if (status === "suc") {
      console.log("call_getQmsSipTb.data = ", data);
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getRcptb.status]);

  const searchBasic = [
    <ExpandButton key="ExpandButton" onClick={() => set_s_isShowDndModal(true)} />,
    // <AddButton onClick={() => set_s_isShowModal(true)} />,
  ];

  return (
    <>
      <PageHeader
        title={
          <span>
            配方標準明細_
            <span className="text-blue-500 font-semibold">{c_lightData.rcpID}</span>
          </span>
        }
        // extra={searchBasic}
      />
      <CustomTable
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={s_tableData}
        pagination={false}
      />

      <CustomModal
        title={
          <span>
            配方標準明細_
            <span className="text-blue-500 font-semibold">{c_lightData.rcpID}</span>
          </span>
        }
        width={"100%"}
        open={s_isShowDndModal}
        onCancel={() => set_s_isShowDndModal(false)}
        afterClose={() => {
          call_getRcptb.request(c_lightData);
        }}
      >
        <DndModal
          c_lightData={c_lightData}
          tableColumns={tableColumns}
          s_tableData={s_tableData}
          set_s_tableData={set_s_tableData}
          call_getRcptb={call_getRcptb}
        />
      </CustomModal>
    </>
  );
};

export default TB;
