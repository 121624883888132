import { Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deleteInspreport, getInspreport } from "@/service/apis/QMS/Inspreport";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯

  // useAPI 取得表頭資料
  const call_getInspreport = useAPI(getInspreport);
  // useAPI 刪除表頭資料
  const call_deleteInspreport = useAPI(deleteInspreport);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  // 搜尋
  const onSearch = () => {
    call_getInspreport.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteInspreport.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "表單ID", // 料號
      dataIndex: "reportID",
      align: "center",
      width: "3%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "表單名稱", // 料號
      dataIndex: "reportNM",
      align: "left",
      width: "20%",
      // width: "3%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "限用製程", //線別
      dataIndex: "pfID",
      align: "left",
      width: "8%",
      // width: "4%",
      onCell: (rowData) => ({ className: `bg-[#fffde4] ` }),
      render: (text) => {
        const genNM = s_arrMap["pf"].find((item) => item.value === text)?.label;
        console.log(genNM);
        return <ShowTooltip title={genNM} toolTipText={genNM} />;
      },
      // width: "7%",
      // render: (text) => ctrlrequire_map[text],
    },
    {
      title: "限用工站", //"工站ID",
      dataIndex: "wsID",
      width: "8%",
      render: (text) => {
        const genNM = s_arrMap["ws"].find((item) => item.value === text)?.label;

        return <ShowTooltip title={text} toolTipText={genNM} />;
      },

      onCell: (rowData) => ({ className: `bg-[#fffde4] ` }),
    },
    {
      title: "限用工作中心", //"製程ID",
      dataIndex: "pwcID",
      // width: "4%",
      width: "8%",
      align: "center",
      onCell: (rowData) => ({ className: `bg-[#fffde4] ` }),

      render: (text) => {
        const genNM = s_arrMap["pwc"].find((item) => item.value === text)?.label;

        return <ShowTooltip title={text} toolTipText={genNM} />;
      },
    },
    {
      title: "限用機碼", //"製程ID",
      dataIndex: "wlID",
      // width: "4%",
      width: "8%",
      align: "center",
      onCell: (rowData) => ({ className: `bg-[#fffde4] ` }),
      render: (text) => {
        const genNM = s_arrMap["wl"].find((item) => item.value === text)?.label;

        return <ShowTooltip title={text} toolTipText={genNM} />;
      },
    },
    {
      title: "自定限用分類", //"製程ID",
      dataIndex: "usecata",
      // width: "4%",
      width: "8%",
      align: "center",
      onCell: (rowData) => ({ className: `bg-[#fffde4] ` }),
      render: (text) => {
        const genNM = s_arrMap["wl"].find((item) => item.value === text)?.label;

        return <ShowTooltip title={text} toolTipText={genNM} />;
      },
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
      width: "8%",
      render: (text) => <ShowTooltip title={text} />,
    },

    {
      title: "建立時間",
      dataIndex: "createT",
      align: "center",
      width: "5%",

      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      align: "center",
      width: "3%",
      // sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "5%",

      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getInspreport;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          index: i,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getInspreport.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteInspreport;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteInspreport.status]);

  return (
    <>
      <CustomTable
        // scroll={{ x: 0 }}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        // dataSource={[]}
        loading={call_getInspreport.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.sipID === c_lightData.sipID ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={type === "create" ? "新增管理表單" : "編輯管理表單"}
        width={"70%"}
        // style={{ top: 150 }}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
