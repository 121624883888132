import { message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util component
import PageHeader from "@/components/PageHeader";
// API
import useAPI from "@/hooks/useAPI";
import { addQmsApSettingTb, getQmsApSettingTb } from "@/service/apis/QMS/Apui";
// import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import { MenuOutlined } from "@ant-design/icons";

// component
// import DndModal from "./ModalContainer/DndModal";
// import Preview from "../../File/Preview";

const TB = ({ c_lightData, set_c_lightData }) => {
  const { t } = useTranslation();
  const call_getQmsApSettingTb = useAPI(getQmsApSettingTb);
  const call_addQmsApSettingTb = useAPI(addQmsApSettingTb);
  // const call_updateQmsApuiRec = useAPI(updateQmsApuiRec);

  const [s_spinning, set_s_spinning] = useState(false);
  // Tabs 的資料
  const [s_tabs, set_s_tabs] = useState([]);
  // 目前選中的 tab
  const [s_TabKey, set_s_TabKey] = useState("");
  const [s_tableColumns, set_s_tableColumns] = useState([]);
  const [s_tableData, set_s_tableData] = useState([]);

  const tableColumns = [
    {
      title: "",
      dataIndex: "sort",
      align: "center",
      width: "2%",
      render: () => <MenuOutlined />,
    },

    {
      title: t("util.util.itemno"), // 項序
      dataIndex: "itemno",
      key: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },

    {
      title: "sop文編",
      dataIndex: "sopID",
      width: "25%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "版次",
      dataIndex: "version",
      align: "center",
      width: "5%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "SOPID註記",
      dataIndex: "sophdnote",
      // width: "12%",
      align: "left",
    },
    {
      title: "功能框列數",
      dataIndex: "rowcount",
      align: "center",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.updtT"), //"更新時間",
      dataIndex: "updtT",
      align: "center",
      width: "8%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    // {
    //   title: "分量百分比(%)",
    //   dataIndex: "pct",
    //   align: "right",
    //   width: "8%",
    // },
    // {
    //   title: "管制上限(%)",
    //   dataIndex: "UCL",
    //   className: "bg-[#f9e9d7ac]",
    //   align: "right",
    //   width: "8%",
    //   render: (text) => formatAmount(text),
    // },
    // {
    //   title: "管制下限(%)",
    //   dataIndex: "LCL",
    //   align: "right",
    //   className: "bg-[#f9e9d7ac]",
    //   width: "8%",
    //   render: (text) => formatAmount(text),
    // },
    // {
    //   title: "備註",
    //   dataIndex: "note",
    //   align: "center",
    //   width: "10%",
    //   // render: (text) => ctrlrequire_map[text],
    // },
    // {
    //   title: t("util.util.isvalid"), // 是否有效
    //   dataIndex: "isvalid",
    //   width: "90px",
    //   align: "center",
    //    render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },

    // {
    //   title: t("util.util.action"), // 操作
    //   dataIndex: "action",
    //   align: "center",
    //   width: "90px",

    // },
  ];

  const handleChangeTab = (activeKey) => {
    set_s_TabKey(activeKey);
    const tab = s_tabs.find((item) => item.key === activeKey);
    call_getQmsApSettingTb.request(tab);
  };

  // 第一次渲染及 c_lightData 改變時取得表身資料
  useEffect(() => {
    call_getQmsApSettingTb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得 Tabs
  useEffect(() => {
    const { status, data, msg } = call_getQmsApSettingTb;
    console.log("call", status);
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(data);
      console.log("data = ", data);
      const tabs =
        data?.map((item) => ({ ...item, key: item.sophdnote, label: item.sophdnote })) || [];
      console.log("tabs = ", tabs);
      set_s_tabs(tabs);
      set_s_TabKey(tabs[0]?.key);
      // call_getQmsApSettingTb.request(tabs[0]);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
      set_s_tabs([]);
      set_s_tableColumns([]);
      set_s_TabKey("");
    }
  }, [call_getQmsApSettingTb.status]);

  // 取得表尾
  // useEffect(() => {
  //   const { status, data, msg } = call_getQmsApuiRec;
  //   if (status === "load") {
  //     set_s_spinning(true);
  //   }
  //   if (status === "suc") {
  //     console.table("call_getQmsApuiRec.data = ", data);
  //     if (data.length === 0) return;

  //     // 取得 tableColumns
  //     const column = Object.keys(data[0]); // 格式 => ["氣味", "莫耳數", "濃度"]
  //     const tableColumns = column.map((item) => ({
  //       title: item,
  //       dataIndex: item,
  //       key: item,
  //       editable: true,
  //       align: "center",
  //     }));

  //     // 取得 tableData
  //     const _tableData = data?.map((item, index) => ({
  //       ...item,
  //       key: index,
  //     }));

  //     set_s_tableData(_tableData);
  //     set_s_tableColumns(tableColumns);
  //     set_s_spinning(false);
  //   }
  //   if (status === "err") {
  //     message.error(t(`error.error-code.${msg}`));
  //     set_s_spinning(false);
  //   }
  // }, [call_getQmsApuiRec.status]);

  // 更新表尾
  useEffect(() => {
    const { status, data, msg } = call_addQmsApSettingTb;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      console.table("call_updateQmsApuiRec.data = ", data);
      call_addQmsApSettingTb.request(data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_spinning(false);
    }
  }, [call_addQmsApSettingTb.status]);

  // const searchBasic = [
  //   <ExpandButton key="ExpandButton" onClick={() => set_s_isShowDndModal(true)} />,
  //   // <AddButton onClick={() => set_s_isShowModal(true)} />,
  // ];

  return (
    <>
      <PageHeader
        title={
          <span>
            介面區塊設定
            {/* <span className="text-blue-500 font-semibold">{c_lightData.rcpID}</span> */}
          </span>
        }
        // extra={searchBasic}
      />
      <CustomTable
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item,
          };
        })}
        pagination={false}
      />

      <CustomModal
        title={
          <span>
            配方標準明細_
            {/* <span className="text-blue-500 font-semibold">{c_lightData.rcpID}</span> */}
          </span>
        }
        width={"100%"}
        // open={s_isShowDndModal}
        // onCancel={() => set_s_isShowDndModal(false)}
        afterClose={() => {
          // call_getRcptb.request(c_lightData);
        }}
      >
        {/* <DndModal
          c_lightData={c_lightData}
          tableColumns={tableColumns}
          s_tableData={s_tableData}
          set_s_tableData={set_s_tableData}
          call_getRcptb={call_getRcptb}
        /> */}
      </CustomModal>
    </>
  );
};

export default TB;
