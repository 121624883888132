import React from "react";
import "./Square.css";
import "./Circle.css";
import "./Triangle.css";

const TransLight = ({ type = "square", color = "bg-red-500" }) => {
  // type 如果是 square 就會吃 Square.css, 如果是Circle 就會吃Circle.css

  return (
    <span className={`${type}`} style={{ backgroundColor: color }}>
      <span style={{ "--i": 0 }}></span>
      {/* <span style={{ "--i": 1 }}></span> */}
      {/* <span style={{ "--i": 2 }}></span> */}
      {/* <span style={{ "--i": 3 }}></span> */}
    </span>
  );
};

export default TransLight;
