/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, message, Space, Button } from "antd";
import { ExclamationCircleOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getrun8090Tb } from "@/service/apis/WMS/ProInstock";
import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData, hdRef, c_searchData }) => {
  const { t } = useTranslation();

  // useAPI 取得表身資料
  const call_getrun8090Tb = useAPI(getrun8090Tb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_editData, set_s_editData] = useState({});

  const editData = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  const deleteData = (rowData) => {
    alert("?");
    // Modal.confirm({
    //   title: t(`IMS.util.opcode_${c_lightData.opcode}`) + "明細刪除確認",
    //   icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
    //   content: "請確認您希望删除此資料",
    //   okText: t("util.util.ok"), // 確定
    //   cancelText: t("util.util.cancel"), // 取消
    //   onOk: () => call_deleteTb.request({ ...rowData, opcode: c_lightData.opcode }),
    // });
  };

  // 第一次渲染以及c_lightData發生改變時重新取得表身資料
  useEffect(() => {
    call_getrun8090Tb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getrun8090Tb;

    if (status === "suc") {

      set_s_tableData(data.map((x, i) => ({ ...x, key: i })));
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getrun8090Tb.status]);

  const tableColumns = [
    {
      title: t("util.util.SN"), // 序
      dataIndex: "itemno",
      align: "center",
    },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("util.util.whID"), // 倉別
      dataIndex: "whID",
      align: "center",
      render: (_, record) => `${record.whID} -${record.whNM}`,
    },
    {
      title: t("util.util.idmtype"), // 過帳型態
      dataIndex: "idmtype",
      align: "center",
      render: (text, _, __) => {
        const keyMap = {
          S: t("util.util.idmtype_s"),
          D: t("util.util.idmtype_d"),
          M: t("util.util.idmtype_m"),
        };
        return keyMap[text];
      },
    },
    {
      // title: opcodeBatchnoMap[c_lightData.opcode],
      title: t(`IMS.util.batchno_${c_lightData.opcode}`),
      dataIndex: "batchno",
      align: "center",
    },
    {
      title: t("IMS.util.planqty"), // 計畫數量
      dataIndex: "planqty",
      align: "right",
      render: (text) => formatAmount(text)
    },
    {
      title: t("IMS.util.realtqty"), // 實際數量
      dataIndex: "realqty",
      align: "right",
      render: (text) => formatAmount(text),
    },

    {
      title: t("util.util.action"),//"操作",
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => {
        return (
          <Space className="flex-center font-size-24">
            <IconPen
              // 只有庫房調入不可編輯
              disabled={c_lightData.lifeF !== "0"}
              onClick={() => editData(rowData)}
            />

            {/* <IconGarbage onClick={() => deleteData(rowData)} /> */}
          </Space>
        );
      },
    },
  ];
  console.log(c_lightData.lifeF, typeof c_lightData.lifeF);
  return (
    <>
      <PageHeader
        title={t(`IMS.util.opcode_${c_lightData.opcode}`) + " " + t(`util.util.detail`)}
        extra={[
          <Button
            key="hidden"
            danger
            icon={<FullscreenExitOutlined />}
            onClick={() => set_c_lightData({})}
          >
            {t("util.util.hide_detail")}
          </Button>,
        ]}
      />

      <CustomTable columns={tableColumns} dataSource={s_tableData} tbMode pagination={false} />

      <CustomModal
        width="60%"
        title={"編輯" + t(`IMS.util.opcode_${c_lightData.opcode}`) + "明細"}
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          hdRef.Call_hd();
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
