import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, InputNumber, Radio } from "antd";
import { addDept, modifyDept } from "@/service/apis/ADM/functionParamsManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();

  const call_addDept = useAPI(addDept);
  const call_modifyDept = useAPI(modifyDept);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addDept.request(values);
    }
    if (type === "edit") {
      call_modifyDept.request(values);
    }
  };

  useEffect(() => {
    if (call_addDept.status === "suc") {
      message.success(call_addDept.msg);
      set_s_isShowModal(false);
    } else if (call_addDept.status === "err") {
      message.error(t(`error.error-code.${call_addDept.msg}`));
    }
  }, [call_addDept.status]);

  useEffect(() => {
    if (call_modifyDept.status === "suc") {
      message.success(call_modifyDept.msg);
      set_s_isShowModal(false);
    } else if (call_modifyDept.status === "err") {
      message.error(t(`error.error-code.${call_modifyDept.msg}`));
    }
  }, [call_modifyDept.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        {/* "部門別" */}
        <Col span={8}>
          <Form.Item
            label={t("ADM.plant-manage.deptID")}
            name="deptID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "部門名稱" */}
          <Form.Item label={t("util.util.name")} name="deptNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
