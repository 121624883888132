import { Carousel, Col, DatePicker, Row, Select, Spin, message, Divider } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SystemContext from "@/components/Context/SystemContext";

import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getLine, getPF, getStation } from "@/service/apis/ADM/publicAPI";
import { tickerBoard } from "@/service/apis/ADM/tickerDashboard";

import NgPieChart from "./chart/NgPieChart";
import ProductionLineChart from "./chart/ProductionLineChart";
import { numberWithCommas } from "@/util/format";

dayjs.extend(duration);

function floatFormat(num, pos) {
  const size = Math.pow(10, pos);
  return Math.round(num * size) / size;
}

const CustomCarousel = styled(Carousel)`
  // 指定輪播牆高度
  /* height: 430px; */

  .slick-dots li {
    width: 60px;
  }
  .slick-dots li button {
    background: #494949 !important;
    border-radius: 10px !important;
    height: 10px;
  }
  .slick-slide {
    height: 440px !important;
  }
`;

const Title = styled.span`
  color: ${(props) => props.pageTitle};
`;

const StyleDatePicker = styled(DatePicker)`
  .ant-picker-input > input {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
`;

const CustomSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 25px;
    color: black;
    text-align: center;
  }
  .ant-select-arrow {
    font-size: 20px;
    color: blue;
    margin-top: -4px;
  }
`;

const CustomWsSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 22px;
    color: black;
  }
  .ant-select-arrow {
    font-size: 20px;
    color: blue;
    margin-top: -4px;
  }
`;

// 預設時間邏輯，假設今天早上7點打開頁面的時候是顯示3/7但是今天早上九點打開的話會是3/8
const defNowTime = () => {
  const getHour = dayjs().hour();
  if (getHour >= 8) {
    return dayjs().format("YYYY-MM-DD");
  } else {
    return dayjs().subtract(8, "hour").format("YYYY-MM-DD");
  }
};

export default function TickerDashboard() {
  const { t } = useTranslation();
  const { search } = useLocation();
  // 从URL中解析查询参数
  const searchParams = new URLSearchParams(search);
  const query_wlID = searchParams.get("wlID");

  const ticker_ref = useRef(null);
  const productionLineChartParent_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    wlID: undefined,
    date: defNowTime(),
  });

  const call_tickerBoard = useAPI(tickerBoard);
  const call_getLine = useAPI(getLine);

  // 深淺樣式切換
  const { c_systemConfig } = useContext(SystemContext);

  const pageTitle = c_systemConfig.theme === "dark" ? `text-[#FFFFFF]` : "bg-[#54586F]";

  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    set_s_searchData(tmp);
  };

  useEffect(() => {
    call_getLine.request();
  }, []);

  useEffect(() => {
    console.log(s_searchData.wlID);
    if (s_searchData.wlID) {
    }
  }, [s_searchData.wlID]);

  useEffect(() => {
    if (call_getLine.status === "suc") {
    }
    if (call_tickerBoard.status === "err") {
      message.error(call_tickerBoard.msg);
    }
  }, [call_tickerBoard.status]);

  useEffect(() => {
    if (call_getLine.status === "suc") {
      set_s_searchData((prev) => ({
        ...prev,
        wlID: query_wlID ? query_wlID : call_getLine.data[0].wlID,
      }));
    }
  }, [call_getLine.status]);

  return (
    <Spin spinning={call_tickerBoard.status === "load"}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <PageHeader
            title={<Title color={pageTitle}>機台生產看板</Title>}
            extra={[
              <div className="flex w-[250px] bg-white ">
                <div className="w-1/2 bg-blue-300 px-2 py-2 font-bold text-[22px]">不良總數</div>
                <div className="flex-1 flex justify-end items-center text-[22px]">
                  {numberWithCommas(call_tickerBoard.data?.totalng ?? 0)}
                </div>
              </div>,
              <div className="flex w-[250px] bg-white ">
                <div className="w-1/2 bg-blue-300 px-2 py-2 font-bold text-[22px]">良品總數</div>
                <div className="flex-1 flex justify-end items-center text-[22px]">
                  {numberWithCommas(call_tickerBoard.data?.totalok ?? 0)}
                </div>
              </div>,
              <CustomWsSelect
                size="large"
                showSearch
                value={s_searchData.wlID}
                optionFilterProp="label"
                className="w-[236px]"
                options={call_getLine?.data.map((x) => ({
                  label: x.wlNM,
                  value: x.wlID,
                }))}
                onChange={(value) => {
                  set_s_searchData((prev) => ({ ...prev, wlID: value }));
                  call_tickerBoard.request({ ...s_searchData, wlID: value });
                }}
              />,
              <StyleDatePicker
                placeholder="請選擇起始日期"
                onChange={(value) => {
                  handleChange("date", dayjs(value).format("YYYY-MM-DD"));
                  call_tickerBoard.request({
                    ...s_searchData,
                    date: dayjs(value).format("YYYY-MM-DD"),
                  });
                }}
                value={dayjs(s_searchData.date)}
                className="w-[236px]"
              />,
            ]}
          />
        </Col>

        <Col span={24}>
          <Row>
            <Col span={16} className="pr-4" ref={productionLineChartParent_ref}>
              <ProductionLineChart
                chartData={call_tickerBoard?.data?.line ?? []}
                parentRef={productionLineChartParent_ref}
              />
            </Col>

            <Col span={8} className="">
              <NgPieChart
                ngData={
                  call_tickerBoard?.data?.ng?.map((x) => ({
                    ...x,
                    percent: floatFormat(x.percent, 2),
                  })) ?? []
                }
                parentRef={ticker_ref}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
