/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";

import { IconPen, IconGarbage, IconCheck, IconClose, IconFile } from "@/components/Icon/Action";

import { message, Space, Typography } from "antd";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/PageHeader";
import { getDevice, updateDevice } from "@/service/apis//ADM/measurementEquipment";
import ModalContainer from "./ModalContainer";
import useAPI from "@/hooks/useAPI";
// import RecModal from "./RecModal";
import { UploadOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  // 編輯Table用
  .editable-cell {
    position: relative;
  }
  // 編輯Table用
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  // 編輯Table用
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 32px;
    position: absolute;
    bottom: 8px;
    width: 90%;
  }

  .editable-row:hover .antd-table-cell {
    position: relative;
  }

  // 編輯Table用
  [data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;
const { Link } = Typography;

const TB = ({ c_lightData, set_c_lightData }) => {
  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);
  const [s_editData, set_s_editData] = useState({});

  const [s_rec, set_s_rec] = useState({});

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 200,
  });
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState("");

  const call_getDevice = useAPI(getDevice);
  const call_updateDevice = useAPI(updateDevice);

  // 更新 c_lightData 重新抓資料
  useEffect(() => {
    console.log(c_lightData);
    if (Object.keys(c_lightData).length > 0) {
      call_getDevice.request({ dcUUID: c_lightData.dcUUID });
    } else {
      set_s_tableData([]);
    }
  }, [c_lightData]);

  // 取得表身
  useEffect(() => {
    if (call_getDevice.status === "suc") {
      const tmpData = call_getDevice.data.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      message.success(call_getDevice.msg);
      set_s_tableData(tmpData);
    }
    if (call_getDevice.status === "err") {
      message.error(call_getDevice.msg);
      set_s_tableData([]);
    }
  }, [call_getDevice.status]);

  // 編輯表身
  useEffect(() => {
    if (call_updateDevice.status === "suc") {
      // 編輯成功要重新取得資料
      call_getDevice.request();
      message.success(call_updateDevice.msg);
    }
    if (call_updateDevice.status === "err") {
      message.error(call_updateDevice.msg);
    }
  }, [call_updateDevice.status]);

  // 表身有資料、RWD 時計算高度

  const tableColumns = [
    // {
    //   title: "SOP文編",
    //   dataIndex: "sopID",
    //   key: "sopID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    // },
    {
      title: "資產圖面",
      dataIndex: "draw",
      align: "center",
      // width: "10%",
      render: (text) => {
        const splitArr = text.split("/");
        return (
          <Link
            onClick={() => {
              window.open(text, "_block");
            }}
          >
            {splitArr[splitArr.length - 1]}
          </Link>
        );
      },
    },
    {
      title: "資產照片",
      dataIndex: "pic",
      align: "center",

      render: (text) => {
        const splitArr = text.split("/");
        return (
          <Link
            onClick={() => {
              window.open(text, "_block");
            }}
          >
            {splitArr[splitArr.length - 1]}
          </Link>
        );
      },
    },
    {
      title: "設備手冊",
      dataIndex: "manual",
      align: "center",

      render: (text) => {
        const splitArr = text.split("/");
        return (
          <Link
            onClick={() => {
              window.open(text, "_block");
            }}
          >
            {splitArr[splitArr.length - 1]}
          </Link>
        );
      },
    },

    {
      title: "是否保養計畫",
      dataIndex: "ismrplan",
      align: "center",
      width: "5.5%",

      // hidden: c_lightData.soptype !== "WPC",
      // render: (_, rowData) => ${rowData.pfID}_${rowData.pfNM},
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "是否定頻保養",
      dataIndex: "ismrcycle",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
      width: "5.5%",

      // hidden: c_lightData.soptype !== "WPC",
      // render: (_, rowData) => ${rowData.wsID}_${rowData.wsNM},
    },
    {
      title: "設定保養次數",
      dataIndex: "setmrcycle",
      align: "right",
      width: "5.5%",

      // hidden: c_lightData.soptype !== "WPC",
      // render: (_, rowData) => ${rowData.pwcID}_${rowData.pwcNM},
    },
    {
      title: "是否定期保養",
      dataIndex: "ismrperiod",
      key: "ismrperiod",
      align: "center",
      width: "5.5%",

      render: (text) => (text ? <IconCheck /> : <IconClose />),

      // align: "center",
      // width: innerWidth <= 1440 ? ${80}px : ${100}px,
      // hidden: c_lightData.soptype !== "OPC",
      // onCell: (record) => {
      //   return {
      //     style: { backgroundColor: "rgb(231, 247, 231)" },
      //   };
      // },
    },
    {
      title: "設定週期保養",
      dataIndex: "setmrperiod",
      key: "setmrperiod",
      align: "right",
      width: "5.5%",

      // width: innerWidth <= 1440 ? ${80}px : ${100}px,
      // hidden: c_lightData.soptype !== "OPC",
      // onCell: (record) => {
      //   return {
      //     style: { backgroundColor: "rgb(231, 247, 231)" },
      //   };
      // },
    },
    {
      title: "上次保養日期",
      dataIndex: "premrT",
      key: "premrT",
      align: "center",
      width: "5.5%",
      render: (text) => {
        return text === "0001-01-01T00:00:00Z" ? "" : text;
      },
    },
    {
      title: "上次保養次數",
      dataIndex: "premrC",
      key: "premrC",
      align: "center",
      width: "5.5%",
      render: (text) => {
        return text === "0001-01-01T00:00:00Z" ? "" : text;
      },
    },
    {
      title: "預計下次保養日期",
      dataIndex: "nextmrT",
      key: "nextmrT",
      align: "center",
      width: "7.25%",
      render: (text) => {
        return text === "0001-01-01T00:00:00Z" ? "" : text;
      },
    },
    {
      title: "預計下次保養次數",
      dataIndex: "nextmrC",
      key: "nextmrC",
      align: "center",
      width: "7.25%",
      render: (text) => {
        return text === "0001-01-01T00:00:00Z" ? "" : text;
      },
    },
    {
      title: "上傳檔案", // 操作
      dataIndex: "action",
      align: "center",
      width: "4%",
      render: (_, rowData) => (
        <Space>
          <UploadOutlined
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_editData(rowData);
              set_s_showModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const handleSave = (data, dataIndex, value) => {
    console.log("要傳給後端的資料 = ", {
      ...data,
      [dataIndex]: value,
    });
    call_updateDevice.request({
      ...data,
      [dataIndex]: value,
    });
  };

  console.log(s_rec);
  return (
    <Wrapper>
      <PageHeader
        title={
          <span>
            測量設維護資訊
            <span className="text-blue-500 font-semibold">{c_lightData.sopID}</span>
          </span>
        }
      />
      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item,
          };
        })}
        rowClassName="editable-row"
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["200", "500", "1000"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <span className="text-lg font-semibold">
              {t("util.util.showTotal", {
                total: total,
                rangeFirst: range[0],
                rangeEnd: range[1],
              })}
            </span>
          ),
        }}
      />

      <CustomModal
        width="60%"
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>上傳檔案</span>
          </div>
        }
        visible={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          // set_c_lightData({});
          call_getDevice.request();
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={c_lightData}
          set_s_editData={set_s_editData}
        />
      </CustomModal>
    </Wrapper>
  );
};

export default TB;
