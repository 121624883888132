import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US from "./locales/en-US";
import zh_TW from "./locales/zh-TW";
import zh_CN from "./locales/zh-CN";
import vi_VN from "./locales/vi-VN";
import id_ID from "./locales/id-ID";
import th_TH from "./locales/th-TH";

export const locales = [
  { name: "bahasa Indonesia", key: "id-ID" },
  { name: "Tiếng Việt", key: "vi-VN" },
  { name: "English", key: "en-US" },
  { name: "簡體中文", key: "zh-CN" },
  { name: "繁體中文", key: "zh-TW" },
  { name: "แบบไทย", key: "th-TH" },
];

const resources = {
  "id-ID": {
    translation: id_ID,
  },
  "vi-VN": {
    translation: vi_VN,
  },
  "en-US": {
    translation: en_US,
  },
  "zh-TW": {
    translation: zh_TW,
  },
  "zh-CN": {
    translation: zh_CN,
  },
  "th-TH": {
    translation: th_TH,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "zh-TW",
    fallbackLng: "zh-TW",
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
