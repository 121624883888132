import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, InputNumber, Divider, message, Space, Typography } from "antd";
import { IconPlus, IconMinus } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import {
  getArrayStruct,
  getTreeStruct,
  getDeleteArrayStruct,
  addBom,
  modifyBom,
} from "@/service/apis/ADM/bom";
import useAPI from "@/hooks/useAPI";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

export default function ModalConintaer({ s_editData, set_s_isShowModal, s_arrMap }) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [s_note, set_s_note] = useState("");
  //bomTree 由二維arr組成 ex: [[1],[2]]
  const [s_bomTree, set_s_bomTree] = useState([]);
  const [s_tableData, set_s_tableData] = useState([]);
  // table的展開項
  const [s_expandedRowKeys, s_setExpandedRowKeys] = useState(["0-0"]);

  const call_getArrayStruct = useAPI(getArrayStruct);
  const call_getTreeStruct = useAPI(getTreeStruct);
  const call_getDeleteArrayStruct = useAPI(getDeleteArrayStruct);
  const call_addBom = useAPI(addBom);
  const call_modifyBom = useAPI(modifyBom);

  const tableColumns = [
    {
      title: t("util.util.pdtNM"),
      dataIndex: "cpdtNM",
      width: "25%",
      render: (text, rowData) => `【${text}】 * ${rowData.qty}`,
    },
    {
      title: t("ADM.edm.level"),
      dataIndex: "level",
      align: "center",
      width: "80px",
    },
    {
      title: t("util.util.PN"),
      dataIndex: "cPN",
      render: (text, rowData) =>
        // 第一階層不能選
        // 能不能選擇 決定於他的下一階有沒有以他當pPN的
        // 先在bomTree找到這個料號
        rowData.level === 0 ? (
          text
        ) : !Array.isArray(s_bomTree[rowData.level + 1]) ||
          s_bomTree[rowData.level + 1].map((x) => x.pPN).indexOf(rowData.cPN) === -1 ? (
          <DebounceSelect
            fetchOptions={fetchAllPNList}
            maxTagCount={10}
            showSearch
            className="w-full"
            defaultValue={rowData.cPN}
            onChange={(_, data) => {
              set_s_bomTree((prev) => {
                const checkPN = prev[rowData.level].filter((x) => x.cPN === data.data.PN);
                // 同一層內PN 不能重複
                if (checkPN.length > 0) {
                  message.error("料號重複");
                  return prev;
                }
                prev[rowData.level][rowData.itemno] = {
                  ...prev[rowData.level][rowData.itemno],
                  ...rowData,
                  cPN: data.data.PN,
                  cpdtNM: data.data.pdtNM,
                  attribute: data.data.attribute,
                  cunit: data.data.unit,
                };
                return [...prev];
              });
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: t("ADM.edm.pPN"),
      dataIndex: "pPN",
    },
    {
      title: t("ADM.edm.ppdtNM"),
      dataIndex: "ppdtNM",
    },
    {
      title: t("util.util.attribute"),
      dataIndex: "attribute",
      align: "center",
      width: "80px",
      render: (text) => s_arrMap.attributeArr?.filter((x) => x.value === text)[0]?.label,
    },
    {
      title: t("util.util.unit"),
      dataIndex: "cunit",
      align: "center",
      width: "6%",
    },
    {
      title: t("util.util.qty"),
      dataIndex: "qty",
      align: "center",
      width: "100px",
      render: (text, rowData) => (
        <InputNumber
          min={0}
          defaultValue={text}
          onChange={(e) =>
            set_s_bomTree((prev) => {
              prev[rowData.level][rowData.itemno].qty = e;
              return [...prev];
            })
          }
        />
      ),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => {
        return (
          <Space>
            {/* 成品或半成品 才可新增 */}
            {rowData.attribute === "4" ||
              (rowData.attribute === "5" && <IconPlus onClick={() => addBomTree(rowData)} />)}
            {rowData.level !== 0 && <IconMinus onClick={() => delBomTree(rowData)} />}
          </Space>
        );
      },
    },
  ];

  const fetchProductPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    // 成品跟半成品
    return await getProductSelect({ PN: value, attribute: "4|5" })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  const fetchAllPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  const onSubmit = () => {
    if (s_bomTree[0].length === 0) {
      message.error(`${t("util.util.placeholder_select")}${t("util.util.PN")}`);
      return;
    }
    // 組成後端Data
    let hd = {
        ...s_bomTree[0][0],
        PN: s_bomTree[0][0].cPN,
        note: s_note,
      },
      tb = [];
    // 確認全部資料階已填寫完畢
    for (const node of s_bomTree) {
      for (const [index, item] of node.entries()) {
        if (!item.cPN || !item.pPN || !item.qty) {
          message.error("請填寫完整資料");
          return;
        }
        // itemno 最後全部+1 , 後端資料由1開始 , 前端從0
        tb.push({
          ...item,
          itemno: index + 1,
        });
      }
    }

    const req = {
      hd: hd,
      tb: tb,
    };

    if (Object.keys(s_editData).length === 0) {
      call_addBom.request(req);
    } else {
      call_modifyBom.request(req);
    }
  };

  //加
  const addBomTree = (rowData) => {
    if (!rowData.cPN || rowData.qty === 0) {
      message.error("請填寫完整資料");
      return;
    }
    // 創建基本項目
    let nodeData = {
      cPN: "",
      level: rowData.level + 1,
      itemno: (s_bomTree[rowData.level + 1] || []).length,
      pPN: rowData.cPN,
      attribute: undefined,
      qty: 1,
      cpdtNM: undefined,
      ppdtNM: rowData.cpdtNM,
    };
    set_s_bomTree((prev) => {
      prev[rowData.level + 1] = [...(prev[rowData.level + 1] || []), nodeData];
      return [...prev];
    });
  };
  // 刪
  const delBomTree = (rowData) => {
    call_getDeleteArrayStruct.request({
      deleteItem: rowData,
      treeArr: s_bomTree,
    });
  };

  useEffect(() => {
    if (Object.keys(s_editData).length > 0) {
      call_getArrayStruct.request({ bomID: s_editData.bomID });
    }
  }, []);

  useEffect(() => {
    if (s_bomTree.length === 0) return;
    console.log("s_bomTree", s_bomTree);
    // 重新計算展開選項 attribute === "5"
    s_setExpandedRowKeys(
      s_bomTree
        .map((x) =>
          x.reduce((data, item) => {
            item.attribute === "5" && data.push(item.level + "-" + item.itemno);
            return data;
          }, [])
        )
        .flat()
    );
    call_getTreeStruct.request(s_bomTree);
  }, [s_bomTree]);

  useEffect(() => {
    if (call_getArrayStruct.status === "suc") {
      set_s_note(call_getArrayStruct.data[0][0].note);
      // 在前端 itemno 全部從0開始算
      set_s_bomTree(
        call_getArrayStruct.data.map((x) =>
          x.map((item, i) => ({ ...item, itemno: i, key: item.level + "-" + i }))
        )
      );
    } else if (call_getArrayStruct.status === "err") {
      message.error(t(`error.error-code.${call_getArrayStruct.msg}`));
    }
  }, [call_getArrayStruct.status]);

  useEffect(() => {
    if (call_getTreeStruct.status === "suc") {
      set_s_tableData([call_getTreeStruct.data]);
    } else if (call_getTreeStruct.status === "err") {
      message.error(t(`error.error-code.${call_getTreeStruct.msg}`));
      set_s_tableData([]);
    }
  }, [call_getTreeStruct.status]);

  useEffect(() => {
    if (call_getDeleteArrayStruct.status === "suc") {
      set_s_bomTree(
        call_getDeleteArrayStruct.data.map((x) =>
          x.map((item) => ({ ...item, key: item.level + "-" + item.itemno }))
        )
      );
    } else if (call_getDeleteArrayStruct.status === "err") {
      message.error(t(`error.error-code.${call_getDeleteArrayStruct.msg}`));
    }
  }, [call_getDeleteArrayStruct.status]);

  useEffect(() => {
    if (call_addBom.status === "suc") {
      message.success(call_addBom.msg);
      set_s_isShowModal(false);
    } else if (call_addBom.status === "err") {
      message.error(t(`error.error-code.${call_addBom.msg}`));
    }
  }, [call_addBom.status]);

  useEffect(() => {
    if (call_modifyBom.status === "suc") {
      message.success(call_modifyBom.msg);
      set_s_isShowModal(false);
    } else if (call_modifyBom.status === "err") {
      message.error(t(`error.error-code.${call_modifyBom.msg}`));
    }
  }, [call_modifyBom.status]);

  return (
    <Row gutter={[24, 12]}>
      <Col span={8}>
        <Title level={5}>{t("util.util.PN")}：</Title>
        {Object.keys(s_editData).length > 0 ? (
          <Input
            disabled={Object.keys(s_editData).length > 0}
            value={s_bomTree[0] && s_bomTree[0][0]?.cPN}
          />
        ) : (
          <DebounceSelect
            fetchOptions={fetchProductPNList}
            maxTagCount={10}
            showSearch
            className="w-full"
            onChange={(_, data) => {
              let newTree = [
                [
                  {
                    ...data.data,
                    note: "",
                    pPN: data.data.PN,
                    ppdtNM: data.data.pdtNM,
                    punit: data.data.punit,
                    cPN: data.data.PN,
                    cpdtNM: data.data.pdtNM,
                    cunit: data.data.unit,
                    level: 0,
                    itemno: 0,
                    qty: 1,
                    key: "0-0",
                  },
                ],
              ];
              console.log(data.data.unit, newTree);
              set_s_bomTree(newTree);
            }}
          />
        )}
      </Col>
      <Col span={8}>
        <Title level={5}>{t("util.util.pdtNM")}：</Title>
        <Input disabled value={s_bomTree[0] && s_bomTree[0][0]?.cpdtNM} />
      </Col>
      <Col span={8}>
        <Title level={5}>{t("util.util.note")}：</Title>
        <Input value={s_note} onChange={(e) => set_s_note(e.target.value)} />
      </Col>
      <Col span={24}>
        <Divider className="m-0 p-0" />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          dataSource={s_tableData}
          pagination={false}
          expandedRowKeys={s_expandedRowKeys}
          onExpand={(isOpen, record) => {
            s_setExpandedRowKeys(
              isOpen
                ? [...s_expandedRowKeys, record.key]
                : s_expandedRowKeys.filter((key) => key !== record.key)
            );
          }}
        />
      </Col>
      <Col span={24} className="flex justify-end">
        <Space>
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" onClick={onSubmit}>
            {t("util.util.ok")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
