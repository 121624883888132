import { Input } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";

import Progress from "./Progress";

export default function WonSearch() {
  const { t } = useTranslation();

  // 利用ref 子傳父
  const Progress_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    PN: undefined,
    woN: undefined,
    page: 1,
    pageSize: 200,
  });
  const [s_tabKey, set_s_tabKey] = useState("Progress"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      PN: undefined,
      pdtNM: undefined,
      attribute: undefined,
      pdtclass: undefined,
      querycat: undefined,
      mgmtcat: undefined,
      pdtspec: undefined,
      page: 1,
      pageSize: 200,
    });
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      s_tabKey === "Progress" && Progress_ref.current.onSearch();
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    s_tabKey === "Progress" && Progress_ref.current.onSearch();
  };

  const onCreate = () => {
    s_tabKey === "Progress" && Progress_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "Progress",
      label: "工單進度",
      children: (
        <Progress
          ref={Progress_ref}
          s_tabKey={s_tabKey}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={"工單進度查詢"}
        extra={[
          <Input
            key="s_searchData.PN"
            value={s_searchData.PN}
            onChange={(e) => handlChange("PN", e.target.value)}
            placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
            onKeyDown={handleKeyDownEnter}
            allowClear
          />,
          <Input
            key="s_searchData.woN"
            value={s_searchData.woN}
            onChange={(e) => handlChange("woN", e.target.value)}
            placeholder={`${t("util.util.placeholder_input")}${t("util.util.woN")}`}
            onKeyDown={handleKeyDownEnter}
            allowClear
          />,
          <SearchButton type="primary" key="search" onClick={onSearch} />,
        ]}
      />

      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
