import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const ButtonStyle = styled.button`
  height: ${({ height }) => `${height ? height : 40}px`};
  width: ${({ width }) => `${width ? `${width}px` : `100%`}`};
  position: relative;
  background-color: ${({ color }) => `${color ? color : "#7ab1f4"}`};
  background-image: none;
  border: ${({ disabled }) => `${disabled ? "none" : " none"}`};
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.85);
  cursor: ${({ disabled }) => `${disabled ? "not-allowed" : "pointer"}`};
  box-shadow: ${({ disabled }) =>
    `${disabled ? "none" : "rgba(0, 0, 0, 0.5) inset -2px -2px 10px"}`};
  transition: all 0.5s ease;

  &:hover {
    box-shadow: ${({ disabled }) =>
      `${disabled ? "none" : "rgba(0, 0, 0, 0.5) inset 2px 2px 10px"}`};
  }

  &::after {
    position: ${({ disabled }) => `${disabled ? "absolute" : "none"}`};
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
    width: 100%;
    height: 100%;
  }
`;

export default function MyButton(props) {
  return (
    <Spin spinning={props.loading || false}>
      <ButtonStyle {...props} onClick={props.disabled ? null : props.onClick}>
        {props.children}
      </ButtonStyle>
    </Spin>
  );
}
