/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, message, Space, Button } from "antd";
import { PlusOutlined, FullscreenExitOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// component
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { getApsOutMoTb, deleteApsOutMoTb } from "@/service/apis/APS/apsMange";
import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData }) => {
  const { t } = useTranslation();

  // useAPI 取得表身資料
  const call_getApsOutMoTb = useAPI(getApsOutMoTb);
  // useAPI 刪除表身
  const call_deleteApsOutMoTb = useAPI(deleteApsOutMoTb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_editData, set_s_editData] = useState({});

  const editData = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"), // 確定
      cancelText: t("util.util.cancel"), // 取消
      onOk: () => call_deleteApsOutMoTb.request(rowData),
    });
  };

  // 第一次渲染以及c_lightData發生改變時重新取得表身資料
  useEffect(() => {
    call_getApsOutMoTb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getApsOutMoTb;

    if (status === "suc") {
      message.success(msg);
      set_s_tableData(data.map((x, i) => ({ ...x, key: i })));
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getApsOutMoTb.status]);

  // useAPI 刪除表身資料
  useEffect(() => {
    const { status, msg } = call_deleteApsOutMoTb;
    if (status === "suc") {
      message.success(msg);
      call_getApsOutMoTb.request(c_lightData);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_deleteApsOutMoTb.status]);

  const tableColumns = [
    {
      title: t("util.util.SN"), // 序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("util.util.planqty"), // 預計數量
      dataIndex: "planqty",
      align: "center",
      render: (text) => formatAmount(text),
    },
    {
      title: t("APS.util.realqty"), // 預計數量
      dataIndex: "realqty",
      align: "center",
      render: (text) => formatAmount(text),
    },
    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData) => {
        return (
          <Space className="flex-center font-size-24">
            <IconPen onClick={() => editData(rowData)} />
            <IconGarbage onClick={() => deleteData(rowData)} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title={t(`APS.aps-manage.mo`) + t(`APS.util.detail`)}
        extra={[
          <Button
            key="hidden"
            danger
            icon={<FullscreenExitOutlined />}
            onClick={() => set_c_lightData({})}
          >
            {t("util.util.hide_detail")}
          </Button>,
          <Button
            key="add"
            onClick={() => set_s_showModal(true)}
            type="primary"
            className="styleButton"
            icon={<PlusOutlined />}
          >
            {t(`util.util.add`)}
            {/* 新增的按鈕 */}
          </Button>,
        ]}
      />

      <CustomTable columns={tableColumns} dataSource={s_tableData} tbMode pagination={false} />

      <CustomModal
        width="60%"
        title={
          (Object.keys(s_editData).length === 0 ? t("util.util.add") : t("util.util.edit")) +
          t("APS.aps-manage.mo") +
          t(`APS.util.detail`)
        }
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          call_getApsOutMoTb.request(c_lightData);
          set_s_editData({});
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
