interface ImageGroupType {
  x: number;
  y: number;
  base64: string;
  invert: boolean;
  length: number;
  width: number;
}

interface CombineBase64ImagesType {
  col?: number;
  imgWidth: number;
  imgHeight: number;
  imgGap: number;
  normalImgGroup: ImageGroupType[];
  keyImgGroup: ImageGroupType[][][];
  type?: string;
}
/**
 * 組合多個圖片的 Base64 編碼成後端需要格式
 * @param {Object} options - 選項設定物件
 * @param {number} options.col - 列數，預設為 1
 * @param {number} options.imgWidth - 圖片寬度
 * @param {number} options.imgHeight - 圖片高度
 * @param {number} options.imgGap - 圖片間隔
 * @param {Array} options.normalImgGroup - 普通圖片群組
 * @param {Array} options.keyImgGroup - 關鍵圖片群組
 * @returns {Object} - 包含背景圖片和物件圖片的物件
 */
export default function combineBase64Images({
  col = 1, // 列數，預設為 1
  imgWidth, // 圖片寬度
  imgHeight, // 圖片高度
  imgGap, // 圖片間隔
  normalImgGroup, // 普通圖片群組
  keyImgGroup, // 關鍵圖片群組
  type = " ",
}: CombineBase64ImagesType) {
  // 建立畫布
  const canvas = document.createElement("canvas");
  const canvasWidth = imgWidth * col + imgGap * (col - 1); // 畫布寬度
  const canvasHeight = imgHeight; // 畫布高度
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const ctx = canvas.getContext("2d")!;
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // 取得整張畫布的 base64 編碼
  const allCanvasBase64 = canvas.toDataURL("image/png", 1).split(",")[1];

  // 1*1的黑點
  const blackBase64 =
    "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY2BgYAAAAAQAAVzN/2kAAAAASUVORK5CYII=";

  let normalGroup: ImageGroupType[] = [];
  let keyGroup: ImageGroupType[][] = [];

  // 組裝固定出現的模板
  if (normalImgGroup) {
    for (let i = 0; i < col; i++) {
      if (normalImgGroup[i]) {
        const offsetX = (imgWidth + imgGap) * i; // X 偏移量
        const offsetY = 0; // Y 偏移量

        normalGroup.push(
          ...normalImgGroup.map((item) => ({
            ...item,
            x: item.x + offsetX,
            y: item.y + offsetY,
          }))
        );

        if (type === " ") {
          if (offsetX > 0) {
            let dotObj: ImageGroupType = {
              base64: blackBase64,
              invert: false,
              length: imgHeight,
              width: imgGap,
              x: imgWidth * i,
              y: 0,
            };
            normalGroup.push(dotObj);
            // 在測試模式下添加中間黑線條
          }
        }
      }
    }
    normalGroup.unshift({
      x: 0,
      y: 0,
      length: canvasHeight,
      width: canvasWidth,
      base64: allCanvasBase64,
      invert: false,
    });
  }

  // 組裝關鍵出現的模板
  keyGroup = keyImgGroup.map((group) =>
    group.flatMap((subGroup, i) =>
      subGroup.map((item) => ({
        ...item,
        x: item.x + (imgWidth + imgGap) * i,
        y: item.y,
      }))
    )
  );

  return {
    bg_img: normalGroup, // 背景圖片
    obj_img: keyGroup, // 物件圖片
  };
}
