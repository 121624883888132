import { Button, Col, Collapse, Form, Input, Radio, Row, Select, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { addStaffPerm, getRole, getStaff, modifyStaffPerm } from "@/service/apis/ADM/userManage";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_role = Form.useWatch("role", form);

  const call_addStaffPerm = useAPI(addStaffPerm);
  const call_modifyStaffPerm = useAPI(modifyStaffPerm);
  const call_getRole = useAPI(getRole);
  const call_getStaff = useAPI(getStaff);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const plantID = getCookie("plantID");
  const options = [
    { value: true, label: t("util.util.yes") },
    { value: false, label: t("util.util.no") },
  ];

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    values.account = values.staffID;
    if (values.role && values.role.length > 0) {
      values.role = values.role.join("|");
    }

    if (type === "create") {
      call_addStaffPerm.request(values);
    }

    if (type === "edit") {
      call_modifyStaffPerm.request(values);
    }
  };

  // 找出多個 auth 中 為true的key
  function filterObjects(arr) {
    const result = {};
    for (const item of arr) {
      for (const prop in item) {
        if (item[prop]) {
          result[prop] = true;
        } else {
          result[prop] = undefined;
        }
      }
    }
    return result;
  }

  useEffect(() => {
    call_getRole.request();
    call_getStaff.request();
  }, []);

  useEffect(() => {
    const { status, msg } = call_addStaffPerm;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_addStaffPerm.status]);

  useEffect(() => {
    const { status, msg } = call_modifyStaffPerm;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_modifyStaffPerm.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ ...s_editData }}>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          {/* 員工姓名 */}
          <Form.Item
            label={t("ADM.user-manage.peopleNM")}
            name="peopleNM"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              disabled={Object.keys(s_editData).length > 0}
              onChange={(_, data) => form.setFieldsValue({ ...data.data })}
              options={call_getStaff.data.map((x) => ({
                label: x.peopleNM,
                value: x.peopleNM,
                data: x,
              }))}
              filterOption={(input, option) => {
                return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 員工帳號 */}
          <Form.Item label={t("ADM.user-manage.account")} name="staffID">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 密碼 */}
          <Form.Item label={t("ADM.user-manage.pwd")} name="pwd">
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 角色權限 */}
          <Form.Item label={t("ADM.user-manage.role")} name="role" rules={[{ required: true }]}>
            <Select
              mode="tags"
              options={call_getRole.data.map((x) => ({
                label: x.itemNM,
                value: x.itemID,
                data: x,
              }))}
              onChange={(_, allData) => {
                const authArr = allData.map((x) => JSON.parse(x.data.auth));
                const result = filterObjects(authArr);
                form.setFieldsValue({ ...result });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="MOM" key="1">
              <Collapse defaultActiveKey={["PDS"]}>
                <Panel forceRender header="PDS" key="PDS">
                  <Row>
                    <Col span={6}>
                      {/* 數位生產看板 */}
                      <Form.Item label={t("ADM.user-manage.dashboard")} name="PDS-dashboard">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    {/* <Col span={6}>
                      <Form.Item label="數位生產圖框" name="PDS-dashboard-card">
                        <Radio.Group options={options} disabled />
                      // </Form.Item>
                    </Col> */}

                    <Col span={6}>
                      {/* 異常管理看板 */}
                      <Form.Item label={t("ADM.user-manage.abc-board")} name="PDS-abcBoard">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      {/* 線體稼動總表 */}
                      <Form.Item
                        label={t("util.util.nav_pds_overview-lines")}
                        name="PDS-overview-lines"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      {/* 綜合效能總表 */}
                      <Form.Item label="綜合效能總表" name="PDS-efficiency">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      {/* 線體生產狀況 */}
                      <Form.Item label="線體生產狀況" name="PDS-ticker-dashboard">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel
                  forceRender
                  header={`1. ${t("util.util.nav_BDM")}`} // 物料資料管理
                  key="BDM"
                >
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.util.nav_BDM")} name="BDM">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("ADM.pn-manage.pageHeader")} // 基本資料維護
                        name="BDM-pn-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_bdm_edm")} // 技術資料維護
                        name="BDM-edm"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="客製表格維護" name="BDM-sheet">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel
                  forceRender
                  header={`2. ${t("util.util.nav_TDM")}`} // 廠區資料管理
                  key="TDM"
                >
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.util.nav_TDM")} name="TDM">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_tdm_plant-manage")} // 廠別主檔設定
                        name="TDM-plant-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_tdm_procedure-manage")} // 製程規劃維護
                        name="TDM-procedure-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_tdm_production-line-manage")} // 產線規劃維護
                        name="TDM-production-line-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_tdm_warehouse-manage")} // 庫房規劃維護
                        name="TDM-warehouse-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col> */}

                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_tdm_string-manage")} // 工程字串管理
                        name="TDM-string-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel
                  forceRender
                  header={`4. ${t("util.util.nav_SDM")}`} // 系統資料管理
                  key="SDM"
                >
                  <Row>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_SDM")} // 系統資料管理
                        name="SDM"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_user-manage")} // 員工資料維護
                        name="SDM-user-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="員工資料維護-員工資料" // 員工資料維護-員工資料管理
                        name="SDM-user-manage-staff"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="員工資料維護-員工權限" // 員工資料維護
                        name="SDM-user-manage-auth"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    {/* <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_company")} // 廠商資料維護
                        name="SDM-company"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_client")} // 客戶資料維護
                        name="SDM-client"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_category-manage")} // 查詢分類設定
                        name="SDM-category-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_data-auth-manage")} // 功能授權設定
                        name="SDM-data-auth-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_trading-pattern")} // 項目字串設定
                        name="SDM-trading-pattern"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={t("util.util.nav_sdm_function-params-manage")} // 系統參數設定
                        name="SDM-function-params-manage"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Panel>

                <Panel forceRender header={`4. 條碼設備管理`} key="LMS">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="LMS" name="LMS">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="出貨標籤列印" name="LMS-label-orderN-printer">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="入庫標籤列印" name="LMS-label-instock-printer">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="條碼模板管理" name="LMS-label-template">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="條碼紙版管理" name="LMS-label-paper">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="列印設備管理" name="LMS-label-machines">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="輸出標籤查詢" name="LMS-label-search">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="條碼日期管理" name="LMS-label-date">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel forceRender header={`5. 生產資訊查詢`} key="PIS">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="生產資訊查詢" name="PIS">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="每日報表查詢" name="PIS-daily-report">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="每日報表查詢-每日員工產出"
                        name="PIS-daily-report-DailyReport_Employee"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="每日報表查詢-航太移轉報表"
                        name="PIS-daily-report-AirTransfer"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="每日報表查詢-大造熊移轉報表"
                        name="PIS-daily-report-BearTransfer"
                      >
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="製程進度查詢" name="PIS-wpc-search">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="暫置量報表" name="PIS-dal-report">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="產品條碼查詢" name="PIS-pdtcode">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="產品數據查詢" name="PIS-pdtdata">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel forceRender header={`6. 中介資料查詢`} key="SAP">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="中介資料查詢" name="SAP">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="SAP工單表頭" name="SAP-head">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="SAP工單途程" name="SAP-wpc">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="SAP訂單資料" name="SAP-order">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="QC資料查詢" name="SAP-qcdata">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel forceRender header={`7. 工時績效管理`} key="WHM">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="工時績效管理" name="WHM">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護" name="WHM-ct">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-科技廠" name="WHM-ct-Technology">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-輪組前後輪" name="WHM-ct-Wheel_BeforeAndAfter">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-輪組貼標料號" name="WHM-ct-Wheel_PN">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-輪組每日其他項目" name="WHM-ct-Wheel_Miscellaneous">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-車圈料號機台維護" name="WHM-ct-Car_PN">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-車圈機台維護" name="WHM-ct-Car_wl">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-車圈標籤維護" name="WHM-ct-Car_label">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="CT維護-車圈每日其他項目" name="WHM-ct-Car_Miscellaneous">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="出勤工時維護" name="WHM-attendance-hours">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="員工績效報表" name="WHM-performance">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="員工績效報表-車圈" name="WHM-performance-car">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="員工績效報表-輪組" name="WHM-performance-wheel">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                {plantID === "dev" ? (
                  <Panel forceRender header={`8. 品質管理系統`} key="QMS">
                    <Row>
                      <Col span={6}>
                        <Form.Item label="品質管理系統" name="QMS">
                          <Radio.Group options={options} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="品質主檔維護" name="QMS-qmfm">
                          <Radio.Group options={options} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="檢驗標準管理" name="QMS-qms-manage">
                          <Radio.Group options={options} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="檢驗規格模板" name="QMS-spec">
                          <Radio.Group options={options} disabled />
                        </Form.Item>
                      </Col>
                      {/* <Col span={6}>
                      <Form.Item label="儀器設備管理" name="QMS-cmje">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="儀器校正管理" name="QMS-cmjeq">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col> */}
                      <Col span={6}>
                        <Form.Item label="統計製程管理" name="QMS-spc">
                          <Radio.Group options={options} disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                ) : null}

                <Panel forceRender header={`9. 管理推播系統`} key="MNS">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="管理推播系統" name="MNS">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="推播設定管理" name="MNS-notification-setting">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Panel>
            <Panel forceRender header="MES" key="2">
              <Row>
                {/* 生產控制 */}
                <Col span={6}>
                  <Form.Item label={t("ADM.user-manage.WOC")} name="WOC">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生管排程 */}
                <Col span={6}>
                  <Form.Item label="生管排程" name="production-scheduling">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生管派單 */}
                <Col span={6}>
                  <Form.Item label="生管派單" name="wpc-printer">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="工單復原" name="won-resume">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生管補單 */}
                <Col span={6}>
                  <Form.Item label="生管補單" name="fill-order">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="回寫紀錄查詢" name="erp-history">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="操作紀錄查詢" name="record-search">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="批次報工" name="wl-woc-aps">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="生產報工_航太" name="wl-woc-1011">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="生產報工_大造熊" name="wl-woc-1021">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生產報工 */}
                <Col span={6}>
                  <Form.Item label="生產報工_車圈" name="ez-woc-1001">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生產報工 */}
                <Col span={6}>
                  <Form.Item label="生產報工_輪組" name="ez-woc-1002">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 生產報工 */}
                <Col span={6}>
                  <Form.Item label="生產報工_碳圈" name="ez-woc-1003">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 質控作業 */}
                <Col span={6}>
                  <Form.Item label={t("ADM.user-manage.QC")} name="qc">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 三合一掃碼 */}
                <Col span={6}>
                  <Form.Item label="三合一掃碼" name="three-in-one-scan">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 三次元送檢標籤 */}
                <Col span={6}>
                  <Form.Item label="三次元送檢標籤" name="label-3d">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 三次元標籤回饋 */}
                <Col span={6}>
                  <Form.Item label="三次元標籤回饋" name="label-record-3d">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 三次元品檢看板 */}
                <Col span={6}>
                  <Form.Item label="三次元品檢看板" name="dashboard-3d">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="三次元送檢標籤-再次檢驗" name="label-3d-double-check">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="三次元品檢刪除" name="label-3d-delete">
                    <Radio.Group options={options} />
                  </Form.Item>
                </Col>
                {/* 熱處理看板 */}
                <Col span={6}>
                  <Form.Item label="熱處理看板" name="dashboard-t4t6">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 品管標籤列印 */}
                <Col span={6}>
                  <Form.Item label="品管標籤列印" name="custom-label-printer">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                {/* 航太廠生產看板 */}
                <Col span={6}>
                  <Form.Item label="航太廠生產看板" name="air-card-dashboard">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="航太廠排程看板" name="air-production-scheduling">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="航太廠看板資料維護" name="air-dashboard-maintain">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="IOT自動列印" name="aiot">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="品質控制" name="new-qualityControl">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="異常提報" name="alert">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="異常解除" name="relieve">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="生產準備" name="open-prepare">
                    <Radio.Group options={options} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
