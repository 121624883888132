import { Modal, Space, message } from "antd";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen, IconCheck, IconClose } from "@/components/Icon/Action";

import Light from "@/components/Light";
import useAPI from "@/hooks/useAPI";
import {
  deleteMnsEvent,
  getMnsAppGp,
  getMnsEvent,
  getMnsFunGp,
} from "@/service/apis/MNS/notificationSetting";
import { multipleIDtoName } from "@/util/format";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer/index";
import TB from "./TB";

const Notify = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const { c_systemConfig } = useContext(SystemContext);

  const { t } = useTranslation();

  const [s_arrMap, set_s_arrMap] = useState({
    rptcodeMap: [
      { value: "A0", label: "人員提報" },
      { value: "B0", label: "系統提報" },
      { value: "C0", label: "設備提報" },
      { value: "D0", label: "管理提報" },
      // { value: "A0", label: t("ADM.function-params-manage.rptcode_A0") },
      // { value: "B0", label: t("ADM.function-params-manage.rptcode_B0") },
      // { value: "C0", label: t("ADM.function-params-manage.rptcode_C0") },
      // { value: "D0", label: t("ADM.function-params-manage.rptcode_D0") },
      // { value: "E0", label: t("ADM.function-params-manage.rptcode_E0") },
    ],
    pusherMap: [
      { value: "IOT", label: "IOT" },
      { value: "WOS", label: "WOS" },
      { value: "SFC", label: "SFC" },
      { value: "PMS", label: "PMS" },
      { value: "OMS", label: "OMS" },
      { value: "IMS", label: "IMS", disabled: true },
      { value: "WMS", label: "WMS", disabled: true },
    ],
    situFMap: [
      { label: "黃", value: "Y" },
      { label: "紅", value: "R" },
      { label: "NA", value: "NA" },
    ],
    startlvlMap: [
      { label: "全員", value: 0 },
      { label: "1_班組幹部", value: 1 },
      { label: "2_課級幹部", value: 2 },
      { label: "3_理級主管", value: 3 },
      { label: "4_總級主管", value: 4 },
    ],
    ctrlspot: [
      { label: "製程", value: "pf" },
      { label: "工站", value: "ws" },
      { label: "工線", value: "wl" },
      { label: "工作中心", value: "pwc" },
    ],
    funcodeMap: [],
    appGp: [],
  });

  const call_getNotify = useAPI(getMnsEvent);
  const call_deleteMnsEvent = useAPI(deleteMnsEvent);
  const call_getMnsFunGp = useAPI(getMnsFunGp);
  const call_getMnsAppGp = useAPI(getMnsAppGp);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getNotify.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.eventID"), //"事件ID",
      dataIndex: "eventID",
      width: 100,
      sorter: (a, b) => a.eventID.localeCompare(b.eventID),
    },
    {
      title: t("ADM.function-params-manage.eventNM"), //"事件名稱",
      dataIndex: "eventNM",
      width: "10%",
    },
    {
      title: t("ADM.function-params-manage.rptcode"), //"提報類別",
      dataIndex: "rptcode",
      align: "center",
      width: "9%",
      // render: (text) => s_arrMap.rptcodeMap.filter((x) => x.value === text)[0].label,
      render: (text) => t(`ADM.function-params-manage.rptcode_${text}`),
    },
    {
      title: t("ADM.function-params-manage.pusher"), //"發報系統",
      dataIndex: "pusher",
      align: "center",
      width: "9%",
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      align: "center",
      width: "9%",
      render: (text) => multipleIDtoName(s_arrMap.ctrlspot, text, "value", "label"),
    },
    {
      title: t("ADM.function-params-manage.situF"), //"警訊燈號",
      dataIndex: "situF",
      align: "center",
      width: "9%",
      render: (value) => (
        <Light type={value === "R" ? "error" : value === "Y" ? "warning" : "white"} />
      ),
      // render: (text) => t(`ADM.function-params-manage.situF_${text}`),
    },
    {
      title: t("ADM.function-params-manage.audience"), //"推播受群",
      dataIndex: "funcode",
      width: "13%",
      render: (text) => multipleIDtoName(s_arrMap.funcodeMap, text, "value", "label"),
    },
    {
      title: t("ADM.function-params-manage.startlvl"), //"起報層級",
      dataIndex: "startlvl",
      align: "center",
      width: "13%",
      // render: (text) => s_arrMap.startlvlMap.filter((x) => x.value === text)[0].label,
      render: (text) => t(`ADM.function-params-manage.startlvl_${text}`),
    },
    {
      title: t("ADM.function-params-manage.setlvl"), //"推播級數",
      dataIndex: "setlvl",
      align: "center",
      width: "7%",
    },
    // {
    //   title: t("ADM.function-params-manage.cruisebot"), //"監控程式",
    //   dataIndex: "cruisebot",
    //   width: "190px",
    // },
    // {
    //   title: "微信機器人",
    //   dataIndex: "wechat",
    //   width: "190px",
    // },
    {
      title: t("ADM.function-params-manage.msgmodel"), //"推播訊息模版",
      dataIndex: "msgmodel",
      width: "13%",
      render: (text) => (
        <div
          dangerouslySetInnerHTML={{
            __html: text.replace(/(\n|\r|\r\n)/g, "<br>"),
          }}
        />
      ),
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "4.8%",

      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "120px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => {
        call_deleteMnsEvent.request(rowData);
      },
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getMnsFunGp.request();
    call_getMnsAppGp.request();
  }, []);

  useEffect(() => {
    if (Object.keys(s_editData).length > 0 && !s_editData?.ctrlspot) {
      message.error("暫無控點");
    }
  }, [s_editData]);

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getNotify.status === "suc") {
      message.success(call_getNotify.msg);
      set_s_tableData(
        call_getNotify.data.map((x, i) => {
          // 整理資料
          const funcode = x.funcode.split("|").filter((x) => x !== "");
          return {
            ...x,
            key: i,
            funcode: funcode,
          };
        })
      );
    } else if (call_getNotify.status === "err") {
      message.error(call_getNotify.msg);
      set_s_tableData([]);
    }
  }, [call_getNotify.status]);

  useEffect(() => {
    if (call_deleteMnsEvent.status === "suc") {
      message.success(call_deleteMnsEvent.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteMnsEvent.status === "err") {
      message.error(call_deleteMnsEvent.msg);
    }
  }, [call_deleteMnsEvent.status]);

  useEffect(() => {
    if (call_getMnsFunGp.status === "suc") {
      set_s_arrMap((prev) => {
        prev.funcodeMap = call_getMnsFunGp.data.map((x) => ({
          label: x.funNM,
          value: x.funcode,
        }));
        return { ...prev };
      });
    } else if (call_getMnsFunGp.status === "err") {
      message.error(call_getMnsFunGp.msg);
    }
  }, [call_getMnsFunGp.status]);

  useEffect(() => {
    if (call_getMnsAppGp.status === "suc") {
      set_s_arrMap((prev) => {
        prev.appGp = call_getMnsAppGp.data.map((x) => ({
          value: x.buttongp,
        }));
        return { ...prev };
      });
    } else if (call_getMnsAppGp.status === "err") {
      message.error(call_getMnsAppGp.msg);
    }
  }, [call_getMnsAppGp.status]);

  useEffect(() => {
    console.log("c_systemConfig.locale === ", c_systemConfig.locale);
    call_getMnsFunGp.request();
  }, [c_systemConfig.locale]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getNotify.status === "load"}
        c_lightData={s_editData}
        tbMode={Object.keys(s_editData).length > 0}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <TB c_lightData={s_editData} />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.util.edit") + t("ADM.function-params-manage.pushEvent")
            : t("util.util.add") + t("ADM.function-params-manage.pushEvent")
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_arrMap={s_arrMap}
        />
      </CustomModal>
    </>
  );
});

export default Notify;
