/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getApsOutMoHd, deleteApsOutMoHd } from "@/service/apis/APS/apsMange";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";

const HD = forwardRef((props, ref) => {
  const { c_lightData, set_c_lightData, c_searchData } = props;
  const { t } = useTranslation();

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
  const [s_tableData, set_s_tableData] = useState([]);

  // 後端的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // 送給後端的資料
  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 200,
  });

  // useAPI 取得料單表頭
  const call_getApsOutMoHd = useAPI(getApsOutMoHd);
  // useAPI 取得料單表身
  const call_deleteApsOutMoHd = useAPI(deleteApsOutMoHd);

  const OPCODE_LABELS = [
    { value: "4110", label: `4110 ${t("IMS.util.opcode_4110")}` },
    { value: "4160", label: `4160 ${t("IMS.util.opcode_4160")}` },
    { value: "4210", label: `4210 ${t("IMS.util.opcode_4210")}` },
    { value: "4260", label: `4260 ${t("IMS.util.opcode_4260")}` },
  ];

  const tableColumns = [
    {
      title: t("IMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "7%",
      render: (text) => t(`IMS.util.opcode_${text}`),
    },
    {
      title: t("APS.aps-manage.jobN"), // 工單號
      dataIndex: "jobN",
      width: "15%",
      align: "left",
    },
    {
      title: t("util.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: "7%",
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
      // width: "10%",
      render: (text) => t(`IMS.util.lifeF_${text}`),
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
    },
    {
      title: t("util.util.action"),
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen onClick={(e) => editMo(e, rowData)} />
          <IconGarbage onClick={(e) => deleteMo(e, rowData)} />
        </Space>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_showModal(true), //開啟新增Modal
    Call_hd: () => call_getApsOutMoHd.request({ ...c_searchData, ...s_searchValue }),
  }));

  const editMo = (e, rowData) => {
    set_s_showModal(true);
    set_c_lightData(rowData);
    e.stopPropagation(); // 這裡不要開啟表身
  };

  const deleteMo = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteApsOutMoHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  // c_searchData 變更時改變 page
  useEffect(() => {
    set_c_lightData({});
    set_s_searchValue((prev) => ({ ...prev, page: 1 }));
  }, [c_searchData]);

  useEffect(() => {
    call_getApsOutMoHd.request({ ...c_searchData, ...s_searchValue });
  }, [s_searchValue]);

  useEffect(() => {
    const { data, msg, status } = call_getApsOutMoHd;
    if (status === "suc") {
      const { tableData, pageInfo } = data;
      console.log("tableData = ", tableData);
      const newTableData = tableData.map((x, i) => ({ ...x, key: x.opUUID, index: i }));

      message.success(msg);
      set_s_tableData(newTableData);
      set_s_pageInfo(pageInfo);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getApsOutMoHd.status]);

  useEffect(() => {
    const { msg, status } = call_deleteApsOutMoHd;
    if (status === "suc") {
      message.success(msg);
      call_getApsOutMoHd.request({ ...c_searchData, ...s_searchValue });
      set_c_lightData({});
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteApsOutMoHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getApsOutMoHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
        pagination={{
          total: s_pageInfo.totalItems,
          current: s_searchValue.page,
          pageSize: s_searchValue.pageSize,
          onChange: (current, size) =>
            set_s_searchValue((prev) => ({
              ...prev,
              page: prev.pageSize !== size ? 1 : current,
              pageSize: size,
            })),
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t("APS.aps-manage.mo")}`
            : `${t("util.util.add")}${t("APS.aps-manage.mo")}`
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getApsOutMoHd.request({ ...c_searchData, ...s_searchValue });
        }}
      >
        <ModalContainer
          OPCODE_LABELS={OPCODE_LABELS}
          set_s_showModal={set_s_showModal}
          s_editData={c_lightData}
        />
      </CustomModal>
    </>
  );
});

export default HD;
