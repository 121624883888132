import * as React from "react";
import { Select, Form, InputNumber, Input, Col } from "antd";
import { FormListFieldData } from "antd";
import { keyTextOptions } from "../../../../util/anyMap";

const fontFamilyOptions = [
  {
    label: "細明體",
    value: "MingLiU",
  },
  {
    label: "新細明體",
    value: "PMingLiU",
  },
  {
    label: "微軟雅黑",
    value: "Microsoft YaHei",
  },
  {
    label: "標楷體",
    value: "DFKai-SB",
  },
  {
    label: "思源黑體",
    value: "Noto Sans TC",
  },
  {
    label: "微軟正黑體",
    value: "Microsoft JhengHei",
  },
  {
    label: "楷體",
    value: "KaiTi",
  },
  {
    label: "黑體",
    value: "SimHei",
  },
  {
    label: "宋體",
    value: "SimSun",
  },
  {
    label: "新宋体",
    value: "NSimSun",
  },
  {
    label: "Calibri",
    value: "Calibri",
  },
  {
    label: "Times New Roman",
    value: "Times New Roman",
  },
  {
    label: "Arial",
    value: "Arial",
  },
  {
    label: "Verdana",
    value: "Verdana",
  },
  {
    label: "Helvetica",
    value: "Helvetica",
  },
  {
    label: "Courier New",
    value: "Courier New",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Tahoma",
    value: "Tahoma",
  },
  {
    label: "Impact",
    value: "Impact",
  },
  {
    label: "Trebuchet MS",
    value: "Trebuchet MS",
  },
  {
    label: "Arial Narrow",
    value: "Arial Narrow",
  },
  {
    label: "Century Gothic",
    value: "Century Gothic",
  },
  {
    label: "Garamond",
    value: "Garamond",
  },
  {
    label: "Book Antiqua",
    value: "Book Antiqua",
  },
  {
    label: "Palatino Linotype",
    value: "Palatino Linotype",
  },
  {
    label: "Lucida Sans Unicode",
    value: "Lucida Sans Unicode",
  },
  {
    label: "Arial Black",
    value: "Arial Black",
  },
  {
    label: "Candara",
    value: "Candara",
  },
  {
    label: "Franklin Gothic Medium",
    value: "Franklin Gothic Medium",
  },
  {
    label: "Segoe UI",
    value: "Segoe UI",
  },
];

const textBoldOptions = [
  { label: "一般", value: " " },
  { label: "粗體", value: "bold" },
  { label: "斜體", value: "italic" },
  {
    label: "粗斜體",
    value: "bold italic",
  },
];

const textColor = [
  { label: "黑", value: "#000000" },
  { label: "白", value: "#ffffff" },
];

const textAlignOptions = [
  { label: "靠左", value: "left" },
  { label: "置中", value: "center" },
  { label: "靠右", value: "right" },
];

const keyTextLabel = (field: FormListFieldData) => (
  <>
    <Col span={12}>
      <Form.Item label="關鍵文字" name={[field.name, "keyText"]} rules={[{ required: true }]}>
        <Select
          showSearch
          options={keyTextOptions.map(({ key, text }) => ({
            value: key,
            label: key + "-" + text,
          }))}
          filterOption={(input, option) => {
            return (option?.label ?? "").includes(input);
          }}
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item label="替代字串" name={[field.name, "fakeText"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item label="引用訂單號" name={[field.name, "orderIndex"]} rules={[{ required: true }]}>
        <InputNumber min={1} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const textLabel = (field: FormListFieldData) => (
  <>
    <Col span={12}>
      <Form.Item label="固定字串" name={[field.name, "text"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
  </>
);

const quoteTextLabel = (field: FormListFieldData) => (
  <>
    <Col span={12}>
      <Form.Item
        label="ex:固定字串${1}${2}"
        name={[field.name, "quoteText"]}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item label="替代字串" name={[field.name, "fakeText"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
  </>
);

const textUtilLabel = (field: FormListFieldData) => (
  <>
    <Col span={12}>
      <Form.Item label="字型" name={[field.name, "fontFamily"]} rules={[{ required: true }]}>
        <Select showSearch options={fontFamilyOptions} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="文字大小" name={[field.name, "fontSize"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="文字寬度(%)" name={[field.name, "fontWidth"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="換行寬度" name={[field.name, "boxWidth"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="對齊方向" name={[field.name, "boxAlign"]} rules={[{ required: true }]}>
        <Select options={textAlignOptions} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="粗細" name={[field.name, "bold"]} rules={[{ required: true }]}>
        <Select options={textBoldOptions} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="顏色" name={[field.name, "c"]} rules={[{ required: true }]}>
        <Select options={textColor} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const LabelText = {
  keyTextLabel,
  textLabel,
  quoteTextLabel,
  textUtilLabel,
};

export default LabelText;
