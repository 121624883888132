import { useState, useEffect } from "react";
import { Modal, message, Space, Button, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getCustHd, deleteCustHd } from "@/service/apis/ADM/client";
import { getMgmtcat } from "@/service/apis/ADM/manage";

import useAPI from "@/hooks/useAPI";

import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";
import PageHeader from "@/components/PageHeader/index";

// util
import { multipleIDtoName } from "@/util/format";

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;
  const [s_searchData, set_s_searchData] = useState({
    name: undefined,
  });
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_mgmtcat71Options, set_s_mgmtcat71Options] = useState([]);
  const [s_mgmtcat73Options, set_s_mgmtcat73Options] = useState([]);

  const call_getCustHd = useAPI(getCustHd);
  const call_deleteCustHd = useAPI(deleteCustHd);
  const call_getMgmtcat73 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);

  const tableColumns = [
    {
      title: t("util.util.custID"), // 客戶ID
      dataIndex: "custID",
      align: "center",
      width: "8%",
      sorter: (a, b) =>
        // 根據字串中的數字進行排序
        parseFloat(a.custID.match(/\d+/g)[0]) - parseFloat(b.custID.match(/\d+/g)[0]),
    },
    {
      title: t("util.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      width: "8%",
    },
    {
      title: t("util.util.custNM"), // 客戶名稱
      dataIndex: "custNM",
      // width: "400px",
    },
    {
      title: t("ADM.client.location"), // 市場區域
      dataIndex: "location",
      align: "center",
      width: "8%",
    },

    {
      title: t("util.util.mgmtcat"), // 授權分類
      dataIndex: "mgmtcat",
      width: "8%",
      render: (text) => {
        return multipleIDtoName(s_mgmtcat71Options, text, "value", "label");
      },
    },
    {
      title: t("util.util.querycat"), // 查詢分類
      dataIndex: "querycat",
      width: "8%",
      render: (text) => s_mgmtcat73Options.filter((x) => x.value === text)[0]?.label,
    },
    {
      title: t("ADM.client.taxID"), // 統一編號
      dataIndex: "taxID",
      width: "8%",
    },
    {
      title: t("util.util.currencytype"), // 交易幣別
      dataIndex: "maincurrency",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.client.payterm"), // 付款條件
      dataIndex: "payterm",
      width: "8%",
      align: "center",
    },
    {
      title: t("ADM.client.maxcredit"), // 信用條件
      dataIndex: "maxcredit",
      align: "center",
      width: "8%",
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    set_c_lightData({});
    call_getCustHd.request(s_searchData);
  };

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteCustHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getCustHd.request();
    call_getMgmtcat73.request({ pageType: "73" });
    call_getMgmtcat71.request({ pageType: "71", type: "cust" });
  }, []);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getCustHd.status === "suc") {
      message.success(call_getCustHd.msg);
      set_s_tableData(
        call_getCustHd.data.map((x, i) => ({
          ...x,
          key: x.custID,
          index: i,
          mgmtcat: x.mgmtcat.split("|").filter((x) => x !== ""),
        }))
      );
    } else if (call_getCustHd.status === "err") {
      message.error(t(`error.error-code.${call_getCustHd.msg}`));
      set_s_tableData([]);
    }
  }, [call_getCustHd.status]);

  useEffect(() => {
    if (call_deleteCustHd.status === "suc") {
      message.success(call_deleteCustHd.msg);
      // delete之後 重call表格資料
      call_getCustHd.request();
    } else if (call_deleteCustHd.status === "err") {
      message.error(t(`error.error-code.${call_deleteCustHd.msg}`));
    }
  }, [call_deleteCustHd.status]);

  useEffect(() => {
    if (call_getMgmtcat71.status === "suc") {
      set_s_mgmtcat71Options(
        () =>
          call_getMgmtcat71.data?.reduce(
            (data, current) => {
              data.push({
                label: current.itemNM,
                value: current.mgmtitem,
                type: current.type,
              });
              return data;
            },
            [{ label: t("util.util.all"), value: "All", type: "" }]
          ) || []
      );
    } else if (call_getMgmtcat71.status === "err") {
      // message.error(call_getMgmtcat71.msg);
    }
  }, [call_getMgmtcat71.status]);

  useEffect(() => {
    if (call_getMgmtcat73.status === "suc") {
      set_s_mgmtcat73Options(
        () =>
          call_getMgmtcat73.data?.reduce(
            (data, current) => {
              data.push({
                label: current.itemNM,
                value: current.mgmtitem,
                type: current.type,
              });
              return data;
            },
            [{ label: t("util.util.all"), value: "All", type: "" }]
          ) || []
      );
    } else if (call_getMgmtcat73.status === "err") {
      // message.error(call_getMgmtcat73.msg);
    }
  }, [call_getMgmtcat73.status]);

  return (
    <>
      <PageHeader
        title={t("ADM.client.pageHeader")}
        extra={[
          <Input
            key="s_searchData.item"
            value={s_searchData.name}
            onPressEnter={onSearch}
            onChange={(e) => handlChange("name", e.target.value)}
            className="w-[550px]"
            placeholder={`${t("util.util.placeholder_input")}${t("util.util.custID")}，${t(
              "util.util.custalias"
            )}，${t("util.util.custNM")}`}
          />,
          // <Button type="primary" key="search" onClick={onSearch}>
          //   {t("util.util.search")}
          // </Button>,
          // <Button
          //   type="primary"
          //   key="create"
          //   onClick={() => {
          //     set_c_lightData({});
          //     set_s_isShowModal(true);
          //   }}
          // >
          //   {t("util.util.add")}
          // </Button>,

          <SearchButton type="primary" key="search" onClick={onSearch} />,

          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getCustHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t("ADM.client.pageHeader")}`
            : `${t("util.util.add")}${t("ADM.client.pageHeader")}`
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getCustHd.request();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={{
            s_mgmtcat71Options: s_mgmtcat71Options,
            s_mgmtcat73Options: s_mgmtcat73Options,
          }}
        />
      </CustomModal>
    </>
  );
};

export default HD;
