// rem = 12*1
export const fontSize = {
  h1: "2.5rem",
  h2: "2rem",
  h3: "1.75rem",
  h4: "1.5rem",
  h5: "1.25rem",
  p: "1rem",
  text:"0.75rem"
};
