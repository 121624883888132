const zh_CN = {
  "ADM": {
    "category-manage": {
      "mgmtitem": "分类项目",
      "mtype": "机种",
      "pageHeader": "查询分类设定",
      "parentID": " 父阶ID",
      "parentNM": "父阶名称",
      "tabCust": "客户分类",
      "tabMtype": "物料机种分类",
      "tabQuery": "物料分类",
      "tabSupp": "厂商分类",
    },
    "client": {
      "15": "请输入职称",
      "account": "银行帐号",
      "addCustomerInformation": "新增客户资料",
      "addr": "公司地址",
      "address": "连络住址",
      "bankAddrOS": "银行地址",
      "bankCodeOS": "分行代号",
      "branchOS": "分行名称",
      "classificationOfRestrictions": "限权分类",
      "contact": "联络人",
      "country": "国别",
      "custID": "客户ID",
      "custNM": "客户名称",
      "custalias": "客户简称",
      "customerBasicInformation": "客户基本资料",
      "customerContactInformation": "客户联络资料",
      "department": "部门",
      "domesticBankName": "国内银行名称",
      "domestic_OverseasBankInformation": "国内/海外银行资料",
      "fullRangeOfFurniture": "全系列家具",
      "groupalias": "集团简称",
      "isValid": "是否有效",
      "item": "10条/页",
      "itemNM": "窗口功能",
      "itemno": "项序",
      "livingRoomFurniture": "客厅家具",
      "location": "市场区域",
      "mail": "邮件",
      "maincurrency": "币别",
      "mark": "注记",
      "maxcredit": "信用条件",
      "mgmtcat": "授权分类",
      "mobile": "行动电话",
      "modifyContactWindowInformation": "修改联络窗口资料",
      "modifyCustomerInformation": "修改客户资料",
      "no": "否",
      "ordertype": "订货类别",
      "overseasBankName": "海外银行名称",
      "pageHeader": "客户资料维护",
      "pageHeader2": "客户资料管理",
      "payterm": "付款条件",
      "postID": "职称",
      "rcvT": "收款时间",
      "rcvtotal": "收款金额",
      "rcvtype": "收款方式",
      "rcvtype_1": "银行汇款(T/T)",
      "rcvtype_2": "应付票据(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "showTotal": "共 1 项 ， 正在显示第1到1项",
      "strike_lifeF_0": "",
      "strike_lifeF_1": "冲帐覆核",
      "strike_lifeF_T": "完成冲帐",
      "strike_note": "冲帐注记",
      "swiftCode": "SWIFT CODE",
      "taxID": "统一编号",
      "tel": "公司电话",
      "username": "户名",
      "yes": "是",
    },
    "company": {
      "15": "请输入职称",
      "account": "银行帐号",
      "addSupplierInformation": "新增厂商资料",
      "addr": "公司地址",
      "bankAddrOS": "银行地址",
      "bankCodeOS": "分行代号",
      "branchOS": "分行名称",
      "classificationOfRestrictions": "限权分类",
      "contact": "联络人",
      "country": "国别",
      "department": "部门",
      "domesticBankName": "国内银行名称",
      "domestic_OverseasBankInformation": "国内/海外银行资料",
      "groupalias": "集团简称",
      "isValid": "是否有效",
      "item": "10条/页",
      "itemNM": "窗口功能",
      "itemno": "项序",
      "location": "市场区域",
      "mail": "邮件",
      "maincurrency": "交易币别",
      "manufacturerBasicInformation": "厂商基本资料",
      "manufacturerContactInformation": "厂商联络资料",
      "mark": "注记",
      "maxcredit": "信用条件",
      "mgmtcat": "授权分类",
      "mobile": "行动电话",
      "modifySupplierContactInformation": "修改厂商联络资料",
      "modifySupplierInformation": "修改厂商资料",
      "no": "否",
      "overseasBankName": "海外银行名称",
      "pageHeader": "厂商资料维护",
      "pageHeader2": "厂商资料管理",
      "payterm": "付款条件",
      "postID": "职称",
      "showTotal": "共 1 项 ， 正在显示第1到1项",
      "suppID": "厂商ID",
      "suppNM": "厂商名称",
      "suppalias": "厂商简称",
      "swiftCode": "SWIFT CODE",
      "taxID": "统一编号",
      "tel": "公司电话",
      "username": "户名",
      "yes": "是",
    },
    "data-auth-manage": {
      "mgmtitem": "分类项目",
      "pageHeader": "功能授权设定",
      "tabCust": "客资授权分组",
      "tabPdt": "物料授权分组",
      "tabSupp": "厂资授权分组",
      "tabWh": "库房授权分组",
    },
    "edm": {
      "ECN": "工程变更通知",
      "Pfc": "工程文件版控",
      "Upload": "上传",
      "alertspeed": "产速报警",
      "alertstop": "逾停报警",
      "alertyield": "良率红灯",
      "alternative_wpc": "替代途程",
      "attribute": "物料属性",
      "attribute_0": "0 原料",
      "attribute_1": "1 物料",
      "attribute_2": "2 非标零件",
      "attribute_3": "3 市售零件",
      "attribute_4": "4 半成品",
      "attribute_5": "5 成品",
      "batchbom": "批号整包数",
      "bom": "物料清单维护",
      "data": "资料",
      "doc": "标准文件发行",
      "docName": "文件名称",
      "docUpload": "文件上传",
      "docVer": "文件版本",
      "edit_all_wpc": "途程全局编辑",
      "errorPleaseFull": "请填写完整资料",
      "ieUUID": "汇算单号",
      "img": "图像",
      "isrework": "重工",
      "isvalid": "是否启用",
      "level": "料阶",
      "main_wpc": "主途程",
      "mgmtcat": "管理分类",
      "nextSN": "次途程号",
      "note": "备注",
      "pPN": "父件料号",
      "pageHeader": "技术资料维护",
      "pdftype": "文件类别",
      "pdtclass": "商品类别",
      "pdtclass_A": "附件",
      "pdtclass_M": "主件",
      "pdtclass_P": "包材",
      "pdtclass_X": "[N/A]",
      "pdtclass_X_BK": "非销售出货料件",
      "pfNM": "制程",
      "planhead": "计划人数",
      "ppdtNM": "父件品名",
      "productionScheduleDetails": "途程明细",
      "pwcNM": "工作中心",
      "qty": "组数",
      "stdlt": "备产时间",
      "stdmh": "标准机时",
      "stdwh": "标准人时",
      "unitNM": "计量单位",
      "updtT": "更新时间",
      "ver": "版码",
      "warnyield": "良率黄灯",
      "whID": "入库仓别",
      "wpc": "生产途程",
      "wpcManage": "生产途程维护",
      "wpcSN": "途程号",
      "wpcSetting": "途程号设定",
      "wpcset": "途程组",
      "wpcsetNM": "途程组名称",
      "wsID": "工站",
    },
    "function-params-manage": {
      "1_decimal_place": "小数点后1位",
      "2_decimal_place": "小数点后2位",
      "add_number": "新增数字",
      "add_point": "新增小数点",
      "add_string": "新增字串",
      "area": "面积",
      "audience": "推播受群",
      "capacity": "容积",
      "category": "计量类别",
      "class": "2(课级)",
      "count": "计数",
      "countType": "计数型",
      "cruisebot": "监控程式",
      "currency": "交易币别",
      "currencySetting": "交易币别设定",
      "currencyitem": "币别代号",
      "eventID": "事件ID",
      "eventNM": "事件名称",
      "funNM": "功能名称",
      "funcode": "组织功能码",
      "function": "推播群组设定",
      "functions": "推播群组",
      "ismycurrency": "是否本币",
      "itemNM": "币别名称",
      "length": "长度",
      "lvlNM": "层级名称",
      "lvlcode": "层级码",
      "management": "3(理级)",
      "msgmodel": "推播讯息模版",
      "notify": "推播事件设定",
      "opUnitSetting": "操作单位设定",
      "opunit": "操作单位",
      "pageHeader": "系统参数设定",
      "pushEvent": "推播事件",
      "pusher": "发报系统",
      "pushlayer": "推播层级",
      "pushlvl": "推播层级设定",
      "quantitative": "计量型",
      "rptcode": "提报类别",
      "rptcode_A0": "人员提报",
      "rptcode_B0": "系统提报(WOS)",
      "rptcode_C0": "设备提报",
      "rptcode_D0": "管理提报_远端",
      "rptcode_E0": "管理提报_云端",
      "searchPlaceholder": "查询ID，单位，代号，功能码，层级名称",
      "setlvl": "推播级数",
      "situF": "警讯灯号",
      "situF_R": "红",
      "situF_Y": "黄",
      "sort": "排序",
      "startlvl": "起报层级",
      "startlvl_0": "全员",
      "startlvl_1": "班组干部",
      "startlvl_2": "课级干部",
      "startlvl_3": "理级主管",
      "startlvl_4": "总级主管",
      "team": "1(班组)",
      "total": "4(总级)",
      "type": "计量型态",
      "unit": "计量单位",
      "unitNM1": "单位名称",
      "unitSetting": "计量单位设定",
      "volume": "体积",
      "weight": "重量",
    },
    "logistics-equipment_AGV": { "pageHeader": " 物流设备_AGV" },
    "measurement-equipment": {
      "RnDEquipment": "研发设备",
      "pageHeader": "量测设备",
      "qualityAssuranceEquipment": "品保设备",
      "weighingEquipment": "量秤设备",
    },
    "mold": {
      "advance": "进阶资料",
      "assetID": "模具ID",
      "assetNM": "资产名称",
      "assetkind": "模具类型",
      "cavity": "实体穴数",
      "company": "公司",
      "cummoldC": "最后累计生产模数",
      "custID": "客户ID",
      "draw": "模具图面",
      "femalepic": "母模照片",
      "gatesystem": "浇口型式",
      "height": "模具总高",
      "isvalid": "是否有效",
      "length": "模具总长",
      "malepic": "公模照片",
      "mold": "模具资料",
      "moldlife": "设计寿命",
      "moldtype": "共模",
      "note": "模具注记",
      "openC": "开帐模次",
      "openT": "取得日期",
      "pageHeader": "模具资料",
      "pdtpic": "成品照片",
      "pfID": "制程",
      "precummoldC": "上次累计生产模数",
      "property": "财产归属",
      "status": "状况灯号",
      "stdcavity": "标准穴数",
      "validcavity": "有效穴数",
      "weight": "模具重量",
      "wholepic": "合模照片",
      "width": "模具总宽",
      "wsID": "工站",
    },
    "oem-manage": {
      "oem_item": "",
      "oem_item_manage": "",
      "oem_supp": "",
      "oem_supp_manage": "",
      "oemitemNM": "",
      "type": "",
      "type_A": "",
      "type_B": "",
      "type_C": "",
      "vendorcode": "",
    },
    "optype": {},
    "plant-maintenance": { "pageHeader": "厂房维护" },
    "plant-manage": {
      "deptID": "部门别",
      "level": "推播层级",
      "pageHeader": "厂别主档设定",
      "plantID": "厂别",
      "postID": "职称ID",
      "push1": "一级推播时距(分)",
      "push2": "二级推播时距(分)",
      "push3": "三级推播时距(分)",
      "tabDept": "部门主档",
      "tabPlant": "厂区主档",
      "tabPost": "职称主档",
    },
    "pn-manage": {
      "BDM": "物料资料管理",
      "SDM": "系统资料管理",
      "attribute": "属性",
      "attribute_6": "",
      "basicProduct": "产品基本资料",
      "bom": "单位转换",
      "boxH": "包装尺寸 (高)",
      "boxL": "包装尺寸 (长)",
      "boxW": "包装尺寸 (宽)",
      "dealID": "对象ID",
      "dealNM": "交易品名",
      "dealPN": "交易料号/SKU",
      "dealPNSearch": "交易料号查询",
      "dealalias": "对象简称",
      "dealpid": "条码",
      "drawno": "工程图号",
      "header_create": "据此创建条目",
      "heirPN": "继承料号",
      "heirdealID": "继承对象",
      "idkw": "包装条码关字",
      "inititalcost": "期初程本",
      "isfocus": "是否关注",
      "isvalid": "是否有效",
      "matl": "材质",
      "maxcost": "库存金额上限",
      "maxstock": "库存上限",
      "mgmtcat": "授权分类",
      "minstock": "库存下限",
      "moq": "最小起购量",
      "mtype": "机种",
      "newPN": "新版料号",
      "nextpacksn": "外层包装层数",
      "opunit": "操作单位",
      "pack": "包装单位",
      "packsn": "包装层数",
      "packtype": "包装用途",
      "packtype_0": "操作包装_操作单位使用",
      "packtype_1": "出货包装_打包出货使用",
      "packtype_2": "周转包装_产线周转使用",
      "pageHeader": "基本资料维护",
      "palH": "栈板 (高)",
      "palL": "栈板 (长)",
      "palW": "栈板 (宽)",
      "payAttentionToMaterials": "关注料件",
      "pbom": "包装内层组数",
      "pdtH": "产品尺寸 (高)",
      "pdtL": "产品尺寸 (长)",
      "pdtW": "产品尺寸 (宽)",
      "pdtclass": "商品",
      "punit": "内层使用单位",
      "purchasecost": "本期进货成本",
      "purchaseqty": "本期进料量",
      "querycat": "查询分类",
      "replacePN": "替代料号",
      "safestock": "安全库存",
      "salecurrency": "标价币别",
      "setbatch": "入库批号",
      "setidm": "条码设定",
      "setpack": "包装设定",
      "settlemonth": "变动结算月份",
      "shipV": "包装材积CUFT",
      "shipW": "运装重量",
      "sizeunit": "尺寸单位",
      "stdcost": "标准成本",
      "stdsale": "标准价格",
      "stdstock": "标准库存",
      "stock": "10条/页",
      "stocklife": "库存周期",
      "tabCost": "标准成本设定",
      "tabDeal": "交易料号设定",
      "tabExtra": "产品尺寸设定",
      "tabIdkw": "生产绑定设定",
      "tabPack": "包装绑定设定",
      "tabPrice": "标准价格设定",
      "tabStock": "库存条件设定",
      "unitwt": "计数单重",
      "varcost": "变动成本",
      "ver": "包装标准版本",
      "weightunit": "重量单位",
    },
    "procedure-manage": {
      "pageHeader": "制程规划维护",
      "pccID": "成本中心ID",
      "pccNM": "成本中心名称",
      "pf": "制程",
      "pfID": "制程ID",
      "pfNM": "制程名称",
      "pwc": "工作中心",
      "pwcID": "工作中心ID",
      "pwcNM": "工作中心名称",
      "tabCost": "成本中心设定",
      "tabFlow": "制程主档设定",
      "tabWork": "工作中心设定",
    },
    "production-equipment": {
      "auxiliaryEquipment": "辅助机器",
      "mainEquipment": "主要设备",
      "pageHeader": "生产设备",
      "publicEquipment": "公共设备",
    },
    "production-line-manage": {
      "MC": "物料作业",
      "QC": "质控作业",
      "TK": "生产看板",
      "WOC": "生产控制",
      "auth_setting": "功能设置",
      "batch": "批号管理",
      "dispachManage": "派单管理",
      "isdashboard": "看板显示",
      "mold": "模具管理",
      "oem": "委外报工",
      "pageHeader": " 产线规划维护",
      "partsBinding": "部品扫码",
      "pcc": "成本中心",
      "pf": "制程",
      "ppidp": "成品绑定",
      "ps": "车间",
      "psID": "车间ID",
      "psNM": "车间名称",
      "pwc": "工作中心",
      "tabLine": "工线资料设定",
      "tabPS": "车间资料设定",
      "tabStation": "工站资料设定",
      "tbHeader_PS": "工站资料",
      "tbHeader_Station": "工线资料",
      "whm": "工时管理",
      "wlID": "工线ID",
      "wlNM": "工线名称",
      "wpcSearch": "途单查询",
      "ws": "工站",
      "wsID": "工站ID",
      "wsNM": "工站名称",
    },
    "string-manage": {
      "driItemID": "单位ID",
      "driItemNM": "单位名称",
      "itemID": "项目ID",
      "pageHeader": "功能字串管理",
      "tabDri": "责任单位",
      "tabNgItem": "不良项目",
      "tabOffLine": "下线项目",
      "tabOkItem": "良品项目",
      "tabPause": "停工项目",
    },
    "trading-pattern": {
      "OMS": "订单系统",
      "PMS": "采购系统",
      "adda": "条款注记",
      "addb": "加扣款项",
      "addc": "预付作业",
      "addd": "付款方式",
      "adde": "贸易条件",
      "addf": "退货作业",
      "addg": "取消订量",
      "addh": "付款条件",
      "addi": "收款条件",
      "additem": "项目ID",
      "addr": "联络住址",
      "addtype": "交易条件",
      "contact": "联络人",
      "contactitem": "联络窗口代号",
      "department": "部门",
      "itemNM": "联络窗口名称",
      "mail": "邮址",
      "mobile": "行动电话",
      "pageHeader": "项目字串设定",
      "tabAdded": "附加项目设定",
      "tabOMSAdded": "销货附加条件",
      "tabPMSAdded": "采购附加条件",
      "tabWindow": "对外窗口设定",
      "tel": "联络电话",
    },
    "transportation-equipment": { "pageHeader": "交通设备" },
    "user-manage": {
      "1": "员工帐号",
      "APP": "行动APP",
      "BDM": "物料资料管理",
      "EDM": " 生产设备管理",
      "MC": "物料作业",
      "OMS": "销货管理系统",
      "PDA": "PDA权限",
      "PMS": "采购管理系统",
      "QC": "质控作业",
      "SDM": "系统资料管理",
      "TDM": "厂区资料管理",
      "TK": "生产看板",
      "WMS": "库存管理系统",
      "WOC": "生产控制",
      "abc-board": "异常管理看板",
      "account": "员工帐号",
      "autoReport": "接收自动报表",
      "cardID": "卡勤识别码",
      "dashboard": "数位生产看板",
      "dealmgmt": "对象授权分类",
      "dispachManage": "派单管理",
      "function": "组织功能",
      "level": "推播层级",
      "mail": "邮址",
      "pageHeader": "员工资料维护",
      "pageHeader24524": "系统资料维护-员工资料管理",
      "partsBinding": "部品扫码",
      "pdtmgmt": "物料授权分类",
      "peopleNM": "员工姓名",
      "personalID": "身份证号",
      "postID": "职称",
      "ppidp": "成品绑定",
      "proxyNM": "联络人姓名",
      "proxytel": "联络人电话",
      "pwd": "密码",
      "role": "角色权限",
      "roleID": "角色ID",
      "roleNM": "角色名称",
      "staffAuth": "角色权限设定",
      "staffData": "员工资料管理",
      "staffID": "员工工号",
      "systemAuth": "系统登入权限",
      "tel": "电话",
      "whmgmt": "仓库授权分类",
      "wpcSearch": "途单查询",
    },
    "util": {
      "dispachManage": "派单管理",
      "mail": "邮件",
      "partsBinding": "部品扫码",
      "pf": "制程",
      "pfID": "制程",
      "pfNM": "制程名称",
      "postID": "职称",
      "ppidp": "成品绑定",
      "pushlayer": "推播层级",
      "pwc": "工作中心",
      "sectID": "",
      "sectID_C": "",
      "sectID_D": "",
      "wpcSearch": "途单查询",
      "wsID": "工站ID",
    },
    "warehouse-manage": {
      "cancelClose": "取消关帐",
      "close": "关帐",
      "isClose": "是否关帐",
      "mgmtcat": "库房授权",
      "pageHeader": " 库房规划维护",
      "pdtclass": "料阶",
      "ps": "车间",
      "psNM": "車間名稱",
      "tabWareHouse": "仓别资料设定",
      "whID": "仓别ID",
      "whNM": "仓别名称",
    },
  },
  "APS": {
    "aps-manage": {
      "ETDend": "",
      "ETDstart": "",
      "issueID": "",
      "issueday": "",
      "jobN": "",
      "mo": "",
      "orderN": "",
      "pageHeader": "",
      "tabMo": "",
      "tabWo": "",
      "wo": "",
    },
    "util": {
      "detail": "",
      "dueend": "",
      "duestart": "",
      "opcode": "",
      "opcode_3310": "",
      "opcode_3320": "",
      "opcode_3330": "",
      "opcode_3340": "",
      "realqty": "",
    },
  },
  "BPM": {
    "dsevent": { "keynote": "", "system": "" },
    "sign-level": { "opfunction": "", "optype": "", "pageHeader": "" },
    "util": {
      "approve": "",
      "check": "",
      "level": "",
      "open": "",
      "opfunction_1": "",
      "opfunction_2": "",
      "opfunction_3": "",
      "opfunction_4": "",
      "opfunction_5": "",
      "optype_11": "",
      "optype_12": "",
      "optype_40": "",
      "optype_41": "",
      "permissions": "",
      "review": "",
      "signmode": "",
    },
  },
  "IMS": {
    "inv-history": {
      "beginCBM": "期初\\n(CBM)",
      "beginPrice": "期初\\n金额",
      "beginqty": "期初",
      "endCBM": "期末\\n(CBM)",
      "endPrice": "期末\\n金额",
      "endqty": "期末",
      "h": "厚",
      "inCBM": "进\\n(CBM)",
      "inPrice": "进货\\n金额",
      "inqty": "进",
      "l": "长",
      "level": "等级",
      "outCBM": "销\\n(CBM)",
      "outPrice": "销货\\n金额",
      "outqty": "销",
      "pageHeader": "每月库存纪录",
      "proD": "进料日期",
      "stdsale": "单价",
      "suppalias": "供应商",
      "unit": "计量单位",
      "useCBM": "耗\\n(CBM)",
      "usePrice": "耗用\\n金额",
      "w": "宽",
    },
    "inventory": {},
    "open-account": {},
    "pdt-manage": {},
    "pdt-overuse": { "batchno": "出库批号" },
    "pdt-rtn": {},
    "pdt-use": {},
    "plan-in": {
      "IMS": "库存管理系统",
      "PN": "料号",
      "SN": "序",
      "add": "新增",
      "add_in_pdt": "新建生产入库资料",
      "all": "全部",
      "bal": "帐差",
      "cancel": "取消",
      "choose_in_pdt_N": "请选择生产入库单",
      "choose_whID": "选择仓别",
      "close": "關閉",
      "confirm": "确定",
      "del_confirm": "删除提交确认",
      "del_confirm_msg": "请先确认异动资料无误，再确认删除",
      "delete": "删除",
      "detail": "詳情",
      "dqty": "条码扫入",
      "fir": "星期五",
      "hide_detail": "隐藏明细档",
      "idmtype": "过帐型态",
      "idmtype_d": "条码计数",
      "idmtype_m": "混合计数",
      "idmtype_s": "人工计数",
      "in_pdt_N": "生产入库单",
      "in_pdt_detail": "生产入库明细",
      "in_pro_": "采购入库明细",
      "inqty": "生产数量",
      "jobN": "作业单号",
      "lifeF": "历程",
      "lifeF_0": "已创建",
      "lifeF_1": "执行中",
      "lifeF_2": "已提交",
      "lifeF_T": "已异动",
      "modify": "修改",
      "mon": "星期一",
      "month": "月",
      "no_data": "无此资料",
      "ok": "確認",
      "opcode": "作业单别",
      "opcode_4010": "开帐盘点",
      "opcode_4020": "周期盘点",
      "opcode_4030": "专案盘点",
      "opcode_4110": "生产入库",
      "opcode_4120": "采购入库",
      "opcode_4130": "销售退回",
      "opcode_4141": "库房调入",
      "opcode_4142": "库房借入",
      "opcode_4143": "借出归还",
      "opcode_4160": "生产退料",
      "operation": "操作",
      "page": "共 1 项 ， 正在显示第1到1项",
      "pageHeader": "库房入库作业",
      "page_size": "10条/页",
      "pdtNM": "品名",
      "pdtspec": "规格",
      "planN": "来源单号码",
      "planqty": "计画数量",
      "plantxnqty": "规划异动量",
      "realqty": "实际数量",
      "realtxnqty": "实际异动量",
      "sat": "星期六",
      "sqty": "人工输入",
      "sun": "星期日",
      "thu": "星期四",
      "today": "今天",
      "totalqty": "总数",
      "tue": "星期二",
      "txn_confirm": "异动提交确认",
      "txn_confirm_msg": "请先确认异动资料无误，再确认异动",
      "updtT": "更新时间",
      "wed": "星期三",
      "whID": "仓别",
      "year": "年",
    },
    "plan-out": {
      "opcode_4210": "生产领用",
      "opcode_4220": "销售发货",
      "opcode_4230": "进料退出",
      "opcode_4241": "调拨出库",
      "opcode_4242": "库房借出",
      "opcode_4243": "借入归还",
      "opcode_4260": "生产超领",
      "pageHeader": "库房出库作业",
      "planqty": "计画数量",
    },
    "pro-instock": {
      "inchargeID": "",
      "lifeF_T": "",
      "pageHeader": "",
      "wmsopUUID": "",
    },
    "report": {},
    "stock-search": {
      "borrow": "借",
      "changeDetails": "异动明细",
      "inTransit": "在途量",
      "loan": "贷",
      "lockqty": "封控数",
      "onhandqty": "有效库存数",
      "pageHeader": "库存状况查询",
      "planin": "入库规划",
      "planout": "出库规划",
      "posted": "已过帐",
      "scanin": "点料入库",
      "scanout": "检料出库",
      "searchBatchno": "批号查询",
      "searchPN": "料号查询",
      "searchWh": "各仓查询",
      "timeSeries": "时间序",
      "total": "预计可用数",
      "totalIn": "总入库",
      "totalOut": "总出库",
      "tqty": "库存数",
      "whDetails": "各仓明细",
    },
    "util": {
      "bal": "帐差",
      "batchno_4010": "",
      "batchno_4020": "",
      "batchno_4030": "",
      "batchno_4110": "",
      "batchno_4120": "",
      "batchno_4130": "",
      "batchno_4141": "",
      "batchno_4142": "",
      "batchno_4143": "",
      "batchno_4160": "",
      "batchno_4210": "",
      "batchno_4220": "",
      "batchno_4230": "",
      "batchno_4241": "",
      "batchno_4242": "",
      "batchno_4243": "",
      "batchno_4260": "",
      "batchno_4299": "",
      "dealID_4110": "",
      "dealID_4120": "",
      "dealID_4130": "",
      "dealID_4142": "",
      "dealID_4210": "",
      "dealID_4220": "",
      "dealID_4230": "",
      "dealID_4241": "",
      "dealID_4242": "",
      "dealID_4260": "",
      "dealID_4299": "",
      "dealNM_4110": "",
      "dealNM_4120": "",
      "dealNM_4130": "",
      "dealNM_4142": "",
      "dealNM_4210": "",
      "dealNM_4220": "",
      "dealNM_4230": "",
      "dealNM_4241": "",
      "dealNM_4242": "",
      "dealNM_4260": "",
      "dealNM_4299": "",
      "dueday_4010": "",
      "dueday_4020": "",
      "dueday_4030": "",
      "dueday_4110": "",
      "dueday_4120": "",
      "dueday_4130": "",
      "dueday_4141": "",
      "dueday_4142": "",
      "dueday_4143": "",
      "dueday_4160": "",
      "dueday_4210": "",
      "dueday_4220": "",
      "dueday_4230": "",
      "dueday_4241": "",
      "dueday_4242": "",
      "dueday_4243": "",
      "dueday_4260": "",
      "dueday_4299": "",
      "jobN": "作业单号",
      "lifeF_0": "已创建",
      "lifeF_1": "执行中",
      "lifeF_2": "已提交",
      "lifeF_A": "全部",
      "lifeF_T": "已异动",
      "opcode": "作业单别",
      "opcode_4010": "开帐盘点",
      "opcode_4020": "周期盘点",
      "opcode_4030": "专案盘点",
      "opcode_4110": "生产入库",
      "opcode_4120": "采购入库",
      "opcode_4130": "销售退回",
      "opcode_4141": "库房调入",
      "opcode_4142": "库房借入",
      "opcode_4143": "借出归还",
      "opcode_4150": "",
      "opcode_4160": "生产退料",
      "opcode_4170": "",
      "opcode_4210": "生产领用",
      "opcode_4220": "销售发货",
      "opcode_4230": "进料退出",
      "opcode_4241": "调拨出库",
      "opcode_4242": "库房借出",
      "opcode_4243": "借入归还",
      "opcode_4260": "生产超领",
      "opcode_4299": "",
      "operN_4010": "盘点单号",
      "operN_4020": "盘点单号",
      "operN_4030": "盘点单号",
      "operN_4110": "单号",
      "operN_4120": "采购单号",
      "operN_4130": "销售单号",
      "operN_4141": "对仓",
      "operN_4142": "借入单号",
      "operN_4143": "借出单号",
      "operN_4160": "借出单号",
      "operN_4210": "领用单号",
      "operN_4220": "销售单号",
      "operN_4230": "退出单号",
      "operN_4241": "对仓",
      "operN_4242": "借出单号",
      "operN_4243": "借入单号",
      "operN_4260": "领用单号",
      "operN_4299": "领用单号",
      "planqty": "计画数量",
      "preN_4010": "",
      "preN_4020": "",
      "preN_4030": "",
      "preN_4110": "",
      "preN_4120": "",
      "preN_4130": "",
      "preN_4141": "",
      "preN_4142": "",
      "preN_4143": "",
      "preN_4150": "",
      "preN_4160": "",
      "preN_4170": "来源单号",
      "preN_4210": "",
      "preN_4220": "",
      "preN_4230": "",
      "preN_4241": "",
      "preN_4242": "",
      "preN_4243": "",
      "preN_4260": "",
      "preN_4299": "",
      "realtqty": "实际数量",
      "txn_confirm": "异动提交确认",
      "txn_confirm_msg": "请先确认异动资料无误，再确认删除",
    },
  },
  "OMS": {
    "ap-manage": {
      "N17": "应收单号",
      "N18": "冲帐单号",
      "ap": "对帐作业",
      "apCheck_confirm_text": "即将进入对帐覆核作业，是否储存对帐单资料",
      "apSearch": "对帐资料查询",
      "ap_addr": "客户地址",
      "ap_balance": "帐差",
      "ap_contact": "客户业务窗口",
      "ap_hubqty": "出库数量",
      "ap_itemNM": "窗口注记",
      "ap_itemtotal": "应收含税金额",
      "ap_lifeF_0": "创立",
      "ap_lifeF_1": "应收覆核",
      "ap_lifeF_2": "出应收单",
      "ap_lifeF_3": "冲帐(收款)未结案",
      "ap_lifeF_T": "结案",
      "ap_paytotal": "已冲帐金额",
      "ap_remove_text": "请先确认要删除的对帐单资料无误，再进行删除",
      "ap_sumtotal": "总项金额",
      "ap_tb": "对帐单明细",
      "ap_total": "应收含税总金额",
      "cancel_strikeCheck_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "confirmStrike": "确认冲帐",
      "confirmStrike_confirm_text": "确认冲帐后此冲帐单将结案，请确认是否冲帐",
      "contactWindow": "联络窗口资讯",
      "inbatchno": "进料批号",
      "opcode_1710": "销售出货",
      "opcode_1810": "应收冲帐",
      "pageHeader": "应收帐款管理",
      "rcv": "应收帐款查询",
      "rcvData": "应收资料",
      "rcvT": "收款时间",
      "rcv_drawer_title": "应收帐款主档",
      "rcv_inqty": "移转数量",
      "rcv_itemsum": "各项金额",
      "rcv_itemsumtax": "各项税额",
      "rcv_subtotal": "含税小计",
      "rcvtotal": "收款金额",
      "rcvtype": "收款方式",
      "rcvtype_1": "银行汇款(T/T)",
      "rcvtype_2": "应付票据(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "releaseAP_confirm_text": "即将进入出应收单确认，是否储存应收单资料?",
      "strike": "冲帐作业",
      "strikeCheck_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "strikeSearch": "冲帐纪录查询",
      "strike_lifeF_0": "创立",
      "strike_lifeF_1": "冲帐覆核",
      "strike_lifeF_T": "完成冲帐",
      "strike_note": "冲帐注记",
      "strike_remove_text": "请先确认要删除的冲帐单资料无误，再进行删除",
      "strike_sumtotal": "冲帐总金额",
      "strike_tb": "冲帐单明细",
      "strike_tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "unitprice": "销货单价",
    },
    "check": {
      "4130_header": "销售退回单主档",
      "4130_tb": "退回明细",
      "4220_header": "销售发货单主档",
      "4220_tb": "发货明细",
      "N": "作业单号",
      "batchdate": "批号日期",
      "batchno": "批号",
      "jobN": "来源单号",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "note": "注记",
      "opcode_4130": "销售退回单",
      "opcode_4220": "销售发货单",
      "pageHeader": "销货检验查询",
      "planqty": "移转数量",
      "punit": "销货单位",
      "stockinT": "移转时间",
      "total": "移转总量",
    },
    "fod": {
      "EDT": "预计交货日",
      "N12": "预订单号",
      "UploadSign": "上传回签",
      "approveT": "核准日期",
      "balqty": "剩余数量",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "cancel_fodCheck_confirm_text": "请先确认要取消预订覆核的资料无误，再进行取消预订覆核",
      "cancelqty": "取消数量",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "confirmqty": "确认数量",
      "contact": "客户交货窗口",
      "contact_placeholder": "选择客户交货窗口",
      "createID": "创建人员",
      "createT": "创建日期",
      "custCheck_confirm": "客户签回确认",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "custHistory": "客户交易历史查询",
      "custNM": "客户名称",
      "dealN": "客户单号",
      "dealN_placeholder": "请输入客户单号",
      "deal_history": "产品交易历史查询",
      "dealaddr": "客户地址",
      "dealcontact": "客户业务窗口",
      "dealcontact_placeholder": "选择客户业务窗口",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "demandqty": "客订数量",
      "driID": "负责业务",
      "driID_placeholder": "请选择负责业务",
      "endEDT": "预计交货日(迄)",
      "fod": "预订单",
      "fodCheck_confirm_text": "请先确认要预订覆核的资料无误，再进行预订覆核",
      "hd_delete_confirm_text": "请先确认要删除的预订单资料无误，再进行删除",
      "hd_drawer_title": "预订单主档",
      "itemsum": "单项金额(未税)",
      "itemsumtax": "各项税额",
      "lifeF_0": "发布",
      "lifeF_2": "预订覆核",
      "lifeF_7": "客户签回",
      "lifeF_T": "结案",
      "local_subtotal": "本币含税总金额",
      "local_total": "含税总金额(本币)",
      "note": "预订注记",
      "opcode_1210": "预估订单",
      "pageHeader": "预订作业",
      "payment": "付款方式",
      "punit": "订购单位",
      "qty": "预计抛转数量",
      "returnqty": "退货数量",
      "reviewFlow": "审核流程(3)",
      "signBack": "签回",
      "signT": "签回日期",
      "startEDT": "预计交货日(起)",
      "subtotal": "含税小计",
      "success": "成功",
      "sumtotal": "未税总金额",
      "tax": "总税额",
      "tb": "预订明细",
      "tb_drawer_title": "预订单明细",
      "tel_placeholder": "请选择联络人",
      "total": "含税总金额",
      "trade": "贸易条件",
      "trade_placeholder": "请选择贸易条件",
      "transferqty": "已抛转数量",
      "unitprice": "订购单价",
    },
    "ord": {
      "EDT": "预计交货日",
      "N": "单号",
      "N13": "销货单号",
      "accord": "據此",
      "add": "新建",
      "adda": "条款注记",
      "adda_delete_confirm_text": "请先确认要删除的条款资料无误，再进行删除",
      "addb": "加扣款项",
      "addb_delete_confirm_text": "请先确认要删除的款项资料无误，再进行删除",
      "addc": "预付作业",
      "addc_delete_confirm_text": "请先确认要删除的预付作业无误，再进行删除",
      "amount": "未税金额",
      "balqty": "剩余数量",
      "boxC": "箱数",
      "cancel": "取消",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "cancel_orderCheck_text": "请先确认要取消销货覆核的资料无误，再进行取消销货覆核",
      "cancelqty": "取消数量",
      "charge": "总加扣款项",
      "closeData_confirm_text": "请先确认要结令的销货单资料无误，再进行强制结令",
      "confirmqty": "客户确认数量",
      "contact": "客户交货窗口",
      "create": "创建",
      "currencytype": "币别",
      "currencytype_placeholder": "请选择币别",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "custID": "客户编号",
      "custalias": "客户简称",
      "deal_history": "产品交易历史查询",
      "dealaddr": "客户地址",
      "dealcontact": "客户业务窗口",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "demandqty": "销货数量",
      "driID": "负责业务",
      "endEDT": "预计出货日(迄)",
      "exchangeD": "汇率日期",
      "exchangerate": "汇率",
      "exportExcel": "汇出Excel",
      "fodSearch": "预订纪录查询",
      "hd_drawer_title": "销货单主档",
      "header_cancel_create": "取消据此创建",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "itemno_total": "总项数",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "lifeF_0": "发布",
      "lifeF_2": "销货覆核",
      "lifeF_7": "客户签回",
      "lifeF_T": "结令",
      "local_total": "含税总金额(本币)",
      "note": "销货注记",
      "ok": "",
      "opcode": "单别",
      "opcode_1310": "国内订单",
      "opcode_1320": "国外订单",
      "opcode_placeholder": "请选择单别",
      "ord": "销货单",
      "orderCheck_text": "请先确认要销货覆核的资料无误，再进行销货覆核",
      "outstockSearch": "发货纪录查询",
      "pageHeader": "销货作业",
      "payment": "付款方式",
      "placeholder_input": "请输入",
      "placeholder_select": "请选择",
      "punit": "销货单位",
      "qty": "本次抛转数量",
      "quoSearch": "报价纪录查询",
      "removeData_confirm_text": "请先确认要删除的销货单资料无误，再进行删除",
      "returnqty": "退货数量",
      "reviewFlow": "审核流程(3)",
      "setAdvanced": "设定进阶筛选条件",
      "startEDT": "预计出货日(起)",
      "statement": "项目说明",
      "submit": "确定",
      "subtotal": "含税小计",
      "sumtotal": "未税总金额",
      "sysMsg": "系统讯息",
      "table_close": "强制结令",
      "table_delete": "删除",
      "table_edit": "编辑",
      "table_more": "查看更多",
      "table_operate": "操作",
      "take": "引用",
      "taxrate": "税率",
      "taxtype": "税别",
      "tb": "销货明细",
      "tel": "电话",
      "throw": "抛转",
      "total": "含税总金额(交易币别)",
      "trade": "贸易条件",
      "transferqty": "已抛转数量",
      "unitprice": "销货单价",
      "window": "窗口",
    },
    "out-stock": {
      "IQCcheck": "进检抽样",
      "IQCcheck_text": "即将进入进检抽样作业，是否储存发货单资料?",
      "IQCfinish_text": "即将进入IQC完验作业，是否储存发货单资料?",
      "N14": "发货单号",
      "QAcheck": "品保放行",
      "QAcheck_err": "发货明细 [验收数量]+[验退数量] 要等于 [发货数量]",
      "QAcheck_text": "即将进入品保放行作业，是否储存发货单资料?",
      "boxnum": "箱数",
      "cancel_outstockCheck_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "choose": "选择",
      "choose_err": "剩余数量为0不可引用",
      "closeData_confirm_text": "请先确认要删除的发货单资料无误，再进行删除",
      "contact": "联络人",
      "goqty": "验收数量",
      "hd_drawer_title": "发货单主档",
      "indqty": "发货数量",
      "inspectID": "进检人员",
      "inspectT": "完验时间",
      "judgeT": "品保判定日期",
      "lifeF_0": "发布",
      "lifeF_2": "发货覆核",
      "lifeF_3": "IQC抽验",
      "lifeF_4": "IQC完验",
      "lifeF_7": "品保覆核",
      "lifeF_T": "结案",
      "note": "发货注记",
      "opcode_1410": "国内订单",
      "opcode_1420": "国外订单",
      "outstock": "发货单",
      "outstockCheck_text": "请先确认要发货覆核的资料无误，再进行发货覆核",
      "pageHeader": "发货作业",
      "punit": "发货单位",
      "qalID": "品判人员",
      "qalT": "放行时间",
      "qty": "数量",
      "rejectqty": "验退数量",
      "sampleID": "抽样人员",
      "sampleT": "抽样日期",
      "search": "搜寻",
      "stockSearch": "验收记录查询",
      "tb": "发货明细",
      "tb_drawer_title": "发货单明细",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "tel": "联络电话",
      "throwQty_msg": "抛转数量不可小于0",
      "throwStock_text": "即将进入抛转发货单作业，是否确定抛转?",
      "throw_save_msg": "请先选择引用再输入引用数量",
      "unitprice": "销货单价",
      "useDetail_msg": "不可引用相同明细",
    },
    "plan": { "plan": "" },
    "quo": {
      "Audit_confirm": "审查签核确认",
      "Audit_confirm_text": "审查签核后此报价单将无法编辑，确定要完成审查吗",
      "N11": "报价单号",
      "N_placeholder": "请输入单号",
      "PN": "料号",
      "PN_placeholder": "请选择料号",
      "UploadSignature": "上传签呈",
      "attribute": "物料属性",
      "cancel_quoCheck": "取消覆核",
      "cancel_quoCheck_confirm_Text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "del_confirm": "删除确认",
      "demand": "报价数量",
      "demand_placeholder": "请填写报价数量",
      "drawno": "图号",
      "get_next_data_err": "取得下一笔资料失败",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_prev_data_suc": "取得上一笔资料成功",
      "hd_delete_confirm_text": "请先确认要删除的报价单资料无误，再进行删除",
      "hd_drawer_title": "报价单主档",
      "isapprove": "单项成交",
      "isvalid": "是否有效",
      "itemno": "项序",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "itemtotal": "未税总金额",
      "lifeF_0": "发布",
      "lifeF_2": "报价覆核",
      "lifeF_7": "审核正式签送",
      "lifeF_T": "结案",
      "lifeF_placeholder": "请选择历程",
      "local_subtotal": "本币含税总金额",
      "localprice": "本币金额",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "no": "否",
      "note": "报价注记",
      "opcode_1110": "国内报价",
      "opcode_1120": "国外报价",
      "opcode_placeholder": "请选择单别",
      "opunit": "操作单位",
      "ordSearch": "销货纪录查询",
      "pageHeader": "报价作业",
      "pdtNM": "品名",
      "pdtclass": "商品类别",
      "pdtspec": "规格",
      "pdttype": "管理分类",
      "plan": "计画单",
      "planSearch": "计画纪录查询",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "punit": "报价单位",
      "quo": "报价单",
      "quoCheck": "",
      "quoCheck_confirm_Text": "请先确认要覆核的资料无误，再进行覆核",
      "quoID": "报价人员",
      "quoT": "报价日期",
      "removeSearch": "清除搜寻纪录",
      "searchField": "搜寻栏位",
      "subtotal": "单项含税小计",
      "tax": "总税额",
      "taxrate_placeholder": "请输入税率",
      "taxtype_placeholder": "请选择税别",
      "tb": "报价明细",
      "tb_drawer_title": "报价单明细",
      "total": "含税总金额(交易币别)",
      "unit": "计量单位",
      "unitprice": "报价金额",
      "unitprice_placeholder": "请填写报价金额",
      "updtT": "更新日期",
      "validdays": "有效天数",
      "validdays_placeholder": "请填写有效天数",
      "yes": "是",
    },
    "return": {
      "addr": "联络地址",
      "contact": "联络人",
      "contact_window": "客户业务窗口",
      "hd_remove_text": "请先确认要删除的销货退回单资料无误，再进行删除",
      "inbatchno": "进料批号",
      "itemNM": "窗口注记",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "note": "进退注记",
      "opcode_1610": "销货退回",
      "outN": "验退单号",
      "pageHeader": "销货退回作业",
      "return": "销货退回单",
      "rtnqty": "退回数量",
      "take": "取用",
      "tb": "销货退回明细",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "tel": "联络电话",
      "unitprice": "出货单价",
    },
    "util": {
      "N11": "报价单号",
      "N12": "预订单号",
      "N13": "销货单号",
      "N14": "发货单号",
      "N17": "应收单号",
      "N18": "冲帐单号",
      "ap": "",
      "apSearch": "对帐资料查询",
      "approveT": "客户签订日期",
      "attribute": "物料属性",
      "dealN": "客户单号",
      "drawno": "图号",
      "entrust": "客户签订",
      "fod": "",
      "fodSearch": "预订纪录查询",
      "localprice": "本币金额",
      "opcode_1110": "",
      "opcode_1120": "",
      "opcode_1210": "",
      "opcode_1310": "",
      "opcode_1320": "",
      "opcode_1410": "",
      "opcode_1420": "",
      "opcode_1610": "",
      "opcode_1710": "",
      "opcode_1810": "",
      "opcode_4130": "",
      "opcode_4220": "",
      "ord": "",
      "ordSearch": "销货纪录查询",
      "outN": "",
      "outstock": "",
      "outstockSearch": "发货纪录查询",
      "pdtclass": "商品类别",
      "pdttype": "管理分类",
      "plan": "",
      "planSearch": "计画纪录查询",
      "quo": "",
      "quoSearch": "报价纪录查询",
      "return": "",
      "stockSearch": "验收记录查询",
      "strikeSearch": "冲帐纪录查询",
    },
  },
  "PDS": {
    "abc-board": { "pageHeader": "异常管理看板" },
    "dashboard": { "pageHeader": "数位生产看板" },
    "dashboard-card": {},
    "overview-lines": {
      "act": "稼动率",
      "allDay": "左",
      "pageHeader": "线体稼动总表",
      "shiftD": "日班",
      "shiftN": "夜班",
      "wlID": "线别",
    },
    "performance-table": {},
    "product_barcode_search": {},
    "production-line-status": {},
    "report_search": {},
    "util": { "wlID": "线别" },
    "won_resume_search": {},
    "wpc-search": {
      "hdC": "",
      "issueday": "",
      "lifeF_0": "",
      "lifeF_1": "",
      "lifeF_2": "",
      "lifeF_3": "",
      "lifeF_4": "",
      "lifeF_7": "",
      "lifeF_B": "",
      "lifeF_G": "",
      "lifeF_L": "",
      "lifeF_P": "",
      "lifeF_aps": "",
      "linemix": "",
      "pageHeader": "",
      "wlID": "",
    },
    "ws_wpc_search": {},
  },
  "PMS": {
    "ap-manage": {
      "": "",
      "AP_lifeF_0": "创立",
      "AP_lifeF_1": "覆核",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "冲帐(付款)未结案",
      "AP_lifeF_T": "结案",
      "N22": "请购单号",
      "N23": "采购单号",
      "N24": "进料单号",
      "N27": "对帐单号",
      "N28": "冲帐单号",
      "PN": "ERP料号",
      "Strike_lifeF_0": "创立",
      "Strike_lifeF_1": "冲帐覆核",
      "Strike_lifeF_T": "",
      "addr": "厂商地址",
      "addr_placeholder": "请输入联络地址",
      "ap": "对帐单",
      "apCheck": "对帐覆核",
      "apCheck_confirm": "对帐覆核确认",
      "apCheck_confirm_text": "即将进入对帐覆核作业，是否储存对帐单资料?",
      "apSearch": "对帐资料查询",
      "ap_create_header": "新建对帐单主档",
      "ap_del_hd_confirm_text": "请先确认要删除的对帐单资料无误，再进行删除",
      "ap_edit_header": "编辑对帐单主档",
      "ap_sumtotal": "总项金额",
      "ap_tb": "对帐单明细",
      "ap_total": "应付含税总金额",
      "balance": "帐差",
      "cancel_apCheck": "取消覆核",
      "cancel_releaseAP": "取消出对帐单",
      "cancel_strikeCheck_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "chooseWindow": "选择窗口",
      "confirmStrike": "确认冲帐",
      "confirmStrike_confirm": "冲帐确认",
      "confirmStrike_confirm_text": "确认冲帐后此冲帐单将结案，请确认是否冲帐",
      "contact": "联络人",
      "contactWindow": "联络窗口资讯",
      "hd_drawer_title": "对帐资料主档",
      "hubqty": "入库数量",
      "inbatchno": "进料批号",
      "inqty": "移转数量",
      "itemNM": "窗口注记",
      "itemsum": "各项金额",
      "itemsumtax": "各项税额",
      "itemtotal": "应付含税金额",
      "message_chose_take_which_ap": "请选择要引用的对帐资料",
      "message_only_take_one_ap": "只可引用一张对帐单",
      "note": "冲帐注记",
      "opcode_2710": "採購進料",
      "opcode_2810": "",
      "pageHeader": "应付帐款管理",
      "pay": "应付帐款查询",
      "payT": "付款时间",
      "payment_placeholder": "请选择付款方式",
      "paytotal": "已冲帐金额",
      "paytype": "付款方式",
      "punit": "使用单位",
      "recon": "对帐资料",
      "releaseAP": "出对帐单",
      "releaseAP_confirm": "出对帐单确认",
      "releaseAP_confirm_text": "即将进入出应付单确认，是否储存应付单资料?",
      "strike": "付款冲帐",
      "strikeCheck": "冲帐覆核",
      "strikeCheck_confirm": "冲帐覆核确认",
      "strikeCheck_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "strikeSearch": "冲帐纪录查询",
      "strike_create_header": "新建冲帐单主档",
      "strike_del_hd_confirm_text": "请先确认要删除的冲帐单资料无误，再进行删除",
      "strike_edit_header": "",
      "strike_paytotal": "付款金额",
      "strike_sumtotal": "冲帐总金额",
      "strike_tb": "冲帐单明细",
      "strike_tb_delete_confirm": "冲帐明细删除确认",
      "strike_tb_delete_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "subtotal": "金额",
      "takeAp": "引用对帐单",
      "unitprice": "采购单价",
    },
    "gostock": {
      "4120_header": "验收主档",
      "4230_header": "",
      "N22": "请购单号",
      "N23": "采购单号",
      "batchdate": "批号日期",
      "batchno": "批号",
      "batchno_placeholder": "请输入批号",
      "idmtype": "过帐型态",
      "instockSearch": "进料纪录查询",
      "jobN": "来源单号",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "note": "注记",
      "opcode_4120": "验收单",
      "opcode_4230": "验退单",
      "pageHeader": "进料检验查询",
      "planqty": "移转数量",
      "proSearch": "采购纪录查询",
      "punit": "使用单位",
      "reqSearch": "请购纪录查询",
      "rtnqty": "退回数量",
      "stockinT": "移转时间",
      "tb": "验收明细",
      "total": "移转总量",
    },
    "instock": {
      " ": "请输入联络人名称",
      "IQCcheck_confirm": "进检抽样确认",
      "IQCcheck_confirm_text": "请先确认要进检抽样的资料无误，再进行进检抽样",
      "IQCfinish_confirm": "IQC完验确认",
      "IQCfinish_confirm_text": "请先确认要IQC完验的资料无误，再进行IQC完验",
      "IQCstart": "进检抽样",
      "QAQTYcheck": "进料明细[品保判退数量]+[品保放行数量]要等于[进料数量]",
      "QAcheck": "品保放行",
      "QAcheck_confirm": "品保放行确认",
      "QAcheck_confirm_text": "请先确认要品保放行的资料无误，再进行品保放行",
      "Tooltip_disable_take_balqty_0": "剩余数量为0不可引用",
      "balqty": "剩余数量",
      "cancel_IQCcheck_confirm_text": "请先确认要取消进检抽样的资料无误，再进行取消进检抽样",
      "cancel_IQCfinish_confirm_text": "请先确认要取消IQC完验的资料无误，再进行取消IQC完验",
      "cancel_QAcheck_confirm_text": "请先确认要取消品保放行的资料无误，再进行取消品保放行",
      "cancel_instockCheck_confirm_text": "请先确认要取消进料覆核的资料无误，再进行取消进料覆核",
      "contact": "联络人",
      "create_header": "新建进料主档",
      "dealN": "厂商单号",
      "del_hd_confirm_text": "请先确认要删除的进料单资料无误，再进行删除",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "edit_header": "编辑进料主档",
      "goqty": "验收数量",
      "gostock": "进料检验单",
      "hd_drawer_title": "进料单主档",
      "inbatchno": "进料批号",
      "indqty": "暂收数量",
      "inspectID": "进检人员",
      "inspectT": "完验时间",
      "instock": "进料单",
      "instockCheck_confirm": "进料覆核确认",
      "instockCheck_confirm_text": "请先确认要进料覆核的资料无误，再进行进料覆核",
      "judgeT": "品保判定日期",
      "lifeF_0": "发布",
      "lifeF_2": "进料覆核",
      "lifeF_3": "IQC抽验",
      "lifeF_4": "IQC完验",
      "lifeF_7": "品保覆核",
      "lifeF_T": "结案",
      "message_take_before_choose": "请先选择引用再输入引用数量",
      "message_take_same_detail": "不可引用相同明细",
      "message_transfer_small_than_0": "抛转数量不可小于0",
      "note": "进料注记",
      "opcode_2410": "国内进料",
      "opcode_2420": "国外进料",
      "opcode_2430": "委外进料",
      "opunit": "操作单位",
      "pageHeader": "进料作业",
      "punit": "进料单位",
      "qalID": "品判人员",
      "qalT": "放行时间",
      "rejectqty": "验退数量",
      "render": "选择",
      "sampleID": "抽样人员",
      "sampleT": "抽样日期",
      "search": "搜寻",
      "stockSearch": "验收纪录查询",
      "takeqty": "引用数量",
      "tb": "进料明细",
      "tel": "连络电话",
      "throwStock_message": "",
      "unit": "采购单位",
      "unitprice": "采购单价",
      "unitprice_placeholder": "请填写未税单价",
    },
    "plan": {
      "demandqty": "",
      "hd_delete_confirm_text": "",
      "pageHeader": "询价计画",
      "plan": "",
      "rec_drawer_title": "",
      "tb": "",
      "tb_drawer_title": "",
    },
    "pro": {
      "EDT": "预计交货日",
      "ReviewProcess": "审核流程(3)",
      "UploadSign": "上传回签",
      "adda": "条款注记",
      "adda_delete_confirm": "",
      "adda_delete_confirm_text": "请先确认要删除的条款资料无误，再进行删除",
      "addb": "加扣款项",
      "addb_delete_confirm_text": "请先确认要删除的款项资料无误，再进行删除",
      "addc": "预付作业",
      "addc_delete_confirm_text": "请先确认要删除的预付作业无误，再进行删除",
      "amount": "未税金额",
      "amount_placeholder": "请输入金额",
      "approveT": "核准日期",
      "cancel_proCheck": "取消覆核",
      "cancel_proCheck_confirm": "取消采购覆核确认",
      "cancel_proCheck_confirm_text": "请先确认要取消采购覆核的资料无误，再进行取消采购覆核",
      "cancel_suppCheck": "取消签回",
      "cancel_suppCheck_confirm": "取消厂商签回确认",
      "cancel_suppCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "cancelqty": "取消数量",
      "charge": "总加扣款项",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "confirmqty": "厂商确认数量",
      "contact": "厂商交货窗口",
      "contact_placeholder": "选择厂商交货窗口",
      "createID": "创建人员",
      "createT": "创建日期",
      "create_adda": "新增条款注记",
      "create_addb": "新增加扣款项",
      "create_header": "新建采购主档",
      "create_tb_title": "新增采购明细",
      "dealN": "厂商单号",
      "dealN_placeholder": "请输入厂商单号",
      "deal_history": "产品交易历史查询",
      "dealaddr": "厂商地址",
      "dealcontact": "厂商业务窗口",
      "dealcontact_placeholder": "选择厂商业务窗口",
      "del_hd_confirm_text": "请先确认要删除的采购订单资料无误，再进行删除",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "delete_confirm": "删除确认",
      "demandqty": "采购数量",
      "demandqty_placeholder": "请填写采购数量",
      "drawno": "图号",
      "driID": "负责采购",
      "edit_adda": "编辑条款注记",
      "edit_addb": "编辑加扣款项",
      "edit_header": "编辑采购主档",
      "edit_tb_title": "编辑采购明细",
      "end": "迄",
      "endEDT": "预计交货日(迄)",
      "exit_Disposal": "退出处置",
      "hd_close_confirm_text": "请先确认要结令的采购单资料无误，再进行强制结令",
      "hd_drawer_title": "采购订单主档",
      "instockSearch": "进料纪录查询",
      "itemsum": "未税单项金额",
      "itemsumtax": "单项税额",
      "lifeF_0": "发布",
      "lifeF_2": "采购覆核",
      "lifeF_7": "厂商签回",
      "lifeF_T": "结案",
      "local_subtotal": "本币含税总金额",
      "local_total": "含税总金额(交易币别)",
      "localprice": "本币单价",
      "message_transfer_small_than_0": "抛转数量不可小于0",
      "note": "采购注记",
      "note_placeholder": "请输入采购注记",
      "opcode_2310": "国内采购",
      "opcode_2320": "国外采购",
      "opcode_2330": "委外加工",
      "pageHeader": "采购作业",
      "payment": "付款方式",
      "payment_placeholder": "请选择付款方式",
      "pdtspec": "规格",
      "pro": "",
      "proCheck": "采购覆核(1)",
      "proCheck_confirm": "采购覆核确认",
      "proCheck_confirm_text": "请先确认要采购覆核的资料无误，再进行采购覆核",
      "proT": "采购日期",
      "punit": "采购单位",
      "reqSearch": "请购纪录查询",
      "returnqty": "退货数量",
      "sampleID": "抽样人员",
      "selectstatement": "请选择加扣款项",
      "signT": "签回日期",
      "start": "起",
      "startEDT": "预计交货日(起)",
      "statement": "项目说明",
      "statement_placeholder": "请输入项目说明",
      "subtotal": "含税小计",
      "sumtotal": "未税总金额",
      "suppCheck": "厂商签回(2)",
      "suppCheck_confirm": "厂商签回确认",
      "suppCheck_confirm_text": "",
      "supp_history": "厂商交易历史查询",
      "table_close": "强制结令",
      "table_delete": "删除",
      "table_edit": "编辑",
      "table_more": "查看更多",
      "table_suppCheckNum1": "",
      "tax": "总税额",
      "tb": "采购明细",
      "tb_delete_confirm": "采购明细删除确认",
      "tb_drawer_title": "采购订单明细",
      "tel": "电话",
      "tel_placeholder": "请选择联络人",
      "throwqty": "本次抛转数量",
      "total": "含税总金额",
      "totalEnd": "最大值",
      "totalStart": "最小值",
      "trade": "贸易条件",
      "trade_placeholder": "请选择贸易条件",
      "transferqty": "已抛转数量",
      "unitprice": "采购单价",
      "unitprice_placeholder": "请填写未税单价",
      "updtID": "更新人员",
      "updtT": "",
      "window": "窗口",
    },
    "report": {
      "Amount_Of_Income": "进货金额",
      "Amount_Of_Net_Income": "进货净额",
      "Amount_Of_Return": "退货金额",
      "Average_Cost": "平均成本",
      "Cost": "成本",
      "Cost_Of_Adjust": "本期调整成本",
      "Cost_Of_Beginning": "期初成本",
      "Cost_Of_Ending": "期末成本",
      "Cost_Of_Income": "本期进货成本",
      "Cost_Of_Sale": "本期销货成本",
      "Delivery_Quantity": "已交量",
      "Incoming_Quantity": "已进货",
      "Non_Delivery_Quantity": "未交量",
      "Non_Incoming_Quantity": "未进货",
      "Quantity_Of_Adjust": "本期调整数量",
      "Quantity_Of_Beginning": "期初数量",
      "Quantity_Of_Change": "异动数量",
      "Quantity_Of_Ending": "期末数量",
      "Quantity_Of_Income": "进货数量",
      "Quantity_Of_Net_Income": "进货净量",
      "Quantity_Of_Return": "退货数量",
      "Quantity_Of_Sale": "本期销货数量",
      "Status": "进货状态",
      "Stock_Quantity": "库存数量",
      "Storage_Cost": "库存成本",
      "ThisMonth": "当月",
      "ThisWeek ": "当周",
      "Today": "当日",
      "Unit_Of_Cost": "单位成本",
    },
    "req": {
      "EDT": "预计交货日",
      "N": "单号",
      "N_placeholder": "请填写单号",
      "PN": "料号",
      "PN_placeholder": "请填写料号",
      "UploadSignature": "上传签呈",
      "accord": "据此",
      "advanceSearch": "进阶查询",
      "audit_confirm": "审查签核确认",
      "audit_confirm_text": "审查签核后此请购单将无法编辑，确定要完成审查吗",
      "audit_finish": "已完成审查",
      "audit_okText": "确定审查",
      "audit_start": "审核正式送签",
      "business_tax": "营业税",
      "cancel": "取消",
      "cancel_check_confirm": "取消请购覆核确认",
      "cancel_check_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "cancel_reqCheck": "取消请购覆核",
      "check_confirm": "请购覆核确认",
      "check_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "create": "创建",
      "createByThis": "据此创建",
      "createID": "创建人员",
      "createT": "创建日期",
      "create_header": "新建请购主档",
      "create_tb_title": "新增请购明细",
      "currencytype": "交易币别",
      "currencytype_placeholder": "请选择币别",
      "date_placeholder": "请选择日期",
      "del_hd_confirm_text": "请先确认要删除的请购单资料无误，再进行删除",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "demandDate": "需求日期",
      "demandqty": "请购数量",
      "demandqty_placeholder": "请填写请购数量",
      "driID": "负责请购",
      "driID_placeholder": "请选择请购人员",
      "duty_free": "免税",
      "edit": "编辑",
      "edit_header": "编辑请购主档",
      "edit_tb_title": "编辑请购明细",
      "exchangeD": "汇率日期",
      "exchangeD_placeholder": "请输入汇率",
      "exchangerate": "汇率",
      "exportExcel": "汇出Excel",
      "get_nextData_suc": "取得下一笔资料成功",
      "get_prevData_suc": "取得上一笔资料成功",
      "hd_note": "请购注记",
      "header_cancel_create": "取消据此创建",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "itemno": "项序",
      "itemno_total": "总项数",
      "itemsum": "单项金额(未税)",
      "itemsumtax": "单项税额",
      "itemtotal": "未税总金额",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "lifeF_0": "发布",
      "lifeF_2": "请购覆核",
      "lifeF_7": "审核送签完成",
      "lifeF_T": "结案",
      "lifeF_placeholder": "请选择历程",
      "local_subtotal": "本币含税总金额",
      "localprice": "本币单价",
      "noData": "无此资料",
      "opcode": "单别",
      "opcode_2210": "国内请购",
      "opcode_2220": "国外请购",
      "opcode_2230": "委外申请",
      "opcode_placeholder": "请选择单别",
      "opunit": "操作单位",
      "pageHeader": "请购作业",
      "pbom": "组合数量",
      "pdtNM": "品名",
      "plan": "询价单",
      "planSearch": "询价纪录查询",
      "pro": "采购单",
      "proSearch": "采购纪录查询",
      "punit": "请购单位",
      "remove": "删除",
      "removeSearch": "清除搜寻纪录",
      "req": "请购单",
      "reqCheck": "请购单覆核",
      "reqCheck_lifeF_0_okText": "确定覆核",
      "reqCheck_lifeF_2_okText": "取消覆核",
      "searchField": "搜寻栏位",
      "search_placeholder": "请输入搜寻文字",
      "setAdvanced": "设定进阶筛选条件",
      "startEDT": "预计交货日期",
      "submit": "确定",
      "subtotal": "单项含税小计",
      "suppID": "厂商编号",
      "suppID_placeholder": "请选择厂商编号",
      "suppNM": "厂商名称",
      "suppalias": "厂商简称",
      "sysMsg": "系统讯息",
      "table_operate": "操作",
      "take": "引用",
      "tax": "总税额",
      "taxrate": "税率",
      "taxrate_placeholder": "请输入税率",
      "taxtype": "交易税别",
      "taxtype_placeholder": "请选择税别",
      "tb_delete_confirm": "请购明细删除确认",
      "tb_itemsum": "单项金额",
      "tb_note": "注记",
      "tb_note_placeholder": "请填写注记",
      "tb_subtotal": "含税小计",
      "tb_title": "请购明细",
      "throw": "抛转",
      "throw_modal_submit": "确定抛转【采购单】",
      "total": "含税总金额(交易币别)",
      "unit": "计量单位",
      "unitprice": "请购单价(未税)",
      "unitprice_placeholder": "请填写未税单价",
      "utype": "单位类别",
      "utype_placeholder": "",
      "vat": "增值税",
      "zero_tax_rate": "零税率",
    },
    "return": {
      "N23": "采购单号",
      "N24": "进料单号",
      "addr": "联络地址",
      "contact": "联络人",
      "create_header": "新建进料退出主档",
      "del_hd_confirm_text": "请先确认要删除的进料退出单资料无误，再进行删除",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "edit_header": "编辑进料退出主档",
      "inbatchno": "进料批号",
      "itemNM": "窗口注记",
      "itemNM_placeholder": "请输入窗口注记",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "message_at_least_chose_one": "请至少选择一笔验退单明细",
      "note": "进退注记",
      "opcode_2610": "库房退出",
      "opcode_2620": "品保退出",
      "outN": "验退单号",
      "pageHeader": "进料退出作业",
      "planqty": "验退数量",
      "punit": "使用单位",
      "return": "进料退出",
      "rtnqty": "退回数量",
      "tb": "进料退出明细",
      "tel": "联络电话",
    },
    "util": {
      "N22": "请购单号",
      "N23": "采购单号",
      "N24": "进料单号",
      "N27": "对帐单号",
      "N28": "冲帐单号",
      "ap": "对帐单",
      "apSearch": "对帐资料查询",
      "drawno": "图号",
      "gostock": "进料检验单",
      "instock": "进料单",
      "instockSearch": "进料纪录查询",
      "localprice": "本币单价",
      "opcode_2010": "询价计画",
      "opcode_2210": "国内请购",
      "opcode_2220": "国外请购",
      "opcode_2230": "委外申請",
      "opcode_2310": "国内采购",
      "opcode_2320": "国外采购",
      "opcode_2330": "委外加工",
      "opcode_2410": "国内进料",
      "opcode_2420": "国外进料",
      "opcode_2430": "委外进料",
      "opcode_2610": "库房退出",
      "opcode_2620": "品保退出",
      "opcode_2710": "采购进料",
      "opcode_2810": "应付冲帐",
      "opcode_4120": "验收单",
      "opcode_4230": "验退单",
      "outN": "验退单号",
      "pay": "应付帐款查询",
      "plan": "询价单",
      "planSearch": "请购纪录查询",
      "pro": "采购单",
      "proSearch": "采购纪录查询",
      "req": "请购单",
      "reqSearch": "请购纪录查询",
      "return": "进料退出",
      "stockSearch": "验收纪录查询",
      "strike": "付款冲帐",
      "strikeSearch": "冲帐纪录查询",
      "unitprice": "采购单价",
    },
  },
  "WOS": {
    "error": {
      "addAtLeastOne": "",
      "batchQtyExceedRemainingQty": "",
      "batchnoQtyLessZero": "",
      "chooseBatchno": "",
      "countLessZero": "",
      "currentHistoryRequiresManualOperation": "",
      "duplicateBatchno": "",
      "exceedAvailable_qty": "",
      "mcLoginFirst": "",
      "openqtyCannotExceedTheOpenqty": "",
      "productEmpty": "",
      "shiftEmpty": "",
    },
    "login": { "pageTitle": "登入帐户" },
    "mc": {
      "mcCheck": "",
      "mcCheckContent": "",
      "ngitemNM": "",
      "noProduct": "",
      "okProduct": "",
      "okitemNM": "",
      "pageTitle": "物控作业",
      "registerNgitem": "",
      "registerOkitem": "",
    },
    "parts-binding": { "pageTitle": "部品扫码" },
    "pis": {
      "": "",
      "PN": "",
      "PQC": "",
      "checkIn": "",
      "custom_text_001": "",
      "custom_text_002": "",
      "d_planqty": "",
      "goH": "",
      "goT": "",
      "good": "",
      "hdC": "",
      "in": "",
      "inProduction": "",
      "loginID": "",
      "loginT": "",
      "machine_ID": "",
      "merge_in": "",
      "merge_out": "",
      "mhC_M": "",
      "mhC_S": "",
      "moldspec": "",
      "moveIn": "",
      "ng": "",
      "ngC": "",
      "offline_current_station": "",
      "okC": "",
      "output": "",
      "outsourced_finished_qty": "",
      "outsourced_qty": "",
      "peopleNM": "",
      "plan": "",
      "planqty": "",
      "prefixplanqty": "",
      "process_progress": "",
      "pwcID": "",
      "pwcNM": "",
      "status_code": "",
      "stdplanqty": "",
      "supp": "",
      "tab_inpdt": "",
      "tab_won": "",
      "unin": "",
      "whC_M": "",
      "whC_S": "",
      "wo_status_code": "",
      "workSN": "",
      "work_report_number": "",
      "work_report_type": "",
      "wsNM": "",
    },
    "ppidp": { "pageTitle": "成品绑定" },
    "qc": { "qc_complete": "", "qc_log": "", "qc_total_duration": "" },
    "tk": { "hdC": "", "planHead": "", "time_slot": "" },
    "util": {
      "AddNg": "",
      "AddOk": "",
      "Input": "",
      "NotWorkOrderScanning": "",
      "PPE": "",
      "QCResume": "",
      "QCTiming": "",
      "QTY": "",
      "SIPPreview": "",
      "SOPBrowsing": "",
      "TAR": "",
      "Waybill": "",
      "WpcCode": "",
      "abnDri": "",
      "abnSN": "",
      "abnhd": "",
      "accumulation": "",
      "addSN": "",
      "after": "",
      "alarm": "",
      "available_qty": "",
      "bal": "",
      "barCode": "",
      "barCodeParsingFailure": "",
      "barcodeCommands": "",
      "batch": "",
      "batchC": "",
      "batchID": "",
      "batchRemittance": "",
      "batchbom": "",
      "box": "",
      "checkWoNFirst": "",
      "checklist": "",
      "checkrRgister": "",
      "closeT": "",
      "closeToSlc": "",
      "complete": "",
      "correctly": "",
      "d_qty": "",
      "equipment": "",
      "estimatedCompletion": "",
      "focusText": "",
      "goal": "",
      "hourC": "",
      "idkw": "",
      "inC": "",
      "index": "",
      "isTheOrderLogged": "",
      "lifeF_0": "",
      "lifeF_1": "",
      "lifeF_2": "",
      "lifeF_3": "",
      "lifeF_4": "",
      "lifeF_5": "",
      "lifeF_7": "",
      "lifeF_B": "",
      "lifeF_G": "",
      "lifeF_L": "",
      "lifeF_P": "",
      "line_dispach_manage": "",
      "linemix": "",
      "linemix_0": "",
      "linemix_1": "",
      "linemix_2": "",
      "manualEntry": "",
      "mc": "",
      "nextSN": "",
      "ngC": "",
      "ngRate": "",
      "noBarcode": "",
      "noWl": "",
      "none": "",
      "notSwitchable": "",
      "officers": "",
      "offline": "",
      "okC": "",
      "okRate": "",
      "pallet": "",
      "pdtRate": "",
      "pfID": "",
      "pis": "",
      "planqty": "",
      "please": "",
      "preSN": "",
      "prefix": "",
      "prefixWork": "",
      "preparation": "",
      "preview": "",
      "produce": "",
      "productPrepar": "",
      "productTimer": "",
      "production": "",
      "pwcID": "",
      "qc": "",
      "qc_quarantine": "",
      "qc_waiver": "",
      "qty": "",
      "qualityControl": "",
      "rationale": "",
      "remaining": "",
      "reportingTime": "",
      "rptID": "",
      "rptMsg": "",
      "rptTime": "",
      "rptUnit": "",
      "rptUser": "",
      "rptitemMsg": "",
      "rptitemNM": "",
      "sampleID": "",
      "sampleSize": "",
      "sampleT": "",
      "shift": "",
      "shift_D": "",
      "shift_N": "",
      "shutdown": "",
      "situF": "",
      "situF_light_R": "",
      "situF_light_Y": "",
      "slcT": "",
      "sqc": "",
      "start": "",
      "suffix": "",
      "suffixWork": "",
      "tag": "",
      "ticker_dashboard": "",
      "time": "",
      "tk": "",
      "total_bal": "",
      "total_inC": "",
      "total_ngC": "",
      "total_okC": "",
      "total_woN": "",
      "typeInspection": "",
      "verified": "",
      "whether": "",
      "withdraw": "",
      "wlwoc": "",
      "work": "",
      "workOrder": "",
      "wotype": "",
      "wotype_0": "",
      "wotype_1": "",
      "wpc": "",
      "wpcC": "",
      "wpcList": "",
      "wpcPrinter": "",
      "wpcSN": "",
      "wpcSearch": "",
      "wpcset": "",
    },
    "wlwoc": {
      "": "",
      "AQL": "",
      "BMS": "",
      "CL": "",
      "INSP": "",
      "LCL": "",
      "LSL": "",
      "Pause": "",
      "PleaseMakeSureTheProductionProcedureIsCorrectFirst": "",
      "RINSP": "",
      "Resume": "",
      "TINSP": "",
      "UCL": "",
      "UOM": "",
      "USL": "",
      "WPCLogout": "",
      "We": "",
      "WorkTimeManagement": "",
      "WpcLogin": "",
      "abcDismiss": "",
      "abcHandle": "",
      "abcReport": "",
      "action": "",
      "add": "",
      "allLine": "",
      "apsWorkOrder": "",
      "artificialWorkOrder": "",
      "barCodeInstruction": "",
      "barcodeCtrl": "",
      "barcodeInstructionErrorMessage": "",
      "barcodeParsingFailure": "",
      "batchID": "",
      "bdmbatchbom": "",
      "bulk": "",
      "clock_in": "",
      "clock_in_staff": "",
      "clock_out": "",
      "clock_out_staff": "",
      "continuing": "",
      "count": "",
      "create_batchno": "",
      "detachable": "",
      "detail": "",
      "document_code": "",
      "drawarea": "",
      "drawno": "",
      "dueday": "",
      "feeding": "",
      "inspN": "",
      "insp_record": "",
      "insp_standard": "",
      "inspitem": "",
      "inspkeynote": "",
      "insptype": "",
      "instructionConfirmTitle": "",
      "isBillTheWoN": "",
      "isRegisterTheWoN": "",
      "isSplitWpc": "",
      "issueday": "",
      "itemtype": "",
      "light": "",
      "mc_login": "",
      "measure": "",
      "merge": "",
      "mergeBatch": "",
      "merge_batchno": "",
      "multiUpStaff": "",
      "noBarcode": "",
      "noData": "",
      "noWoNScan": "",
      "normal": "",
      "oem": "",
      "oem_code": "",
      "oem_print": "",
      "oemitemNM": "",
      "ok_count": "",
      "online_sampling": "",
      "openqty": "",
      "output": "",
      "peopleNM": "",
      "planhead": "",
      "pleaseClickThisInputBoxFirst": "",
      "pleaseScanBarCode": "",
      "produce_after_lifeF_B": "",
      "qms": "",
      "realvalue": "",
      "record": "",
      "retake": "",
      "rptUser": "",
      "sampleT": "",
      "scanBarCode": "",
      "secure": "",
      "sepcial": "",
      "sipcond": "",
      "split": "",
      "splitWoN": "",
      "split_batchno": "",
      "splitted": "",
      "staff_now": "",
      "staff_on": "",
      "statement": "",
      "stdmh": "",
      "stdwh": "",
      "stop": "",
      "submit": "",
      "suppID": "",
      "thisLine": "",
      "waybillWithdraw": "",
      "whcDay": "",
    },
    "wpc-printer": {
      "": "",
      "AIPN": "",
      "EmbryoModel": "",
      "T4inStove": "",
      "T4outStove": "",
      "T6inStove": "",
      "T6outStove": "",
      "all": "",
      "batchID": "",
      "batchbom": "",
      "bdmbatchbom": "",
      "carNumber": "",
      "cust": "",
      "finishDay": "",
      "forgingModel": "",
      "heatCage": "",
      "jobNumber": "",
      "note": "",
      "order": "",
      "pickUpBom": "",
      "planqty": "",
      "previewPrint": "",
      "process": "",
      "rest": "",
      "shippingDate": "",
      "sign": "",
      "standardProcess": "",
      "version": "",
      "whole": "",
      "wotype": "",
      "wpcCard": "",
      "wpcPrint": "",
    },
    "wpc-search": { "pageTitle": "途单查询" },
  },
  "error": { "error-code": { "403": "", "404": "", "409": "", "500": "" } },
  "util": {
    "util": {
      "": "",
      "EDT": "预计交货日",
      "N": "单号",
      "PN": "料号",
      "SN": "序",
      "UploadSign": "上传回签",
      "UploadSignature": "上传签呈",
      "accord": "据此",
      "action": "操作",
      "add": "新增",
      "advanceSearch": "进阶查询",
      "all": "全部",
      "approveT": "核准日期",
      "assetkind": "资产分类",
      "assetkind_TD": "压铸",
      "assetkind_TF": "锻造",
      "assetkind_TI": "射出",
      "assetkind_TS": "冲压",
      "attribute": "属性",
      "attribute_0": "0 原料",
      "attribute_1": "1 物料",
      "attribute_2": "2 非标零件",
      "attribute_3": "3 标准零件",
      "attribute_4": "4 半成品",
      "attribute_5": "5 制成品",
      "attribute_6": "6 客供品",
      "attribute_7": "维修备品",
      "attribute_8": "管理财产",
      "attribute_9": "列管资产",
      "balqty": "剩余数量",
      "batchdate": "批号日期",
      "batchno": "批号",
      "cancel": "取消",
      "cancel_Check": "取消签回",
      "category": "",
      "check": "覆核",
      "close": "关闭",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "contact": "联络人",
      "create": "创建",
      "createID": "创建人员",
      "createT": "创建日期",
      "currencytype": "币别",
      "custID": "客户ID",
      "custNM": "客户名称",
      "custPN": "客户料号",
      "custalias": "客户简称",
      "d_inC": "",
      "d_planqty": "",
      "data": "资料",
      "dealPN": "交易料号",
      "del_confirm": "删除确认",
      "del_confirm_msg": "请先确认资料无误，再删除",
      "delete": "删除",
      "dept": "部门",
      "detail": "详情",
      "dqty": "条码扫入",
      "drawno": "工程图号",
      "edit": "编辑",
      "edit_detect_msg1": "系统侦测到您编辑了以下栏位",
      "edit_detect_msg2": "请先储存系统资料再编辑明细",
      "end": "迄",
      "endEDT": "预计交货日(迄)",
      "exchangeD": "汇率日期",
      "exchangerate": "汇率",
      "excluding_tax": "(未税)",
      "exportExcel": "汇出Excel",
      "fileType_DRAW": "DWG_工程图档",
      "fileType_ECN": "ECN_工程变更通知",
      "fileType_PACK": "PAK_包装作业标准",
      "fileType_PIC": "PIC_照片指导",
      "fileType_SIP": "SIP_检验作业标准",
      "fileType_SOP": "SOP_生产作业标准",
      "get_next_data_err": "取得下一笔资料失败",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_prev_data_suc": "取得上一笔资料成功",
      "header_cancel_create": "取消据此创建",
      "header_create": "据此创建",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "heirPN": "继承料号",
      "heirdealID": "继承对象",
      "hide_detail": "隐藏明细档",
      "idmtype": "過帳型態",
      "idmtype_d": "条码过帐",
      "idmtype_m": "混合过帐",
      "idmtype_s": "人工过帐",
      "inC": "产出数量",
      "inqty": "生产数量",
      "isfocus": "关注",
      "isvalid": "有效",
      "itemID": "项目ID",
      "itemNM": "名称",
      "itemno": "项序",
      "itemno_total": "总项数",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "lineMix": "",
      "linemix": "混线",
      "linemix_0": "不混线",
      "linemix_1": "右",
      "linemix_2": "左",
      "matl": "原料材质",
      "mgmtcat": "授权分类",
      "mold": "",
      "moldC": "合模次数",
      "moldID": "模具ID",
      "moldcavity": "模具穴数",
      "msg_chose_atleast_one_Detail": "请至少选择一笔明细",
      "msg_chose_data": "请选择要引用的资料",
      "msg_chose_whichType_toThrow": "请选择要抛转的单别",
      "mtype": "机种",
      "name": "名称",
      "nav_BDM": "物料资料管理",
      "nav_EDM": "生产设备管理",
      "nav_OMS": "销货管理系统",
      "nav_PMS": "采购管理系统",
      "nav_SDM": "系统资料管理",
      "nav_TDM": "厂区资料管理",
      "nav_WMS": "库存管理系统",
      "nav_bdm_edm": "技术资料维护",
      "nav_bdm_pn-manage": "基本资料维护",
      "nav_edm_logistics-equipment_AGV": "物流设备_AGV",
      "nav_edm_measurement-equipment": "量测设备",
      "nav_edm_mold": "模具资料",
      "nav_edm_plant-maintenance": "厂房维护",
      "nav_edm_production-equipment": "生产设备",
      "nav_edm_transportation-equipment": "交通设备",
      "nav_oms_ap-manage": "应收帐款管理",
      "nav_oms_check": "销货检验查询",
      "nav_oms_fod": "预订作业",
      "nav_oms_ord": "销货作业",
      "nav_oms_outstock": "发货作业",
      "nav_oms_quo": "报价作业",
      "nav_oms_report": "报表查询 ",
      "nav_oms_report-salelist": "销退货明细表",
      "nav_oms_report-salesummary": "销退货汇总表",
      "nav_oms_report-unshipped": "订单未出明细表",
      "nav_oms_return": "销货退回作业",
      "nav_pds_abc-board": "异常管理看板",
      "nav_pds_dashboard": "数位生产看板",
      "nav_pds_overview-lines": "线体稼动总表",
      "nav_pms_ap-manage": "应付帐款管理",
      "nav_pms_gostock": "进料检验查询",
      "nav_pms_instock": "进料作业",
      "nav_pms_plan": "询价计画",
      "nav_pms_pro": "采购作业",
      "nav_pms_report": "报表查询",
      "nav_pms_report-diff": "采购价格调幅表",
      "nav_pms_report-instocksummary": "库存汇总表",
      "nav_pms_report-inventorydiff": "库存异动明细帐",
      "nav_pms_report-psisummary": "进销存汇总表",
      "nav_pms_report-purchaselist": "进退货明细表",
      "nav_pms_report-purchasestatistics": "进退货统计表",
      "nav_pms_report-purchasesummary": "进退货汇总表",
      "nav_pms_report-unpurchase": "采购未进货明细表",
      "nav_pms_req": "请购作业",
      "nav_pms_return": "进料退出作业",
      "nav_sdm_category-manage": "查询分类设定",
      "nav_sdm_client": "客户资料维护",
      "nav_sdm_company": "厂商资料维护",
      "nav_sdm_data-auth-manage": "功能授权设定",
      "nav_sdm_function-params-manage": "系统参数设定",
      "nav_sdm_trading-pattern": "项目字串设定",
      "nav_sdm_user-manage": "员工资料维护",
      "nav_tdm_plant-manage": "厂别主档设定",
      "nav_tdm_plant-params-manage": "厂别参数设定",
      "nav_tdm_procedure-manage": "制程规划维护",
      "nav_tdm_production-line-manage": "产线规划维护",
      "nav_tdm_string-manage": "功能字串管理",
      "nav_tdm_warehouse-manage": "库房规划维护",
      "nav_wms_inv-history": "每月库存纪录",
      "nav_wms_inventory-inquiry": "现有库存查询",
      "nav_wms_plan-in": "库房入库作业",
      "nav_wms_plan-out": "库房出库作业",
      "nav_wms_pro-instock": "代理入库作业",
      "nav_wms_pro-outstock": "代理出库作业",
      "nav_wms_stock-search": "库存状况查询",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "ngC": "不良数量",
      "no": "否",
      "no_data": "无此资料",
      "note": "注记",
      "offfix": "",
      "ok": "确认",
      "okC": "良品数量",
      "opcode": "单别",
      "opunit": "操作单位",
      "or": "或",
      "order": "单",
      "packtype_0": "操作包装(0)_操作单位使用",
      "packtype_1": "出货包装(1)_打包出货使用",
      "packtype_2": "周转包装(2)_产线周转使用",
      "pbom": "组合数量",
      "pdtNM": "品名",
      "pdtclass": "商品",
      "pdtclass_A": "附件",
      "pdtclass_M": "主件",
      "pdtclass_P": "包材",
      "pdtclass_X": "N/A",
      "pdtspec": "规格",
      "placeholder_input": "请输入",
      "placeholder_select": "请选择",
      "planqty": "计画数量",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "punit": "使用单位",
      "qty": "组数",
      "querycat": "查询分类",
      "realtqty": "",
      "register": "",
      "removeSearch": "清除搜寻纪录",
      "render": "选择",
      "result": "",
      "sampleSize": "",
      "samplingT": "",
      "search": "查询",
      "searchPN": "搜寻料号",
      "setAdvanced": "设定进阶筛选条件",
      "setting": "设定",
      "showTotal": "共 {{total}} 条 ， 当前显示 {{rangeFirst}} - {{rangeEnd}} 条",
      "signT": "签回日期",
      "sign_out": "登出",
      "situF": "状态",
      "size_big": "特大",
      "size_large": "大尺寸",
      "size_middle": "中尺寸",
      "size_small": "小尺寸",
      "sqty": "人工输入",
      "start": "起",
      "startEDT": "预计交货日(起)",
      "stockinT": "移转时间",
      "subtotal": "含税小计",
      "success": "成功",
      "suppID": "厂商ID",
      "suppNM": "厂商名称",
      "suppalias": "厂商简称",
      "sysMsg": "系统讯息",
      "table_close": "强制结令",
      "table_more": "查看更多",
      "take": "引用",
      "tax": "总税额",
      "taxrate": "税率",
      "taxtype": "税别",
      "tel": "联络电话",
      "throw": "抛转",
      "total": "总数",
      "tqty": "当前总数",
      "type": "",
      "unit": "计量单位",
      "unitwt": "计数单重",
      "updtID": "更新人员",
      "updtT": "更新时间",
      "utype": "单位类别",
      "ver": "版码",
      "whID": "仓别",
      "window": "窗口",
      "wl": "工线",
      "wlID": "",
      "wo": "",
      "woMix": "",
      "woN": "工单号",
      "ws": "工站",
      "yes": "是",
    },
  },
};

export default zh_CN;
