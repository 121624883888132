/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Input, Divider, message, Radio, Select } from "antd";
import { addPause, updatePause } from "@/service/apis/ADM/StringManage";
import useAPI from "@/hooks/useAPI";


const ModalContainer = ({ s_editData, set_s_isShowModal, call_getStation, pfArr }) => {
  const [s_wsOptions, set_s_wsOptions] = useState([])
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);
  // useAPI 新增停工項目
  const call_addPause = useAPI(addPause);
  // useAPI 編輯停工項目
  const call_updatePause = useAPI(updatePause);


  //工站下拉選單
  const options = call_getStation?.data?.reduce(
    (prev, curr) => {
      const option = {
        label: `${curr.wsID}_${curr.wsNM}`,
        value: curr.wsID,
        pfID: curr.pfID,
        key: curr.wsID
      };
      prev.push(option);
      return prev;
    }, [])

  //根據傳入的pfID陣列 去做新的工站下拉選單，該工站有該pfID才可出現在下拉選單中
  const findpfID = (arr) => {
    let tmpArr = []
    for (let index = 0; index < arr?.length; index++) {
      if (f_pfID?.includes("All") && arr.length === 1) { return }

      let tmp = options.filter(item => item.pfID === arr[index] || item.pfID.includes(arr[index]))
      tmpArr.push(...tmp)
    }
    const uniqueArr = [...new Set(tmpArr)]; //E跟G 共用工站，所以要將重複工站去除
    return uniqueArr;
  }

  useEffect(() => {
    console.log("f_pfID == ", f_pfID)
    set_s_wsOptions(findpfID(f_pfID))
  }, [f_pfID])

  useEffect(()=>{
    console.log("s_wsOptions = >",s_wsOptions);
  },[s_wsOptions])

  const onSubmit = (values) => {

    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    if (values.wsID && values.wsID.length > 0) {
      values.wsID = values.wsID?.join("|") ?? "";
    }
    if (values.pfID && values.pfID.length > 0) {
      values.pfID = values.pfID?.join("|") ?? "";
    }
    if (values.pfID && values.pfID.length === 0) {
      values.pfID = "";
    }
    if (values.wsID && values.wsID.length === 0) {
      values.wsID = "";
    }
    if (type === "create") {
      call_addPause.request(values);
    }
    if (type === "edit") {
      call_updatePause.request(values);
    }
  };

  // useAPI 新增停工項目
  useEffect(() => {
    if (call_addPause.status === "suc") {
      message.success(call_addPause.msg);
      set_s_isShowModal(false);
    } else if (call_addPause.status === "err") {
      message.error(t(`error.error-code.${call_addPause.msg}`));
    }
  }, [call_addPause.status]);

  // useAPI 編輯停工項目
  useEffect(() => {
    if (call_updatePause.status === "suc") {
      message.success(call_updatePause.msg);
      set_s_isShowModal(false);
    } else if (call_updatePause.status === "err") {
      message.error(t(`error.error-code.${call_updatePause.msg}`));
    }
  }, [call_updatePause.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        pfID: s_editData.pfID ?? [],
        ...s_editData,

      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("util.util.itemID")} // 項目ID
            name="itemID"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("util.util.placeholder_input")}${t("util.util.itemID")}`}
              disabled={Object.keys(s_editData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("util.util.itemNM")} // 項目名稱
            name="itemNM"
            rules={[{ required: true }]}
          >
            <Input placeholder={`${t("util.util.placeholder_input")}${t("util.util.itemNM")}`} />
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            label={t("util.util.isvalid")} // 是否有效
            name="isvalid"
          >
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("ADM.util.pfID")} // 製程ID
            name="pfID"
          >
            <Select
              mode="multiple"
              onChange={() => {
                form.setFieldsValue({ wsID: [] });
              }}
              options={
                pfArr?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>


        <Col span={8}>
          <Form.Item
            label={t("ADM.mold.wsID")} // 工站名稱
            name="wsID"
          >
            <Select
              disabled={f_pfID?.length === 0 || f_pfID?.includes("All") && f_pfID.length === 1}
              mode="multiple"
              placeholder={`${t("util.util.placeholder_select")}${t("ADM.util.pfID")}`}
              options={s_wsOptions?.map((item) => {
                return { value: item.value, label: item.label, pfID: item.pfID }
              })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("util.util.note")} // 註記
            name="note"
          // rules={[{ required: true }]}
          >
            <Input placeholder={`${t("util.util.placeholder_input")}${t("util.util.note")}`} />
          </Form.Item>
        </Col>

        <Divider className="m-0" />

        <Col span={24} className="flex justify-end gap-3">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
