/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Space, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { deleteOpHd, getOpHd } from "@/service/apis/ADM/optype";
import { PlusOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { DataContext } from "../index";
import ModalContainer from "./ModalContainer";

message.config({
  duration: 1.5,
});

const HD = () => {
  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog
  const [s_editData, set_s_editData] = useState({}); // 要編輯的資料
  const [scrollY, setScrollY] = useState("");

  const call_getOpHd = useAPI(getOpHd);
  const call_deleteOpHd = useAPI(deleteOpHd);

  let countRef = useRef(null);

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  // 新增作業科目管理
  const createHD = () => {
    set_s_showModal(true);
  };

  // 編輯作業科目管理
  const editOptypeHd = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  // 刪除作業科目管理
  const deleteOptypeHd = (rowData) => {
    Modal.confirm({
      title: "作業科目管理刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的作業科目管理無誤，再進行刪除",
      okText: "確定",
      onOk: async () => {
        call_deleteOpHd.request(rowData);

        // 刪除後要清空表身
        set_c_lightData({});
      },
      cancelText: "取消",
    });
  };

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64;
    }
    let tHeader = null;
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0];
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0];
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0;
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom;
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(c_lightData).length > 0 ? "50vh" : "100vh";

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`;
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName("ant-table-placeholder")[0];
      if (placeholder) {
        placeholder.style.height = height;
        placeholder.style.display = "flex";
        placeholder.style.alignItems = "center";
        placeholder.style.justifyContent = "center";
      }
    }
    console.log("height = ", height);
    return height;
  };

  // 取得所有製程標準管理資料
  useEffect(() => {
    call_getOpHd.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getOpHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(data || []);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getOpHd.status]);

  useEffect(() => {
    const { status, msg } = call_deleteOpHd;
    if (status === "suc") {
      message.success(msg);
      call_getOpHd.request();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteOpHd.status]);

  // 表身有資料時計算高度
  useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if (innerWidth >= 1440) {
      // alert(innerWidth)
      // 沒有表身資料
      if (Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } else {
        setScrollY(ROWHEIGHT * 6);
      }
    }

    // 一般螢幕尺寸
    if (innerWidth >= 1920) {
      // 沒有表身資料
      if (Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } else {
        setScrollY(ROWHEIGHT * 6);
      }
    }
  }, [c_lightData]);

  const tableColumns = [
    {
      title: "作業類別",
      dataIndex: "optype",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: "類別說明",
      dataIndex: "typeNM",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${350}px`,
    },
    {
      title: "營運功能",
      dataIndex: "opfunction",
      align: "center",
      // width: innerWidth <= 1440 ? `${120}px` : `${350}px`,
      render: (text) => {
        const map = {
          "1": "訂單系統",
          "2": "採購系統",
          "3": "生產系統",
          "4": "庫儲系統",
          "5": "財務系統",
        };
        return map[text];
      },
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      // width: innerWidth <= 1440 ? `${150}px` : `${450}px`,
    },
    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      width: "150px",
      render: (text, rowData, index) => (
        <Space>
          <IconPen
            onClick={(e) => {
              editOptypeHd(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              deleteOptypeHd(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="作業科目管理"
        extra={
          <Button
            onClick={createHD}
            type="primary"
            style={{ borderRadius: "5px" }}
            icon={<PlusOutlined />}
            size="large"
          >
            新增
          </Button>
        }
      />
      <div ref={countRef}>
        <CustomTable
          // size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
          scroll={{ y: scrollY }}
          columns={tableColumns}
          dataSource={s_tableData.map((item, index) => {
            return {
              key: index,
              ...item,
            };
          })}
          rowClassName={(record) => (record.optype === c_lightData.optype ? "clickRowStyle" : "")}
          onRow={(record) => {
            return {
              onClick: () =>
                record.optype === c_lightData.optype
                  ? set_c_lightData({})
                  : set_c_lightData(record),
            };
          }}
        />
      </div>

      <CustomModal
        width="60%"
        title={Object.keys(s_editData).length > 0 ? "編輯作業科目資料" : "新建作業科目資料"}
        visible={s_showModal}
        maskClosable={false}
        destroyOnClose
        footer={null}
        forceRender
        getContainer={false}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getOpHd.request();
          // call_getAllStandard();
        }}
      >
        {s_showModal ? (
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            // call_getAllStandard={call_getAllStandard}
          />
        ) : null}
      </CustomModal>
    </>
  );
};

export default HD;
