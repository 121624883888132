import styled from "styled-components";
import { theme } from "antd";
import TransLight from "./TransLight";

const { useToken } = theme;

const Light = ({ rowData }) => {
  const { woN = "", lifeF = "", situF = "", peakV = 0 } = rowData;
  const { token } = useToken();
  // console.log("type = ", props.type);

  const colorMap = {
    "": "white",
    B: "main",
    G: "success",
    W: "white", // warning
    R: "error",
    noN: "purple",
    noM: "purple",
  };

  const getColor = (type) => {
    switch (type) {
      case "white":
        return token.colorWhite;
      case "main":
        return token.colorPrimary;
      case "purple":
        return token.colorPurple;
      case "yellow":
        return token.colorYellow;
      case "error":
        return token.colorError;
      case "warning":
        return token.colorWarning;
      case "success":
        return token.colorSuccess;
      default:
        return token.colorPrimary;
    }
  };

  // 如果是 skyblue or  要渲染其他顏色
  if (situF === "noN") {
    // 工單未開skyblue(noN)
    return <TransLight type="square" color={getColor(colorMap[situF])} />;
  }
  if (situF === "noM") {
    // 機器未開purple(noM)
    // return <TransLight type="circle" color={getColor(colorMap[situF])} />;
    return <TransLight type="triangle" color={getColor(colorMap[situF])} />;
  }

  return (
    <LightStyle token={token} type={getColor(colorMap[situF])}>
      <span className="badge-status-dot badge-status-processing" />
    </LightStyle>
  );
};

/**
 * @param {string} type "main , error , warning , success"
 * @param {string} size "default 18px , input any px"
 * @param {Bool} disabled - description
 */

export const LightStyle = styled.span`
  .badge-status-dot {
    position: relative;
    width: 18px;
    height: 18px;
    border: ${(props) => props.type === "white" && "1px solid rgb(161 161 170)"};
    background-color: ${(props) => props.type};
  }
  .badge-status-processing {
    top: -1px;
    display: ${(props) => (props.disabled ? "none" : "inline-block")};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    vertical-align: middle;
    border-radius: 50%;
  }
  .badge-status-dot:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.type};
    border: ${(props) => {
      return `1px solid ${props.type === "white" ? "none" : props.type}`;
    }};
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    content: "";
  }

  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.4);
      opacity: 0;
    }
  }
`;

export default Light;
