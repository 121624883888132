import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//現品票維護

// 取得表頭
export const getQmsApSettingHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApSettingHd`, {
    params: _params,
  });
};

// 新增表頭
export const addQmsApSettingHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsApSettingHd`, data);
};

// 更新表頭
export const updateQmsApSettingHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApSettingHd`, data);
};

// 刪除表頭
export const deleteQmsApSettingHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsApSettingHd`, data);
};

// 據此創建
export const copyQmsApPwc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/copyQmsApPwc`, data);
};

// 取得表身
export const getQmsApSettingTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApSettingTb`, {
    params: _params,
  });
};

// 新增表身
export const addQmsApSettingTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsApSettingTb`, data);
};


//現品票規劃

// 取得表頭
export const getQmsApuiHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApuiHd`, {
    params: _params,
  });
};

// 新增表頭
export const addQmsApuiHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsApuiHd`, data);
};

// 更新表頭
export const updateQmsApuiHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApuiHd`, data);
};

// 取得表身
export const getQmsApuiTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApuiTb`, {
    params: _params,
  });
};

// 新增表身
export const updateQmsApuiTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApuiTb`, data);
};

//量產試作

// 取得表頭
export const getQmsApTryHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApTryHd`, {
    params: _params,
  });
};

// 新增表頭
export const addQmsApTryHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsApTryHd`, data);
};

// 更新表頭
export const updateQmsApTryHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApTryHd`, data);
};

// 據此創建
export const copyQmsApTry = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/copyQmsApTry`, data);
};

// 取得表身
export const getQmsApTryTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApTryTb`, {
    params: _params,
  });
};

// 更新表身
export const updateQmsApTryTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApTryTb`, data);
};

//製程管制項目

// 取得表頭
export const getQmsApPwcHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApPwcHd`, {
    params: _params,
  });
};

// 新增表頭
export const addQmsApPwcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsApPwcHd`, data);
};

// 更新表頭
export const updateQmsApPwcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApPwcHd`, data);
};

// 刪除表頭
export const deleteQmsApPwcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsApPwcHd`, data);
};

// 取得表身
export const getQmsApPwcTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApPwcTb`, {
    params: _params,
  });
};

// 更新表身
export const updateQmsApPwcTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsApPwcTb`, data);
};









// 刪除表頭
export const deleteQmsApuiHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsApuiHd`, data);
};

// 據此創建
export const copyQmsApui = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/copyQmsApui`, data);
};




// 上傳檔案
export const uploadQmsApuiImg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadQmsApuiImg`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//量產試作

// 取得製令單號下拉選單
export const getWoNs = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoNs`, {
    params: _params,
  });
};



// 刪除表頭
export const deleteQmsApTryHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsApTryHd`, data);
};


// 取得工作中心下拉選單
export const getQmsApPwcSelect = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsApPwcSelect`, {
    params: _params,
  });
};
