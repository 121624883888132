import { useEffect } from "react";
import { Form, Row, Col, Space, Button, message, Upload } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { addDaycodeTb, updateDaycodeTb } from "@/service/apis/LMS/LabelManage";
import * as XLSX from "xlsx";

import { useState } from "react";
import dayjs from "dayjs";

export default function ModalContainer({ set_s_showModal, s_editData, tableData }) {
  const [form] = Form.useForm();

  const call_addDaycodeTb = useAPI(addDaycodeTb);
  const call_updateDaycodeTb = useAPI(updateDaycodeTb);

  const [s_uploadFileList, set_s_uploadFileList] = useState([]);

  const [s_uploadTb, set_s_uploadTb] = useState([]);

  const uploadFile = (e) => {
    try {
      console.log(e);
      const file = e;
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        for (const Sheet in workbook.Sheets) {
          // var XL_row_object =
          XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
          if (workbook.Sheets.hasOwnProperty(Sheet)) {
            data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
            set_s_uploadTb(
              data.map((x) => {
                return {
                  dateStr: dayjs(x.date).format("YYYY-MM-DD"),
                  timecode: x.timecode.toString(),
                };
              })
            );
          }
        }
      };
      reader.readAsBinaryString(file);
      set_s_uploadFileList([e]);
      return true;
    } catch (e) {
      console.log(e);
      message.error("上傳失敗");
      set_s_uploadFileList([]);
      return false;
    }
  };

  const formMap = [
    {
      title: "上傳日期Excel",
      dataIndex: "upload",
      align: "center",
      col: 6,
      auth: false,
      // getValueFromEvent: normFile,
      type: (
        <Upload
          name="Excel"
          accept={".xls, .xlsx"}
          action={true}
          maxCount={1}
          beforeUpload={uploadFile}
          fileList={s_uploadFileList}
          style={{ width: "100%" }}
        >
          <Button icon={<UploadOutlined />} block>
            上傳
          </Button>
        </Upload>
      ),
    },
  ];

  const onSubmit = async () => {
    if (Object.keys(s_editData).length === 0 && s_uploadTb.length === 0) {
      message.error("請上傳日期excel");
      return;
    }

    const req = {
      ...s_editData,
      tb: s_uploadTb,
    };

    if (Object.keys(s_editData).length === 0) {
      call_addDaycodeTb.request(req);
    } else {
      call_updateDaycodeTb.request(req);
    }
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  useEffect(() => {
    if (call_addDaycodeTb.status === "suc") {
      handleCancel();
    } else if (call_addDaycodeTb.status === "err") {
      message.error(call_addDaycodeTb.msg);
    }
  }, [call_addDaycodeTb.status]);

  useEffect(() => {
    if (call_updateDaycodeTb.status === "suc") {
      handleCancel();
    } else if (call_updateDaycodeTb.status === "err") {
      message.error(call_updateDaycodeTb.msg);
    }
  }, [call_updateDaycodeTb.status]);

  return (
    <Form
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={s_editData}
    >
      <Row gutter={[6, 6]}>
        {formMap.map((x, i) => {
          return (
            <Col span={x.col} key={i}>
              <Form.Item
                {...x}
                label={x.title}
                name={x.dataIndex}
                rules={
                  x.auth && [
                    {
                      required: true,
                      message: `請輸入${x.title}`,
                    },
                  ]
                }
              >
                {x.type}
              </Form.Item>
            </Col>
          );
        })}
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={handleCancel}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
