import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getZPPS007ROUTING = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getZPPS007ROUTING`, {
    params: data,
  });
};


