import { useContext } from "react";
import { useTranslation } from "react-i18next";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// util function
import { formatAmount } from "@/util/format";

const Container = ({ hd_values, pageData, currentPage, totalPages }) => {
  // 人員資料
  const { getStaffName } = useContext(ERPContext);
  const { t } = useTranslation();

  const width = {
    no: 3,
    item_kode: 17,
    specification: 30,
    unit: 6,
    demandqty: 14,
    issuedqty: 14,
    note: 16,
  };

  return (
    <div className="flex flex-col w-full" >
      {/* 表頭 */}
      <div className="flex border-solid border-y-2 border-x-2 divide-x-2 divide-y-0 divide-solid text-[12px] text-center">
        {/* 序號 */}
        <div className={`flex-center pt-1`} style={{ width: `${width.no}%` }}>NO</div>
        {/* 料號 */}
        <div className={`flex-center pt-1`} style={{ width: `${width.item_kode}%` }}>料號</div>
        <div className={`flex-center pt-1`} style={{ width: `${width.specification}%` }}>描述</div>
        <div className={`flex-center pt-1`} style={{ width: `${width.unit}%` }}>
          <div>單位</div>
        </div>
        <div className={`flex-center pt-1`} style={{ width: `${width.demandqty}%` }}>
          <div>計畫數量</div>
        </div>
        <div className={`flex-center pt-1`} style={{ width: `${width.issuedqty}%` }}>
          <div>實際退料</div>
        </div>
        <div className={`flex-center pt-1`} style={{ width: `${width.note}%` }}>
          <div>備註</div>
        </div>
      </div>

      <div className="w-full  border-solid border-b-[1px] border-t-[0px] border-r-[0px] border-l-[0px] border h-4" />


      {/* 表格內容 */}
      {pageData?.map((item, index) => (
        <div
          key={index}
          className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-[10px] text-center h-[30px]"
        >
          <div className={`flex-center`} style={{ width: `${width.no}%` }}>{item.itemno}</div>
          <div className={`flex justify-start items-center`} style={{ width: `${width.item_kode}%` }} >
            <div className="scale-90 whitespace-nowrap">{item.PN}</div>
          </div>
          <div className={`flex justify-start items-center`} style={{ width: `${width.specification}%` }} >
            <div className="flex h-full flex-col justify-around">
              <div className="scale-90 flex items-start">{item.pdtNM}</div>
            </div>
          </div>

          <div className={`flex justify-center items-center`} style={{ width: `${width.unit}%` }}>
            <div className="scale-90">{item.unit}</div>
          </div>
          <div className={`flex justify-end items-center`} style={{ width: `${width.demandqty}%` }}>
            <div className="scale-90">{formatAmount(item.demandqty)}</div>
          </div>
          <div className={`flex justify-end items-center`} style={{ width: `${width.issuedqty}%` }}>
            <div className="scale-90">{formatAmount(item.issuedqty)}</div>
          </div>
          <div className={`flex justify-end items-center`} style={{ width: `${width.note}%` }}>
            <div className="flex scale-90 break-all">{item.note}</div>
          </div>
        </div>
      )) ?? undefined}
      {currentPage !== totalPages && (
        <span className="flex justify-end text-[12px] mt-1">see next page...</span>
      )}

      {/* 最後一頁才要顯示 */}
      {currentPage === totalPages && (
        <div className="flex flex-col">
          
          {/* 簽名區域要在最後一頁的最下方 */}
          <div className="absolute bottom-[0px] text-[12px] w-full">

            <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[2px] border-y-[1px] border-solid border-x-[2px] ">
              <div className="flex-center w-[20%] text-[12px] h-6 bg-zinc-300 ">審核人員</div>
              <div className="flex-center  w-[36%] text-[12px] h-6 bg-zinc-300">倉管人員</div>
              <div className="flex-center w-[44%] text-[12px] h-6 bg-zinc-300">退料人員</div>
            </div>

            <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[2px] border-solid border-x-[2px] ">
              <div className="flex-center w-[20%] text-[12px] h-[80px]"></div>
              <div className="flex-center  w-[36%] text-[12px] h-[80px]"></div>
              <div className="flex-center w-[44%] text-[12px] h-[80px]"></div>
            </div>

            {/* <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[2px] border-solid border-x-[2px] ">
              <div className="flex-center w-[22%] text-[10px] h-6 ">
                <div className="text-[12px]">Mr.Zack</div>
              </div>
              <div className="flex-center  w-[48%] text-[10px] h-6 ">
                <div className="text-[12px]">Mr.Philbert</div>
              </div>
              <div className="flex-center w-[30%] text-[10px] h-6 ">
                <div className="text-[12px]">Tria</div>
              </div>
            </div> */}

          </div>
        </div>
      )}
    </div>
  );
};

export default Container;
