import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getPlanHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPlanHd`, {
    params: _params,
  });
};
export const addPlanHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPlanHd`, data);
};

export const updatePlanHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePlanHd`, data);
};

export const deletePlanHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePlanHd`, data);
};

// -----------------------------------------------------------------------------------------------
export const getPlanTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPlanTb`, {
    params: _params,
  });
};
export const addPlanTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPlanTb`, data);
};

export const updatePlanTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePlanTb`, data);
};

export const deletePlanTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePlanTb`, data);
};

export const createPlanImage = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/createPlanImage`, data);
};
