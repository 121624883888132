import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { addWh, updateWh } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addWh = useAPI(addWh);
  const call_updateWh = useAPI(updateWh);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addWh.request(postData);
    }

    if (type === "edit") {
      call_updateWh.request(postData);
    }
  };

  useEffect(() => {
    if (call_addWh.status === "suc") {
      message.success(call_addWh.msg);
      set_is_showModal(false);
    } else if (call_addWh.status === "err") {
      message.error(call_addWh.msg);
    }
  }, [call_addWh.status]);

  useEffect(() => {
    if (call_updateWh.status === "suc") {
      message.success(call_updateWh.msg);
      set_is_showModal(false);
    } else if (call_updateWh.status === "err") {
      message.error(t(`error.error-code.${call_updateWh.msg}`));
    }
  }, [call_updateWh.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ isperf: false, ...s_editData }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="SN" label="編號">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="pwcID" label="製程" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="itemNM" label="項目名稱" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="wh" label="C/T值(S/PCS)" rules={[{ required: true }]}>
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="是否算績效" name="isperf" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addWh.status === "load" || call_updateWh.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
