import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getProc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProc`, {
    params: _params,
  });
};
export const addProc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProc`, data);
};

export const updateProc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateProc`, data);
};

export const deleteProc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteProc`, data);
};
