/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  message,
  Radio,
  Space,
  InputNumber,
  Upload,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";

import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { addDevice, updateDevice } from "@/service/apis/ADM/measurementEquipment";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";

import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_editData, set_s_showModal, arrMap }) {
  const { t } = useTranslation();
  const [s_file, set_s_file] = useState(null);
  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);

  const call_addDevice = useAPI(addDevice);
  const call_updateDevice = useAPI(updateDevice);
  const call_getProduct = useAPI(getProductSelect);
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const props = {
    listType: "picture",
    beforeUpload: () => false,
    name: "test",
    maxCount: 1,
    onChange: ({ file }) => {
      // console.log("file = ", file);
      set_s_file(file);
    },
  };
  const onSubmit = async (values) => {
    console.log(values);

    const formate = Array.isArray(values.pwcID) ? values.pwcID.join("|") : values.pwcID;
    if (type === "create") {
      call_addDevice.request({ ...values, pwcID: formate });
    }

    if (type === "edit") {
      console.log(values);
      call_updateDevice.request({ ...values, pwcID: formate });
    }
  };

  useEffect(() => {
    call_getProduct.request();
  }, []);

  useEffect(() => {
    if (call_addDevice.status === "suc") {
      message.success(call_addDevice.msg);
      set_s_showModal(false);
    } else if (call_addDevice.status === "err") {
      message.error(call_addDevice.msg);
    }
  }, [call_addDevice.status]);

  useEffect(() => {
    if (call_updateDevice.status === "suc") {
      message.success(call_updateDevice.msg);
      set_s_showModal(false);
    } else if (call_updateDevice.status === "err") {
      message.error(call_updateDevice.msg);
    }
  }, [call_updateDevice.status]);
  console.log(arrMap);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        // isvalid: true,
        // moldtype: 1,
        // cavity: 1,
        // stdcavity: 1,
        // validcavity: 1,
        ...s_editData,
        // openT: dayjs(s_editData.openT),
        // assetkind: "EA",
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item
            label={t("ADM.edm.mgmtcat")} // 管理分類
            name="assetkind"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={[
                { label: "重量秤量", value: "EA" },
                { label: "尺寸測量", value: "EB" },
                { label: "化學檢驗", value: "EC" },
                { label: "物理檢驗", value: "ED" },
                { label: "機械性質", value: "EE" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="設備編號" name="assetID" rules={[{ required: true }]}>
            <Input disabled={type === "edit"} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="設備名稱"
            name="assetNM"
            disabled={type === "edit"}
            rules={[{ required: true }]}
          >
            <Input disabled={type === "edit"} />
          </Form.Item>
        </Col>

        <Col span={6}></Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item
            label={t("ADM.util.pfID")} // 製程ID
            name="pfID"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              onChange={() => {
                form.setFieldsValue({ wsID: [] });
              }}
              options={
                arrMap.pfArr?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t("ADM.util.pwc")} // 工作中心
            name="pwcID"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              disabled={!f_pfID}
              options={arrMap.psArr?.reduce((options, curr) => {
                curr.pfID.includes(f_pfID) &&
                  options.push({
                    label: `${curr.pwcID}_${curr.pwcNM}`,
                    value: curr.pwcID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="是否保養計劃" name="ismrplan">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}></Col>

        <Col span={6} className="">
          <Form.Item label="是否定頻保養" name="ismrcycle">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="設定保養次數" name="setmrcycle">
            <InputNumber min={1} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="是否定期保養" name="ismrperiod">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="設定保養週期" name="setmrperiod">
            <InputNumber min={1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}></Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={call_addDevice.status === "load" || call_updateDevice.status === "load"}
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
