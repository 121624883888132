import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { addMnsPushLvl, updateMnsPushLvl } from "@/service/apis/MNS/notificationSetting";

export default function ModalConintaer({ s_editData, set_s_isShowModal, set_s_editData }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addMnsPushLvl = useAPI(addMnsPushLvl);
  const call_updateMnsPushLvl = useAPI(updateMnsPushLvl);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addMnsPushLvl.request(values);
    }
    if (type === "edit") {
      call_updateMnsPushLvl.request(values);
    }
  };

  useEffect(() => {
    if (call_addMnsPushLvl.status === "suc") {
      message.success(call_addMnsPushLvl.msg);
      set_s_isShowModal(false);
    } else if (call_addMnsPushLvl.status === "err") {
      message.error(call_addMnsPushLvl.msg);
    }
  }, [call_addMnsPushLvl.status]);

  useEffect(() => {
    if (call_updateMnsPushLvl.status === "suc") {
      message.success(call_updateMnsPushLvl.msg);
      set_s_isShowModal(false);
    } else if (call_updateMnsPushLvl.status === "err") {
      message.error(call_updateMnsPushLvl.msg);
    }
  }, [call_updateMnsPushLvl.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        {/* 層級碼 */}
        {/* 選擇推播層級 */}
        {/* 1(班組)  ,2(課級) , 3(理級) , 4(總級) */}
        <Col span={12}>
          <Form.Item
            label={t("ADM.function-params-manage.lvlcode")}
            name="lvlcode"
            rules={[{ required: true }]}
          >
            <Select
              disabled={Object.keys(s_editData).length > 0}
              placeholder={t("util.util.placeholder_select") + t("ADM.util.pushlayer")}
              allowClear
              options={[
                { label: t("ADM.function-params-manage.team"), value: 1 },
                { label: t("ADM.function-params-manage.class"), value: 2 },
                { label: t("ADM.function-params-manage.management"), value: 3 },
                { label: t("ADM.function-params-manage.total"), value: 4 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* 層級名稱 */}
          <Form.Item
            label={t("ADM.function-params-manage.lvlNM")}
            name="lvlNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
