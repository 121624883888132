import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { debounce } from "lodash";
import { useEffect, useRef, useState, useContext } from "react";
import SystemContext from "@/components/Context/SystemContext";
import dayjs from "dayjs";

export default function ProductionLineChart({ chartData, parentRef }) {
  const chartEle = useRef(null);
  const { c_systemConfig } = useContext(SystemContext);

  const thisYear = dayjs().year();
  const thisMonth = dayjs().month(); // month() 返回0-11，所以需要+1
  const yesterday = dayjs().subtract(1, "day").date();
  const today = dayjs().date();

  const bgColor = c_systemConfig.theme === "dark" ? `#252B42` : "#FFFFFF";

  const labelTextColor = c_systemConfig.theme === "dark" ? "#ffffff" : "#363955";

  const [chartOptions, setChartOptions] = useState({
    chart: {
      animation: false,
      borderRadius: 10,
      borderColor: "#dadada",
      // borderWidth: 1,
      marginTop: 25,
      backgroundColor: bgColor,
      events: {
        // 固定SHOW最後一筆資料
        render() {
          try {
            if (this?.series) {
              let points = [];
              this.series.forEach((series) => {
                if (series) {
                  points.push(series.points[series.points.length - 1]);
                }
              });
              if (points.length > 0) {
                this.tooltip.refresh(points);
              }
            }
          } catch (e) {
            console.log(e);
          }
        },
      },
    },
    title: { text: "" },

    credits: {
      enabled: false,
    },

    xAxis: {
      tickInterval: 1000 * 60 * 60, // 每小时一个刻度
      type: "datetime",
      min: Date.UTC(thisYear, thisMonth, yesterday, 8, 0, 0), // 设置x轴最小值为thisYear年8月1日早上8点
      max: Date.UTC(thisYear, thisMonth, today, 7, 59, 59), // 设置x轴最大值为thisYear年8月2日早上7点59分59秒
      dateTimeLabelFormats: {
        day: "%H:%M", // x轴标签的格式为小时:分钟
      },
      //tickInterval: 1,
      labels: {
        overflow: "justify", // 標籤溢出的處理方式
        style: {
          color: labelTextColor,
          fontSize: "14px",
          fontWidth: "600",
        },
        format: "{value:%H:%M}", // 标签的时间格式为小时:分钟
        // rotation: -45, // iris 630 變更標籤顯示角度
      },
    },

    yAxis: [
      // y軸有2個物件, 表該圖表有2個y軸配置
      {
        title: "",
        tickAmount: 5, // 表示『刻度線』的數量
        labels: {
          style: {
            color: labelTextColor,
            fontSize: 12, // Y軸字體大小
          },
        },
      },
      {
        title: "",
        tickAmount: 5, // 刻度线的数量
        opposite: true, // 把第二個y軸放在圖表右側
        labels: {
          style: {
            color: labelTextColor, // 第二个y轴标签的颜色
            fontSize: 12, // Y軸字體大小
          },
        },
      },
    ],

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      enabled: false, // 不显示图例
    },

    tooltip: {
      shared: true,
      headerFormat: "",
      valueDecimals: 0, // 数值保留两位小数
      style: {
        fontSize: 25, // 提示框的字体大小
      },
    },

    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#37e22068"],
            [1, Highcharts.color("#2cac1b").setOpacity(0).get("rgba")],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        name: "不良總數",
        data: [], // 第一个系列的数据，初始为空
        color: "#5076D2",
        // type: "area",
        pointStart: Date.UTC(thisYear, thisMonth, yesterday, 0, 0, 0),
        pointInterval: 1000 * 60 * 60, // 每個數據間隔1小時
 
      },
      {
        name: "良品總數",
        data: [], // 第二个系列的数据，初始为空
        color: "#57B8FF",
        pointStart: Date.UTC(thisYear, thisMonth, yesterday, 0, 0, 0),
        pointInterval: 1000 * 60 * 60, // 每个数据点间隔一个小时
        yAxis: 1, // 将该系列关联到第二个y轴
      },
    ],
  });

  const [s_parentWidth, set_s_parentWidth] = useState(0);

  // 当浏览器窗口尺寸发生变化时触发的回调函数
  const resizeChange = (e) => {
    set_s_parentWidth(e.contentRect.width);
  };

  // 使用防抖功能包装的resizeChange函数
  const debounceResizeChange = debounce(resizeChange, 300);

  useEffect(() => {
    // 创建ResizeObserver实例并注册回调函数
    const resize = new ResizeObserver((e) => {
      if (!Array.isArray(e) || !e.length) return;
      for (const ent of e) {
        debounceResizeChange(ent);
      }
    });

    // 开始监听父元素的尺寸变化
    resize.observe(parentRef.current);

    // 清除监听函数（组件卸载时执行）
    return () => {
      parentRef?.current && resize.unobserve(parentRef?.current);
    };
  }, []);


  useEffect(() => {
    setChartOptions((prev) => {

      prev.series[0].data = chartData.map((x, i) => [
        Date.UTC(thisYear, thisMonth, yesterday, i + 8, 0, 0),
        x.ngC,
      ]);
      prev.series[1].data = chartData.map((x, i) => [
        Date.UTC(thisYear, thisMonth, yesterday, i + 8, 0, 0),
        x.okC,
      ]);
      return { ...prev };
    });
  }, [chartData]);

  useEffect(() => {
    // 更新图表选项中的宽度
    setChartOptions((prev) => {
      prev.chart.width = s_parentWidth;
      return { ...prev };
    });
  }, [s_parentWidth]);
  console.log("chartOptions =", chartOptions);
  console.log("chartData =", chartData[0]);
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartEle} />;
}
