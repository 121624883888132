const th_TH = {
  "util": {
    "util": {
      "itemID": "ไอดีรายการ",
      "itemno": "ลำดับ",
      "sign_out": "ออกจากระบบ",
      "add": "เพิ่มใหม่",
      "edit": "แก้ไข",
      "delete": "ลบออก",
      "search": "ค้นหา",
      "cancel": "ยกเลิก",
      "close": "ปิด",
      "ok": "ยืนยัน",
      "render": "เลือก",
      "check": "ตรวจซ้ำ",
      "success": "สำเร็จ",
      "all": "ทั้งหมด",
      "yes": "ใช่",
      "no": "ไม่",
      "name": "ชื่อ",
      "total": "จำนวนทั้งสิ้น",
      "SN": "ลำดับ",
      "detail": "รายละเอียด",
      "action": "ดำเนินการ",
      "size_big": "ไซส์ใหญ่พิเศษ",
      "size_large": "ไซส์ใหญ่",
      "size_middle": "ไซส์กลาง",
      "size_small": "ไซส์เล็ก",
      "hide_detail": "ซ่อนไฟล์รายละเอียด",
      "del_confirm": "ยืนยันลบออก",
      "del_confirm_msg": "กรุณาตรวจเช็คความถูกต้องก่อนยืนยันลบออก",
      "note": "หมายเหตุ",
      "no_data": "ไม่พบข้อมูล",
      "data": "ข้อมูล",
      "header_cancel_create": "ยกเลิกการสร้างข้อมูลตามนี้",
      "header_create": "สร้างรายการตามข้อมูลนี้",
      "placeholder_select": "กรุณาเลือก",
      "placeholder_input": "กรุณากรอก",
      "or": "หรือ",
      "order": "ใบงาน",
      "showTotal": "รวม {{total}} รายการ， กำลังแสดงรายการ {{rangeFirst}} - {{rangeEnd}} ",
      "itemNM": "ชื่อรายการ",
      "excluding_tax": "(ไม่รวมภาษี)",
      "start": "เริ่มต้น",
      "end": "สิ้นสุด",
      "wl": "ไลน์งาน",
      "ws": "สถานนีงาน",
      "woN": "รหัสใบงาน",
      "situF": "สถานะ",
      "PN": "รหัสวัสดุ",
      "whID": "โกดังที่",
      "idmtype": "ประเภทการผ่านบัญชี",
      "custID": "ไอดีลูกค้า",
      "custPN": "รหัสวัสดุลูกค้า",
      "pdtNM": "ชื่อสินค้า",
      "pdtspec": "สเปค",
      "planqty": "จำนวนที่วางแผนไว้",
      "inqty": "จำนวนการผลิต",
      "tqty": "จำนวนทั้งสิ้นในตอนนี้",
      "sqty": "ป้อนข้อมูลโดยพนักงาน",
      "dqty": "สแกนบาร์โค้ด",
      "batchno": "รหัสล็อต",
      "okC": "จำนวนชิ้นงานมาตรฐาน",
      "inC": "จำนวนการผลิต",
      "ngC": "จำนวนชิ้นงานตำหนิ",
      "lifeF": "ประวัติเส้นทาง",
      "isvalid": "มีผล",
      "isfocus": "โฟกัส",
      "dept": "แผนก",
      "updtT": "เวลาอัพเดท",
      "pdtclass": "สินค้า",
      "mtype": "ประเภทเครื่อง",
      "ver": "เวอร์ชั่น",
      "qty": "จำนวน BOM",
      "attribute": "ลักษณะเฉพาะ",
      "unit": "หน่วยวัด",
      "opunit": "หน่ายดำเนินการ",
      "unitwt": "น้ำหนักต่อหน่วย",
      "setting": "ตั้งค่า",
      "mgmtcat": "ประเภทการมอบอำนาจ",
      "querycat": "ค้นหาประเภท",
      "drawno": "เลขที่ภาพวิศวกรรม",
      "matl": "วัตถุดิบ",
      "moldID": "ไอดีแม่พิมพ์",
      "heirPN": "รหัสวัสดุที่สืบทอดมา",
      "heirdealID": "สืบทอดมาจาก",
      "moldC": "จำนวนรอบในการใช้แม่พิมพ์",
      "moldcavity": "จำนวนรูแม่พิมพ์",
      "searchPN": "ค้นหารหัสวัสดุ",
      "dealPN": "รหัสการซื้อขายวัสดุ",
      "edit_detect_msg1": "ระบบตรวจจับได้ว่าท่านได้แก้ไขข้อมูลดังช่องข้างล่างนี้",
      "edit_detect_msg2": "กรุณาเซฟข้อมูลระบบก่อนแล้วค่อยทำการแก้ไขรายละเอียด",
      "msg_chose_data": "กรุณาเลือกข้อมูลที่ต้องการนำไปใช้",
      "msg_chose_whichType_toThrow": "กรุณาเลือกใบงานที่ต้องการโอนย้าย",
      "msg_chose_atleast_one_Detail": "กรุณาเลือกรายการรายละเอียดอย่างน้อย1รายการ",
      "UploadSignature": "อัพโหลดการเซ็นต์อนุมัติ",
      "UploadSign": "อัพโหลดการเซ็นต์ตอบ",
      "removeSearch": "ล้างประวัติการค้นหา",
      "setAdvanced": "ตั้งค่าเงื่อนไขการค้นหาแบบเชิงลึก",
      "exportExcel": "ส่งออกไปยังExcel",
      "table_close": "คำสั่งบังคับปิดเคส",
      "table_more": "ดูเพิ่มเติม",
      "header_next": "รายการถัดไป",
      "header_prev": "รายการที่แล้ว",
      "header_print": "ปริ้น",
      "header_return": "กลับหน้าตารางรวม",
      "header_save": "เซฟข้อมูล",
      "header_tour": "กลับหน้าหลัก",
      "take": "นำไปใช้",
      "throw": "โอนย้าย",
      "accord": "ตามนี้",
      "create": "สร้าง",
      "cancel_Check": "ยกเลิกการเซ็นต์ตอบ",
      "sysMsg": "ข้อความจากระบบ",
      "advanceSearch": "ค้นหาแบบมีเงื่อนไข",
      "window": "ช่องทางติดต่อ",
      "contact": "ผู้ติดต่อ",
      "tel": "เบอร์โทรศัพท์ติดต่อ",
      "itemno_total": "จำนวนรายการทั้งหมด",
      "get_prev_data_suc": "เรียกข้อมูลรายการทที่แล้วสำเร็จ",
      "get_next_data_suc": "เรียกข้อมูลรายการถัดไปสำเร็จ",
      "get_prev_data_err": "เรียกข้อมูลรายการที่แล้วล้มเหลว",
      "get_next_data_err": "เรียกข้อมุลรายการถัดไปล้มเหลว",
      "prev_data_errMsg": "ข้อมูลขณะนี้เป็นข้อมูลรายการแรก ไม่มีข้อมูลก่อนหน้านี้แล้ว",
      "next_data_errMsg": "ข้อมูลขณะนี้เป็นข้อมูลรายการสุดท้าย ไม่มีรายการถัดไปแล้ว",
      "opcode": "ประเภทใบงาน",
      "N": "รหัสใบงาน",
      "currencytype": "สกุลเงิน",
      "taxtype": "ประเภทภาษี",
      "taxrate": "อัตราภาษี",
      "utype": "ประเภทหน่วย",
      "custalias": "ชื่อย่อลูกค้า",
      "custNM": "ชื่อลูกค้า",
      "exchangerate": "อัตราแลกเปลี่ยน",
      "exchangeD": "อัตราแลกเปลี่ยนวันที่",
      "confirmID": "ผู้ตรวจซ้ำ",
      "lifeFT": "เวลาประวัติเส้นทาง",
      "createID": "ผู้สร้างข้อมูล",
      "createT": "วันที่สร้างข้อมูล",
      "confirmT": "วันที่ตรวจซ้ำ",
      "signT": "วันที่เซ็นต์ตอบ",
      "approveT": "วันที่อนุม้ติ",
      "updtID": "ผู้อัพเดท",
      "EDT": "กำหนดวันที่ส่งมอบสินค้า",
      "startEDT": "กำหนดวันที่จัดส่งสินค้า(เริ่ม)",
      "endEDT": "กำหนดวันที่ส่งมอบสินค้า(สิ้นสุด)",
      "pbom": "จำนวนการผสมผสาน",
      "punit": "หน่วยที่ใช้",
      "tax": "ยอดเงินภาษี",
      "itemsum": "ยอดเงินต่อรายการ",
      "itemsumtax": "เงินภาษีต่อรายการ",
      "subtotal": "รวมยอดย่อยรวมเงินภาษี",
      "batchdate": "วันที่รหัสล็อต",
      "balqty": "จำนวนคงเหลือ",
      "suppID": "รหัสไอดีซัพพลายเออร์",
      "suppalias": "ชื่อย่อซัพพลายเออร์",
      "suppNM": "ชื่อซัพพลายเออร์",
      "stockinT": "เวลาโยกย้าย",
      "idmtype_s": "ผ่า่นบัญชีโดยพนักงาน",
      "idmtype_d": "ผ่านบัญชีโดยบาร์โค้ด",
      "idmtype_m": "ผ่านบัญชีแบบผสมผสาน",
      "attribute_0": "0วัตถุดิบ",
      "attribute_1": "1วัสดุ",
      "attribute_2": "2อะไหล่ไม่ได้มาตรฐาน",
      "attribute_3": "3 อะไหล่มาตรฐาน",
      "attribute_4": "4 สินค้ากึ่งสำเร็จรูป",
      "attribute_5": "5 สินค้าสำเร็จรูป",
      "attribute_6": "6 สินค้าจากลูกค้า",
      "pdtclass_X": "N/A",
      "pdtclass_M": "ชิ้นส่วนหลัก",
      "pdtclass_A": "อุกกรณ์เสริม",
      "pdtclass_P": "วัสดุห่อหุ้ม",
      "packtype_0": "ดำเนินการห่อหุ้ม(0)_ใช้สำหรับฝ่ายห่อหุ่ม",
      "packtype_1": "การห่อหุ้มส่งออกสินค้า(1)_ใช้สำหรับห่อหุ้มส่งออกสินค้า",
      "packtype_2": "ห่อหุ่มโยกย้ายภายใน(2)_ใช้สำหรับโยกย้ายระหว่างไลน์งานผลิต",
      "assetkind": "การแบ่งประเภททรัพย์สิน",
      "assetkind_TI": "การฉีดขึ้นรูป",
      "assetkind_TS": "การปั้ม",
      "assetkind_TD": "การหล่อขึ้นรูป",
      "assetkind_TF": "การตีขึ้นรูป",
      "fileType_SOP": "มาตรฐานการทำงานSOP",
      "fileType_SIP": "มาตรฐานการตรวจสอบSIP",
      "fileType_PACK": "มาตรฐานการห่อหุ้มPACK",
      "fileType_DRAW": "แผนภาพวิศวกรรมDWG",
      "fileType_PIC": "ไฟล์รูปภาพPIC",
      "fileType_ECN": "ECN_工程變更通知",
      "nav_pds_dashboard": "บอร์ดดิจิตอลแสดงข้อมูลการผลิต",
      "nav_pds_abc-board": "บอร์ดบริหารจัดการความผิดปกติ",
      "nav_pds_overview-lines": "ตารางกำลังการผลิตโดยรวม",
      "nav_BDM": "การบริหารจัดการข้อมูลวัสดุ",
      "nav_bdm_pn-manage": "การบำรุงรักษาข้อมูล",
      "nav_bdm_edm": "การบำรุงรักษาข้อมูลทางเทคนิค",
      "nav_EDM": "การบริหารจัดการข้อมูลแม่พิมพ์",
      "nav_edm_mold": "การบำรุงรักษาข้อมูลแม่พิมพ์",
      "nav_TDM": "การบริหารจัดการข้อมูลในเขตโรงงาน",
      "nav_tdm_plant-manage": "ตั้งค่าไฟล์ข้อมูลโรงงาน",
      "nav_tdm_procedure-manage": "การวางแผนและบำรุงรักษาขบวนการผลิต",
      "nav_tdm_production-line-manage": "การวางแผนและบำรุงรักษาไลน์ผลิต",
      "nav_tdm_warehouse-manage": "การวางแผนแลบำรุงรักษาโกดัง",
      "nav_tdm_string-manage": "การบริหารจัดการฟังก์ชันสตริง",
      "nav_tdm_plant-params-manage": "ตั้งค่าพารามิเตอร์ดโรงงาน",
      "nav_SDM": "บริหารจัดการข้อมูลระบบ",
      "nav_sdm_user-manage": "บำรุงรักษาข้อมูลพนักงาน",
      "nav_sdm_company": "บำรุงรักษาข้อมูลซัพพลายเออร์",
      "nav_sdm_client": "บำรุงรักษาข้อมูลลูกค้า",
      "nav_sdm_category-manage": "ตั้งค่าประเภทการค้นหา",
      "nav_sdm_data-auth-manage": "ตั้งค่าฟังก์ชันการมอบอำนาจ",
      "nav_sdm_trading-pattern": "ตั้งค่ารายการสตริง",
      "nav_sdm_function-params-manage": "ตั้งค่าพารามิเตอร์ระบบ",
      "nav_WMS": "ระบบบริหารจัดการสินค้าคงคลัง",
      "nav_wms_plan-in": "การดำเนินการนำสินค้าเข้าโกดัง",
      "nav_wms_plan-out": "การดำเนินการนำสินค้าออกจากโกดัง",
      "nav_wms_stock-search": "เช็คสถานะสินค้าคงคลัง",
      "nav_wms_inv-history": "บันทึกสินค้าคงคลังรายเดือน",
      "nav_wms_pro-instock": "ตัวแทนดำเนินการนำสินค้าเข้าโกดัง",
      "nav_PMS": "ระบบบริหารจัดการการจัดซื้อ",
      "nav_pms_plan": "แผนการสอบถามราคา",
      "nav_pms_req": "การขอจัดซื้อสินค้า",
      "nav_pms_pro": "การจัดซื้อสินค้า",
      "nav_pms_instock": "การดำเนินการเข้าวัสถุ",
      "nav_pms_gostock": "การตรวจสอบวัสดุเข้า",
      "nav_pms_return": "การดำเนินการตีคืนวัสดุเข้า",
      "nav_pms_ap-manage": "การบริหารจักการบัญชีค้างจ่าย",
      "nav_pms_report": "ค้นหารีพอร์ท",
      "nav_pms_report-unpurchase": "รายละเอียดสินค้าจัดซื้อที่ยังไม่ได้ส่งมอบ",
      "nav_pms_report-diff": "ตารางปรับราคาการจัดซื้ิอ",
      "nav_pms_report-instocksummary": "ตารางสรุปสินค้าคงคลัง",
      "nav_pms_report-inventorydiff": "รายละเอียดการเคลื่อนไหวสินค้าคงคลัง",
      "nav_pms_report-psisummary": "ตารางสรุปการเข้าและออกสินค้าในคลัง",
      "nav_pms_report-purchasesummary": "ตารางสรุปการเข้าและตีคืนสินค้า",
      "nav_pms_report-purchaselist": "รายละเอียดรายการสินค้าเข้าและตีคืน",
      "nav_pms_report-purchasestatistics": "ตารางสถิติการเข้าและตีคืนสินค้า",
      "nav_OMS": "ระบบบริหารจัดการการจำหน่ายสินค้า",
      "nav_oms_quo": "การดำเนินการเสนอราคา",
      "nav_oms_fod": "การดำเนินการสั่งจองล่วงหน้า",
      "nav_oms_ord": "การดำเนินการจำหน่ายสินค้า",
      "nav_oms_outstock": "การดำเนินการจัดส่งสินค้า",
      "nav_oms_check": "ค้นหาการตรวจสอบการจำหน่ายสินค้า",
      "nav_oms_return": "การดำเนินการสินค้าจำหน่ายที่ตีคืน",
      "nav_oms_ap-manage": "บริหารจัดการบัญชีค้างจ่าย",
      "nav_oms_report": "ค้นหารีพอร์ท",
      "nav_oms_report-unshipped": "รายละเอียดรายการสินค้าในออร์เดอร์ที่ยังไม่ได้จัดส่ง",
      "nav_oms_report-salesummary": "ตารางสรุปรายการสินค้าเข้าและตีคืน",
      "nav_oms_report-salelist": "รายละเอียดรายการสินค้าเข้าและตีคืน",
    },
  },
  "error": {
    "error-code": {
      "403": "ไม่มีอำนาจ",
      "404": "ไม่พบข้อมูล",
      "409": "ข้อมูลซ้ำ",
      "500": "การดำเนินการล้มเหลม",
    },
  },
  "WOS": {
    "util": {},
    "login": {
      "pageTitle": "เข้าสู่ระบบ",
    },
    "mc": {
      "pageTitle": "การจัดการวัสดุ",
    },
    "parts-binding": {
      "pageTitle": "部品掃碼",
    },
    "ppidp": {
      "pageTitle": "成品綁定",
    },
    "qc": {},
    "tk": {
      "pageTitle": "生產看板",
    },
    "wl-woc": {
      "pageTitle": "生產控制",
    },
    "wpc-search": {
      "pageTitle": "途單查詢",
    },
  },
  "IMS": {
    "util": {
      "lifeF_0": "สร้างข้อมูลเรียบร้อย",
      "lifeF_1": "กำลังดำเนินการ",
      "lifeF_2": "ส่งมอบแล้ว",
      "lifeF_A": "ทั้งหมด",
      "lifeF_T": "แก้ไขแล้ว",
      "opcode_4110": "รับสินค้าเข้าจากการผลิต",
      "opcode_4120": "รายละเอียดสินค้าเข้าจาการสั่งซื้อ",
      "opcode_4130": "รับสินค้าเข้าจากการตีคืนสินค้า",
      "opcode_4141": "สินค้าย้ายเข้า",
      "opcode_4142": "สินค้ายืมเข้า",
      "opcode_4143": "รับคืนสินค้ายืมออก",
      "opcode_4160": "รับสินค้าตีคืนจากการผลิต",
      "opcode_4010": "เช็คสต๊อกตอนเปิดบัญชีใหม่",
      "opcode_4020": "เช็คสต๊อกตามรอบเวลา",
      "opcode_4030": "เช็คสต็อกตามโปรเจ็ค",
      "opcode_4210": "ฝ่ายผลิตเบิกใช้",
      "opcode_4260": "ฝ่ายผลิตเบิกเกิน",
      "opcode_4220": "จัดส่งสินค้าจำหน่าย",
      "opcode_4230": "進料退出",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4243": "借入歸還",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "preN_4010": "รหัสใบเช็คยอด",
      "preN_4020": "รหัสใบเช็คยอด",
      "preN_4030": "รหัสใบเช็คยอด",
      "preN_4110": "รหัสใบงานแหล่งที่มา",
      "preN_4120": "รหัสใบงานแหล่งที่มา",
      "preN_4130": "รหัสใบงานแหล่งที่มา",
      "preN_4220": "รหัสใบงานแหล่งที่มา",
      "preN_4230": "รหัสใบงานแหล่งที่มา",
      "preN_4141": "รหัสใบงานแหล่งที่มา",
      "preN_4241": "รหัสใบงานแหล่งที่มา",
      "preN_4242": "รหัสใบงานแหล่งที่มา",
      "preN_4143": "รหัสใบงานแหล่งที่มา",
      "preN_4142": "รหัสใบงานแหล่งที่มา",
      "preN_4243": "รหัสใบงานแหล่งที่มา",
      "preN_4210": "รหัสใบงานแหล่งที่มา",
      "preN_4160": "รหัสใบงานแหล่งที่มา",
      "preN_4260": "รหัสใบงานแหล่งที่มา",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "operN_4299": "領用單號",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "dueday_4299": "領用日期",
      "batchno_4010": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4020": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4030": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4110": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4120": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4130": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4141": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4143": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4142": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4243": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4220": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4242": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4230": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4241": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4210": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4160": "รหัสล๊อตการเข้าโกดัง",
      "batchno_4260": "รหัสล๊อตการนำออกจากโกดัง",
      "batchno_4299": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "ยืนยันแก้ไขข้อมูล",
      "txn_confirm_msg": "กรุณาตรวจสอบความถูกต้องข้อมูลที่แก้ไข ก่อนยืนยันลบข้อมูล",
      "opcode": "ประเภทงาน",
      "jobN": "รหัสงาน",
      "planqty": "จำนวนที่วางแผนไว้",
      "realtqty": "จำนวนจริง",
      "bal": "ค่าต่างทางบัญชี",
    },
    "plan-in": {
      "pageHeader": "การรับสิ้นค้า",
    },
    "plan-out": {
      "pageHeader": "การจ่ายสินค้า",
    },
    "stock-search": {
      "searchBatchno": "批號查詢",
      "pageHeader": "เช็คสถานะสต๊อกสินค้า",
      "searchPN": "เช็ครหัสวัตถุดิบ",
      "searchWh": "เช็คโกดังแต่ละที่",
      "whDetails": "รายละเอียดโกดังย่อย",
      "changeDetails": "รายละเอียดการปรับแก้ไข",
      "borrow": "ยืมเข้า",
      "inTransit": "จำนวนที่อยู่ระหว่างเตรียมส่ง",
      "loan": "ยืมออก",
      "lockqty": "จำนวนที่ระงับใช้",
      "onhandqty": "จำนวนสต๊อกใช้ได้จริง",
      "planin": "การวางแผนสินค้าเข้าคลัง",
      "planout": "การวางแผนจ่ายสินค้า",
      "posted": "ผ่านบัญชีแล้ว",
      "scanin": "ตรวจเช็คเข้าคลัง",
      "scanout": "ตรวจสอบจ่ายสินค้า",
      "timeSeries": "ลำดับเวลา",
      "total": "จำนวนใช้ได้จริงที่คาดไว้",
      "totalIn": "รวมรับเข้าคลังทั้งสิ้น",
      "totalOut": "รวมจ่ายออกทั้งสิ้น",
      "tqty": "จำนวนสต๊อก",
    },
    "inv-history": {
      "pageHeader": "บันทึกสินค้าคงคลังรายเดือน",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "หนา",
      "inCBM": "進\n(CBM)",
      "l": "ยาว",
      "level": "เกรด",
      "suppalias": "ซัพพลายเออร์",
      "w": "กว้าง",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "代理入庫作業",
      "lifeF_T": "已規劃",
      "wmsopUUID": "庫儲作業單號",
      "inchargeID": "負責人員",
    },
  },
  "ADM": {
    "util": {
      "pfNM": "ชื่อขบวนการผลิต",
      "pushlayer": "ระดับขั้นในการส่งกระจายข้อมูล",
      "pf": "ขบวนการผลิต",
      "pfID": "รหัสไอดีขบวนการผลิต",
      "pwc": "ศูนย์กลางการทำงาน",
      "wpcSearch": "ค้นหาสถานะการขนส่ง",
      "dispachManage": "การบริหารจัดการในจัดสินค้า",
      "ppidp": "ผูกมัดสินค้าสำเร็จรูป",
      "partsBinding": "สแกนบาร์โค้ดสินค้า",
      "mail": "อีเมลล์",
      "postID": "ตำแหน่งงาน",
      "sectID": "工類",
      "sectID_C": "間接人力",
      "sectID_D": "直接人力",
    },
    "oem-manage": {
      "type": "ประเภทการจ้างงานภายนอก",
      "oem_item_manage": "ตั้งค่ารายการที่จัดจ้างงานภายนอก",
      "oem_item": "รายการที่จัดจ้างงานภายนอก",
      "type_A": "บุคลากร",
      "type_B": "จัดการพื้นผิว",
      "type_C": "ช่างกล",
      "oem_supp_manage": "การบำรุงรักาาการวานให้จัดส่งจากภายนอก",
      "oem_supp": "วานให้จัดส่งจากภายนอก",
      "vendorcode": "รหัสแทนการจัดส่ง",
      "oemitemNM": "ชื่อรายการที่จัดจ้างภายนอก",
    },
    "function-params-manage": {
      "pageHeader": "ตั้งค่าพารามิเตอร์ของระบบ",
      "searchPlaceholder": "ค้นห้าID หน่วยงาน รหัสแทน รหัสฟังก์ชั่น ชื่อระกับขั้น",
      "functions": "กลุ่มในการส่งกระจายข้อมูล",
      "unitNM1": "ชื่อหน่วย",
      "type": "ลักษณะการบันทึกปริมาณ",
      "category": "ประเภทการบันทึกปริมาณ",
      "countType": "รูปแบบการบันทึกปริมาณ",
      "quantitative": "รูปแบบการบันทึกปริมาณ",
      "count": "計數",
      "weight": "น้ำหนัก",
      "length": "ความยาว",
      "capacity": "หน่วยปริมาตร",
      "area": "หน่วยพื้นที่",
      "volume": "หน่วยปริมาณ",
      "sort": "เรียงลำดับ",
      "currencyitem": "รหัสแทนสกุลเงิน",
      "itemNM": "ชื่อสกุลเงิน",
      "currency": "สกุลเงินในการซื้อขาย",
      "ismycurrency": "ใช่สกุลเงินเดิมหรือไม่",
      "funcode": "รหัสฟังก์ชั่นองค์กร",
      "funNM": "ชื่อฟังก์ชั่นการทำงาน",
      "function": "การตั้งค่ากลุ่มการส่งกระจาย",
      "lvlcode": "รหัสระดับขั้น",
      "lvlNM": "ชื่อระดับขั้น",
      "team": "1(ทีม)",
      "class": "2(ระดับแผนก",
      "management": "3(ระดับผู้จัดการ)",
      "total": "4(ระดับผุ้จัดการใหญ่)",
      "eventID": "IDเหตุการณ์",
      "eventNM": "ชื่อเหตุการณ์",
      "rptcode": "ประเภทการแจ้ง",
      "pusher": "ระบบที่แจ้ง",
      "situF": "สัญญาณไฟเดือน",
      "audience": "กลุ่มที่รับการส่งกระจาย",
      "startlvl": "ระดับชั้นที่เริ่มต้นการแจ้ง",
      "setlvl": "ระดับชั้นในการส่งกระจาย",
      "cruisebot": "โปรแกรมเฝ้าสังเกตการณ์",
      "msgmodel": "เทมเพลตในการส่งกระจายข้อความ",
      "pushEvent": "เหตุการณ์ที่ส่งกระจาย",
      "rptcode_A0": "รายงานโดยบุคลากร",
      "rptcode_B0": "แจ้งเตือนโดยระบบ(WOS)",
      "rptcode_C0": "設備提報",
      "rptcode_D0": "รายงานการควบคุม_ทางไกล",
      "rptcode_E0": "รายงานการควบคุม_คลาวด์",
      "situF_Y": "เหลือง",
      "situF_R": "แดง",
      "startlvl_0": "พนักงานทั้งหมด",
      "startlvl_1": "ทีมงานบริหาร",
      "startlvl_2": "หัวหน้าระดับแผนก",
      "startlvl_3": "หัวหน้าระดับผู้จัดการ",
      "startlvl_4": "ผู้บริหารระดับผู้จัดการใหญ๋",
      "add_string": "เพิ่มกลุ่มคำ",
      "add_number": "เพิ่มจำนวนตัวอักษร",
      "add_point": "เพิ่มจุดทศนิยม",
      "1_decimal_place": "หลังทศนิยม 1จุด",
      "2_decimal_place": "หลังทศนิยม 2จุด",
    },
    "procedure-manage": {
      "pageHeader": "บำรุงรักษาและวางแผนขบวนการผลิต",
      "pccID": "รหัสไอดีศูนย์ควบคุมต้นทุน",
      "pccNM": "ชื่อศูนย์ควบคุมต้นทุน",
      "pwcID": "รหัสไอดีศูนย์กลางการทำงาน",
      "pwcNM": "ชื่อศูนย์กลางการทำงาน",
      "tabCost": "ตั้งค่าศูนย์ควบคุมต้นทุน",
      "tabFlow": "ตั้งค่าไฟล์ข้อมูลขบวนการผลิต",
      "tabWork": "ตั้งค่าศูนย์กลางการทำงาน",
    },
    "user-manage": {
      "dashboard": "數位生產看板",
      "abc-board": "異常管理看板",
      "BDM": "物料資料管理",
      "SDM": "系統資料管理",
      "TDM": "廠區資料管理",
      "EDM": "模具資料管理",
      "WMS": "庫存管理系統",
      "OMS": "銷貨管理系統",
      "PMS": "採購管理系統",
      "WOC": "生產控制",
      "MC": "物料作業",
      "QC": "質控作業",
      "TK": "生產看板",
      "account": "員工帳號",
      "pwd": "密碼",
      "role": "角色權限",
      "roleID": "角色ID",
      "roleNM": "角色名稱",
      "systemAuth": "系統登入權限",
      "autoReport": "接收自動報表",
      "APP": "行動APP",
      "PDA": "PDA權限",
      "dealmgmt": "對象授權分類",
      "pdtmgmt": "物料授權分類",
      "whmgmt": "倉庫授權分類",
      "cardID": "หมายเลขบัตร",
      "function": "組織功能",
      "pageHeader": "員工資料維護",
      "peopleNM": "員工姓名",
      "personalID": "หมายเลขบัตรประจำตัว",
      "proxyNM": "聯絡人姓名",
      "proxytel": "聯絡人電話",
      "staffAuth": "角色權限設定",
      "staffData": "員工資料管理",
      "staffID": "員工工號",
    },
    "trading-pattern": {
      "OMS": "ระบบออเดอร์",
      "PMS": "ระบบจัดซื้อ",
      "adda": "บันทึกข้อกำหนด",
      "addb": "เพิ่มรายการหักเงิน",
      "addc": "การชำระเงินล่วงหน้า",
      "addd": "วิธีการชำระเงิน",
      "adde": "เงื่อนไขทางการค้า",
      "addf": "ดำเนินการคืนสินค้า",
      "addg": "ยกเลิกออเดอร์",
      "addh": "เงื่อนไขการชำระเงิน",
      "addi": "เงื่อนไขการเก็บเงิน",
      "additem": "รหัสไอดีรายการ",
      "addr": "ที่อยู่ติดต่อ",
      "addtype": "เงื่อนไขการซื้อขาย",
      "contactitem": "รหัสแทนช่องทางติดต่อ",
      "department": "แผนก",
      "itemNM": "ชื่อช่องทางติดต่อ",
      "mobile": "เบอร์โทรศัพท์มือถือ",
      "pageHeader": "ตั่งค่ารายการสายอักษร",
      "tabAdded": "ตั่งค่ารายการเสริม",
      "tabWindow": "ตั้งค่าช่องทางติดต่อภายนอก",
      "tabPMSAdded": "เงื่อนไขเสริมในการจัดซื้อ",
      "tabOMSAdded": "เงื่อนไขเสริมในการขายสินค้า",
    },
    "data-auth-manage": {
      "mgmtitem": "รายการแบ่งประเภท",
      "pageHeader": "ตั้งค่าฟั้งก์ชั่นการมอบอำ่นาจ",
      "tabCust": "客資授權分組",
      "tabPdt": "物料授權分組",
      "tabSupp": "廠資授權分組",
      "tabWh": "庫房授權分組",
    },
    "category-manage": {
      "mgmtitem": "รายการแบ่งประเภท",
      "pageHeader": "ตั้งประเภทการค้นหา",
      "tabCust": "ประเภทการค้นหาลูกค้า",
      "tabQuery": "ประเภทการค้นหาข้อมูล",
      "tabSupp": "ประเภทการค้นหาบริษัทผู้ขาย",
      "tabMtype": "物料機種分類",
    },
    "warehouse-manage": {
      "cancelClose": "ยกเลิกปิดบัญชี",
      "close": "ปิดบัญชี",
      "isClose": "ปิดบัญชีหรือไม่",
      "mgmtcat": "มอบอำนาจคลังสินค้า",
      "pageHeader": "บำรุงรักษาข้อมูลคลังนสินค้า",
      "pdtclass": "ระดับเกรดวัสดุ",
      "ps": "ห้องเครื่อง",
      "tabWareHouse": "ตั้งค่าข้อมูลโกดัง",
      "whID": "รหัสไอดีโกดัง",
      "whNM": "ชื่อโกดัง",
      "psNM": "ชื่อห้องเครื่อง",
    },
    "string-manage": {
      "pageHeader": "การบริหารจัดการสายตัวอักษร",
      "tabPause": "รายการหยุดการผลิต",
      "tabOkItem": "รายการสินค้ามาตรฐาน",
      "tabNgItem": "รายการสินค้าตำหนิ",
      "tabDri": "หน่วยงานที่รับผิดชอบ",
      "tabOffLine": "รายการที่ออฟไลน์",
      "driItemID": "รหัสไอดีของหน่วยงาน",
      "driItemNM": "ชื่อหน่วยงาน",
    },
    "client": {
      "groupalias": "ชื่อย่อองค์กร",
      "location": "เขตพื้นที่ตลาด",
      "account": "หมายเลขธนาคาร",
      "addr": "ที่อยู่บริษัท",
      "bankAddrOS": "ที่อยู่ธนาคาร",
      "bankCodeOS": "รหัสสาขา",
      "branchOS": "ชื่อสาขา",
      "classificationOfRestrictions": "การแยกประเภทขอบเขตอำนาจ",
      "country": "ประเทศ",
      "customerBasicInformation": "ข้อมูลพื้นฐานของลูกค้า",
      "customerContactInformation": "ข้อมูลการติดต่อลูกค้า",
      "department": "แผนก",
      "domesticBankName": "ชื่อธนาคารภายในประเทศ",
      "domestic_OverseasBankInformation": "ข้อมูลธนาคารภายใน/ต่างประเทศ",
      "item": "10รายการ/หน้า",
      "itemNM": "ฟังก์ชั่นช่องทางติดต่อ",
      "maxcredit": "เครดิตเทอม",
      "mobile": "โทรศัพท์มือถือ",
      "overseasBankName": "ชื่อธนาคารต่างประเทศ",
      "pageHeader": "บำรุงรักษาข้อมูลลูกค้า",
      "pageHeader2": "การบริหารจัดการข้อมูลลูกค้า",
      "payterm": "เงื่อนไขการชำระเงิน",
      "showTotal": "รวม 1 รายการ กำลังแสดงรายการที่ 1ถึง 1",
      "swiftCode": "รหัสสวิฟต์โค้ด ",
      "taxID": "เลขที่จดทะเบียนทางการค้า",
      "username": "ชื่อบัญชี",
    },
    "company": {
      "manufacturerBasicInformation": "ข้อมูลพื้นฐานซัพพลายเออร์",
      "manufacturerContactInformation": "ข้อมูลติดต่อซัพพลายเออร์",
      "pageHeader": "บำรุงรักษาข้อมูลซัพพลายเออร์",
      "groupalias": "ชื่อย่อองค์กร",
      "location": "市場區域",
      "account": "หมายเลขบัญชีธนาคาร",
      "addr": "ที่อยู่บริษัท",
      "bankAddrOS": "ที่อยู่ธนาคาร",
      "bankCodeOS": "รหัสสาขา",
      "branchOS": "ชื่อสาขา",
      "classificationOfRestrictions": "การแบ่งประเภทขอบเขตอำนาจ",
      "country": "ประเทศ",
      "department": "แผนก",
      "domesticBankName": "ชื่อธนาคารภายในประเทศ",
      "domestic_OverseasBankInformation": "ข้อมูลธนาคารภายใน/นอกประเทศ",
      "item": "10รายการ/หน้า",
      "itemNM": "ฟังก์ชั้นช่องทางติดต่อ",
      "maxcredit": "เครดิตเทอม",
      "mobile": "เบอร์โทรศัพท์มือถือ",
      "overseasBankName": "ชื่อธนาคารต่างประเทศ",
      "payterm": "เงื่อนไขการชำระเงิน",
      "showTotal": "รวม 1รายการ/กำลังแสดงรายการที่ 1 ถึง 1",
      "swiftCode": "รหัสสวิฟท์โค้ต",
      "taxID": "เลขที่ทะเบียนทางการค้า",
      "username": "ชื่อบัญชี",
    },
    "edm": {
      "alertspeed": "การแจ้งเตือนความเร็วการผลิต",
      "alertstop": "แจ้งเตือนหยุดเกินเวลา",
      "warnyield": "แจ้งเตือนอัตราชิ้นงานมาตรฐานไฟเหลือง",
      "alertyield": "แจ้งเตือนอัตราชิ้นงานมาตรฐานไฟแดง",
      "attribute": "ประเภทวัสดุ",
      "bom": "บำรุงรักษาบิลวัสดุ",
      "errorPleaseFull": "กรุณากรอกข้อมูลให้ครบถ้วน",
      "ieUUID": "เลขที่ใบคิดอัตรแลกเปลี่ยน",
      "isrework": "ทำการผลิตซ็ำ",
      "level": "การแบ่งระดับวัสดุ",
      "nextSN": "ขบวนการผลิตรอง",
      "pPN": "รหัสวัสดุหลัก",
      "pageHeader": "การบำรุงรักษาข้อมูลด้านเทคนิค",
      "pdtclass": "ประเภทสินค้า",
      "pfNM": "ขบวนการผลิต",
      "planhead": "จำนวนคนที่วางแผนไว้",
      "ppdtNM": "ชื่อวัสดุหลัก",
      "productionScheduleDetails": "รายละเอียดขบวนการผลิต",
      "qty": "จำนวนชุด",
      "stdlt": "เวลาในการเตรียมการผลิต",
      "stdmh": "มาตรฐานการผลิตของเครื่อง",
      "stdwh": "มาตรฐานเวลาการผลิตของคน",
      "batchbom": "จำนวนถุงเต็มล๊อต",
      "ver": "เวอร์ชั่น",
      "wpcManage": "บำรุงรักษาข้อมูลขบวนการผลิต",
      "wpc": "ขบวนการผลิต",
      "wpcSN": "รหัสขบวนการผลิต",
      "wpcSetting": "ตั้งรหัสขบวนการผลิต",
      "wpcset": "กลุ่มขบวนการผลิต",
      "wpcsetNM": "ชื่อขบวนการผลิต",
      "whID": "เข้าโกดังที่",
      "alternative_wpc": "ขบวนการผลิตสำรอง",
      "main_wpc": "ขบวนการผลิตหลัก",
      "edit_all_wpc": "แก้ไขขบวนการผลิตทั้งหมด",
      "Pfc": "การควบคุมแบบฉบับเอกสารวิศวกรรม",
      "doc": "ประกาศใช้เอกสารมาตรฐาน",
      "ECN": "แจ้งการเปลี่ยนโปรเจ็ค",
      "pdftype": "ประเภทไฟล์ข้อมูล",
      "docUpload": "อัพโหลดไฟล์ข้อมูล",
      "Upload": "อัพโหลด",
      "docName": "ขื่อไฟล์ข้อมูล",
      "docVer": "เวอร์ชั้นไฟล์ข้อมูล",
    },
    "pn-manage": {
      "bom": "單位轉換",
      "basicProduct": "ข้อมูลพื้นฐานของสินค้า",
      "dealPNSearch": "ค้นหารหัสวัสดุที่ซื้อขาย",
      "boxH": "ขนาดการห่อกหุ้ม(สูง)",
      "boxL": "ขนาดการห่อหุ้ม(ยาว)",
      "boxW": "ขนาดการห่อหุ้ม(กว้าง)",
      "dealID": "รหัสไอดีเป้าหมาย",
      "dealNM": "ชื่อสินค้าซื้อขาย",
      "dealPN": "รหัสวัสดุที่ซื้อขาย/SKU",
      "dealalias": "ชื่อย่อเป้าหมาย",
      "dealpid": "บาร์โค้ต",
      "idkw": "คีย์เวิล์ดบาร์โค้ตการห่อหุ้ม",
      "inititalcost": "กำหนดการเริ่มต้น",
      "isfocus": "ติดตามหรือไม่",
      "isvalid": "ได้ผลหรือไม่",
      "maxcost": "ยอดเงินสินค้าคงคลังสูงสุด",
      "maxstock": "สินค้าคงคลังสูงสุด",
      "minstock": "สินค้าคงคลังต่ำสุด",
      "moq": "ปริมาณต่ำสุดในการจัดซื้อ",
      "newPN": "รหัสวัสดุเวอร์ชั่นใหม่",
      "nextpacksn": "จำนวนชั้นห่อหุ้มภายนอก",
      "pack": "หน่วยห่อหุ้ม",
      "packsn": "จำนวนชั้นในการห่อหุ้ม",
      "packtype": "จุดประสงค์การห่อหุ้ม",
      "pageHeader": "บำรุงรักษาข้อมูลพื้นฐาน",
      "palH": "พาเลท(สูง)",
      "palL": "พาเลท(ยาว)",
      "palW": "พาเลท(กว้าง)",
      "pbom": "จำนวนชั้นการห่อชั้นใน",
      "pdtH": "ขนาดสินค้า(สูง)",
      "pdtL": "ขนาดสินค้า(ยาว)",
      "pdtW": "ขนาดสินค้า(กว้าง)",
      "punit": "หน่วยที่ใช้ด้านใน",
      "purchasecost": "ต้นทุนการเข้าสินค้าในไตรมาสนี้",
      "purchaseqty": "ปริมาณการนำเข้าวัสดุในไตรมาสนี้",
      "replacePN": "รหัสวัสดุแทน",
      "safestock": "สินค้าคงคลังขั้นต่ำ",
      "salecurrency": "ราคาสกุลเงิน",
      "setbatch": "รหัสล๊อตบัญชี",
      "setidm": "ตั้งค่าบาร์โค้ต",
      "setpack": "ตั้งค่าการห่อหุ้ม",
      "settlemonth": "เดือนที่ตัดยอดค่าผันแปร",
      "shipV": "ปริมาตรการห่อหุ้ม",
      "shipW": "น้ำหนักขนส่ง",
      "sizeunit": "หน่วยความยาว",
      "stdcost": "ต้นทุนมาตรฐาน",
      "stdsale": "ราคึามาตรฐาน",
      "stdstock": "มาตรฐานสินค้าคงคลัง",
      "stocklife": "รอบเวลาสินค้าคงคลัง",
      "tabCost": "ตั้งค่ามาตรฐานต้นทุน",
      "tabDeal": "ตั้งค่ารหัสวัสดุซื้อขาย",
      "tabExtra": "ตั้งค่าขนาดสินค้า",
      "tabIdkw": "ตั้งค่าการผูกมัดการผลิต",
      "tabPack": "ตั้งค่าการผูกมัดการห่อหุ้ม",
      "tabPrice": "ตั้งค่ามาตรฐานราคา",
      "tabStock": "ตั้งค่าเงื่อนไขสินค้าคงคลัง",
      "unitwt": "น้ำหนักต่อชิ้น",
      "varcost": "ต้นทุนผันแปร",
      "ver": "เวอร์ชั่นการห่อหุ้มมาตรฐาน",
      "weightunit": "หน่วยน้ำหนัก",
    },
    "mold": {
      "pageHeader": "บำรุงรักษาข้อมูลแม่พิมพ์",
      "mold": "ข้อมูลแม่พิมพ์",
      "assetkind": "ประเภทแม่พิมพ์",
      "assetID": "รหัสแม่พิมพ์",
      "assetNM": "ชื่อทรัพสินย์",
      "moldtype": "แม่พิมพ์ใช้ร่วม",
      "cavity": "จำนวนรู",
      "stdcavity": "จำนวนรูมาตรฐาน",
      "validcavity": "จำนวนรูที่มีผล",
      "wsID": "สถานนีทำงาน",
      "property": "เจ้าของทรัพย์สิน",
      "company": "บริษัท",
      "gatesystem": "ลักษณะช่องเปิด",
      "moldlife": "อายุไขตามการออกแบบ",
      "draw": "แผนภาพแม่พิมพ์",
      "pdtpic": "รูปภาพผลิตภัณฑ์สำเร็จรูป",
      "malepic": "รูปภาพแม่พิมพ์ตัวผู้",
      "femalepic": "รูปภาพแม่พิมพ์ตัวเมีย",
      "wholepic": "รูปภาพแม่พิมพ์ประกอบเสร็จ",
      "advance": "รายละเอียดข้อมูลแม่พิมพ์",
      "length": "ความยาวแม่พิมพ์ทั้งหมด",
      "width": "ความกว้างแม่พิมพ์ทั้งหมด",
      "height": "ความสูงแม่พิมพ์ทั้งหมด",
      "weight": "น้ำหนักแม่พิมพ์",
      "openC": "จำนวนครั้งในการใช้",
      "openT": "วันที่ได้รับ",
      "cummoldC": "ยอดรวมการผลิต",
      "precummoldC": "ยอดรวมจำนวนการผลิตครั้งที่แล้ว",
      "status": "สถานะสัญญาณไฟ",
      "note": "โน๊ต",
    },
    "plant-manage": {
      "pageHeader": "ตั้งค่าไฟล์หลักโรงงาน",
      "plantID": "โรงงานที่",
      "deptID": "แผนก",
      "push1": "ระยะห่างการส่งกระจายข้อมูลระดับ1",
      "push2": "ระยะห่างการส่งกระจายข้อมูลระดับ2",
      "push3": "ระยะห่างการส่งกระจายข้อมูลระดับ3",
      "tabDept": "ไฟล์หลักของแผนก",
      "tabPlant": "ไฟล์หลักของเขตโรงงาน",
      "tabPost": "ไฟล์หลักของตำแหน่ง",
    },
    "production-line-manage": {
      "MC": "การดำเนินการเกี่ยวกับวัสดุ",
      "QC": "การดำเนินการด้านการควบคุมคุณภาพ",
      "TK": "แดชบอร์ดการผลิต",
      "WOC": "ควบคุมการผลิต",
      "auth_setting": "ตั้งค่าสิทธิ์อำนาจ",
      "batch": "บริหารจัดการรหัสล๊อต",
      "mold": "การบริหารจัดการแม่พิมพ์",
      "oem": "มอบหมายจ้างงานภายนอก",
      "pageHeader": "วางแผนและบำรุงรักษาไลน์ผลิต",
      "pcc": "ศูยน์ควบคุมต้นทุน",
      "ps": "ห้องเครื่อง",
      "psID": "รหัสไอดีห้องเครื่อง",
      "psNM": "ชื่อห้องเครื่อง",
      "tabLine": "ตั้งค่าข้อมูลไลน์งาน",
      "tabPS": "ตั้งค่าข้อมูลห้องเครื่อง",
      "tabStation": "ตั้งค่าข้อมูลสถานนีงาน",
      "tbHeader_PS": "ข้อมูลสถานนีงาน",
      "tbHeader_Station": "ข้อมุลไลน์ผลิต",
      "whm": "การบริหารเวลางาน",
      "wlID": "รหัสไอดีไลน์งาน",
      "wlNM": "ชื่อไลน์งาน",
      "ws": "สถานนีงาน",
      "wsID": "รหัสไอดีสถานนีงาน",
      "wsNM": "ชื่อสถานนีงาน",
    },
  },
  "APS": {
    "util": {
      "opcode": "ประเภทใบงาน",
      "opcode_3310": "ใบสั่งงานปกติ",
      "opcode_3320": "ใบสั่งงานผลิตซ้ำ",
      "opcode_3330": "ใบสั่งงานทดลองผลิต",
      "opcode_3340": "ใบสั่งงานวิศวกรรม",
      "duestart": "กำหนดเสร็จสิ้นตั้งแต่วันที่",
      "dueend": "กำหนดเสร็จสิ้นภายในวันที่",
      "realqty": "จำนวนตามความเป็นจริง",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "การสั่งงานฝ่ายควบคุมการผลิต",
      "wo": "ใบสั่งงาน",
      "tabWo": "ไฟล์ข้อมูลใบสั่งงาน",
      "mo": "ใบวัสดุ",
      "jobN": "รหัสใบสั่งงาน",
      "tabMo": "ไฟล์ข้อมูลวัสดุ",
      "orderN": "รหัสใบออเดอร์ลูกค้า",
      "ETDstart": "กำหนดวันที่เริ่มส่งมอบสินค้า",
      "ETDend": "กำหนดสิ้นสุดวันส่งมอบสินค้า",
      "issueID": "ผู้ประกาศ",
      "issueday": "วันที่ประกาศ",
    },
  },
  "PMS": {
    "util": {
      "opcode_2010": "แผนการสอบถามราคา",
      "opcode_2210": "ยื่นขอจัดซื้อภายในประเทศ",
      "opcode_2220": "ขอจัดซื้อจากต่างประเทฯศ",
      "opcode_2230": "ยื่นขอการจ้างภายนอก",
      "opcode_2310": "การจัดซื้อภายในประเทศ",
      "opcode_2320": "จัดซื้อจากต่างประเทศ",
      "opcode_2330": "จัดจ้างแปรรูปภายนอก",
      "opcode_2410": "วัสดุภายในประเทศ",
      "opcode_2420": "วัสดุต่างประเทศ",
      "opcode_2430": "มอบหมายจัดซื้อภายนอก",
      "opcode_2610": "ตีคืนจากโกดัง",
      "opcode_2620": "ตีคืนจากฝ่ายคิวซี",
      "opcode_2710": "เข้าวัสดุจาการสั่งซื้อ",
      "opcode_2810": "ตัดบัญชีรายการค้างชำระ",
      "opcode_4120": "ใบตรวจรับ",
      "opcode_4230": "ใบตรวจตีคืน",
      "plan": "ใบสอบถามราคา",
      "req": "ใบยื่นขอจัดซื้อ",
      "pro": "ใบสั่งซื้อ",
      "instock": "ใบเข้าวัสดุ",
      "gostock": "ใบตรวจเช็ควัสดุเข้า",
      "return": "ตีคืนวัสดุเข้า",
      "pay": "ค้นหาบัญชีค้างขำระ",
      "ap": "ใบตรวจเช็คบัญชี",
      "strike": "ชำระเงินตัดบัญชี",
      "N22": "รหัสใบยื่นขอจัดซื้อ",
      "N23": "รหัสใบจัดซื้อ",
      "N24": "รหัสใบเข้าวัสดุ",
      "N27": "รหัสบิลที่ตรวจเทียบบัญชี",
      "N28": "รหัสบิลตัดบัญชี",
      "outN": "รหัสใบตีคืน",
      "planSearch": "ค้นหาบันทึกประวัติการสอบถามราคา",
      "reqSearch": "ค้นหาบันทึกประวัติการยื่นขอจัดซื้อ",
      "proSearch": "ค้นหาบันทึกการจัดซื้อ",
      "instockSearch": "ค้นหาข้อมูลบันทึกการเข้าวัสดุ",
      "stockSearch": "ค้นหาบันทึกการตรวจรับ",
      "strikeSearch": "ค้นหาบันทึกการผ่านบัญชี",
      "apSearch": "ค้นหาข้อมูลการตรวจเช็คบัญชี",
      "drawno": "รหัสภาพ",
      "unitprice": "採購單價",
      "localprice": "ราคาต่อหน่วยตามสกุลเงินเดิม",
    },
    "plan": {
      "pageHeader": "แผนการสอบถามราคา",
      "hd_delete_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลสอบถามราคาที่ต้องการลบออกก่อนยืนยันลบออก",
      "demandqty": "่จำนวนความต้องการ",
      "tb": "รายละเอียดแผนการ",
      "tb_drawer_title": "รายละเอียดข้อมูลแผนการสอบถามราคา",
      "rec_drawer_title": "ประเมินสเปคสินค้า",
    },
    "req": {
      "pageHeader": "การยื่นขอจัดซื้อ",
      "create_header": "เพิ่มไฟล์ข้อมูลขอจัดซื้อ",
      "edit_header": "แก้ไขไฟล์ข้อมูลการขอจัดซื้อ",
      "hd_note": "บันทึกการขอจัดซื้อ",
      "driID": "รับผิดชอบจัดซื้อ",
      "punit": "ฝ่ายที่ยื่นขอจัดซื้อ",
      "unitprice": "ราคาขอจัดซื้อต่อหน่าย(ไม่รวมภาษี)",
      "demandqty": "จำนวนขอสั่งซื้อ",
      "demandDate": "วันที่ต้องการ",
      "itemtotal": "ยอดเงินทั้งสิ้นไม่รวมภาษี",
      "local_subtotal": "ยอดเงินทั้งหมดรวมภาษีตามสกุลเงินเดิม",
      "total": "ยอดเงินทั้งสินรวมภาษี(สกุลเงินซื้อขาย)",
      "lifeF_0": "ประกาศ",
      "lifeF_2": "ตรวจซ้ำการยื่นขอจัดซื้อ",
      "lifeF_7": "การตรวจซ้ำส่งเซ็นต์เสร็จสิ้น",
      "lifeF_T": "ปิดเคส",
      "del_hd_confirm_text": "กรุณาตรวจเช็คความถูกต้องใบขอจัดซื้อที่ต้องการลบออกก่อนทำการลบออก",
      "audit_start": "ตรวจสอบส่งเซ็นต์อย่างเป็นทางการ",
      "audit_finish": "เสร็จสิ้นการตรวจสอบ",
      "reqCheck": "ตรวจซ้ำใบขอจัดซื้อ",
      "cancel_reqCheck": "ยกเลิกตรวจซ้ำการขอจัดซื้อ",
      "check_confirm": "ยืนยันตรวจซ้ำการขอจัดซื้อ",
      "check_confirm_text": "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการวตรวจซ้ำก่อนทำการตรวจซ้ำ",
      "cancel_check_confirm": "ยืนยันยกเลิกการวตรวจซ้ำการขอจัดซื้อ",
      "cancel_check_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการยกเลิกตรวจซ้ำก่อนยกเลิก",
      "reqCheck_lifeF_0_okText": "ยืนยันตรวจซ้ำ",
      "reqCheck_lifeF_2_okText": "ยกเลิกตรวจซ้ำ",
      "audit_confirm": "ยันยืนการเซ็นต์อนุมัติ",
      "audit_confirm_text":
        "หลังจากเซ็นต์อนุมัติแล้วจะไม่สามราถแก้ไขใบขอจัดซื้อได้อีก จะยืนยันการเซ็นต์อนุมัติหรือไม่",
      "audit_okText": "ยืนยันการตรวจสอบ",
      "driID_placeholder": "กรุณาเลือกผู้ขอจัดซื้อ",
      "demandqty_placeholder": "กรุณากรอกจำนวนขอจัดซื้อ",
      "unitprice_placeholder": "กรุณากรอกราคาต่อหน่วยไม่รวมภาษี",
      "tb_delete_confirm": "ยืนยันลบรายละเอียดรายการขอจัดซื้อ",
      "tb_title": "รายละเอียดรายการขอจัดซื้อ",
      "create_tb_title": "เพิ่มรายละเอียดรายการขอจัดซื้อ",
      "edit_tb_title": "แก้ไขรายละเอียดรายการขอจัดซื้อ",
      "throw_modal_submit": "ยืนยันการโอนย้าย【ใบสั่งซื้อ】",
    },
    "pro": {
      "pageHeader": "การจัดซื้อ",
      "create_header": "สร้างไฟล์ข้อมูลการจัดซื้อ",
      "edit_header": "แก้ไขไฟล์จัดซื้อ",
      "hd_drawer_title": "ไฟล์ข้อมูลใบจัดซื้อ",
      "driID": "รับผิดชอบจัดซื้อ",
      "sampleID": "ผู้สุ่มตัวอย่าง",
      "supp_history": "ค้าหาประวัติการซื้อขายกับซัพพลายเออร์",
      "trade": "เทอมทางการค้า",
      "dealaddr": "ที่อยู่ซัพพลายเออร์",
      "contact": "ช่องทางติดต่อส่งมอบสินค้าซัพพลายเออร์",
      "dealcontact": "ช่องทางติดต่อซัพพลายเออร์",
      "payment": "เงื่อนไขการชำระเงิน",
      "sumtotal": "ยอดเงินทั้งสิ้นไม่รวมภาษี",
      "local_subtotal": "ยอดเงินทั้งสิ้นรวมภาษีตามสกุลเงินนี้",
      "total": "ยอดเงินภาษีทั้งสิ้นรวมภาษี",
      "local_total": "ยอดเงินทั้งสิ้นรวมภาษี(ตามสกุลเงินซื้อขาย)",
      "note": "โน๊ต",
      "demandqty": "จำนวนจัดซื้อ",
      "punit": "ฝ่ายจัดซื้อ",
      "confirmqty": "จำนวนที่ซัพพลายเออร์ยืนยัน",
      "dealN": "รหัสบิลซัพพลายออเออร์",
      "cancelqty": "ยกเลิกจำนวน",
      "returnqty": "จำนวนสินค้าตีคืน",
      "charge": "รายการเงินหักที่เพิ่มเติม",
      "ReviewProcess": "ขั้นตอนการตรวจอนุมัติ",
      "tb_drawer_title": "รายละเอียดรายการจัดซื้อ",
      "lifeF_0": "ประกาศ",
      "lifeF_2": "ตรวจซ้ำการจัดซื้อ",
      "lifeF_7": "ซัพพลายเออร์เซ็นต์กลับ",
      "lifeF_T": "ปิดเคส",
      "del_hd_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบจัดซื้อที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "hd_close_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบจัดซื้อที่ต้องการบังคับปิดก่อนยืนยันคำสั่งบังคับปิด",
      "proT": "วันที่จัดซื้อ",
      "dealN_placeholder": "กรุณากรอกรหัสบิลซัพพลายเออร์",
      "payment_placeholder": "กรุณาเลือกวิธีการชำระเงิน",
      "trade_placeholder": "กรุณาเลือกเทอมทางการค้า",
      "dealcontact_placeholder": "กรุณาเลือกช่องทางติดต่อซัพพลายเออร์",
      "contact_placeholder": "กรุณาเลือกช่องทางติดต่อการส่งมอบสินค้าซัพพลายเออร์",
      "unitprice_placeholder": "กรุณากรอกราคาต่อหน่วยไม่รวมภาษี",
      "demandqty_placeholder": "กรุณากรอกจำนวนยื่นขอจัดซื้อ",
      "note_placeholder": "กรุณากรอกบันทึกการยื่นขอจัดซื้อ",
      "statement_placeholder": "กรุณากรอกการชี้แจ้งโปรเจค",
      "amount_placeholder": "กรุณากรอกจำนวนเงิน",
      "proCheck": "ตรวจซ้ำการจัดซื้อ",
      "cancel_proCheck": "ยกเลิกการตรวจซ้ำ",
      "suppCheck": "ซับพลายเออร์เซ็นต์กลับ",
      "proCheck_confirm": "ยืนยันตรวจซ้ำการจัดซื้อ",
      "proCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลการจัดซื้อที่ต้องการวตรวจซ้ำก่อนยืนยันการตรวจซ้ำ",
      "cancel_proCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลการจัดซื้อที่ต้องการยกเลิกตรวจซ้ำก่อนยืนยันยกเลิกตรวจซ้ำ",
      "suppCheck_confirm": "ยืนยันการเซ็นต์ตอบซัพพลายเออร์",
      "suppCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการเซ็นต์ตอบก่อนทำการเซ็นต์ตอบ",
      "cancel_suppCheck_confirm": "ยืนยันการยกเลิกการเซ็นต์กลับของซัพพลายเออร์",
      "cancel_suppCheck_confirm_text":
        "กรุณาตรวจความถูกต้องข้อมูลเซ็นต์กลับที่ต้องการยกเลิกก่อนยืนยันยกเลิกการเซ็นต์กลับ",
      "tb": "รายละเอียดการจัดซื้อ",
      "adda": "บันทึกเงื่อนไข",
      "adda_delete_confirm_text": "กรุณาตรวจเช็คความถูกต้องบันทึกเงื่อนไขที่ต้องการลบก่อนทำการลบ",
      "addb": "เพิ่มรายการหัก",
      "addb_delete_confirm_text": "กรุณาตรวจเช็คความถูกต้องรายการที่ต้องการลบออกก่อนทำการลบออก",
      "addc": "การชำระเงินล่วงหน้า",
      "addc_delete_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องรายการชำระล่วงหน้าที่ต้องการลบออกก่อนทำการลบออก",
      "statement": "ชี้แจงโปรเจค",
      "amount": "ยอดเงินไม่รวมภาษี",
      "selectstatement": "กรุณาเลือกรายการหักที่เพิ่ม",
      "totalStart": "ค่าต่ำสุด",
      "totalEnd": "ค่าสูงสุด",
      "deal_history": "ค้นหาประวัติการซื้อขายสินค้า",
      "transferqty": "จำนวนที่โอนย้ายแล้ว",
      "throwqty": "จำนวนที่โอนย้ายในครั้งนี้",
      "message_transfer_small_than_0": "拋轉數量不可小於0",
      "exit_Disposal": "ถอยออกจากการจัดการ",
    },
    "instock": {
      "pageHeader": "การรับวัสดุเข้า",
      "create_header": "สร้างไฟล์ข้อมูลเข้าวัสดุ",
      "edit_header": "แก้ไขไฟล์ข้อมูลเข้าวัสดุ",
      "hd_drawer_title": "ไฟล์ข้อมูลเข้าวัสดุ",
      "sampleID": "ผู้สุ่มตัวอย่าง",
      "sampleT": "วันที่สุ่มตัวอย่าง",
      "note": "โน๊ต",
      "del_hd_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลบิลเข้าวัสดุที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "tb": "รายละเอียดการเข้าวัสดุ",
      "goqty": "จำนวนตรวจรับ",
      "rejectqty": "จำนวนตีคืน",
      "indqty": "จำนวนรับชั่วคราว",
      "inspectID": "ผู้ตรวจรับวัสดุ",
      "inspectT": "เวลาที่เสร็จสิ้นการตรวจ",
      "qalID": "ผู้ตัดสินคุณภาพ",
      "qalT": "เวลาปล่อยผ่าน",
      "lifeF_0": "ประกาศ",
      "lifeF_2": "ตรวจการเข้าวัสดุซ้ำ",
      "lifeF_3": "IQCสุ่มตราวจ",
      "lifeF_4": "IQCตรวจเสร็จสิ้น",
      "lifeF_7": "ฝ่ายคิวซีตรวจซ้ำ",
      "lifeF_T": "結案",
      "IQCstart": "สุ่มตรวจอย่างตอนตรวจวัสดุเข้า",
      "QAcheck": "ฝ่ายคิวซีปล่อยผ่าน",
      "punit": "ฝ่ายที่เข้าวัสดุ",
      "inbatchno": "รหัสล๊อตการเข้าวัสดุ",
      "judgeT": "วันที่ฝ่ายคิวซีตัดสิน",
      "instockCheck_confirm": "ยืนยันตรวจวัสดุเข้าซ้ำ",
      "instockCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลเข้าวัสดุที่ต้องการตรวจซ้ำก่อนยืนยันตรวจซ้ำ",
      "cancel_instockCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลวัสดุตรวจซ้ำที่ต้องการยกเลิกก่อนยืนยันยกเลิกการตรวจซ้ำ",
      "IQCcheck_confirm": "ยืนยันการสุ่มตัวอย่างการวตรวจ",
      "IQCcheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลการตรวจที่ต้องการสุ่มตัวอย่างก่อนยืนยันสุ่มตัวอย่าง",
      "cancel_IQCcheck_confirm_text":
        "กรุณาตรวนเช็คความถูกต้องข้อมูลการตรวจที่ต้องการยกเลิกสุ่มตัวอย่างก่อนยืนยันยกเลิก",
      "IQCfinish_confirm": "ยืนยันIQCตรวจเสร็จสิ้น",
      "IQCfinish_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลIQCที่ต้องการลงตรวจเสร็จสิ้นก่อนยืนยันIQCตรวจเสร็จสิ้น",
      "cancel_IQCfinish_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลIQCที่ต้องการยกเลิกตรวจเสร็นก่อนยืนยันยกเลิก",
      "QAcheck_confirm": "ยืนยันคิวซีปล่อยผ่าน",
      "QAcheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการคิวซีปล่อยผ่านก่อนยืนยันปล่อยผ่าน",
      "cancel_QAcheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการยกเลิกคิวซีปล่อยผ่านก่อนยืนยันยกเลิก",
      "QAQTYcheck":
        "รายละเอียดการเข้าวัสดุ[จำนวนที่ฝ่ายคิวซีตีคืน]+[จำนวนที่ฝ่ายคิวซีปล่อยผ่าน]ต้องเท่ากับ[จำนวนการเข้าวัสดุทั้งหมด]",
      "throwStock_message": "กำลังจะเข้าสู่การโอนย้ายข้อมูลเข้าวัสดุ ต้องการเซฟข้อมูลหรือไม่?",
      "dealN": "รหัสซัพพลายเออร์",
      "takeqty": "จำนวนบิลที่ใช้อ้างอิง",
      "unitprice_placeholder": "กรุณากรอกราคาต่อหน่วยไม่รวมภาษี",
      "Tooltip_disable_take_balqty_0": "จำนวนคงเหลือเป็น 0 ไม่สารมารถเรียกใช้ได้",
      "message_take_before_choose":
        "กรุณาเลือกข้อมูลที่ต้องการเรียกใช้แล้วค่อยกรอกจำนวนที่ต้องการเรียกใช้",
      "message_transfer_small_than_0": "จำนวนการโอนย้ายต้องไม่ต่ำกว่า 0",
      "message_take_same_detail": "ไม่สามาถเรียกใช้รายละเอียดข้อมูลที่เหมือนกัน",
    },
    "gostock": {
      "pageHeader": "ค้นหาข้อมูลการตรวจเช็ควัสดุเข้า",
      "4120_header": "ไฟล์ข้อมูลการตรวจรับ",
      "4230_header": "ไฟล์ข้อมูลการตีคืน",
      "total": "ยอดรวมการโอนย้ายทั้งสิ้น",
      "planqty": "จำนวนการโอนย้าย",
      "jobN": "รหัสใบงานแหล่งที่มา",
      "batchno_placeholder": "กรุณากรอกรหัสล๊อต",
      "note": "โน๊ต",
      "tb": "รายละเอียดการวตรวจรับ",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "บริหารจัดการบัญชีค้างชำระ",
      "ap_create_header": "สร้างไฟล์ข้อมูลการตรวจเทียบบัญชี",
      "ap_edit_header": "แก้ไขไฟล์ข้อมูลการตรวจเทียบบัญชี",
      "ap_sumtotal": "ยอกเงินรวม",
      "strike_sumtotal": "ยอดเงินตัดบัญชี",
      "takeAp": "ใช้ข้อมูลบิลตัดบัญชี",
      "contactWindow": "ข้อมูลช่องทางติดต่อ",
      "chooseWindow": "เลือกช่องทางติดต่อ",
      "ap_tb": "รายละเอียดข้อมูลการตรวจเทียบบัญชี",
      "strike_tb": "รายละเอียดบิลตัดบัญชี",
      "inbatchno": "รหัสล๊อตการนำเข้าสินค้า",
      "itemtotal": "ยอดเงินภาษีค้างขำระ",
      "hubqty": "จำนวนเข้าคลัง",
      "balance": "ค่าต่างทางบัญชี",
      "recon": "ข้อมูลการตรวจเช็คบัญชี",
      "addr": "ที่อยู่ซัพพลายเออร์",
      "addr_placeholder": "กรุณาป้อนที่อยู่ผู้ติดต่อ",
      "strike_tb_delete_confirm": "ยืนยันลบข้อมูลรายละเอียดการตัดบัญชี",
      "paytotal": "ยอดเงินที่ตัดบัญชีแล้ว",
      "ap_total": "ยอดเงินต้องชำระรวมเงินภาษี",
      "apCheck": "ตรวจเช็คบัญชีฤซ้ำ",
      "cancel_apCheck": "ยกเลิกการตรวจซ้ำ",
      "apCheck_confirm": "ยืนยันการตรวจเทียบบัญชีซ้ำ",
      "apCheck_confirm_text": "กำลังจะเข้าสู่การตรวจซ้ำ ต้องการเซฟข้อมูลการตรวจเทียบบัญชีหรือไม่?",
      "releaseAP": "ออกใบตรวจเทียบบัญชี",
      "payment_placeholder": "กรุณาเลือกวิธีการชำระเงิน",
      "cancel_releaseAP": "ยกเลิกออกใบตรวจเช็คบัญชี",
      "releaseAP_confirm": "ยืนยันออกใบตรวจเทียบบัญชี",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck": "ตรวจการตัดบัญชีซ้ำ",
      "strike_create_header": "สร้างไฟล์ข้อมูลบิลตัดบัญชีใหม่",
      "strike_edit_header": "แก้ไขไฟล์การข้อมูลตัดบัญชี",
      "hd_drawer_title": "ไฟล์ข้อมูลการตรวจเช็คบัญชี",
      "itemNM": "โน๊ต",
      "paytype": "วิธีชำระเงิน",
      "strikeCheck_confirm": "ยืนยันการตรวจเช็คบัญญีซ้ำ",
      "strikeCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการตรวจซ้ำก่อนยืนยันตรวจซ้ำ",
      "cancel_strikeCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลตรวจซ้ำที่ต้องการยกเลิกก่อนยืนยันยกเลิก",
      "confirmStrike": "ยืนยันตัดบัญชี",
      "confirmStrike_confirm": "ยืนยันตัดบัญชี",
      "confirmStrike_confirm_text":
        "หลังจากยืนยันตัดบัญชีแล้วบิลนี้จะปิดเคส กรุณายืนยันว่าจะตัดบัญชีหรือไม่",
      "strike_paytotal": "จำนวนเงินที่ชำระ",
      "payT": "เวลาที่ชำระเงิน",
      "note": "บันทึกการตัดบัญชี",
      "PN": "รหัสวัสดุERP",
      "inqty": "จำนวนการโอนย้าย",
      "ap_del_hd_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลบัญชีที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "strike_del_hd_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องบิลตัดบัญชีที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "AP_lifeF_0": "สร้าง",
      "AP_lifeF_1": "ตรวจซ้ำ",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "ตัดบัญชี(ชำระเงิน)ยังไม่ปิดเคส",
      "AP_lifeF_T": "ปิดเคส",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "เสร็จสิ้นการตัดบัญชี",
      "message_chose_take_which_ap": "กรุณาเลือกข้อมูลที่จะใช้ในการตรวจเทียบบัญชี",
      "message_only_take_one_ap": "ใช้ใบตรวจเทียบบัญชีได้ใบเดียวเท่านั้น",
    },
    "return": {
      "pageHeader": "การตีคืนวัสดุเข้า",
      "create_header": "สร้างไฟล์ข้อมูลการตีคืนวัสดุ",
      "edit_header": "แก้ไขไฟล์ข้อมูลการตีคืนวัสดุเข้า",
      "addr": "ที่อยู่ติดต่อ",
      "del_hd_confirm_text": "กรุณาตรวจเช็คความถูกต้องใบตีคืนที่ต้องการลบออกก่อนทำการลบออก",
      "rtnqty": "่จำนวนตีคืน",
      "inbatchno": "รหัสล๊อตการเข้าวัสดุ",
      "note": "บันทึกการตีคืนวัสดุเข้า",
      "tb": "รายละเอียดรายการวัสดุตีคืน",
      "itemNM": "บันทึกช่องทางติดต่อ",
      "itemNM_placeholder": "กรุณากรอกบันทึกช่องทางติดต่อ",
      "lifeF_0": "ประกาศ",
      "lifeF_T": "ปิดเคส",
      "message_at_least_chose_one": "กรุณาเลือกรายละเอียดรายการตีคืนอย่างน้อย1รายการ",
      "planqty": "จำนวนการตีคืน",
    },
  },
  "OMS": {
    "util": {
      "opcode_1110": "แจ้งราคาภายในประเทศ",
      "opcode_1120": "แจ้งราคาต่างประเทศ",
      "opcode_1210": "ใบประเมินราคา",
      "opcode_1310": "ออเดอร์ภายในประเทศ",
      "opcode_1320": "ออเดอร์ต่างประเทศ",
      "opcode_1410": "ออเดอร์ภายในประเทศ",
      "opcode_1420": "ออเดอร์ต่างประเทศ",
      "opcode_4220": "ในตรวจเช็คสินค้าส่งออก",
      "opcode_4130": "ใบตรวจเช็คสินค้าตีคืน",
      "opcode_1610": "สินค้าตีคืน",
      "opcode_1710": "จัดส่งสินค้า",
      "opcode_1810": "รายการค้างเก็บผ่านบัญชี",
      "plan": "ใบวางแผน",
      "quo": "ใบแจ้งราคา",
      "fod": "ใบสั่งจองล่วงหน้า",
      "ord": "ใบออเดอร์",
      "outstock": "ในจัดส่งสินค้า",
      "return": "ในสินค้าตีคืน",
      "ap": "ใบตรวจเทียบบัญชี",
      "outN": "รหัสใบตรวจเช็คสินค้าตีคืน",
      "N11": "รหัสใบเสนอราคา",
      "N12": "รหัสใบสั่งจองสินค้า",
      "N13": "รหัสใบจำหน่ายสินค้า",
      "N14": "รหัสใบจัดส่งสินค้า",
      "N17": "รหัสบิลค้างจ่าย",
      "N18": "รหัสบิลตัดบัญชี",
      "planSearch": "ค้นหาบันทึกการวางแผนงาน",
      "quoSearch": "ค้นหาบันทึกการเสนอราคา",
      "fodSearch": "ค้นหาบันทึกการสั่งจองล่วงหน้า",
      "ordSearch": "ค้นหาบันทึกการจำหน่ายสินค้า",
      "stockSearch": "ค้นหาบันทึกการตรวจรับสินค้า",
      "outstockSearch": "ค้นหาบันทึกการจัดส่งสินค้า",
      "apSearch": "ค้นหาข้อมูลการตรวจเทียบบัญชี",
      "strikeSearch": "ค้นหาบันทึกการตัดบัญชี",
      "dealN": "รหัสใบออเดอร์ลูกค้า",
      "drawno": "เลขที่รูปภาพ",
      "attribute": "ลักษณะเฉพาะของวัสดุ",
      "pdtclass": "ประเภทสินค้า",
      "pdttype": " ประเภทการบริหารจัดการ",
      "localprice": "ราคาตามสกุลเงินเดิม",
    },
    "quo": {
      "pageHeader": "การเสนอราคา",
      "quoID": "ผู้เสนอราคา",
      "quoT": "วันที่เสนอราคา",
      "validdays": "จำนวนวันที่มีผล",
      "isvalid": "มีผลหรือไม่",
      "hd_drawer_title": "ไฟลข้อมูลเสนอราคา",
      "tb_drawer_title": "รายละเอียดใบเสนอราคา",
      "demand": "จำนวนที่เสนอราคา",
      "punit": "หน่วยที่เสนอราคา",
      "unitprice": "ราคาที่เสนอ",
      "isapprove": "ซื้อขายรายการเดียว",
      "itemtotal": "ยอกรวมทั้งสิ้นไม่รวมภาษี",
      "local_subtotal": "ยอดเงินทั้งสิ้นตามสกุลเงินเดิมไม่รวมภาษี",
      "total": "ยอดเงินทั้งสิ้นรวมภาษี(สกุลเงิน)",
      "note": "โน๊ตการเสนอราคา",
      "hd_delete_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบเสนอราคาที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "lifeF_0": "ประกาศ",
      "lifeF_2": "ตรวจการเสนอราคาซ้ำ",
      "lifeF_7": "เซ็นต์อนุมัติอย่างเป็นทางการ",
      "lifeF_T": "ปิดเคส",
      "quoCheck_confirm_Text": "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการตรวจซ้ำก่อนทำากรตรวจซ้ำ",
      "cancel_quoCheck_confirm_Text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการยกเลิกการตรวจซ้ำก่อนยืนยันยกเลิก",
      "Audit_confirm": "ยืนยันการเซ็นต์อนุมัติ",
      "Audit_confirm_text":
        "หลังจากเซ็นต์อนุมัติแล้วใบเสนอราคานี้จะไม่สามารถแก้ไขได้อีก จะยืนยันอนุมัติหรือไม่",
      "tb": "รายละเอียดการเสนอราคา",
    },
    "fod": {
      "pageHeader": "การสั่งจองล่วงหน้า",
      "hd_drawer_title": "ไฟล์ข้อมูลพรีออเดอร์",
      "tb_drawer_title": "รายละเอียดพรีออเดอร์",
      "driID": "ผู้รับผิดชอบ",
      "trade": "เทอมทางการค้า",
      "dealcontact": "ช่องทางติดต่อลูกค้า",
      "payment": "วิธีการชำระเงิน",
      "dealaddr": "ที่อยู่ลูกค้า",
      "custHistory": "ค้นหาประวัติการซื้อของลูกค้า",
      "contact": "ช่องทางติดต่อการส่งสินค้าของลูกค้า",
      "sumtotal": "ยอดเงินทั้งสิ้นไม่รวมภาษี",
      "note": "บันทึกการสั่งจอง",
      "total": "ยอดรวมทั้งสิ้นรวมภาษี",
      "demandqty": "จำนวนสั่งซื้อของลูกค้า",
      "punit": "ฝ่ายที่สั่งจองล่วงหน้า",
      "unitprice": "ราคาต่อหน่วยในการสั่งซื้อ",
      "confirmqty": "ยืนยันจำนวน",
      "cancelqty": "ยกเลิกจำนวน",
      "returnqty": "จำนวนสินค้าตีคืน",
      "transferqty": "จำนวนที่โอนย้ายแล้ว",
      "qty": "จำนวนที่คาดว่าจะโอนย้าย",
      "hd_delete_confirm_text":
        "กรุณาตรวจเช็คความต้องถูกต้องข้อมูลพรีออเดอร์ที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "lifeF_0": "ประกาศใช้",
      "lifeF_2": "ตรวจอนุมัติการสั่งจองล่วงหน้า",
      "lifeF_7": "ลูกค้าเซ็นต์ชื่อส่งกลับ",
      "lifeF_T": "ปิดเคส",
      "local_total": "ยอดเงินทั้งสิ้นรวมเงินภาษี(สกุลเงินเดิม)",
      "fodCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลพรีออเดอร์ที่ต้องการตรวจซ้ำก่อนยืนยันตรวจซ้ำ",
      "cancel_fodCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องพรีออเดอร์ตรวจซ้ำที่ต้องการยกเลิกตรวจซ้ำก่อนยืนยันยกเลิกตรวจซ้ำตรวจซ้ำก่อนยืนยัน",
      "custCheck_confirm": "ยืนยันการเซ็นต์กลับของลูกค้า",
      "custCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการเซ็นต์กลับก่อนยืนยันเซ็นต์กลับ",
      "cancel_custCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลเซ็นต์กลับที่ต้องการยกเลิกก่อนยืนยันยกเลิก",
      "reviewFlow": "ขั้นตอนการตรวจอนุมัติ(3)",
      "tb": "รายละเอียดพรีออเดอร์",
      "deal_history": "ค้นหาประวัติข้อมูลการซื้อขายสินค้า",
    },
    "ord": {
      "pageHeader": "การจำหน่ายสินค้า",
      "hd_drawer_title": "ไฟล์ข้อมูลใบส่งสินค้า",
      "tb": "รายละเอียดใบรายการสินค้าจำหน่าย",
      "adda": "บันทึกเงื่อนไข",
      "addb": "เพิ่มรายการหัก",
      "addc": "การจ่ายเงินล่วงหน้า",
      "lifeF_0": "ประกาศใช้",
      "lifeF_2": "การตรวจการจัดส่งสินค้าซ้ำ",
      "lifeF_7": "ลูกค้าเซ็นต์ชื่อส่งกลับ",
      "lifeF_T": "คำสั่ง",
      "driID": "ผู้รับผิดชอบ",
      "sumtotal": "ยอดเงินทั้งสิ้นไม่รวมภาษี",
      "total": "ยอดรวมทั้งสิ้นรวมภาษี(สกุลเงินในการซื้อขาย)",
      "dealaddr": "ที่อยู่ลูกค้า",
      "contact": "ช่องทางติดต่อฝ่ายรับมอบสินค้าของลูกค้า",
      "dealcontact": "ช่องทางติดต่อเซลล์ลูกค้า",
      "payment": "วิธีการชำระเงิน",
      "trade": "เทอมทางการค้า",
      "note": "โน๊ต",
      "reviewFlow": "ขั้นตอนการตรวจอนุมัติ(3)",
      "demandqty": "จำนวนการจำหน่ายสินค้า",
      "transferqty": "จำนวนที่โอนย้ายแล้ว",
      "qty": "จำนวนการโอนย้ายในครั้งนี้",
      "punit": "หน่วยจำหน่ายสินค้า",
      "unitprice": "ราคาจำหน่ายต่อหน่วย",
      "confirmqty": "ลูกค้ายืนยันจำนวน",
      "cancelqty": "ยกเลิกจำนวน",
      "returnqty": "จำนวนสินค้าตีคืน",
      "charge": "รวมรายการหักทั้งสิ้น",
      "statement": "ชี้แจงโปรเจค",
      "amount": "ยอกเงินไม่รวมภาษี",
      "local_total": "ยอดรวมทั้งสิ้นรวมภาษี(สกุลเงินเดิม)",
      "closeData_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบออเดอร์ที่ต้องการออกคำสังบังคับก่อนยืนยันออกคำสั่งบังคับ",
      "removeData_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบออเดอร์ที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "orderCheck_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลรายการสินค้าจำหน่ายที่ต้องการตรวจซ้ำก่อนยืนยืนตรวจซ้ำ",
      "cancel_orderCheck_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลสินค้าจำหน่ายที่ต้องการยกเลิกตรวจซ้ำก่อนทำการยกเลิก",
      "cancel_custCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลเซ็นต์กลับที่ต้องการลบก่อนยืนยันลบข้อมูล",
      "custCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลที่ต้องการเซ็นต์กลับก่อนทำการเซ็นต์กลับ",
      "adda_delete_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลเงื่อนไขที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องการชำระเงินล่วงหน้าที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "deal_history": "ค้นหาประวัติการซื้อสินค้า",
    },
    "out-stock": {
      "pageHeader": "การส่งออกสินค้า",
      "hd_drawer_title": "ไฟล์ข้อมูลใบส่งสินค้า",
      "tb_drawer_title": " ",
      "tb": "รายละเอียดการส่งสินค้า",
      "lifeF_0": "ประกาศใช้",
      "lifeF_2": "ตรวจอนุมัติการส่งสินค้าซ้ำ",
      "lifeF_3": "IQCสุ่มตรวจ",
      "lifeF_4": "IQCตรวจเสร็จสิ้น",
      "lifeF_7": "คิวซีตรวจซ้ำ",
      "lifeF_T": "ปิดเคส",
      "sampleID": "ผู้สุ่มตัวอย่าง",
      "sampleT": "วันที่สุ่มตัวอย่าง",
      "note": "บันทึกการส่งสินค้า",
      "punit": "ฝ่ายจัดส่งสินค้า",
      "indqty": "จำนวนที่ส่งออกสินค้า",
      "goqty": "จำนวนที่ตรวจรับ",
      "unitprice": "ราคาขายต่อหน่วย",
      "rejectqty": "จำนวนตรวจไม่ผ่าน",
      "IQCcheck": "สุ่มตัวอย่างสินค้ารับเข้า",
      "inspectID": "ผูัตรวจรับสินค้า",
      "inspectT": "เวลาเสร็จสิ้นการตรวจ",
      "qalID": "พนักงานคิวซี",
      "qalT": "เวลาปล่อยสินค้า",
      "judgeT": "วันที่คิวซีตัดสิน",
      "qty": "จำนวน",
      "choose": "เลือก",
      "choose_err": "จำนวนคงเหลือ 0 ไม่สามารถใช้ได้",
      "throw_save_msg": "กรุณาเลือกใช้ก่อนแล้วค่อยกรอกจำนวนการใช้",
      "throwQty_msg": "จำนวนการโอนย้ายต้องไม่ต่ำกว่า 0",
      "useDetail_msg": "ห้ามใช้ข้อมูลรายละเอียดเหมือนกัน",
      "IQCcheck_text": "กำลังจะเข้าสู่การสุ่มตัวอย่าง จะทำการเซฟข้อมูลใบส่งสินค้าหรือไม่?",
      "closeData_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลใบส่งสินค้าที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "outstockCheck_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลการส่งสินค้าที่ต้องการตรวจซ้ำก่อนยืนยันการตรวจซ้ำ",
      "cancel_outstockCheck_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลตรวจซ้ำที่ต้องการยกเลิกก่อนยืนยันยกเลิก",
      "IQCfinish_text": "กำลังจะเข้าสู่IQCตรวจเสร็จสิ้น ต้องการจะเซฟใบส่งสินค้าหรือไม่",
      "QAcheck_err": " ",
      "QAcheck": "ฝ่ายคิวซีปล่อยสินค้า",
      "QAcheck_text": "กำลังจะเข้าสู่การคิวซีปล่อยสินค้า จะเซฟข้อมูลใบสงสินค้าหรือไม่?",
      "throwStock_text": "กำลังจะเข้าสู่การโอนย้ายใบส่งสินค้า ยืนยันจะโอนย้ายหรือไม่?",
    },
    "check": {
      "pageHeader": "ค้นหาข้อมูลการตรวจสอบสินค้าส่งออก",
      "4220_header": "ไฟล์จัดส่งสินค้าจำหน่าย",
      "4220_tb": "รายละเอียดสินค้าส่งจำหน่าย",
      "4130_header": "ไฟล์สินค้าตีคืน",
      "4130_tb": "กลับหน้ารายละเอียด",
      "jobN": "เลขที่บิลที่มา",
      "note": "โน๊ต",
      "total": "จำนวนโยกย้ายทั้งสิ้น",
      "planqty": "จำนวนที่ทำการโยกย้าย",
      "punit": "ฝ่ายที่จัดจำหน่าย",
      "lifeF_0": "ประกาศใช้",
      "lifeF_T": "ิปิดเคส",
    },
    "return": {
      "pageHeader": "การจัดการสินค้าตีคืน",
      "tb": "รายละเอียดสินค้าตีคืน",
      "hd_remove_text": "กรุณาตรวจเช็คความถูกต้องใบสินค้าตีคืนที่ต้องการลบออกก่อนยืนยันลบข้อมูล",
      "unitprice": "ราคาจำหน่ายต่อหน่วย",
      "rtnqty": "จำนวนสินค้าตีคืน",
      "inbatchno": "รหัสล๊อตการนำเข้าวัสดุ",
      "contact_window": "ช่องทางติดต่อลูกค้า",
      "note": "บันทึกการเข้า/คืนสินค้า",
      "addr": "ที่อยู่ติดต่อ",
      "itemNM": "โนท",
      "lifeF_0": "ประกาศใช้",
      "lifeF_T": "ปิดเคส",
    },
    "ap-manage": {
      "pageHeader": "การจัดการบัญชีที่ค้างเก็บ",
      "rcv": "ค้นหาข้อมูลรายการค้างเก็บ",
      "rcvData": "ข้อมูลที่ควรเก็บ",
      "rcv_drawer_title": "ไฟล์ข้อมูลรายการค้างเก็บ",
      "inbatchno": "รหัสล๊อตการนำเข้าสินค้า",
      "contactWindow": "聯絡窗口資訊",
      "ap_tb": "รายละเอียดการตรวจเทียบบัญชี",
      "strike": "การตัดบัญชี",
      "strike_tb": "รายละเอียดการตัดบัญชี",
      "unitprice": "ราคาจำหน่ายต่อหน่วย",
      "rcv_inqty": "จำนวนที่โยกย้าย",
      "rcv_itemsum": "ราคาแต่ละรายการ",
      "rcv_itemsumtax": "ภาษีแต่ละรายการ",
      "rcv_subtotal": "ยอดรวมภาษี",
      "ap_remove_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลการตรวจเทียบบัญชีที่ต้องการลบก่อนยืนยันลบข้อมูล",
      "strike_remove_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลรายการตัดบัญชีที่ต้องการลบก่อนยืนยันลบข้อมูล",
      "ap_total": "ยอดเก็บทั้งหมดรวมเงินภาษี",
      "ap_itemtotal": "ยอดเงินค้างเก็บรวมภาษีแล้ว",
      "ap_paytotal": "จำนวนเงินที่ตัดบัญชีแล้ว",
      "ap_sumtotal": "ยอดรวมทั้งสิ้น",
      "strike_sumtotal": "ยอดเงินตัดบัญชีทั้งสิ้น",
      "ap_hubqty": "จำนวนสินค้าจ่ายออกจากโกดัง",
      "ap_addr": "ที่อยู่ลูกค้า",
      "ap_balance": "ค่าต่าง",
      "ap_lifeF_0": "สร้าง",
      "ap_lifeF_1": "ตรวจอนุมัติรรายการค้างเก็บซ้ำ",
      "ap_lifeF_2": "ออกบิลรายการค้างเก็บ",
      "ap_lifeF_3": "ตัดบัญชี(เก็บเงิน)ยังไม่ปิดเคส",
      "ap_lifeF_T": "結案",
      "ap_contact": "ช่องทางติดต่อทูกค้า",
      "ap_itemNM": "บันทึกช่องทางติดต่อ",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "現金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "บันทึกการตัดบัญชี",
      "confirmStrike": "ยืนยันตัดบัญชี",
      "apCheck_confirm_text":
        "กำลังจะเข้าสู่การตรวจอนุมัติรายการค้างเก็บซ้ำ ต้องการเซฟข้อมูลบัญชีหรือไม่",
      "releaseAP_confirm_text":
        "กำลังจะเข้าสู่การออกบิลรายการค้างเก็บ ต้องการเซฟข้อมูลบัญชีหรือไม่",
      "strikeCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลรายการที่ต้องการตรวจอนุมัติซ้ำก่อนทำการตรวจซ้ำ",
      "cancel_strikeCheck_confirm_text":
        "กรุณาตรวจเช็คความถูกต้องข้อมูลรายการตรวจอนุมัติซ้ำที่ต้องการยกเลิกก่อน ทำการยกเลิก",
      "confirmStrike_confirm_text":
        "หลังจากยืนยันตัดบัญชีแล้วบิลนี้ก็จะปิดเคส กรุณายืนยันว่าต้องการตัดบัญชีหรือไม่",
    },
  },
  "BPM": {
    "util": {
      "signmode": "簽核層級",
      "level": "階",
      "opfunction_1": "訂單系統",
      "opfunction_2": "採購系統",
      "opfunction_3": "生產系統",
      "opfunction_4": "庫儲系統",
      "opfunction_5": "財務系統",
      "optype_11": "訂單專案",
      "optype_12": "採購專案",
      "optype_40": "庫儲專案",
      "optype_41": "出庫專案",
      "permissions": "權限",
      "open": "立案",
      "check": "查權",
      "review": "審權",
      "approve": "核權",
    },
    "sign-level": {
      "pageHeader": "การตั้งค่าขอบเขตอำนาจในการเซ็นต์อนุมัติ",
      "opfunction": "ฟังก์ชันการดำเนินธุรกิจ",
      "optype": "ประเภทการดำเนินการ",
    },
    "dsevent": {
      "system": "โมดูลระบบ",
      "keynote": "หัวข้อ",
    },
  },
  "PDS": {
    "util": {
      "wlID": "ไลน์",
    },
    "wpc-search": {
      "hdC": "ช่วงจำนวนคนสูงสุด",
      "issueday": "เวลาประกาศ",
      "lifeF_0": "ประกาศแล้ว",
      "lifeF_1": "เข้าสู่ระบบแล้ว",
      "lifeF_2": "กำลังทำการผลิต",
      "lifeF_3": "แจ้งหยุดงาน",
      "lifeF_4": "แจ้งกลับสู่การทำงาน",
      "lifeF_7": "แจ้งเสร็จงาน",
      "lifeF_B": "แจ้งเริ่มทำงาน",
      "lifeF_G": "ผ่านสถานนี",
      "lifeF_L": "ตรวจวัสดุซ้ำ",
      "lifeF_P": "การควบคุมคุณภาพผ่าน",
      "lifeF_aps": "ประกาศฝ่ายควบคุมการผลิต",
      "linemix": "ไลน์ผสม",
      "pageHeader": "ค้นหาใบขบวนการผลิต",
      "wlID": "ไลน์",
    },
    "overview-lines": {
      "pageHeader": "ตารางกำลังการผลิตโดยรวม",
      "act": "อัตราการใช้กำลังการผลิต",
      "shiftD": "กะกลางวัน",
      "shiftN": "กะกลางคืน",
      "allDay": "ซ้าย",
    },
    "dashboard": {
      "pageHeader": "บอร์ดข้อมูลการผลิตดิจิทัล",
    },
    "abc-board": {
      "pageHeader": "บอร์ดบริหารจัดการความผิดปกติ",
    },
  },
};

export default th_TH;
