import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";

import { addPrice, updatePrice } from "@/service/apis/ADM/pnManage";
import { getCurrency } from "@/service/apis/ADM/functionParamsManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addPrice = useAPI(addPrice);
  const call_updatePrice = useAPI(updatePrice);
  const call_getCurrency = useAPI(getCurrency);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addPrice.request(postData);
    }

    if (type === "edit") {
      call_updatePrice.request(postData);
    }
  };

  useEffect(() => {
    call_getCurrency.request();
  }, []);

  useEffect(() => {
    if (call_addPrice.status === "suc") {
      message.success(call_addPrice.msg);
      set_s_isShowModal(false);
    } else if (call_addPrice.status === "err") {
      message.error(t(`error.error-code.${call_addPrice.msg}`));
    }
  }, [call_addPrice.status]);

  useEffect(() => {
    if (call_updatePrice.status === "suc") {
      message.success(call_updatePrice.msg);
      set_s_isShowModal(false);
    } else if (call_updatePrice.status === "err") {
      message.error(t(`error.error-code.${call_updatePrice.msg}`));
    }
  }, [call_updatePrice.status]);

  useEffect(() => {
    if (call_getCurrency.status === "err") {
      message.error(t(`error.error-code.${call_getCurrency.msg}`));
    }
  }, [call_getCurrency.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              ...c_lightData,
              stdsale: 0,
              moq: 0,
              isvalid: true,
              note: "",
            }
          : s_editData
      }
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label={t("util.util.PN")}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stdsale" label={t("ADM.pn-manage.stdsale")} rules={[{ required: true }]}>
            <InputNumber
              className="w-full"
              min={0}
              initialValue={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("ADM.pn-manage.salecurrency")}
            name="salecurrency"
            rules={[{ required: true }]}
          >
            <Select
              options={
                call_getCurrency.data?.reduce((accumulator, option) => {
                  option.isvalid &&
                    accumulator.push({
                      label: option.itemNM,
                      value: option.currencyitem,
                    });
                  return accumulator;
                }, []) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="moq" label={t("ADM.pn-manage.moq")} rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
