import { useState, useEffect, useContext } from "react";
import { Layout, Button, Space, Switch, Select, theme, Typography } from "antd";
import { useLocation, matchRoutes, useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  BgColorsOutlined,
  GlobalOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import FontIcon from "./FontIcon";
import styled from "styled-components";
import dayjs from "dayjs";
import SystemContext from "../Context/SystemContext";
import { t } from "i18next";
import Cookies from "js-cookie";
// component
import Countrys from "./Countrys";

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    width: 1px;
    background-color: #d9d9d9;
  }
`;

const { Title } = Typography;
const { Header } = Layout;
const { useToken } = theme;

const MyHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const plantID = Cookies.get("plantID");
  const devBgColor = "yellow";
  const abcBgColor = "white";

  const { token } = useToken();
  const { c_systemConfig, set_c_systemConfig, c_userData } = useContext(SystemContext);

  const change_sider_state = (e) => {
    stop_propagation(e);
    if (props.navbarState === "close" || props.navbarState === "shrinkClose") {
      props.setNavbarState("open");
    } else {
      props.setNavbarState("close");
    }
  };

  const stop_propagation = (e) => {
    e?.nativeEvent.stopImmediatePropagation();
  };

  const handlerSystemConfigChange = (type, value) => {
    console.log({ type, value });
    // return;
    set_c_systemConfig((prev) => {
      prev[type] = value;
      return { ...prev };
    });
  };

  useEffect(() => {
    const closeSider = () => {
      props.setNavbarState("close");
    };
    if (props.navbarState === "open") {
      window.addEventListener("click", closeSider);
    }
    return () => {
      window.removeEventListener("click", closeSider);
    };
  }, [change_sider_state]);
  console.log(plantID);
  return (
    // <Header className="shadow-lg flex px-4" style={{ backgroundColor: token.colorHeaderBg }}>
    <Header
      className="shadow-lg flex px-4"
      style={{ backgroundColor: plantID === "dev" ? devBgColor : abcBgColor }}
    >
      <Space>
        <span className="text-3xl flex">
          <MenuFoldOutlined
            className="cursor-pointer"
            onClick={change_sider_state}
            style={{ color: token.colorHeaderText }}
          />
        </span>
        <Title level={3} className="m-0 font-normal" style={{ color: token.colorHeaderText }}>
          生產戰情系統
        </Title>
      </Space>
      <Space className="flex-1 justify-end items-center">
        <Title level={4} className="m-0 px-2 font-normal" style={{ color: token.colorHeaderText }}>
          {c_userData.peopleNM}
        </Title>
        <Line />
        <Title level={4} className="m-0 px-2 font-normal" style={{ color: token.colorHeaderText }}>
          {dayjs().format("YYYY-MM-DD")}
        </Title>
        <Line />
        <Title level={4} className="m-0 px-2 font-normal" style={{ color: token.colorHeaderText }}>
          {/* {"TEST_" + Cookies.get("plantID")} */}
          {Cookies.get("plantNM")}
        </Title>
        <Line />
        {/* <FontSizeOutlined />
        <Line /> */}
        <FontIcon
          c_systemConfig={c_systemConfig}
          handlerSystemConfigChange={handlerSystemConfigChange}
        />
        <Line />
        {/* <Select
          onChange={(e) => handlerSystemConfigChange("size", e)}
          value={c_systemConfig.size}
          options={[
            { value: "large", label: t("util.util.size_large") },
            { value: "middle", label: t("util.util.size_middle") },
            { value: "small", label: t("util.util.size_small") },
          ]}
        /> */}
        {/* <BgColorsOutlined />
        <Select
          onChange={(e) => handlerSystemConfigChange("theme", e)}
          value={c_systemConfig.theme}
          options={[{ value: "default", label: "預設" }]}
        />
        <Line /> */}
        <GlobalOutlined />
        <Select
          className="w-[200px]"
          value={c_systemConfig.locale}
          onChange={(e) => handlerSystemConfigChange("locale", e)}
          options={[
            { value: "zh-TW", label: "zh-TW | 繁中" },
            { value: "zh-CN", label: "zh-CN | 简中" },
            { value: "en-US", label: "en-US | English" },
            { value: "vi-VN", label: "vi_VN | Tiếng Việt" },
          ]}
        />
        {/* <Countrys
          locale={c_systemConfig.locale}
          handlerSystemConfigChange={handlerSystemConfigChange}
        /> */}
      </Space>
    </Header>
  );
};

export default MyHeader;
