import React, { useState } from "react";
import { Button, Dropdown, Space, Tooltip, Select, message } from "antd";
import { BarcodeOutlined, QrcodeOutlined, PlusOutlined } from "@ant-design/icons";
import { IconCircular, IconLine, IconPhoto, IconSquare, IconWord } from "@/components/Icon/LMS";
import styled from "styled-components";

const CustomBarcodeSelect = styled(Select)`
  .ant-select-selector {
    background-color: #eff2f5;
  }
`;

const defaultSetting = {
  x: 10,
  y: 10,
  isvalid: true,
  fontSize: 20,
  boxWidth: 80,
  boxAlign: "left",
  barcodeSize: 1,
  angle: 0,
  len: 50,
  b: 10,
  imgName: "",
  imgUrl: "",
  fontFamily: "PMingLiU",
  bold: " ", //粗體
  radius: 50, //半徑
  keyTextDigits: 4, //關鍵文字補位*/
  c: "#000000", //顏色 W or B
  fontWidth: 100, //文字寬度
  orderIndex: 1, //引用單號
};

// 長型
const barcodeOptions = [
  { value: "code128" },
  { value: "ean13" },
  { value: "upca" },
  { value: "code39" },
];
// 方形
const qrcodeOptions = [{ value: "qrcode" }, { value: "datamatrix" }];

export default function CreateGruup({ form_tb_ref, renderDraw }) {
  const [s_barcodeSelect, set_s_barcodeSelect] = useState({
    type: "barcode",
    choose: undefined,
  });

  const addItem = (type) => {
    let newObj = {
      type: type,
      groupType: "text",
      ...defaultSetting,
    };
    if (type === "text") newObj.text = "TEST";
    if (type === "keyText") newObj.keyText = "PN";
    if (type === "quoteText") newObj.quoteText = "TEST${1}${2}";
    if (type === "border" || type === "round" || type === "rect") newObj.groupType = "shape";
    if (type === "img") newObj.groupType = "img";
    if (type === "datamatrix") newObj.datamatrixVer = "24x24";
    if (
      type === "qrcode" ||
      type === "datamatrix" ||
      type === "code128" ||
      type === "ean13" ||
      type === "upca" ||
      type === "code39"
    ) {
      newObj.quoteText = "TEST${1}${2}";
      newObj.groupType = "barcode";
    }
    let formData = form_tb_ref.getFieldValue();
    formData.details.push(newObj);
    formData.details = formData.details.map((x, i) => ({ ...x, key: i + 1 }));
    form_tb_ref.setFieldsValue(formData);
  };

  const items = [
    {
      key: "text",
      label: "文字",
      onClick: () => addItem("text"),
    },
    {
      key: "keyText",
      label: "關鍵文字",
      onClick: () => addItem("keyText"),
    },
    {
      key: "quoteText",
      label: "引用文字",
      onClick: () => addItem("quoteText"),
    },
  ];

  return (
    <div className="flex justify-between">
      <Space>
        <Space.Compact size="large">
          <Dropdown placement="bottomLeft" menu={{ items }} trigger={["click"]}>
            <Button type="primary" className="p-0 m-0" icon={<IconWord />} />
          </Dropdown>
          <Tooltip title="圓形">
            <Button type="primary" icon={<IconCircular />} onClick={() => addItem("round")} />
          </Tooltip>
          <Tooltip title="四角形">
            <Button type="primary" icon={<IconSquare />} onClick={() => addItem("rect")} />
          </Tooltip>
          <Tooltip title="線條">
            <Button type="primary" icon={<IconLine />} onClick={() => addItem("border")} />
          </Tooltip>
          <Tooltip title="圖片">
            <Button type="primary" icon={<IconPhoto />} onClick={() => addItem("img")} />
          </Tooltip>
        </Space.Compact>

        <Space.Compact size="large">
          <CustomBarcodeSelect
            defaultValue="barcode"
            value={s_barcodeSelect.type}
            onChange={(value) => set_s_barcodeSelect(() => ({ choose: undefined, type: value }))}
            options={[
              {
                label: <BarcodeOutlined className="text-[30px] h-full" />,
                value: "barcode",
              },
              {
                label: <QrcodeOutlined className="text-[30px] h-full" />,
                value: "qrcode",
              },
            ]}
          />
          <Select
            style={{ width: "250px" }}
            showSearch
            value={s_barcodeSelect.choose}
            options={s_barcodeSelect.type === "barcode" ? barcodeOptions : qrcodeOptions}
            onChange={(value) => set_s_barcodeSelect((prev) => ({ ...prev, choose: value }))}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              if (!s_barcodeSelect.choose) {
                message.error("請選擇條碼");
                return;
              }
              addItem(s_barcodeSelect.choose);
            }}
          />
        </Space.Compact>
      </Space>

      <Button
        size="large"
        type="primary"
        danger
        onClick={() => {
          renderDraw();
        }}
      >
        重新產圖
      </Button>
    </div>
  );
}
