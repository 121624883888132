import React, { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import MyLayout from "@/components/Layout";
import ErrorPage from "@/pages/404";
import EDM from "@/pages/ADM/BDM/EDM";
import PNManage from "@/pages/ADM/BDM/PNManage";
import Sheet from "@/pages/ADM/BDM/Sheet";
import LogisticsEquipmentAGV from "@/pages/ADM/EDM/LogisticsEquipmentAGV";
import MeasurementEquipment from "@/pages/ADM/EDM/MeasurementEquipment";
import Mold from "@/pages/ADM/EDM/Mold";
import PlantMaintenance from "@/pages/ADM/EDM/PlantMaintenance";
import ProductionEquipment from "@/pages/ADM/EDM/ProductionEquipment";
import TransportationEquipment from "@/pages/ADM/EDM/TransportationEquipment";
import CategoryManage from "@/pages/ADM/SDM/CategoryManage";
import Client from "@/pages/ADM/SDM/Client";
import Company from "@/pages/ADM/SDM/Company";
import DataAuthManage from "@/pages/ADM/SDM/DataAuthManage";
import FunctionParamsManage from "@/pages/ADM/SDM/FunctionParamsManage";
import OEMItemManage from "@/pages/ADM/SDM/OEMManage/OEMItemManage";
import OEMSuppManage from "@/pages/ADM/SDM/OEMManage/OEMSuppManage";
import Optype from "@/pages/ADM/SDM/Optype";
import TradingPattern from "@/pages/ADM/SDM/TradingPattern";
import UserManage from "@/pages/ADM/SDM/UserManage";
import PlantManage from "@/pages/ADM/TDM/PlantManage";
import PlantParamsManage from "@/pages/ADM/TDM/PlantParamsManage/index";
import ProcedureManage from "@/pages/ADM/TDM/ProcedureManage/index";
import ProductionLineManage from "@/pages/ADM/TDM/ProductionLineManage";
import StringManage from "@/pages/ADM/TDM/StringManage";
import WarehouseManage from "@/pages/ADM/TDM/WarehouseManage";
import ApsManage from "@/pages/APS/ApsManage";
import LabelDate from "@/pages/LMS/LabelDate";
import LabelInStockPrinter from "@/pages/LMS/LabelInStockPrinter";
import LabelMachines from "@/pages/LMS/LabelMachines";
import LabelOrderNPrinter from "@/pages/LMS/LabelOrderNPrinter";
import LabelPaper from "@/pages/LMS/LabelPaper";
import LabelSearch from "@/pages/LMS/LabelSearch";
import LabelTemplate from "@/pages/LMS/LabelTemplate";
import Login from "@/pages/Login";
import NotificationSetting from "@/pages/MNS/NotificationSetting";
import AbcBorder from "@/pages/PDS/AbcBoard/New";
import Dashboard from "@/pages/PDS/DashBoard";
import DashBoardCard from "@/pages/PDS/DashBoardCard";
import Efficiency from "@/pages/PDS/Efficiency";
import HeaderLine from "@/pages/PDS/HeaderLine";
import OverViewLines from "@/pages/PDS/OverViewLines";
import TestMarquee from "@/pages/PDS/TestMarquee/index";
import TickerDashboard from "@/pages/PDS/TickerDashboard";
import CaptainLineManage from "@/pages/PIS/CaptainLineManage";
import DailyReport from "@/pages/PIS/DailyReport";
import DalReport from "@/pages/PIS/DalReport";
import WonSearch from "@/pages/PIS/WonSearch";
import WpcSearch from "@/pages/PIS/WpcSearch";
import Head from "@/pages/SAP/head/";
import Order from "@/pages/SAP/order/";
import Qcdata from "@/pages/SAP/qcdata/";
import Wpc from "@/pages/SAP/wpc/";
import RCP from "@/pages/TestPage/MMS/RCP";
import SOP from "@/pages/TestPage/MMS/SOP";
import ApPwc from "@/pages/TestPage/QMS/ApPwc";
import ApTry from "@/pages/TestPage/QMS/ApTry";
import Apui from "@/pages/TestPage/QMS/Apui";
import ApuiSetting from "@/pages/TestPage/QMS/ApuiSetting";
import QMFM from "@/pages/TestPage/QMS/QMFM";
import QMSManageTest from "@/pages/TestPage/QMS/QMSManage";
import SPC from "@/pages/TestPage/QMS/SPC";
import TestPage from "@/pages/TestPage/meds/index";
import AttendanceHours from "@/pages/WHM/AttendanceHours";
import CT from "@/pages/WHM/CT";
import Performance from "@/pages/WHM/Performance";
import BarChart from "@/pages/WMS/BarChart";
import InvHistory from "@/pages/WMS/InvHistory/index";
import PlanIn from "@/pages/WMS/PlanIn";
import PlanOut from "@/pages/WMS/PlanOut";
import ProInstock from "@/pages/WMS/ProInstock";
import ProOutstock from "@/pages/WMS/ProOutstock";
import StockSearch from "@/pages/WMS/StockSearch";
import PdtCode from "@/pages/PIS/PdtCode";
import PdtData from "@/pages/PIS/PdtData";

//Test

//Test MMS 製程管理系統

//Test QMS

// import Inspeq from "@/pages/TestPage/QMS/Inspeq";

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* 斜線導入 */}
        <Route path="/" element={<MyLayout />}>
          <Route path="/404" element={<ErrorPage />} />
          <Route path="/" element={<PNManage />} />

          {/*TestPage */}
          <Route path="/testpage/meds/spec" element={<TestPage />} />
          {/*Test QMS */}
          <Route path="/testpage/qms/qms-manage" element={<QMSManageTest />} />
          <Route path="/testpage/qms/apuiSetting" element={<ApuiSetting />} />
          <Route path="/testpage/qms/apui" element={<Apui />} />
          <Route path="/testpage/qms/apTry" element={<ApTry />} />
          <Route path="/testpage/qms/apPwc" element={<ApPwc />} />
          {/* <Route path="/testpage/qms/inspeq" element={<Inspeq />} /> */}
          <Route path="/testpage/qms/qmfm" element={<QMFM />} />
          <Route path="/testpage/qms/spc" element={<SPC />} />
          {/*Test  MMS */}
          <Route path="/testpage/mms/sop" element={<SOP />} />
          <Route path="/testpage/mms/rcp" element={<RCP />} />

          {/* WHM */}
          <Route path="/whm/performance" element={<Performance />} />
          <Route path="/whm/ct" element={<CT />} />
          <Route path="/whm/attendance-hours" element={<AttendanceHours />} />

          {/* LMS */}
          <Route path="/lms/label-template" element={<LabelTemplate />} />
          <Route path="/lms/label-paper" element={<LabelPaper />} />
          <Route path="/lms/label-date" element={<LabelDate />} />
          <Route path="/lms/label-machines" element={<LabelMachines />} />
          <Route path="/lms/label-search" element={<LabelSearch />} />
          <Route path="/lms/label-orderN-printer" element={<LabelOrderNPrinter />} />
          <Route path="/lms/label-instock-printer" element={<LabelInStockPrinter />} />

          {/* PDS */}
          <Route path="/pds/dashboard" element={<Dashboard />} />
          <Route path="/pds/dashboard-card" element={<DashBoardCard />} />
          <Route path="/pds/ticker-dashboard" element={<TickerDashboard />} />
          <Route path="/pds/abc-board" element={<AbcBorder />} />
          <Route path="/pds/overview-lines" element={<OverViewLines />} />
          <Route path="/pds/efficiency" element={<Efficiency />} />
          <Route path="/pds/header-line" element={<HeaderLine />} />
          <Route path="/pds/test-marquee" element={<TestMarquee />} />

          {/* BDM */}
          <Route path="/bdm/pn-manage" element={<PNManage />} />
          <Route path="/bdm/edm" element={<EDM />} />
          <Route path="/bdm/sheet" element={<Sheet />} />
          <Route path="/bdm/oem-item-manage" element={<OEMItemManage />} />
          <Route path="/bdm/oem-supp-manage" element={<OEMSuppManage />} />

          {/* SDM */}
          <Route path="/sdm/user-manage" element={<UserManage />} />
          <Route path="/sdm/optype" element={<Optype />} />
          <Route path="/sdm/data-auth-manage" element={<DataAuthManage />} />
          <Route path="/sdm/category-manage" element={<CategoryManage />} />
          <Route path="/sdm/trading-pattern" element={<TradingPattern />} />
          <Route path="/sdm/company" element={<Company />} />
          <Route path="/sdm/client" element={<Client />} />

          <Route path="/sdm/function-params-manage" element={<FunctionParamsManage />} />
          {/* TDM */}
          <Route path="/tdm/warehouse-manage" element={<WarehouseManage />} />
          <Route path="/tdm/plant-manage" element={<PlantManage />} />
          <Route path="/tdm/production-line-manage" element={<ProductionLineManage />} />
          <Route path="/tdm/string-manage" element={<StringManage />} />
          <Route path="/tdm/procedure-manage" element={<ProcedureManage />} />
          <Route path="/tdm/plant-params-manage" element={<PlantParamsManage />} />
          {/* EDM */}
          <Route path="/edm/mold" element={<Mold />} />
          <Route path="/edm/production-equipment" element={<ProductionEquipment />} />
          <Route path="/edm/measurement-equipment" element={<MeasurementEquipment />} />
          <Route path="/edm/logistics-equipmentAGV" element={<LogisticsEquipmentAGV />} />
          <Route path="/edm/transportation-equipment" element={<TransportationEquipment />} />
          <Route path="/edm/plant-maintenance" element={<PlantMaintenance />} />

          {/* APS */}
          <Route path="/aps/aps-manage" element={<ApsManage />} />
          {/* IMS */}
          <Route path="/ims/plan-in" element={<PlanIn />} />
          <Route path="/ims/plan-out" element={<PlanOut />} />
          <Route path="/ims/stock-search" element={<StockSearch />} />
          <Route path="/ims/inv-history" element={<InvHistory />} />
          <Route path="/ims/pro-instock" element={<ProInstock />} />
          <Route path="/ims/pro-outstock" element={<ProOutstock />} />

          {/* PIS */}
          <Route path="/pis/daily-report" element={<DailyReport />} />
          <Route path="/pis/wpc-search" element={<WpcSearch />} />
          <Route path="/pis/dal-report" element={<DalReport />} />
          <Route path="/pis/captain-line-manage" element={<CaptainLineManage />} />
          <Route path="/pis/won-search" element={<WonSearch />} />
          <Route path="/pis/pdtCode" element={<PdtCode />} />
          <Route path="/pis/pdtData" element={<PdtData />} />

          {/* SAP */}
          <Route path="/sap/head" element={<Head />} />
          <Route path="/sap/order" element={<Order />} />
          <Route path="/sap/wpc" element={<Wpc />} />
          <Route path="/sap/qcdata" element={<Qcdata />} />

          <Route path="inf/bi" element={<BarChart />} />

          <Route path="/mns/notification-setting" element={<NotificationSetting />} />
        </Route>

        <Route path="/login" element={<Login />} />
        {/* 未匹配 */}
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
}
