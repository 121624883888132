import { Affix, Col, DatePicker, Row, Select, Spin, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import PageHeader from "@/components/PageHeader/index";
// API
import useAPI from "@/hooks/useAPI";
import useWebSocket from "@/hooks/useWebSocket";
import { getPS } from "@/service/apis/ADM/publicAPI";
import { actSocket } from "@/service/ws/ws";

import OvalChart from "./OvalChart";
import TimeChart from "./TimeChart";
import WsButton from "./WsButton";

const Wrapper = styled.div`
  padding: 0px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
  overflow: auto;
  /* height: 100%; */
  height: calc(100vh - 120px);
  //background-color: green;

  // 折線圖跟水波圖的區域
  .ChartContainer {
    display: flex;
    justify-content: space-between;
    height: 350px;
  }

  // 稼動
  .FacetChart {
    //height: calc(100% - 350px - 100px);
    height: calc(100% - 400px);
    margin-top: 30px;
    //border: 1px solid red;
    //margin-bottom: 20px;
    //background-color: red
    /* z-index: 99; */
    //overflow: auto;
  }

  // 稼動率的區塊
  .FacetChartContainer {
    /* background-color: #f5f5f5; */
    background-color: #d6eaff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
      0px 1px 18px 0px rgb(0 0 0 / 12%);
    height: 50px;
    //margin-top: 20px;
    display: flex;
    //background-color: pink
  }

  .Container {
    height: calc(100% - 70px);
    overflow: auto;
  }

  .FacetChartContainer1 {
    display: flex;
    padding: 5px;
    box-shadow: 5px 1px 1px rgb(0 0 0 / 20%);
  }

  // 稼動率的 header title
  .colHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3089c7;
    //padding-top: 10px;
    //padding-bottom: 10px;
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // 線別的按鈕
  .wsBtn {
    color: rgba(0, 0, 0, 0.87);
    //width: 60px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
      0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    padding: 6px 16px;
  }
`;

const OverViewLines = () => {
  const { t } = useTranslation();
  const call_getPS = useAPI(getPS);
  // const [s_AllPS_options, set_s_AllPS_options] = useState([]); // 所有車間選單
  const [s_AllPS, set_s_AllPS] = useState([]); // 所有車間選單

  const [s_act, set_s_act] = useState([]);
  const [s_board, set_s_board] = useState([]);

  const [s_searchData, set_s_searchData] = useState({
    "psID": "",
    "date": dayjs().format("YYYY-MM-DD"),
  });

  const [container, setContainer] = useState(null);

  const call_actSocket = useWebSocket(actSocket);

  useEffect(() => {
    call_getPS.request();
  }, []);

  useEffect(() => {
    const { status, data, msg } = call_getPS;
    if (status === "suc") {
      message.success(msg);
      set_s_AllPS(
        data.reduce(
          (prev, curr) => {
            prev.push({
              key: curr.psID,
              label: curr.psNM,
              value: curr.psID,
            });
            return prev;
          },
          [{ label: t("util.util.all"), value: "" }]
        )
      );
    } else if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getPS.status]);

  useEffect(() => {
    call_actSocket.changeParams({ psID: s_searchData.psID, date: s_searchData.date });
    call_actSocket.setIsOpen(true);
  }, [s_searchData]);

  useEffect(() => {
    if (call_actSocket.socketData.status === true) {
      console.log("DataSocket.data = ", call_actSocket.socketData.data);

      set_s_act(call_actSocket.socketData.data.act);
      set_s_board(call_actSocket.socketData.data.board);
    }
  }, [call_actSocket.socketData]);

  // 切換搜尋項目(工站、時間)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    set_s_searchData(tmp);
    call_actSocket.changeParams({ psID: s_searchData.psID, date: s_searchData.date });
  };

  return (
    <Wrapper ref={setContainer}>
      <PageHeader
        title={t("PDS.overview-lines.pageHeader")}
        extra={[
          <Select
            style={{ width: "150px" }}
            value={s_searchData.psID}
            onChange={(value) => handleChange("psID", value)}
            options={s_AllPS}
          />,
          <DatePicker
            placeholder="請選擇起始日期"
            onChange={(value) => handleChange("date", dayjs(value).format("YYYY-MM-DD"))}
            className="w-[150px]"
            value={dayjs(s_searchData.date)}
          />,
        ]}
      />

      {/* <div className="ChartContainer">
        <div style={{ width: "75%" }}>
          <LineChart s_board={s_board} width="100%" height="350px" />
        </div>

        <div style={{ width: "24%", height: "100%" }}>
          
          <div
            className="text-center text-4xl text-[#fbecfb] font-bold py-2 bg-purple-400"
            // style={{ textShadow: "3px 3px 0px #c0bfc1" }}
          >
            {t("PDS.overview-lines.pageHeader")}
          </div>
          <WaveChart s_AllPS={s_AllPS} wsID={s_searchData.wsID} s_act={s_act} height="290px" />
        </div>
      </div> */}

      {/* <FacetChart /> */}
      {/* <div className="mt-7 h-[calc(100%_-_400px)]"> */}
      <Spin spinning={!call_actSocket.socketData.status}>
        <div className="mt-7">
          <Affix target={() => container}>
            <Row className="FacetChartContainer text-[#1257E1]" style={{ zIndex: 999 }}>
              <Col className="flex-center p-[10px]" span={2}>
                {/* 線別 */}
                {t("PDS.util.wlID")}
              </Col>
              <Col className="flex-center p-[10px]" span={5}>
                {/* 日班(08:00 - 20:00) */}
                {t("PDS.overview-lines.shiftD")}(08:00 - 20:00)
              </Col>
              <Col className="flex-center p-[10px]" span={4}>
                {/* 日班稼動率 */}
                {t("PDS.overview-lines.shiftD") + t("PDS.overview-lines.act")}%
              </Col>
              <Col className="flex-center p-[10px]" span={5}>
                {/* 夜班(20:00 - 08:00) */}
                {t("PDS.overview-lines.shiftN")}(20:00 - 08:00)
              </Col>

              <Col className="flex-center p-[10px] z-50" span={4}>
                {/* 夜班稼動率 */}
                {t("PDS.overview-lines.shiftN") + t("PDS.overview-lines.act")}%
              </Col>
              <Col className="flex-center p-[10px]" span={4}>
                {/* 全天稼動率 */}
                {t("PDS.overview-lines.allDay") + t("PDS.overview-lines.act")}%
              </Col>
            </Row>
          </Affix>

          <div className="overflow-auto h-[calc(100%_-_70px)]" style={{ zIndex: 999 }}>
            {s_act?.map((item, index) => (
              <Row key={index} className="FacetChartContainer1">
                {/* 線別 */}
                <Col className="flex-center p-[10px]" span={2}>
                  {/* <div className="wsBtn">{item.wlID}</div> */}
                  <WsButton actData={item} />
                </Col>
                {/* 日班 */}
                <Col className="flex-center p-[10px]" span={5}>
                  <TimeChart date={s_searchData.date} type="morningData" data={item.morningData} />
                </Col>
                {/* 日班稼動率 */}
                <Col className="flex-center p-[10px]" span={4}>
                  {/* <OvalChart percentage={item.MorningACT} /> */}
                  <span className="text-[#1677FF] text-4xl font-semibold">{item.MorningACT}%</span>
                </Col>
                {/* 夜班 */}
                <Col className="flex-center p-[10px]" span={5}>
                  <TimeChart date={s_searchData.date} type="nightData" data={item.nightData} />
                </Col>
                {/* 夜班稼動率 */}
                <Col className="flex-center p-[10px]" span={4}>
                  {/* <OvalChart data={item.NightACT} /> */}
                  <span className="text-[#564ACE] text-4xl font-semibold">{item.NightACT}%</span>
                </Col>
                {/* 全天稼動率 */}
                <Col className="flex-center p-[10px]" span={4}>
                  <OvalChart data={item.AllACT} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </Spin>
      {/* </div> */}
    </Wrapper>
  );
};

export default OverViewLines;
