import React, { useEffect, useState } from "react";

import HD from "./HD";
import TB from "./TB";

export const DataContext = React.createContext();

const Optype = () => {
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  return (
    <DataContext.Provider value={{ c_lightData, set_c_lightData }}>
      <div className="h-full">
        <div className="h-1/2">
          <HD />
        </div>
        <div className="h-1/2">{Object.keys(c_lightData).length > 0 ? <TB /> : null}</div>
      </div>
    </DataContext.Provider>
  );
};

export default Optype;
