import bwipjs from "bwip-js";
import Konva from "konva";
import { LabelItemObj, PrinterParams } from "../types/type";
import { replaceItemVars, replaceVariable } from "./replaceItemVars";

const unitToPx = (value: number, unit: string): number => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    case "inch":
      return value * 96;
    default:
      return value;
  }
};

export function formatFloat(num: number, pos: number): number {
  const size = Math.pow(10, pos);
  return Math.round(num * size) / size;
}

const dragBoundFunc = (pos: Konva.Vector2d): Konva.Vector2d => {
  return {
    x: pos.x < 0 ? 0 : pos.x,
    y: pos.y < 0 ? 0 : pos.y,
  };
};

// 繪製文字
const createText = (layer: Konva.Layer, data: LabelItemObj) => {
  const totalTextWidth = (text: string, fontFamily: string, bold: string, fontSize: number) => {
    const context = document.createElement("canvas").getContext("2d")!;
    context.font = `${bold} ${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  const text = data.type === "keyText" || data.type === "quoteText" ? data.fakeText : data.text;

  const boxWidth = data.boxWidth!; // 添加 boxWidth
  let lines = [text]; // 初始化为单行

  // 判断是否需要分行
  if (totalTextWidth(text!, data.fontFamily!, data.bold!, data.fontSize!) > boxWidth) {
    lines = []; // 清空，准备添加多行

    let words = text!.split("");
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      let word = words[i];

      let width = totalTextWidth(
        currentLine + " " + word,
        data.fontFamily!,
        data.bold!,
        data.fontSize!
      );
      if (width < boxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  // 提升解析度 增加分辨率
  const UPSIZE = 3;
  context.scale(UPSIZE, UPSIZE);

  const lineHeight = data.fontSize! * 1.3;
  const canvasHeight = lines.length * lineHeight;

  canvas.width = boxWidth * UPSIZE;
  canvas.height = canvasHeight * UPSIZE;

  context.font = `${data.bold!} ${data.fontSize! * UPSIZE}px ${data.fontFamily}`;
  context.fillStyle = data.c!;
  context.textBaseline = "middle";

  for (let i = 0; i < lines.length; i++) {
    let margin = (i * data.fontSize! * UPSIZE) / 2;
    let linesLength = (i + 1) * lineHeight;
    let xStart = 0; // 默认为左对齐

    const lineWidth = totalTextWidth(lines[i]!, data.fontFamily!, data.bold!, data.fontSize!);

    // 根据 textAlign 调整 xStart
    if (data.boxAlign === "center") {
      xStart = (boxWidth - lineWidth) / 2;
    } else if (data.boxAlign === "right") {
      xStart = boxWidth - lineWidth;
    }

    context.fillText(lines[i]!, xStart * UPSIZE, (linesLength * UPSIZE) / 2 + margin);
  }

  const image = new Konva.Image({
    x: data.x!,
    y: data.y!,
    image: canvas,
    width: boxWidth * (data.fontWidth! / 100),
    height: canvasHeight,
    draggable: true,
    id: data.id!,
  });
  // 將圖片對象添加到層中
  layer.add(image);
};

// 繪製形狀
const createShape = (layer: Konva.Layer, data: LabelItemObj) => {
  switch (data.type) {
    case "border":
      let startPoint = { x: data.x!, y: data.y! };
      let endPoint = {
        x: startPoint.x + data.len! * Math.cos((data.angle! * Math.PI) / 180),
        y: startPoint.y + data.len! * Math.sin((data.angle! * Math.PI) / 180),
      };
      const line = new Konva.Line({
        draggable: true,
        // dragBoundFunc: dragBoundFunc,
        id: data.id!,
        points: [startPoint.x, startPoint.y, endPoint.x, endPoint.y],
        stroke: data.c!,
        strokeWidth: data.b!,
      });
      layer.add(line);
      break;
    case "round":
      const circle = new Konva.Circle({
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        id: data.id!,
        x: data.x!,
        y: data.y!,
        radius: data.radius!,
        fill: data.c!,
      });
      layer.add(circle);
      break;
    case "rect":
      const rect = new Konva.Rect({
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        id: data.id!,
        x: data.x!,
        y: data.y!,
        width: data.boxWidth!,
        height: data.len!,
        stroke: "black",
        strokeWidth: 1,
      });
      layer.add(rect);
      break;
    default:
      break;
  }
};

// 繪製 barcode
const createBarcodeImage = async (layer: Konva.Layer, data: LabelItemObj, scaleFactor: number) => {
  let opts: any = {
    bcid: data.type || "qrcode",
    text: data.fakeText || data.quoteText || "noData",
    scale: 3,
    includetext: false,
    monochrome: true,
  };
  if (data.type !== "datamatrix" && data.type !== "qrcode") {
    // 設定高度須把px 轉換成mm
    opts.height = (data.code128height! * 25.4) / 96;
  } else {
    opts.eclevel = "H";
  }

  if (data.type === "datamatrix") {
    // 防止舊模板出錯 預設"20x20"
    opts.version = data.datamatrixVer || "20x20";
  }

  if (data.type === "upca" || data.type === "ean13") {
    opts.includetext = true;
    opts.textsize = 9;
  }

  // 創建 barcode_canvas
  const barcode_canvas = document.createElement("canvas");
  // 使用 bwipjs 將條碼繪製到 canvas 上
  bwipjs.toCanvas(barcode_canvas, opts);
  // 將繪製好的條碼轉換為 base64 編碼的圖片資料
  const barcodeDataUrl = barcode_canvas.toDataURL("image/png", 1);

  return new Promise((resolve, reject) => {
    const barcode_image = new Image();
    barcode_image.src = barcodeDataUrl;
    barcode_image.onload = () => {
      const barcodeWidth =
        data.type === "datamatrix" || data.type === "qrcode" ? 126 : data.code128width!;
      const barcodeHeight =
        data.type === "datamatrix" || data.type === "qrcode" ? barcodeWidth : data.code128height!;
      const scaledWidth = barcodeWidth * data.barcodeSize!;
      const scaledHeight = barcodeHeight * data.barcodeSize!;
      const konvaImg = new Konva.Image({
        id: data.id!,
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        x: data.x!,
        y: data.y!,
        image: barcode_image,
        width: scaledWidth,
        height: scaledHeight,
        imageSmoothingEnabled: false, //禁用圖像平滑
      });

      // console.log(konvaImg.toDataURL());

      // 將圖片繪製到 canvas 上
      layer.add(konvaImg);
      resolve("ok");
    };

    barcode_image.onerror = () => reject("ng");
  });
};

// 繪製圖片指定位置
const createImage = (layer: Konva.Layer, data: LabelItemObj) => {
  return new Promise((resolve, reject) => {
    const _image = new Image();
    _image.src = data.imgUrl!;
    _image.onload = () => {
      const konvaImg = new Konva.Image({
        id: data.id!,
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        x: data.x!,
        y: data.y!,
        image: _image,
        width: data.boxWidth!,
        height: data.len!,
      });

      konvaImg.on("click", function () {
        if (this.stroke() !== "transparent") {
          // 如果元素已經有邊框，則取消邊框
          this.stroke("transparent");
          this.dash([]);
        } else {
          // 如果元素沒有邊框，則添加邊框
          this.stroke("lightblue"); // 设置边框颜色为浅蓝色
          this.strokeWidth(1);
          this.dash([10, 5]); // 设置虚线模式，10px 的线段，2px 的间隙
        }
        layer.draw();
      });

      // 將圖片繪製到 canvas 上
      layer.add(konvaImg);
      resolve("ok");
    };

    _image.onerror = () => reject("ng");
  });
};

/**
生成標籤的函數
@param {HTMLDivElement} ref - 用於渲染標籤的容器元素
@param {PrinterParams} variables - 要替換的變數
@param {LabelItemObj[]} models - 條碼模板
@param {Function} changeModels - 更新模板的函數
@returns {Promise<string>} - 回傳一個 Promise 物件，內容為生成的標籤圖片的 base64 編碼 URL
*/
const buildLabel = async (
  ref: HTMLDivElement,
  variables: PrinterParams,
  models: LabelItemObj[],
  changeModels: Function,
  set_is_chooseItem: Function
): Promise<string> => {
  // 放大倍數
  const scaleFactor = variables.size! as number;
  const stageWidth = unitToPx(variables.width! as number, variables.unit as string) * scaleFactor;
  const stageHeight = unitToPx(variables.height! as number, variables.unit as string) * scaleFactor;

  // Create a stage and layer
  const stage = new Konva.Stage({
    container: ref,
    width: stageWidth,
    height: stageHeight,
  });

  stage.scale({
    x: scaleFactor,
    y: scaleFactor,
  });

  // 切換元素邊框
  function toggleImageStroke(stage: Konva.Stage): void {
    const shapes = stage.getIntersection({
      x: stage?.pointerPos?.x || 0,
      y: stage?.pointerPos?.y || 0,
    });

    if (!shapes) return;

    const id = shapes?.id();

    const layer = shapes.getLayer(); // 获取属于该形状的层

    if (!layer || !layer.children || layer.children.length === 0) return;

    stage.children!.forEach((item) => {
      let element = item.children![0];
      // 确保它是一个Image
      if (element instanceof Konva.Image) {
        if (id === element.id()) {
          if (element.stroke() === "lightblue") {
            element.stroke("transparent");
            element.dash([]);
            set_is_chooseItem(0);
          } else {
            element.stroke("lightblue");
            element.strokeWidth(2);
            element.dash([10, 5]);
            set_is_chooseItem(element.id());
          }
        } else {
          element.stroke("transparent");
          element.dash([]);
        }
      }
    });

    layer.draw(); // 重新绘制层以应用更改
  }

  //
  stage.on("click", function () {
    toggleImageStroke(stage);
  });

  stage.on("dragend", function () {
    // 當拖動結束時觸發事件
    // 獲取拖動結束時滑鼠指針的座標
    let shapes = stage.getIntersection({
      x: stage?.pointerPos?.x || 0,
      y: stage?.pointerPos?.y || 0,
    });
    if (!shapes) return; // 如果沒有找到交互物件，則退出函數

    // 創建新的模型數組，用於存儲更新後的位置資訊
    const newModels: LabelItemObj[] = models.reduce(
      (data: LabelItemObj[], current: LabelItemObj) => {
        const targetID = shapes?.id();
        if (current.id === targetID) {
          // 如果模型的ID與交互物件的ID相符，表示該模型需要更新位置
          if (current.type === "border") {
            // 如果是border型別的模型，則更新x和y座標
            data.push({
              ...current,
              x: parseFloat((current.x + shapes?.attrs?.x).toFixed(2)),
              y: parseFloat((current.y + shapes?.attrs?.y).toFixed(2)),
            });
          } else {
            // 其他型別的模型，只更新x和y座標
            data.push({
              ...current,
              x: parseFloat((shapes?.attrs?.x).toFixed(2)),
              y: parseFloat((shapes?.attrs?.y).toFixed(2)),
            });
          }
        } else {
          // 非拖動的模型，保持原樣
          data.push(current);
        }
        return data;
      },
      []
    );

    // 將更新後的模型數組傳遞給changeModels函數進行更新
    changeModels(newModels);
  });

  let layer = new Konva.Layer();

  const background = new Konva.Rect({
    x: 0,
    y: 0,
    width: stage.width(),
    height: stage.height(),
    fill: "white",
  });
  layer.add(background);

  const dpi = window.devicePixelRatio * 96; // 螢幕每英寸的像素數
  const width = stage.width(); // 獲取`stage`的寬度
  const height = stage.height(); // 獲取`stage`的高度
  const xNum = (width / dpi) * 25.4; // 將寬度轉換為mm標準尺寸
  const yNum = (height / dpi) * 25.4; // 將高度轉換為mm標準尺寸

  // 繪製X軸標尺
  for (let i = 0; i < xNum; i++) {
    const x = (i / xNum) * width;
    const line = new Konva.Line({
      points: [x, 0, x, height],
      stroke: "#F3F3F3", // 使用RGBA格式設置線條顏色
      strokeWidth: i % 10 === 0 ? 0.5 : 0.2,
    });
    layer.add(line);
  }

  // 繪製y軸標尺
  for (let i = 0; i < yNum; i++) {
    const y = (i / yNum) * height;
    const line = new Konva.Line({
      points: [0, y, width, y],
      stroke: "#F3F3F3", // 使用RGBA格式設置線條顏色
      strokeWidth: i % 10 === 0 ? 0.5 : 0.2,
    });
    layer.add(line);
  }

  stage.add(layer);

  // 遍歷模板中的所有項目
  for (const model of models) {
    // 如果模板無效，則跳過此模板
    if (!model.isvalid) continue;
    let layer = new Konva.Layer();
    layer.on("mouseover", function () {
      document.body.style.cursor = "pointer";
    });
    layer.on("mouseout", function () {
      document.body.style.cursor = "default";
    });
    stage.add(layer);
    // 替換項目中的變數
    const data = replaceItemVars(models, model, variables);

    // 根據項目的類型繪製圖形或文字
    switch (data.type) {
      case "text":
      case "keyText":
      case "date":
      case "quoteText":
        createText(layer, data);
        break;
      case "border":
      case "round":
      case "rect":
        createShape(layer, data);
        break;
      case "img":
        await createImage(layer, data);
        break;
      default:
        await createBarcodeImage(layer, data, scaleFactor);
        break;
    }
  }

  // // 將畫布轉換成 base64 編碼的圖片 URL，並返回
  const dataURL = stage.toDataURL();
  return dataURL;
};

export default {
  buildLabel,
  replaceVariable,
  quoteRef: replaceItemVars,
};
