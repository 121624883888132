import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { getPWC, deletePWC } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";
import dayjs from "dayjs";

const ProductWork = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isModify, set_s_isModify] = useState(false);
  const call_getPWC = useAPI(getPWC);
  const call_deletePWC = useAPI(deletePWC);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getPWC.request({ pwcID: s_searchData.item });

  const tableColumns = [
    {
      title: t("util.util.SN"), //"序",
      dataIndex: "SN",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.procedure-manage.pwcID"), //"工作中心ID",
      dataIndex: "pwcID",
      align: "center",
      sorter: (a, b) => a.pwcID.localeCompare(b.pwcID),
      width: "10%",
    },
    {
      title: t("util.util.name"), //"名稱",
      dataIndex: "pwcNM",
      width: "20%",
    },
    // {
    //   title: "製程ID",
    //   dataIndex: "pfID",
    //   className: "bg-[#f9e9d7ac]",
    //   align: "center",
    // },
    // {
    //   title: "製程名稱",
    //   dataIndex: "pfNM",
    //   className: "bg-[#f9e9d7ac]",
    //   align: "left",
    //   width: "20%",
    // },
    {
      title: t("ADM.util.pf"), //"製程",
      dataIndex: "pfNM",
      className: "bg-[#f9e9d7ac]",
      align: "left",
      width: "20%",
      render: (_, rowData) => `${rowData.pfID}_${rowData.pfNM}`, //製程ID + 製程名稱
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
      // width: "20%",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      width: "10%",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => {
        const lastTwoDigits = rowData?.pwcID.slice(-2) || "";
        return (
          <Space>
            <IconPen
              onClick={(e) => {
                set_s_editData(rowData);
                set_s_isShowModal(true);
                set_s_isModify(true);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              disabled={lastTwoDigits.includes("00")}
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          </Space>
        );
      },
    },
  ];

  // 確認是否刪除資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deletePWC.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };
  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_s_editData({});
    if (call_getPWC.status === "suc") {
      // message.success(call_getPWC.msg);
      set_s_tableData(
        call_getPWC.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getPWC.status === "err") {
      message.error(t(`error.error-code.${call_getPWC.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPWC.status]);

  useEffect(() => {
    if (call_deletePWC.status === "suc") {
      message.success(call_deletePWC.msg);
    } else if (call_deletePWC.status === "err") {
      message.error(t(`error.error-code.${call_deletePWC.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deletePWC.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.map((item, index) => {
          return { ...item, key: index, SN: index + 1 };
        })}
        loading={call_getPWC.status === "load"}
        c_lightData={s_editData}
        rowClassName={(record, _) => {
          if (record.isvalid === false) {
            return "opacity-50";
          }
          return "";
        }}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_s_editData(record);
              set_s_isShowModal(true);
            },
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.procedure-manage.tabWork")}`
            : `${t("util.util.add")}${t("ADM.procedure-manage.tabWork")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => {
          set_s_isModify(false);
          set_s_isShowModal(false);
        }}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          s_isModify={s_isModify}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          set_s_isModify={set_s_isModify}
        />
      </CustomModal>
    </>
  );
});

export default ProductWork;
