import React, { useState, useEffect } from "react";
import { Button, Space, Modal, message, Input, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { getIntockWo } from "@/service/apis/LMS/LabelManage";
import { numberWithCommas } from "@/util/format";
import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ChooseLabel from "./ModalContainer/ChooseLabel";
import dayjs from "dayjs";

const FORMAT_DATE = "YYYY.MM.DD";

export default function LabelInStockPrinter() {
  const call_getIntockWo = useAPI(getIntockWo);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  // 查詢用資料
  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 200,
    woN: undefined,
    PN: undefined,
    pdtNM: undefined,
    // pageSize: 3,
  });
  const [s_showChooseModal, set_s_showChooseModal] = useState(false);

  // const [c_lightData, set_c_lightData] = useState({});

  const [s_selectedRows, set_s_selectedRows] = useState([]);

  const [s_tableData, set_s_tableData] = useState([]);

  const onSelectChange = (_, newSelectedRows) => {
    set_s_selectedRows(newSelectedRows);
  };

  const tableColumns = [
    { title: "工單號", dataIndex: "woN", align: "center" },
    { title: "料號", dataIndex: "PN", align: "center" },
    { title: "品名", dataIndex: "pdtNM", width: 400 },
    { title: "規格", dataIndex: "pdtspec", width: 350 },
    { title: "訂單號", dataIndex: "orderN", align: "center" },
    { title: "訂單項次", dataIndex: "orderitemno", align: "center" },
    { title: "客戶名稱", dataIndex: "custNM" },
  ];

  const rowSelection = {
    selectedRowKeys: s_selectedRows.map((x) => x.key),
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      set_s_searchData((prev) => ({ ...prev, page: 1 }));
      call_getIntockWo.request({ ...s_searchData, page: 1 });
    }
  };

  useEffect(() => {
    call_getIntockWo.request(s_searchData);
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (call_getIntockWo.status === "suc") {
      set_s_pageInfo(call_getIntockWo.data.pageInfo);

      const newData =
        call_getIntockWo.data?.tableData?.map((x, i) => {
          let rowData = {
            ...x,
            key: i + 1,
            index: i + 1,
            WADAT: dayjs(x.WADAT).format(FORMAT_DATE),
          };

          for (const index in x.class) {
            rowData[x.class[index].ATBEZ] =
              x.class[index].ATWTB !== " " ? x.class[index].ATWTB : x.class[index].ATWRT;
          }
          return rowData;
        }) || [];

      console.log(newData);
      set_s_tableData(newData);
    } else if (call_getIntockWo.status === "err") {
      message.error(call_getIntockWo.msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getIntockWo.status]);

  return (
    <>
      <PageHeader
        title="入庫標籤列印"
        extra={
          <Space>
            <Input
              className="width-[300px]"
              placeholder="請輸入品名"
              value={s_searchData.pdtNM}
              onKeyDown={handleKeyDownEnter}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, pdtNM: e.target.value }))}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入料號"
              value={s_searchData.PN}
              onKeyDown={handleKeyDownEnter}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, PN: e.target.value }))}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入工單號"
              value={s_searchData.woN}
              onKeyDown={handleKeyDownEnter}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, woN: e.target.value }))}
            />

            <Button
              type="primary"
              onClick={() => {
                set_s_searchData((prev) => ({ ...prev, page: 1 }));
                call_getIntockWo.request({ ...s_searchData, page: 1 });
              }}
            >
              查詢
            </Button>

            <Button
              disabled={s_selectedRows.length === 0}
              icon={<PrinterOutlined />}
              onClick={() => set_s_showChooseModal(true)}
            >
              列印
            </Button>
          </Space>
        }
      />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getIntockWo.status === "load"}
        rowSelection={rowSelection}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />

      <CustomModal
        width="90%"
        title="選擇列印模板"
        open={s_showChooseModal}
        onCancel={() => set_s_showChooseModal(false)}
        afterClose={() => {
          set_s_selectedRows([]);
          call_getIntockWo.request(s_searchData);
        }}
      >
        {s_showChooseModal && (
          <ChooseLabel set_s_showModal={set_s_showChooseModal} s_keyParams={s_selectedRows} />
        )}
      </CustomModal>
    </>
  );
}
