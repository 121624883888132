import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
// api
import useAPI from "@/hooks/useAPI";
import { addQmsSipTb, updateQmsSipTb } from "@/service/apis/QMS/QMSManage";
import { formatAmount } from "@/util/format";

import Preview from "../../../File/Preview";

const StyleInputNumber = styled(InputNumber)`
  .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ant-input-number-input {
    height: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    color: #1677ff;
  }
`;

const StyleInput = styled(Input)`
  .ant-input {
    height: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    color: #1677ff;
    margin-left: -2em;
  }
`;

const ModalContainer = ({ s_editData, set_s_editData, c_lightData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_drawing = Form.useWatch("drawing", form);
  const f_insptype = Form.useWatch("insptype", form);
  // 新增表身資料
  const call_addQmsSipTb = useAPI(addQmsSipTb);
  // 編輯表身資料
  const call_updateQmsSipTb = useAPI(updateQmsSipTb);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      console.log("values = ", values);
      call_addQmsSipTb.request(values);
    }
    if (type === "edit") {
      call_updateQmsSipTb.request(values);
    }
  };
  useEffect(() => {
    form.setFieldsValue({ sipID: c_lightData.sipID, dcUUID: c_lightData.dcUUID });
  }, []);

  useEffect(() => {
    const { status, msg } = call_addQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipTb.status]);

  useEffect(() => {
    const { status, msg } = call_updateQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipTb.status]);
  console.log("c_lightData = ", c_lightData);
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        sipcond: "常溫常壓",
        UOM: "mm",
        samplesize: 1,
        ...s_editData,
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="drawing" hidden>
          <Input />
        </Form.Item>
      </div>

      {/* {c_lightData.drawing && (
        <Row gutter={[12, 12]}>
          <Col span={12} className="flex text-lg">
            <Typography.Title level={5}>工程圖檔：</Typography.Title>
            <Typography.Title level={5} className="mt-0">
              <Preview url={c_lightData.drawing} />
            </Typography.Title>
          </Col>
          <Divider className="mt-0" />
        </Row>
      )} */}

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="檢驗項目" name="inspitem" rules={[{ required: true }]}>
            <Input placeholder="請輸入檢驗項目" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="檢驗類別" name="itemtype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "主要缺陷", value: "normal" },
                { label: "次要缺陷", value: "secure" },
                { label: "嚴重缺陷", value: "sepcial" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="驗收型態" name="insptype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              onChange={() => {
                form.setFieldsValue({
                  drawarea: "",
                  drawno: "",
                });
              }}
              allowClear
              options={[
                { label: "計數型(定性)", value: "count" },
                { label: "計量型", value: "measure" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label={f_insptype === "count" ? "檢驗重點" : "檢測設備"} name="inspkeynote">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="樣本大小" name="samplesize">
            <InputNumber className="w-full" placeholder="請輸入樣本大小" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="檢測環境" name="sipcond">
            <Input disabled={f_insptype === "measure"} placeholder="請輸入檢測環境" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="允收品質水準" name="AQL">
            {/* <Input placeholder="請輸入允收品質水準" /> */}
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "正常檢驗計劃 I ", value: "1" },
                { label: "正常檢驗計劃 II", value: "2" },
                { label: "正常檢驗計劃 III", value: "3" },
                { label: "特殊抽樣計劃 S-1", value: "S1" },
                { label: "特殊抽樣計劃 S-2", value: "S2" },
                { label: "特殊抽樣計劃 S-3", value: "S3" },
                { label: "特殊抽樣計劃 S-4", value: "S4" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="加嚴檢驗" name="TINSP">
            <Input disabled placeholder="請輸入加嚴檢驗" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="減量檢驗" name="RINSP">
            <Input disabled placeholder="請輸入檢驗檢驗" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8} className="">
          <Typography.Text level={5}>工程圖檔：</Typography.Text>
          <Typography.Title level={5} className="mt-0">
            <Preview url={s_editData.drawing || c_lightData.drawing} />
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Form.Item label="圖面圖域" name="drawarea">
            <Input disabled={f_insptype === "count"} placeholder="請輸入圖面圖域" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="圖面球號" name="drawno">
            <Input disabled={f_insptype === "count"} placeholder="請輸入圖面球號" />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={[12, 12]}>
        <Col span={8} className="">
          <Typography.Text level={5}>工程圖檔：</Typography.Text>
          <Typography.Title level={5} className="mt-0">
            <Preview url={c_lightData.drawing} />
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Form.Item label="測量單位" name="UOM">
            <Input disabled={f_insptype === "count"} placeholder="請輸入測量單位" />
          </Form.Item>
        </Col>
      </Row> */}

      <Divider className="my-2" />

      <Row gutter={[12, 12]}>
        <Col span={8} className="">
          <Space.Compact direction="vertical">
            <Form.Item
              className="h-full mb-[0px]"
              label={<span className="text-blue-600 font-bold">標準值</span>}
              name="CL"
            >
              <StyleInputNumber
                formatter={(value) => formatAmount(value)}
                className="w-full h-[90px] font-bold"
                placeholder="請輸入標準值"
                disabled={f_insptype === "count"}
                onChange={(e) => {
                  form.setFieldsValue({ CL: e, USL: e, LSL: e, UCL: e, LCL: e });
                }}
                style={{
                  borderBottom: "0px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
              />
            </Form.Item>

            <Form.Item name="UOM" className="mb-[0px]">
              <StyleInput
                prefix={<span className="z-[1]">單位</span>}
                style={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              />
            </Form.Item>
          </Space.Compact>
        </Col>

        <Col span={16}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="規格上限" name="USL" className="mb-[8px]">
                <InputNumber
                  size="large"
                  formatter={(value) => formatAmount(value)}
                  className="w-full mb-[0px]"
                  onChange={(value) => form.setFieldValue("UCL", value)}
                  placeholder="請輸入規格上限"
                  disabled={f_insptype === "count"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="規格下限" name="LSL" className="mb-[0px]">
                <InputNumber
                  size="large"
                  formatter={(value) => formatAmount(value)}
                  className="w-full"
                  onChange={(value) => form.setFieldValue("LCL", value)}
                  placeholder="請輸入規格下限"
                  disabled={f_insptype === "count"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="管制上限" name="UCL" className="mb-[0px]">
                <InputNumber
                  size="large"
                  formatter={(value) => formatAmount(value)}
                  className="w-full"
                  placeholder="請輸入管制上限"
                  disabled={f_insptype === "count"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="管制下限" name="LCL" className="mb-[0px]">
                <InputNumber
                  size="large"
                  formatter={(value) => formatAmount(value)}
                  className="w-full"
                  placeholder="請輸入規格下限"
                  disabled={f_insptype === "count"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[12, 12]}>
      <Col span={12}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={12} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
