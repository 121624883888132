import React, { useState, useEffect } from "react";
import { Button, Space, Modal, message, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { getLabel, deleteLabel } from "@/service/apis/LMS/LabelManage";
import { IconGarbage, IconPen } from "@/components/Icon/Action";

import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";

export default function LabelPaper() {
  const call_getLabel = useAPI(getLabel);
  const call_deleteLabel = useAPI(deleteLabel);

  const [s_showModal, set_s_showModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const [tableData, setTableData] = useState([]);

  const tableColumns = [
    { title: "標籤紙ID", dataIndex: "labelID" },
    { title: "標籤紙名稱", dataIndex: "labelNM" },
    { title: "標籤紙類型", dataIndex: "labelType" },
    { title: "標籤寬度", dataIndex: "width", align: "center" },
    { title: "標籤寬度單位", dataIndex: "widthUnit", align: "center" },
    { title: "標籤高度", dataIndex: "high", align: "center" },
    { title: "標籤高度單位", dataIndex: "highUnit", align: "center" },
    // { title: "軸芯", dataIndex: "coreSize", align: "center" },
    // { title: "軸芯單位", dataIndex: "coreUnit", align: "center" },
    // { title: "紙捲長度", dataIndex: "length", align: "center" },
    // { title: "紙捲長度單位", dataIndex: "lengthUnit", align: "center" },
    { title: "備註", dataIndex: "note", align: "center" },
    {
      title: "更新時間",
      dataIndex: "astUpdateT",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => {
        return (
          <Space className="font-size-24">
            <IconPen
              onClick={(e) => {
                e.stopPropagation(); // 這裡不要開啟表身
                set_s_editData(rowData);
                set_s_showModal(true);
              }}
            />
            <IconGarbage onClick={(e) => deleteRow(e, rowData)} />
          </Space>
        );
      },
    },
  ];

  const deleteRow = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteLabel.request({ ...rowData }),
      cancelText: "取消",
    });
  };

  const search = (e) => {
    if (e === "" || !e) {
      setTableData(
        call_getLabel.data.map((x) => {
          return { ...x, key: x.labelID };
        })
      );
    } else {
      // filter key
      setTableData((prev) =>
        prev.filter((x) => x.labelID.indexOf(e) !== -1 || x.labelNM.indexOf(e) !== -1)
      );
    }
  };

  useEffect(() => {
    call_getLabel.request();
  }, []);

  useEffect(() => {
    if (call_getLabel.status === "suc") {
      setTableData(call_getLabel.data.map((x) => ({ ...x, key: x.labelID })));
    } else if (call_getLabel.status === "err") {
      message.error(call_getLabel.msg);
      setTableData([]);
    }
  }, [call_getLabel.status]);

  useEffect(() => {
    if (call_deleteLabel.status === "suc") {
      call_getLabel.request();
      set_s_editData({});
    } else if (call_deleteLabel.status === "err") {
      message.error(call_deleteLabel.msg);
    }
  }, [call_deleteLabel.status]);

  return (
    <>
      <PageHeader
        title="條碼紙版管理"
        extra={
          <Space align="end" style={{ display: "flex", justifyContent: "right" }}>
            <Input.Search
              style={{ width: "300px" }}
              placeholder="請輸入紙張ID或紙張名稱"
              enterButton
              onSearch={search}
            />
            <Button type="primary" onClick={() => set_s_showModal(true)}>
              新增
            </Button>
          </Space>
        }
      />
      <CustomTable columns={tableColumns} dataSource={tableData} />

      <CustomModal
        width="60%"
        title={Object.keys(s_editData).length > 0 ? "編輯標籤紙資料" : "新建標籤紙資料"}
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getLabel.request();
        }}
      >
        {s_showModal && (
          <ModalContainer set_s_showModal={set_s_showModal} s_editData={s_editData} />
        )}
      </CustomModal>
    </>
  );
}
