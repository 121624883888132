import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconRollbackOutlined, IconClose } from "@/components/Icon/Action";

import { getWh, deleteWh, getPS, closeWh } from "@/service/apis/ADM/publicAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
// util
import { multipleIDtoName } from "@/util/format";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const WareHouse = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_mgmtcat71Options, set_s_mgmtcat71Options] = useState([]);
  const [s_getPsOptions, set_s_getPsOptions] = useState([]);
  const { t } = useTranslation();

  const call_getWh = useAPI(getWh);
  const call_deleteWh = useAPI(deleteWh);
  const call_closeWh = useAPI(closeWh);
  const call_getPS = useAPI(getPS);
  const call_getMgmtcat = useAPI(getMgmtcat);

  const pdtClassMap = [
    // { label: "全部", value: "All" },
    { label: t("util.util.attribute_0"), value: "0" },
    { label: t("util.util.attribute_1"), value: "1" },
    { label: t("util.util.attribute_2"), value: "2" },
    { label: t("util.util.attribute_3"), value: "3" },
    { label: t("util.util.attribute_4"), value: "4" },
    { label: t("util.util.attribute_5"), value: "5" },
    { label: t("util.util.attribute_6"), value: "6" },
    { label: t("util.util.attribute_7"), value: "7" },
    { label: t("util.util.attribute_8"), value: "8" },
    { label: t("util.util.attribute_9"), value: "9" },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getWh.request({ whID: s_searchData.item });

  const tableColumns = [
    {
      title: t("ADM.warehouse-manage.whID"), //"倉別",
      dataIndex: "whID",
      width: "7%",
    },
    {
      title: t("ADM.warehouse-manage.whNM"), //"倉別名稱",
      dataIndex: "whNM",
    },
    {
      title: t("ADM.warehouse-manage.psNM"), //"車間名稱",
      dataIndex: "psID",
      align: "center",
      render: (text) => s_getPsOptions.filter((x) => x.value === text)[0]?.label,
      width: "15%",
    },
    {
      title: t("ADM.warehouse-manage.pdtclass"), //"料階",
      dataIndex: "pdtclass",
      align: "center",
      width: "16%",
      // render: (text) => pdtClassMap.filter((x) => x.value === text)[0]?.label,
      // render: (text) => multipleIDtoName(pdtClassMap, text, "value", "label"),
      render: (text) =>
        pdtClassMap
          .filter((x) => new Set(text).has(x.value))
          .map((x) => x.label)
          .join("，"),
    },
    {
      title: t("ADM.warehouse-manage.mgmtcat"), //"庫房授權",
      dataIndex: "mgmtcat",
      align: "center",
      render: (text) =>
        s_mgmtcat71Options
          .filter((x) => new Set(text).has(x.value))
          .map((x) => x.label)
          .join("，"),
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "25%",
    },
    {
      title: t("ADM.warehouse-manage.isClose"), //"是否關帳",
      dataIndex: "close",
      align: "center",
      render: (text) => (text ? t("util.util.yes") : t("util.util.no")),
      onCell: (record) => ({
        className: record.close ? "bg-[#ffd3d3]" : "bg-[#d3ffdc]",
      }),
    },
    {
      title: t("ADM.warehouse-manage.close"), //"關帳",
      dataIndex: "close",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <IconClose
          disabled={rowData.close}
          onClick={() => call_closeWh.request({ ...rowData, close: !rowData.close })}
        />
      ),
    },
    {
      title: t("ADM.warehouse-manage.cancelClose"), //"取消關帳",
      dataIndex: "cancelClose",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <IconRollbackOutlined
          disabled={!rowData.close}
          onClick={() => call_closeWh.request({ ...rowData, close: !rowData.close })}
        />
      ),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteWh.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getMgmtcat.request({ pageType: 71, type: "wh" });
    call_getPS.request();
  }, []);

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getWh.status === "suc") {
      message.success(call_getWh.msg);
      set_s_tableData(
        call_getWh.data.map((x, i) => {
          const mgmtcat = x.mgmtcat.split("|").filter((x) => x !== "");
          const pdtclass = x.pdtclass.split("|").filter((x) => x !== "");

          return {
            ...x,
            key: i,
            mgmtcat: mgmtcat,
            pdtclass: pdtclass,
          };
        })
      );
    } else if (call_getWh.status === "err") {
      message.error(t(`error.error-code.${call_getWh.msg}`));
      set_s_tableData([]);
    }
  }, [call_getWh.status]);

  useEffect(() => {
    if (call_deleteWh.status === "suc") {
      message.success(call_deleteWh.msg);
    } else if (call_deleteWh.status === "err") {
      message.error(t(`error.error-code.${call_deleteWh.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deleteWh.status]);

  useEffect(() => {
    if (call_closeWh.status === "suc") {
      message.success(call_closeWh.msg);
      onSearch();
    } else if (call_closeWh.status === "err") {
      message.error(t(`error.error-code.${call_closeWh.msg}`));
      onSearch();
    }
  }, [call_closeWh.status]);

  useEffect(() => {
    if (call_getPS.status === "suc") {
      set_s_getPsOptions(
        call_getPS.data.map((x) => ({
          label: x.psNM,
          value: x.psID,
        }))
      );
    } else if (call_getPS.status === "err") {
      // message.error(call_getPS.msg);
    }
  }, [call_getPS.status]);

  useEffect(() => {
    if (call_getMgmtcat.status === "suc") {
      set_s_mgmtcat71Options(
        call_getMgmtcat.data.reduce(
          (data, current) => {
            data.push({
              label: current.itemNM,
              value: current.mgmtitem,
            });
            return data;
          },
          [{ label: "全部", value: "All" }]
        ) || []
      );
    } else if (call_getMgmtcat.status === "err") {
      // message.error(call_getMgmtcat.msg);
    }
  }, [call_getMgmtcat.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getWh.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.warehouse-manage.tabWareHouse")}`
            : `${t("util.util.add")}${t("ADM.warehouse-manage.tabWareHouse")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={{
            s_mgmtcat71Options: s_mgmtcat71Options,
            s_getPsOptions: s_getPsOptions,
            pdtClassMap: pdtClassMap,
          }}
        />
      </CustomModal>
    </>
  );
});

export default WareHouse;
