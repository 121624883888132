import React from "react";

import MyCard from "@/pages/TestPage/QMS/SPC/Alert/Card/index";
import { useTranslation } from "react-i18next";

import { IconException } from "@/components/Icon/Navbar";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const WarringTextColor = "text-[#FA5D51]";
const AlertCard = ({ call_getDrawno }) => {
  const { t } = useTranslation();
  const alertData = [
    { date: "12-04 10:54", title: "異常警報-231204000002" },
    { date: "12-04 10:54", title: "異常警報-231204000003" },
    { date: "12-04 10:54", title: "異常警報-231204000004" },
  ];
  return (
    <div>
      <Spin spinning={call_getDrawno.status === "load"}>
        <MyCard
          type="QualityControl"
          content={
            <div className="w-full flex justify-around items-center py-[10px] ">
              <div className=" ">
                <IconException className={"text-[50px]  text-red-600 "} />
              </div>
              <div className="">
                {alertData.map((item, index) => (
                  <div key={index} className="flex justify-around mb-[20px] gap-5 items-center    ">
                    <span className={`${WarringTextColor} text-[15px]`}>{item.title}</span>
                    <span className={`text-[10px]`}> {item.date}</span>
                  </div>
                ))}
              </div>
              <div className="h-full flex items-center ">
                <CaretDownOutlined />
              </div>
            </div>
          }
          titleAlign="center"
          align="center"
          borderAlign="top"
          color="#FA5D51"
        />
      </Spin>
    </div>
  );
};

export default AlertCard;
