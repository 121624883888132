import { Modal, Space, Spin, message } from "antd";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import Preview from "@/components/Global/Preview/index";
import { IconCheck, IconClose, IconFile, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { deleteInspHd, getInspHd } from "@/service/apis/QMS/meds";
// import { uploadInspTb } from "@/service/apis/QMS/meds";
import { updateInspHd } from "@/service/apis/QMS/meds";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer";
import UploadModalConintaer from "./ModalContainer/FileModalContainer";

// 檢驗規格維護 HD
const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { c_lightData, set_s_isShowModal, s_isShowModal, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const [s_mgmtcat75, set_s_mgmtcat75] = useState([]);
  const [s_mgmtcat74, set_s_mgmtcat74] = useState([]); //管理分類
  const [s_mgmtcat71, set_s_mgmtcat71] = useState([]);

  const call_getMgmtcat75 = useAPI(getMgmtcat);
  const call_getMgmtcat74 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);
  const call_getMgmtcat = useAPI(getMgmtcat);

  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);

  const call_getInspHd = useAPI(getInspHd);
  const call_deleteInspHd = useAPI(deleteInspHd);

  // const call_uploadInspHd = useAPI(uploadInspHd);
  const call_updateInspHd = useAPI(updateInspHd);

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],

    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) || [],

    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    pwc:
      call_getPWC?.data.map((pwc) => ({ label: `${pwc.pwcID}_${pwc.pwcNM}`, value: pwc.pwcID })) ||
      [],
    ctrlrequire: [
      { value: "A", label: "每次取樣創建檢驗單" },
      { value: "B", label: "固定週期自創檢驗單" },
      { value: "C", label: "物控覆核後自創檢單" },
      { value: "D", label: "自動班別創建檢檢單" },
    ],
  };

  const [s_isShowUploadModal, set_s_isShowUploadModal] = useState(false);

  const deleteHd = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteInspHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };
  const removeDrawing = (rowData) => {
    Modal.confirm({
      title: "刪除圖檔確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_updateInspHd.request({ ...rowData, drawing: "" }),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "SPECID",
      dataIndex: "specID",
      align: "left",
      render: (text) => {
        const showText = text.split("_");
        return `${showText[0]}_${showText[1]}`;
      },

      width: 220,
    },
    {
      title: "版次",
      dataIndex: "version",
      align: "center",

      width: 50,
    },
    {
      title: "規格圖檔",
      dataIndex: "drawing",
      width: 200,

      align: "left",
      render: (text) => text && <Preview url={text} type="PDF" />,
    },

    {
      title: "群組",
      dataIndex: "group",
      key: "group",
      align: "center",
      width: 135,
      render: (text) => {
        return [
          { label: "工程圖面", value: "draw" },
          { label: "引用規格", value: "from" },
        ].find((item) => item.value === text)?.label;
      },
    },
    {
      title: "群組說明",
      dataIndex: "groupNM",
      key: "groupNM",
      width: 150,
    },
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      width: "10%",
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      width: 180,
      className: "truncate",
      render: (text) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: t("util.util.pdtspec"),
      dataIndex: "pdtspec",
      align: "left",
      width: 150,
      render: (text) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      // width: 100,
      align: "left",
      render: (text) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: "引用檢驗規格",
      dataIndex: "datafm", // getInspHD
      // width: 100,
      align: "left",
      render: (text) => {
        return <ShowTooltip title={text} />;
      },
    },
    {
      title: "是否\n有效",
      dataIndex: "isvalid",
      align: "center",
      width: 70,
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },

    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "6%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "4.5%",
      render: (_, rowData) => {
        const isDrawing =
          rowData.drawing.length > 0 ? (
            <CloseOutlined
              onClick={(e) => {
                removeDrawing(rowData);
                e.stopPropagation();
              }}
            />
          ) : (
            <IconFile
              onClick={(e) => {
                console.log(rowData);
                set_s_isShowUploadModal(true);
                set_s_editData(rowData);
                e.stopPropagation();
              }}
            />
          );

        return (
          <Space>
            {isDrawing}
            <IconPen
              onClick={(e) => {
                set_s_editData(rowData);
                set_s_isShowModal(true);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              onClick={(e) => {
                deleteHd(e, rowData);
                // e.stopPropagation();
              }}
            />
          </Space>
        );
      },
    },
  ];

  const attributeMap = [
    { label: t("util.util.attribute_0"), value: "0" },
    { label: t("util.util.attribute_1"), value: "1" },
    { label: t("util.util.attribute_2"), value: "2" },
    { label: t("util.util.attribute_3"), value: "3" },
    { label: t("util.util.attribute_4"), value: "4" },
    { label: t("util.util.attribute_5"), value: "5" },
    { label: t("util.util.attribute_6"), value: "6" },
    { label: t("util.util.attribute_7"), value: "7" },
    { label: t("util.util.attribute_8"), value: "8" },
    { label: t("util.util.attribute_9"), value: "9" },
  ];
  const pdtclassMap = [
    { label: t("util.util.pdtclass_X"), value: "X" },
    { label: t("util.util.pdtclass_M"), value: "M" },
    { label: t("util.util.pdtclass_A"), value: "A" },
    { label: t("util.util.pdtclass_P"), value: "P" },
  ];

  useEffect(() => {
    call_getMgmtcat.request({ pageType: "74" });
    call_getMgmtcat75.request({ pageType: "75" });
    call_getMgmtcat71.request({ pageType: "71", type: "pdt" });
    call_getMgmtcat74.request({ pageType: "74" });
    call_getInspHd.request();
  }, []);

  useEffect(() => {
    if (call_getMgmtcat.status === "err") {
      message.error(call_getMgmtcat.msg);
    }
  }, [call_getMgmtcat.status]);

  useEffect(() => {
    if (call_getMgmtcat75.status === "suc") {
      console.log("call_getMgmtcat75.data = ", call_getMgmtcat75.data);
      set_s_mgmtcat75(call_getMgmtcat75.data);
    } else if (call_getMgmtcat75.status === "err") {
      message.error(call_getMgmtcat75.msg);
      set_s_mgmtcat75([]);
    }
  }, [call_getMgmtcat75.status]);

  useEffect(() => {
    if (call_getMgmtcat74.status === "suc") {
      const renderData = call_getMgmtcat74.data?.map((item) => ({
        label: item.itemNM,
        value: item.mgmtitem,
      }));
      // console.log("call_getMgmtcat74.data = ", call_getMgmtcat74.data);
      set_s_mgmtcat74(call_getMgmtcat74.data);
    } else if (call_getMgmtcat74.status === "err") {
      message.error(call_getMgmtcat74.msg);
      set_s_mgmtcat74([]);
    }
  }, [call_getMgmtcat74.status]);

  useEffect(() => {
    if (call_deleteInspHd.status === "suc") {
      message.success(call_deleteInspHd.msg);
      call_getInspHd.request();
    }
    if (call_deleteInspHd.status === "err") {
      message.error(call_deleteInspHd.msg);
    }
  }, [call_deleteInspHd.status]);
  useEffect(() => {
    if (call_getInspHd.status === "suc") {
      message.success(call_getInspHd.msg);
      set_s_tableData(
        call_getInspHd.data.map((item) => ({ ...item, updtT: dayjs(item.updtT), key: item.dcUUID }))
      );
    }
    if (call_getInspHd.status === "err") {
      message.error(call_getInspHd.msg);
      set_s_tableData([]);
    }
  }, [call_getInspHd.status]);
  useEffect(() => {
    if (call_getMgmtcat71.status === "suc") {
      set_s_mgmtcat71(call_getMgmtcat71.data);
    }
    if (call_getMgmtcat71.status === "err") {
      message.error(call_getMgmtcat71.msg);
      set_s_mgmtcat71([]);
    }
  }, [call_getMgmtcat71.status]);

  useEffect(() => {
    const { status, msg, data } = call_updateInspHd;
    if (status === "suc") {
      message.success(msg);
      call_getInspHd.request();

      // set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateInspHd.status]);

  return (
    <>
      <Spin spinning={call_deleteInspHd.status === "load" || call_getInspHd.status === "load"}>
        <CustomTable
          columns={tableColumns}
          dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
          tbMode={Object.keys(c_lightData).length > 0}
          c_lightData={c_lightData}
          onRow={(record) => {
            return {
              onClick: () =>
                record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
            };
          }}
        />
      </Spin>
      <CustomModal
        title={`上傳檔案`}
        width={"60%"}
        open={s_isShowUploadModal}
        onCancel={() => set_s_isShowUploadModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getInspHd.request();
        }}
      >
        <UploadModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowUploadModal} />
      </CustomModal>
      <CustomModal
        style={{ top: 275 }}
        title={
          Object.keys(c_lightData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          set_s_editData({});
          call_getInspHd.request();
        }}
      >
        <ModalConintaer
          arrMap={{
            attributeMap: attributeMap,
            pdtclassMap: pdtclassMap,
            mgmtcat71: s_mgmtcat71,
            mgmtcat74: s_mgmtcat74,
            mgmtcat75: s_mgmtcat75,
          }}
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
