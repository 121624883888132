import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Divider,
  message,
  Radio,
  Collapse,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";

import { addSuppHd, modifySuppHd } from "@/service/apis/ADM/company";
import { getAdd, getCurrency } from "@/service/apis/ADM/functionParamsManage";
import useAPI from "@/hooks/useAPI";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addSuppHd = useAPI(addSuppHd);
  const call_modifySuppHd = useAPI(modifySuppHd);
  const call_getAdd = useAPI(getAdd);
  const call_getCurrency = useAPI(getCurrency);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|") ?? "";
    }

    if (type === "create") {
      call_addSuppHd.request(values);
    }

    if (type === "edit") {
      call_modifySuppHd.request(values);
    }
  };

  useEffect(() => {
    console.log(s_editData);
    call_getAdd.request();
    call_getCurrency.request();
  }, []);

  useEffect(() => {
    if (call_addSuppHd.status === "suc") {
      message.success(call_addSuppHd.msg);
      set_s_isShowModal(false);
    } else if (call_addSuppHd.status === "err") {
      message.error(t(`error.error-code.${call_addSuppHd.msg}`));
    }
  }, [call_addSuppHd.status]);

  useEffect(() => {
    if (call_modifySuppHd.status === "suc") {
      message.success(call_modifySuppHd.msg);
      set_s_isShowModal(false);
    } else if (call_modifySuppHd.status === "err") {
      message.error(t(`error.error-code.${call_modifySuppHd.msg}`));
    }
  }, [call_modifySuppHd.status]);

  // useEffect(() => {
  //   if (call_getAdd.status === "err") {
  //     message.error(call_getAdd.msg);
  //   }
  // }, [call_getAdd.status]);

  // useEffect(() => {
  //   if (call_getCurrency.status === "err") {
  //     message.error(call_getCurrency.msg);
  //   }
  // }, [call_getCurrency.status]);
  console.log("arrMap?.s_mgmtcat71Options = ", arrMap?.s_mgmtcat71Options);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Collapse defaultActiveKey={[1]}>
            <Panel header={t("ADM.company.manufacturerBasicInformation")} key="1">
              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    label={t("util.util.suppID")}
                    name="suppID"
                    rules={[{ required: true }]}
                  >
                    <Input disabled={Object.keys(s_editData).length > 0} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("util.util.suppalias")}
                    name="suppalias"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("util.util.suppNM")}
                    name="suppNM"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item label={t("ADM.company.groupalias")} name="groupalias">
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.location")} // 市場區域
                    name="location"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("util.util.mgmtcat")} // 授權分類
                    name="mgmtcat"
                    rules={[{ required: true }]}
                  >
                    <Select mode="tags" allowClear options={arrMap?.s_mgmtcat71Options} />
                  </Form.Item>
                </Col>

                <Col span={8} />

                <Col span={8}>
                  <Form.Item
                    label={t("util.util.querycat")}
                    name="querycat"
                    rules={[{ required: true }]}
                  >
                    <Select allowClear options={arrMap?.s_mgmtcat72Options} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.taxID")}
                    name="taxID"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("util.util.currencytype")}
                    name="maincurrency"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getCurrency.data?.map((x) => ({
                          label: x.currencyitem,
                          value: x.currencyitem,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.payterm")}
                    name="payterm"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getAdd.data?.filter(item => item.addtype === 'addh')?.map((x) => ({
                          label: x.itemNM,
                          key: x.additem,
                          value: x.itemNM,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.maxcredit")}
                    name="maxcredit"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label={t("util.util.tel")} name="tel">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item label={t("ADM.company.addr")} name="addr">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label={t("util.util.isvalid")} name="isvalid">
                    <Radio.Group
                      options={[
                        { value: true, label: t("util.util.yes") },
                        { value: false, label: t("util.util.no") },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header={t("ADM.company.domestic_OverseasBankInformation")} key="2">
              <Row gutter={[24, 0]}>
                <Col span={6}>
                  <Form.Item
                    label={
                      <span className="text-[#9FB2FC]">{t("ADM.company.domesticBankName")}</span>
                    }
                    name="bank"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={9}>
                  <Row gutter={[24, 0]}>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.branchOS")} name="branch">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.bankCodeOS")} name="bankCode">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={5}>
                  <Form.Item label={t("ADM.company.account")} name="account">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label={t("ADM.company.username")} name="username">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Divider className="mb-6 p-0" />
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={
                      <span className="text-[#9FB2FC]">{t("ADM.company.overseasBankName")}</span>
                    }
                    name="bankOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={9}>
                  <Row gutter={[24, 0]}>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.branchOS")} name="branchOS">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.bankCodeOS")} name="bankCodeOS">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={5}>
                  <Form.Item label={t("ADM.company.swiftCode")} name="swiftCode">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label={t("ADM.company.account")} name="accountOS">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={t("ADM.company.username")} name="usernameOS">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item label={t("ADM.company.bankAddrOS")} name="bankAddrOS">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addSuppHd.status === "load" || call_modifySuppHd.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
