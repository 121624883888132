import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { getPWC, getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addQmsApSettingHd, updateQmsApSettingHd } from "@/service/apis/QMS/Apui";

const ModalContainer = ({ s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_ctrlspot = Form.useWatch("ctrlspot", form);

  const call_getProduct = useAPI(getProductSelect);
  const call_addQmsApSettingHd = useAPI(addQmsApSettingHd);
  const call_updateQmsApSettingHd = useAPI(updateQmsApSettingHd);
  const call_getPWC = useAPI(getPWC);

  const [s_PWCOptions, set_s_PWCOptions] = useState([]);
  const [s_PNOptions, set_s_PNOptions] = useState([]);
  const [s_GPOptions, set_s_GPOptions] = useState([
    { label: "查詢分類", value: "querycat" },
    { label: "屬性", value: "attribute" },
    { label: "商品", value: "mgmtcat" },
    { label: "機種", value: "model" },
  ]);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addQmsApSettingHd.request(values);
    }

    if (type === "edit") {
      call_updateQmsApSettingHd.request(values);
    }
  };

  // 第一次渲染取得料號下拉選單
  useEffect(() => {
    call_getProduct.request();
    call_getPWC.request();
  }, []);

  // useAPI 取得料號
  useEffect(() => {
    const { status, data, msg } = call_getProduct;
    if (status === "suc") {
      set_s_PNOptions(
        data.reduce(
          (data, current) => {
            data.push({
              label: `${current.PN}_${current.pdtNM}`,
              value: current.PN,
              data: current,
            });
            return data;
          },
          [{ label: "All_全部", value: "All" }]
        ) || []
      );
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getProduct.status]);

  // useAPI 取得工作中心
  useEffect(() => {
    const { status, data, msg } = call_getPWC;
    if (status === "suc") {
      set_s_PWCOptions(
        data.reduce(
          (data, current) => {
            data.push({
              label: `${current.pwcID}_${current.pwcNM}`,
              value: current.pwcID,
              data: current,
            });
            return data;
          },
          [{ label: "All_全部", value: "All" }]
        ) || []
      );
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getPWC.status]);

  // useAPI 新增表頭
  useEffect(() => {
    const { data, status, msg } = call_addQmsApSettingHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsApSettingHd.status]);

  // useAPI 更新表頭
  useEffect(() => {
    const { data, status, msg } = call_updateQmsApSettingHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsApSettingHd.status]);

  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  return (
    <Spin spinning={call_getProduct.status === "load"}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          isvalid: false,
          ...s_editData,
        }}
      >
        <Row gutter={[24, 12]}>
          <Col span={8}>
            <Form.Item label="維護介面ID" name="uiID">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="名稱" name="uiNM">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="版次" name="ver">
              <InputNumber disabled className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 12]}>
          <Col span={8}>
            <Form.Item label="控點" name="ctrlspot" rules={[{ required: true }]}>
              <Select
                disabled={type === "edit"}
                onChange={() => {
                  form.setFieldsValue({
                    spotID: "",
                  });
                }}
                options={[
                  { label: "料號", value: "PN" },
                  { label: "群組", value: "GP" },
                  { label: "料號+群組", value: "PG" },
                  { label: "工作中心", value: "WC" },
                  { label: "表單", value: "TS" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="控點編號"
              name="spotID"
              rules={[{ required: !(f_ctrlspot === "TS") }]}
            >
              <Select
                disabled={type === "edit" || !f_ctrlspot || f_ctrlspot === "TS"}
                options={
                  f_ctrlspot === "PN" || f_ctrlspot === "PG"
                    ? s_PNOptions
                    : f_ctrlspot === "WC"
                    ? s_PWCOptions
                    : s_GPOptions
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="SOP數量" name="sopcount">
              <InputNumber disabled className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 12]}>
          <Col span={16}>
            {/* 註記 */}
            <Form.Item label={t("util.util.note")} name="note">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            {/* 是否有效 */}
            <Form.Item label={t("util.util.isvalid")} name="isvalid">
              <Radio.Group
                options={[
                  { value: true, label: t("util.util.yes") },
                  { value: false, label: t("util.util.no") },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[24, 12]}>
          <Col span={24} className="flex justify-end gap-2">
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ModalContainer;
