import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { addPack, modifyPack } from "@/service/apis/ADM/pnManage";
import { getOpUnit, getUnit } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_packsn = Form.useWatch("packsn", form);

  const call_getOpUnit = useAPI(getOpUnit);
  const call_getUnit = useAPI(getUnit);
  const call_addPack = useAPI(addPack);
  const call_modifyPack = useAPI(modifyPack);

  const change_packtype = (type) => {
    form.setFieldsValue({
      packsn: type === "0" ? "0" : undefined,
      nextpacksn: type === "0" ? "T" : undefined,
      pack: type === "0" ? c_lightData.opunit : undefined,
      // pack: type === "0" ? null : undefined,
    });
  };

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...values,
      psopno: s_editData.psopno,
      ver: parseInt(values.ver),
      pbom: parseInt(values.pbom),
    };

    if (type === "create") {
      call_addPack.request(postData);
    }

    if (type === "edit") {
      call_modifyPack.request(postData);
    }
  };

  useEffect(() => {
    call_getOpUnit.request();
    call_getUnit.request();
  }, []);

  useEffect(() => {
    if (call_getUnit.status === "err") {
      message.error(t(`error.error-code.${call_getUnit.msg}`));
    }
  }, [call_getUnit.status]);

  useEffect(() => {
    if (call_getOpUnit.status === "err") {
      message.error(t(`error.error-code.${call_getOpUnit.msg}`));
    }
  }, [call_getOpUnit.status]);

  useEffect(() => {
    if (call_addPack.status === "suc") {
      message.success(call_addPack.msg);
      set_s_isShowModal(false);
    } else if (call_addPack.status === "err") {
      message.error(t(`error.error-code.${call_addPack.msg}`));
    }
  }, [call_addPack.status]);

  useEffect(() => {
    if (call_modifyPack.status === "suc") {
      message.success(call_modifyPack.msg);
      set_s_isShowModal(false);
    } else if (call_modifyPack.status === "err") {
      message.error(t(`error.error-code.${call_modifyPack.msg}`));
    }
  }, [call_modifyPack.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        ...c_lightData,
        ...s_editData,
        ver: "0",
        packtype: "1",
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={t("util.util.PN")} name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.pn-manage.ver")} name="ver">
            <Input disabled name="ver" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("ADM.pn-manage.packtype")}
            name="packtype"
            rules={[{ required: true }]}
            shouldUpdate
          >
            <Select
              allowClear
              options={[{ label: t("util.util.packtype_1"), value: "1" }]}
              onChange={change_packtype}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Form.Item label={t("ADM.pn-manage.pack")} name="pack">
                <Select
                  disabled={form.getFieldValue("packtype") === "0"}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? "").includes(input)}
                  options={call_getOpUnit.data.map((x) => {
                    return { label: x.opunitNM1, value: x.opunit };
                  })}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Form.Item
                label={t("ADM.pn-manage.packsn")}
                name="packsn"
                rules={[{ required: true }]}
              >
                <Input disabled={form.getFieldValue("packtype") === "0"} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item shouldUpdate noStyle>
            {() => (
              <Form.Item
                label={t("ADM.pn-manage.nextpacksn")}
                name="nextpacksn"
                rules={[{ required: true }]}
              >
                <Input disabled={form.getFieldValue("packtype") === "0"} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.pn-manage.pbom")} name="pbom">
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.pn-manage.punit")} name="punit">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              options={
                f_packsn > 1
                  ? call_getUnit.data?.map((x) => {
                      return { label: x.unitNM1, value: x.unit };
                    })
                  : call_getOpUnit.data?.map((x) => {
                      return { label: x.opunitNM1, value: x.opunit };
                    })
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t("ADM.pn-manage.idkw")} name="idkw">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
