import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  message,
  Space,
  Radio,
  Collapse,
  ConfigProvider,
} from "antd";
import { addStation, updateStation, getPS } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";

const { Panel } = Collapse;

const initAuth = {
  WOC: true, //"生產控制",
  MC: true, //"物料作業",
  QC: true, //"質控作業",
  TK: true, //"生產看板",
  partsBinding: true, //"部品掃碼",
  ppidp: true, //"成品綁定",
  wpcSearch: true, //"途單查詢",
  dispachManage: true, //"派單管理",
  mold: true, //"模具管理",
  whm: true, //"工時管理",
  batch: true, //"批號管理",
  oem: true, //"委外報工",
};

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);
  const call_addStation = useAPI(addStation);
  const call_updateStation = useAPI(updateStation);
  const call_getPS = useAPI(getPS);
  const { t } = useTranslation();

  //Radio 為false 的時候 呈現紅色
  const Custom_RadioGroup = ({ label, name }) => (
    <Form.Item label={t(`${label}`)} name={name}>
      <Radio.Group>
        <Radio value={true}>{t("util.util.yes")}</Radio>
        <ConfigProvider
          theme={{
            components: {
              Radio: {
                colorPrimary: "red",
              },
            },
          }}
        >
          <Radio value={false}>{t("util.util.no")}</Radio>
        </ConfigProvider>
      </Radio.Group>
    </Form.Item>
  );

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    values.psID = values.psID?.join("|");
    values.pfID = values.pfID?.join("|");
    values.pwcID = values.pwcID?.join("|");
    values.pccID = values.pccID?.join("|");

    // 權限Data
    const authData = {};
    for (const objkey in initAuth) {
      authData[objkey] = values[objkey];
    }
    values.auth = JSON.stringify(authData);

    if (type === "create") {
      call_addStation.request(values);
    }
    if (type === "edit") {
      call_updateStation.request(values);
    }
  };

  useEffect(() => {
    call_getPS.request();
  }, []);

  useEffect(() => {
    if (call_addStation.status === "suc") {
      message.success(call_addStation.msg);
      set_s_isShowModal(false);
    } else if (call_addStation.status === "err") {
      message.error(t(`error.error-code.${call_addStation.msg}`));
    }
  }, [call_addStation.status]);

  useEffect(() => {
    if (call_updateStation.status === "suc") {
      message.success(call_updateStation.msg);
      set_s_isShowModal(false);
    } else if (call_updateStation.status === "err") {
      message.error(t(`error.error-code.${call_updateStation.msg}`));
    }
  }, [call_updateStation.status]);

  useEffect(() => {
    if (call_getPS.status === "err") {
      message.error(t(`error.error-code.${call_getPS.msg}`));
    }
  }, [call_getPS.status]);

  const changePwcID = (pwcIDs = []) => {
    const pccIDs = arrMap.pccArr?.reduce((arr, curr) => {
      for (const pwcID of pwcIDs) {
        pwcID === curr.pwcID && arr.push(curr.pccID);
      }
      return arr;
    }, []);
    form.setFieldsValue({ pccID: pccIDs });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        ...initAuth,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Form.Item name="custID" noStyle />
        <Col span={8}>
          {/* "工站ID" */}
          <Form.Item
            label={t("ADM.production-line-manage.wsID")}
            name="wsID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "工站名稱" */}
          <Form.Item
            label={t("ADM.production-line-manage.wsNM")}
            name="wsNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "車間ID" */}
          <Form.Item
            label={t("ADM.production-line-manage.psID")}
            name="psID"
            rules={[{ required: true }]}
          >
            <Select
              options={
                call_getPS.data?.map((x) => ({
                  label: x.psID + "_" + x.psNM,
                  value: x.psID,
                  data: x,
                })) || []
              }
              mode="multiple"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 製程 */}
          <Form.Item label={t("ADM.util.pf")} name="pfID" rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              onChange={(value) => {
                form.setFieldsValue({ pwcID: [], pccID: [] });
              }}
              options={
                arrMap.pfArr?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "工作中心" */}
          <Form.Item label={t("ADM.util.pwc")} name="pwcID">
            <Select
              mode="multiple"
              // placeholder="請選擇製程"
              disabled={!Array.isArray(f_pfID) || f_pfID?.length === 0}
              allowClear
              onChange={changePwcID}
              options={
                f_pfID &&
                arrMap.pwcArr
                  ?.filter((item) => item.isvalid)
                  ?.reduce((options, curr) => {
                    for (const pfID of f_pfID) {
                      curr.pfID === pfID &&
                        options.push({
                          label: curr.pwcID + "_" + curr.pwcNM,
                          value: curr.pwcID,
                          data: curr,
                        });
                    }
                    return options;
                  }, [])
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "成本中心" */}
          <Form.Item label={t("ADM.production-line-manage.pcc")} name="pccID" shouldUpdate>
            <Select
              mode="multiple"
              // placeholder="請選擇工作中心"
              disabled
              options={arrMap.pccArr?.map((x) => ({
                label: x.pccID + "_" + x.pccNM,
                value: x.pccID,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Collapse defaultActiveKey={["1"]}>
            {/* "WOS 權限設置" */}
            <Panel header={t("ADM.production-line-manage.auth_setting")} key="1">
              <Row>
                <Col span={6}>
                  {/* 生產控制*/}
                  <Custom_RadioGroup label={"ADM.production-line-manage.WOC"} name="WOC" />
                </Col>

                <Col span={6}>
                  {/*物料作業 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.MC"} name="MC" />
                </Col>

                <Col span={6}>
                  {/* 質控作業 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.QC"} name="QC" />
                </Col>

                <Col span={6}>
                  {/* 生產看板 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.TK"} name="TK" />
                </Col>

                <Col span={6}>
                  {/* 途單查詢 */}
                  <Custom_RadioGroup label={"ADM.util.wpcSearch"} name="wpcSearch" />
                </Col>
                <Col span={6}>
                  {/* 派單管理 */}
                  <Custom_RadioGroup label={"ADM.util.dispachManage"} name="dispachManage" />
                </Col>
                <Col span={6}>
                  {/* 成品綁定 */}
                  <Custom_RadioGroup label={"ADM.util.ppidp"} name="ppidp" />
                </Col>
                <Col span={6}>
                  {/* 部品掃碼 */}
                  <Custom_RadioGroup label={"ADM.util.partsBinding"} name="partsBinding" />
                </Col>

                <Col span={6}>
                  {/* 模具管理 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.mold"} name="mold" />
                </Col>

                <Col span={6}>
                  {/* 工時管理 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.whm"} name="whm" />
                </Col>

                <Col span={6}>
                  {/* 批號管理 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.batch"} name="batch" />
                </Col>

                <Col span={6}>
                  {/* 委外報工 */}
                  <Custom_RadioGroup label={"ADM.production-line-manage.oem"} name="oem" />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24}>
          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item label="站控電腦IP" name="IP">
                <Input />
              </Form.Item>
            </Col>
            {/* 註記 */}
            <Col span={8}>
              <Form.Item label={t("util.util.note")} name="note">
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={t("util.util.isvalid")} name="isvalid">
                <Radio.Group
                  options={[
                    { value: true, label: t("util.util.yes") },
                    { value: false, label: t("util.util.no") },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* IP */}
        </Col>
        <Col span={24} className="flex justify-between">
          <Space>
            <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          </Space>
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
