import { useState, useRef, useContext } from "react";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import SystemContext from "@/components/Context/SystemContext";

import Wheel from "./Wheel";
import Car from "./Car";

export default function Performance() {
  const { c_systemConfig } = useContext(SystemContext);

  const Wheel_ref = useRef(null);
  const Car_ref = useRef(null);

  const [s_tabKey, set_s_tabKey] = useState("Wheel"); // 目前是在哪個Tab上

  const tabsData = [
    {
      key: "Wheel",
      label: "輪組報表",
      children: <Wheel ref={Wheel_ref} />,
      auth: c_systemConfig.auth["WHM-performance-wheel"],
    },
    {
      key: "Car",
      label: "車圈報表",
      children: <Car ref={Car_ref} />,
      auth: c_systemConfig.auth["WHM-performance-car"],
    },
  ];

  return (
    <>
      <PageHeader title="員工績效報表" />

      <CustomTabs
        items={tabsData.filter((x) => x.auth)}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
        }}
      />
    </>
  );
}
