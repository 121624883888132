import { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { useTranslation } from "react-i18next";
import { AddButton } from "@/components/PDS/Buttons";
import Pack from "./Pack";
// import Idkw from "./Idkw";
import Cost from "./Cost";
import Stock from "./Stock";
import Price from "./Price";
import Extra from "./Extra";
import Deal from "./Deal";

export default function TB({ c_lightData }) {
  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_TabKey, set_s_TabKey] = useState("Pack");
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // idkw 切換關鍵字
  const [s_bindType, set_s_bindType] = useState("");

  const tabsData = [
    {
      key: "Pack",
      label: t("ADM.pn-manage.tabPack"),
      children: (
        <Pack
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },
    {
      key: "Idkw", //未做
      label: "生產綁定設定",
      hidden: true,
      disabled: true,
    },
    {
      key: "Stock",
      label: t("ADM.pn-manage.tabStock"),
      children: (
        <Stock
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },
    {
      key: "Cost",
      label: t("ADM.pn-manage.tabCost"),
      children: (
        <Cost
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },
    {
      key: "Price",
      label: t("ADM.pn-manage.tabPrice"),
      children: (
        <Price
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },
    {
      key: "Extra", // 需要測試
      label: t("ADM.pn-manage.tabExtra"),
      hidden: true,
      children: (
        <Extra
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },

    {
      key: "Deal",
      label: t("ADM.pn-manage.tabDeal"),
      children: (
        <Deal
          s_isShowModal={s_isShowModal}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
        />
      ),
    },
  ];

  const Action = () => {
    return (
      // <Button type="primary" onClick={handleCreate}>
      //   {t("util.util.add")}
      // </Button>
      <AddButton type="primary" onClick={handleCreate}/>
    );
  };

  const IdkwAction = () => {
    return (
      <>
        <Button
          size="large"
          type="primary"
          onClick={() => haldleFilter("")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          全部
        </Button>
        <Button
          name="ppid"
          size="large"
          type="primary"
          onClick={() => haldleFilter("ppid")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          序碼綁定
        </Button>
        <Button
          name="pcid"
          size="large"
          type="primary"
          onClick={() => haldleFilter("pcid")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          控碼綁定
        </Button>
        <Button
          name="kid"
          size="large"
          type="primary"
          onClick={() => haldleFilter("kid")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          部品綁定
        </Button>
        <Button
          name="sid1"
          size="large"
          type="primary"
          onClick={() => haldleFilter("sid1")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          主副綁定
        </Button>
        <Button
          name="sid2"
          size="large"
          type="primary"
          onClick={() => haldleFilter("sid2")}
          style={{
            borderRadius: "5px",
            marginRight: "15px",
            backgroundColor: "#3764c5",
          }}
        >
          左右綁定
        </Button>
      </>
    );
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    set_s_TabKey(activeKey);
  };

  // 建立
  const handleCreate = () => {
    set_s_isShowModal(true);
  };

  const haldleFilter = (value) => {
    set_s_bindType(value);
  };

  useEffect(() => {
    set_s_bindType("");
  }, [c_lightData]);

  return (
    <>
      <CustomTabs
        items={tabsData}
        onChange={(activeKey) => handleChangeTab(activeKey)}
        tabBarExtraContent={
          <div>
            {s_TabKey === "Idkw" && IdkwAction()}
            {Action()}
          </div>
        }
      />
    </>
  );
}
