/* eslint-disable react-hooks/exhaustive-deps */
import { Form, InputNumber, Modal, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { deleteAqlTb, getAqlTb, updateAqlTb } from "@/service/apis/QMS/aql";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer/index";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <InputNumber
          ref={inputRef}
          onPressEnter={() => save(dataIndex)}
          onBlur={() => save(dataIndex)}
          style={{ width: "100%" }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TB = ({ c_lightData, set_c_lightData }) => {
  const { t } = useTranslation();

  const call_getQmsSipTb = useAPI(getAqlTb);
  const call_deleteAqlTb = useAPI(deleteAqlTb);
  const call_updateAqlTb = useAPI(updateAqlTb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (data, dataIndex, value) => {
    console.log(value);
    console.log(data);
    console.log(dataIndex);
    console.log("要傳給後端的資料 = ", {
      ...data,
      [dataIndex]: value,
    });
    call_updateAqlTb.request({
      ...data,
      [dataIndex]: value,
    });
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteAqlTb.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    // {
    //   title: "樣本數代碼",
    //   dataIndex: "sizecode",
    //   align: "center",

    //   width: 100,
    //   // render: () => <MenuOutlined />,
    // },
    // {
    //   title: "樣本大小", // 序
    //   dataIndex: "size",
    //   align: "right",
    //   width: 100,
    // },
    {
      title: "允收水準%",
      dataIndex: "AQL",
      align: "right",
      width: "15%",
    },
    {
      title: "Ac核決數",
      dataIndex: "acjudge",
      align: "right",
      width: "15%",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "acjudge",
        title: "Ac核決數",
        handleSave,
      }),
    },
    {
      title: "Re核決數",
      dataIndex: "rejudge",
      width: "15%",
      align: "right",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "rejudge",
        title: "Re核決數",
        handleSave,
      }),
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
    },

    // {
    //   title: t("util.util.action"),
    //   dataIndex: "action",
    //   align: "center",
    //   width: "4%",
    //   render: (_, rowData) => (
    //     <Space>
    //       {/* <IconPen
    //         onClick={(e) => {
    //           set_s_editData(rowData);
    //           set_s_isShowModal(true);
    //           e.stopPropagation();
    //         }}
    //       /> */}
    //       <IconGarbage
    //         onClick={(e) => {
    //           removeData(rowData);
    //           e.stopPropagation();
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
  ];
  function onSearch() {
    call_getQmsSipTb.request({ ...c_lightData });
  }

  useEffect(() => {
    onSearch();
  }, []);
  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getQmsSipTb;
    if (status === "suc") {
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getQmsSipTb.status]);

  useEffect(() => {
    const { status, msg } = call_deleteAqlTb;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteAqlTb.status]);

  useEffect(() => {
    const { status, data, msg } = call_updateAqlTb;

    if (status === "suc") {
      message.success(msg);
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateAqlTb.status]);

  useEffect(() => {
    onSearch();
  }, [c_lightData]);

  const searchBasic = [
    // <ExpandButton key="ExpandButton" onClick={() => set_s_isShowDndModal(true)} />,
    // <AddButton onClick={() => set_s_isShowModal(true)} />,
  ];

  return (
    <>
      <PageHeader title={"允收水準明細"} extra={searchBasic} />
      <CustomTable
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={s_tableData}
        pagination={false}
        components={components}
        rowClassName={() => "editable-row"}
        scroll={{ y: 320 }}
      />

      <CustomModal
        title={type === "create" ? "新增允收水準明細" : "編輯允收水準明細"}
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <ModalContainer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default TB;
