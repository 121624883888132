import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";

import { addDeal, updateDeal, getCustHd } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addDeal = useAPI(addDeal);
  const call_updateDeal = useAPI(updateDeal);
  const call_getCustHd = useAPI(getCustHd);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addDeal.request(postData);
    }

    if (type === "edit") {
      call_updateDeal.request(postData);
    }
  };

  useEffect(() => {
    call_getCustHd.request();
  }, []);

  useEffect(() => {
    if (call_addDeal.status === "suc") {
      message.success(call_addDeal.msg);
      set_s_isShowModal(false);
    } else if (call_addDeal.status === "err") {
      message.error(call_addDeal.msg);
    }
  }, [call_addDeal.status]);

  useEffect(() => {
    if (call_updateDeal.status === "suc") {
      message.success(call_updateDeal.msg);
      set_s_isShowModal(false);
    } else if (call_updateDeal.status === "err") {
      message.error(t(`error.error-code.${call_updateDeal.msg}`));
    }
  }, [call_updateDeal.status]);

  useEffect(() => {
    if (call_getCustHd.status === "err") {
      message.error(t(`error.error-code.${call_getCustHd.msg}`));
    }
  }, [call_getCustHd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              ...c_lightData,
              note: "",
            }
          : s_editData
      }
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label={t("util.util.PN")}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dealPN" label={t("ADM.pn-manage.dealPN")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dealNM" label={t("ADM.pn-manage.dealNM")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dealpid" label={t("ADM.pn-manage.dealpid")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dealID" label={t("ADM.pn-manage.dealID")} shouldUpdate>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              options={
                call_getCustHd.data?.map((x) => {
                  return {
                    label: x.custID + "_" + x.custNM,
                    value: x.custID,
                    data: x,
                  };
                }) || []
              }
              onChange={(_, data) => {
                form.setFieldsValue({ dealalias: data.data.custalias });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dealalias" label={t("ADM.pn-manage.dealalias")}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.isfocus")} name="isfocus">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
