import React from "react";
import styled from "styled-components";
import { theme } from "antd";
const { useToken } = theme;

const Wrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  display: flex;
  /* padding: 1px; */

  width: 150px;
  height: 40px;

  /* margin-right: 15px; */
  /* width: 300px; */

  .Header {
    width: 50%;
    background-color: ${({ token }) => token.colorTableHeader};
    //padding: 5px;
    line-height: 40px;
    text-align: center;
    font-size: 19px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: ${({ token }) => token.colorText};
  }

  .Value {
    width: 50%;
    font-size: 19px;
    color: ${({ token }) => token.colorText};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 40px;
    text-align: center;
  }
`;

const DashBox = ({ header, value }) => {
  const { token } = useToken();
  return (
    <Wrapper token={token}>
      <div className="Header">{header}</div>
      <div className="Value">{value}</div>
    </Wrapper>
  );
};

export default DashBox;
