import { Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util component
import {
  IconCheck,
  IconClose,
  IconGarbage,
  IconOrderedListOutlined,
  IconPen,
} from "@/components/Icon/Action";
import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader/index";
// API
import useAPI from "@/hooks/useAPI";
import { deleteQmsApSettingHd, getQmsApSettingHd } from "@/service/apis/QMS/Apui/";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import RecModal from "./ModalContainer/RecModal";
// component
import ModalConintaer from "./ModalContainer/index";

const HD = (props) => {
  const { c_lightData, set_c_lightData } = props;
  const { t } = useTranslation();
  const call_getQmsApSettingHd = useAPI(getQmsApSettingHd);
  const call_deleteQmsApSettingHd = useAPI(deleteQmsApSettingHd);
  const [s_tableData, set_s_tableData] = useState([]);
  // 新增、編輯表頭的 Modal
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  //
  const [s_isShowRecModal, set_s_isShowRecModal] = useState(false);

  const ctrlspotOptions = {
    "PN": "料號",
    "GP": "群組",
    "PG": "料號+群組",
    "WC": "工作中心",
    "TS": "表單",
  };

  // 刪除資料
  const remove = (rowData) => {
    set_c_lightData({});
    Modal.confirm({
      title: "製程標準資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的製程標準資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteQmsApSettingHd.request(rowData),
      cancelText: "取消",
    });
  };

  const tableColumns = [
    {
      title: "介面ID",
      width: "15%",
      dataIndex: "uiID",
    },
    {
      title: "版次",
      dataIndex: "ver",
      width: "7%",
      align: "center",
    },
    {
      title: "介面名稱",
      dataIndex: "uiNM",
      width: "25%",
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      align: "center",
      width: "7%",
      render: (text) => ctrlspotOptions[text],
    },
    {
      title: "控點編號",
      dataIndex: "spotID",
      width: "8%",
      align: "center",
      // render: (text) => {
      //   const map = {
      //     "model": "機種",
      //     "querycat": "查詢分類",
      //     "attribute": "物料屬性",
      //     "mgmtcat": "授權分類",
      //   };
      //   return map[text];
      // },
    },
    {
      title: "SOPID數",
      dataIndex: "sopcount",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.note"), // 註記
      dataIndex: "note",
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconOrderedListOutlined
            className="text-2xl"
            disabled={rowData.isvalid}
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowRecModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            disabled={rowData.isvalid}
            onClick={(e) => {
              remove(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];
  console.log(c_lightData);
  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getQmsApSettingHd.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getQmsApSettingHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
          index: i,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getQmsApSettingHd.status]);

  useEffect(() => {
    if (call_deleteQmsApSettingHd.status === "suc") {
      message.success(call_deleteQmsApSettingHd.msg);
      //刪除後要更新資料
      call_getQmsApSettingHd.request();
    }
    if (call_deleteQmsApSettingHd.status === "err") {
      message.error(call_deleteQmsApSettingHd.msg);
    }
  }, [call_deleteQmsApSettingHd.status]);

  return (
    <>
      <PageHeader
        // title={t("ADM.client.pageHeader")}
        title="介面連結定義維護"
        extra={[
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getQmsApSettingHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}介面連結定義`
            : `${t("util.util.add")}介面連結定義`
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getQmsApSettingHd.request();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      <CustomModal
        title="製程內容"
        width={"90%"}
        open={s_isShowRecModal}
        onCancel={() => set_s_isShowRecModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getQmsApSettingHd.request();
        }}
      >
        <RecModal
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowRecModal={set_s_isShowRecModal}
        />
      </CustomModal>
    </>
  );
};

export default HD;
