import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getBdmAir = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBdmAir`, {
    params: data,
  });
};

export const addBdmAir = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addBdmAir`, data);
};

export const updateBdmAir = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateBdmAir`, data);
};

export const deleteBdmAir = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteBdmAir`, data);
};

export const getBdmTech = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBdmTech`, {
    params: data,
  });
};

export const addBdmTech = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addBdmTech`, data);
};

export const updateBdmTech = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateBdmTech`, data);
};

export const deleteBdmTech = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteBdmTech`, data);
};
export const getBdmWl = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBdmWl`, {
    params: data,
  });
};

export const addBdmWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addBdmWl`, data);
};

export const updateBdmWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateBdmWl`, data);
};

export const deleteBdmWl = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteBdmWl`, data);
};

//-------------------機種特性--------------------

export const getClass = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getClass`, {
    params: data,
  });
};
export const addClass = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addClass`, data);
};

export const updateClass = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateClass`, data);
};

export const deleteClass = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteClass`, data);
};

//群組碼
export const getClassName = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getClassName`, {
    params: data,
  });
};

//特性代碼
export const getClassNM = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getClassNM`, {
    params: data,
  });
};

//-------------------機種特性--------------------
