import React, { useState, useEffect, useContext } from "react";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Space,
  message,
  Descriptions,
  theme,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import useAPI from "@/hooks/useAPI";
import { getInvBatchDetail } from "@/service/apis/WMS/StockSearch";
import { formatAmount } from "@/util/format";
import dayjs from "dayjs";

const { useToken } = theme;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const CustomDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    background-color: ${({ token }) => token.colorTableHeader} !important;
    text-align: center;
    font-weight: bold;
  }
  .ant-descriptions-item-content {
    text-align: center;
  }
  .ant-descriptions-header {
    margin: 6px 0;
  }
`;



// 明細
const ChangeDetail = ({ s_editData }) => {
  // const { token } = useToken();
  const { t } = useTranslation();

  // const [s_total, set_s_total] = useState({
  //   in: 0,
  //   out: 0,
  // });
  const [s_tableData, set_s_tableData] = useState([]);

  // const [s_searchValue, set_s_searchValue] = useState({
  //   startT: dayjs().startOf("month"),
  //   endT: dayjs(),
  // });

  const call_getInvBatchDetail = useAPI(getInvBatchDetail);
 


  const tableColumns = [
    {
      dataIndex: "PN",
      title: t("util.util.PN"),
      
    },
    {
      title: t("util.util.whID"),
      dataIndex: "whID",
      render: (_, record) => `${record.whID} -${record.whNM}`,
    },
    {
      title: t("util.util.batchno"),
      dataIndex: "batchno",
    },
    {
      title: t("util.util.planqty"),
      dataIndex: "planqty",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.dqty"),
      dataIndex: "dqty",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.sqty"),
      dataIndex: "sqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.tqty"),
      dataIndex: "tqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
  ];

  useEffect(() => {
    call_getInvBatchDetail.request({
      ...s_editData
    });
  }, []);

  useEffect(() => {
    if (call_getInvBatchDetail.status === "suc") {
      set_s_tableData(call_getInvBatchDetail?.data.map((x, index) => ({
        ...x,
        key: index
      })))
      message.success(call_getInvBatchDetail.msg);
    } else if (call_getInvBatchDetail.status === "err") {
      message.error(call_getInvBatchDetail.msg);
      set_s_tableData([]);
    }
  }, [call_getInvBatchDetail.status]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Space size={20}>
          {/* <Title level={4} style={{ color: token.colorPrimaryActive }}>
            {t("IMS.stock-search.totalIn")}：{formatAmount(s_total.in)}
          </Title>
          <Title level={4} style={{ color: token.colorErrorActive }}>
            {t("IMS.stock-search.totalOut")}：{formatAmount(s_total.out)}
          </Title> */}
        </Space>
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        {/* <Space>
          <Button type="primary" onClick={() => sortTable("time")}>
            {t("IMS.stock-search.timeSeries")}
          </Button>
          <Button type="primary" onClick={() => sortTable("T")}>
            {t("IMS.stock-search.posted")}
          </Button>
          <Button type="primary" onClick={() => sortTable("!T")}>
            {t("IMS.stock-search.inTransit")}
          </Button>
          <RangePicker
            value={[s_searchValue.startT, s_searchValue.endT]}
            onChange={(val) =>
              set_s_searchValue((prev) => ({
                ...prev,
                startT: val[0],
                endT: val[1],
              }))
            }
          />
        </Space> */}
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns.filter((x) => !x.hidden)}
          dataSource={s_tableData}
          loading={call_getInvBatchDetail.status === "load"}
       
        />
      </Col>
    </Row>
  );
};

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        {/* <CustomTabs
          onChange={(activeKey) => set_s_chooseTab(activeKey)}
          activeKey={s_chooseTab}
          items={tabsData}
        /> */}
        <ChangeDetail s_editData={s_editData}/>
      </Col>
      <Col span={24} className="flex justify-end">
        <Space>
          {/* <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button> */}
        </Space>
      </Col>
    </Row>
  );
}
