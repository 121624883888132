import * as React from "react";
import { FormListFieldData } from "antd";
import { Form, InputNumber, Col } from "antd";

const rectLabel = (field: FormListFieldData) => (
  <>
    <Col span={6}>
      <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item label="長度" name={[field.name, "len"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item label="寬度" name={[field.name, "boxWidth"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const LabelRect = { rectLabel };

export default LabelRect;
