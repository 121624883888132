import React from "react";
import { Row, Col, Form, Select, Button, message } from "antd";
import Cookies from "js-cookie";

const PlantParamsManage = () => {
  const [form] = Form.useForm();

  const plant_options = [
    // { label: "振傑", value: "CK" },
    // { label: "優如", value: "JU" },
    // { label: "中耀", value: "CPT" },
    { label: "TEST_CK", value: "CK" },
    { label: "TEST_JU", value: "JU" },
    { label: "TEST_CPT", value: "CPT" },
    { label: "TEST_ALX", value: "ALX" },
    { label: "TEST_ABC", value: "ABC" },
    { label: "TEST_SAT", value: "SAT" },
  ];

  const onFinish = async (values) => {
    const target = plant_options.find((item) => item.value === values.plantID);

    Cookies.set("plantID", target.value);
    Cookies.set("plantNM", target.label);

    message.success("切換廠別成功");
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical" size="large">
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item name="plantID" rules={[{ required: true, message: "請選擇廠別" }]}>
            <Select placeholder="請選擇廠別" options={plant_options} />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              確定
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PlantParamsManage;
