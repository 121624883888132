import { useState, useEffect, useContext } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

import MyHeader from "./Header";
import MyNavbar from "./Navbar";
import Cookies from "js-cookie";
import { Layout, message } from "antd";
import defaultStyle from "../../styles/default";
import pmsStyle from "../../styles/pms";
import omsStyle from "../../styles/oms";
import bpmStyle from "../../styles/bpm";
import SystemContext from "@/components/Context/SystemContext";
// useAPI Hook
import useAPI from "@/hooks/useAPI";
//API
import { getUserPerm } from "@/service/apis/ADM/publicAPI";

const { Content } = Layout;

// 找出多個 auth 中 為true的key
function filterObjects(arr) {
  const result = {};
  for (const item of arr) {
    for (const prop in item) {
      if (item[prop]) {
        result[prop] = true;
      }
    }
  }
  return result;
}

const MyLayout = () => {
  //navbar ctrl
  //open , close, shrinkClose
  let navigate = useNavigate();
  const location = useLocation();

  const { set_c_userData, c_systemConfig, set_c_systemConfig, set_s_theme } =
    useContext(SystemContext);

  const [navbarState, setNavbarState] = useState("close");
  const call_getUserPerm = useAPI(getUserPerm);

  // 第一次渲染
  useEffect(() => {
    // 取得權限
    call_getUserPerm.request();
    // 第一次渲染將使用者設定存到cookie
    let setting = Cookies.get("setting");

    !setting &&
      Cookies.set(
        "setting",
        JSON.stringify({
          theme: "default",
          size: "middle",
          locale: "zh-TW",
        })
      );

    setting = setting
      ? JSON.parse(setting)
      : {
          theme: "default",
          size: "middle",
          locale: "zh-TW",
        };

    set_c_systemConfig((prev) => {
      return { ...prev, ...setting };
    });
  }, []);

  useEffect(() => {
    Cookies.set(
      "setting",
      JSON.stringify({
        theme: c_systemConfig.theme,
        size: c_systemConfig.size,
        locale: c_systemConfig.locale,
      })
    );

    set_s_theme((prev) => {
      const judgeStyle =
        c_systemConfig.theme === "pms"
          ? pmsStyle
          : c_systemConfig.theme === "oms"
          ? omsStyle
          : c_systemConfig.theme === "bpm"
          ? bpmStyle
          : defaultStyle;
      let copyStyle = JSON.parse(JSON.stringify(judgeStyle));

      copyStyle.token.fontSize =
        c_systemConfig.size === "large"
          ? 18
          : c_systemConfig.size === "middle"
          ? 15
          : c_systemConfig.size === "small"
          ? 13
          : 20;
      // console.log("copyStyle = ", copyStyle);
      return { ...copyStyle };
    });
  }, [c_systemConfig]);

  useEffect(() => {
    const themeMap = {
      pms: "pms",
      oms: "oms",
      bpm: "bpm",
    };
    const currentPath = location.pathname.split("/")[1];
    const theme = themeMap[currentPath] || "default";
    set_c_systemConfig((prev) => ({ ...prev, theme: theme }));
  }, [location]);

  useEffect(() => {
    if (call_getUserPerm.status === "suc") {
      const userData = {
        peopleNM: call_getUserPerm.data.peopleNM,
        staffID: call_getUserPerm.data.staffID,
        postID: call_getUserPerm.data?.postID,
      };
      set_c_userData(userData);
      set_c_systemConfig((prev) => {
        // 將多個角色合併
        prev.auth = filterObjects(call_getUserPerm.data.auth.map((x) => JSON.parse(x)));
        console.log(prev.auth);
        return { ...prev };
      });
    } else if (call_getUserPerm.status === "err") {
      message.error(call_getUserPerm.msg);
      // alert("123");
      console.log(call_getUserPerm.data);
      navigate("/login");
    }
  }, [call_getUserPerm.status]);

  return (
    <Layout className="h-screen w-screen">
      <MyNavbar
        navbarState={navbarState}
        setNavbarState={setNavbarState}
        // auth={storeData.auth}
        // className="navbar"
      />
      <div
        navbarstate={navbarState}
        className={`${
          navbarState === "shrinkClose" ? "w-[calc(100%-80px)]" : "w-full"
        } flex flex-col`}
      >
        <MyHeader navbarState={navbarState} setNavbarState={setNavbarState} />
        <Content className="p-4 overflow-x-hidden">
          <Outlet />
        </Content>
      </div>
    </Layout>
  );
};

export default MyLayout;
