/**
 * React 組件，用於渲染圖像預覽容器。
 */
import React from "react";
import styled from "styled-components";

/**
 * 自定義圖像查看器的樣式容器。
 * @param {Object} props - 組件props
 * @param {string} [props.maxHeight="500px"] - 最大高度，默認為500px。
 */
const CustomImageViewers = styled.div`
  max-width: fit-content;
  width: 100%;

  display: flex;
  justify-content: center;
  max-height: ${(props) => props.maxHeight || "500px"};
`;

/**
 * 圖像預覽樣式。

 * @param {string} [props.objectfit="object-scale-down"] - 自定義object-fit，默認為scale-down。
 */
const PreviewImage = styled.img`
  object-fit: ${(props) => props.objectfit || "scale-down "};
  max-width: 100%;
  display: block;
  
`;

/**
 * 渲染圖像預覽組件。若有其他需求，請直接在頂層 Preview 中匯入自訂義組件 CustomComponent 請勿修改此區 !!

 * @param {string} props.filePath - 圖像文件路徑。
 * @param {string} [props.maxHeight] - 圖像預覽容器的最大高度。
 * @param {string} [props.objectfit] - 自定義object-fit。
 * @returns {JSX.Element} 渲染的 React 組件。
 */
const Image = ({ filePath, maxHeight, objectfit }) => {
  console.log(objectfit)
  return (
    <CustomImageViewers maxHeight={maxHeight}>
      <PreviewImage objectfit={objectfit} src={filePath} alt="預覽圖" />
    </CustomImageViewers>
  );
};

export default React.memo(Image);
