import { Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { uploadInspTb } from "@/service/apis/QMS/meds";
import { InboxOutlined } from "@ant-design/icons";

// API

const { Dragger } = Upload;
const FileModalContainer = ({ c_lightData, s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  // 上傳料號圖片
  const call_uploadInspTb = useAPI(uploadInspTb);

  const [s_tableData, set_s_tableData] = useState([]);

  // 上傳檔案時執行
  const handleChange = (props) => {
    const { file, doctype } = props;
    console.log(file, doctype);
    // console.log(" props = ", props);
    // 刪除
    if (file.status === "removed") {
      // call_deleteUploadPN.request({
      //   PN: s_editData.PN,
      //   doctype: doctype,
      // });
      return;
    }
    // 新建
    let formData = new FormData();
    formData.append("file", file);
    formData.append("dcUUID", c_lightData.dcUUID);
    formData.append("itemno", s_editData.itemno);

    formData.forEach(function (value, key) {
      console.log(key, value);
    });

    call_uploadInspTb.request(formData);
  };

  const types = ["drawno"];

  useEffect(() => {
    const _data = types.map((item) => {
      const fileList = s_editData[item]?.length > 0 ? [{ url: s_editData[item] }] : [];
      return {
        doctype: item,
        fileList: fileList,
      };
    });
    set_s_tableData(_data);
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_uploadInspTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_uploadInspTb.status]);

  console.log(s_editData);
  return (
    <div className="flex justify-around gap-2 ">
      {s_tableData.map((item) => {
        return (
          <section key={item.doctype} className="flex flex-col gap-2 w-[100%]">
            <Dragger
              key={item.doctype}
              className=""
              maxCount={1}
              beforeUpload={() => false}
              onChange={(props) => handleChange({ ...props, doctype: item.doctype })}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data
                or other banned files.
              </p>
            </Dragger>
          </section>
        );
      })}
    </div>
  );
};

export default FileModalContainer;
