import { Form, Input, InputNumber, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getQmsSopTb, updateQmsSopTb } from "@/service/apis/QMS/QMSManage";
import { BarcodeOutlined } from "@ant-design/icons";

import RecModal from "./RecModal";

const Wrapper = styled.div`
  // 編輯Table用
  .editable-cell {
    position: relative;
  }
  // 編輯Table用
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  // 編輯Table用
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 32px;
    position: absolute;
    bottom: 8px;
    width: 90%;
  }

  .editable-row:hover .antd-table-cell {
    position: relative;
  }

  // 編輯Table用
  [data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "note" ? (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
          />
        ) : (
          <InputNumber
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={0}
            style={{ width: "100%" }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TB = ({ c_lightData, set_c_lightData }) => {
  const innerWidth = window.innerWidth;
  const call_getQmsSopTb = useAPI(getQmsSopTb);
  const call_updateQmsSopTb = useAPI(updateQmsSopTb);
  const [s_tableData, set_s_tableData] = useState([]);

  const [s_rec, set_s_rec] = useState({});
  const [s_showRecModal, set_s_showRecModal] = useState(false); // 是否開啟 dialog

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 200,
  });
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState("");

  // 取得所有投料項目明細
  // const call_getQmsSopTb = async () => {
  //   try {
  //     //console.log(c_lightData)
  //     const res = await getQmsSopTb({ sopID: c_lightData.sopID, version: c_lightData.version, spotID: c_lightData.spotID });
  //     // console.log('res = ', res)
  //     if (res.data) {
  //       console.log('全部投料項目明細 = ', res.data);
  //       const tmpData = res.data.map((item, index) => {
  //         return {
  //           ...item,
  //           key: index
  //         }
  //       });
  //       set_s_tableData(tmpData)
  //     } else {
  //       set_s_tableData([])
  //     }

  //   } catch (e) {
  //     console.log('---------- api getQmsSopTb error ----------');
  //     console.log(e)
  //   }
  // }

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  };

  const recClick = (rowData) => {
    set_s_rec(rowData);
    set_s_showRecModal(true);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  // 更新 c_lightData 重新抓資料
  useEffect(() => {
    console.log(c_lightData);
    if (Object.keys(c_lightData).length > 0) {
      call_getQmsSopTb.request({ dcUUID: c_lightData.dcUUID });
    } else {
      set_s_tableData([]);
    }
  }, [c_lightData]);

  // 取得表身
  useEffect(() => {
    if (call_getQmsSopTb.status === "suc") {
      const tmpData = call_getQmsSopTb.data.tableData.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
      message.success(call_getQmsSopTb.msg);
      set_s_tableData(tmpData);
    }
    if (call_getQmsSopTb.status === "err") {
      message.error(call_getQmsSopTb.msg);
      set_s_tableData([]);
    }
  }, [call_getQmsSopTb.status]);

  // 編輯表身
  useEffect(() => {
    if (call_updateQmsSopTb.status === "suc") {
      // 編輯成功要重新取得資料
      call_getQmsSopTb.request({ dcUUID: c_lightData.dcUUID });
      message.success(call_updateQmsSopTb.msg);
    }
    if (call_updateQmsSopTb.status === "err") {
      message.error(call_updateQmsSopTb.msg);
    }
  }, [call_updateQmsSopTb.status]);

  // 表身有資料、RWD 時計算高度
  useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if (innerWidth >= 1440) {
      setScrollY(ROWHEIGHT * 6);
    }

    // 一般螢幕尺寸
    if (innerWidth >= 1920) {
      setScrollY(ROWHEIGHT * 6);
    }
  }, [innerWidth, c_lightData]);

  const tableColumns = [
    // {
    //   title: "SOP文編",
    //   dataIndex: "sopID",
    //   key: "sopID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    // },
    // {
    //   title: "版次",
    //   dataIndex: "version",
    //   key: "version",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: c_lightData.soptype === "WPC" ? "途程序" : t("util.util.itemno"), //"工位控程",
      dataIndex: "itemno",
      key: "itemno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: t("ADM.edm.pfNM"),
      dataIndex: "pfID",
      align: "left",
      hidden: c_lightData.soptype !== "WPC",
      render: (_, rowData) => `${rowData.pfID}_${rowData.pfNM}`,
    },
    {
      title: t("util.util.ws"),
      dataIndex: "wsID",
      align: "left",
      hidden: c_lightData.soptype !== "WPC",
      render: (_, rowData) => `${rowData.wsID}_${rowData.wsNM}`,
    },
    {
      title: t("ADM.util.pwc"),
      dataIndex: "pwcID",
      lign: "left",
      hidden: c_lightData.soptype !== "WPC",
      render: (_, rowData) => `${rowData.pwcID}_${rowData.pwcNM}`,
    },
    {
      title: "作業序ASSY",
      dataIndex: "assyno",
      key: "assyno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      hidden: c_lightData.soptype !== "OPC",
      onCell: (record) => {
        return {
          style: { backgroundColor: "rgb(231, 247, 231)" },
        };
      },
    },
    {
      title: "檢驗序INSP",
      dataIndex: "inspno",
      key: "inspno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      hidden: c_lightData.soptype !== "OPC",
      onCell: (record) => {
        return {
          style: { backgroundColor: "rgb(231, 247, 231)" },
        };
      },
    },
    {
      title: "包裝序PACK",
      dataIndex: "packno",
      key: "packno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      hidden: c_lightData.soptype !== "OPC",
      onCell: (record) => {
        return {
          // className=" bg-[#FFF8D5]"

          style: { backgroundColor: "rgb(231, 247, 231)" },
        };
      },
    },
    {
      title: "[人]掃碼數",
      dataIndex: "laborno",
      className: "bg-[#fff8d5]",
      key: "laborno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "人員紀錄",
    //   dataIndex: "laborUUID",
    //   key: "laborUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "[機]掃碼數",
      dataIndex: "equipno",
      key: "equipno",
      align: "center",
      className: "bg-[#fff8d5]",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "設備紀錄",
    //   dataIndex: "equipUUID",
    //   key: "equipUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "[料]掃碼數",
      dataIndex: "feedno",
      className: "bg-[#fff8d5]",
      key: "feedno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "[法]掃碼數",
      dataIndex: "methodno",
      className: "bg-[#fff8d5]",
      key: "methodno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "說明文字數",
      dataIndex: "requestno",
      key: "requestno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "輸入欄位數",
      dataIndex: "labelno",
      key: "labelno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "項目選擇數",
      dataIndex: "selectno",
      key: "selectno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "數值紀錄數",
      dataIndex: "valueno",
      key: "valueno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "投料紀錄",
    //   dataIndex: "feedUUID",
    //   key: "feedUUID",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    {
      title: "條件設定",
      dataIndex: "render",
      key: "render",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
      align: "center",
      render: (text, rowData, index) => (
        <div style={{ fontSize: "24px", cursor: "pointer" }}>
          <BarcodeOutlined
            onClick={(event) => {
              event.stopPropagation();
              recClick(rowData);
            }}
          />
        </div>
      ),
    },
  ];

  const handleSave = (data, dataIndex, value) => {
    console.log("要傳給後端的資料 = ", {
      ...data,
      [dataIndex]: value,
    });
    call_updateQmsSopTb.request({
      ...data,
      [dataIndex]: value,
    });
  };

  const columns = tableColumns
    .filter((col) => !col.hidden)
    .map((col) => {
      if (!col.editable) return col;

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          // style: {
          //   backgroundColor: record?.lifeF === 'T' ? '#e1dbdb' : '',
          //   pointerEvents: record?.lifeF === 'T' ? 'none' : '',
          //   opacity: record?.lifeF === 'T' ? '.5' : '1',
          //   color: record?.lifeF === 'T' ? '#b6b1b1' : record?.confirmqty === 0 ? '#b6b1b1' : '#FF850A'
          // }
        }),
      };
    });

  return (
    <Wrapper>
      <PageHeader
        title={
          <span>
            作業標準明細_
            <span className="text-blue-500 font-semibold">{c_lightData.sopID}</span>
          </span>
        }
      />
      <CustomTable
        components={components}
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content", y: scrollY }}
        columns={columns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item,
          };
        })}
        rowClassName="editable-row"
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["200", "500", "1000"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <span className="text-lg font-semibold">
              {t("util.util.showTotal", {
                total: total,
                rangeFirst: range[0],
                rangeEnd: range[1],
              })}
            </span>
          ),
        }}
      />

      {s_showRecModal ? (
        <CustomModal
          width="90%"
          // title='SOP投料全局項目編輯'
          // title="工位掃碼設定"
          title={
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <span>條件設定</span>
              {/* <div>
                <span style={{ marginRight: "30px", color: "rgb(167, 164, 164)" }}>
                  工位控程：{s_rec.itemno}
                </span>
                <span style={{ marginRight: "30px", color: "rgb(167, 164, 164)" }}>
                  組裝序ASSY：{s_rec.assyno}
                </span>
                <span style={{ marginRight: "30px", color: "rgb(167, 164, 164)" }}>
                  檢測序INSP：{s_rec.inspno}
                </span>
                <span style={{ marginRight: "30px", color: "rgb(167, 164, 164)" }}>
                  包裝序PACK：{s_rec.packno}
                </span>
              </div> */}
            </div>
          }
          visible={s_showRecModal}
          onCancel={() => set_s_showRecModal(false)}
        >
          <RecModal
            s_rec={s_rec}
            set_s_showRecModal={set_s_showRecModal}
            c_lightData={c_lightData}
          />
        </CustomModal>
      ) : null}
    </Wrapper>
  );
};

export default TB;
