import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getZ1RimWl, deleteZ1RimWl } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import ModalConintaer from "./ModalContainer";

const Car_wl = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const { t } = useTranslation();

  const [is_showModal, set_is_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const call_getZ1RimWl = useAPI(getZ1RimWl);
  const call_deleteZ1RimWl = useAPI(deleteZ1RimWl);

  const tableColumns = [
    {
      title: "機碼",
      dataIndex: "wlID",
      render: (_, record) => record.wlID + "_" + record.wlNM,
    },
    {
      title: "C/T值(S/PCS)",
      dataIndex: "wh",
      align: "right",
    },
    {
      title: "除數",
      dataIndex: "divider",
      align: "right",
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              e.stopPropagation();
              set_is_showModal(true);
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteZ1RimWl.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const onSearch = () => {
    call_getZ1RimWl.request(s_searchData);
  };
  const onCreate = () => {
    set_is_showModal(true);
    set_s_editData({});
  };

  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: onCreate,
  }));

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    if (call_getZ1RimWl.status === "suc") {
      message.success(call_getZ1RimWl.msg);
      set_s_tableData(
        call_getZ1RimWl.data.map((x, i) => {
          return { ...x, key: i };
        })
      );
    } else if (call_getZ1RimWl.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getZ1RimWl.msg}`));
    }
  }, [call_getZ1RimWl.status]);

  useEffect(() => {
    if (call_deleteZ1RimWl.status === "suc") {
      message.success(call_deleteZ1RimWl.msg);
      onSearch();
    } else if (call_deleteZ1RimWl.status === "err") {
      message.error(t(`error.error-code.${call_deleteZ1RimWl.msg}`));
      onSearch();
    }
  }, [call_deleteZ1RimWl.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        c_lightData={s_editData}
        loading={call_getZ1RimWl.status === "load"}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        width={"60%"}
        open={is_showModal}
        onCancel={() => set_is_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_is_showModal={set_is_showModal} />
      </CustomModal>
    </>
  );
});

export default Car_wl;
