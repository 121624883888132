/**

將數字格式化為指定位數的小數點表示形式。
@param {Number} number - 要格式化的數字。
@param {Number} digits - 要保留的小數位數（默認為2）。
@returns {String} - 格式化後的帶有指定小數位數的數字字符串。
*/
export default function showDecimalPoint(number, digits = 2) {
  if (isNaN(number)) return console.error("the number type is NAN");
  return number.toFixed(digits);
}
