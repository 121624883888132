import { Button, Col, Divider, Form, Input, InputNumber, Radio, Row, Select, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// util component
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
// api
import useAPI from "@/hooks/useAPI";
import { getSampleSet } from "@/service/apis/QMS/SampleSet";
import { addProc, updateProc } from "@/service/apis/QMS/sampling";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
}) {
  console.log(s_editData);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const f_sampletype = Form.useWatch("sampletype", form);
  const f_setvalue1 = Form.useWatch("setvalue1", form);

  // 新增表頭資料
  const call_addProc = useAPI(addProc);
  // 編輯表頭資料
  const call_updateProc = useAPI(updateProc);

  const call_getSampleSet = useAPI(getSampleSet);

  function checkPlane0X(planID) {
    // 建立正規表示式來符合 'plane0X' 的模式，但不符合 'plane9X'
    var pattern = /^plan0\d$/;

    // 使用正規表示式的測試方法檢查變數是否符合模式
    if (pattern.test(planID)) {
      // 匹配模式
      return true;
    } else {
      // 不匹配模式
      return false;
    }
  }

  const procOptions = call_getSampleSet.data
    .filter((item) => {
      // { value: "0", label: "國際標準" },
      // { value: "9", label: "自定義標準" },
      const filter = {
        "0": true,
        "9": false,
      };
      const result = filter[f_sampletype] === checkPlane0X(item?.planID);

      return result;
    })
    .map((item) => ({
      label: item?.sampleNM,
      value: item?.sampleID,
      data: item,
    }));

  const onSubmit = (values) => {
    console.log(values.usertype);

    const usertype = Array.isArray(values.usertype) ? values.usertype.join("|") : values.usertype;
    console.log(usertype);
    if (type === "create") {
      console.log({ ...values });
      call_addProc.request({ ...values, setvalue1: String(values.setvalue1), usertype });
    }
    if (type === "edit") {
      call_updateProc.request({ ...values, setvalue1: String(values.setvalue1), usertype });
    }
  };

  const handleEnterKey = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      // 检查是否按下 Enter 键
      e.preventDefault();
    }
  };
  useEffect(() => {
    // if (type === "edit") {

    call_getSampleSet.request();

    // }
  }, []);

  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg, data } = call_getSampleSet;
    console.log(f_setvalue1);
    if (status === "suc") {
      if (["0", "9"].includes(f_sampletype)) {
        const result = call_getSampleSet?.data.find((item) => {
          if (!f_setvalue1) {
            return item.samplelevel === "G2";
          }
          if (
            // 萬一未來這裡正常檢驗不再是 03 跟 06 會壞掉喔 OVO b 在 yuju_momV 1.19.6版本這裡是沒問題ㄉ
            type === "edit" &&
            (s_editData?.setvalue1 !== "03" || s_editData?.setvalue1 !== "06")
          ) {
            return item?.sampleID === s_editData?.setvalue1;
          }
          return item?.samplelevel === "G2";
        });
        console.log(result);
        console.log(s_editData?.setvalue1);

        form.setFieldsValue({ setvalue1: result?.sampleID });
      }
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getSampleSet.status]);
  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addProc;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addProc.status]);

  useEffect(() => {}, [f_sampletype]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateProc;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateProc.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        usertype: "品保",
        ...s_editData,
      }}
      onKeyDown={handleEnterKey}
      onFinish={onSubmit}
    >
      <div>
        <Form.Item label="建立時間" name="createT" hidden>
          <Input disabled></Input>
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="程序ID" name="procID">
            <Input disabled></Input>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="程序名稱" name="procNM">
            <Input></Input>
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 是否有效 */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}></Col>

        <Col span={6}>
          <Form.Item label="抽樣類型" name="sampletype">
            <Select
              className="w-full"
              allowClear
              options={[
                { value: "A", label: "免驗" },
                { value: "E", label: "全檢" },
                { value: "B", label: "固定樣品數" },
                { value: "C", label: "固定百分比" },
                { value: "0", label: "國際抽樣計劃" },
                { value: "9", label: "自定義抽樣計劃" },

                // { value: "D", label: "抽樣計劃規劃" },
              ]}
              onChange={(value) => {
                console.log(value);
                const fieldsToSet = { dotmethod1: null };
                fieldsToSet.setvalue1 = null;
                if (value === "A") {
                  fieldsToSet.setvalue1 = null;
                } else if (value === "E") {
                  fieldsToSet.setvalue1 = 100;
                } else if (value === "0" || value === "9") {
                  call_getSampleSet.request({ plantype: value });
                  form.setFieldsValue({ setvalue1: "" });
                }

                form.setFieldsValue(fieldsToSet);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="抽樣條件" name="setvalue1">
            {["0", "9"].includes(f_sampletype) ? (
              <Select
                options={procOptions}
                onChange={(value, allData) => {
                  console.log(allData);
                }}
              />
            ) : (
              <InputNumber
                addonAfter={["C", "E"].includes(f_sampletype) ? "%" : null}
                disabled={["A", "E"].includes(f_sampletype)}
                className="w-full"
                allowClear
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="小數點處理原則" name="dotmethod1">
            <Select
              disabled={f_sampletype !== "C"}
              options={[
                { value: "round", label: "四捨五入" },
                { value: "ceil", label: "進位法" },
                { value: "floor", label: "捨去法" },
              ]}
              className="w-full"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={"功能分類"} // 機碼
            name="usertype"
          >
            <Select
              mode="multiple"
              options={[
                { value: "開發", label: "開發" },
                { value: "品保", label: "品保" },
                { value: "生產", label: "生產" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={4}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={20} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            loading={call_addProc.status === "load" || call_updateProc.status === "load"}
            type="primary"
            htmlType="submit"
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
