import { useState, useEffect } from "react";
import { Button, Space, message, DatePicker, Input } from "antd";

import { IconPen, IconCheck, IconClose, IconGarbage } from "@/components/Icon/Action";
import styled from "styled-components";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getZPPS007ROUTING } from "@/service/apis/SAP/wpc.js";
import { useTranslation } from "react-i18next";
// import { sapTypeOptions } from "../../util/anyMap";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";
import { numberWithCommas } from "../../../../util/format";
const { RangePicker } = DatePicker;
const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;

  const call_getZPPS007ROUTING = useAPI(getZPPS007ROUTING);

  const [s_tableData, set_s_tableData] = useState([]);
  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const [s_searchData, set_s_searchData] = useState({
    startDate: today,
    endDate: tomorrow,
    AUFNR: "",
  });

  const tableColumns = [
    {
      title: "用戶端",
      dataIndex: "CLIENT",
      align: "center",
      fixed: true,
      width: 200,
    },
    {
      title: "工廠",
      dataIndex: "WERKS",
      align: "center",
      fixed: true,
      width: 200,

      // align: "center",
    },
    {
      title: "工單號",
      dataIndex: "AUFNR",
      fixed: true,
      width: 200,
      align: "center",

      // width: "300px",
    },
    { title: "工序", dataIndex: "VORNR", width: "300px", align: "center", fixed: true },
    {
      title: "工作中心",
      dataIndex: "ARBPL",
      fixed: true,
      width: 200,
      align: "center",

      // render: (text) => numberWithCommas(text),
    },
    {
      title: "製程名稱",
      dataIndex: "LTXA1",
      fixed: true,
      width: 200,
      // width: "30%",
    },
    {
      title: "不列印",
      dataIndex: "ZZNprint",
      // width: "30%",
    },
    {
      title: "顏色",
      dataIndex: "ZZCOLOR",
      // align: "center",
      // width: "90px",
      // render: (text) => (text === "X" ? "已交貨" : "未交貨"),
    },
    {
      title: "時間",
      dataIndex: "ZZIFTIMESTAMP",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      // width: "30%",
      align: "center",

    },
  ];

  const fakeData = [
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶A",
      WERKS: "工廠A",
      AUFNR: "A001",
      VORNR: "工序1",
      ARBPL: "工作中心A",
      LTXA1: "製程名稱A",
      ZZNprint: "Y",
      ZZCOLOR: "紅色",
      ZZIFTIMESTAMP: "2023-11-01",
    },
    {
      CLIENT: "客戶B",
      WERKS: "工廠B",
      AUFNR: "B002",
      VORNR: "工序2",
      ARBPL: "工作中心B",
      LTXA1: "製程名稱B",
      ZZNprint: "N",
      ZZCOLOR: "藍色",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "客戶C",
      WERKS: "工廠C",
      AUFNR: "C003",
      VORNR: "工序3",
      ARBPL: "工作中心C",
      LTXA1: "製程名稱C",
      ZZNprint: "Y",
      ZZCOLOR: "綠色",
      ZZIFTIMESTAMP: "2023-11-10",
    },
    // 添加更多假資料...
  ];
  const search = (e) => {
    set_c_lightData({});
    const startDate = dayjs(s_searchData).format("YYYY-MM-DD");

    call_getZPPS007ROUTING.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  };

  const handleKeyDownEnter = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      call_getZPPS007ROUTING.request({
        ...s_searchData,
        startDate: s_searchData.startDate.format("YYYY-MM-DD"),
        endDate: s_searchData.endDate.format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    const startDate = dayjs(s_searchData).format("YYYY-MM-DD");
    call_getZPPS007ROUTING.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  }, []);

  useEffect(() => {
    // if (
    //   (s_searchData.PN === undefined || s_searchData.PN === "") &&
    //   (s_searchData.modelID === undefined || s_searchData.modelID === "")
    // ) {
    //   call_getModelHd.request(s_searchData);
    // }
  }, [s_searchData]);

  useEffect(() => {
    if (call_getZPPS007ROUTING.status === "suc") {
      set_s_tableData(call_getZPPS007ROUTING.data.map((x, i) => ({ ...x, key: i, index: i })));
    } else if (call_getZPPS007ROUTING.status === "err") {
      message.error(call_getZPPS007ROUTING.msg);
      set_s_tableData([]);
    }
  }, [call_getZPPS007ROUTING.status]);

  const searchTemp = [
    <Input
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.woN")}`}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, AUFNR: e.target.value };
        });
      }}
      onKeyDown={handleKeyDownEnter}
    />,
    <RangePicker
      allowClear={false}
      format="YYYY-MM-DD"
      value={[s_searchData.startDate, s_searchData.endDate]}
      className="w-full"
      onChange={(_, dateString) => {
        // console.log(date);
        console.log(dateString);

        set_s_searchData((prve) => {
          return { ...prve, startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) };
        });
      }}
    />,
    <Button type="primary" onClick={search} key="search">
      查詢
    </Button>,
  ];
  console.log(s_searchData);
  return (
    <>
      <PageHeader title="SAP工單途程" extra={searchTemp} />

      <CustomTable
        scroll={{ x: 2000, y: 720 }}
        rowClassName={`bg-slate-50`}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        // dataSource={fakeData}
        loading={call_getZPPS007ROUTING.status === "load"}
        // tbMode={Object.keys(c_lightData).length > 0}
        // c_lightData={c_lightData}
        // onRow={(record) => ({
        //   onClick: () =>
        //     record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        // })}
      />

      {/* <CustomModal
        width="90%"
        title="新建標籤樣板"
        open={s_showModal}
        centered
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getModelHd.request(s_searchData);
        }}
      >
      
      </CustomModal> */}
    </>
  );
};
export default HD;
