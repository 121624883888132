import { Button, Col, Divider, Form, Input, Row, Select, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { addPlanHd, updatePlanHd } from "@/service/apis/QMS/plan";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  // const f_sampletype = Form.useWatch("sampletype", form);

  // 新增表頭資料
  const call_addPlanHd = useAPI(addPlanHd);
  // 編輯表頭資料
  const call_updatePlanHd = useAPI(updatePlanHd);

  const onSubmit = (values) => {
    if (type === "create") {
      console.log(values);
      call_addPlanHd.request({ ...values });
    }
    if (type === "edit") {
      console.log(values);

      call_updatePlanHd.request({ ...values });
    }
  };

  const handleEnterKey = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      // 检查是否按下 Enter 键
      e.preventDefault();
    }
  };
  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addPlanHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addPlanHd.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updatePlanHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updatePlanHd.status]);

  useEffect(() => {}, []);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...s_editData,
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
      onKeyDown={handleEnterKey}
      onFinish={onSubmit}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label={"計畫ID"} name="planID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"計畫說明"} name="planNM">
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"計畫型態"} name="plantype">
            <Select
              options={[
                { value: "0", label: "國際標準" },
                { value: "9", label: "自定義標準" },
              ]}
              className="w-full"
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row> */}

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={18}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={6} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            loading={call_addPlanHd.status === "load" || call_updatePlanHd.status === "load"}
            type="primary"
            htmlType="submit"
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
