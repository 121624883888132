import { Button, Col, DatePicker, Divider, Form, Row, Spin, message } from "antd";
import React, { useEffect } from "react";

import useAPI from "@/hooks/useAPI";
import apiBasic from "@/service/APIBasic";
import { getBalExcel } from "@/service/apis/PIS/dalReport";

const ExcelModal = ({ set_s_isShowExcelModal }) => {
  const [form] = Form.useForm();
  const call_getBalExcel = useAPI(getBalExcel);
  const onSubmit = async (values) => {
    console.log(values);
    call_getBalExcel.request({ monthStart: values.month[0], monthEnd: values.month[1] });
  };

  useEffect(() => {
    const { status, msg, data } = call_getBalExcel;

    if (status === "suc") {
      console.log("data = ", data);
      const fileName = data.split("/").reverse()[0];

      const location = data.substring(data.indexOf(".") + 1);
      const link = document.createElement("a");
      const href = `${apiBasic.conn}://${apiBasic.url}${location}`;
      // console.log("data = ", data);
      // console.log("href = ", href);
      // console.log("fileName", fileName);
      // return;
      link.href = href;

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      message.success(msg);
      set_s_isShowExcelModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_isShowExcelModal(false);
    }
  }, [call_getBalExcel.status]);

  return (
    <Spin spinning={call_getBalExcel.status === "load"}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label="日期"
              name="month"
              rules={[{ required: true, message: "請選擇日期" }]}
            >
              <DatePicker.RangePicker className="w-full" picker="month" />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="my-2" />

        <Row gutter={[12, 12]}>
          <Col span={24} className="flex justify-end">
            <Button type="primary" htmlType="submit">
              下載Excel
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ExcelModal;
