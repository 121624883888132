/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import useAPI from "@/hooks/useAPI";
import { message, Modal, Space, Typography } from "antd";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { multipleIDtoName } from "@/util/format";
import { IconPen, IconGarbage } from "@/components/Icon/Action";

import { getDevice, deleteDevice } from "@/service/apis//ADM/measurementEquipment";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import { useTranslation } from "react-i18next";
import ModalContainer from "./ModalContainer";
import FileModal from "./ModalContainer/FileModal";

const HD = forwardRef((props, ref) => {
  const { s_tabKey, c_lightData, set_c_lightData, arrMap } = props;

  const [s_showModal, set_s_showModal] = useState(false);
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const [s_tableData, set_s_tableData] = useState([]);

  const { t } = useTranslation();

  const call_getDevice = useAPI(getDevice);
  const call_deleteDevice = useAPI(deleteDevice);

  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => {
        call_deleteDevice.request(rowData);
        set_c_lightData({});
        set_s_editData({});
      },
      cancelText: t("util.util.cancel"),
    });
  };

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_s_showModal(true);
      set_c_lightData({});
    },
  }));

  useEffect(() => {
    call_getDevice.request({ assetkind: s_tabKey });
  }, [s_tabKey]);

  useEffect(() => {
    const { status, msg } = call_deleteDevice;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      call_getDevice.request({ assetkind: s_tabKey });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_deleteDevice.status]);

  useEffect(() => {
    const { status, data, msg } = call_getDevice;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getDevice.status]);

  const tableColumns = [
    {
      title: t("ADM.edm.mgmtcat"), // 管理分類
      dataIndex: "assetkind",
      align: "center",
      render: (text) => {
        const map = {
          EA: "重量秤量",
          EB: "尺寸測量",
          EC: "化學檢驗",
          ED: "物理檢驗",
          EE: "機械性質",
        };

        return map[text];
      },
    },
    {
      title: "設備編號",
      dataIndex: "assetID",
      align: "center",
    },
    {
      title: "設備名稱",
      dataIndex: "assetNM",
      align: "left",
    },
    {
      title: t("ADM.util.pfID"),
      dataIndex: "pfID",
      key: "pfID",
      align: "center",
      render: (text) => {
        const [hdContext] = multipleIDtoName(arrMap.psArr, text, "pfID", "pfNM").split(",");
        return hdContext;
      },
    },
    {
      title: t("ADM.mold.wsID"), // 工站ID
      dataIndex: "pwcID",
      key: "pwcID",
      align: "center",
      render: (text) => multipleIDtoName(arrMap.psArr, text, "pwcID", "pwcNM"),
    },
    {
      title: "資產照片",
      dataIndex: "pic",
      align: "center",

      render: (text) => {
        const splitArr = text.split("/");
        return (
          <Typography.Link
            onClick={() => {
              window.open(text, "_block");
            }}
          >
            {splitArr[splitArr.length - 1]}
          </Typography.Link>
        );
      },
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_editData(rowData);
              set_s_showModal(true);
              e.stopPropagation();
            }}
          />

          <UploadOutlined
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_editData(rowData);
              set_s_showFileModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];
  console.log(c_lightData);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        loading={call_getDevice.status === "load"}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          <span>
            {Object.keys(s_editData).length > 0
              ? `${t("util.util.edit")}測量設備`
              : `${t("util.util.add")}測量設備`}
          </span>
        }
        width="60%"
        visible={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getDevice.request({ assetkind: s_tabKey });
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          arrMap={arrMap}
          s_editData={c_lightData}
          set_s_editData={set_s_editData}
        />
      </CustomModal>

      <CustomModal
        title={`上傳檔案`}
        width="30%"
        open={s_showFileModal}
        maskClosable={true}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getDevice.request({ assetkind: s_tabKey });
        }}
      >
        <FileModal c_lightData={c_lightData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
});

export default HD;
