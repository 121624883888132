import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { addPnWl, updatePnWl } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { getLine } from "@/service/apis/ADM/publicAPI";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addPnWl = useAPI(addPnWl);
  const call_updatePnWl = useAPI(updatePnWl);
  const call_getLine = useAPI(getLine);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addPnWl.request(postData);
    }

    if (type === "edit") {
      call_updatePnWl.request(postData);
    }
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    call_getLine.request();
  }, []);

  useEffect(() => {
    if (call_addPnWl.status === "suc") {
      message.success(call_addPnWl.msg);
      set_is_showModal(false);
    } else if (call_addPnWl.status === "err") {
      message.error(call_addPnWl.msg);
    }
  }, [call_addPnWl.status]);

  useEffect(() => {
    if (call_updatePnWl.status === "suc") {
      message.success(call_updatePnWl.msg);
      set_is_showModal(false);
    } else if (call_updatePnWl.status === "err") {
      message.error(t(`error.error-code.${call_updatePnWl.msg}`));
    }
  }, [call_updatePnWl.status]);

  useEffect(() => {
    if (call_getLine.status === "err") {
      message.error(t(`error.error-code.${call_getLine.msg}`));
    }
  }, [call_getLine.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ wh: 0, ...s_editData }}
    >
      <Form.Item name="wlNM" hidden />
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label="料號" rules={[{ required: true }]}>
            {Object.keys(s_editData).length > 0 ? (
              <Input disabled={Object.keys(s_editData).length > 0} />
            ) : (
              <DebounceSelect
                fetchOptions={fetchPNList}
                maxTagCount={10}
                showSearch
                onChange={(value) => {
                  form.setFieldsValue({ PN: value.value, pdtNM: value.title });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wlID" label="機碼" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              optionFilterProp="label"
              showSearch
              options={
                call_getLine?.data?.map((x) => ({
                  label: x.wlID + "_" + x.wlNM,
                  value: x.wlID,
                  data: x,
                })) || []
              }
              onChange={(_, data) => form.setFieldsValue({ wlNM: data.data.wlNM })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wh" label="C/T值(S/PCS)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addPnWl.status === "load" || call_updatePnWl.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
