import React from "react";
import { theme } from "antd";
// import { fontSize } from '../../styled/publicTag';
import { formatAmount } from "@/util/format";

const { useToken } = theme;

const Card = ({ data }) => {
  const { token } = useToken();
  const { wlID, woN, PN, pdtNM, target, ngC, okC, inC, pdtRate, ngRate, situF, PPE, linemix } =
    data;

  const toDecimal = (percent) => {
    const parsed = parseFloat(percent);

    if (!Number.isNaN(parsed)) {
      // console.log('parseFloat(percent) = ', parseFloat(percent))
      return parseFloat(percent);
    } else {
      // alert(123)
      return 0;
    }
  };

  const ng = toDecimal(ngRate);

  const ngRateeColor = () => {
    if (ng < 5) {
      return "white";
    } else if (ng >= 5 && ng <= 10) {
      return "orange";
    } else {
      return "#c50404";
    }
  };

  const ppe = toDecimal(PPE);
  console.log("pp", ppe);

  const PPEColor = () => {
    if (ppe <= 50) {
      return "grey";
    } else if (ppe >= 100) {
      return "#ee3535";
    } else {
      return "white";
    }
  };

  const colorMap = {
    "": "white",
    B: "main",
    G: "success",
    W: "white", // warning
    R: "error",
    // P: "purple",
    // : "skyblue"
  };

  const getType = () => {
    if (woN.length > 0) {
      if (data.lifeF === "1") {
        // lifeF 1 = 登錄狀態下
        return data.peakV > 0 ? "white" : "purple"; // 有速度代表有單有機, 白燈，沒速度代表有單沒機, 紫燈
      } else {
        return colorMap[situF];
      }
    } else {
      return data.peakV > 0 ? "skyblue" : "white";
    }
  };

  const type = getType();
  console.log("type = ", type);
  const getColor = (type) => {
    switch (type) {
      case "white":
        return token.colorWhite;
      case "purple":
        return token.colorPurple;
      case "skyblue":
        return token.colorSkyblue;
      case "main":
        return token.colorPrimary;
      case "yellow":
        return token.colorYellow;
      case "error":
        return token.colorError;
      case "warning":
        return token.colorWarning;
      case "success":
        return token.colorSuccess;
      default:
        return token.colorPrimary;
    }
  };

  return (
    <div
      className={`rounded-lg flex flex-col bg-[#F9F9F9] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] ${
        type === "white" ? "opacity-50" : "opacity-100"
      }`}
    >
      <div className="flex">
        <div
          className="shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-tl-lg rounded-br-lg flex-1 flex-center flex-col"
          style={{ backgroundColor: getColor(type) }}
        >
          <div
            className={`flex-[6] w-full flex-center font-bold text-3xl ${
              type === "white" ? "text-[#B7B6B6]" : "text-white"
            }`}
          >
            {wlID}
          </div>
          {/* <div
            className={`flex-[3] bg-black/10 flex-center w-full rounded-br-lg text-xl ${
              type === "white" ? "text-[#B7B6B6]" : "text-white"
            }`}
          >
            {linemix === 0 ? "單線" : linemix === 1 ? "左線" : "右線"}
          </div> */}
        </div>
        <div className="box-border flex-[3] p-2 flex flex-col justify-around">
          <div className="flex">
            <div className="font-bold text-xl text-[#6C6C6C]">工單</div>
            <div className="text-xl text-[#5D80EC]">{woN}</div>
          </div>
          <div className="flex">
            <div className="font-bold text-xl text-[#6C6C6C]">料號</div>
            <div className="text-xl text-[#5D80EC]">{PN}</div>
          </div>
          <div className="flex">
            <div className="font-bold text-xl text-[#6C6C6C]">品名</div>
            <div className="text-xl text-[#5D80EC]">{pdtNM}</div>
          </div>
        </div>
      </div>

      <div className="px-5 py-2 flex justify-between border-0 border-t border-solid border-[#B8B8B8]">
        <div className="flex-center flex-col">
          <div className="text-[#6F7C98] font-bold text-base">目標數量</div>
          <div className="text-[#0346FF] font-bold text-base">
            {target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
        <div className="flex-center flex-col">
          <div className="text-[#6F7C98] font-bold text-base">生產數量</div>
          <div className="text-[#0346FF] font-bold text-base">
            {inC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
        <div className="flex-center flex-col">
          <div className="text-[#6F7C98] font-bold text-base">良品數量</div>
          <div className="text-[#0346FF] font-bold text-base">
            {okC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
        <div className="flex-center flex-col">
          <div className="text-[#6F7C98] font-bold text-base">不良數量</div>
          <div className="text-[#0346FF] font-bold text-base">
            {ngC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        </div>
      </div>
      <div className="px-5 py-1 bg-[#71AFF8] rounded-bl-lg rounded-br-lg flex justify-between text-white text-base font-bold">
        <div className="flex">
          <div className="">達成率:</div>
          <div className="ml-2">{formatAmount(pdtRate)}</div>
        </div>
        <div className="flex">
          <div className="">效率:</div>
          <div className="ml-2" style={{ color: PPEColor() }}>
            {formatAmount(PPE)}%
          </div>
        </div>
        <div className="flex">
          <div className="">不良率:</div>
          <div className="ml-2" style={{ color: ngRateeColor() }}>
            {formatAmount(ngRate)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
