/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getPWC, getLine } from "@/service/apis/ADM/publicAPI";
import { getBdmWl, deleteBdmWl } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer";

const Wl = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [s_editData, set_s_editData] = useState({});
  const [s_pwcOptions, set_s_pwcOptions] = useState([]);
  const [s_wlOptions, set_s_wlOptions] = useState([]);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getPWC = useAPI(getPWC);
  const call_getLine = useAPI(getLine);
  const call_getBdmWl = useAPI(getBdmWl);
  const call_deleteBdmWl = useAPI(deleteBdmWl);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  useEffect(() => {
    call_getLine.request();
    call_getPWC.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getPWC;
    if (status === "suc") {
      const _options = data?.map((item) => ({
        label: `${item.pwcNM}_${item.pwcID}`,
        value: item.pwcID,
      }));
      set_s_pwcOptions(_options);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getPWC.status]);

  useEffect(() => {
    const { status, msg, data } = call_getLine;
    if (status === "suc") {
      console.log("call_getLine = ", data);
      const _options = data?.map((item) => ({
        label: `${item.wlNM}_${item.wlID}`,
        value: item.wlID,
      }));
      set_s_wlOptions(_options);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getLine.status]);

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      align: "center",
      // width: "4%",
    },
    {
      title: t("WOS.util.pwcID"),
      dataIndex: "pwcID",
      align: "center",
      // width: "4%",
    },
    {
      title: t("WOS.wlwoc.We"),
      dataIndex: "wlID",
      align: "center",
      // width: "4%",
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteBdmWl.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getBdmWl.status === "suc") {
      message.success(call_getBdmWl.msg);
      set_s_tableData(
        call_getBdmWl.data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getBdmWl.status === "err") {
      message.error(t(`error.error-code.${call_getBdmWl.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBdmWl.status]);

  useEffect(() => {
    if (call_deleteBdmWl.status === "suc") {
      message.success(call_deleteBdmWl.msg);
      // delete之後 重call表格資料
      call_getBdmWl.request();
    } else if (call_deleteBdmWl.status === "err") {
      // delete之後 重call表格資料
      call_getBdmWl.request();
      message.error(t(`error.error-code.${call_deleteBdmWl.msg}`));
    }
  }, [call_deleteBdmWl.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBdmWl.status === "load"}
        s_editData={s_editData}
        indentSize={50}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}航太廠`
            : `${t("util.util.add")}航太廠`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getBdmWl.request();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_wlOptions={s_wlOptions}
          s_pwcOptions={s_pwcOptions}
        />
      </CustomModal>
    </>
  );
});

export default Wl;
