import { Modal, Space, message } from "antd";
import dayjs from "dayjs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deleteInspmethod, getInspmethod } from "@/service/apis/QMS/Instrument";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer";
import BatchImport from "./ModalContainer/BatchImport";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isBatchModal, set_s_isBatchModal] = useState(false);
  // 是否顯示上傳檔案的跳顯

  // useAPI 取得表頭資料
  const call_getInspmethod = useAPI(getInspmethod);
  // useAPI 刪除表頭資料
  const call_deleteInspmethod = useAPI(deleteInspmethod);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
    onCrateBatch: () => set_s_isBatchModal(true),
  }));

  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  // 搜尋
  const onSearch = () => {
    call_getInspmethod.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteInspmethod.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "方法ID",
      dataIndex: "methodID",
      align: "center",
      width: 160,
    },
    {
      title: "檢驗方法",
      dataIndex: "gpID",
      align: "left",
      width: 160,
    },
    {
      title: "檢驗類型",
      dataIndex: "insptype",
      align: "center",
      width: 160,
      render: (text, rowData, _) => {
        const name = [
          { label: "計數型(定性)", value: "count" },
          { label: "計量型(定量)", value: "measure" },
        ];
        return name.find((item) => item.value === text)?.label;
      },
    },
    {
      title: "單位",
      dataIndex: "UOM",
      width: 160,
      align: "center",
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
    },
    {
      title: "建立時間",
      dataIndex: "createT",
      align: "center",
      width: 160,

      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: 160,

      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.isvalid"), //"是否有效",
      dataIndex: "isvalid",
      align: "center",
      width: 160,

      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },

    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: 160,
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getInspmethod;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          index: i,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getInspmethod.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteInspmethod;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteInspmethod.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        loading={call_getInspmethod.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={"批量新增檢驗方法"}
        width={"70%"}
        centered={true}
        open={s_isBatchModal}
        onCancel={() => set_s_isBatchModal(false)}
        afterClose={() => {
          onSearch();
        }}
      >
        <BatchImport set_s_batchImportModal={set_s_isBatchModal} />
      </CustomModal>

      <CustomModal
        title={type === "create" ? "新增檢驗方法" : "編輯檢驗方法"}
        width={"70%"}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
