import { Button, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { IconBatchADD, IconPDF } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import PDFview from "@/pages/TestPage/QMS/QMFM/ModalContainer/PDF/PDFview";
import apiBasic from "@/service/APIBasic";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { createAQLimage } from "@/service/apis/QMS/aql";

import Aql from "./Aql";
import FormSetting from "./FormSetting/";
import Instrument from "./Instrument";
import Plan from "./Plan";
import SampleSet from "./SampleSet/";
import Sampling from "./Sampling";

const QMSMAnage = () => {
  const { t } = useTranslation();

  const samlproc_ref = useRef(null);
  const instrument_ref = useRef(null);
  const sampling_ref = useRef(null);
  const aql_ref = useRef(null);
  const plan_ref = useRef(null);
  const SampleSet_ref = useRef(null);
  const formSetting_ref = useRef(null);

  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);
  // 產生AQL圖檔
  const call_createAQLimage = useAPI(createAQLimage);

  const [s_searchData, set_s_searchData] = useState({
    ID: undefined,
    PN: undefined,
    pdtNM: undefined,
  });
  const [s_tabKey, set_s_tabKey] = useState("instrument"); // 目前是在哪個Tab上
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const [s_PDFurl, set_s_PDFurl] = useState("");
  const resetSearchData = () => {
    set_s_searchData({});
  };

  const onSearch = () => {
    s_tabKey === "samlproc" && samlproc_ref.current.onSearch();
    s_tabKey === "instrument" && instrument_ref.current.onSearch();
    s_tabKey === "sampling" && sampling_ref.current.onSearch();
    s_tabKey === "aql" && aql_ref.current.onSearch();
    s_tabKey === "plan" && plan_ref.current.onSearch();
    s_tabKey === "formSetting" && formSetting_ref.current.onSearch();
  };

  const onCreate = () => {
    s_tabKey === "samlproc" && samlproc_ref.current.onCreate();
    s_tabKey === "instrument" && instrument_ref.current.onCreate();
    s_tabKey === "sampling" && sampling_ref.current.onCreate();
    s_tabKey === "aql" && aql_ref.current.onCreate();
    s_tabKey === "plan" && plan_ref.current.onCreate();
    s_tabKey === "SampleSet" && SampleSet_ref.current.onCreate();
    s_tabKey === "formSetting" && formSetting_ref.current.onCreate();
  };

  const onCrateBatch = () => {
    s_tabKey === "instrument" && instrument_ref.current.onCrateBatch();
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    console.log(type, value);
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
  }, []);

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],
    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) || [],
    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    pwc:
      call_getPWC?.data.map((pwc) => ({ label: `${pwc.pwcID}_${pwc.pwcNM}`, value: pwc.pwcID })) ||
      [],
    ctrlrequire: [
      { value: "A", label: "每次抽樣創建" },
      { value: "B", label: "每固定週期自創" },
      { value: "C", label: "物控覆核時自創" },
      { value: "D", label: "切換工班時自創" },
    ],
  };

  // 產生品質允收水準(AQL)圖檔
  useEffect(() => {
    const { status, msg, data } = call_createAQLimage;
    if (status === "suc") {
      message.success(msg);
      console.log(data);
      set_s_PDFurl(data);
      set_s_isShowModal(true);
      // window.open(data, "_blank");
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_createAQLimage.status]);

  const tabsData = [
    {
      key: "formSetting",
      label: "管理表單設定",
      // disabled: true,
      children: (
        <FormSetting
          ref={formSetting_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "instrument",
      label: "檢驗方法主檔 ",
      children: (
        <Instrument
          ref={instrument_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "sampling",
      label: "抽樣程序主檔 ",
      // disabled: true,
      children: (
        <Sampling
          ref={sampling_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "SampleSet",
      label: "抽樣計劃規劃",
      children: (
        <SampleSet
          ref={SampleSet_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },

    {
      key: "plan",
      label: "抽樣計劃主檔 ",
      children: (
        <Plan
          ref={plan_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },

    {
      key: "aql",
      label: "允收水準設定",

      children: (
        <Aql
          ref={aql_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];
  const aqlControl = [
    <IconPDF
      type="primary"
      key="pdf"
      className="text-red-500 text-[40px]"
      onClick={() => {
        call_createAQLimage.request();
      }}
    />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];
  const planControl = [<AddButton type="primary" key="create" onClick={onCreate} />];
  const sampleSetControl = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton disabled type="primary" key="create" onClick={onCreate} />,
  ];
  const instrumentControl = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <Button
      // disabled
      type="primary"
      icon={<IconBatchADD />}
      onClick={() => {
        onCrateBatch();
      }}
    ></Button>,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  const searchBasicFilter = (s_tabkey) => {
    const search = {
      basic: searchBasic,
      aql: aqlControl,
      plan: planControl,
      SampleSet: sampleSetControl,
      instrument: instrumentControl,
    };
    return search[s_tabkey] ?? search["basic"];
  };
  console.log(s_PDFurl);
  return (
    <>
      <PageHeader title="品質主檔維護" extra={searchBasicFilter(s_tabKey)} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />

      <CustomModal
        title={"允收水準總表"}
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {}}
      >
        <PDFview url={`${apiBasic.conn}://${apiBasic.url}/${s_PDFurl}`} />
      </CustomModal>
    </>
  );
};

export default QMSMAnage;
