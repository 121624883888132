import React, { useState, useEffect } from "react";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getStock, deleteStock } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";
import ModalConintaer from "./ModalContainer";
import { useTranslation } from "react-i18next";
import { formatAmount } from "@/util/format";

const Pack = ({ s_isShowModal, set_s_isShowModal, c_lightData }) => {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const call_getStock = useAPI(getStock);
  const call_deleteStock = useAPI(deleteStock);

  const tableColumns = [
    {
      title: t("ADM.pn-manage.replacePN"),
      dataIndex: "replacePN",
      width: "15%",
    },
    {
      title: t("ADM.pn-manage.newPN"),
      dataIndex: "newPN",
      width: "15%",
    },
    {
      title: t("ADM.pn-manage.stdstock"),
      dataIndex: "stdstock",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.safestock"),
      dataIndex: "safestock",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.minstock"),
      dataIndex: "minstock",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.maxstock"),
      dataIndex: "maxstock",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.maxcost"),
      dataIndex: "maxcost",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("ADM.pn-manage.stocklife"),
      dataIndex: "stocklife",
      align: "right",
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      width: "15%",
      align: "left",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              e.stopPropagation();
              set_s_isShowModal(true);
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteStock.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    if (Object.keys(c_lightData).length === 0) return;
    call_getStock.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getStock.status === "suc") {
      message.success(call_getStock.msg);
      set_s_tableData(
        call_getStock.data.map((x) => {
          return { ...x, key: x.PN + x.psopno };
        })
      );
    } else if (call_getStock.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getStock.msg}`));
    }
  }, [call_getStock.status]);

  useEffect(() => {
    if (call_deleteStock.status === "suc") {
      message.success(call_deleteStock.msg);
    } else if (call_deleteStock.status === "err") {
      message.error(t(`error.error-code.${call_deleteStock.msg}`));
    }
    call_getStock.request(c_lightData);
  }, [call_deleteStock.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.pn-manage.tabStock")}`
            : `${t("util.util.add")}${t("ADM.pn-manage.tabStock")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getStock.request(c_lightData);
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default Pack;
