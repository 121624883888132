import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

// export const createAQLimage = (data) => {
//   return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/createAQLimage`, data);
// };

export const getSampleSet = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSampleSet`, {
    params: _params,
  });
};
export const addSampleSet = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addSampleSet`, data);
};

export const updateSampleSet = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateSampleSet`, data);
};

export const deleteSampleSet = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteSampleSet`, data);
};


export const chooseSizecode = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/chooseSizecode`, {
    params: _params,
  });
};
// -----------------------------------------------------------------------------------------------
// export const getAqlTb = (_params) => {
//   return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAqlTb`, {
//     params: _params,
//   });
// };
// export const addAqlTb = (data) => {
//   return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addAqlTb`, data);
// };

// export const updateAqlTb = (data) => {
//   return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateAqlTb`, data);
// };

// export const deleteAqlTb = (data) => {
//   return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteAqlTb`, data);
// };
