import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import { getPS, deletePS } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const HD = forwardRef((props, ref) => {
  const { s_searchData, c_lightData, set_c_lightData } = props;
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getPS = useAPI(getPS);
  const call_deletePS = useAPI(deletePS);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
  }));

  const onSearch = () => call_getPS.request({ psID: s_searchData.item });

  const tableColumns = [
    {
      title: t("util.util.SN"), //"序",
      dataIndex: "SN",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.production-line-manage.psID"), //"車間ID",
      dataIndex: "psID",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.name"), //"名稱",
      dataIndex: "psNM",
      align: "center",
      width: "13%",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deletePS.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getPS.status === "suc") {
      // message.success(call_getPS.msg);
      set_s_tableData(
        call_getPS.data.map((x, i) => ({
          ...x,
          key: i,
          index: i,
        }))
      );
    } else if (call_getPS.status === "err") {
      message.error(t(`error.error-code.${call_getPS.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPS.status]);

  useEffect(() => {
    if (call_deletePS.status === "suc") {
      message.success(call_deletePS.msg);
    } else if (call_deletePS.status === "err") {
      message.error(t(`error.error-code.${call_deletePS.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deletePS.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData?.map((item, index) => {
          return { ...item, key: index, SN: index + 1 };
        })}
        loading={call_getPS.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_c_lightData(record);
              set_s_isShowModal(true);
            },
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t("ADM.production-line-manage.tabPS")}`
            : `${t("util.util.add")}${t("ADM.production-line-manage.tabPS")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
