import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Spin, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import {
  addQmsApuiHd,
  copyQmsApui,
  getQmsApSettingHd,
  updateQmsApuiHd,
} from "@/service/apis/QMS/Apui/";

const ModalContainer = ({ s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_PN = Form.useWatch("PN", form);

  const call_getProduct = useAPI(getProductSelect);
  const call_addQmsApuiHd = useAPI(addQmsApuiHd);
  const call_updateQmsApuiHd = useAPI(updateQmsApuiHd);
  const call_copyQmsApui = useAPI(copyQmsApui);
  const call_getQmsApSettingHd = useAPI(getQmsApSettingHd);
  const [s_isCopy, set_s_isCopy] = useState(false);
  const [s_PNOptions, set_s_PNOptions] = useState([]);
  const [s_spotIDOptions, set_s_spotIDOptions] = useState([
    { label: "機種", value: "model" },
    { label: "查詢分類", value: "querycat" },
    { label: "授權分類", value: "mgmtcat" },
    { label: "物料屬性", value: "attribute" },
  ]);

  const type = s_isCopy ? "copy" : Object.keys(s_editData).length === 0 ? "create" : "edit";

  const onSubmit = async (values) => {
    if (type === "copy") {
      call_copyQmsApui.request(values);
    }

    if (type === "create") {
      call_addQmsApuiHd.request(values);
    }

    if (type === "edit") {
      call_updateQmsApuiHd.request(values);
    }
  };

  // 據此條目創建
  const copy = () => {
    set_s_isCopy(true);
    set_s_editData({});
    form.setFieldsValue({
      copyPN: f_PN,
    });
  };

  // 第一次渲染取得料號下拉選單
  useEffect(() => {
    call_getProduct.request();
    call_getQmsApSettingHd.request();
  }, []);

  // useAPI 取得料號
  useEffect(() => {
    const { status, data, msg } = call_getProduct;
    if (status === "suc") {
      set_s_PNOptions(
        data.reduce(
          (data, current) => {
            data.push({
              label: `${current.PN}_${current.pdtNM}`,
              value: current.PN,
              data: current,
            });
            return data;
          },
          // [{ label: "All_全部", value: "All" }]
          []
        ) || []
      );
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getProduct.status]);

  // useAPI 新增表頭
  useEffect(() => {
    const { data, status, msg } = call_addQmsApuiHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsApuiHd.status]);

  // useAPI 更新表頭
  useEffect(() => {
    const { data, status, msg } = call_updateQmsApuiHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsApuiHd.status]);

  // 據此創建
  useEffect(() => {
    const { data, status, msg } = call_copyQmsApui;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_copyQmsApui.status]);

  return (
    <Spin spinning={call_getProduct.status === "load"}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          isvalid: true,
          uiID: "APP-PN-All", // 寫死 for 量產試作
          ver: 2, // 寫死 for 量產試作
          ...s_editData,
        }}
      >
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="copyPN" hidden>
            <Input />
          </Form.Item>
        </div>

        <Row gutter={[24, 12]}>
          <Col span={8}>
            <Form.Item label="維護介面ID" name="uiID">
              {/* <Input  /> */}
              <Select
                disabled
                onChange={(_, data) => {
                  form.setFieldsValue({
                    ver: data.data.ver,
                  });
                }}
                options={call_getQmsApSettingHd.data?.map((item) => ({
                  key: item.uiID + item.ver,
                  label: `${item.uiNM}_${item.ver}`,
                  value: item.uiID,
                  data: item,
                }))}
              />
            </Form.Item>
          </Col>

          {/* <Col span={8}>
            <Form.Item label="維護介面名稱" name="uiNM">
              <Input />
            </Form.Item>
          </Col> */}

          <Col span={8}>
            <Form.Item label="維護介面版本" name="ver">
              <InputNumber disabled className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 12]}>
          {/* <Col span={8}>
            <Form.Item label="連結類別" name="ctrlspot" rules={[{ required: true }]}>
              <Select
                disabled={type === "edit"}
                onChange={(ctrlspot) => {
                  form.setFieldsValue({
                    spotID: "",
                  });
                }}
                options={[
                  { label: "料號", value: "PN" },
                  { label: "群組", value: "GP" },
                ]}
              />
            </Form.Item>
          </Col> */}

          <Col span={8}>
            <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
              <Select
                disabled={type === "edit"}
                options={s_PNOptions}
                showSearch
                onChange={(_, data) => {
                  console.log(data);
                  form.setFieldsValue({
                    pdtNM: data.data.pdtNM,
                    pdtspec: data.data.pdtspec,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="品名" name="pdtNM">
              <Input disabled className="w-full" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="規格" name="pdtspec">
              <Input disabled className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 12]}>
          <Col span={8}>
            {/* 註記 */}
            <Form.Item label={"群組明細"} name="gpitem">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* 註記 */}
            <Form.Item label={"量產試作記錄"} name="testN">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* 註記 */}
            <Form.Item label={t("util.util.note")} name="note">
              <Input />
            </Form.Item>
          </Col>
          {/* 是否有效 */}
          {/* <Col span={8}>
           
            <Form.Item label={t("util.util.isvalid")} name="isvalid">
              <Radio.Group
                options={[
                  { value: true, label: t("util.util.yes") },
                  { value: false, label: t("util.util.no") },
                ]}
              />
            </Form.Item>
          </Col> */}
        </Row>

        <Divider />

        <Row gutter={[24, 12]}>
          <Col span={18}>
            {type === "edit" ? (
              <Button
                size="large"
                style={{ marginRight: "20px", borderRadius: "5px" }}
                onClick={copy}
              >
                據此條目創建
              </Button>
            ) : null}
          </Col>
          <Col span={6} className="flex justify-end gap-2">
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ModalContainer;
