import { Button, Divider, Space, Transfer, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useAPI from "@/hooks/useAPI";
import { getQmsSpecCompare, importQmsSpecTb } from "@/service/apis/QMS/QMSManage";

const CustomTransfer = styled(Transfer)`
  justify-content: space-around !important;
  border-color: #d7d7d7 !important;

  .ant-transfer-list {
    width: 450px !important;
  }
  .ant-transfer-list-header {
    background-color: #def7ff !important;
  }

  /* Styles for unchecked items */
  .ant-transfer-list-content-item {
    /* background-color: green !important; */
    background-color: #ffffff;
    height: 55px;
  }
  .ant-transfer-list-content-item:nth-child(even) {
    /* background-color: green !important; */
    background-color: #d7d7d7;
    height: 55px;
  }

  /* Styles for checked items */
  .ant-transfer-list-content-item-checked {
    background-color: #d2eaff !important;
  }
  .ant-transfer-list-content-item-checked .ant-btn {
    width: 80px !important;
    height: 80px;
    background-color: #559ee2 !important;
    margin-top: 42px;
  }
  .ant-btn[disabled] {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #badbfa !important;
  }
  .ant-btn {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #4096ff !important;
  }
  .ant-btn .ant-btn-icon {
    /* display: block !important; */
    /* width:100px !important; */
    /* height:100px !important; */
  }
  .ant-btn .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
  }
  .ant-transfer-operation {
  }
  .ant-btn[disabled] .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
    color: #ffffff;
  }

  .ant-checkbox-inner {
    /* background-color: red !important; */
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #559ee2 !important;
  }
`;

const BatchImport = ({ set_s_batchImportModal, c_lightData }) => {
  const { t } = useTranslation();

  const call_getQmsSpecCompare = useAPI(getQmsSpecCompare);
  const call_importQmsSpecTb = useAPI(importQmsSpecTb);

  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  const [s_inspgroup, set_s_inspgroup] = useState("");

  useEffect(() => {
    console.log(c_lightData);
    call_getQmsSpecCompare.request({
      dcUUID: c_lightData.dcUUID,
      datafm: c_lightData.datafm,
      fmversion: c_lightData.fmversion,
    });
  }, []);

  useEffect(() => {
    const { data, msg, status } = call_getQmsSpecCompare;
    if (status === "suc") {
      const specOptions =
        data?.spec?.map((item, index) => ({
          key: index,
          data: { ...item, updtT: new Date(item.updtT) },
          title: `${item?.inspitem}_${item?.drawno}`,
        })) || [];
      const sipOptions =
        data?.insp?.map((item, index) => ({
          key: specOptions.length + index,
          data: { ...item, updtT: new Date(item.updtT) },
          title: `${item?.inspitem}_${item?.drawno}`,
        })) || [];

      setMockData([...specOptions, ...sipOptions]);
      setTargetKeys(sipOptions.map((item) => item.key));
    }
    if (status === "err") {
      message.error(call_getQmsSpecCompare.msg);
      // set_s_eq([]);
    }
  }, [call_getQmsSpecCompare.status]);

  useEffect(() => {
    const { data, msg, status } = call_importQmsSpecTb;
    if (status === "suc") {
      message.success(msg);
      set_s_batchImportModal(false);
    }
    if (status === "err") {
      message.error(call_importQmsSpecTb.msg);
      // set_s_eq([]);
    }
  }, [call_importQmsSpecTb.status]);

  const handleChange = (targetKeys, direction, moveKeys) => {
    console.log(targetKeys);
    setTargetKeys(targetKeys);
  };
  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };
  const onSubmit = () => {
    const payload = targetKeys.reduce((total, value) => {
      console.log(typeof value);
      console.log(mockData[value]);
      total.push({ ...mockData[value].data, drawing: c_lightData.drawing, inspgroup: s_inspgroup });
      return total;
    }, []);

    call_importQmsSpecTb.request({
      dcUUID: c_lightData.dcUUID,
      insp: payload,
    });
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-[95%] ">
        <CustomTransfer
          dataSource={mockData}
          showSearch
          selectAllLabels={(selectedCount, totalCount) => {
            console.log(selectedCount, totalCount);
            return <div>123</div>;
          }}
          titles={["檢驗規格｜項目", "檢驗標準｜項目"]}
          // filterOption={filterOption}
          targetKeys={targetKeys}
          onChange={handleChange}
          onSearch={handleSearch}
          listStyle={{
            width: "100%",
            height: 500,
          }}
          render={(item) => item.title}
        />
      </div>
      {/* <div className="flex mt-2  w-[95%]">
        <div>{`指定作業群組:`}</div>
        <div>
          <Input
            className="ml-3 w-[200px]"
            onChange={(e) => {
              set_s_inspgroup(e.target.value);
            }}
          />
        </div>
      </div> */}

      <Divider />
      <Space className=" self-end">
        <Button onClick={() => set_s_batchImportModal(false)}>{t("util.util.cancel")}</Button>
        <Button type="primary" onClick={onSubmit}>
          {t("util.util.ok")}
        </Button>
      </Space>
    </div>
  );
};

export default BatchImport;
