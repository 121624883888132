import { useState, useEffect } from "react";
import { Button, Space, message, Input } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { sapTypeOptions } from "../../util/anyMap";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getModelHd } from "@/service/apis/LMS/LabelManage";
import { useTranslation } from "react-i18next";
import Printer from "../../LabelTemplate/TB/ModalCantainer/Printer";
import ChoosePrinter from "../../LabelTemplate/TB/ModalCantainer/ChoosePrinter";

const handleKeyDownEnter = (e, fun) => {
  if (e.key === "Enter") fun();
};

const ChooseLabel = (props) => {
  const { t } = useTranslation();
  const { s_keyParams } = props;

  const [s_chooseLabel, set_s_chooseLabel] = useState({});

  const call_getModelHd = useAPI(getModelHd);

  const [s_printerModal, set_s_printerModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_searchData, set_s_searchData] = useState({
    PN: undefined,
    modelID: undefined,
  });

  const tableColumns = [
    {
      title: "標籤名稱",
      dataIndex: "modelNM",
    },
    {
      title: "SAP標籤類型",
      dataIndex: "saptype",
      align: "center",
      render: (text) => text && sapTypeOptions.filter((x) => x.value === text)[0].label,
    },
    {
      title: "SAP標籤ID",
      dataIndex: "sapSNstr",
      align: "center",
    },
    {
      title: "標籤ID",
      dataIndex: "modelID",
      render: (_, rowData) => ` ${rowData.modelID}`,
    },
    { title: "版本", dataIndex: "ver", width: "90px", align: "center" },

    {
      title: "寬*長",
      dataIndex: "size",
      render: (_, rowData) =>
        `(${rowData.width}${rowData.unit} x ${rowData.height}${rowData.unit})`,
    },
    {
      title: "備註",
      dataIndex: "note",
      width: "30%",
    },

    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Space>
          <PrinterOutlined
            className="text-[25px]"
            onClick={(e) => {
              set_s_chooseLabel(rowData);
              set_s_printerModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const search = (e) => {
    set_s_chooseLabel({});
    call_getModelHd.request(s_searchData);
  };

  useEffect(() => {
    if (
      (s_searchData.PN === undefined || s_searchData.PN === "") &&
      (s_searchData.modelID === undefined || s_searchData.modelID === "")
    ) {
      call_getModelHd.request(s_searchData);
    }
  }, [s_searchData]);

  useEffect(() => {
    if (call_getModelHd.status === "suc") {
      // 搜尋控點ID 對應的模板
      const getModel = call_getModelHd.data.map((x, i) => ({ ...x, key: i, index: i }));
      set_s_tableData(getModel);
    } else if (call_getModelHd.status === "err") {
      message.error(call_getModelHd.msg);
      set_s_tableData([]);
    }
  }, [call_getModelHd.status]);

  const searchTemp = [
    <Input
      key="modelID"
      className="w-[300px]"
      allowClear
      placeholder="請輸入標籤ID或標籤名稱"
      value={s_searchData.modelID}
      onChange={(e) =>
        set_s_searchData((prev) => ({
          ...prev,
          modelID: e.target.value,
        }))
      }
      onPressEnter={(e) => handleKeyDownEnter(e, search)}
    />,
    <Button type="primary" onClick={search} key="search">
      查詢
    </Button>,
  ];

  return (
    <>
      <PageHeader title="" extra={searchTemp} />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getModelHd.status === "load"}
        c_lightData={s_chooseLabel}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_chooseLabel.key ? set_s_chooseLabel({}) : set_s_chooseLabel(record),
        })}
      />

      <CustomModal
        width="80%"
        title="條碼列印"
        open={s_printerModal}
        onCancel={() => set_s_printerModal(false)}
      >
        <Printer
          set_s_showModal={set_s_printerModal}
          s_editData={s_keyParams}
          c_lightData={s_chooseLabel}
          s_recordSN={[]}
        />
      </CustomModal>
    </>
  );
};
export default ChooseLabel;
