import IconBox from "../IconBox";
import { ReactComponent as Compaer } from "@/assets/icon/BI/compaer.svg"
import { ReactComponent as Current } from  "@/assets/icon/BI/current.svg";
import { ReactComponent as Mom  } from  "@/assets/icon/BI/mom.svg";
import { ReactComponent as Yoy } from "@/assets/icon/BI/yoy.svg";
import { ReactComponent as OneColumns } from "@/assets/icon/BI/oneColumns.svg";
import { ReactComponent as TowColumns } from "@/assets/icon/BI/twoColumns.svg";
import { ReactComponent as ThreeColumns } from "@/assets/icon/BI/threeColumns.svg";



// 當前
export const IconCurrent = (props) => <IconBox component={Current} {...props} />;
// 環比
export const IconMOM = (props) => <IconBox component={Mom} {...props} />;
// 評比
export const IconCompaer = (props) => <IconBox component={Compaer} {...props} />;
// 同比
export const IconYOY = (props) => <IconBox component={Yoy} {...props} />;

//圖表顯示單欄
export const IconOneColumns = (props) => <IconBox component={OneColumns} {...props} />;
//圖表顯示雙欄
export const IconTowColumns = (props) => <IconBox component={TowColumns} {...props} />;
//圖表顯示三欄
export const IconThreeColumns = (props) => <IconBox component={ThreeColumns} {...props} />;
