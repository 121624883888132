import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useWebSocket from "@/hooks/useWebSocket";
import { custSocket } from "@/service/ws/ws";
import { numberWithCommas } from "@/util/format";

import Light from "./Light";

/**
 * 根據指定條件生成用於渲染表格儲存格的屬性物件。
 *
 * @param {Array} sourceData - 原始資料陣列。
 * @param {Object} record - 當前行的資料物件。
 * @param {number} index - 當前行的索引。
 * @param {string} key - 指定欄位的鍵名。
 * @returns {Object} 包含用於渲染儲存格的屬性物件，其中包括 rowSpan 屬性。
 */
const cellProps = (sourceData = [], record = {}, index, key) => {
  // 如果是第一行，或者當前行的指定欄位的值不等於上一行的值，或者"SN"欄位的值不同
  if (index === 0 || sourceData[index - 1][key] !== record[key]) {
    // 尋找接下來的行中有多少行與當前行的指定欄位和"SN"欄位的值相同
    let rowSpan = 1;
    for (let i = index + 1; i < sourceData.length; i++) {
      if (sourceData[i][key] === record[key]) {
        rowSpan++;
      } else {
        break;
      }
    }
    return {
      rowSpan: rowSpan,
    };
  } else {
    // 如果當前行的指定欄位的值等於上一行的值，並且"SN"欄位的值也相同，則設置 rowSpan 為0
    return {
      rowSpan: 0,
    };
  }
};

export default function Dashboard() {
  const { t } = useTranslation();
  // 全螢幕state
  const [s_full, set_s_full] = useState(false);
  const [s_loading, set_s_loading] = useState(true);
  const [s_tableData, set_s_tableData] = useState([]);
  // header上方data
  const call_custSocket = useWebSocket(custSocket);

  const handle = useFullScreenHandle();

  // 開啟全螢幕
  const openFullScreen = () => {
    set_s_full(true);
    handle.enter();
  };

  const closeFullScreen = () => {
    set_s_full(false);
    handle.exit();
  };

  const tableColumns = [
    {
      title: "機碼",
      dataIndex: "wlID",
      align: "center",
      width: 250,
      onCell: (record, index) => cellProps(s_tableData, record, index, "wlID"),
      render: (_, rocord) => (
        <span className="text-blue-500">
          {rocord.wlID} / {rocord.wlNM}
        </span>
      ),
    },

    {
      title: t("util.util.situF"),
      dataIndex: "situF",
      align: "center",
      width: "6%",
      render: (_, rowData) => <Light rowData={{ situF: rowData.woN === "" ? "" : "G" }} />,
    },

    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
      width: 250,
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
      width: 250,
    },
    {
      title: "工作中心",
      dataIndex: "pwcID",
      align: "center",
      width: 250,
      render: (_, rowData) => (rowData.pwcID ? rowData.pwcID + "_" + rowData.pwcNM : ""),
    },
    {
      title: "良品",
      dataIndex: "okC",
      align: "right",
      width: 100,
      className: "bg-[#f9e9d7]",
      render: (value) => numberWithCommas(value),
    },
    {
      title: "不良品",
      dataIndex: "ngC",
      align: "right",
      width: 100,
      className: "bg-[#f9e9d7]",
      render: (value) => numberWithCommas(value),
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
    },
    {
      title: "規格",
      dataIndex: "pdtspec",
    },
  ];

  useEffect(() => {
    call_custSocket.changeParams({});
    call_custSocket.setIsOpen(true);
    set_s_loading(true);
    return () => call_custSocket.setIsOpen(false);
  }, []);

  useEffect(() => {
    if (call_custSocket.socketData.status) {
      set_s_tableData(
        call_custSocket.socketData.data?.map((x, i) => {
          return {
            ...x,
            key: i,
            index: i,
          };
        }) || []
      );
      set_s_loading(false);
    } else {
      set_s_tableData([]);
    }
  }, [call_custSocket.socketData]);

  return (
    <Spin spinning={s_loading}>
      <div className="relative h-full">
        <FullScreen handle={handle} onChange={set_s_full}>
          <PageHeader title="數位生產看板" extra={[]} />

          <CustomTable pagination={false} columns={tableColumns} dataSource={s_tableData} />
        </FullScreen>
      </div>
    </Spin>
  );
}
