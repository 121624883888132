import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// API
import useAPI from "@/hooks/useAPI";
import { addQmsApSettingTb, getQmsApSettingTb } from "@/service/apis/QMS/Apui";
import { getQmsSopHd } from "@/service/apis/QMS/QMSManage";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const RecModal = ({ s_editData, set_s_editData, set_s_isShowRecModal }) => {
  const [form] = Form.useForm();
  const f_details = Form.useWatch("details", form);

  const { t } = useTranslation();

  const [s_sopOptions, set_s_sopOptions] = useState([]);

  const call_getQmsSopHd = useAPI(getQmsSopHd);
  const call_addQmsApSettingTb = useAPI(addQmsApSettingTb);
  const call_getQmsApSettingTb = useAPI(getQmsApSettingTb);

  useEffect(() => {
    call_getQmsApSettingTb.request(s_editData);
    call_getQmsSopHd.request();
  }, []);

  // useAPI 取得表頭
  useEffect(() => {
    const { status, msg, data } = call_getQmsSopHd;
    if (status === "suc") {
      // message.success(msg);
      const options =
        data?.map((item) => ({
          label: item.sopID + "_" + item.note,
          value: item.sopID + item.version,
          data: item,
        })) || [];
      set_s_sopOptions(options);
    }
    // if (status === "err") {
    //   message.error(t(`error.error-code.${msg}`));
    // }
  }, [call_getQmsSopHd.status]);

  // useAPI 取得表身
  useEffect(() => {
    const { status, msg, data } = call_getQmsApSettingTb;
    if (status === "suc") {
      console.log("data", data);
      form.setFieldsValue({
        details: data.map((item) => ({
          ...item,
          sop: item.sopID + item.version,
        })),
      });
    }
    // if (status === "err") {
    //   message.error(t(`error.error-code.${msg}`));
    // }
  }, [call_getQmsApSettingTb.status]);

  // useAPI 新增表身
  useEffect(() => {
    const { status, msg } = call_addQmsApSettingTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowRecModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_isShowRecModal(false);
    }
  }, [call_addQmsApSettingTb.status]);

  const onSubmit = async (values) => {
    console.log(values);
    call_addQmsApSettingTb.request(values.details);
  };
  return (
    <Form onFinish={onSubmit} name="basic" form={form} autoComplete="off" layout="vertical">
      <Form.List name="details" shouldUpdate>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key} gutter={[12, 12]}>
                {/* 影藏欄位 */}
                <Form.Item {...field} noStyle shouldUpdate name={[field.name, "sopID"]} />
                <Form.Item
                  {...field}
                  hidden
                  label="文件編碼"
                  shouldUpdate
                  name={[field.name, "dcUUID"]}
                />

                <Col span={2} className="mb12">
                  <Form.Item
                    {...field}
                    //序
                    label={t("util.util.SN")}
                    name={[field.name, "itemno"]}
                  >
                    <Input disabled value={index + 1} />
                  </Form.Item>
                </Col>

                <Col span={4} className="mb12">
                  <Form.Item {...field} label="介面維護ID" name={[field.name, "uiID"]}>
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={2} className="mb12">
                  <Form.Item {...field} label="版本" shouldUpdate name={[field.name, "ver"]}>
                    <InputNumber className="w-full" disabled />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    {...field}
                    label="sopID"
                    rules={[{ required: true }]}
                    shouldUpdate
                    name={[field.name, "sop"]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      options={s_sopOptions}
                      filterOption={(input, option) => (option?.label ?? "").includes(input)}
                      onChange={(_, data) => {
                        let formData = form.getFieldValue();
                        formData.details[index].sopID = data.data.sopID;
                        formData.details[index].version = data.data.version;
                        formData.details[index].dcUUID = data.data.dcUUID;
                        formData.details[index].sophdnote = data.data.note;
                        form.setFieldsValue(formData);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={2} className="mb12">
                  <Form.Item {...field} label="版次" shouldUpdate name={[field.name, "version"]}>
                    <InputNumber className="w-full" disabled />
                  </Form.Item>
                </Col>

                <Col span={4} className="mb12">
                  <Form.Item
                    {...field}
                    label="sopID註記"
                    shouldUpdate
                    name={[field.name, "sophdnote"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={2} className="mb12">
                  <Form.Item
                    {...field}
                    label="列數"
                    shouldUpdate
                    name={[field.name, "rowcount"]}
                    rules={[{ required: true }]}
                  >
                    <InputNumber className="w-full" />
                  </Form.Item>
                </Col>

                <Col span={1} className="flex-center">
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      let formData = form.getFieldValue();
                      formData.details = formData.details.map((x, index) => {
                        return { ...x, itemno: index + 1 };
                      });
                      form.setFieldsValue(formData);
                    }}
                    style={{ fontSize: "20px", marginLeft: "5px" }}
                  />
                </Col>
              </Row>
            ))}

            <Form.Item>
              <Button
                //新增生產入庫詳情
                type="dashed"
                onClick={() => {
                  add({ itemno: fields.length + 1, uiID: s_editData.uiID, ver: s_editData.ver });
                }}
                block
                icon={<PlusOutlined />}
                size="large"
                className="bg-[#3FC6E2] text-white"
              >
                {t("util.util.add") + "製程內容" + t("util.util.detail")}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Divider />

      <Row gutter={[24, 12]}>
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowRecModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" disabled={f_details?.length === 0} htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RecModal;
