import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, message, Radio, Space, InputNumber } from "antd";
import { addPF, updatePF } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addPF = useAPI(addPF);
  const call_updatePF = useAPI(updatePF);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addPF.request(values);
    }
    if (type === "edit") {
      call_updatePF.request(values);
    }
  };

  useEffect(() => {
    if (call_addPF.status === "suc") {
      message.success(call_addPF.msg);
      set_s_isShowModal(false);
    } else if (call_addPF.status === "err") {
      message.error(t(`error.error-code.${call_addPF.msg}`));
    }
  }, [call_addPF.status]);

  useEffect(() => {
    if (call_updatePF.status === "suc") {
      message.success(call_updatePF.msg);
      set_s_isShowModal(false);
    } else if (call_updatePF.status === "err") {
      message.error(t(`error.error-code.${call_updatePF.msg}`));
    }
  }, [call_updatePF.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="NO" hidden>
          <InputNumber />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        {/* "製程ID" */}
        <Col span={8}>
          <Form.Item label={t("ADM.util.pfID")} name="pfID" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "製程名稱" */}
          <Form.Item label={t("util.util.name")} name="pfNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*  */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid" initialValue={true}>
            <Radio.Group name="isvalid">
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
