import { Button, Col, Form, Input, Row, Space, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { addMnsFunGp, updateMnsFunGp } from "@/service/apis/MNS/notificationSetting";

export default function ModalConintaer({ s_editData, set_s_isShowModal, set_s_editData }) {
  const [form] = Form.useForm();

  const call_addMnsFunGp = useAPI(addMnsFunGp);
  const call_updateMnsFunGp = useAPI(updateMnsFunGp);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addMnsFunGp.request(values);
    }
    if (type === "edit") {
      call_updateMnsFunGp.request(values);
    }
  };

  useEffect(() => {
    if (call_addMnsFunGp.status === "suc") {
      message.success(call_addMnsFunGp.msg);
      set_s_isShowModal(false);
    } else if (call_addMnsFunGp.status === "err") {
      message.error(call_addMnsFunGp.msg);
    }
  }, [call_addMnsFunGp.status]);

  useEffect(() => {
    if (call_updateMnsFunGp.status === "suc") {
      message.success(call_updateMnsFunGp.msg);
      set_s_isShowModal(false);
    } else if (call_updateMnsFunGp.status === "err") {
      message.error(call_updateMnsFunGp.msg);
    }
  }, [call_updateMnsFunGp.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={12}>
          {/*組織功能碼*/}
          <Form.Item
            label={t("ADM.function-params-manage.funcode")}
            name="funcode"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/*組織功能名稱*/}
          <Form.Item
            label={t("ADM.function-params-manage.funNM")}
            name="funNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
