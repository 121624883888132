export const CARD_DATA = [
    {
      plantID: "",
      singleIP: "192.168.0.101",
      weID: "E01",
      weNM: "Lift : L-2S-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_001",
      version: 1,
      wiID: "L-2S",
      pic: "",
      note: "發送委外",
      updtT: "2022-08-26T14:04:05+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.102",
      weID: "E02",
      weNM: "Tilt : T-FQ-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_002",
      version: 2,
      wiID: "T-FQ",
      pic: "",
      note: "發送委外",
      updtT: "2022-08-26T14:04:19+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.103",
      weID: "E03",
      weNM: "Tilt : T-FQ-02",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_003",
      version: 3,
      wiID: "T-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:04:30+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "21739857-130e-4549-8a04-6f0df98d1cb3",
        woN: "NB2208230175W",
        UUID: "f7ed77ab-1f0d-4a32-9929-9be746310639",
        weID: "E03",
        wsID: "WS01",
        wlID: "BB16",
        linemix: 2,
        SL: 19,
        USL: 20,
        LSL: 18,
        PN: "SAS.0225702.1EGN",
        pdtNM: "Dell CY21 Aegis Isosceles_24 Hinge R2RF9(出海外)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.104",
      weID: "E04",
      weNM: "Tilt : T-FQ-03",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_004",
      version: 4,
      wiID: "T-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:04:34+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "445a7e93-f766-435c-a870-d415bd691ac7",
        woN: "NB2208230175W",
        UUID: "f7ed77ab-1f0d-4a32-9929-9be746310639",
        weID: "E04",
        wsID: "WS01",
        wlID: "BB16",
        linemix: 2,
        SL: 19,
        USL: 20,
        LSL: 18,
        PN: "SAS.0225702.1EGN",
        pdtNM: "Dell CY21 Aegis Isosceles_24 Hinge R2RF9(出海外)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.105",
      weID: "E05",
      weNM: "Pivot : P-FQ-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_005",
      version: 5,
      wiID: "P-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T13:52:47+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.106",
      weID: "E06",
      weNM: "Pivot : P-FQ-02",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_006",
      version: 6,
      wiID: "P-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:04:40+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "dab9ed1f-f387-4de6-9c39-df050beed096",
        woN: "NB2208170282D",
        UUID: "3aeb5cdf-91b1-422f-afcb-e2d46937e4d5",
        weID: "E06",
        wsID: "WS01",
        wlID: "BB06",
        linemix: 1,
        SL: 33.5,
        USL: 35,
        LSL: 32,
        PN: "SAS.0131502.1BGN",
        pdtNM: "Dell P2422HE Column (cost saving)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.107",
      weID: "E07",
      weNM: "Pivot : P-FQ-03",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_007",
      version: 7,
      wiID: "P-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:04:43+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.108",
      weID: "E08",
      weNM: "Pivot : P-2S-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "",
      version: 0,
      wiID: "P-2S",
      pic: "",
      note: "",
      updtT: "2022-09-07T13:48:14+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "70ccde21-8ffd-4059-94f3-4da145d6f438",
        woN: "NB2208230372D",
        UUID: "704a6f66-0c50-4910-b732-b9950f1434d2",
        weID: "E08",
        wsID: "WS01",
        wlID: "BB08",
        linemix: 1,
        SL: 27.5,
        USL: 29,
        LSL: 26,
        PN: "SAS.0131602.11GN",
        pdtNM: "Dell P2722H Column",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.109",
      weID: "E09",
      weNM: "Pivot : P-2S-02",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_009",
      version: 9,
      wiID: "P-2S",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:04:50+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
        woN: "NB2403210372D",
        UUID: "53f35da4-0f23-4a07-9200-c808030f3a07",
        weID: "E09",
        wsID: "WS01",
        wlID: "BB07",
        linemix: 1,
        SL: 20.5,
        USL: 22,
        LSL: 19,
        PN: "SAS.0141202.11GN",
        pdtNM: "Dell P2225H Column 6E.66301.001(F78XN)",
      },
      spcData: [
        {
          SN: 421315,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:11:23+08:00",
          average: 23.252,
          averageCL: 23.195,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.841,
          range: 2,
          rangeCL: 1.691,
          rangeUCL: 3.576,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:11:30+08:00",
        },
        {
          SN: 421317,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:12:20+08:00",
          average: 23.142,
          averageCL: 23.195,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.81,
          range: 1.96,
          rangeCL: 1.692,
          rangeUCL: 3.578,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:12:23+08:00",
        },
        {
          SN: 421319,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:13:15+08:00",
          average: 23.214,
          averageCL: 23.195,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.772,
          range: 1.82,
          rangeCL: 1.692,
          rangeUCL: 3.579,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:13:21+08:00",
        },
        {
          SN: 421321,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:14:18+08:00",
          average: 22.984,
          averageCL: 23.194,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.855,
          range: 2,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:14:24+08:00",
        },
        {
          SN: 421323,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:15:20+08:00",
          average: 23.31,
          averageCL: 23.194,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.602,
          range: 1.48,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:15:27+08:00",
        },
        {
          SN: 421325,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:16:19+08:00",
          average: 22.918,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.684,
          range: 1.98,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:16:20+08:00",
        },
        {
          SN: 421327,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:17:11+08:00",
          average: 23.28,
          averageCL: 23.194,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.533,
          range: 1.37,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:17:12+08:00",
        },
        {
          SN: 421329,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:18:18+08:00",
          average: 22.794,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.619,
          range: 1.34,
          rangeCL: 1.691,
          rangeUCL: 3.577,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:18:21+08:00",
        },
        {
          SN: 421331,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:19:10+08:00",
          average: 22.976,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.864,
          range: 2,
          rangeCL: 1.692,
          rangeUCL: 3.579,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:19:14+08:00",
        },
        {
          SN: 421333,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:20:12+08:00",
          average: 23.308,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.824,
          range: 1.94,
          rangeCL: 1.693,
          rangeUCL: 3.581,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:20:17+08:00",
        },
        {
          SN: 421335,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:21:08+08:00",
          average: 23.252,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.695,
          range: 1.55,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:21:10+08:00",
        },
        {
          SN: 421337,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:21:54+08:00",
          average: 23.012,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.693,
          range: 1.73,
          rangeCL: 1.693,
          rangeUCL: 3.581,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:22:03+08:00",
        },
        {
          SN: 421339,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:22:59+08:00",
          average: 23.23,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.618,
          range: 1.58,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:23:01+08:00",
        },
        {
          SN: 421340,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:23:55+08:00",
          average: 23.4,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.74,
          range: 1.64,
          rangeCL: 1.692,
          rangeUCL: 3.579,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:24:04+08:00",
        },
        {
          SN: 421342,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:24:37+08:00",
          average: 22.932,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.706,
          range: 2,
          rangeCL: 1.693,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:24:47+08:00",
        },
        {
          SN: 421344,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:25:34+08:00",
          average: 23.272,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.658,
          range: 1.97,
          rangeCL: 1.694,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:25:40+08:00",
        },
        {
          SN: 421346,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:26:39+08:00",
          average: 23.292,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.605,
          range: 1.79,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:26:42+08:00",
        },
        {
          SN: 421348,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:27:26+08:00",
          average: 23.128,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.863,
          range: 2,
          rangeCL: 1.696,
          rangeUCL: 3.587,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:27:35+08:00",
        },
        {
          SN: 421350,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:28:24+08:00",
          average: 23.516,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.465,
          range: 1.25,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:28:32+08:00",
        },
        {
          SN: 421352,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:29:22+08:00",
          average: 23.182,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.628,
          range: 1.56,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:29:25+08:00",
        },
        {
          SN: 421354,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:30:28+08:00",
          average: 23.154,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.423,
          range: 1.22,
          rangeCL: 1.692,
          rangeUCL: 3.579,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:30:38+08:00",
        },
        {
          SN: 421356,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:31:23+08:00",
          average: 23.288,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.767,
          range: 2,
          rangeCL: 1.693,
          rangeUCL: 3.581,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:31:31+08:00",
        },
        {
          SN: 421357,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:32:17+08:00",
          average: 22.908,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.519,
          range: 1.59,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:32:24+08:00",
        },
        {
          SN: 421358,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:33:04+08:00",
          average: 23.232,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.734,
          range: 1.91,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:33:12+08:00",
        },
        {
          SN: 421360,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:34:14+08:00",
          average: 23.346,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.781,
          range: 1.81,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:34:15+08:00",
        },
        {
          SN: 421362,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:35:10+08:00",
          average: 23.29,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.774,
          range: 1.84,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:35:18+08:00",
        },
        {
          SN: 421363,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:36:06+08:00",
          average: 23.244,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.773,
          range: 1.79,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:36:11+08:00",
        },
        {
          SN: 421365,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:36:55+08:00",
          average: 23.018,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.665,
          range: 1.61,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:37:04+08:00",
        },
        {
          SN: 421368,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:38:58+08:00",
          average: 23.226,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.688,
          range: 2,
          rangeCL: 1.696,
          rangeUCL: 3.586,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:39:03+08:00",
        },
        {
          SN: 421370,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:40:09+08:00",
          average: 23.138,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.74,
          range: 2,
          rangeCL: 1.697,
          rangeUCL: 3.588,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:40:16+08:00",
        },
        {
          SN: 421373,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:41:29+08:00",
          average: 22.812,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.621,
          range: 1.38,
          rangeCL: 1.696,
          rangeUCL: 3.586,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:41:29+08:00",
        },
        {
          SN: 421375,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:42:37+08:00",
          average: 23.282,
          averageCL: 23.192,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.807,
          range: 2,
          rangeCL: 1.697,
          rangeUCL: 3.588,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:42:41+08:00",
        },
        {
          SN: 421377,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:43:41+08:00",
          average: 22.822,
          averageCL: 23.19,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.731,
          range: 2,
          rangeCL: 1.698,
          rangeUCL: 3.59,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:43:44+08:00",
        },
        {
          SN: 421378,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:44:26+08:00",
          average: 24,
          averageCL: 23.193,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0,
          range: 0,
          rangeCL: 1.692,
          rangeUCL: 3.578,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:44:27+08:00",
        },
        {
          SN: 421380,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:45:47+08:00",
          average: 22.746,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.792,
          range: 1.99,
          rangeCL: 1.693,
          rangeUCL: 3.581,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:45:50+08:00",
        },
        {
          SN: 421382,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:46:52+08:00",
          average: 22.918,
          averageCL: 23.191,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.771,
          range: 2,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:46:53+08:00",
        },
        {
          SN: 421384,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:47:40+08:00",
          average: 22.814,
          averageCL: 23.189,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.56,
          range: 1.35,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:47:41+08:00",
        },
        {
          SN: 421385,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:48:34+08:00",
          average: 22.712,
          averageCL: 23.188,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.727,
          range: 2,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:48:34+08:00",
        },
        {
          SN: 421388,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:49:32+08:00",
          average: 23.398,
          averageCL: 23.188,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.61,
          range: 1.51,
          rangeCL: 1.693,
          rangeUCL: 3.581,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:49:37+08:00",
        },
        {
          SN: 421389,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:50:20+08:00",
          average: 22.788,
          averageCL: 23.187,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.833,
          range: 1.97,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:50:20+08:00",
        },
        {
          SN: 421391,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:51:21+08:00",
          average: 23.216,
          averageCL: 23.187,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.674,
          range: 1.87,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:51:23+08:00",
        },
        {
          SN: 421393,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:52:09+08:00",
          average: 22.996,
          averageCL: 23.187,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.628,
          range: 1.8,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:52:16+08:00",
        },
        {
          SN: 421395,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:53:16+08:00",
          average: 22.694,
          averageCL: 23.185,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.37,
          range: 1.03,
          rangeCL: 1.693,
          rangeUCL: 3.58,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:53:23+08:00",
        },
        {
          SN: 421397,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:54:42+08:00",
          average: 23.114,
          averageCL: 23.185,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.923,
          range: 2,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:54:46+08:00",
        },
        {
          SN: 421400,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:55:42+08:00",
          average: 23.158,
          averageCL: 23.185,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.706,
          range: 1.69,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:55:49+08:00",
        },
        {
          SN: 421401,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:56:36+08:00",
          average: 22.734,
          averageCL: 23.183,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.673,
          range: 2,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:56:42+08:00",
        },
        {
          SN: 421404,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:57:35+08:00",
          average: 22.886,
          averageCL: 23.182,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.649,
          range: 1.51,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:57:42+08:00",
        },
        {
          SN: 421405,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:58:37+08:00",
          average: 23.252,
          averageCL: 23.182,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.562,
          range: 1.52,
          rangeCL: 1.694,
          rangeUCL: 3.582,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:58:45+08:00",
        },
        {
          SN: 421407,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:59:30+08:00",
          average: 23.246,
          averageCL: 23.183,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.726,
          range: 1.76,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:59:38+08:00",
        },
        {
          SN: 421409,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:00:40+08:00",
          average: 22.708,
          averageCL: 23.181,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.673,
          range: 1.96,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:00:41+08:00",
        },
        {
          SN: 421411,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:01:33+08:00",
          average: 23.18,
          averageCL: 23.181,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.785,
          range: 1.79,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:01:34+08:00",
        },
        {
          SN: 421412,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:02:42+08:00",
          average: 22.984,
          averageCL: 23.181,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.517,
          range: 1.4,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:02:43+08:00",
        },
        {
          SN: 421414,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:03:37+08:00",
          average: 23.05,
          averageCL: 23.18,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.723,
          range: 2,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:03:45+08:00",
        },
        {
          SN: 421416,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:04:38+08:00",
          average: 22.886,
          averageCL: 23.179,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.913,
          range: 1.99,
          rangeCL: 1.696,
          rangeUCL: 3.587,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:04:38+08:00",
        },
        {
          SN: 421418,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:05:29+08:00",
          average: 23.384,
          averageCL: 23.18,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.441,
          range: 1.32,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:05:31+08:00",
        },
        {
          SN: 421419,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:06:26+08:00",
          average: 23.004,
          averageCL: 23.179,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.731,
          range: 2,
          rangeCL: 1.696,
          rangeUCL: 3.587,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:06:35+08:00",
        },
        {
          SN: 421421,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:07:14+08:00",
          average: 23.536,
          averageCL: 23.18,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.586,
          range: 1.42,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:07:17+08:00",
        },
        {
          SN: 421423,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:08:29+08:00",
          average: 23.142,
          averageCL: 23.18,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.589,
          range: 1.43,
          rangeCL: 1.694,
          rangeUCL: 3.583,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:08:35+08:00",
        },
        {
          SN: 421425,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:09:43+08:00",
          average: 23.332,
          averageCL: 23.181,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.833,
          range: 1.92,
          rangeCL: 1.695,
          rangeUCL: 3.585,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:09:49+08:00",
        },
        {
          SN: 421427,
          plantID: "",
          spcUUID: "7db0d8f6-86fe-4cf4-a108-8246fb4ffc65",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:10:39+08:00",
          average: 23.04,
          averageCL: 23.18,
          averageUCL: 25,
          averageLCL: 20,
          standard: 0.751,
          range: 1.61,
          rangeCL: 1.695,
          rangeUCL: 3.584,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:10:42+08:00",
        },
      ],
    },
    {
      plantID: "",
      singleIP: "192.168.0.110",
      weID: "E10",
      weNM: "Swivel : S-FQ-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_010",
      version: 10,
      wiID: "S-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:01+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "dc46205e-46d9-42e8-a437-f72a05b296db",
        woN: "NB2208170283D",
        UUID: "efe80553-eacf-4379-a6cd-2a43f80ed192",
        weID: "E10",
        wsID: "WS01",
        wlID: "BB06",
        linemix: 2,
        SL: 24,
        USL: 26,
        LSL: 22,
        PN: "SAS.0131502.15GN",
        pdtNM: "Dell P2422HE Base (cost down)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.111",
      weID: "E11",
      weNM: "Swivel : S-FQ-02",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_011",
      version: 11,
      wiID: "S-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:05+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.112",
      weID: "E12",
      weNM: "Swivel : S-FQ-03",
      psID: "PS01",
      wsID: "",
      wlID: "BB01",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_012",
      version: 12,
      wiID: "S-FQ",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:08+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "",
        woN: "",
        UUID: "",
        weID: "",
        wsID: "",
        wlID: "",
        linemix: 0,
        SL: 0,
        USL: 0,
        LSL: 0,
        PN: "",
        pdtNM: "",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.113",
      weID: "E13",
      weNM: "Swivel : S-2S-01",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_013",
      version: 13,
      wiID: "S-2S",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:13+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "be6b9a27-e043-427d-bf30-784a58851c04",
        woN: "NB2208240379D",
        UUID: "0cb1f8ad-a41a-40d6-9da6-98cf8ae4e7d2",
        weID: "E13",
        wsID: "WS01",
        wlID: "BB06",
        linemix: 2,
        SL: 23,
        USL: 26,
        LSL: 20,
        PN: "SAS.0127902.14GN",
        pdtNM: "Dell S2721DS Base(水性漆)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.114",
      weID: "E14",
      weNM: "Swivel : S-2S-02",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_014",
      version: 14,
      wiID: "S-2S",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:16+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "af7b2f95-1ffe-44e3-b9e3-936c7d71a676",
        woN: "NB2208220323D",
        UUID: "88ff5ada-b935-42e5-89d5-02f01abaad73",
        weID: "E14",
        wsID: "WS01",
        wlID: "BB08",
        linemix: 2,
        SL: 23,
        USL: 26,
        LSL: 20,
        PN: "SAS.0131402.1BGN",
        pdtNM: "Dell P2222H Base(出SKD)(cost saving)",
      },
      spcData: [],
    },
    {
      plantID: "",
      singleIP: "192.168.0.115",
      weID: "E15",
      weNM: "Swivel : S-2S-03",
      psID: "",
      wsID: "",
      wlID: "",
      linemix: 0,
      lifeF: "",
      sipID: "SOP_A14053G0-000N_WS01_015",
      version: 15,
      wiID: "S-2S",
      pic: "",
      note: "",
      updtT: "2022-08-26T14:05:24+08:00",
      transF: "1",
      spcInfo: {
        plantID: "",
        spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
        woN: "NB2403210373D",
        UUID: "0e1a6ecf-4f89-4812-927c-c9edfc7becfb",
        weID: "E15",
        wsID: "WS01",
        wlID: "BB07",
        linemix: 2,
        SL: 18,
        USL: 21,
        LSL: 15,
        PN: "SAS.0141202.12GN",
        pdtNM: "Dell P2225H Base 6E.66302.001(R4XGX)",
      },
      spcData: [
        {
          SN: 421314,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:11:03+08:00",
          average: 18.694,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.343,
          range: 0.73,
          rangeCL: 0.935,
          rangeUCL: 1.978,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:11:11+08:00",
        },
        {
          SN: 421316,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:11:52+08:00",
          average: 18.424,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.438,
          range: 1.05,
          rangeCL: 0.936,
          rangeUCL: 1.98,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:11:53+08:00",
        },
        {
          SN: 421318,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:12:41+08:00",
          average: 18.084,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.357,
          range: 0.93,
          rangeCL: 0.936,
          rangeUCL: 1.979,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:12:45+08:00",
        },
        {
          SN: 421320,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:13:58+08:00",
          average: 18.426,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.488,
          range: 1.2,
          rangeCL: 0.937,
          rangeUCL: 1.982,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:13:58+08:00",
        },
        {
          SN: 421322,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:15:00+08:00",
          average: 18.212,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.417,
          range: 1.17,
          rangeCL: 0.938,
          rangeUCL: 1.984,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:15:08+08:00",
        },
        {
          SN: 421324,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:15:50+08:00",
          average: 18.636,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.473,
          range: 1.16,
          rangeCL: 0.939,
          rangeUCL: 1.986,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:15:50+08:00",
        },
        {
          SN: 421326,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:16:47+08:00",
          average: 18.334,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.444,
          range: 1.05,
          rangeCL: 0.939,
          rangeUCL: 1.987,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:16:53+08:00",
        },
        {
          SN: 421328,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:17:48+08:00",
          average: 18.18,
          averageCL: 18.44,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.438,
          range: 1.2,
          rangeCL: 0.941,
          rangeUCL: 1.989,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:17:55+08:00",
        },
        {
          SN: 421330,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:18:41+08:00",
          average: 18.642,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.334,
          range: 0.84,
          rangeCL: 0.94,
          rangeUCL: 1.988,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:18:48+08:00",
        },
        {
          SN: 421332,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:19:34+08:00",
          average: 18.4,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.382,
          range: 1.2,
          rangeCL: 0.941,
          rangeUCL: 1.991,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:19:37+08:00",
        },
        {
          SN: 421334,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:20:30+08:00",
          average: 18.326,
          averageCL: 18.44,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.302,
          range: 0.68,
          rangeCL: 0.94,
          rangeUCL: 1.988,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:20:39+08:00",
        },
        {
          SN: 421336,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:21:27+08:00",
          average: 18.37,
          averageCL: 18.44,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.415,
          range: 1.15,
          rangeCL: 0.941,
          rangeUCL: 1.99,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:21:32+08:00",
        },
        {
          SN: 421338,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:22:35+08:00",
          average: 18.636,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.396,
          range: 1.12,
          rangeCL: 0.942,
          rangeUCL: 1.992,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:22:35+08:00",
        },
        {
          SN: 421341,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:24:07+08:00",
          average: 18.29,
          averageCL: 18.44,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.58,
          range: 1.2,
          rangeCL: 0.943,
          rangeUCL: 1.994,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:24:10+08:00",
        },
        {
          SN: 421343,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:25:01+08:00",
          average: 18.528,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.446,
          range: 1.2,
          rangeCL: 0.944,
          rangeUCL: 1.996,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:25:08+08:00",
        },
        {
          SN: 421345,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:25:52+08:00",
          average: 18.592,
          averageCL: 18.441,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.419,
          range: 1.1,
          rangeCL: 0.945,
          rangeUCL: 1.998,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:26:01+08:00",
        },
        {
          SN: 421347,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:26:49+08:00",
          average: 18.764,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.245,
          range: 0.63,
          rangeCL: 0.943,
          rangeUCL: 1.995,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:26:53+08:00",
        },
        {
          SN: 421349,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:27:47+08:00",
          average: 18.746,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.425,
          range: 1.1,
          rangeCL: 0.944,
          rangeUCL: 1.996,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:27:56+08:00",
        },
        {
          SN: 421351,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:28:44+08:00",
          average: 18.572,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.524,
          range: 1.08,
          rangeCL: 0.944,
          rangeUCL: 1.998,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:28:49+08:00",
        },
        {
          SN: 421353,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:29:40+08:00",
          average: 18.464,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.371,
          range: 1.04,
          rangeCL: 0.945,
          rangeUCL: 1.998,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:29:48+08:00",
        },
        {
          SN: 421355,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:31:12+08:00",
          average: 18.22,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.396,
          range: 0.95,
          rangeCL: 0.945,
          rangeUCL: 1.998,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:31:22+08:00",
        },
        {
          SN: 421359,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:34:03+08:00",
          average: 18.506,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.48,
          range: 1.09,
          rangeCL: 0.945,
          rangeUCL: 2,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:34:08+08:00",
        },
        {
          SN: 421361,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:35:07+08:00",
          average: 18.598,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.231,
          range: 0.7,
          rangeCL: 0.944,
          rangeUCL: 1.998,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:35:09+08:00",
        },
        {
          SN: 421364,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:36:20+08:00",
          average: 18.234,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.445,
          range: 1.2,
          rangeCL: 0.945,
          rangeUCL: 2,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:36:22+08:00",
        },
        {
          SN: 421366,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:37:26+08:00",
          average: 18.33,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.344,
          range: 0.95,
          rangeCL: 0.945,
          rangeUCL: 2,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:37:35+08:00",
        },
        {
          SN: 421367,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:38:22+08:00",
          average: 18.27,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.303,
          range: 0.94,
          rangeCL: 0.945,
          rangeUCL: 2,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:38:28+08:00",
        },
        {
          SN: 421369,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:39:19+08:00",
          average: 18.83,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.249,
          range: 0.64,
          rangeCL: 0.944,
          rangeUCL: 1.997,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:39:20+08:00",
        },
        {
          SN: 421371,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:40:26+08:00",
          average: 18.216,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.375,
          range: 0.97,
          rangeCL: 0.944,
          rangeUCL: 1.997,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:40:30+08:00",
        },
        {
          SN: 421372,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:41:20+08:00",
          average: 18.594,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.436,
          range: 1.2,
          rangeCL: 0.945,
          rangeUCL: 1.999,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:41:22+08:00",
        },
        {
          SN: 421374,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:42:15+08:00",
          average: 18.318,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.393,
          range: 1.2,
          rangeCL: 0.946,
          rangeUCL: 2.002,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:42:15+08:00",
        },
        {
          SN: 421376,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:43:30+08:00",
          average: 18.39,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.366,
          range: 1.15,
          rangeCL: 0.947,
          rangeUCL: 2.003,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:43:38+08:00",
        },
        {
          SN: 421379,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:44:52+08:00",
          average: 18.442,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.414,
          range: 1.09,
          rangeCL: 0.948,
          rangeUCL: 2.004,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:44:59+08:00",
        },
        {
          SN: 421381,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:46:34+08:00",
          average: 18.196,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.394,
          range: 1.07,
          rangeCL: 0.948,
          rangeUCL: 2.005,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:46:43+08:00",
        },
        {
          SN: 421383,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:47:36+08:00",
          average: 18.58,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.435,
          range: 1.2,
          rangeCL: 0.949,
          rangeUCL: 2.007,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:47:36+08:00",
        },
        {
          SN: 421386,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:48:30+08:00",
          average: 18.482,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.386,
          range: 1.2,
          rangeCL: 0.95,
          rangeUCL: 2.009,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:48:39+08:00",
        },
        {
          SN: 421387,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:49:21+08:00",
          average: 18.362,
          averageCL: 18.442,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.333,
          range: 0.83,
          rangeCL: 0.95,
          rangeUCL: 2.008,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:49:22+08:00",
        },
        {
          SN: 421390,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:50:50+08:00",
          average: 18.544,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.509,
          range: 1.2,
          rangeCL: 0.951,
          rangeUCL: 2.01,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:50:52+08:00",
        },
        {
          SN: 421392,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:51:40+08:00",
          average: 18.706,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.322,
          range: 0.88,
          rangeCL: 0.95,
          rangeUCL: 2.01,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:51:44+08:00",
        },
        {
          SN: 421394,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:52:48+08:00",
          average: 18.412,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.477,
          range: 1.2,
          rangeCL: 0.951,
          rangeUCL: 2.012,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:52:57+08:00",
        },
        {
          SN: 421396,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:53:45+08:00",
          average: 18.256,
          averageCL: 18.443,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.403,
          range: 1.16,
          rangeCL: 0.952,
          rangeUCL: 2.014,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:53:50+08:00",
        },
        {
          SN: 421398,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:54:40+08:00",
          average: 18.61,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.369,
          range: 0.99,
          rangeCL: 0.952,
          rangeUCL: 2.014,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:54:50+08:00",
        },
        {
          SN: 421399,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:55:41+08:00",
          average: 18.442,
          averageCL: 18.444,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.386,
          range: 1.16,
          rangeCL: 0.953,
          rangeUCL: 2.015,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:55:43+08:00",
        },
        {
          SN: 421402,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:56:40+08:00",
          average: 18.724,
          averageCL: 18.445,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.43,
          range: 1.11,
          rangeCL: 0.954,
          rangeUCL: 2.017,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:56:45+08:00",
        },
        {
          SN: 421403,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:57:38+08:00",
          average: 18.518,
          averageCL: 18.445,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.408,
          range: 1,
          rangeCL: 0.954,
          rangeUCL: 2.017,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:57:38+08:00",
        },
        {
          SN: 421406,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:58:42+08:00",
          average: 18.682,
          averageCL: 18.446,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.237,
          range: 0.71,
          rangeCL: 0.953,
          rangeUCL: 2.015,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:58:51+08:00",
        },
        {
          SN: 421408,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T13:59:35+08:00",
          average: 18.162,
          averageCL: 18.445,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.184,
          range: 0.5,
          rangeCL: 0.951,
          rangeUCL: 2.012,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T13:59:40+08:00",
        },
        {
          SN: 421410,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:00:43+08:00",
          average: 18.76,
          averageCL: 18.446,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.48,
          range: 1.2,
          rangeCL: 0.952,
          rangeUCL: 2.014,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:00:53+08:00",
        },
        {
          SN: 421413,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:02:54+08:00",
          average: 18.654,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.445,
          range: 1.08,
          rangeCL: 0.952,
          rangeUCL: 2.015,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:02:58+08:00",
        },
        {
          SN: 421415,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:04:15+08:00",
          average: 18.494,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.457,
          range: 1.19,
          rangeCL: 0.953,
          rangeUCL: 2.016,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:04:22+08:00",
        },
        {
          SN: 421417,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:05:19+08:00",
          average: 18.35,
          averageCL: 18.446,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.314,
          range: 0.89,
          rangeCL: 0.953,
          rangeUCL: 2.016,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:05:22+08:00",
        },
        {
          SN: 421420,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:07:05+08:00",
          average: 18.722,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.367,
          range: 0.91,
          rangeCL: 0.953,
          rangeUCL: 2.016,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:07:06+08:00",
        },
        {
          SN: 421422,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:08:25+08:00",
          average: 18.242,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.411,
          range: 1.2,
          rangeCL: 0.954,
          rangeUCL: 2.017,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:08:30+08:00",
        },
        {
          SN: 421424,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:09:31+08:00",
          average: 18.668,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.398,
          range: 1.1,
          rangeCL: 0.954,
          rangeUCL: 2.019,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:09:32+08:00",
        },
        {
          SN: 421426,
          plantID: "",
          spcUUID: "cc631822-ca7c-4d4b-a49b-5730a0fb0915",
          runtype: "",
          samplesize: 5,
          pickT: "2024-03-22T14:10:25+08:00",
          average: 18.222,
          averageCL: 18.447,
          averageUCL: 20,
          averageLCL: 16,
          standard: 0.42,
          range: 1.2,
          rangeCL: 0.955,
          rangeUCL: 2.02,
          rangeLCL: 0,
          mask: false,
          pcount: 0,
          createT: "2024-03-22T14:10:32+08:00",
        },
      ],
    },
  ];