import { Button, Col, Divider, Form, Input, Radio, Row, Select, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// util component
// api
import useAPI from "@/hooks/useAPI";
import { addInspreport, updateInspreport } from "@/service/apis/QMS/Inspreport";
import { getEqgp } from "@/service/apis/QMS/eqgp";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  // const f_assetkind = Form.useWatch("assetkind", form);

  const [s_eq, set_s_eq] = useState([]);
  // 新增表頭資料
  const call_addInspreport = useAPI(addInspreport);
  // 編輯表頭資料
  const call_updateInspreport = useAPI(updateInspreport);

  const call_getEqgp = useAPI(getEqgp);

  const onSubmit = (values) => {
    if (type === "create") {
      call_addInspreport.request(values);
    }
    if (type === "edit") {
      call_updateInspreport.request(values);
    }
  };

  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addInspreport;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addInspreport.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateInspreport;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateInspreport.status]);

  useEffect(() => {}, []);

  // useEffect(() => {
  //   if (call_getEqgp.status === "suc") {
  //     const options =
  //       call_getEqgp.data?.map((item, index) => ({
  //         label: `${item.gpID}_${item.gpNM}`,
  //         value: `${item.gpID}`,
  //       })) || [];
  //     console.log(options);
  //     set_s_eq([...options]);
  //   }
  //   if (call_getEqgp.status === "err") {
  //     message.error(call_getEqgp.msg);
  //     set_s_eq([]);
  //   }
  // }, [call_getEqgp.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        insptype: "measure",
        ...s_editData,
      }}
      onFinish={onSubmit}
    >
      <div>
        <Form.Item label="" name="createT" hidden>
          <Input disabled />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="表單ID" name="reportID">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="表單名稱" name="reportNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 是否有效 */}
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* <Form.Item label={t("ADM.production-line-manage.wlID")} name="wlID"> */}
          <Form.Item label={"限用分類"} name="usecata">
            <Input
              disabled
              onChange={() =>
                form.setFieldsValue({ wlID: null, pfID: null, wsID: null, pwcID: null })
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* <Form.Item label={t("ADM.util.pfID")} name="pfID"> */}
          <Form.Item label={"限用製程"} name="pfID">
            <Select
              disabled
              // disabled={form.getFieldValue("ctrlspot") === "wp" || type === "edit"}
              className="w-full"
              allowClear
              onChange={() =>
                form.setFieldsValue({ usecata: null, wsID: null, wlID: null, pwcID: null })
              }
              options={s_arrMap["pf"]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* <Form.Item label={t("util.util.ws")} name="wsID"> */}
          <Form.Item label={"限用工站"} name="wsID">
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="label"
              options={s_arrMap["ws"]}
              onChange={() =>
                form.setFieldsValue({ usecata: null, pfID: null, wlID: null, pwcID: null })
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* <Form.Item label={t("util.util.pwcID")} name="pwc"> */}
          <Form.Item label={"限用工作中心"} name="pwcID">
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="label"
              options={s_arrMap["pwc"]}
              onChange={() =>
                form.setFieldsValue({ usecata: null, pfID: null, wlID: null, wsID: null })
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* <Form.Item label={t("ADM.production-line-manage.wlID")} name="wlID"> */}
          <Form.Item label={"限用機碼"} name="wlID">
            <Select
              disabled
              allowClear
              showSearch
              optionFilterProp="label"
              options={s_arrMap["wl"]}
              onChange={() =>
                form.setFieldsValue({ usecata: null, pfID: null, wsID: null, pwcID: null })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={4}>
          {/* <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} /> */}
        </Col>
        <Col span={20} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
