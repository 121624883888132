import React, { useState, useEffect } from "react";
import { Button, Space, Modal, message, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { getDaycodeHd, deleteDaycodeHd } from "@/service/apis/LMS/LabelManage";
import { IconGarbage, IconPen } from "@/components/Icon/Action";

import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";

export default function LabelDate({ c_lightData, set_c_lightData }) {
  const call_getDaycodeHd = useAPI(getDaycodeHd);
  const call_deleteDaycodeHd = useAPI(deleteDaycodeHd);

  const [s_showModal, set_s_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);

  const tableColumns = [
    { title: "客戶ID", dataIndex: "custID", width: "10%" },
    { title: "客戶名稱", dataIndex: "custNM", width: "10%" },
    { title: "備註", dataIndex: "note" },
    {
      title: "更新時間",
      dataIndex: "astUpdateT",
      align: "center",
      width: "160px",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => {
        return (
          <Space className="font-size-24">
            <IconPen
              onClick={(e) => {
                e.stopPropagation(); // 這裡不要開啟表身
                set_c_lightData(rowData);
                set_s_showModal(true);
              }}
            />
            <IconGarbage onClick={(e) => deleteRow(e, rowData)} />
          </Space>
        );
      },
    },
  ];

  const deleteRow = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteDaycodeHd.request({ ...rowData }),
      cancelText: "取消",
    });
  };

  const search = (e) => {
    set_c_lightData({});
    if (e === "" || !e) {
      set_s_tableData(
        call_getDaycodeHd.data.map((x) => {
          return { ...x, key: x.custID };
        })
      );
    } else {
      // filter key
      set_s_tableData((prev) =>
        prev.filter((x) => x.custID.indexOf(e) !== -1 || x.custNM.indexOf(e) !== -1)
      );
    }
  };

  useEffect(() => {
    call_getDaycodeHd.request();
  }, []);

  useEffect(() => {
    if (call_getDaycodeHd.status === "suc") {
      set_s_tableData(
        call_getDaycodeHd.data.map((x) => {
          return { ...x, key: x.custID };
        })
      );
    } else if (call_getDaycodeHd.status === "err") {
      message.error(call_getDaycodeHd.msg);
      set_s_tableData([]);
    }
  }, [call_getDaycodeHd.status]);

  useEffect(() => {
    if (call_deleteDaycodeHd.status === "suc") {
      call_getDaycodeHd.request();
      set_c_lightData({});
    } else if (call_deleteDaycodeHd.status === "err") {
      message.error(call_deleteDaycodeHd.msg);
    }
  }, [call_deleteDaycodeHd.status]);

  return (
    <>
      <PageHeader
        title="條碼日期管理"
        extra={
          <Space align="end" className="flex justify-end">
            <Input.Search
              size="large"
              style={{ width: "300px" }}
              placeholder="請輸入客戶ID或客戶名稱"
              enterButton
              onSearch={search}
            />
            <Button
              type="primary"
              size="large"
              onClick={() => {
                set_c_lightData({});
                set_s_showModal(true);
              }}
            >
              新增
            </Button>
          </Space>
        }
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        loading={call_getDaycodeHd.status === "load"}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      <CustomModal
        width="60%"
        title={Object.keys(c_lightData).length > 0 ? "編輯日期資料" : "新建日期資料"}
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getDaycodeHd.request();
        }}
      >
        {s_showModal && (
          <ModalContainer set_s_showModal={set_s_showModal} s_editData={c_lightData} />
        )}
      </CustomModal>
    </>
  );
}
