/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { message, Grid, Space } from "antd";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util
import { multipleIDtoName } from "@/util/format";
import useAPI from "@/hooks/useAPI";
import { getProduct } from "@/service/apis/ADM/pnManage";
import { getMgmtcat } from "@/service/apis/ADM/client";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  // s_arrMap = 把各種ID轉成NM所需要的資料
  const { s_arrMap, s_searchData, set_s_searchData, c_lightData, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const screens = Grid.useBreakpoint();

  const removeData = (rowData) => {};

  const call_getProduct = useAPI(getProduct);
  const call_getMgmtcat71 = useAPI(getMgmtcat);

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      align: "left",
      width: "11%",
    },
    {
      title: t("util.util.pdtspec"),
      dataIndex: "pdtspec",
      align: "left",
      width: "8%",
    },

    {
      title: t("util.util.attribute"),
      dataIndex: "attribute",
      align: "center",
      className: "bg-[#f9e9d7ac]",
      render: (text) => s_arrMap.attributeArr?.find((x) => x.value === text)?.label,
      width: "6%",
    },
    {
      title: t("util.util.pdtclass"),
      dataIndex: "pdtclass",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      render: (text) => s_arrMap.pdtclassArr?.find((x) => x.value === text)?.label,
      width: "8%",
    },
    {
      title: t("util.util.mgmtcat"), // 授權分類
      dataIndex: "mgmtcat",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8%",
      render: (text) => multipleIDtoName(call_getMgmtcat71.data, text, "mgmtitem", "itemNM"),
    },
    {
      title: t("util.util.unit"),
      dataIndex: "unit",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.opunit"),
      dataIndex: "opunit",
      width: "8%",
      align: "center",
    },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => {
      call_getProduct.request({ ...s_searchData, page: 1, pageSize: 200 });
      call_getMgmtcat71.request({ pageType: "71" });
    },
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
    onDealSearch: () => {
      call_getProduct.request(s_searchData);
    },
  }));

  useEffect(() => {
    call_getProduct.request(s_searchData);
    call_getMgmtcat71.request({ pageType: "71" });
  }, [s_searchData]);

  useEffect(() => {
    if (call_getProduct.status === "suc") {
      set_s_pageInfo(call_getProduct.data.pageInfo);
      set_s_tableData(call_getProduct.data.tableData.map((x, i) => ({ ...x, key: i, index: i })));
      message.success(call_getProduct.msg);
    } else if (call_getProduct.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getProduct.msg}`));
    }
  }, [call_getProduct.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getProduct.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
    </>
  );
});

export default HD;
