import { useState, useEffect } from "react";
import { Modal, message, Space, Button, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose, IconFile } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { getMold, deleteMold } from "@/service/apis/ADM/mold";
import { getStation, getPF, getCustHd, getProductSelect } from "@/service/apis/ADM/publicAPI";

import useAPI from "@/hooks/useAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";

import { AddButton } from "@/components/PDS/Buttons";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";
import FileModalContainer from "./ModalContainer/FileModalContainer";
import PageHeader from "@/components/PageHeader/index";
import { multipleIDtoName } from "@/util/format";

const Mold = () => {
  const { t } = useTranslation();
  const [c_lightData, set_c_lightData] = useState({});
  const [s_searchData, set_s_searchData] = useState({
    assetkind: null,
  });
  const call_getMgmtcat75 = useAPI(getMgmtcat);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isShowFileModal, set_s_isShowFileModal] = useState(false);

  const call_getMold = useAPI(getMold);
  const call_deleteMold = useAPI(deleteMold);
  const call_getStation = useAPI(getStation);
  const call_getPF = useAPI(getPF);
  const call_getCustHd = useAPI(getCustHd);
  const call_getProduct = useAPI(getProductSelect);

  const arrMap = {
    assetkind: [
      { label: t("util.util.assetkind_TI"), value: "TI" },
      { label: t("util.util.assetkind_TS"), value: "TS" },
      { label: t("util.util.assetkind_TD"), value: "TD" },
      { label: t("util.util.assetkind_TF"), value: "TF" },
    ],
    property: [
      { label: t("ADM.mold.company"), value: "company" },
      { label: t("util.util.custID"), value: "customer" },
    ],
    pfArr: call_getPF.data,
    wsArr: call_getStation.data,
    custArr: call_getCustHd.data,
    pdtArr: call_getProduct.data,
  };
  const tableColumns = [
    // {
    //   title: t("ADM.mold.assetkind"),
    //   dataIndex: "assetkind",
    //   align: "center",
    //   width: "5%",
    //   render: (text) => arrMap.assetkind?.find((item) => item.value === text)?.label ?? "",
    // },
    {
      title: t("ADM.mold.assetID"),
      dataIndex: "assetID",
    },
    {
      title: t("util.util.name"),
      dataIndex: "assetNM",
    },
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      align: "center",
      width: "14%",
      render: (text) => multipleIDtoName(arrMap.pdtArr, text, "PN"),
    },
    // {
    //   title: t("ADM.util.pfID"),
    //   dataIndex: "pfID",
    //   align: "center",
    //   width: "7%",
    //   render: (text) => arrMap.pfArr?.find((item) => item.pfID === text)?.pfNM ?? "",
    // },
    // {
    //   title: t("ADM.mold.wsID"),
    //   dataIndex: "wsID",
    //   align: "center",
    //   width: "7%",
    //   render: (text) => arrMap.wsArr?.find((item) => item.wsID === text)?.wsNM ?? "",
    // },
    {
      title: t("ADM.mold.moldtype"),
      dataIndex: "moldtype",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.cavity"),
      dataIndex: "cavity",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.stdcavity"),
      dataIndex: "stdcavity",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.validcavity"),
      dataIndex: "validcavity",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.property"),
      dataIndex: "property",
      className: "bg-[#defee9]",
      align: "center",
      width: "5%",
      render: (text) => arrMap.property?.find((item) => item.value === text)?.label ?? "",
    },
    {
      title: t("util.util.custID"),
      dataIndex: "custID",
      className: "bg-[#defee9]",
      width: "9%",
      render: (text) => {
        const _cust = arrMap.custArr?.find((item) => item.custID === text) || "";
        if (_cust === "") return "";
        return `${_cust.custID}_${_cust.custalias}`;
      },
    },
    {
      title: t("ADM.mold.gatesystem"),
      dataIndex: "gatesystem",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.moldlife"), // 設計模次
      dataIndex: "moldlife",
      align: "center",
      width: "5%",
    },
    // {
    //   title: t("ADM.mold.length"),
    //   dataIndex: "length",
    //   align: "center",
    // },
    // {
    //   title: t("ADM.mold.width"),
    //   dataIndex: "width",
    //   align: "center",
    // },
    // {
    //   title: t("ADM.mold.height"),
    //   dataIndex: "height",
    //   align: "center",
    // },
    // {
    //   title: t("ADM.mold.weight"),
    //   dataIndex: "weight",
    //   align: "center",
    // },

    {
      title: t("ADM.mold.openT"), // 取得日期
      dataIndex: "openT",
      width: "6%",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("ADM.mold.openC"), // 開帳模次
      dataIndex: "openC",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.mold.cummoldC"), // 累計模次
      dataIndex: "cummoldC",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "3%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconFile
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowFileModal(true);
              e.stopPropagation();
            }}
          />
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => { call_deleteMold.request(rowData) },
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getStation.request();
    call_getPF.request();
    call_getCustHd.request();
    call_getProduct.request();
    call_getMgmtcat75.request({ pageType: "75" });
  }, []);

  useEffect(() => {
    call_getMold.request(s_searchData);
  }, [s_searchData]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getMold.status === "suc") {
      message.success(call_getMold.msg);

      set_s_tableData(
        call_getMold.data.map((x, i) => ({
          ...x,
          key: x.assetID,
          PN: x.PN.split("|").filter((x) => x !== ""),
          wsID: x.wsID.split("|").filter((x) => x !== ""),
        }))
      );
    } else if (call_getMold.status === "err") {
      message.error(t(`error.error-code.${call_getMold.msg}`));
      set_s_tableData([]);
    }
  }, [call_getMold.status]);

  useEffect(() => {
    if (call_deleteMold.status === "suc") {
      message.success(call_deleteMold.msg);
      // delete之後 重call表格資料
      call_getMold.request(s_searchData);
    } else if (call_deleteMold.status === "err") {
      message.error(call_deleteMold.msg);
    }
  }, [call_deleteMold.status]);

  return (
    <>
      <PageHeader
        title={t("ADM.mold.pageHeader")}
        extra={[
          <Select
            key="s_searchData.assetkind"
            value={s_searchData.assetkind}
            placeholder={t("util.util.placeholder_select") + t("ADM.mold.assetkind")}
            onChange={(value) => handlChange("assetkind", value)}
            className="w-[250px]"
            options={arrMap.assetkind}
            allowClear
          />,
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
          // <Button
          //   type="primary"
          //   key="create"
          //   onClick={() => {
          //     set_c_lightData({});
          //     set_s_isShowModal(true);
          //   }}
          // >
          //   {t("util.util.add")}
          // </Button>,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getMold.status === "load"}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t("ADM.mold.mold")}`
            : `${t("util.util.add")}${t("ADM.mold.mold")}`
        }
        centered
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getMold.request();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={arrMap}
        />
      </CustomModal>

      <CustomModal
        title={t("util.util.fileType_PIC")}
        centered
        width={"60%"}
        open={s_isShowFileModal}
        onCancel={() => set_s_isShowFileModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getMold.request();
        }}
      >
        <FileModalContainer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default Mold;
