import { Button, Col, Row, Tooltip, Typography } from "antd";
import { useState, useEffect, useRef, useContext } from "react";

import styled from "styled-components";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";

import XBarChart from "../../ModalContainer/XBarChart";

import { FullscreenOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
import { getSpcOverview } from "@/service/ws/ws";
import useWebSocket from "@/hooks/useWebSocket";

import Light from "../../Light";
import EazySPC from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/index";
import Analyze from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/Analyze/index";
import { IconAnalyzeII, IconUnion } from "@/components/Icon/Action";
import showDecimalPoint from "../../util/showDecimalPoint";
const { Title } = Typography;

const CardStyle = styled.div`
  width: 100%;
  height: 420px;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.27);
  background-color: #fff;
  .header {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    font-size: 22px;
    background-color: #0d6ad2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .header-light {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header-wl {
    flex: 4;
    height: 80%;
    border-top-left-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0;
  }
  .header-linemix {
    font-size: 18px;
  }
  .header-text {
    flex: 8;
    flex-basis: 10%;
    margin-left: 15px;
    border-top-left-radius: 15px;
    color: white;
  }

  .white-space {
    white-space: nowrap; /*//强制文本在一行内输出 */
    overflow: hidden; /*隐藏溢出部分*/
    text-overflow: ellipsis; /*//對溢出部分加上...*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .header-icon {
    color: white;
    position: relative;
    font-size: 30px;
    cursor: pointer;
    /* margin-top: 10px; */
  }
  .chart {
    /* height: 350px; */
    height: 300px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;

    /* background-color: red */
  }
`;

export default function Card({ data, boardData, s_selectedDrawno }) {
  const chartEle = useRef(null);
  const { s_thisSpcNS, s_DrawnoTableData } = useContext(WpcContext);
  const [s_isModal, set_s_IsModal] = useState(false);
  const [s_isAnalyzeModal, set_s_isAnalyzeModal] = useState(false);
  const defaltChartOption = {
    chart: {
      marginTop: 60,
      reflow: true,
      height: 300,
      // width: 870,
    },

    noData: {
      text: "No data to display",
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    navigator: {
      height: 30, // 調整導覽器元件的高度
    },
    rangeSelector: {
      selected: 0,
      inputEnabled: false,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    time: {
      useUTC: false,
    },

    yAxis: [
      {
        title: {
          text: "X BAR(平均值)",
          align: "high",
          textAlign: "center",
          rotation: 0,
          y: -15,
          offset: -0,
          style: {
            color: "#0E6BD3",
            fontSize: 10,
          },
        },
        labels: {
          align: "center",
          x: 0,
        },
        // min:0,
        // max:100,
        tickAmount: 5,
        // startOnTick: true,
        height: "30%",
        lineWidth: 2,
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
        plotLines: [
          {
            value: 10,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "USL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
          {
            value: 190,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "LSL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
        ],
      },
      {
        labels: {
          align: "center",
          x: -3,
        },
        title: {
          text: "R (全距)",
          align: "high",
          textAlign: "left",
          rotation: 0,
          y: -10,
          offset: -0,
          style: {
            color: "#0E6BD3",
            fontSize: 10,
          },
        },
        // min:0,
        // max:100,
        tickAmount: 5,
        top: "45%",
        height: "30%",
        // startOnTick: false,
        // endOnTick: false,
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        lineWidth: 2,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
      },
    ],

    xAxis: {
      // type: 'datetime',
      min: null,
      max: null,
      // min: lastHour,
      // max: now,
    },

    // tooltip: {
    //   shared: true,
    //   split: true,
    //   valueDecimals: 2,
    // },

    legend: {
      enabled: false,
      align: "right",
      padding: 50,
    },

    series: [
      {
        name: "xbar",
        data: [0],
        marker: {
          radius: 0,
        },
      },
      {
        name: "R",
        data: [0],
        yAxis: 1,
      },
    ],
  };
  const [chartOptions, setChartOptions] = useState(JSON.parse(JSON.stringify(defaltChartOption)));
  useEffect(() => {
    setChartOptions((prev) =>
      JSON.parse(
        JSON.stringify({
          ...prev,

          navigator: {
            enabled: true,
          },
        })
      )
    );
    return;

    // console.log(copyOptions);
  }, [s_thisSpcNS]);

  useEffect(() => {
    if (!boardData) return;
    let xData = [],
      xCL = [],
      xLCL = [],
      xUCL = [],
      USL = [],
      LSL = [],
      rData = [],
      rCL = [],
      rLCL = [],
      rUCL = [];
    for (const item of boardData) {
      // X
      xData.push([new Date(item.measureT).getTime(), item.avg]);
      xCL.push([new Date(item.measureT).getTime(), data.CL]);
      xLCL.push([new Date(item.measureT).getTime(), data.LCL]);
      xUCL.push([new Date(item.measureT).getTime(), data.UCL]);
      USL.push([new Date(item.measureT).getTime(), data.USL]);
      LSL.push([new Date(item.measureT).getTime(), data.LSL]);

      // R
      rData.push([new Date(item.measureT).getTime(), item.range]);
      rCL.push([new Date(item.measureT).getTime(), data.rangeCL]);
      rLCL.push([new Date(item.measureT).getTime(), data.rangeLCL]);
      rUCL.push([new Date(item.measureT).getTime(), data.rangeUCL]);
    }

    let series = [
      {
        name: "X_AVG",
        data: xData,
        lineWidth: 4,
        yAxis: 0,

        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },

        zones: [
          {
            value: data?.LCL,
            color: "#f75c5c",
          },
          {
            value: data?.UCL,
            color: "#a8a8a8",
          },
          {
            color: "#f75c5c",
          },
        ],
      },

      {
        name: "X_CL",
        data: xCL,
        color: "#40ad36",
        yAxis: 0,
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_LCL",
        data: xLCL,
        yAxis: 0,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_UCL",
        data: xUCL,
        yAxis: 0,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "LSL",
        data: LSL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "USL",
        data: USL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_RANGE",
        data: rData,
        lineWidth: 4,
        yAxis: 1,
        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },
      },
      {
        name: "R_CL",
        data: rCL,
        color: "#40ad36",
        yAxis: 1,
        tooltip: {
          pointFormat: "",
        },
      },

      {
        name: "R_LCL",
        data: rLCL,
        yAxis: 1,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_UCL",
        data: rUCL,
        yAxis: 1,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
    ];

    setChartOptions((prev) => {
      prev.series = series;
      prev.yAxis[0].plotLines[0].value = data.USL;
      prev.yAxis[0].plotLines[1].value = data.LSL;
      return { ...prev };
    });
  }, [boardData]);

  return (
    <CardStyle>
      <Row className="">
        <Col span={24} className="header">
          <div className="flex w-full px-7 gap-10 ">
            <div className=" flex flex-1 flex-col justify-between">
              <Tooltip title={data?.drawno}>
                <div className="white-space mt-1 text-white">圖號: {data?.drawno}</div>
              </Tooltip>
              <Tooltip title={data?.qoN}>
                <div className="white-space mt-1 flex text-white">檢單: {data?.qoN}</div>
              </Tooltip>
              <Tooltip title={data?.woN} placement="bottom">
                <div className="white-space mt-1 text-white">工單: {data?.woN}</div>
              </Tooltip>
            </div>
            <div className=" flex w-[30%]  flex-col justify-end">
              <div className="flex mt-1 w-full text-white justify-between ">
                <div>CL:</div>
                <div>{showDecimalPoint(data?.CL)}</div>
              </div>
              <div className="flex mt-1 w-full text-white justify-between ">
                <div>UCL:</div>
                <div>{showDecimalPoint(data?.UCL)}</div>
              </div>
              <div className="flex mt-1 w-full text-white justify-between ">
                <div>LCL:</div>
                <div>{showDecimalPoint(data?.LCL)}</div>
              </div>
            </div>
            <div className=" flex flex-col pt-2 justify-between">
              <div className="flex ">
                <IconUnion
                  // disabled={true}
                  onClick={() => set_s_IsModal(true)}
                  className="header-icon text-[22px] text-[#FFFFFF] "
                />
              </div>
              <div className="flex -ml-[1px]">
                <IconAnalyzeII
                  onClick={() => {
                    set_s_isAnalyzeModal(true);
                  }}
                  className="header-icon mt-1 text-[26px] text-[#FFFFFF]"
                />
              </div>

              <div className="">
                <Light size="26px" type={data?.spcData.length > 0 ? "success" : "main"} />
              </div>
            </div>
          </div>
        </Col>
        <Col span={24} className="chart w-full" ref={chartEle}>
          <HighchartsReact
            highcharts={Highcharts}
            // highcharts={s_heightChart}
            options={chartOptions}
            constructorType={"stockChart"}
          />
        </Col>
      </Row>

      <CustomModal
        // title="SPC管制圖"
        visible={s_isModal}
        onCancel={() => set_s_IsModal(false)}
        width="100%"
        footer={null}
        centered
        closable={true}
      >
        <EazySPC overViewDrawno={data} s_selectedDrawno={s_selectedDrawno} />
      </CustomModal>
      <CustomModal
        // title="SPC管制圖"
        visible={s_isAnalyzeModal}
        onCancel={() => set_s_isAnalyzeModal(false)}
        width="100%"
        footer={null}
        centered
        afterClose={() => {
          set_s_isAnalyzeModal(false);
        }}
        closable
      >
        <Analyze overViewDrawno={data} boardData={boardData} />
      </CustomModal>
    </CardStyle>
  );
}
