/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Form, Input, Layout, Menu, Switch, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import MainItem from "@/auth/auth";
import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { changeStaffPerm, logout } from "@/service/apis/ADM/publicAPI";
import { BarsOutlined, ExportOutlined, UserOutlined } from "@ant-design/icons";

import useAPI from "../../hooks/useAPI";

// import LogoImg from "../../assets/img/logo_company.png";

// API

const { Sider } = Layout;

const MyNavbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { c_systemConfig, c_userData } = useContext(SystemContext);
  //當前收起狀態 true 為展開
  const [collapsedState, setCollapsedState] = useState(true);
  //collapsedWidth的寬度
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  //是否為shrink狀態
  const [isShrink, setIsShrink] = useState(false);

  const [s_modifyPwdModal, set_s_modifyPwdModal] = useState(false);

  const call_logout = useAPI(logout);
  const call_changeStaffPerm = useAPI(changeStaffPerm);

  const subItems = [
    {
      label: (
        <div className="flex justify-between items-center">
          <Switch checked={isShrink} onChange={(e) => setIsShrink(e)} />
        </div>
      ),
      key: "changeNavbar",
      icon: <BarsOutlined />,
      title: "",
    },
    {
      label: "修改個人密碼",
      key: "modifypwd",
      icon: <UserOutlined />,
      title: "",
      onClick: () => set_s_modifyPwdModal(true),
    },
    {
      label: (
        <div className="flex justify-between items-center">
          <span className="font-semibold">{t("util.util.sign_out")}</span>
          <span>V 2.20.11 A1</span>
        </div>
      ),
      key: "logout",
      icon: <ExportOutlined />,
      title: "",
      onClick: () => call_logout.request(),
    },
  ];

  const items = MainItem({ auth: c_systemConfig.auth, c_userData: c_userData } || {});

  const onFinish = (values) => {
    if (values.pwdtwo !== values.pwd) {
      message.error("請確認新密碼是否相同");
      return;
    }
    call_changeStaffPerm.request(values);
  };

  // 角色權限管理
  useEffect(() => {
    console.log(c_systemConfig?.auth);
    // 檢查 c_systemConfig?.auth 是否為空物件 || 在404頁面 || 登入人員為 superman
    if (
      c_userData.peopleNM === "superman" ||
      Object.keys(c_systemConfig?.auth).length === 0 ||
      location.pathname === "/404"
    )
      return;
    // 從 location.pathname 中取得路由名稱
    let getRouterNmae = location.pathname.split("/").slice(2).join("/");
    console.log("getRouterNmae", getRouterNmae);
    // 篩選出 c_systemConfig?.auth 中值為 true 的項目，並且符合路由名稱的項目
    let checkRouterAuth = Object.keys(c_systemConfig?.auth)
      .filter((key) => c_systemConfig?.auth[key] === true)
      .filter((key) => {
        const getKey = key.split("/");
        // console.log("getKey", getKey[0], getRouterNmae[getRouterNmae.length - 1]);
        return getKey[0].includes(getRouterNmae[getRouterNmae.length - 1]);
      });

    // 如果符合路由名稱的授權項目為空，顯示錯誤訊息並導向 "/404" 頁面
    if (!checkRouterAuth || checkRouterAuth.length === 0) {
      message.error("Please confirm permissions");
      navigate("/404");
    }
  }, [location, c_systemConfig?.auth]);

  useEffect(() => {
    //控制navbar伸縮
    if (props.navbarState === "shrinkClose" && !isShrink) {
      props.setNavbarState("close");
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
    if (props.navbarState === "shrinkClose") {
      return;
    } else if (props.navbarState === "open") {
      setCollapsedState(false);
      setCollapsedWidth(250);
    } else if (props.navbarState === "close" && isShrink) {
      props.setNavbarState("shrinkClose");
      setCollapsedState(true);
      setCollapsedWidth(80);
    } else {
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
  }, [props.navbarState, isShrink]);

  useEffect(() => {
    if (call_logout.status === "suc" || call_logout.status === "err") {
      message.success("登出成功");
      navigate("/login");
    }
  }, [call_logout.status]);

  useEffect(() => {
    if (call_changeStaffPerm.status === "suc") {
      message.success("修改成功，請重新登入");
      navigate("/login");
    } else if (call_changeStaffPerm.status === "err") {
      message.error(t(`error.error-code.${call_changeStaffPerm.msg}`));
    }
  }, [call_changeStaffPerm.status]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Sider
      className={`${props.className} shadow overflow-auto z-10 h-[100vh]`}
      trigger={null}
      collapsible
      theme="light"
      collapsed={collapsedState}
      width={250}
      collapsedWidth={collapsedWidth} //只在collapsed = true 吃設定
      onClick={stop_propagation}
    >
      {/* <div className="w-full relative h-[120px] p-3 ">
        <img
          src={SATLogo}
          className="absolute inset-0 max-h-full max-w-full m-auto p-3"
          alt="logo"
        />
      </div> */}

      {/* <div className="flex flex-col min-h-[calc(100vh_-_130px)]"> */}
      <div className="flex flex-col min-h-[100vh]">
        <Menu
          mode="inline"
          size="small"
          className="flex flex-1 flex-col"
          selectedKeys={[location.pathname]}
          items={items}
          onClick={() => props.setNavbarState("close")}
        />
        <Menu mode="inline" items={subItems} />
      </div>

      <CustomModal
        title="密碼修改"
        width="20%"
        open={s_modifyPwdModal}
        onCancel={() => set_s_modifyPwdModal(false)}
        centered
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          initialValues={{ oldpwd: undefined, pwd: undefined }}
        >
          <Form.Item label="舊密碼" name="oldpwd" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item label="新密碼" name="pwd" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="請再次輸入新密碼"
            name="pwdtwo"
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value, callback) => {
                  let cfmPwd = form.getFieldValue("pwd");
                  if (cfmPwd && cfmPwd !== value) {
                    callback("兩次密碼輸入不一致");
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className="text-right">
            <Button
              type="primary"
              htmlType="submit"
              loading={call_changeStaffPerm.status === "load"}
            >
              送出
            </Button>
          </Form.Item>
        </Form>
      </CustomModal>
    </Sider>
  );
};

export default MyNavbar;
