import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { debounce } from "lodash";
import { useEffect, useRef, useState, useContext } from "react";
import SystemContext from "@/components/Context/SystemContext";

export default function NgPieChart({ ngData = [], parentRef }) {
  const chartEle = useRef(null);
  // 深淺樣式切換
  const { c_systemConfig } = useContext(SystemContext);
  const bgColor = c_systemConfig.theme === "dark" ? `#252B42` : "#FFFFFF";
  const labelTextColor = c_systemConfig.theme === "dark" ? "#ffffff" : "#363955";
  const tooltipBgColor = c_systemConfig.theme === "dark" ? "#3D445E" : "#F0F3FB";

  const [chartOptions, setChartOptions] = useState({
    chart: {
      // height: 320,
      // height: 360,
      animation: false,
      borderRadius: 10,
      borderColor: "#dadada",
      backgroundColor: bgColor,
      // marginBottom: 70,
    },
    // for 標題
    title: {
      text: "",
      // text: "不良項目分佈圖",
      style: {
        color: "#808A87",
        fontSize: 25,
      },
    },

    credits: {
      enabled: false,
    },

    colors: ["#D25C5C", "#F56E6E", "#F88896", "#FDB2BB", "#FFD3D3"],

    tooltip: {
      borderColor: "#8ae",
      formatter: function () {
        return `${this.key}：${this.y}`;
      },
      style: {
        fontSize: 25,
      },
    },
    // 所有系列的通用屬性
    plotOptions: {
      series: {
        dataLabels: {
          position: "center",
          enabled: true,
          connectorColor: "#8D8E9D",
          connectorWidth: 2,
          // format: `<b>{point.name}</b>: <b style="color:red">{point.y}%</b>`,
          format: ` <b style=${labelTextColor}>{point.y}%</b>`,
          style: {
            fontSize: 25,
          },
        },
        cursor: "pointer",
        borderWidth: 3,
      },
    },

    legend: {
      backgroundColor: tooltipBgColor,
      itemStyle: {
        color: labelTextColor,
        fontSize: "15px",
      },
      borderRadius: 10,
      layout: "horizontal",
    },

    series: [
      {
        type: "pie",
        allowPointSelect: true,
        keys: ["name", "y", "selected", "sliced"],
        data: [],
        // 是否顯示在圖例中
        showInLegend: true,
      },
    ],
  });


  console.log("ngData =", ngData);
  useEffect(() => {
    setChartOptions((prev) => ({
      ...prev,
      series: [
        {
          ...prev.series[0],
          data: ngData
            .filter((x) => x.ngC !== 0)
            // .sort((a, b) => a.item.localeCompare(b.item))
            .sort((a, b) => b.ngC - a.ngC)
            .map((x) => [x.item + "_" + x.itemNM, x.percent, false]),
        },
      ],
      title: {
        ...prev.title,
        text: ngData.length === 0 ? "暫無資料" : "",
      },
    }));
  }, [ngData]);

  useEffect(() => {
    const newBgColor = c_systemConfig.theme === "dark" ? `#252B42` : "#FFFFFF";
    const newLabelTextColor = c_systemConfig.theme === "dark" ? "#ffffff" : "#363955";
    const newTooltipBgColor = c_systemConfig.theme === "dark" ? "#3D445E" : "#F0F3FB";

    setChartOptions((prev) => ({
      ...prev,
      chart: {
        ...prev.chart,
        backgroundColor: newBgColor,
      },
      legend: {
        ...prev.legend,
        backgroundColor: newTooltipBgColor,
        itemStyle: {
          ...prev.legend.itemStyle,
          color: newLabelTextColor,
        },
      },

      // for 標題
      // title: {
      //   ...prev.title,
      //   style: {
      //     ...prev.title.style,
      //     color: newBgColor,
      //   },
      // },
    }));
  }, [c_systemConfig.theme]);

  const [s_parentWidth, set_s_parentWidth] = useState(0);


  useEffect(() => {
    // 更新图表选项中的宽度
    setChartOptions((prev) => {
      prev.chart.width = s_parentWidth * 0.33;
      return { ...prev };
    });
  }, [s_parentWidth]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartEle} />;
}
