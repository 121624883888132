import { Button, Col, Divider, Form, Input, InputNumber, Row, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// api
import useAPI from "@/hooks/useAPI";
import { addAqlTb, updateAqlTb } from "@/service/apis/QMS/aql";

const ModalContainer = ({ s_editData, set_s_editData, c_lightData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_drawing = Form.useWatch("drawing", form);
  const f_insptype = Form.useWatch("insptype", form);
  // 新增表身資料
  const call_addAqlTb = useAPI(addAqlTb);
  // 編輯表身資料
  const call_updateAqlTb = useAPI(updateAqlTb);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      console.log("values = ", values);
      call_addAqlTb.request({ ...values, ...c_lightData });
    }
    if (type === "edit") {
      call_updateAqlTb.request({ ...values, ...c_lightData });
    }
  };
  useEffect(() => {
    form.setFieldsValue({ sipID: c_lightData.sipID, dcUUID: c_lightData.dcUUID });
  }, []);

  useEffect(() => {
    const { status, msg } = call_addAqlTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addAqlTb.status]);

  useEffect(() => {
    const { status, msg } = call_updateAqlTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateAqlTb.status]);
  console.log("c_lightData = ", c_lightData);
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        acjudge: 0,
        rejudge: 1,
        ...s_editData,
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        {/* <Form.Item name="drawing" hidden>
          <Input />
        </Form.Item> */}
      </div>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item rules={[{ required: true }]} label="允收水準" name="AQL">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Ac核決數" name="acjudge">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Re核決數" name="rejudge">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[12, 12]}>
        {/* <Col span={12}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col> */}
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
