import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  message,
  Radio,
  Space,
} from "antd";
import WpcSetting from "@/components/BDM/WpcSetting";
import { editWpcTb, getWpcIe } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function Edit({ s_tableData, set_s_isShowEdit, c_lightData }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const wpcWrapperRef = useRef(null);

  const call_editWpcTb = useAPI(editWpcTb);
  const call_getWpcIe = useAPI(getWpcIe);

  const makeWpcs = () => {
    let wpcSN = "1",
      wpcs = [],
      subWpcs = [];

    s_tableData.forEach((item, index) => {
      // 如果換途程號了要把之前的所有替代途程丟到主途程內
      if (item.wpcSN !== wpcSN) {
        wpcs.push({ subWpcs: subWpcs });
        subWpcs = [];
        wpcSN = item.wpcSN;
      }

      // 塞入替代途程
      subWpcs.push(item);

      // 如果是最後一筆要把之前的所有替代途程丟到主途程內
      if (index === s_tableData.length - 1) {
        wpcs.push({ subWpcs: subWpcs });
      }
    });

    return wpcs;
  };

  const onSubmit = (values) => {
    // values.wpcs.forEach((wpc) =>
    //   wpc.subWpcs.forEach((subwpc) => (subwpc.pwcID = subwpc.pwcID?.join("|")))
    // );

    call_editWpcTb.request(values);
  };

  useEffect(() => {
    if (call_editWpcTb.status === "suc") {
      message.success(call_editWpcTb.msg);
      set_s_isShowEdit(false);
    } else if (call_editWpcTb.status === "err") {
      message.error(t(`error.error-code.${call_editWpcTb.msg}`));
    }
  }, [call_editWpcTb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        PN: c_lightData.PN, // 表頭料號
        wpcset: c_lightData.wpcset, // 表頭途程組
        wpcs:
          s_tableData.length !== 0
            ? makeWpcs()
            : [
                {
                  subWpcs: [
                    {
                      wpcSN: "1",
                      nextSN: "T",
                      stdwh: 20,
                      stdmh: 20,
                      stdlt: 3600,
                      // 'stdcost': 0,
                      planhead: 1,
                      pfID: null,
                      wsID: null,
                      pwcID: null,
                      itemno: 0,
                      batchbom: 0,
                      ieUUID: null,
                      // wlID: null,
                      warnyield: 90,
                      alertyield: 80,
                      alertspeed: 90,
                      alertstop: 1200,
                    },
                  ],
                },
              ],
      }}
    >
      <Row gutter={[24, 12]}>
        <Form.Item name="PN" nostyle />
        <Form.Item name="wpcset" nostyle />
        <Col span={24}>
          <WpcSetting form={form} wpcWrapperRef={wpcWrapperRef} isEdit />
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowEdit(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
