import { Button, Col, Collapse, Form, Input, Radio, Row, Space, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { addRole, updateRole } from "@/service/apis/ADM/userManage";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addRole = useAPI(addRole);
  const call_updateRole = useAPI(updateRole);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const plantID = getCookie("plantID");

  const authList = {
    MOM: [
      {
        groupName: "PDS",
        name: "PDS",
        label: "PDS",
        disabled: false,
        group: [
          {
            "label": "數位生產看板",
            "name": "PDS-dashboard",
            "disabled": false,
          },
          // {
          //   "label": "數位生產圖框",
          //   "name": "PDS-dashboard-card",
          //   "disabled": false,
          // },
          {
            "label": "異常管理看板",
            "name": "PDS-abcBoard",
            "disabled": false,
          },
          {
            "label": "線體稼動總表",
            "name": "PDS-overview-lines",
            "disabled": false,
          },
          {
            "label": "綜合效能總表",
            "name": "PDS-efficiency",
            "disabled": false,
          },
          {
            "label": "線體生產狀況",
            "name": "PDS-ticker-dashboard",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "BDM",
        "name": "BDM",
        "label": "物料資料管理",
        "disabled": false,
        "group": [
          {
            "label": "基本資料維護",
            "name": "BDM-pn-manage",
            "disabled": false,
          },
          {
            "label": "技術資料維護",
            "name": "BDM-edm",
            "disabled": false,
          },
          {
            "label": "客製表格維護",
            "name": "BDM-sheet",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "TDM",
        "name": "TDM",
        "label": "廠區資料管理",
        "disabled": false,
        "group": [
          {
            "label": "廠別主檔設定",
            "name": "TDM-plant-manage",
            "disabled": false,
          },
          {
            "label": "製程規劃維護",
            "name": "TDM-procedure-manage",
            "disabled": false,
          },
          {
            "label": "產線規劃維護",
            "name": "TDM-production-line-manage",
            "disabled": false,
          },
          // {
          //   "label": "庫房規劃維護",
          //   "name": "TDM-warehouse-manage",
          //   "disabled": false,
          // },
          {
            "label": "工程字串管理",
            "name": "TDM-string-manage",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "SDM",
        "name": "SDM",
        "label": "系統資料管理",
        "disabled": false,
        "group": [
          {
            "label": "員工資料維護",
            "name": "SDM-user-manage",
            "disabled": false,
          },
          {
            "label": "員工資料維護-員工資料",
            "name": "SDM-user-manage-staff",
            "disabled": false,
          },
          {
            "label": "員工資料維護-員工權限",
            "name": "SDM-user-manage-auth",
            "disabled": false,
          },
          // {
          //   "label": "廠商資料維護",
          //   "name": "SDM-company",
          //   "disabled": false,
          // },
          // {
          //   "label": "客戶資料維護",
          //   "name": "SDM-client",
          //   "disabled": false,
          // },
          // {
          //   "label": "查詢分類設定",
          //   "name": "SDM-category-manage",
          //   "disabled": false,
          // },
          // {
          //   "label": "功能授權設定",
          //   "name": "SDM-data-auth-manage",
          //   "disabled": false,
          // },
          // {
          //   "label": "項目字串設定",
          //   "name": "SDM-trading-pattern",
          //   "disabled": false,
          // },
          {
            "label": "作業科目管理",
            "name": "SDM-optype",
            "disabled": false,
          },
          {
            "label": "領班線別管理",
            "name": "SDM-captain-line-manage",
            "disabled": false,
          },
          {
            "label": "系統參數設定",
            "name": "SDM-function-params-manage",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "LMS",
        "name": "LMS",
        "label": "條碼設備管理",
        "disabled": false,
        "group": [
          {
            "label": "出貨標籤列印",
            "name": "LMS-label-orderN-printer",
            "disabled": false,
          },
          {
            "label": "入庫標籤列印",
            "name": "LMS-label-instock-printer",
            "disabled": false,
          },
          {
            "label": "條碼模板管理",
            "name": "LMS-label-template",
            "disabled": false,
          },
          {
            "label": "條碼紙版管理",
            "name": "LMS-label-paper",
            "disabled": false,
          },
          {
            "label": "列印設備管理",
            "name": "LMS-label-machines",
            "disabled": false,
          },
          {
            "label": "輸出標籤查詢",
            "name": "LMS-label-search",
            "disabled": false,
          },
          {
            "label": "條碼日期管理",
            "name": "LMS-label-date",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "PIS",
        "name": "PIS",
        "label": "生產資訊查詢",
        "disabled": false,
        "group": [
          {
            "label": "每日報表查詢",
            "name": "PIS-daily-report",
            "disabled": false,
          },
          {
            "label": "每日報表查詢-每日員工產出",
            "name": "PIS-daily-report-DailyReport_Employee",
            "disabled": false,
          },
          {
            "label": "每日報表查詢-航太移轉報表",
            "name": "PIS-daily-report-AirTransfer",
            "disabled": false,
          },
          {
            "label": "每日報表查詢-大造熊移轉報表",
            "name": "PIS-daily-report-BearTransfer",
            "disabled": false,
          },
          {
            "label": "製程進度查詢",
            "name": "PIS-wpc-search",
            "disabled": false,
          },
          {
            "label": "工單進度查詢",
            "name": "PIS-won-search",
            "disabled": false,
          },
          {
            "label": "暫置量報表",
            "name": "PIS-dal-report",
            "disabled": false,
          },
          {
            "label": "產品條碼查詢",
            "name": "PIS-pdtcode",
            "disabled": false,
          },
          {
            "label": "產品數據查詢",
            "name": "PIS-pdtdata",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "SAP",
        "name": "SAP",
        "label": "中介資料查詢",
        "disabled": false,
        "group": [
          {
            "label": "SAP工單表頭",
            "name": "SAP-head",
            "disabled": false,
          },
          {
            "label": "SAP工單途程",
            "name": "SAP-wpc",
            "disabled": false,
          },
          {
            "label": "SAP訂單資料",
            "name": "SAP-order",
            "disabled": false,
          },
          {
            "label": "QC資料查詢",
            "name": "SAP-qcdata",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "WHM",
        "name": "WHM",
        "label": "工時績效管理",
        "disabled": false,
        "group": [
          {
            "label": "CT維護",
            "name": "WHM-ct",
            "disabled": false,
          },
          {
            "label": "CT維護-科技廠",
            "name": "WHM-ct-Technology",
            "disabled": false,
          },
          {
            "label": "CT維護-輪組前後輪",
            "name": "WHM-ct-Wheel_BeforeAndAfter",
            "disabled": false,
          },
          {
            "label": "CT維護-輪組貼標料號",
            "name": "WHM-ct-Wheel_PN",
            "disabled": false,
          },
          {
            "label": "CT維護-輪組每日其他項目",
            "name": "WHM-ct-Wheel_Miscellaneous",
            "disabled": false,
          },
          {
            "label": "CT維護-車圈料號機台維護",
            "name": "WHM-ct-Car_PN",
            "disabled": false,
          },
          {
            "label": "CT維護-車圈機台維護",
            "name": "WHM-ct-Car_wl",
            "disabled": false,
          },
          {
            "label": "CT維護-車圈標籤維護",
            "name": "WHM-ct-Car_label",
            "disabled": false,
          },
          {
            "label": "CT維護-車圈每日其他項目",
            "name": "WHM-ct-Car_Miscellaneous",
            "disabled": false,
          },
          {
            "label": "出勤工時維護",
            "name": "WHM-attendance-hours",
            "disabled": false,
          },
          {
            "label": "員工績效報表",
            "name": "WHM-performance",
            "disabled": false,
          },
          {
            "label": "員工績效報表-車圈",
            "name": "WHM-performance-car",
            "disabled": false,
          },
          {
            "label": "員工績效報表-輪組",
            "name": "WHM-performance-wheel",
            "disabled": false,
          },
        ],
      },
      {
        "groupName": "QMS",
        "name": "QMS",
        "label": "品質管理系統",
        "disabled": false,
        "group": [
          {
            "label": "品質主檔維護",
            "name": "QMS-qmfm",
            "disabled": false,
          },
          {
            "label": "檢驗標準管理",
            "name": "QMS-qms-manage",
            "disabled": false,
          },
          {
            "label": "檢驗規格模板",
            "name": "QMS-spec",
            "disabled": false,
          },
          // {
          //   "label": "儀器設備管理",
          //   "name": "QMS-cmje",
          //   "disabled": false,
          // },
          // {
          //   "label": "儀器校正管理",
          //   "name": "QMS-cmjeq",
          //   "disabled": false,
          // },
          {
            "label": "統計製程管理",
            "name": "QMS-spc",
            "disabled": false,
          },
        ],
        "auth": plantID === "dev",
      },
      {
        "groupName": "MNS",
        "name": "MNS",
        "label": "管理推播系統",
        "disabled": false,
        "group": [
          {
            "label": "推播設定管理",
            "name": "MNS-notification-setting",
            "disabled": false,
          },
        ],
      },
    ],
    MES: [
      {
        "groupName": "MES",
        "name": "MES",
        "label": "MES",
        "group": [
          {
            "label": "生管排程",
            "name": "production-scheduling",
            "disabled": false,
          },
          {
            "label": "生管派單",
            "name": "wpc-printer",
            "disabled": false,
          },
          {
            "label": "工單復原",
            "name": "won-resume",
            "disabled": false,
          },
          {
            "label": "生管補單",
            "name": "fill-order",
            "disabled": false,
          },
          {
            "label": "回寫紀錄查詢",
            "name": "erp-history",
            "disabled": false,
          },
          {
            "label": "操作紀錄查詢",
            "name": "record-search",
            "disabled": false,
          },
          {
            "label": "批次報工",
            "name": "wl-woc-aps",
            "disabled": false,
          },
          {
            "label": "生產報工_航太",
            "name": "wl-woc-1011",
            "disabled": false,
          },
          {
            "label": "生產報工_大造熊",
            "name": "wl-woc-1021",
            "disabled": false,
          },
          {
            "label": "生產報工_車圈",
            "name": "ez-woc-1001",
            "disabled": false,
          },
          {
            "label": "生產報工_輪組",
            "name": "ez-woc-1002",
            "disabled": false,
          },
          {
            "label": "生產報工_碳圈",
            "name": "ez-woc-1003",
            "disabled": false,
          },
          {
            "label": "質控作業",
            "name": "qc",
            "disabled": false,
          },
          {
            "label": "三合一掃碼",
            "name": "three-in-one-scan",
            "disabled": false,
          },
          {
            "label": "三次元送檢標籤",
            "name": "label-3d",
            "disabled": false,
          },
          {
            "label": "三次元送檢標籤-再次檢驗",
            "name": "label-3d-double-check",
            "disabled": false,
          },
          {
            "label": "三次元品檢刪除",
            "name": "label-3d-delete",
            "disabled": false,
          },
          {
            "label": "三次元標籤回饋",
            "name": "label-record-3d",
            "disabled": false,
          },
          {
            "label": "三次元品檢看板",
            "name": "dashboard-3d",
            "disabled": false,
          },
          {
            "label": "熱處理看板",
            "name": "dashboard-t4t6",
            "disabled": false,
          },
          {
            "label": "品管標籤列印",
            "name": "custom-label-printer",
            "disabled": false,
          },
          {
            "label": "航太廠生產看板",
            "name": "air-card-dashboard",
            "disabled": false,
          },
          {
            "label": "航太廠排程看板",
            "name": "air-production-scheduling",
            "disabled": false,
          },
          {
            "label": "航太廠看板資料維護",
            "name": "air-dashboard-maintain",
            "disabled": false,
          },
          {
            "label": "IOT自動列印",
            "name": "aiot",
            "disabled": false,
          },
          {
            "label": "品質控制",
            "name": "new-qualityControl",
            "disabled": false,
          },
          {
            "label": "異常提報",
            "name": "alert",
            "disabled": false,
          },
          {
            "label": "異常解除",
            "name": "relieve",
            "disabled": false,
          },
          {
            "label": "生產準備",
            "name": "open-prepare",
            "disabled": false,
          },
        ],
      },
    ],
  };

  const options = [
    { value: true, label: t("util.util.yes") },
    { value: false, label: t("util.util.no") },
  ];

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const { itemID, itemNM, ...authData } = values;
    const postData = {
      itemID,
      itemNM,
      auth: JSON.stringify(authData),
    };

    if (type === "create") {
      call_addRole.request(postData);
    }

    if (type === "edit") {
      call_updateRole.request(postData);
    }
  };

  useEffect(() => {
    if (call_addRole.status === "suc") {
      message.success(call_addRole.msg);
      set_s_isShowModal(false);
    } else if (call_addRole.status === "err") {
      message.error(t(`error.error-code.${call_addRole.msg}`));
    }
  }, [call_addRole.status]);

  useEffect(() => {
    if (call_updateRole.status === "suc") {
      message.success(call_updateRole.msg);
      set_s_isShowModal(false);
    } else if (call_updateRole.status === "err") {
      message.error(t(`error.error-code.${call_updateRole.msg}`));
    }
  }, [call_updateRole.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        // MOM
        PDS: true,
        "PDS-dashboard": true,
        "PDS-dashboard-card": true,
        "PDS-abcBoard": true,
        "PDS-overview-lines": true,
        "PDS-efficiency": true,
        "PDS-ticker-dashboard": true,
        BDM: true,
        "BDM-pn-manage": true,
        "BDM-edm": true,
        "BDM-sheet": true,

        TDM: true,
        "TDM-plant-manage": true,
        "TDM-procedure-manage": true,
        "TDM-production-line-manage": true,
        // "TDM-warehouse-manage": true,
        "TDM-string-manage": true,

        SDM: true,
        "SDM-user-manage": true,
        "SDM-user-manage-auth": false,
        "SDM-user-manage-staff": false,
        "SDM-company": true,
        "SDM-client": true,
        "SDM-optype": true,
        "SDM-category-manage": true,
        "SDM-data-auth-manage": true,
        "SDM-trading-pattern": true,
        "SDM-function-params-manage": false,
        "SDM-captain-line-manage": false,

        LMS: true,
        "LMS-label-template": true,
        "LMS-label-paper": true,
        "LMS-label-orderN-printer": true,
        "LMS-label-instock-printer": true,
        "LMS-label-date": true,
        "LMS-label-machines": true,
        "LMS-label-search": true,
        INF: true,
        "INF-bi": true,
        PIS: true,
        "PIS-daily-report": true,
        "PIS-wpc-search": true,
        "PIS-won-search": false,
        "PIS-dal-report": false,
        "PIS-pdtcode": false,
        "PIS-pdtdata": false,
        "PIS-daily-report-DailyReport_Employee": false,
        "PIS-daily-report-AirTransfer": false,
        "PIS-daily-report-BearTransfer": false,
        //WHM
        WHM: true,
        "WHM-ct": true,
        "WHM-ct-Technology": true,
        "WHM-ct-Wheel_BeforeAndAfter": true,
        "WHM-ct-Wheel_PN": true,
        "WHM-ct-Wheel_Miscellaneous": true,
        "WHM-ct-Car_PN": false,
        "WHM-ct-Car_wl": false,
        "WHM-ct-Car_label": false,
        "WHM-ct-Car_Miscellaneous": false,
        "WHM-attendance-hours": true,
        "WHM-performance": false,
        "WHM-performance-car": false,
        "WHM-performance-wheel": false,
        QMS: false,
        "QMS-qmfm": false,
        "QMS-qms-manage": false,
        "QMS-spec": false,
        "QMS-cmje": false,
        "QMS-cmjeq": false,
        "QMS-spc": false,
        MNS: false,
        "MNS-notification-setting": false,
        //SAP
        SAP: true,
        "SAP-head": true,
        "SAP-wpc": true,
        "SAP-order": true,
        "SAP-qcdata": false,
        // WOS
        WOC: true, //"生產控制",
        "production-scheduling": true,
        "wpc-printer": true,
        "fill-order": true,
        "wl-woc-aps": true,
        "wl-woc-1011": true,
        "wl-woc-1021": true,
        "ez-woc-1001": true,
        "ez-woc-1002": true,
        "ez-woc-1003": true,
        qc: true,
        "three-in-one-scan": true,
        "label-3d": true,
        "label-3d-double-check": false,
        "label-3d-delete": false,
        "label-record-3d": true,
        "dashboard-3d": true,
        "dashboard-t4t6": true,
        "custom-label-printer": true,
        "air-production-scheduling": false,
        "air-card-dashboard": false,
        "air-dashboard-maintain": false,
        "aiot": false,
        "erp-history": false,
        "record-search": false,
        "won-resume": false,
        "new-qualityControl": false,
        "alert": false,
        "relieve": false,
        "open-prepare":false,

        ...s_editData,
      }}
    >
      <Row gutter={[12, 12]}>
        <Col span={8}>
          {/* 角色ID */}
          <Form.Item label={t("ADM.user-manage.roleID")} name="itemID" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 角色名稱 */}
          <Form.Item label={t("ADM.user-manage.roleNM")} name="itemNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Collapse defaultActiveKey={["MOM", "MES"]}>
            <Panel forceRender header="MOM" key="MOM">
              <Collapse defaultActiveKey={["PDS"]}>
                {authList.MOM.filter((item) => {
                  return item?.auth === undefined || item?.auth === true;
                }).map((item, i) => (
                  <Panel forceRender header={item.label} key={item.groupName}>
                    <Row>
                      <Col span={6} key={item.name}>
                        <Form.Item
                          label={<span className="text-red-600">{item.label}</span>}
                          name={item.name}
                          disabled={item.disabled}
                          shouldUpdate
                        >
                          <Radio.Group
                            options={options}
                            onChange={(value) => {
                              let newAuth = {};
                              for (const group of authList.MOM[i].group) {
                                const matchItem = group.name.includes(item.name);
                                if (matchItem) {
                                  newAuth[group.name] = value.target.value;
                                }
                              }
                              form.setFieldsValue(newAuth);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {item.group.map((x) => (
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) => prev[item.name] !== current[item.name]}
                        >
                          {() => (
                            <Col span={6} key={x.name}>
                              <Form.Item label={x.label} name={x.name}>
                                <Radio.Group
                                  options={options}
                                  disabled={!form.getFieldValue(item.name)}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Form.Item>
                      ))}
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24}>
          <Collapse defaultActiveKey={["MES"]}>
            {authList.MES.map((item, i) => (
              <Panel forceRender header={item.label} key={item.groupName}>
                <Row>
                  {item.group.map((x) => (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, current) => prev[item.name] !== current[item.name]}
                    >
                      {() => (
                        <Col span={6} key={x.name}>
                          <Form.Item label={x.label} name={x.name}>
                            <Radio.Group
                              options={options}
                              // disabled={!form.getFieldValue(item.name)}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Form.Item>
                  ))}
                </Row>
              </Panel>
            ))}
          </Collapse>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addRole.status === "load" || call_updateRole.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
