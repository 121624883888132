import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
import { getAllWhID } from "@/service/apis/WMS/WMS";
import { updaterun8090Tb } from "@/service/apis/WMS/ProInstock";

const ModalContainer = ({ c_lightData, s_editData, set_s_showModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // 更新表身資料
  const call_updaterun8090Tb = useAPI(updaterun8090Tb);

  const onSubmit = async (values) => {
    call_updaterun8090Tb.request({
      ...values,
      opcode: c_lightData.opcode,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...s_editData,
    });
  }, []);

  useEffect(() => {
    if (call_updaterun8090Tb.status === "suc") {
      message.success(call_updaterun8090Tb.msg);
      set_s_showModal(false);
    } else if (call_updaterun8090Tb.status === "err") {
      message.error(call_updaterun8090Tb.msg);
    }
  }, [call_updaterun8090Tb.status]);
  console.log(call_updaterun8090Tb);
  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label={t("util.util.PN")} name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.idmtype")} name="idmtype">
            <Select
              disabled
              options={[
                { label: t("util.util.idmtype_s"), value: "S" },
                { label: t("util.util.idmtype_d"), value: "D" },
                { label: t("util.util.idmtype_m"), value: "M" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t(`IMS.util.batchno_${c_lightData.opcode}`)} name="batchno">
            <Input className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("IMS.util.planqty")} // 計畫數量
            name="planqty"
            rules={[
              { required: true, message: t("util.util.placeholder_input") + t("IMS.util.planqty") },
            ]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Divider />
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit"
            loading={call_updaterun8090Tb.status==="load" }>
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
