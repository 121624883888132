import { Upload, message } from "antd";
import React, { useEffect } from "react";

import useAPI from "@/hooks/useAPI";
import { addQmsSipHdImg, addQmsSipTbImg, addQmsSopRecImg } from "@/service/apis/QMS/QMSManage";
import { InboxOutlined } from "@ant-design/icons";

const FileModal = ({ type, s_editData, set_s_showFileModal }) => {
  // 上傳表頭工程圖檔
  const call_addQmsSipHdImg = useAPI(addQmsSipHdImg);
  // 上傳表身工程圖檔
  const call_addQmsSipTbImg = useAPI(addQmsSipTbImg);
  // 上傳表身工程圖檔
  const call_addQmsSopRecImg = useAPI(addQmsSopRecImg);

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    fileList: [],
    onChange(info) {
      // console.log("info = ", info);
      // 新建
      let formData = new FormData();
      formData.append("sopID", s_editData.sopID);
      formData.append("sipID", s_editData.sipID);
      formData.append("file", info.file);
      formData.append("dcUUID", s_editData.dcUUID);
      formData.append("itemno", s_editData.itemno);
      formData.append("recno", s_editData.recno);
      formData.append("checkitem", s_editData.checkitem);

      if (type === "sipHd") {
        call_addQmsSipHdImg.request(formData);
      }
      if (type === "sipTb") {
        call_addQmsSipTbImg.request(formData);
      }
      if (type === "sopTb") {
        call_addQmsSopRecImg.request(formData);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    const { status, msg } = call_addQmsSipHdImg;
    if (status === "suc") {
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipHdImg.status]);

  useEffect(() => {
    const { status, msg } = call_addQmsSipTbImg;
    if (status === "suc") {
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipTbImg.status]);

  useEffect(() => {
    const { status, msg } = call_addQmsSopRecImg;
    if (status === "suc") {
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSopRecImg.status]);

  return (
    <Upload.Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">點擊或拖拽文件到此區域上傳</p>
    </Upload.Dragger>
  );
};

export default FileModal;
