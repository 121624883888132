/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, message, Space, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { addClass, updateClass, getClassName, getClassNM } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";
import { use } from "i18next";

export default function ModalConintaer({
  s_editData,
  set_s_isShowModal,
}) {

  const [s_classOption, set_s_classOption] = useState([]);
  const [s_atnamOption, set_s_atnamOption] = useState([]);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const call_addClass = useAPI(addClass);
  const call_updateClass = useAPI(updateClass);
  const call_getClassName = useAPI(getClassName);
  const call_getClassNM = useAPI(getClassNM);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {

      console.log("oldData = ", {
        ...values,
      });

      call_addClass.request(values);
    }

    if (type === "edit") {

      console.log("oldData = ", {
        ...values,
        oldClass: s_editData.class,
        oldAtnam: s_editData.atnam
      });

      call_updateClass.request({
        ...values,
        oldClass: s_editData.class,
        oldAtnam: s_editData.atnam
      });
    }
  };

  const onClassNameChange = (value) => {
    set_s_atnamOption([]);
    console.log("value = ", value);
    call_getClassNM.request({ class: value });
    form.setFieldsValue({
      atnam: undefined // 將第二個選單的值重置
    });
  }

  useEffect(() => {
    if (call_addClass.status === "suc") {
      message.success(call_addClass.msg);
      set_s_isShowModal(false);
    } else if (call_addClass.status === "err") {
      message.error("新增失敗，請檢查群組碼及特性代碼是否已存在");
    }
  }, [call_addClass.status]);

  useEffect(() => {
    if (call_updateClass.status === "suc") {
      message.success(call_updateClass.msg);
      set_s_isShowModal(false);
    } else if (call_updateClass.status === "err") {
      message.error(t(`error.error-code.${call_updateClass.msg}`));
    }
  }, [call_updateClass.status]);

  useEffect(() => {
    call_getClassName.request();
  }, [])

  useEffect(() => {
    if (call_getClassName.status === "suc") {
      message.success(call_getClassName.msg);
      set_s_classOption(call_getClassName?.data);
    } else if (call_getClassName.status === "err") {
      message.error(t(`error.error-code.${call_getClassName.msg}`));
    }
  }, [call_getClassName.status])

  useEffect(() => {
    if (call_getClassNM.status === "suc") {
      message.success(call_getClassNM.msg);
      set_s_atnamOption(call_getClassNM?.data)
    } else if (call_getClassNM.status === "err") {
      message.error(t(`error.error-code.${call_getClassNM.msg}`));
    }
  }, [call_getClassNM.status])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>

        <Col span={12}>
          <Form.Item label="群組碼" name="class" rules={[{ required: true, message: '請先選擇群組碼!' }]}>
            <Select
              onChange={onClassNameChange}
              placeholder="請選擇群組碼"
              options={s_classOption?.map((item) => ({
                ...item,
                value: item?.class,
                label: item?.class,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="特性代碼" name="atnam" rules={[{ required: true, message: '請選擇特性代碼!' }]}>
            <Select placeholder="請選擇特性代碼"
              disabled={form.getFieldValue('class') !== null && form.getFieldValue('class') !== undefined ? false : true}
              options={s_atnamOption?.map((item) => ({
                ...item,
                value: item?.atnam,
                label: item?.atnam + "-" + item?.atbez,
              }))} />
          </Form.Item>
        </Col>


        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addClass.status === "load" || call_updateClass.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
