import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { getWpcHd, deleteWpcHd } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { multipleIDtoName } from "@/util/format";
import ModalConintaer from "./ModalContainer/index";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, set_s_searchData, c_lightData, set_c_lightData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_mgmtcat75, set_s_mgmtcat75] = useState([]);

  const call_getWpcHd = useAPI(getWpcHd);
  const call_deleteWpcHd = useAPI(deleteWpcHd);
  const call_getMgmtcat75 = useAPI(getMgmtcat);

  useEffect(() => {
    call_getMgmtcat75.request({ pageType: "75" });
  }, []);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => call_getWpcHd.request({ ...s_searchData, page: 1, pageSize: 200 }),
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
  }));

  useEffect(() => {
    if (call_getMgmtcat75.status === "suc") {
      const renderData = call_getMgmtcat75.data?.map((item) => ({
        label: item.itemNM,
        value: item.mgmtitem,
      }));
      // console.log("call_getMgmtcat74.data = ", call_getMgmtcat74.data);
      set_s_mgmtcat75(call_getMgmtcat75.data);
    }
    if (call_getMgmtcat75.status === "err") {
      // message.error(call_getMgmtcat74.msg);
      set_s_mgmtcat75([]);
    }
  }, [call_getMgmtcat75.status]);

  const tableColumns = [
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
      render: (text) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="underline"
            onClick={(e) => {
              set_s_searchData((prev) => ({ ...prev, PN: text }));
              call_getWpcHd.request({ ...s_searchData, PN: text });
              e.stopPropagation();
            }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: t("util.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      width: "20%",
    },
    {
      title: t("util.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      width: "20%",
    },
    {
      title: t("util.util.mtype"), // 機種
      dataIndex: "mtype",
      width: "12%",
      render: (text) => multipleIDtoName(s_mgmtcat75, text, "mgmtitem", "itemNM"),
    },
    {
      title: t("ADM.edm.wpcset"), // 途程組
      dataIndex: "wpcset",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.edm.wpcsetNM"),
      dataIndex: "wpcsetNM",
      width: "15%",
      align: "center",
    },
    {
      title: t("util.util.note"), // 註記
      dataIndex: "note",
      width: "25%",
      align: "left",
    },
    {
      title: t("ADM.edm.isrework"),
      dataIndex: "isrework",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.isvalid"), // 有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteWpcHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getWpcHd.request(s_searchData);
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getWpcHd.status === "suc") {
      message.success(call_getWpcHd.msg);
      set_s_tableData(
        call_getWpcHd.data.map((x, i) => ({
          ...x,
          index: i,
          key: x.PN + x.wpcset,
        }))
      );
      set_s_pageInfo(call_getWpcHd.data.pageInfo);
    } else if (call_getWpcHd.status === "err") {
      message.error(t(`error.error-code.${call_getWpcHd.msg}`));
      // message.error(call_getMgmtcat75.msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getWpcHd.status]);

  useEffect(() => {
    if (call_deleteWpcHd.status === "suc") {
      message.success(call_deleteWpcHd.msg);
      // delete之後 重call表格資料
      call_getWpcHd.request(s_searchData);
    } else if (call_deleteWpcHd.status === "err") {
      message.error(t(`error.error-code.${call_deleteWpcHd.msg}`));
    }
  }, [call_deleteWpcHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getWpcHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t("ADM.edm.wpc")}`
            : `${t("util.util.add")}${t("ADM.edm.wpc")}`
        }
        width={"60%"}
        centered
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getWpcHd.request(s_searchData);
        }}
      >
        <ModalConintaer
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
