import { useState, useRef, useEffect } from "react";
import { Button, Input, message } from "antd";
import { useTranslation } from "react-i18next";

import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { getPS, getPF, getPWC, getPCC } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";

import PS from "./PS"; //車間
import Station from "./Station"; //工站
import Line from "./Line"; //工線
import WorkSegment from "./WorkSegment"; //工段

export default function ProductionLineManage() {
  const ps_ref = useRef(null);
  const station_ref = useRef(null);
  const line_ref = useRef(null);
  const workSegment_ref = useRef(null);
  const { t } = useTranslation();

  const call_getPS = useAPI(getPS);
  const call_getPF = useAPI(getPF);
  const call_getPWC = useAPI(getPWC);
  const call_getPCC = useAPI(getPCC);

  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 200,
  });
  const [s_tabKey, set_s_tabKey] = useState("ps"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      query: undefined,
      page: 1,
      pageSize: 200,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    s_tabKey === "ps" && ps_ref.current.onSearch();
    s_tabKey === "station" && station_ref.current.onSearch();
    s_tabKey === "line" && line_ref.current.onSearch();
    s_tabKey === "workSegment" && workSegment_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "ps" && ps_ref.current.onCreate();
    s_tabKey === "station" && station_ref.current.onCreate();
    s_tabKey === "line" && line_ref.current.onCreate();
    s_tabKey === "workSegment" && workSegment_ref.current.onCreate();
  };

  const arrMap = {
    pccArr: call_getPCC.data,
    pwcArr: call_getPWC.data,
    pfArr: call_getPF.data,
    psArr: call_getPS.data,
  };

  const tabsData = [
    {
      key: "ps",
      label: t("ADM.production-line-manage.tabPS"), //"車間資料設定",
      children: <PS ref={ps_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />,
    },
    {
      key: "station",
      label: t("ADM.production-line-manage.tabStation"), //"工站資料設定",
      children: (
        <Station
          ref={station_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
          arrMap={arrMap}
        />
      ),
    },
    {
      key: "line",
      label: t("ADM.production-line-manage.tabLine"), //"工線資料設定",
      children: (
        <Line
          ref={line_ref}
          arrMap={arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    // {
    //   key: "workSegment",
    //   label: "工段資料設定",
    //   children: (
    //     <WorkSegment
    //       ref={workSegment_ref}
    //       s_searchData={s_searchData}
    //       set_s_searchData={set_s_searchData}
    //     />
    //   ),
    // },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("query", e.target.value)}
      placeholder={t("util.util.search")}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   查詢
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   新增
    // </Button>,

    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  useEffect(() => {
    call_getPS.request();
    call_getPF.request();
    call_getPWC.request();
    call_getPCC.request();
  }, []);

  useEffect(() => {
    if (call_getPS.status === "err") {
      message.error(t(`error.error-code.${call_getPS.msg}`));
    }
  }, [call_getPS.status]);

  useEffect(() => {
    if (call_getPF.status === "err") {
      message.error(t(`error.error-code.${call_getPF.msg}`));
    }
  }, [call_getPF.status]);

  useEffect(() => {
    if (call_getPWC.status === "err") {
      message.error(t(`error.error-code.${call_getPWC.msg}`));
    }
  }, [call_getPWC.status]);

  useEffect(() => {
    if (call_getPCC.status === "err") {
      message.error(t(`error.error-code.${call_getPCC.msg}`));
    }
  }, [call_getPCC.status]);

  return (
    <>
      <PageHeader title={t("ADM.production-line-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
