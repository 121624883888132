/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";

import {
  IconCompaer,
  IconCurrent,
  IconMOM,
  IconYOY,
  IconOneColumns,
  IconTowColumns,
  IconThreeColumns,
} from "@/components/Icon/BI";
import { ShrinkOutlined, ArrowsAltOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import { Select, Form, Col, Row, Button, DatePicker, message, Collapse, Tooltip } from "antd";
import { SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";

import InstockOkRate from "./InstockOkRate";
import Pdtwhm from "./Pdtwhm";
import MomInstockOkRate from "./MomInstockOkRate";

import useAPI from "@/hooks/useAPI";
import {
  getBiInstockOkRateQuerycat,
  getBiAllInstockOkRate,
  getBiInstockOkRatePN,
  getBiPdtwhmQuerycat,
  getBiPdtwhmPN,
  getBiMOMQuerycat,
  getBiQuerycatPN,
  getBiMOMPN,
  getBiMOMAllQuerycat,
  getBiParent,
} from "@/service/apis/WMS/chartTest";

import { getMgmtcat } from "@/service/apis/ADM/manage";

import dayjs from "dayjs";
import { loadResources } from "i18next";

const { RangePicker } = DatePicker;

const HighchartsColumnChart = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  //已選中主分類
  const [s_parentIDSelectedOption, set_s_parentIDSelectedOption] = useState([]);
  //已選中次分類
  const [s_mgmtcatSelectedOption, set_s_mgmtcatSelectedOption] = useState([]);
  //已選中產品明細
  const [s_PNSelectedOption, set_s_PNSelectedOption] = useState([]);

  // 下拉選單 Options
  const [s_parentIDSelectDisplayOption, set_s_parentIDSelectDisplayOption] = useState([]);
  const [s_mgmtcatSelectDisplayOption, set_s_mgmtcatSelectDisplayOption] = useState([]);
  const [s_PNSelectDisplayOption, set_s_PNSelectDisplayOption] = useState([]);

  //比較項目
  const [s_compareItem, set_s_compareItem] = useState([]);

  const compareOptions = [
    { label: "投入數量", value: "inC" },
    { label: "良品數", value: "okC" },
    { label: "報廢品量", value: "ngC" },
    { label: "良率", value: "okRate" },
    { label: "全部", value: "all" },
  ];

  const [s_timeUnit, set_s_timeUnit] = useState("month");
  const timeUnitsOptions = () => {
    const nowTimeUnit = [
      { label: "當月", value: "month" },
      { label: "當周", value: "week" },
      { label: "當日", value: "day" },
      { label: "當年", value: "year" },
    ];
    const MOMTimeUnit = [...nowTimeUnit, { label: "時距", value: "Interval" }];
    return s_tabsKey === "now" ? nowTimeUnit : MOMTimeUnit;
  };

  const [s_rateOptions, set_s_rateOptions] = useState("InstockOkRate");
  //
  const rateOptions = [
    { label: "入庫結案狀況", value: "InstockOkRate" },
    // { label: "各料工時良率", value: "Pdtwhm" },
  ];

  //圖表資料
  const [s_parentData, set_s_parentData] = useState([]);
  const [s_MOMparentData, set_s_s_MOMparentData] = useState([]);
  const [s_mgmtcatData, set_s_mgmtcatData] = useState([]);
  const [s_PNChartData, set_s_PNChartData] = useState([]);

  const [s_tabsKey, set_s_tabsKey] = useState("now");
  const [s_chartColumn, set_s_chartColumn] = useState("w-full");
  const [s_mainCategoryColumn, set_s_mainCategoryColumn] = useState("w-full");
  const [s_productDetailsColumn, set_s_productDetailsColumn] = useState("w-full");

  const [s_isExpanded, set_s_IsExpanded] = useState(false);
  const [s_collapsedKeys, set_s_CollapsedKeys] = useState(["1"]);

  const [s_allDate, set_s_allDate] = useState([]);

  //獲取機種
  const call_mgmtcat = useAPI(getMgmtcat);
  const call_getBiParent = useAPI(getBiParent);

  //當前入庫結案良率
  const call_getBiInstockOkRateQuerycat = useAPI(getBiInstockOkRateQuerycat);
  const call_getBiAllInstockOkRate = useAPI(getBiAllInstockOkRate);
  const call_getBiInstockOkRatePN = useAPI(getBiInstockOkRatePN);

  // 環比入庫結案良率
  const call_getBiMOMQuerycat = useAPI(getBiMOMQuerycat);
  const call_getBiMOMPN = useAPI(getBiMOMPN);

  const call_getBiQuerycatPN = useAPI(getBiQuerycatPN);
  const call_getBiMOMAllQuerycat = useAPI(getBiMOMAllQuerycat);

  //各料工時良率
  const call_getBiPdtwhmQuerycat = useAPI(getBiPdtwhmQuerycat);
  const call_getBiPdtwhmPN = useAPI(getBiPdtwhmPN);

  const getDateRange = (type = "month", isFormate = false) => {
    const currentDate = dayjs();
    const dateObject = {
      day: {
        startDate: currentDate.startOf("day"),
        endDate: currentDate.add(1, "day").startOf("day"),
      },
      month: {
        startDate: currentDate.startOf("month"),
        endDate: currentDate.endOf("day"),
      },
      week: {
        startDate: currentDate.startOf("week").day(1),
        endDate: currentDate.endOf("week").day(7),
      },
      year: {
        startDate: currentDate.startOf("year"),
        endDate: currentDate.endOf("day"),
      },
      Interval: {
        startDate: currentDate.startOf("day"),
        endDate: currentDate.add(1, "day").startOf("day"),
      },
    };

    return isFormate
      ? [
          dateObject[type].startDate.format("YYYY-MM-DD"),
          dateObject[type].endDate.format("YYYY-MM-DD"),
        ]
      : [dateObject[type].startDate, dateObject[type].endDate];
    // form.setFieldValue("DateRange", [dateObject[type].startDate, dateObject[type].endDate]);
  };

  const onSearch = () => {
    if (!s_allDate) return;

    
    const [startDate, endDate] = convertDatesToFormat(s_allDate);

    if (s_parentIDSelectedOption.length === 0) return;
    const parentFormat = s_parentIDSelectedOption.join("|");
    const mgmtcatFormat = s_mgmtcatSelectedOption.join("|");
    const PNFormat = s_PNSelectedOption.join("|");

    if (s_rateOptions === "InstockOkRate" && s_tabsKey === "now") {
      if (PNFormat.length !== 0) {
        call_getBiInstockOkRatePN.request({ PN: PNFormat, startDate, endDate });
      }
      if (mgmtcatFormat.length !== 0) {
        call_getBiInstockOkRateQuerycat.request({ querycat: mgmtcatFormat, startDate, endDate });
      }
      if (parentFormat.length !== 0) {
        call_getBiAllInstockOkRate.request({
          pID: parentFormat,
          startDate,
          endDate,
        });
      }

      return;
    }
    // if (s_rateOptions === "Pdtwhm" && s_tabsKey === "now") {
    //   if (values.PN !== undefined && values.PN.length !== 0) {
    //     const PNSearchStr = values.PN?.join("|");
    //     console.log(PNSearchStr);
    //     call_getBiPdtwhmPN.request({ PN: PNSearchStr, startDate, endDate });
    //   }
    //   call_getBiPdtwhmQuerycat.request({ querycat: mgmtcatFormat, startDate, endDate });
    //   return;
    // }

    if (s_rateOptions === "InstockOkRate" && s_tabsKey === "MOM") {
      if (s_parentIDSelectedOption.length !== 0) {
        call_getBiMOMAllQuerycat.request({
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
          pID: parentFormat,
        });
      }
      if (s_mgmtcatSelectedOption.length !== 0) {
        call_getBiMOMQuerycat.request({
          querycat: mgmtcatFormat,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
      if (s_PNSelectedOption.length !== 0) {
        call_getBiMOMPN.request({
          PN: PNFormat,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
      return;
    }
  };

  const datePickerHandleChange = (value) => {
    set_s_allDate(value);
  };
  //轉換日期為 YYYY-MM-DD 格式
  const convertDatesToFormat = (dates = []) => {
    if (dates === undefined) return undefined;

    return dates?.map((date) => dayjs(date).format("YYYY-MM-DD"));
  };
  //按鈕文字
  const tabsText = [
    { text: "當前", Icon: <IconCurrent />, key: "now" },
    { text: "環比", Icon: <IconMOM />, key: "MOM" },
    { text: "評比", Icon: <IconCompaer />, key: "Ratings" },
    { text: "同比", Icon: <IconYOY />, key: "YOY" },
  ];
  //按鈕樣式
  const tabsStyle = {
    basic: "px-5 mr-5 border-transparent ",
    default: "bg-white text-sky-500",
    primary: "bg-sky-500 text-white ",
  };
  const timeUnitTabsStyle = {
    basic: "px-2 mr-5 [&:last-child]:mr-0   border-transparent ",
    default: "bg-white text-blue-800",
    primary: "bg-[#813FC2] text-white ",
  };
  //調整圖表欄位按鈕樣式
  const chartColumnsStyle = {
    basic: "mr-1 [&:last-child]:mr-0 border-none outline-0 focus:border-none focus:outline-0",
    default: "bg-inherit  focus:border-none focus:outline-0",
    primary: " bg-inherit  text-sky-500 focus:border-none focus:outline-0 ",
  };
  //戰情看板下方按鈕點擊事件
  const TabsButtonHandleChange = (key) => {
    if (key === s_tabsKey) return;

    set_s_PNChartData([]);
    set_s_mgmtcatData([]);
    set_s_tabsKey(key);
  };
  const callChartDataAPI = (searchStr, category, newDate) => {
    const payloadDate = newDate ?? s_allDate;
    const [startDate, endDate] = convertDatesToFormat(payloadDate);
    const searchStrJoined = searchStr.join("|");

    //若 searchStrJoined為空陣列會return
    if (!searchStrJoined) return;
    // 若未帶入 category 則　return
    if (!category) return;

    if (s_tabsKey === "now") {
      if (category === "parent") {
        call_getBiAllInstockOkRate.request({
          pID: searchStrJoined,
          startDate,
          endDate,
        });
        return;
      }

      if (category === "mgmtcat") {
        call_getBiInstockOkRateQuerycat.request({ querycat: searchStrJoined, startDate, endDate });
        return;
      }
      if (category === "PN") {
        call_getBiInstockOkRatePN.request({ PN: searchStrJoined, startDate, endDate });
        return;
      }
    }
    if (s_tabsKey === "MOM") {
      if (category === "parent") {
        call_getBiMOMAllQuerycat.request({
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
          pID: searchStrJoined,
        });
      }
      if (category === "mgmtcat") {
        call_getBiMOMQuerycat.request({
          querycat: searchStrJoined,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
      if (category === "PN") {
        call_getBiMOMPN.request({
          PN: searchStrJoined,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
    }
  };

  //控制主分類下拉選單事件
  const parentIDSelectedHandleChange = (parentID) => {
    set_s_mgmtcatSelectDisplayOption([]);
    set_s_mgmtcatSelectedOption([]);
    set_s_PNSelectDisplayOption([]);
    set_s_PNSelectedOption([]);

    if (parentID.length > 6) {
      return;
    }

    if (parentID.length === 0) {
      set_s_s_MOMparentData([]);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
      set_s_parentIDSelectedOption([]);
      return;
    }
    set_s_parentIDSelectedOption([...parentID]);

    const parentIDFormate = parentID.join("|");
    callChartDataAPI(parentID, "parent");
    call_mgmtcat.request({ pageType: 74, pID: parentIDFormate });
  };
  //次分類下拉選單事件
  const mgmtcatHandleChange = (mgmtcat) => {
    set_s_PNSelectDisplayOption([]);
    set_s_PNSelectedOption([]);

    //若選擇大於6個阻擋選擇
    if (mgmtcat.length > 6) {
      return;
    }
    if (mgmtcat.length === 0) {
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
      set_s_mgmtcatSelectedOption([]);
      return;
    }
    set_s_mgmtcatSelectedOption([...mgmtcat]);

    const mgmtcatFormat = mgmtcat.join("|");

    callChartDataAPI(mgmtcat, "mgmtcat");

    call_getBiQuerycatPN.request({ querycat: mgmtcatFormat });
    return [...mgmtcat];
  };
  //產品明細下拉選單事件
  const pdtNMHandleChange = (value) => {
    //若選擇大於6個阻擋選擇
    if (value.length > 6) {
      return;
    }
    if (value.length === 0) {
      set_s_PNChartData([]);
      set_s_PNSelectedOption([]);

      return;
    }
    set_s_PNSelectedOption([...value]);
    callChartDataAPI(value, "PN");
  };

  const handleToggleExpand = () => {
    if (s_isExpanded) {
      set_s_CollapsedKeys([]); //全關閉
    } else {
      set_s_CollapsedKeys(["1", "2", "3"]); //全展開
    }
    set_s_IsExpanded(!s_isExpanded);
  };

  //開啟摺疊面板事件
  const handleCollapseChange = (keys) => {
    set_s_CollapsedKeys(keys);
  };

  //當前當月當期切換事件
  const timeUnitHandleChange = (value) => {
    set_s_timeUnit(value);
  };

  //轉換資料格式為環比圖表所用
  const transformMOMData = (data, selectOptionData) => {
    if (data === undefined || data.length === 0) return;

    //圖表中左上角的標題
    const chartTitle = (str) => {
      if (str === undefined) return str;
      if (selectOptionData !== undefined) {
        const { label } = selectOptionData.find((item) => item.value === str);
        return label;
      }
      return str;
    };

    return data.map((item) => {
      const mgmtcat = chartTitle(item.mtypes);
      const k = 1000;
      const data = [
        {
          name: "投入數量",
          data: item?.inC.map((point) => [
            dayjs(point.date),
            point.value === 0 ? null : point.value,
          ]),

          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat((this.y / k).toFixed(2), 0, ",", ",") + "k";
            },
          },
        },
        {
          name: "良品數量",
          data: item?.okC.map((point) => [
            dayjs(point.date),
            point.value === 0 ? null : point.value,
          ]),
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat((this.y / k).toFixed(2), 0, ",", ",") + "k";
            },
          },
        },
        {
          name: "報廢品量",
          data: item?.ngC.map((point) => [
            dayjs(point.date),
            point.value === 0 ? null : point.value,
          ]),
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat((this.y / k).toFixed(2), 0, ",", ",") + "k";
            },
          },
        },
        {
          name: "良率",
          type: "line",
          yAxis: 1,
          tooltip: {
            valueSuffix: "%",
          },
          data: item?.okRate.map((point) => [
            dayjs(point.date),
            point.value === 0 ? null : Math.round(point.value * 100) / 100,
          ]),
          dataLabels: {
            enabled: true,
            verticalAlign: "top", // 顶部对齐
            formatter: function () {
              if (this.y === 0) return null;
              return Highcharts.numberFormat(this.y) + "%";
            },
          },
        },
      ];
      return { mgmtcat, data, PN: item.PN, date: item.date };
    });
  };

  //摺疊版內容
  const MOMItems = [
    {
      key: "1",
      label: "主分類",
      children: (
        <MomInstockOkRate
          s_chartData={s_MOMparentData}
          s_PNChartData={undefined}
          s_compareItem={s_compareItem}
          s_chartColumn={s_chartColumn}
          s_rateOptions={s_rateOptions}
          s_timeUnit={s_timeUnit}
        />
      ),
      extra: (
        <>
          <Button
            // type={renderChartColumnsType("w-full")}
            // danger
            icon={<IconOneColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_chartColumn === "w-full" ? chartColumnsStyle.primary : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_chartColumn("w-full")}
            text={" "}
          />
          <Button
            // type={renderChartColumnsType("w-[50%]")}
            // danger
            icon={<IconTowColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_chartColumn === "w-[50%]" ? chartColumnsStyle.primary : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_chartColumn("w-[50%]")}
          />
          <Button
            // type={renderChartColumnsType("w-[30%]")}
            // danger
            icon={<IconThreeColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_chartColumn === "w-[33%]" ? chartColumnsStyle.primary : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_chartColumn("w-[33%]")}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "次分類",
      children: (
        <MomInstockOkRate
          s_chartData={s_mgmtcatData}
          s_PNChartData={undefined}
          s_compareItem={s_compareItem}
          s_chartColumn={s_mainCategoryColumn}
          s_rateOptions={s_rateOptions}
          s_timeUnit={s_timeUnit}
        />
      ),
      extra: (
        <>
          <Button
            icon={<IconOneColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_mainCategoryColumn === "w-full"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_mainCategoryColumn("w-full")}
            text={" "}
          />
          <Button
            icon={<IconTowColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_mainCategoryColumn === "w-[50%]"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_mainCategoryColumn("w-[50%]")}
          />
          <Button
            icon={<IconThreeColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_mainCategoryColumn === "w-[33%]"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_mainCategoryColumn("w-[33%]")}
          />
        </>
      ),
    },
    {
      key: "3",
      label: "產品明細",

      children: (
        <MomInstockOkRate
          s_chartData={[]}
          s_PNChartData={s_PNChartData}
          s_compareItem={s_compareItem}
          s_chartColumn={s_productDetailsColumn}
          s_rateOptions={s_rateOptions}
          s_timeUnit={s_timeUnit}
        />
      ),
      extra: (
        <>
          <Button
            icon={<IconOneColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_productDetailsColumn === "w-full"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_productDetailsColumn("w-full")}
            text={" "}
          />
          <Button
            icon={<IconTowColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_productDetailsColumn === "w-[50%]"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_productDetailsColumn("w-[50%]")}
          />
          <Button
            icon={<IconThreeColumns />}
            className={`${chartColumnsStyle.basic} ${
              s_productDetailsColumn === "w-[33%]"
                ? chartColumnsStyle.primary
                : chartColumnsStyle.default
            }`} // 基礎樣式
            onClick={() => set_s_productDetailsColumn("w-[33%]")}
          />
        </>
      ),
    },
  ];
  //摺疊版內容
  const nowItems = [
    {
      key: "1",
      label: "主分類",
      children: <InstockOkRate s_chartData={s_parentData} s_PNChartData={[]} type="parent" />,
    },
    {
      key: "2",
      label: "次分類",
      children: <InstockOkRate s_chartData={s_mgmtcatData} s_PNChartData={[]} type="mgmtcat" />,
    },
    {
      key: "3",
      label: "產品明細",

      children: <InstockOkRate s_chartData={[]} s_PNChartData={s_PNChartData} type="PN" />,
    },
  ];

  useEffect(() => {
    call_getBiParent.request();
  }, []);
  //當前入庫結案良率對象分類獲取
  useEffect(() => {
    if (call_getBiInstockOkRateQuerycat.status === "suc") {
      message.success(call_getBiInstockOkRateQuerycat.msg);
      //設置圖表Data
      set_s_mgmtcatData(call_getBiInstockOkRateQuerycat.data);
      return;
    }
    if (call_getBiInstockOkRateQuerycat.status === "err") {
      message.error(call_getBiInstockOkRateQuerycat.msg);
      set_s_mgmtcatData([]);
      // set_s_pdtNMSelectedOption([]);
    }
  }, [call_getBiInstockOkRateQuerycat.status]);
  //當前各料工時良率對象分類獲取

  useEffect(() => {
    if (call_getBiPdtwhmQuerycat.status === "suc") {
      message.success(call_getBiPdtwhmQuerycat.msg);
      //設置機種 Select

      // console.log(call_getBiPdtwhmQuerycat.data);
      set_s_mgmtcatSelectDisplayOption(
        call_getBiPdtwhmQuerycat.data[0]?.PN.map((PNitem) => ({ label: PNitem, value: PNitem }))
      );
      //設置圖表Data
      set_s_mgmtcatData(call_getBiPdtwhmQuerycat.data);
      return;
    }
    if (call_getBiPdtwhmQuerycat.status === "err") {
      message.error(call_getBiPdtwhmQuerycat.msg);
      set_s_mgmtcatData([]);
      set_s_mgmtcatSelectDisplayOption([]);
    }
  }, [call_getBiPdtwhmQuerycat.status]);

  //當前入庫結案良率對象明細獲取
  useEffect(() => {
    if (call_getBiInstockOkRatePN.status === "suc") {
      message.success(call_getBiInstockOkRatePN.msg);

      //設置明細 select
      set_s_PNChartData(call_getBiInstockOkRatePN.data);
      return;
    }
    if (call_getBiInstockOkRatePN.status === "err") {
      message.error(call_getBiInstockOkRatePN.msg);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
    }
  }, [call_getBiInstockOkRatePN.status]);
  //當前入庫結案良率對象明細獲取
  useEffect(() => {
    if (call_getBiPdtwhmPN.status === "suc") {
      message.success(call_getBiPdtwhmPN.msg);

      //設置明細 select
      set_s_PNChartData(transformMOMData(call_getBiPdtwhmPN.data, undefined));
      return;
    }
    if (call_getBiPdtwhmPN.status === "err") {
      message.error(call_getBiPdtwhmPN.msg);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
    }
  }, [call_getBiPdtwhmPN.status]);

  //環比
  useEffect(() => {
    if (call_getBiMOMQuerycat.status === "suc") {
      message.success(call_getBiMOMQuerycat.msg);

      set_s_mgmtcatData(transformMOMData(call_getBiMOMQuerycat.data, s_mgmtcatSelectDisplayOption));
      return;
    }
    if (call_getBiMOMQuerycat.status === "err") {
      message.error(call_getBiMOMQuerycat.msg);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
    }
  }, [call_getBiMOMQuerycat.status]);
  useEffect(() => {
    if (call_getBiQuerycatPN.status === "suc") {
      set_s_PNSelectDisplayOption(
        call_getBiQuerycatPN.data.map((item) => {
          return {
            label: `${item.mtypes}機種`,
            options: item.PN.map((pn) => {
              return { label: pn, value: pn };
            }),
          };
        })
      );

      return;
    }
    if (call_getBiQuerycatPN.status === "err") {
      message.error(call_getBiQuerycatPN.msg);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
    }
  }, [call_getBiQuerycatPN.status]);
  useEffect(() => {
    if (call_getBiMOMPN.status === "suc") {
      //設置明細 select
      set_s_PNChartData(transformMOMData(call_getBiMOMPN.data, undefined));
      return;
    }
    if (call_getBiMOMPN.status === "err") {
      message.error(call_getBiMOMPN.msg);
      set_s_mgmtcatData([]);
      set_s_PNChartData([]);
    }
  }, [call_getBiMOMPN.status]);
  useEffect(() => {
    if (call_getBiMOMAllQuerycat.status === "suc") {
      message.success(call_getBiMOMAllQuerycat.msg);

      //設置明細 select
      set_s_s_MOMparentData(
        transformMOMData(call_getBiMOMAllQuerycat.data, s_parentIDSelectDisplayOption)
      );
      return;
    }
    if (call_getBiMOMAllQuerycat.status === "err") {
      message.error(call_getBiMOMAllQuerycat.msg);
      set_s_s_MOMparentData([]);
    }
  }, [call_getBiMOMAllQuerycat.status]);
  useEffect(() => {
    const [startDate, endDate] = convertDatesToFormat(s_allDate);
    set_s_timeUnit(timeUnitsOptions()[0].value);

    if (s_parentIDSelectedOption.length === 0) return;

    const parentFormat = s_parentIDSelectedOption.join("|");
    const mgmtcatFormat = s_mgmtcatSelectedOption.join("|");
    const PNFormat = s_PNSelectedOption.join("|");

    if (s_rateOptions === "InstockOkRate" && s_tabsKey === "now") {
      if (PNFormat.length !== 0) {
        call_getBiInstockOkRatePN.request({ PN: PNFormat, startDate, endDate });
      }
      if (mgmtcatFormat.length !== 0) {
        call_getBiInstockOkRateQuerycat.request({ querycat: mgmtcatFormat, startDate, endDate });
      }
      if (parentFormat.length !== 0) {
        call_getBiAllInstockOkRate.request({
          pID: parentFormat,
          startDate,
          endDate,
        });
      }

      return;
    }

    // if (s_tabsKey === "now" && s_rateOptions === "Pdtwhm") {
    //   call_getBiPdtwhmQuerycat.request({ querycat: mgmtcatFormat });
    // }

    if (s_rateOptions === "InstockOkRate" && s_tabsKey === "MOM") {
      if (s_parentIDSelectedOption.length !== 0) {
        call_getBiMOMAllQuerycat.request({
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
          pID: parentFormat,
        });
      }
      if (s_mgmtcatSelectedOption.length !== 0) {
        call_getBiMOMQuerycat.request({
          querycat: mgmtcatFormat,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
      if (s_PNSelectedOption.length !== 0) {
        call_getBiMOMPN.request({
          PN: PNFormat,
          startDate,
          endDate,
          type: s_timeUnit === "Interval" ? "" : s_timeUnit,
        });
      }
      // if (s_parentIDSelectedOption.includes("All")) {
      //   call_getBiMOMAllQuerycat.request({ startDate, endDate, type: s_timeUnit });
      // }
      //取得明細內資料
      // if (values.PN !== undefined && values.PN.length !== 0) {
      //   const [startDate, endDate] = convertDatesToFormat(dateRange, true);

      //   const PNSearchStr = values.PN?.join("|");
      //   console.log(PNSearchStr);
      //
      // }

      // if (s_timeUnit === "day") {
      //   const [startDate, endDate] = convertDatesToFormat(form.getFieldValue("DateRange"), true);
      //   console.log(startDate, endDate);
      //
      //   return;
      // }
      // if (mgmtcatFormat.length === 0) return;
      // call_getBiMOMQuerycat.request({ querycat: mgmtcatFormat, });

      return;
    }
  }, [s_tabsKey]);

  useEffect(() => {
    if (call_mgmtcat.status === "suc") {
      let mgmtcatSelectOptions = call_mgmtcat.data?.map((mgmtData) => ({
        label: mgmtData.itemNM,
        value: mgmtData.mgmtitem,
      }));

      set_s_mgmtcatSelectDisplayOption([...mgmtcatSelectOptions]);
    }
  }, [call_mgmtcat.status]);
  useEffect(() => {
    const newDateRange = getDateRange(s_timeUnit);
    set_s_allDate(newDateRange);

    callChartDataAPI(s_parentIDSelectedOption, "parent", newDateRange);
    callChartDataAPI(s_mgmtcatSelectedOption, "mgmtcat", newDateRange);
    callChartDataAPI(s_PNSelectedOption, "PN", newDateRange);
  }, [s_timeUnit]);

  useEffect(() => {
    if (call_getBiParent.status === "suc") {
      set_s_parentIDSelectDisplayOption(
        call_getBiParent.data.map((item) => ({ label: item.pNM, value: item.pID }))
      );
      set_s_parentIDSelectedOption([call_getBiParent.data[0].pID]);
      call_mgmtcat.request({ pageType: 74, pID: call_getBiParent.data[0].pID });

      const [startDate, endDate] = getDateRange("month", true);

      call_getBiAllInstockOkRate.request({
        pID: call_getBiParent.data[0].pID,
        startDate,
        endDate,
      });
      return;
    }
    if (call_getBiParent.status === "err") {
      message.error(call_getBiParent.msg);
    }
  }, [call_getBiParent.status]);

  useEffect(() => {
    if (call_getBiAllInstockOkRate.status === "suc") {
      set_s_parentData(call_getBiAllInstockOkRate.data);
      return;
    }
    if (call_getBiAllInstockOkRate.status === "err") {
      message.error(call_getBiParent.msg);
    }
  }, [call_getBiAllInstockOkRate.status]);


  // console.log(s_mgmtcatSelectedOption);
  // console.log(s_parentIDSelectedOption);
  // console.log(s_PNSelectedOption);

  // console.log(s_PNSelectDisplayOption);

  // console.log(s_parentData);
  // console.log(s_mgmtcatData);
  // console.log(s_PNChartData);

  // console.error(s_tabsKey);
  // console.log(s_allDate);
  // console.log(s_chartColumn);
  // console.log(s_compareItem);
  // console.log(s_rateOptions);
  // console.log(s_timeUnit);

  return (
    <div className="w-full h-full  flex  flex-col">
      <PageHeader title={"入庫結案狀況"} />
      <Form onFinish={onSearch} form={form} layout="vertical" size="large">
        {/* 按鈕區塊 */}
        <div className={`mb-1 flex  justify-between `}>
          <span className="flex">
            
              {tabsText.map((item) => {
                return (
                  <Button
                    key={item.key}
                    // type={renderButtonType("create")}
                    // danger
                    icon={item.Icon}
                    className={`${tabsStyle.basic} ${
                      s_tabsKey === item.key ? tabsStyle.primary : tabsStyle.default
                    }`} // 基礎樣式
                    disabled={item.key === "Ratings" || item.key === "YOY"}
                    onClick={() => TabsButtonHandleChange(item.key)}
                  >
                    {item.text}
                  </Button>
                );
              })}
           
          </span>

          <div className=" flex  justify-around">
            {/* 時間按鈕區塊 */}
            <span className="flex">
             
                {timeUnitsOptions().map((item) => {
                  return (
                    <Button
                      key={item.value}
                      // type={renderButtonType("create")}
                      // danger
                      // icon={item.Icon}
                      className={`${timeUnitTabsStyle.basic} ${
                        s_timeUnit === item.value
                          ? timeUnitTabsStyle.primary
                          : timeUnitTabsStyle.default
                      }`} // 基礎樣式
                      // disabled={item.key === "Ratings" || item.key === "YOY"}
                      onClick={() => timeUnitHandleChange(item.value)}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              
            </span>
            <span className="flex">
              {/* 
                <Select
                  options={rateOptions}
                  onChange={rateOptionsHandleChange}
                  className="w-full "
                />
             */}
            </span>
          </div>
        </div>

        <Row gutter={[12, 12]} className="">
          <Col span={4}>
            {/* 日歷 */}

            <RangePicker
              className="w-full"
              allowClear={false}
              onChange={datePickerHandleChange}
              value={s_allDate}
              format="YYYY-MM-DD"
              disabled={s_timeUnit !== "Interval"}
            />
          </Col>

          <Col span={17} className="    ">
            <span className="mr-4 ">主分類</span>
            <Tooltip
              title={
                s_parentIDSelectedOption.length !== 0 ? (
                  <p>
                    {s_parentIDSelectedOption.map((item) => (
                      <>
                        {`${item}機種`} <br />
                      </>
                    ))}
                  </p>
                ) : null
              }
              placement="top"
              trigger="hover"
            >
              <>
                <Select
                  onChange={parentIDSelectedHandleChange}
                  options={s_parentIDSelectDisplayOption}
                  value={s_parentIDSelectedOption}
                  className="w-[20%] mr-2 "
                  mode="multiple"
                  maxTagCount={2}
                  maxTagPlaceholder="..."
                />
              </>
            </Tooltip>

            <span className="mr-4">次分類</span>
            <Tooltip
              title={
                s_mgmtcatSelectedOption.length !== 0 ? (
                  <p>
                    {s_mgmtcatSelectedOption.map((item) => (
                      <>
                        {`${item}機種`} <br />
                      </>
                    ))}
                  </p>
                ) : null
              }
              placement="top"
              trigger="hover"
            >
              <>
                <Select
                  onChange={mgmtcatHandleChange}
                  options={s_mgmtcatSelectDisplayOption}
                  value={s_mgmtcatSelectedOption}
                  className="w-[20%] mr-2 "
                  mode="multiple"
                  maxTagCount={2}
                  maxTagPlaceholder="..."
                />
              </>
            </Tooltip>

            <span className="mr-4">產品明細</span>

            <Tooltip
              title={
                s_PNSelectedOption.length !== 0 ? (
                  <p>
                    {s_PNSelectedOption.map((item) => (
                      <>
                        {`${item}機種`} <br />
                      </>
                    ))}
                  </p>
                ) : null
              }
              placement="top"
              trigger="hover"
            >
              <>
                {/* ^^不要刪除該空Fragment ， code會裂開  */}
                <Select
                  mode="multiple"
                  allowClear
                  onChange={pdtNMHandleChange}
                  options={s_mgmtcatSelectedOption.length > 1 ? null : s_PNSelectDisplayOption}
                  value={s_PNSelectedOption}
                  className="w-[40%] "
                  placeholder="無"
                  maxTagCount={3}
                  maxTagPlaceholder="..."
                />
              </>
            </Tooltip>
          </Col>

          <Col span={3} className=" ">
            {/* {s_tabsKey !== "now" && (
             <Form.Item label="比較項目" name="categore" className="w-full mr-2">
               <Select
                 className="w-full"
                 mode="multiple"
                 options={compareOptions}
                 onChange={compareItemHandleChange}
                 disabled
                 allowClear
               />
             </Form.Item>
           )} */}
            <span className="flex justify-between">
              <SearchButton
                type="primary"
                key="search"
                htmlType="submit"
                onClick={onSearch}
                // className=" mt-[-20px]"
              />
              <Button
                icon={s_isExpanded ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                className={`${chartColumnsStyle.basic} ${
                  s_chartColumn === "w-full" ? chartColumnsStyle.primary : chartColumnsStyle.default
                }`} // 基礎樣式
                onClick={handleToggleExpand}
                text={" "}
              />
            </span>
          </Col>
        </Row>

        {/* 搜尋列表 */}
        {/* <div className="mb-3 bg-white rounded-md  ">
          <Row
            gutter={[12, 12]}
            justify={`${s_tabsKey === "now" ? " justify-start" : "justify-between"}`}
            align="top"
          >
            <Col span={9} className="flex  items-end  justify-center">
              {/* 當期當前 */}
        {/* {s_tabsKey !== "now" && (
                <Form.Item label=" 時間基點" name="timeBase" className="w-[15%] ml-2 ">
                  <Button className=" bg-[#1677ff] text-[#fff] " onClick={timeBaseHandleChange}>
                    {timeBaseOptions[s_timeBase]}
                  </Button>
                </Form.Item>
              )} */}

        {/* 當月當周當日 */}
        {/* {
                <Form.Item label="時間基點" name="timeUnit" className="w-[30%] mx-2 ">
                  <Select
                    className=" mr-2 "
                    options={timeUnitsOptions()}
                    onChange={timeUnitHandleChange}
                  />
                </Form.Item>
              } */}
        {/* 日歷 */}
        {/* <Form.Item name="DateRange" className="w-[80%] ">
                <RangePicker
                  className="w-full"
                  allowClear={false}
                  // format={dateRangeFormat()}
                  format="YYYY-MM-DD"
                  // format={s_timeUnit === "day" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
                  picker={dataRangePickerFormat()}
                  disabled={s_timeUnit !== "Interval" || s_tabsKey === "now"}
                />
              </Form.Item>
            </Col> */}

        {
          /* <Col span={10} className="  flex ">
              <Form.Item label="總和" name="All" className="w-[12%] mr-2">
                <Checkbox checked={s_isShowAll} onChange={showAllHandleChange} />
              </Form.Item>

              <Form.Item label="對象分類" name="mgmtcat" className="w-[45%] mr-2">
                <Select
                  onChange={mgmtcatHandleChange}
                  options={s_mgmtcatSelectedOption}
                  className="w-full "
                  mode="multiple"
                />
              </Form.Item>

              <Form.Item label="對象明細" name="PN" className=" w-[80%]">
                <Select
                  mode="multiple"
                  allowClear
                  onChange={pdtNMHandleChange}
                  options={s_pdtNMSelectedOptionedOption}
                  className="w-full"
                  placeholder="無"
                />
              </Form.Item>
            </Col>

            <Col span={4} className=" flex h-auto">
              {s_tabsKey !== "now" && (
                <Form.Item label="比較項目" name="categore" className="w-full mr-2">
                  <Select
                    className="w-full"
                    mode="multiple"
                    options={compareOptions}
                    onChange={compareItemHandleChange}
                    disabled
                    allowClear
                  />
                </Form.Item>
              )}

              <Form.Item label=" ">
                <SearchButton
                  type="primary"
                  key="search"
                  htmlType="submit"
                  onClick={onSearch}
                  className=" -mt-[20px]"
                />
              </Form.Item>
            </Col>
            {
              /* <Col span={ s_tabsKey==="now" ?5:1} className={`flex items-end bg-green-400`}> */
          /* <SearchButton type="primary" key="search" htmlType="submit" onClick={onSearch} /> */
          // </Col>
        }
        {/* </Row> */}
        {/* </div> */}
      </Form>
      {/* 欄位切換按鈕*/}
      {/* <div className=" flex justify-end">
     
        {s_tabsKey !== "now" && (
          <span>
            <Button
              // type={renderChartColumnsType("w-full")}
              // danger
              icon={<IconOneColumns />}
              className={`${chartColumnsStyle.basic} ${
                s_chartColumn === "w-full" ? chartColumnsStyle.primary : chartColumnsStyle.default
              }`} // 基礎樣式
              onClick={() => set_s_chartColumn("w-full")}
              text={" "}
            />
            <Button
              // type={renderChartColumnsType("w-[50%]")}
              // danger
              icon={<IconTowColumns />}
              className={`${chartColumnsStyle.basic} ${
                s_chartColumn === "w-[50%]" ? chartColumnsStyle.primary : chartColumnsStyle.default
              }`} // 基礎樣式
              onClick={() => set_s_chartColumn("w-[50%]")}
            />
            <Button
              // type={renderChartColumnsType("w-[30%]")}
              // danger
              icon={<IconThreeColumns />}
              className={`${chartColumnsStyle.basic} ${
                s_chartColumn === "w-[33%]" ? chartColumnsStyle.primary : chartColumnsStyle.default
              }`} // 基礎樣式
              onClick={() => set_s_chartColumn("w-[33%]")}
            />
          </span>
        )}
      </div> */}
      {/* 各圖表 */}
      <div className={`w-full  overflow-y-auto  grow mt-2`}>
        {s_rateOptions === "InstockOkRate" && s_tabsKey === "now" && (
          <>
            <Collapse
              size="large"
              collapsible="icon"
              items={nowItems}
              activeKey={s_collapsedKeys}
              onChange={handleCollapseChange}
            />
          </>
        )}

        {/* {s_rateOptions === "Pdtwhm" && s_tabsKey === "now" && (
          <Pdtwhm s_mgmtcatData={s_mgmtcatData} s_PNChartData={s_PNChartData} />
        )} */}
        {
          // s_mgmtcatData.length!==0 &&
          s_rateOptions === "InstockOkRate" && s_tabsKey === "MOM" && (
            <>
              <Collapse
                size="large"
                collapsible="icon"
                items={MOMItems}
                activeKey={s_collapsedKeys}
                onChange={handleCollapseChange}
              />
            </>
          )
        }
        {
          // s_mgmtcatData.length!==0 &&
          s_tabsKey === "Ratings" && <div>評比</div>
        }
        {
          // s_mgmtcatData.length!==0 &&
          s_tabsKey === "YOY" && <div>同比</div>
        }
      </div>
    </div>
  );
};

export default HighchartsColumnChart;
