import { Button, DatePicker, Table, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getPWC } from "@/service/apis/ADM/publicAPI";
import { getBalExcel, getBalReport } from "@/service/apis/PIS/dalReport";
import calculateTotalTextWidth from "@/util/calculateTotalTextWidth";
import { numberWithCommas } from "@/util/format";

import ExcelModal from "./ModalContainer/ExcelModal";

const DalReport = () => {
  const [s_searchData, set_s_searchData] = useState({
    woN: undefined,
    month: dayjs(),
  });

  const [s_pageTotal, set_s_pageTotal] = useState([]);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowExcelModal, set_s_isShowExcelModal] = useState(false);

  const [s_tableCol, set_s_tableCol] = useState([
    {
      title: "日期",
      dataIndex: "date",
      align: "center",
      width: 110,
      onCell: (record, index) => cellProps(record, index, "date"),
    },
    {
      title: "名稱",
      dataIndex: "name",
      align: "center",
      width: 90,
      onCell: (record, index) => cellBoardBottom(record, index),
    },
    {
      title: "暫置量總計",
      dataIndex: "daltotal",
      align: "right",
      width: 110,
      onCell: (record, index) => cellBoardBottom(record, index),
    },
    {
      title: "託外組裝",
      dataIndex: "ZP02",
      align: "right",
      width: 110,
      onCell: (record, index) => cellBoardBottom(record, index),
    },
  ]);

  const cellProps = (record, index, name) => {
    if ((index + 1) % 2 === 1) {
      return {
        rowSpan: 2,
        style: {
          borderBottom: "1px solid #969696",
        },
      };
    }
    return {
      rowSpan: 0,
    };
  };

  const cellBoardBottom = (record, index, name) => {
    if ((index + 1) % 2 === 0) {
      return {
        style: {
          borderBottom: "1px solid #969696",
        },
      };
    }
  };

  const call_getBalReport = useAPI(getBalReport);

  const call_getPWC = useAPI(getPWC);

  const handlerSearch = () => {
    call_getBalReport.request({
      ...s_searchData,
      month: new Date(s_searchData.month),
    });
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      handlerSearch();
    }
  };

  useEffect(() => {
    call_getPWC.request();
  }, []);

  useEffect(() => {
    if (call_getPWC.status === "suc") {
      message.success(call_getPWC.msg);
      set_s_tableCol((prev) => {
        //新增getPWC 所有 pwcID
        const newCol = call_getPWC.data.map((x) => ({
          title: x.pwcNM,
          dataIndex: x.pwcID,
          align: "right",
          width: calculateTotalTextWidth(x.pwcNM),
          onCell: (record, index) => cellBoardBottom(record, index),
          render: (text) => numberWithCommas(text),
        }));
        //在prev第二個插入新的col
        prev.splice(2, 0, ...newCol);
        return [...prev];
      });

      let pageTotal = call_getPWC.data.map((x) => ({ pwcID: x.pwcID, okC: 0, ngC: 0, bal: 0 }));
      pageTotal.push({ pwcID: "daltotal", okC: 0, ngC: 0, bal: 0 });
      pageTotal.push({ pwcID: "ZP02", okC: 0, ngC: 0, bal: 0 });
      set_s_pageTotal(pageTotal);
      handlerSearch();
    } else if (call_getPWC.status === "err") {
      message.error(call_getPWC.msg);
      set_s_tableCol([
        {
          title: "日期",
          dataIndex: "date",
          align: "center",
        },
        {
          title: "名稱",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "暫置量總計",
          dataIndex: "daltotal",
          align: "right",
          render: (text) => numberWithCommas(text),
        },
        {
          title: "托外組裝",
          dataIndex: "ZP02",
          align: "right",
          render: (text) => numberWithCommas(text),
        },
      ]);
    }
  }, [call_getPWC.status]);

  useEffect(() => {
    if (call_getBalReport.status === "suc") {
      message.success(call_getBalReport.msg);
      //重製pageTotal的okC, ngC, bal
      let pageTotal = s_pageTotal.map((x) => ({ ...x, okC: 0, ngC: 0, bal: 0 }));
      // 根據這個月的日期創建array object 每個日期2筆 ex:[{date:"2024-05-01"}]
      let monthDates = [];
      for (let i = 1; i <= dayjs(s_searchData.month).daysInMonth(); i++) {
        const date = dayjs(s_searchData.month).set("date", i).format("YYYY-MM-DD");
        monthDates.push({ date, daltotal: 0, name: "生產總量", key: i + date, ngC: 0 });
        monthDates.push({ date, daltotal: 0, name: "暫置量", key: i + 1 + date, ngC: 0 });
      }

      // for call_getBalReport.data 塞進對應日期的數值
      for (const obj of call_getBalReport.data) {
        //在monthDates找到對應日期的object index
        const index = monthDates.findIndex((x) => x.date === obj.date);

        const pageTotalIndex = pageTotal.findIndex((x) => x.pwcID === obj.pwcID);
        //如果有找到
        if (index !== -1) {
          //在monthDates[index]新增obj的key value
          //上面塞inC, 下面塞bal
          monthDates[index][obj.pwcID] = obj.okC;
          monthDates[index + 1][obj.pwcID] = obj.bal;
          monthDates[index + 1]["daltotal"] += obj.bal;
          // ----------------------------------------------
          pageTotal[pageTotalIndex]["okC"] += obj.okC;
          pageTotal[pageTotalIndex]["ngC"] += obj.ngC;
        }
      }
      set_s_tableData(monthDates);
      set_s_pageTotal(pageTotal);
    } else if (call_getBalReport.status === "err") {
      message.error(call_getBalReport.msg);
      set_s_tableData([]);
    }
  }, [call_getBalReport.status]);

  return (
    <>
      <PageHeader
        title={"暫置量報表"}
        extra={[
          <DatePicker
            value={s_searchData.month}
            onChange={(e) => set_s_searchData((prev) => ({ ...prev, month: e }))}
            picker="month"
          />,
          <Button key="search" type="primary" onClick={handlerSearch}>
            查詢
          </Button>,
          <Button
            key="search"
            type="primary"
            className="bg-green-700"
            onClick={() => set_s_isShowExcelModal(true)}
          >
            匯出Excel
          </Button>,
        ]}
      />

      <CustomTable
        columns={s_tableCol.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        pagination={false}
        scroll_x="max-content"
        loading={call_getBalReport.status === "load"}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2} className="text-center">
                  生產總量合計
                </Table.Summary.Cell>
                {s_pageTotal.map((x, i) => (
                  <Table.Summary.Cell key={x.pwcID} index={i + 2} className="text-right">
                    {numberWithCommas(x.okC)}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2} className="text-center">
                  不良品數合計
                </Table.Summary.Cell>
                {s_pageTotal.map((x, i) => (
                  <Table.Summary.Cell key={x.pwcID} index={i + 2} className="text-right">
                    {numberWithCommas(x.ngC)}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
      />

      <CustomModal
        title="匯出Excel"
        width={"20%"}
        open={s_isShowExcelModal}
        onCancel={() => set_s_isShowExcelModal(false)}
      >
        <ExcelModal set_s_isShowExcelModal={set_s_isShowExcelModal} />
      </CustomModal>
    </>
  );
};

export default DalReport;
