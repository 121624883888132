function checkUndefined(variable) {
  const { isNaN } = Number;
  const { isArray } = Array;
  const { includes } = String.prototype;
  const type = typeof variable;

  // if (variable === undefined || variable === null || isArray(variable)) {
  //   return "no Data";
  // }

  if (variable === undefined || variable === null || isArray(variable)) {
    return "";
  }

  // if (type === "string") {
  //   return variable === "" || includes.call(variable, "0001-01-01") ? "no Data" : variable;
  // }

  if (type === "string") {
    return variable === "" || includes.call(variable, "0001-01-01") ? "" : variable;
  }

  if (type === "number") {
    return isNaN(variable) ? 0 : numberWithCommas(variable);
  }

  return variable;
}

function numberWithCommas(x) {
  const { replace } = String.prototype;
  const pattern = /\B(?=(\d{3})+(?!\d))/g;
  const value = typeof x === "string" || typeof x === "number" ? x : "";

  return replace.call(value, pattern, ",");
}

export default checkUndefined;
