/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getTxnHd, txn, deleteTxnHd } from "@/service/apis/WMS/WMS";
import { IconCircle, IconGarbage } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";

const Production = forwardRef((props, ref) => {
  const { c_lightData, set_c_lightData, c_searchData } = props;
  const { t } = useTranslation();

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
  const [s_tableData, set_s_tableData] = useState([]);

  // 後端的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // 送給後端的資料
  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 200,
  });

  const call_getTxnHd = useAPI(getTxnHd);
  const call_txn = useAPI(txn);
  const call_deleteTxnHd = useAPI(deleteTxnHd);

  const tableColumns = [
    {
      title: t("IMS.util.opcode"),
      dataIndex: "opcode",
      align: "center",
      render: (text) => t(`IMS.util.opcode_${text}`),
    },
    {
      title: t("IMS.util.jobN"),
      dataIndex: "jobN",
    },
    {
      title: t(`IMS.util.preN_${c_searchData.opcode}`),
      dataIndex: "woN",
    },
    {
      title: t("IMS.util.planqty"),
      dataIndex: "planqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("IMS.util.realtqty"),
      dataIndex: "realqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("IMS.util.bal"),
      dataIndex: "bal",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      render: (text) => t(`IMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "lifeFT",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => (
        <Space>
          <IconCircle disabled={rowData.lifeF !== "2"} onClick={(e) => recycleTxn(e, rowData)} />
          <IconGarbage disabled={rowData.lifeF !== "0"} onClick={(e) => deleteTxn(e, rowData)} />
        </Space>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_showModal(true), //開啟新增Modal
    Call_hd: () => call_getTxnHd.request({ ...c_searchData, ...s_searchValue }),
  }));

  const recycleTxn = (e, rowData) => {
    Modal.confirm({
      title: t("IMS.util.txn_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("IMS.util.txn_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_txn.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  const deleteTxn = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteTxnHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  // c_searchData 變更時改變 page
  useEffect(() => {
    set_c_lightData({});
    set_s_searchValue((prev) => ({ ...prev, page: 1 }));
  }, [c_searchData]);

  useEffect(() => {
    call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
  }, [s_searchValue]);

  useEffect(() => {
    if (call_getTxnHd.status === "suc") {
      const { tableData, pageInfo } = call_getTxnHd.data;
      const newTableData = tableData.map((x, i) => ({ ...x, key: x.planN, index: i }));

      set_s_tableData(newTableData);
      set_s_pageInfo(pageInfo);

      // 更新 c_lightData
      if (c_lightData.planN && !newTableData.some((x) => x.planN === c_lightData.planN)) {
        set_c_lightData({});
      } else {
        const thisData = newTableData.find((x) => x.planN === c_lightData.planN);
        set_c_lightData(thisData ? { ...thisData, key: thisData.planN } : {});
      }
      message.success(call_getTxnHd.msg);
    } else if (call_getTxnHd.status === "err") {
      set_s_tableData([]);
      message.error(call_getTxnHd.msg);
    }
  }, [call_getTxnHd.status]);

  useEffect(() => {
    if (call_txn.status === "suc") {
      message.success(call_txn.msg);
      call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
    } else if (call_txn.status === "err") {
      message.error(call_txn.msg);
    }
  }, [call_txn.status]);

  useEffect(() => {
    if (call_deleteTxnHd.status === "suc") {
      message.success(call_deleteTxnHd.msg);
      call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
    } else if (call_deleteTxnHd.status === "err") {
      message.error(call_deleteTxnHd.msg);
    }
  }, [call_deleteTxnHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getTxnHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
        pagination={{
          total: s_pageInfo.totalItems,
          current: s_searchValue.page,
          pageSize: s_searchValue.pageSize,
          onChange: (current, size) =>
            set_s_searchValue((prev) => ({
              ...prev,
              page: prev.pageSize !== size ? 1 : current,
              pageSize: size,
            })),
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
            : `${t("util.util.add")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
        }}
      >
        <ModalContainer
          opcode={c_searchData.opcode}
          set_s_showModal={set_s_showModal}
          s_editData={c_lightData}
        />
      </CustomModal>
    </>
  );
});

export default Production;
