import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import ExcelJs from "exceljs";
import * as XLSX from 'xlsx-js-style';
import dayjs from "dayjs";
// component
import Page from "./Page";
// util function
import { formatAmount } from "@/util/format";
import { toInteger } from "lodash";


const RequisitionDrawer = () => {

  const [showPages, set_showPages] = useState(false);
  const printRef = useRef(null);


  const Print = useReactToPrint({
    content: () => {
      console.log(printRef.current);
      return printRef.current;
    },
    bodyClass: "print-style",
    onAfterPrint: () => {
      set_showPages(false);
    },
    pageStyle: () => `
    body{
      font-size:1px !important;
      -webkit-print-color-adjust: exact; 
      overflow: visible;
    }
    @page {
      size:A4 portrait;
      margin: 5mm 5mm 5mm 5mm;
    }

    #content1{
      counter-reset: page ;
    }

    #page:before {
      counter-increment: page ;
    }
  `,
  });

  // 一頁最多六筆資料, 每一個<Page />都吃一個陣列
  const getPages = (array, pageSize) => {
    return array.reduce((prev, curr, index) => {
      // 計算當前明細屬於第幾頁
      const page = Math.floor(index / pageSize) + 1;
      // 計算當前頁的資料陣列
      const data = prev[page - 1] ? prev[page - 1].data : [];
      // 將當前元素加入當前頁的資料陣列
      curr.itemno = index + 1;
      prev[page - 1] = { currentPage: page, data: [...data, curr] };
      return prev;
    }, []);
  };

  // 一頁最多有幾筆明細
  const page_length = 10;

  const trt = [{
    no:"1",
    PN:"111",
    pdtNM:"料號111",
    unit:"KG",
    demandqty:1000,
    issuedqty:1000,
    note:"note1111111111111111111111111",
  },{
    no:"2",
    PN:"222",
    pdtNM:"料號222",
    unit:"KG",
    demandqty:2000,
    issuedqty:2000,
    note:"note22222222222222222222222222",
  },{
    no:"3",
    PN:"333",
    pdtNM:"料號333",
    unit:"PCS",
    demandqty:3000,
    issuedqty:3000,
    note:"note333333333333333333333333333333",
  },
]

  // 計算總共有幾頁
  const totalPages = Math.floor(trt.length / page_length) + 1;
  // 一頁最多顯示六筆明細
  const renderdata = getPages(trt, page_length);
  console.log("renderdata = ", renderdata);
  // console.log("totalPages = ", totalPages);
  //等待總頁數state更新完成才顯示瀏覽器列印視窗，不然總頁數無法顯示正常
  const handlePrint = async () => {
    await setTimeout(() => {
      Print();
    }, 500);
  };

  //----------------------------EXCEL---------------------------------------------------
  // const exportToExcel = () => {

  //   // 創建工作簿
  //   const workbook = XLSX.utils.book_new();
  //   let ws = XLSX.utils.aoa_to_sheet([[]]);
  //   let mergeCell = [];

  //   //表頭儲存格陣列
  //   const excelHeader = [
  //     [{
  //       v: 'PUR-FM-L01',
  //       s: {
  //         font: {
  //           sz: 10,
  //         },
  //       }
  //     }, , , , , , {
  //       v: 'No. BPPB  : 033 / PURC / 10 / 2022',
  //       s: {
  //         font: {
  //           sz: 10,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         }
  //       }
  //     }],
  //     [],
  //     [{
  //       v: '(LOGO) PT. YUJU INDONESIA',
  //       s: {
  //         font: {
  //           sz: 13,
  //           bold: true
  //         }
  //       }
  //     }],
  //     [{
  //       v: 'Kawasan Industri NEWTON TECHNO PARK JL. JATI I BLOK J5 - NO. 5 LIPPO CIKARANG - BEKASI Telp ( 899 - 01460 - 62 ) Fax ( 899 - 01763 )',
  //       s: {
  //         font: {
  //           sz: 8,
  //         }
  //       }
  //       ,
  //     }],
  //     [{
  //       v: 'PURCHASE ORDER',
  //       s: {
  //         font: {
  //           sz: 24,
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         }
  //       }

  //     }],
  //     [],
  //     [{
  //       v: 'NO. SUPPLIER:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , {
  //       v: hd_values.suppID,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , , , {
  //       v: 'PO NO.:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, {
  //       v: hd_values.dealN,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, ,],
  //     [{
  //       v: 'SUPPLIER:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , {
  //       v: hd_values.suppNM,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , , , {
  //       v: 'DATE:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, {
  //       v: dayjs(hd_values.createT).format("YYYY-MM-DD"),
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, ,],
  //     [{
  //       v: 'CONTACT PERSON:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , {
  //       v: hd_values.dealcontact,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , , , {
  //       v: 'DELIVERY:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, {
  //       v: `${dayjs(hd_values.startEDT).format("YYYY-MM-DD")}~${dayjs(hd_values.endEDT).format("YYYY-MM-DD")}`,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, ,],
  //     [{
  //       v: 'TELP / FAX NO:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , {
  //       v: hd_values.dealtel,
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, , , , {
  //       v: 'NO.WORK:',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, {
  //       v: 'U/PT. VALEO SU2',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //       },
  //     }, ,],
  //     [],
  //     [{
  //       v: 'no',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'ITEM KODE',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }

  //     }, {
  //       v: 'SPECIFICATION',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'STN',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'PRICE',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'AMOUNT',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           horizontal: "center",
  //           vertical: "center",
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'REMARK',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           horizontal: "center",
  //           vertical: "center",
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }],
  //     [{
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'KG',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: 'RP',
  //       s: {
  //         font: {
  //           bold: true,
  //         },
  //         alignment: {
  //           horizontal: "center",
  //           vertical: "center",
  //         },
  //         border: {
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'medium' },
  //         }
  //       }
  //     }],
  //     [],
  //   ]

  //   // 表頭合併的儲存格
  //   const mergeHeader = [
  //     //表頭開始
  //     { s: { r: 0, c: 6 }, e: { r: 0, c: 8 } },
  //     { s: { r: 2, c: 0 }, e: { r: 2, c: 9 } },
  //     { s: { r: 3, c: 0 }, e: { r: 3, c: 9 } },
  //     { s: { r: 4, c: 0 }, e: { r: 4, c: 9 } },
  //     { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } },
  //     { s: { r: 6, c: 2 }, e: { r: 6, c: 4 } },
  //     { s: { r: 6, c: 7 }, e: { r: 6, c: 9 } },

  //     { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } },
  //     { s: { r: 7, c: 2 }, e: { r: 7, c: 4 } },
  //     { s: { r: 7, c: 7 }, e: { r: 7, c: 9 } },

  //     { s: { r: 8, c: 0 }, e: { r: 8, c: 1 } },
  //     { s: { r: 8, c: 2 }, e: { r: 8, c: 4 } },
  //     { s: { r: 8, c: 7 }, e: { r: 8, c: 9 } },

  //     { s: { r: 9, c: 0 }, e: { r: 9, c: 1 } },
  //     { s: { r: 9, c: 2 }, e: { r: 9, c: 4 } },
  //     { s: { r: 9, c: 7 }, e: { r: 9, c: 9 } },

  //     //表格標頭
  //     { s: { r: 11, c: 0 }, e: { r: 12, c: 0 } }, //NO
  //     { s: { r: 11, c: 1 }, e: { r: 12, c: 1 } }, //ITEM KODE
  //     { s: { r: 11, c: 2 }, e: { r: 12, c: 4 } }, //SPEC

  //     { s: { r: 11, c: 7 }, e: { r: 11, c: 8 } },//AMOUNT
  //     { s: { r: 12, c: 7 }, e: { r: 12, c: 8 } },//RP
  //     { s: { r: 11, c: 9 }, e: { r: 12, c: 9 } }, //REMARK
  //     //表頭結束

  //     //資料開始跟表頭間隔14行
  //   ];

  //   //將表頭陣列加入資料表)
  //   XLSX.utils.sheet_add_aoa(ws, excelHeader, { origin: 'A1' });

  //   mergeCell.push(...mergeHeader);


  //   // 把表身資料加到資料表
  //   const data = s_proTb.tb.map((item, index) => {
  //     return [
  //       {
  //         v: `${index + 1}`,
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       },
  //       {
  //         v: `${item.PN}`,
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       },
  //       {
  //         v: `${item.pdtspec}`,
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       },
  //       {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       },
  //       {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       },
  //       {
  //         v: formatAmount(item.confirmqty),
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         },

  //       },
  //       {
  //         v: formatAmount(item.unitprice),
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       },
  //       {
  //         v: formatAmount(item.itemsum),
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       },
  //       {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           numFmt: 3
  //         }
  //       },
  //       {
  //         v: `${item.note}`,
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       }
  //     ]
  //   })

  //   // 从表格標題列下方一行开始添加表身数据
  //   XLSX.utils.sheet_add_aoa(ws, data, { origin: 'A15' });

  //   //表身合併的儲存格
  //   const mergeData = [];
  //   for (let i = 0; i < s_proTb.tb.length; i++) {
  //     mergeData.push(
  //       { s: { r: 14 + i, c: 2 }, e: { r: 14 + i, c: 4 } },
  //       { s: { r: 14 + i, c: 7 }, e: { r: 14 + i, c: 8 } },
  //     )
  //   }

  //   mergeCell.push(...mergeData);

  //   //表尾儲存格陣列
  //   const excelFooter = [
  //     [{
  //       v: 'SUBTOTAL',
  //       s: {
  //         font: {
  //           bold: true
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: formatAmount(hd_values.sumtotal),
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "right"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         numFmt: 3
  //       }
  //     }, {
  //       v: 'AAA',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'thin' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //       }
  //     }],
  //     [
  //       {
  //         v: 'PPN11%',
  //         s: {
  //           font: {
  //             bold: true
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: formatAmount(hd_values.tax),
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: 'BBB',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       }],
  //     [
  //       {
  //         v: 'TOTAL',
  //         s: {
  //           font: {
  //             bold: true
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: formatAmount(hd_values.total),
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }, {
  //         v: 'CCC',
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       }],
  //     ['PAYMENT CONDITION : ONE MONTH (45 DAYS AFTER INVOICE )', , , , , , , , , ,],
  //     [],
  //     [],
  //     [],
  //     [, , , , , , {
  //       v: '',
  //       s: {
  //         border: {
  //           bottom: { style: 'medium' },
  //         },
  //       }
  //     }, {
  //         v: '',
  //         s: {
  //           border: {
  //             bottom: { style: 'medium' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             bottom: { style: 'medium' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             bottom: { style: 'medium' },
  //           },
  //         }
  //       },],
  //     [],
  //     [{
  //       v: 'NO',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         fill: {
  //           fgColor: { rgb: "E0E0E0" },
  //         }
  //       }
  //     }, {
  //       v: 'OLD PRICE',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         fill: {
  //           fgColor: { rgb: "E0E0E0" },
  //         }
  //       }
  //     }, {
  //       v: 'NEW PRICE',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         fill: {
  //           fgColor: { rgb: "E0E0E0" },
  //         }
  //       }
  //     }, {
  //       v: 'STATUS',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         fill: {
  //           fgColor: { rgb: "E0E0E0" },
  //         }
  //       }
  //     }, {
  //       v: '%',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'medium' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         fill: {
  //           fgColor: { rgb: "E0E0E0" },
  //         }
  //       }
  //     }, , {
  //       v: 'APPROVAL,',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'medium' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //       }
  //     }, {
  //       v: 'CHECKED,',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //       }
  //     }, {
  //       v: '',
  //       s: {
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //       }
  //     }, {
  //       v: 'PREPARED,',
  //       s: {
  //         alignment: {
  //           vertical: "center",
  //           horizontal: "center"
  //         },
  //         border: {
  //           top: { style: 'medium' },
  //           bottom: { style: 'medium' },
  //           left: { style: 'thin' },
  //           right: { style: 'medium' },
  //         },
  //       }
  //     }],
  //     [, , , , , , {
  //       v: '',
  //       s: {
  //         border: {
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //       }
  //     }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }],
  //     [, , , , , , {
  //       v: '',
  //       s: {
  //         border: {
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //       }
  //     }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }],
  //     [, , , , , , {
  //       v: '',
  //       s: {
  //         border: {
  //           left: { style: 'thin' },
  //           right: { style: 'thin' },
  //         },
  //       }
  //     }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: '',
  //         s: {
  //           border: {
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }],
  //     [, , , , , ,
  //       {
  //         v: 'Mr.Zack',
  //         s: {
  //           font: {
  //             sz: 10,
  //             italic: true
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: 'Mr.Philbert',
  //         s: {
  //           font: {
  //             sz: 10,
  //             italic: true
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: 'Mr.Budi',
  //         s: {
  //           font: {
  //             sz: 10,
  //             italic: true
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }, {
  //         v: 'Tria',
  //         s: {
  //           font: {
  //             sz: 10,
  //             italic: true
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //         }
  //       }]
  //   ]

  //   //新舊價格陣列
  //   const priceDiff =
  //     [
  //       ['1', '24,805', '25,434', 'YES', '↑3%'],
  //       ['2', '21,240', '22,222', 'NO', '↑1%'],
  //     ]

  //   //新舊價格excel陣列
  //   const priceDiff_ExcelArray = priceDiff.map((item, index) => {
  //     return [
  //       {
  //         v: item[0],
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       },
  //       {
  //         v: item[1],
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       },
  //       {
  //         v: item[2],
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "right"
  //           },
  //           numFmt: 3
  //         }
  //       },
  //       {
  //         v: item[3],
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //         }
  //       },
  //       {
  //         v: item[4],
  //         s: {
  //           border: {
  //             top: { style: 'thin' },
  //             bottom: { style: 'thin' },
  //             left: { style: 'thin' },
  //             right: { style: 'thin' },
  //           },
  //           alignment: {
  //             vertical: "center",
  //             horizontal: "center"
  //           },
  //           numFmt: 3
  //         }
  //       }
  //     ]
  //   })

  //   XLSX.utils.sheet_add_aoa(ws, excelFooter, { origin: `A${15 + s_proTb.tb.length}` });
  //   XLSX.utils.sheet_add_aoa(ws, priceDiff_ExcelArray, { origin: `A${15 + s_proTb.tb.length + 10}` });


  //   // 表尾合併的儲存格
  //   const mergeFooter = [
  //     // 表尾開始
  //     { s: { r: 14 + s_proTb.tb.length, c: 0 }, e: { r: 14 + s_proTb.tb.length, c: 4 } },//總計
  //     { s: { r: 14 + s_proTb.tb.length, c: 7 }, e: { r: 14 + s_proTb.tb.length, c: 8 } },
  //     { s: { r: 14 + s_proTb.tb.length + 1, c: 0 }, e: { r: 14 + s_proTb.tb.length + 1, c: 4 } },
  //     { s: { r: 14 + s_proTb.tb.length + 1, c: 7 }, e: { r: 14 + s_proTb.tb.length + 1, c: 8 } },
  //     { s: { r: 14 + s_proTb.tb.length + 2, c: 0 }, e: { r: 14 + s_proTb.tb.length + 2, c: 4 } },
  //     { s: { r: 14 + s_proTb.tb.length + 2, c: 7 }, e: { r: 14 + s_proTb.tb.length + 2, c: 8 } },
  //     // ----------------------------------------------------------------------------------------------------------------------
  //     { s: { r: 14 + s_proTb.tb.length + 3, c: 0 }, e: { r: 14 + s_proTb.tb.length + 3, c: 9 } }, //付款方式
  //     { s: { r: 14 + s_proTb.tb.length + 7, c: 6 }, e: { r: 14 + s_proTb.tb.length + 7, c: 9 } },//右下角簽核區上方底線
  //     { s: { r: 14 + s_proTb.tb.length + 9, c: 7 }, e: { r: 14 + s_proTb.tb.length + 9, c: 8 } },//右下角簽核區
  //     // 表尾結束
  //   ];

  //   mergeCell.push(...mergeFooter);


  //   //輸出時的欄位寬度
  //   const colwidth = [
  //     { width: 5 },
  //     { width: 23 },
  //     { width: 23 },
  //     { width: 8 },
  //     { width: 8 },
  //     { width: 12 },
  //     { width: 12 },
  //     { width: 10 },
  //     { width: 10 },
  //     { width: 15 },
  //   ]

  //   ws['!merges'] = mergeCell;
  //   ws['!cols'] = colwidth;
  //   XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');

  //   // 导出工作簿到本地文件
  //   XLSX.writeFile(workbook, '採購單.xlsx');

  // }

  return (
    <>
      <div className="mb-2">
        <Button type="primary" size="large" onClick={handlePrint}>
          列印
        </Button>
        <Button className="ml-2" type="primary" size="large" 
        // onClick={exportToExcel}
        >
          匯出excel
        </Button>
        {/* <Button className="ml-2" type="primary" size="large" onClick={printDocument}>
          匯出PDF
        </Button> */}
      </div>
      <div
        id="content1"
        ref={printRef}
        // style={{ minHeight: `${totalPages * 100}vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
        style={{ minHeight: `${renderdata.length * 100}vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
        className="flex flex-col items-start w-full font-semibold text-black"
      >
        {renderdata.map((item, index) => (
          <Page
            key={index}
            pageData={item.data}
            currentPage={item.currentPage}
            // totalPages={totalPages}
            totalPages={renderdata.length}
            // hd_values={hd_values}
          />
        ))}
      </div>
    </>
  );

};

export const PrintText = ({ children, ...props }) => (
  <span className="text-[14px]" {...props}>
    {children}
  </span>
);

export default RequisitionDrawer;
