import React from "react";
import { Row, Col, Divider, Tooltip } from "antd";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";

const options = [
  { key: "dataAmount", value: "0", keyNM: "檢測數量：" },
  { key: "samplesize", value: "0", keyNM: "樣本大小：" },
  { key: "groupAmount", value: "0", keyNM: "組數：" },
  { key: "max", value: "0", keyNM: "最大值：" },
  { key: "min", value: "0", keyNM: "最小值：" },
  { key: "average", value: "0", keyNM: "平均值：" },
  { key: "standard", value: "0", keyNM: "標準差：" },
  { key: "averageUCL", value: "0", keyNM: "UCL(x-bar)：" },
  { key: "averageCL", value: "0", keyNM: "CL(x-bar)：" },
  { key: "averageLCL", value: "0", keyNM: "LCL(x-bar)：" },
  { key: "rangeUCL", value: "0", keyNM: "UCL(r)：" },
  { key: "rangeCL", value: "0", keyNM: "CL(r)：" },
  { key: "rangeLCL", value: "0", keyNM: "LCL(r)：" },
  { key: "Ca", value: "0", keyNM: "Ca：" },
  { key: "Cp", value: "0", keyNM: "Cp：" },
  { key: "Cpk", value: "0", keyNM: "Cpk：" },
  { key: "Pp", value: "0", keyNM: "Pp：" },
  { key: "Ppk", value: "0", keyNM: "Ppk：" },
  // { key: "count", value: "0", keyNM: "估計標準差：" },
  { key: "USL", value: "0", keyNM: "規格上限：" },
  { key: "LSL", value: "0", keyNM: "規格下限：" },
];

const DetailCardStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.27);
  background-color: #fff;
  padding-bottom: 15px;
  .align-right {
    text-align: right;
  }
  .align-certer {
    text-align: center;
  }
  .detailCard-header {
    color: white;
    background-color: #0d6ad2;
    border-radius: 15px 15px 0 0;
    height: 150px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
  .detailCard-wl {
    font-size: 34px;
    margin-bottom: 8px;
  }
  .detailCard-text {
    width:100%;
    text-align:center;
    font-size: 20px;
    margin-bottom: 4px;
    letter-spacing: 2px;
    white-space: nowrap; /*//强制文本在一行内输出 */
    overflow: hidden; /*隐藏溢出部分*/
    text-overflow: ellipsis; /*//對溢出部分加上...*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .detailCard-divider {
    margin: 0 !important;
  }
`;

const DetailCardItem = ({ value, keyNM }) => {
  return (
    <Row>
      <Col span={12} className="align-right">
        {keyNM}
      </Col>
      <Col offset={4} span={8}>
        {value}
      </Col>
    </Row>
  );
};

export default function DetailCard({ detailData,weData }) {

  const [filterData, setFilterData] = useState(options)

  useEffect(()=>{
    if (!detailData) return
    setFilterData(prev=>{
      for (const item of prev){
        item.value = detailData[item.key]
      }
      return prev
    })
  },[detailData])

  return (
    <DetailCardStyle>
      <div className="detailCard-header">
        {/* <div className="detailCard-wl">L01</div>
        <div className="detailCard-text">料號: PJFSDF4242442JJ45</div> */}
        <Tooltip title={weData.weID}>
          <div className="detailCard-text">
            機碼: {weData.weID}
          </div>
        </Tooltip>
        <Tooltip title={weData.weNM} placement="bottom">
          <div className="detailCard-text">
            機名: {weData.weNM}
          </div>
        </Tooltip>

      </div>
      
      <Row gutter={[24,6]}>
        {filterData.map((x, i) => (
          <Col key={x.key} span={24}>
            <DetailCardItem {...x}/>
          </Col>
        ))}
        <Col span={24}>
          <Divider className="detailCard-divider"/>
        </Col>
        <Col span={24}><DetailCardItem value={detailData?detailData.SN:""} keyNM="序號："/></Col>
        <Col span={24}><DetailCardItem value={detailData?detailData.groupAvg:""} keyNM="組平均值："/></Col>
        <Col span={24}><DetailCardItem value={detailData?detailData.groupRange:""} keyNM="組全距："/></Col>
      </Row>
    </DetailCardStyle>
  );
}
