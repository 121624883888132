import { useState } from "react";

import HD from "./HD";
// import TB from "./TB";

const Head = () => {
 

  return (
    <>
      <HD  />
      {/* {Object.keys(c_lightData).length > 0 && (
        <TB c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      )} */}
    </>
  );
};

export default Head;