/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Input, InputNumber, Modal, Space, message } from "antd";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import {
  deleteProduct,
  getProduct,
  getSuppHd,
  searchDeal,
  updateBatchbom,
} from "@/service/apis/ADM/pnManage";
import { getUnit } from "@/service/apis/ADM/systemParamsManage";
import { multipleIDtoName } from "@/util/format";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer/index";

// util

const Wrapper = styled.div`
  // 編輯Table用
  .editable-cell {
    position: relative;
  }
  // 編輯Table用
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  // 編輯Table用
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    min-height: 32px;
    position: absolute;
    bottom: 8px;
    width: 90%;
  }

  .editable-row:hover .antd-table-cell {
    position: relative;
  }

  // 編輯Table用
  [data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "note" ? (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
          />
        ) : (
          <InputNumber
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={0}
            style={{ width: "100%" }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();

  // arrMap = 把各種ID轉成NM所需要的資料
  const { arrMap, s_tabKey, s_searchData, set_s_searchData, c_lightData, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const call_getProduct = useAPI(getProduct);
  const call_searchDeal = useAPI(searchDeal);
  const call_getSuppHd = useAPI(getSuppHd);
  const call_deleteProduct = useAPI(deleteProduct);
  const call_getUnit = useAPI(getUnit);
  const call_updateBatchbom = useAPI(updateBatchbom);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => call_getProduct.request({ ...s_searchData, page: 1, pageSize: 200 }),
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
    onDealSearch: () => {
      call_searchDeal.request(s_searchData);
    },
  }));

  // 這邊要求的格式跟 multipleIDtoName 不同，所以獨立寫出來用
  const mgmtcat75_map = useMemo(() => {
    return arrMap.mgmtcat75?.reduce((prev, curr) => {
      prev[curr.mgmtitem] = curr.itemNM;
      return prev;
    }, {});
  }, [arrMap.mgmtcat75]);

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "每車數量",
      dataIndex: "batchbom",
      align: "right",
      editable: true,
      width: "10%",
      sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      align: "left",
      width: "16%",
      sorter: (a, b) => a.pdtNM.localeCompare(b.pdtNM),
    },
    {
      title: t("util.util.pdtspec"),
      dataIndex: "pdtspec",
      align: "left",
      width: "8%",
      sorter: (a, b) => a.pdtspec.localeCompare(b.pdtspec),
    },
    {
      title: t("util.util.mtype"),
      dataIndex: "mtype",
      align: "left",
      width: "5%",
      sorter: (a, b) => a.mtype.toString().localeCompare(b.mtype.toString()),
      // 這邊要求的格式跟 multipleIDtoName 不同，所以獨立寫出來用
      render: (text) => mgmtcat75_map[text],
    },
    {
      title: t("util.util.attribute"),
      dataIndex: "attribute",
      align: "center",
      className: "bg-[#f9e9d7ac]",
      render: (text) => arrMap.attributeMap.find((x) => x.value === text)?.label,
      width: "6%",
    },
    {
      title: t("util.util.pdtclass"),
      dataIndex: "pdtclass",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      render: (text) => arrMap.pdtclassMap.find((x) => x.value === text)?.label,
      width: "8%",
    },
    {
      title: t("util.util.querycat"),
      dataIndex: "querycat",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8%",
      render: (text) =>
        multipleIDtoName(arrMap.mgmtcat74, text, "mgmtitem", "itemNM") || t("util.util.all"),
    },
    {
      title: t("util.util.mgmtcat"), // 授權分類
      dataIndex: "mgmtcat",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8%",
      render: (text) =>
        multipleIDtoName(arrMap.mgmtcat71, text, "mgmtitem", "itemNM") || t("util.util.all"),
    },
    {
      title: t("util.util.unit"),
      dataIndex: "unit",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.opunit"),
      dataIndex: "opunit",
      width: "5%",
      align: "center",
    },
    {
      title: t("ADM.pn-manage.bom"), //整包數
      dataIndex: "pbom",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.isfocus"),
      dataIndex: "isfocus",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.isfocus - b.isfocus,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "4.5%",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "6%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const handleSave = (data, dataIndex, value) => {
    //防止輸入非數字
    if (isNaN(value)) return;
    console.log(
      "要傳給後端的資料 = ",
      {
        ...data,
        [dataIndex]: value,
      },
      dataIndex,
      value
    );
    call_updateBatchbom.request({
      ...data,
      [dataIndex]: value,
    });
    call_getProduct.request(s_searchData);
  };

  const columns = tableColumns
    .filter((col) => !col.hidden)
    .map((col) => {
      if (!col.editable) return col;

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          // style: {
          //   backgroundColor: record?.lifeF === 'T' ? '#e1dbdb' : '',
          //   pointerEvents: record?.lifeF === 'T' ? 'none' : '',
          //   opacity: record?.lifeF === 'T' ? '.5' : '1',
          //   color: record?.lifeF === 'T' ? '#b6b1b1' : record?.confirmqty === 0 ? '#b6b1b1' : '#FF850A'
          // }
        }),
      };
    });

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteProduct.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getSuppHd.request();
    call_getUnit.request();
  }, []);

  useEffect(() => {
    // 這樣寫是因為『交易料號查詢』call 的是另一條 api
    // 而且只有在點擊搜尋時才需要 call『交易料號查詢』的 api
    if (s_tabKey === "basic") {
      call_getProduct.request(s_searchData);
    }
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getProduct.status === "suc") {
      message.success(call_getProduct.msg);
      set_s_tableData(
        call_getProduct.data.tableData?.map((x, i) => {
          // 整理資料
          const querycat = x.querycat.split("|").filter((x) => x !== "");
          const mgmtcat = x.mgmtcat.split("|").filter((x) => x !== "");
          const heirdealID = x.heirdealID.split("|").filter((x) => x !== "");
          const mtype = x.mtype.split("|").filter((x) => x !== "");
          return {
            ...x,
            key: i,
            index: i,
            querycat: querycat,
            mgmtcat: mgmtcat,
            heirdealID: heirdealID,
            mtype: mtype,
          };
        })
      );
      set_s_pageInfo(call_getProduct.data.pageInfo);
    } else if (call_getProduct.status === "err") {
      message.error(t(`error.error-code.${call_getProduct.msg}`));
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getProduct.status]);
  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_updateBatchbom.status === "suc") {
      message.success(call_updateBatchbom.msg);
      call_getProduct.request(s_searchData);
    } else if (call_updateBatchbom.status === "err") {
      message.error(t(`error.error-code.${call_updateBatchbom.msg}`));
    }
  }, [call_updateBatchbom.status]);
  useEffect(() => {
    if (call_deleteProduct.status === "suc") {
      message.success(call_deleteProduct.msg);
      // delete之後 重call表格資料
      call_getProduct.request(s_searchData);
    } else if (call_deleteProduct.status === "err") {
      message.error(t(`error.error-code.${call_deleteProduct.msg}`));
    }
  }, [call_deleteProduct.status]);

  // 透過交易料好取得 tabledata
  useEffect(() => {
    const { data, status, msg } = call_searchDeal;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => {
          // 整理資料
          const querycat = x.querycat.split("|").filter((x) => x !== "");
          const mgmtcat = x.mgmtcat.split("|").filter((x) => x !== "");
          const heirdealID = x.heirdealID.split("|").filter((x) => x !== "");
          const mtype = x.mtype.split("|").filter((x) => x !== "");
          return {
            ...x,
            key: i,
            index: i,
            querycat: querycat,
            mgmtcat: mgmtcat,
            heirdealID: heirdealID,
            mtype: mtype,
          };
        })
      );
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_tableData([]);
    }
  }, [call_searchDeal.status]);

  // 不要刪掉這個鬼東西
  // function handleClick(event) {
  //   event.preventDefault();
  //   if (event.detail === 1) {
  //     if (event.button === 0) {
  //       console.log("滑鼠左鍵單擊了");
  //     } else if (event.button === 2) {
  //       console.log("滑鼠右鍵單擊了");
  //     }
  //   } else if (event.detail === 2) {
  //     if (event.button === 0) {
  //       console.log("滑鼠左鍵雙擊了");
  //     } else if (event.button === 2) {
  //       console.log("滑鼠右鍵雙擊了");
  //     }
  //   }
  //   event.preventDefault();
  // }

  // 不要刪掉這個鬼東西
  // function handleContextMenu(event) {
  //   event.preventDefault();
  //   console.log("event.detail = ", event.detail);
  //   if (event.button === 2 && event.detail === 2) {
  //     console.log("滑鼠右鍵雙擊了");
  //     return;
  //   }
  //   console.log("滑鼠右鍵單擊了");
  // }

  return (
    <>
      {/* <div onClick={handleClick} onDoubleClick={handleClick} onContextMenu={handleContextMenu}>
        不要刪掉這個鬼東西
      </div> */}
      <Wrapper>
        <CustomTable
          columns={columns}
          components={components}
          dataSource={s_tableData}
          loading={call_getProduct.status === "load"}
          tbMode={Object.keys(c_lightData).length > 0}
          c_lightData={c_lightData}
          onRow={(record) => {
            return {
              onClick: () =>
                record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
            };
          }}
          pagination={{
            total: s_pageInfo?.totalItems,
            current: s_searchData.page,
            pageSize: s_searchData.pageSize,
            onChange: (current, size) =>
              set_s_searchData((prev) => {
                // 如果有更動pageSize 回第一頁
                let checkPage = prev.pageSize !== size ? 1 : current;
                return { ...prev, page: checkPage, pageSize: size };
              }),
          }}
        />

        <CustomModal
          title={
            Object.keys(c_lightData).length > 0
              ? `${t("util.util.edit")}${t("ADM.pn-manage.basicProduct")}`
              : `${t("util.util.add")}${t("ADM.pn-manage.basicProduct")}`
          }
          width={"60%"}
          open={s_isShowModal}
          onCancel={() => set_s_isShowModal(false)}
          afterClose={() => {
            set_c_lightData({});

            s_tabKey === "dealSearch"
              ? call_searchDeal.request(s_searchData)
              : call_getProduct.request(s_searchData);
          }}
        >
          <ModalConintaer
            s_editData={c_lightData}
            set_s_editData={set_c_lightData}
            set_s_isShowModal={set_s_isShowModal}
            arrMap={arrMap}
          />
        </CustomModal>
      </Wrapper>
    </>
  );
});

export default HD;
