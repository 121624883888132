import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Radio,
  Space,
  Divider,
} from "antd";

import { getFunctions, getPushlvl, addStaff, modifyUser } from "@/service/apis/ADM/userManage";
import { getDept, getPost } from "@/service/apis/ADM/functionParamsManage";
import { getPWC } from "@/service/apis/ADM/publicAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addStaff = useAPI(addStaff);
  const call_modifyUser = useAPI(modifyUser);
  const call_getDept = useAPI(getDept);
  const call_getPost = useAPI(getPost);
  const call_getFunctions = useAPI(getFunctions); // 取得組織功能
  const call_getPushlvl = useAPI(getPushlvl); // 取得推播層級
  const call_getMgmtcat = useAPI(getMgmtcat); // 取得推播層級
  const call_getPwc = useAPI(getPWC);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.function && values.function.length > 0) {
      values.function = values.function.join("|");
    }
    if (values.dealmgmt && values.dealmgmt.length > 0) {
      values.dealmgmt = values.dealmgmt.join("|");
    }
    if (values.whmgmt && values.whmgmt.length > 0) {
      values.whmgmt = values.whmgmt.join("|");
    }
    if (values.pdtmgmt && values.pdtmgmt.length > 0) {
      values.pdtmgmt = values.pdtmgmt.join("|");
    }

    if (values.plantID && values.plantID.length > 0) {
      values.plantID = values.plantID.join("|");
    }
    if (values.pwcID && values.pwcID.length > 0) {
      values.pwcID = values.pwcID.join("|");
    }

    const quitT = dayjs(values.quitT).isValid() ? new Date(values.quitT) : null;

    if (type === "create") {
      call_addStaff.request({ ...values, quitT });
    }

    if (type === "edit") {
      call_modifyUser.request({ ...values, quitT });
    }
  };

  useEffect(() => {
    call_getDept.request();
    call_getPost.request();
    call_getPushlvl.request();
    call_getFunctions.request();
    call_getMgmtcat.request({ pageType: 71 });
    call_getPwc.request();
  }, []);

  useEffect(() => {
    if (call_addStaff.status === "suc") {
      message.success(call_addStaff.msg);
      set_s_isShowModal(false);
    } else if (call_addStaff.status === "err") {
      message.error(t(`error.error-code.${call_addStaff.msg}`));
    }
  }, [call_addStaff.status]);

  useEffect(() => {
    if (call_modifyUser.status === "suc") {
      message.success(call_modifyUser.msg);
      set_s_isShowModal(false);
    } else if (call_modifyUser.status === "err") {
      message.error(t(`error.error-code.${call_modifyUser.msg}`));
    }
  }, [call_modifyUser.status]);

  useEffect(() => {
    if (call_getPost.status === "suc") {
      console.log("call_getPost = ", call_getPost);
    }
    if (call_getPost.status === "err") {
      message.error(call_getPost.msg);
    }
  }, [call_getPost.status]);

  useEffect(() => {
    if (call_getDept.status === "suc") {
      console.log("call_getDept = ", call_getDept);
    }
    if (call_getDept.status === "err") {
      message.error(t(`error.error-code.${call_getDept.msg}`));
    }
  }, [call_getDept.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Form.Item name="enterT" hidden>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={"廠別"} name="plantID" rules={[{ required: true }]}>
            <Select
              mode="tags"
              placeholder="請選擇廠別"
              options={[
                { label: "全場", value: "0000" },
                { label: "科技廠_車圈", value: "1001" },
                { label: "科技廠_輪組", value: "1002" },
                { label: "科技廠_碳圈", value: "1003" },
                { label: "科技廠_鋁擠", value: "1004" },
                { label: "熔煉廠", value: "1005" },
                { label: "擠型場_擠一二三", value: "1006" },
                { label: "航太廠_航太一", value: "1011" },
                { label: "航太廠_航太二", value: "1012" },
                { label: "大造熊", value: "1021" },
                { label: "鼎特力", value: "1031" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item label={"工作中心"} name="pwcID">
            <Select
              allowClear
              showSearch
              mode="tags"
              placeholder="請選擇工作中心"
              options={
                call_getPwc?.data?.map((x) => ({
                  label: x.pwcID + "_" + x.pwcNM,
                  value: x.pwcID,
                })) || []
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 員工工號 */}
          <Form.Item
            label={t("ADM.user-manage.staffID")}
            name="staffID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 員工姓名 */}
          <Form.Item
            label={t("ADM.user-manage.peopleNM")}
            name="peopleNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 卡勤識別碼 */}
          <Form.Item label={t("ADM.user-manage.cardID")} name="cardID">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 身份證號 */}
          <Form.Item label={t("ADM.user-manage.personalID")} name="personalID">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 電話 */}
          <Form.Item label={t("util.util.tel")} name="tel">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 郵址 */}
          <Form.Item label={t("ADM.util.mail")} name="mail">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 聯絡人姓名 */}
          <Form.Item label={t("ADM.user-manage.proxyNM")} name="proxyNM">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 部門 */}
          <Form.Item label={t("util.util.dept")} name="deptID">
            <Select
              allowClear
              options={
                call_getDept.data
                  ?.filter((x) => x.isvalid)
                  .reduce((data, current) => {
                    return [
                      ...data,
                      {
                        label: current.deptNM,
                        value: current.deptID,
                      },
                    ];
                  }, []) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 聯絡人電話 */}
          <Form.Item label={t("ADM.user-manage.proxytel")} name="proxytel">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>

        <Col span={8}>
          {/* 職稱 */}
          <Form.Item label={t("ADM.util.postID")} name="postID">
            <Select
              showArrow
              options={
                call_getPost.data
                  ?.filter((x) => x.isvalid)
                  .reduce((data, current) => {
                    return [
                      ...data,
                      {
                        label: current.postNM,
                        value: current.postID,
                      },
                    ];
                  }, []) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 組織功能 */}
          <Form.Item label={t("ADM.user-manage.function")} name="function">
            <Select
              mode="multiple"
              showArrow
              allowClear
              options={
                call_getFunctions.data?.reduce(
                  (data, current) => {
                    return [
                      ...data,
                      {
                        label: current.funNM,
                        value: current.funcode,
                      },
                    ];
                  },
                  [{ label: t("util.util.all"), value: "All" }]
                ) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 推播層級 */}
          <Form.Item label={t("ADM.util.pushlayer")} name="level">
            <Select
              disabled
              options={
                call_getPushlvl.data?.reduce(
                  (data, current) => {
                    return [
                      ...data,
                      {
                        label: current.lvlNM,
                        value: current.lvlcode,
                      },
                    ];
                  },
                  [{ label: t("util.util.all"), value: "All" }]
                ) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 工類 */}
          <Form.Item label={t("ADM.util.sectID")} name="sectID" rules={[{ required: true }]}>
            <Select
              options={[
                { label: t("ADM.util.sectID_C"), value: "C" },
                { label: t("ADM.util.sectID_D"), value: "D" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 工類 */}
          <Form.Item label={"離職日期"} name="quitT">
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8} />

        <Col span={8}>
          {/* 對象授權分類 */}
          <Form.Item label={t("ADM.user-manage.dealmgmt")} name="dealmgmt">
            <Select
              mode="tags"
              allowClear
              options={
                call_getMgmtcat.data?.reduce(
                  (data, current) => {
                    (current.type === "cust" || current.type === "supp") &&
                      data.push({
                        label: current.mgmtitem + "_" + current.itemNM,
                        value: current.mgmtitem,
                      });
                    return data;
                  },
                  [{ label: t("util.util.all"), value: "All" }]
                ) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 物料授權分類 */}
          <Form.Item label={t("ADM.user-manage.pdtmgmt")} name="pdtmgmt">
            <Select
              mode="tags"
              options={
                call_getMgmtcat.data?.reduce(
                  (data, current) => {
                    current.type === "pdt" &&
                      data.push({
                        label: current.mgmtitem + "_" + current.itemNM,
                        value: current.mgmtitem,
                      });
                    return data;
                  },
                  [{ label: t("util.util.all"), value: "All" }]
                ) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 倉庫授權分類 */}
          <Form.Item label={t("ADM.user-manage.whmgmt")} name="whmgmt">
            <Select
              mode="tags"
              allowClear
              options={
                call_getMgmtcat.data?.reduce(
                  (data, current) => {
                    current.type === "wh" &&
                      data.push({
                        label: current.mgmtitem + "_" + current.itemNM,
                        value: current.mgmtitem,
                      });
                    return data;
                  },
                  [{ label: t("util.util.all"), value: "All" }]
                ) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
