import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, Radio } from "antd";
import { addLine, modifyLine, getStation } from "@/service/apis/ADM/publicAPI";
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import SystemContext from "../../../../../../../components/Context/SystemContext";
// import SystemContext from "@/components/SystemContext"

export default function ModalConintaer({ arrMap, set_s_editData, s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);
  const f_wsID = Form.useWatch("wsID", form);
  const f_pwcID = Form.useWatch("pwcID", form);
  const call_addLine = useAPI(addLine);
  const call_modifyLine = useAPI(modifyLine);
  const call_getStation = useAPI(getStation);
  const { t } = useTranslation();
  const { c_userData } = useContext(SystemContext);
  console.log("c_userData = ", c_userData);
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const onSubmit = (values) => {
    let queryPlantID = null;
    // values.psID = values.psID?.join("|");
    values.pwcID = values.pwcID?.join("|");
    values.pccID = values.pccID?.join("|");

    if (values.plantID && values.plantID.length > 0) {
      queryPlantID = values.plantID.join("|");
    }

    if (type === "create") {
      call_addLine.request({ ...values, plantID: queryPlantID });
    }
    if (type === "edit") {
      call_modifyLine.request({ ...values, plantID: queryPlantID });
    }
  };

  const changePwcID = (pwcIDs = []) => {
    const pccIDs = arrMap.pccArr?.reduce((arr, curr) => {
      for (const pwcID of pwcIDs) {
        pwcID === curr.pwcID && arr.push(curr.pccID);
      }
      return arr;
    }, []);
    form.setFieldsValue({ pccID: pccIDs });
  };

  useEffect(() => {
    call_getStation.request();
  }, []);

  useEffect(() => {
    if (f_pwcID && f_pwcID.length > 0) {
      changePwcID(f_pwcID);
    }
  }, [f_pwcID]);

  useEffect(() => {
    if (call_addLine.status === "suc") {
      message.success(call_addLine.msg);
      set_s_isShowModal(false);
    } else if (call_addLine.status === "err") {
      message.error(t(`error.error-code.${call_addLine.msg}`));
    }
  }, [call_addLine.status]);

  useEffect(() => {
    if (call_modifyLine.status === "suc") {
      message.success(call_modifyLine.msg);
      set_s_isShowModal(false);
    } else if (call_modifyLine.status === "err") {
      message.error(t(`error.error-code.${call_modifyLine.msg}`));
    }
  }, [call_modifyLine.status]);

  useEffect(() => {
    if (call_getStation.status === "err") {
      message.error(t(`error.error-code.${call_getStation.msg}`));
    }
  }, [call_getStation.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isdashboard: true,
        isvalid: true,
        isfocus: true,
        ...s_editData,
        plantID: type === "edit" ? s_editData.plantID.split("|") : [],
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item hidden name="wlpic">
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          {/* "工線ID" */}
          <Form.Item
            label={t("ADM.production-line-manage.wlID")}
            name="wlID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "工線名稱" */}
          <Form.Item
            label={t("ADM.production-line-manage.wlNM")}
            name="wlNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        {/*有效*/}
        <Col span={6}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={"廠別"} name="plantID" rules={[{ required: true }]}>
            <Select
              mode="tags"
              placeholder="請選擇廠別"
              options={[
                { label: "全場", value: "0000" },
                { label: "科技廠_車圈", value: "1001" },
                { label: "科技廠_輪組", value: "1002" },
                { label: "科技廠_碳圈", value: "1003" },
                { label: "科技廠_鋁擠", value: "1004" },
                { label: "熔煉廠", value: "1005" },
                { label: "擠型場_擠一二三", value: "1006" },
                { label: "航太廠_航太一", value: "1011" },
                { label: "航太廠_航太二", value: "1012" },
                { label: "大造熊", value: "1021" },
                { label: "鼎特力", value: "1031" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* "製程" */}
          <Form.Item label={t("ADM.util.pf")} name="pfID" rules={[{ required: true }]}>
            <Select
              // placeholder="請選擇製程"
              allowClear
              onChange={() => {
                form.setFieldsValue({ wsID: null, psID: [], pwcID: [], pccID: [] });
              }}
              options={
                arrMap.pfArr?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "工站" */}
          <Form.Item
            label={t("ADM.production-line-manage.ws")}
            name="wsID"
            rules={[{ required: true }]}
          >
            <Select
              // placeholder="請選擇工站"
              allowClear
              disabled={!f_pfID}
              options={call_getStation.data?.reduce((options, curr) => {
                curr.pfID.includes(f_pfID) &&
                  options.push({
                    label: curr.wsID + "_" + curr.wsNM,
                    value: curr.wsID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* "工作中心" */}
          <Form.Item label={t("ADM.util.pwc")} name="pwcID" shouldUpdate>
            <Select
              mode="multiple"
              // placeholder="請選擇工作中心"
              disabled={!f_wsID}
              allowClear
              options={arrMap.pwcArr.reduce((options, curr) => {
                call_getStation.data
                  .find((item) => item.wsID === f_wsID)
                  ?.pwcID.includes(curr.pwcID) &&
                  options.push({
                    label: curr.pwcID + "_" + curr.pwcNM,
                    value: curr.pwcID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* "車間" */}
          <Form.Item label={t("ADM.production-line-manage.ps")} name="psID">
            <Select
              // mode="multiple"
              // placeholder="請選擇工站"
              disabled={!f_wsID}
              options={
                call_getStation.data
                  ?.find((x) => x.wsID === f_wsID)
                  ?.psID?.split("|")
                  ?.map((psID) => ({
                    label: `${psID}_${arrMap.psArr?.find((x) => x.psID === psID)?.psNM}`,
                    value: psID,
                    data: psID,
                  })) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t("util.util.isfocus")} name="isfocus">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        {/*看板顯示*/}
        <Col span={6}>
          <Form.Item label={t("ADM.production-line-manage.isdashboard")} name="isdashboard">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 1對1機台IP  */}
          <Form.Item label={"1對1機台IP"} name="singleIP">
            <Input disabled={c_userData.staffID !== "superman"} />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 1對多機台IP */}
          <Form.Item label={"1對多機台IP"} name="multiIP">
            <Input disabled={c_userData.staffID !== "superman"} />
          </Form.Item>
        </Col>

        <Col span={12}>
          {/* "成本中心" */}
          <Form.Item label={t("ADM.production-line-manage.pcc")} name="pccID" shouldUpdate>
            <Select
              mode="multiple"
              // placeholder="請選擇工作中心"
              disabled
              options={arrMap.pccArr?.map((x) => ({
                label: x.pccID + "_" + x.pccNM,
                value: x.pccID,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
