import { useState, useEffect } from "react";
import { message, Space, DatePicker } from "antd";
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getErpWh } from "@/service/apis/WHM/AttendanceHours";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import { AddButton } from "@/components/PDS/Buttons";
import ModalConintaer from "./ModalContainer";
import dayjs from "dayjs";

const AttendanceHours = (props) => {
  const { t } = useTranslation();
  const [is_showModal, set_is_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_searchData, set_s_searchData] = useState({
    date: undefined,
  });

  const call_getErpWh = useAPI(getErpWh);

  const tableColumns = [
    {
      title: "部門名稱",
      dataIndex: "deptNM",
      align: "center",
    },
    {
      title: "員工代號",
      dataIndex: "staffID",
      align: "center",
    },
    {
      title: "員工姓名",
      dataIndex: "peopleNM",
    },
    {
      title: "出勤日期",
      dataIndex: "atddate",
      align: "center",
      render: (value) => dayjs(value).format("YYYY/MM/DD"),
    },
    {
      title: "實際出勤工時_含加班時數(hh:mm)",
      dataIndex: "wh",
      align: "center",
      render: (value) =>
        parseInt(value / 60)
          .toString()
          .padStart(2, 0) +
        ":" +
        (value % 60).toString().padStart(2, 0),
    },
  ];

  const onSearch = () => {
    call_getErpWh.request({
      ...s_searchData,
      date: s_searchData.date ? s_searchData.date.format("YYYY-MM-DD") : undefined,
    });
  };

  const onCreate = () => {
    set_is_showModal(true);
    set_s_editData({});
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData]);

  useEffect(() => {
    if (call_getErpWh.status === "suc") {
      message.success(call_getErpWh.msg);
      set_s_tableData(
        call_getErpWh.data.map((x, i) => {
          return { ...x, key: i };
        })
      );
    } else if (call_getErpWh.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getErpWh.msg}`));
    }
  }, [call_getErpWh.status]);

  return (
    <>
      <PageHeader
        title="出勤工時維護"
        extra={
          <>
            <DatePicker
              value={s_searchData.date}
              onChange={(e) =>
                set_s_searchData((prev) => ({
                  ...prev,
                  date: e,
                }))
              }
              placeholder="請選擇日期"
            />
            <AddButton type="primary" key="create" onClick={onCreate} />
          </>
        }
      />
      <CustomTable columns={tableColumns.filter((x) => !x.hidden)} dataSource={s_tableData} />
      <CustomModal
        title={`${t("util.util.add")}`}
        width={"40%"}
        open={is_showModal}
        onCancel={() => set_is_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer set_is_showModal={set_is_showModal} tableColumns={tableColumns} />
      </CustomModal>
    </>
  );
};

export default AttendanceHours;
