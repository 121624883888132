import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";

import { addCustTb, modifyCustTb } from "@/service/apis/ADM/client";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal, c_lightData }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addCustTb = useAPI(addCustTb);
  const call_modifyCustTb = useAPI(modifyCustTb);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      custID: c_lightData.custID,
      itemno: s_editData.itemno,
      ...values,
    };

    if (type === "create") {
      call_addCustTb.request(postData);
    }

    if (type === "edit") {
      call_modifyCustTb.request(postData);
    }
  };

  useEffect(() => {
    if (call_addCustTb.status === "suc") {
      message.success(call_addCustTb.msg);
      set_s_isShowModal(false);
    } else if (call_addCustTb.status === "err") {
      message.error(t(`error.error-code.${call_addCustTb.msg}`));
    }
  }, [call_addCustTb.status]);

  useEffect(() => {
    if (call_modifyCustTb.status === "suc") {
      message.success(call_modifyCustTb.msg);
      set_s_isShowModal(false);
    } else if (call_modifyCustTb.status === "err") {
      message.error(t(`error.error-code.${call_modifyCustTb.msg}`));
    }
  }, [call_modifyCustTb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item label={t("ADM.client.itemNM")} name="itemNM">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Form.Item label={t("util.util.contact")} name="contact" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.dept")} name="department">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.tel")} name="tel" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("ADM.client.mobile")} name="mobile">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("ADM.util.mail")} name="mail">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("ADM.util.postID")} name="postID">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("ADM.client.addr")} name="addr">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addCustTb.status === "load" || call_modifyCustTb.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
