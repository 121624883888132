import React, { useState, useEffect } from "react";
import { FullscreenOutlined, UploadOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { MenuOutlined } from "@ant-design/icons";

import {
  Row,
  DatePicker,
  Col,
  Input,
  Button,
  Alert,
  Collapse,
  Spin,
  Upload,
  message,
  Select,
} from "antd";
import {
  IconCompaer,
  IconCurrent,
  IconMOM,
  IconYOY,
  IconOneColumns,
  IconTowColumns,
  IconThreeColumns,
} from "@/components/Icon/BI";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
import { IconUnion, IconXlsDownload } from "@/components/Icon/Action";
import displayString from "../../../util/displayString";
import Text from "@/components/MC/Text";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { getLine, getBatchIDs } from "@/service/apis/ADM/publicAPI";
import { getWoNs } from "@/service/apis/QMS/Apui";
import useAPI from "@/hooks/useAPI";
import styled from "styled-components";
import LocaltionChart from "./chart/LocaltionChart";
import { getSpcDataSocket } from "@/service/ws/ws";
import useWebSocket from "@/hooks/useWebSocket";
import DraggableComponent from "./dragdrop/DraggableComponent";
import DroppableComponent from "./dragdrop/DroppableComponent";
import { useDrag, useDrop } from "react-dnd";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import ResizeChart from "./resizeChart/LocaltionChart";
const CutomTable = styled(DragSortingTable)`
  .ant-table-cell {
    height: 40px;
  }
`;

const { RangePicker } = DatePicker;
const Analyze = ({ overViewDrawno, boardData }) => {
  const { t } = useTranslation();

  const defaultStartDate = dayjs().subtract(3, "months");
  const defaultEndDate = dayjs();
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > dayjs().endOf("day");
  };
  const defaltChartOption = {
    chart: {
      marginTop: 60,
      reflow: true,
      height: 300,
      // width: 870,
    },

    noData: {
      text: "No data to display",
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    navigator: {
      height: 30, // 調整導覽器元件的高度
    },
    rangeSelector: {
      selected: 0,
      inputEnabled: false,
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    time: {
      useUTC: false,
    },

    yAxis: [
      {
        title: {
          text: "X&nbsp;BAR(平均值)",
          align: "high",
          rotation: 0,
          y: -20,
          offset: -200,
          style: {
            color: "#0E6BD3",
            fontSize: 10,
          },
        },
        labels: {
          align: "center",
          x: 0,
        },
        // min:0,
        // max:100,
        tickAmount: 5,
        // startOnTick: true,
        height: "30%",
        lineWidth: 2,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
        plotLines: [
          {
            value: 10,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "USL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
          {
            value: 190,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "LSL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
        ],
      },
      {
        labels: {
          align: "center",
          x: -3,
        },
        title: {
          text: "R (全距)",
          align: "high",
          rotation: 0,
          y: -20,
          offset: -200,
          style: {
            color: "#0E6BD3",
            fontSize: 10,
          },
        },
        // min:0,
        // max:100,
        tickAmount: 5,
        top: "45%",
        height: "30%",
        // startOnTick: false,
        // endOnTick: false,
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        lineWidth: 2,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
      },
    ],

    xAxis: {
      // type: 'datetime',
      min: null,
      max: null,
      // min: lastHour,
      // max: now,
    },

    // tooltip: {
    //   shared: true,
    //   split: true,
    //   valueDecimals: 2,
    // },

    legend: {
      enabled: false,
      align: "right",
      padding: 50,
    },

    series: [
      {
        name: "xbar",
        data: [0],
        marker: {
          radius: 0,
        },
      },
      {
        name: "R",
        data: [0],
        yAxis: 1,
      },
    ],
  };
  const [chartOptions, setChartOptions] = useState(JSON.parse(JSON.stringify(defaltChartOption)));
  // 機碼
  const call_getLine = useAPI(getLine);
  // 工單
  const call_getWoNs = useAPI(getWoNs);
  //獲取工單
  const call_getBatchIDs = useAPI(getBatchIDs);

  const call_getSpcDataSocket = useWebSocket(getSpcDataSocket);

  const [s_mainClassification, set_s_s_mainClassification] = useState("batchID");

  const [s_options, set_s_options] = useState([]);
  const [s_optionSelect, set_s_optionSelect] = useState([]);

  const [s_XBarChartData, set_s_XBarChartData] = useState([]);
  const [s_isLoading, set_s_isLoading] = useState(false);

  const [s_isFullmodal, set_s_isfullmodal] = useState(false);
  const [s_productDetailsColumn, set_s_productDetailsColumn] = useState("w-full");
  const chartColumnsStyle = {
    basic: "mr-1 [&:last-child]:mr-0 border-none outline-0 focus:border-none focus:outline-0",
    default: "bg-inherit  focus:border-none focus:outline-0",
    primary: " bg-inherit  text-sky-500 focus:border-none focus:outline-0 ",
  };

  const [s_tableData, set_s_tableData] = useState([]);
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "未篩選資料",
      children: (
        <HighchartsReact
          highcharts={Highcharts}
          // highcharts={s_heightChart}
          options={chartOptions}
          constructorType={"stockChart"}
        />
      ),
    },
  ];
  const column = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      dataIndex: "sort",
      width: "20%",
      align: "center",
      render: () => <MenuOutlined />,
    },
    // {
    //   title: "對象ID",
    //   width: "40%",
    //   dataIndex: "value",
    //   align: "center",
    // },
    {
      title: "對象",
      // width: "40%",
      dataIndex: "name",
      align: "center",
    },
  ];
  const items2 = [
    {
      key: "2",
      label: "排序資料",
      children: (
        <CutomTable
          set_s_tableData={set_s_tableData}
          className={`w-full`}
          columns={column}
          pagination={false}
          scroll={{ y: 350 }}
          dataSource={s_tableData}
          afterSorting={(data) => {
            const newSorting = data.map((item, index) => ({
              ...item,
              key: index,
            }));
            const valueArr = newSorting.map((item) => item.value);
            call_getSpcDataSocket.changeParams({
              queryType: s_mainClassification,
              query: valueArr.join("|"),
              drawno: overViewDrawno.drawno,
              spcN: overViewDrawno.spcN,
              samplesize: overViewDrawno.samplesize,
            });
            set_s_isLoading(true);
            return newSorting;
          }}
        />
      ),
    },
  ];

  const getOptions = (value) => {
    const result = {
      "batchID": () => {
        call_getBatchIDs.request();
      },

      "noResult": () => {
        console.error("無對應條件");
      },
      "wlID": () => {
        call_getLine.request();
      },
      "woN": () => {
        call_getWoNs.request();
      },
    };
    const callMethod = result[value] || result["noResult"];
    callMethod();
  };
  useEffect(() => {
    setChartOptions((prev) =>
      JSON.parse(
        JSON.stringify({
          ...prev,

          navigator: {
            enabled: true,
          },
        })
      )
    );
    return;
  }, []);
  useEffect(() => {
    if (!boardData) return;
    let xData = [],
      xCL = [],
      xLCL = [],
      xUCL = [],
      USL = [],
      LSL = [],
      rData = [],
      rCL = [],
      rLCL = [],
      rUCL = [];
    for (const item of boardData) {
      // X
      xData.push([new Date(item.measureT).getTime(), item.avg]);
      xCL.push([new Date(item.measureT).getTime(), overViewDrawno.CL]);
      xLCL.push([new Date(item.measureT).getTime(), overViewDrawno.LCL]);
      xUCL.push([new Date(item.measureT).getTime(), overViewDrawno.UCL]);
      USL.push([new Date(item.measureT).getTime(), overViewDrawno.USL]);
      LSL.push([new Date(item.measureT).getTime(), overViewDrawno.LSL]);

      // R
      rData.push([new Date(item.measureT).getTime(), item.range]);
      rCL.push([new Date(item.measureT).getTime(), overViewDrawno.rangeCL]);
      rLCL.push([new Date(item.measureT).getTime(), overViewDrawno.rangeLCL]);
      rUCL.push([new Date(item.measureT).getTime(), overViewDrawno.rangeUCL]);
    }

    let series = [
      {
        name: "X_AVG",
        data: xData,
        lineWidth: 4,
        yAxis: 0,

        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },

        zones: [
          {
            value: overViewDrawno?.LCL,
            color: "#f75c5c",
          },
          {
            value: overViewDrawno?.UCL,
            color: "#a8a8a8",
          },
          {
            color: "#f75c5c",
          },
        ],
      },

      {
        name: "X_CL",
        data: xCL,
        color: "#40ad36",
        yAxis: 0,
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_LCL",
        data: xLCL,
        yAxis: 0,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_UCL",
        data: xUCL,
        yAxis: 0,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "LSL",
        data: LSL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "USL",
        data: USL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_RANGE",
        data: rData,
        lineWidth: 4,
        yAxis: 1,
        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },
      },
      {
        name: "R_CL",
        data: rCL,
        color: "#40ad36",
        yAxis: 1,
        tooltip: {
          pointFormat: "",
        },
      },

      {
        name: "R_LCL",
        data: rLCL,
        yAxis: 1,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_UCL",
        data: rUCL,
        yAxis: 1,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
    ];

    setChartOptions((prev) => {
      prev.series = series;
      prev.yAxis[0].plotLines[0].value = overViewDrawno.USL;
      prev.yAxis[0].plotLines[1].value = overViewDrawno.LSL;
      return { ...prev };
    });
  }, [boardData]);

  useEffect(() => {
    if (s_optionSelect.length === 0) {
      call_getSpcDataSocket.setIsOpen(false);
      set_s_XBarChartData([]);
      return;
    }
    console.log(s_optionSelect);
    set_s_isLoading(true);
    call_getSpcDataSocket.setIsOpen(true);
    call_getSpcDataSocket.changeParams({
      queryType: s_mainClassification,
      query: s_optionSelect.join("|"),
      drawno: overViewDrawno.drawno,
      spcN: overViewDrawno.spcN,
      samplesize: overViewDrawno.samplesize,
    });
  }, [s_optionSelect]);

  useEffect(() => {
    const { data, msg, status } = call_getBatchIDs;
    if (status === "suc") {
      message.success(msg);
      set_s_options(data.map((item) => ({ value: item.batchID, label: item.batchID })));
    } else if (status === "err") {
      message.error(msg);
      set_s_options([]);
    }
  }, [call_getBatchIDs.status]);
  useEffect(() => {
    const { data, msg, status } = call_getLine;
    if (status === "suc") {
      message.success(msg);
      set_s_options(data.map((item) => ({ value: item.wlID, label: item.wlNM })));
    } else if (status === "err") {
      message.error(msg);
      set_s_options([]);
    }
  }, [call_getLine.status]);
  useEffect(() => {
    const { data, msg, status } = call_getWoNs;
    if (status === "suc") {
      message.success(msg);
      set_s_options(data.map((item) => ({ value: item.woN, label: item.woN })));
    } else if (status === "err") {
      message.error(msg);
      set_s_options([]);
    }
  }, [call_getWoNs.status]);
  useEffect(() => {
    if (call_getSpcDataSocket.socketData.status) {
      const new_XBarChartData = [
        ...call_getSpcDataSocket.socketData.data.map((item, index) => ({
          ...item,
          data: item.data.map((dataItem) => ({
            ...dataItem,
            CL: overViewDrawno["CL"],
            LCL: overViewDrawno["LCL"],
            UCL: overViewDrawno["UCL"],
            LSL: overViewDrawno["LSL"],
            USL: overViewDrawno["USL"],
          })),
        })),
      ];
      set_s_XBarChartData(new_XBarChartData);
      set_s_isLoading(false);

      return;
    } else {
      // set_s_SPCtableData([]);
    }
  }, [call_getSpcDataSocket.socketData]);

  return (
    <>
      <div className="w-full  h-full ">
        <PageHeader
          title={<div className="bg-[#7a71f8]  text-white rounded-lg p-2">SPC 分析</div>}
          className={"mb-0 relative"}
          extra={[
            <div className="w-[25vw] absolute  top-0 right-0   flex justify-end  gap-3">
              <RangePicker
                disabledDate={disabledDate}
                defaultValue={[defaultStartDate, defaultEndDate]}
              />
              <Button
                disabled
                icon={
                  <IconXlsDownload
                    disable
                    className={`text-[28px] text-[#318638] disabled:text-[#F6F6F6]`}
                  />
                }
              />

              <Button
                className="h-[30px] w-[30px] bg-white "
                type="primary"
                onClick={() => {
                  // set_s_cardModal(false);
                }}
                icon={
                  <IconUnion
                    // disabled={true}
                    onClick={() => set_s_isfullmodal(true)}
                    className="header-icon text-[18px] text-[#5287D9] "
                  />
                }
              />
            </div>,
          ]}
        />
        <div className="flex w-full">
          <div className="flex flex-col w-[30%]  gap-3">
            <div className="flex flex-col">
              <Text level="h4">{`料號:${displayString(overViewDrawno.PN)}`}</Text>
              <Text level="h4">{`圖號:${displayString(overViewDrawno.drawno)}`}</Text>
              <Text level="h4">{`SIPID:${displayString(overViewDrawno.sipID)}`}</Text>
            </div>
            <div className="w-full ">
              <Collapse items={items} onChange={onChange} />
            </div>
            <Spin spinning={s_isLoading}>
              <div className="flex flex-1 flex-col justify-between gap-3">
                <div>
                  <Text level="h4">分析維度</Text>
                  <Select
                    className="w-full"
                    options={[
                      { label: "批號", value: "batchID" },
                      { label: "機碼", value: "wlID" },
                      { label: "工單", value: "woN" },
                    ]}
                    onChange={(value) => {
                      getOptions(value);
                      set_s_optionSelect([]);
                      set_s_s_mainClassification(value);
                      call_getSpcDataSocket.setIsOpen(false);
                    }}
                  />
                </div>
                <div>
                  <Text level="h4">分析對象</Text>
                  <Select
                    onChange={(value, rowData) => {
                      set_s_optionSelect([...value]);
                      set_s_tableData(
                        value.map((item, index) => {
                          return { key: index, value: item, name: rowData[index].label };
                        })
                      );
                    }}
                    mode="multiple"
                    placement="topRight"
                    className="w-full"
                    value={s_optionSelect}
                    options={s_options}
                    allowClear
                  />
                </div>
                <div className="w-full">
                  <Collapse items={items2} onChange={onChange} />
                </div>
              </div>
            </Spin>
          </div>
          {/* <Spin className="flex flex-1" spinning={s_isLoading}> */}

          <div className="flex flex-col  overflow-y-auto max-h-[85vh] w-full flex-1">
            <Spin className="flex flex-1" spinning={s_isLoading}>
              {s_XBarChartData.map((item) => (
                <div className="w-full mt-2">
                  <PageHeader
                    title={
                      <div className="bg-[#7a71f8] draggable-item  text-white rounded-lg p-2">
                        {displayString(
                          s_options.find((option) => option?.value === item?.name)?.label
                        )}
                      </div>
                    }
                  />
                  <LocaltionChart
                    boardData={item.data} // Socket Data
                  />
                </div>
              ))}
            </Spin>
          </div>

          {/* </Spin> */}
        </div>
      </div>

      <CustomModal
        title={"SPC全覽"}
        visible={s_isFullmodal}
        style={{ top: 128 }}
        onCancel={() => set_s_isfullmodal(false)}
        width="95%"
        footer={null}
        destroyOnClose
        // closable={false}
        afterClose={() => {
          set_s_isfullmodal(false);
          set_s_productDetailsColumn("w-full");
        }}
        wrapClassName={`w-full h-full`}
      >
        <PageHeader
          // title={<div className="bg-[#7a71f8]  text-white rounded-lg p-2">SPC 分析</div>}
          className={"mb-0 relative"}
          extra={[
            <div className="w-[25vw] absolute  top-0 right-0   flex justify-end  gap-3">
              <Button
                icon={<IconOneColumns />}
                className={`${chartColumnsStyle.basic} ${
                  s_productDetailsColumn === "w-full"
                    ? chartColumnsStyle.primary
                    : chartColumnsStyle.default
                }`} // 基礎樣式
                onClick={() => set_s_productDetailsColumn("w-full")}
                text={" "}
              />
              <Button
                icon={<IconTowColumns />}
                className={`${chartColumnsStyle.basic} ${
                  s_productDetailsColumn === "w-[50%]"
                    ? chartColumnsStyle.primary
                    : chartColumnsStyle.default
                }`} // 基礎樣式
                onClick={() => set_s_productDetailsColumn("w-[50%]")}
              />
              <Button
                icon={<IconThreeColumns />}
                className={`${chartColumnsStyle.basic} ${
                  s_productDetailsColumn === "w-[33%]"
                    ? chartColumnsStyle.primary
                    : chartColumnsStyle.default
                }`} // 基礎樣式
                onClick={() => set_s_productDetailsColumn("w-[33%]")}
              />
            </div>,
          ]}
        />
        <div className="flex flex-wrap">
          {s_XBarChartData.map((item) => (
            <div className={`${s_productDetailsColumn} mt-2`}>
              <PageHeader
                title={
                  <div className="bg-[#7a71f8] draggable-item  text-white rounded-lg p-2">
                    {displayString(s_options.find((option) => option?.value === item?.name)?.label)}
                  </div>
                }
              />
              <ResizeChart
                boardData={item.data}
                chartSize={s_productDetailsColumn} // Socket Data
              />
            </div>
          ))}
        </div>
      </CustomModal>
    </>
  );
};

export default Analyze;
