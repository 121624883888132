import { Modal, message } from "antd";
import { Button, Input } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { deleteWC, getWC } from "@/service/apis/PIS/CaptainLineManage";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import WorkCaptainModal from "./ModalContainer/index";

const CaptainLineManage = () => {
  const { t } = useTranslation();
  const call_getWC = useAPI(getWC);
  const call_deleteWC = useAPI(deleteWC);

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [is_showModal, set_is_showModal] = useState(false);
  const [s_editData, set_s_editData] = useState(false);
  const [scrollY, setScrollY] = useState("");
  const [s_searchData, set_s_searchData] = useState({
    captainID: "",
  });

  //建立領班線別
  const createWC = () => {
    set_is_showModal(true);
  };

  //編輯領班線別
  const editWC = (rowData) => {
    set_is_showModal(true);
    set_s_editData(rowData);
  };

  //click 搜尋時執行
  const onSearch = async (value) => {
    // 判斷是要取得所有資料還是只包含搜尋文字的資料
    call_getWC.request(s_searchData);
  };

  const removeWC = (rowData) => {
    Modal.confirm({
      title: "領班線別資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請確認您希望删除此資料",
      okText: "刪除",
      onOk: () => call_deleteWC.request(rowData),
      cancelText: "取消",
    });
  };

  const tableColumns = [
    {
      title: "領班線別",
      dataIndex: "captainID",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      sorter: (a, b) => a.captainID.localeCompare(b.captainID),
    },
    {
      title: "實體線別",
      dataIndex: "wlID",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: "領班ID",
      dataIndex: "leaderID",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: "領班名稱",
      dataIndex: "peopleNM",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: "車間",
      dataIndex: "psNM",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text) => text || "全車間",
    },
    {
      title: "應到人數",
      dataIndex: "sethead",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    // {
    //   title: "備註",
    //   dataIndex: "note",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    // },
    {
      title: "更新時間",
      dataIndex: "updtT",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text) => {
        const tmp = dayjs(text).format("YYYY-MM-DD HH:mm");
        return tmp;
      },
    },
    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
      render: (text, rowData, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          <div style={{ marginRight: "10px" }} onClick={() => editWC(rowData)}>
            <IconPen className="icon" />
          </div>

          <div onClick={() => removeWC(rowData)}>
            <IconGarbage className="icon" />
          </div>
        </div>
      ),
    },
  ];

  //第一次渲染及每次 Modal 關閉時都重抓資料
  useEffect(() => {
    call_getWC.request();
  }, []);

  // 取得表頭資料
  useEffect(() => {
    if (call_getWC.status === "suc") {
      console.log("call_getWC.data = ", call_getWC.data);
      set_s_tableData(call_getWC.data);
      message.success(call_getWC.msg);
    } else if (call_getWC.status === "err") {
      set_s_tableData([]);
      message.error(call_getWC.msg);
    }
  }, [call_getWC.status]);

  // 刪除表頭資料
  useEffect(() => {
    if (call_deleteWC.status === "suc") {
      message.success(call_deleteWC.msg);
      // 刪除成功後要重抓表頭資料
      call_getWC.request(s_searchData);
    } else if (call_deleteWC.status === "err") {
      message.error(call_deleteWC.msg);
    }
  }, [call_deleteWC.status]);

  const searchTemp = [
    <Input
      placeholder={`${t("util.util.placeholder_input")}領班線別`}
      value={s_searchData.captainID}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, captainID: e.target.value };
        });
      }}
    />,

    <SearchButton type="primary" onClick={onSearch} key="search" />,
    <AddButton type="primary" key="create" onClick={createWC} />,
  ];

  return (
    <>
      <PageHeader title="領班線別管理" extra={searchTemp} />
      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content", y: scrollY }}
        columns={tableColumns}
        // dataSource={tabledata}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item,
          };
        })}
      />
      <CustomModal
        width="70%"
        title={Object.keys(s_editData).length > 0 ? "修改領班線別資料" : "新建領班線別資料"}
        visible={is_showModal}
        onCancel={() => set_is_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getWC.request(s_searchData);
        }}
      >
        {is_showModal ? (
          <WorkCaptainModal
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            set_is_showModal={set_is_showModal}
          />
        ) : null}
      </CustomModal>
    </>
  );
};

export default CaptainLineManage;
