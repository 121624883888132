import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const updateDevice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDevice`, data);
};
export const addDevice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDevice`, data);
};
export const deleteDevice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDevice`, data);
};

export const getDevice = (param) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDevice`, {
    params: param,
  });
};
export const addDeviceDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDeviceDoc`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addDeviceHdDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDeviceHdDoc`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
