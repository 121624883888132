import { Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
// api
import useAPI from "@/hooks/useAPI";
import { deleteProc, getProc } from "@/service/apis/QMS/sampling";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// component
import ModalConintaer from "./ModalContainer";

// util

const HD = forwardRef((props, ref) => {
  const { c_userData } = useContext(SystemContext);
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  // useAPI 取得表頭資料
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_getProc = useAPI(getProc);
  // useAPI 刪除表頭資料
  const call_deleteProc = useAPI(deleteProc);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  const ctrlrequire_map = useMemo(() => {
    return s_arrMap.ctrlrequire.reduce((prev, curr) => {
      prev[curr.value] = curr.label;
      return prev;
    }, []);
  }, []);

  // 搜尋
  const onSearch = () => {
    call_getProc.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteProc.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "程序ID", // ""
      dataIndex: "procID",
      align: "center",
      width: 80,
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "程序名稱", // 料號
      dataIndex: "procNM",
      align: "left",
      width: "15%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "使用分類",
      dataIndex: "usertype",
      width: 90,
      align: "center",
      // render: (text) => {
      //   const name = [
      //     { value: "A", label: "開發" },
      //     { value: "B", label: "品保" },
      //     { value: "C", label: "生產" },
      //   ];
      //   return name.find((item) => item.value === text)?.label;
      // },
    },
    {
      title: "抽樣類型",
      dataIndex: "sampletype",
      width: "8%",
      align: "center",
      render: (text) => {
        const name = [
          { value: "A", label: "免驗" },
          { value: "E", label: "全檢" },
          { value: "B", label: "固定樣品數" },
          { value: "C", label: "固定百分比" },
          { value: "F", label: "自定義抽樣表" },
          { value: "0", label: "國際標準" },
          { value: "9", label: "自定義標準" },
        ];
        return name.find((item) => item.value === text)?.label;
      },
    },
    {
      title: "抽樣條件",
      dataIndex: "setvalue1",
      width: 100,
      align: "center",
      render: (text, data) => {
        if (text === "undefined") return "";
        if (data.sampletype === "0" || data.sampletype === "9") return `${text}_${data?.sampleNM}`;
        return ["C", "E"].includes(data.sampletype) ? `${text}%` : text;
      },
    },
    {
      title: "小數點處理原則",
      dataIndex: "dotmethod1",
      width: 150,

      align: "center",
      render: (text) => {
        const name = [
          { value: "round", label: "四捨五入" },
          { value: "ceil", label: "進位法" },
          { value: "floor", label: "捨去法" },
        ];
        return name.find((item) => item.value === text)?.label;
      },
      // render: (text) => text && <Preview url={text} />,
    },

    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
    },

    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: 80,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "建立時間",
      dataIndex: "createT",
      align: "center",

      width: 125,
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },

    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getProc;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          index: i,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getProc.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteProc;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteProc.status]);

  // 配合 Hammer 給客戶 demo, 動態生成 Modal title
  const generateTitle = () => {
    // 判斷是否要顯示「編輯」或「新增」
    const isEditMode = Object.keys(s_editData).length > 0;
    const type = isEditMode ? t("util.util.edit") : t("util.util.add");
    let actionText = "";

    if (c_userData.peopleNM === "superman1") {
      actionText = type + "檢驗項目主檔設定";
    } else {
      actionText = type + "檢驗標準";
    }

    return (
      <span>
        {actionText}
        {isEditMode && (
          <>
            <span className="ml-2 text-blue-500 font-semibold">SIP ID: {s_editData.sipID}</span>
            <span className="ml-2 text-blue-500 font-semibold">版次: {s_editData.version}</span>
          </>
        )}
      </span>
    );
  };

  return (
    <>
      <CustomTable
        scroll={{ x: 0 }}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        loading={call_getProc.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.sipID === c_lightData.sipID ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={type === "create" ? "新增抽樣程序" : "編輯抽樣程序"}
        width={"70%"}
        // style={{ top: 150 }}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
