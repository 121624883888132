import React, { useEffect } from "react";
import { Form, Button, Row, Col, Input, message, Space, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { addWheel, updateWheel } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addWheel = useAPI(addWheel);
  const call_updateWheel = useAPI(updateWheel);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addWheel.request(postData);
    }

    if (type === "edit") {
      call_updateWheel.request(postData);
    }
  };

  useEffect(() => {
    if (call_addWheel.status === "suc") {
      message.success(call_addWheel.msg);
      set_is_showModal(false);
    } else if (call_addWheel.status === "err") {
      message.error(call_addWheel.msg);
    }
  }, [call_addWheel.status]);

  useEffect(() => {
    if (call_updateWheel.status === "suc") {
      message.success(call_updateWheel.msg);
      set_is_showModal(false);
    } else if (call_updateWheel.status === "err") {
      message.error(t(`error.error-code.${call_updateWheel.msg}`));
    }
  }, [call_updateWheel.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        "1002W102": 0,
        "1002W101": 0,
        "1002W201": 0,
        "1002W401": 0,
        "1002W501": 0,
        "1002W601": 0,
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="ID" label="輪組代碼" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="spec" label="輪組型號" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="side" label="前後輪" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={[
                {
                  value: "R",
                },
                {
                  value: "F",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W102" label="手工編製 1002W102 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W101" label="編機編製 1002W101 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W201" label="手工校正 1002W201 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W401" label="貼TRS膠帶 1002W401 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W501" label="外觀檢查及處理 1002W501 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="1002W601" label="包裝 1002W601 C/T值(S/PCS)">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addWheel.status === "load" || call_updateWheel.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
