import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//-----------------------------------------新建員工資料----------------------------------------
export const addStaff = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStaff`, data);
};

//-----------------------------------------取得全部員工資料----------------------------------------
export const getAllStaff = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStaff`);
};

//-----------------------------------------編輯員工資料----------------------------------------
export const modifyUser = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStaff`, data);
};

//-----------------------------------------刪除員工資料----------------------------------------
export const deleteUser = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStaff`, data);
};

//-----------------------------------------搜尋員工資料----------------------------------------
export const getStaff = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStaff`, {
    params: _params,
  });
};

//----------------------------------------- 取得組織功能的 Options ----------------------------------------
export const getFunctions = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getFunctions`);
};

//----------------------------------------- 取得推播層級的 Options ----------------------------------------
export const getPushlvl = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPushlvl`);
};

//----------------------------------------- 凍結員工資料 ----------------------------------------
export const freezeStaff = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/freezeStaff`, data);
};

export const getRole = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRole`, { params: _params });
};

export const addRole = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRole`, data);
};

export const updateRole = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRole`, data);
};

export const deleteRole = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteRole`, data);
};

//----------------------------------------- 新建戰情登錄權限 ----------------------------------------
export const addStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStaffPerm`, data);
};

//----------------------------------------- 取得所有員工工號 ----------------------------------------
export const getAllStaffID = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStaffID`);
};

//----------------------------------------- 取得全部戰情登錄權限資料 ----------------------------------------
export const getStaffPerm = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStaffPerm`, { params: data });
};

//----------------------------------------- 編輯戰情登錄權限 ----------------------------------------
export const modifyStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStaffPerm`, data);
};

//----------------------------------------- 刪除戰情登錄權限 ----------------------------------------
export const deleteStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStaffPerm`, data);
};
