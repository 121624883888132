import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getSpcNs = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/spc/getSpcNs`, {
    params: _params,
  });
};
export const getDrawno = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/spc/getDrawno`, {
    params: _params,
  });
};
export const updateSpcN = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/spc/updateSpcN`, data);
};

export const importData = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/spc/importData`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
