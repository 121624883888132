import { forwardRef, useImperativeHandle, useState } from "react";

import HD from "./HD";

const Basic = forwardRef((props, ref) => {
  const [c_lightData, set_c_lightData] = useState({});

  const [s_isShowModal, set_s_isShowModal] = useState(false);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_c_lightData({});
      set_s_isShowModal(true);
    },
  }));
  return (
    <>
      <HD
        ref={ref}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
        s_isShowModal={s_isShowModal}
        set_s_isShowModal={set_s_isShowModal}
        {...props}
      />

      {/* {Object.keys(c_lightData).length > 0 && (
        <TB
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
     
        />
      )} */}
    </>
  );
});

export default Basic;
