import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconFile, IconPen } from "@/components/Icon/Action";
import { getQmsSopRec } from "@/service/apis/QMS/QMSManage";

import FileModal from "../../File/FileModal";
import Preview from "../../File/Preview";
import RecModalContainer from "./RecModalContainer";

const innerWidth = window.innerWidth;

const RecModal = ({ s_rec, set_s_showRecModal, c_lightData }) => {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_editData, set_s_editData] = useState({});

  const [is_showModal, set_is_showModal] = useState(false);

  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 200,
  });

  useEffect(() => {
    if (!is_showModal && !s_showFileModal) {
      const call_getQmsSopRec = async () => {
        const res = await getQmsSopRec({
          dcUUID: s_rec.dcUUID,
          itemno: s_rec.itemno,
        });

        if (res.status === true) {
          message.success(res.msg);
          set_s_tableData(res.data);
        } else {
          message.error(res.msg);
          set_s_tableData([]);
        }
      };
      call_getQmsSopRec();
    }
  }, [is_showModal, s_showFileModal]);

  const edit = (rowData) => {
    set_s_editData(rowData);
    set_is_showModal(true);
  };

  const tableColumns = [
    {
      title: "類序",
      dataIndex: "recno",
      key: "recno",
      align: "center",
      width: innerWidth <= 1440 ? `${40}px` : `${40}px`,
      render: (text, rowData, index) => {
        return 1;
      },
    },
    {
      title: "項目類別",
      dataIndex: "checkitem_str",
      key: "checkitem_str",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text, rowData, index) => {
        const map = {
          "labor": "人員掃碼",
          "equip": "設備掃碼",
          "feed": "投料掃碼",
          "method": "方法掃碼",
          "request": "說明文字",
          "label": "輸入欄位",
          "select": "項目選擇",
          "value": "數值紀錄",
        };

        return map[rowData.checkitem];
      },
    },
    {
      title: "項序",
      dataIndex: "recno",
      key: "recno",
      align: "center",
      width: innerWidth <= 1440 ? `${40}px` : `${40}px`,
    },
    {
      title: "掃碼項目",
      dataIndex: "itemNM",
      key: "itemNM",
      align: "center",
      className: "bg-[#fff8d5]",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    // {
    //   title: "料件品名",
    //   dataIndex: "pdtNM",
    //   key: "pdtNM",
    //   align: "center",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    // },
    {
      title: "識別字串",
      dataIndex: "itemanchor",
      className: "bg-[#fff8d5]",
      key: "itemanchor",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "防呆字串",
      dataIndex: "itemkw",
      className: "bg-[#fff8d5]",

      key: "itemkw",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },

    {
      title: "標題",
      dataIndex: "label",
      key: "label",
      align: "center",
      width: innerWidth <= 1440 ? `${180}px` : `${230}px`,
    },
    {
      title: "輸入值",
      dataIndex: "content",
      key: "content",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "內容",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },

    {
      title: "量測單位",
      dataIndex: "UOM",
      key: "UOM",
      align: "center",
      className: "bg-[#D9D9FF]",
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`,
    },
    {
      title: "標準值",
      className: "bg-[#D9D9FF]",
      dataIndex: "CL",
      key: "CL",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "管制上限",
      className: "bg-[#D9D9FF]",
      dataIndex: "UCL",
      key: "UCL",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "管制下限",
      className: "bg-[#D9D9FF]",
      dataIndex: "LCL",
      key: "LCL",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "參考文件",
      dataIndex: "file",
      key: "file",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text) => text && <Preview url={text} />,
    },
    {
      title: "註記",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "操作",
      dataIndex: "render",
      key: "render",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: "center",
      render: (text, rowData, index) => (
        <div className="flex gap-2">
          {/* {rowData.checkitem === "method" && ( */}
          <IconFile
            onClick={(e) => {
              console.log(rowData);
              set_s_showFileModal(true);
              set_s_editData({
                ...rowData,
                sopID: c_lightData.sopID,
              });
              e.stopPropagation();
            }}
          />
          {/* )} */}
          <IconPen
            onClick={(event) => {
              event.stopPropagation();
              edit(rowData);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content" }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item,
          };
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["200", "500", "1000"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <span className="text-lg font-semibold">
              {t("util.util.showTotal", {
                total: total,
                rangeFirst: range[0],
                rangeEnd: range[1],
              })}
            </span>
          ),
        }}
      />

      {is_showModal ? (
        <CustomModal
          width="60%"
          // title='SOP投料全局項目編輯'
          title="掃碼設定"
          visible={is_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          onCancel={() => set_is_showModal(false)}
        >
          <RecModalContainer
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            set_is_showModal={set_is_showModal}
          />
        </CustomModal>
      ) : null}

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
        }}
      >
        <FileModal type="sopTb" s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
};

export default RecModal;
