import {
  Alert,
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import useAPI from "@/hooks/useAPI";
import { keyTextOptions, sapTypeOptions } from "@/pages/LMS/util/anyMap";
import {
  getDaycodeHd,
  getDaycodeTb,
  getLabel,
  getModelTb,
  getPrinter,
  print,
  printWoN,
  reprint,
} from "@/service/apis/LMS/LabelManage";

import combineBase64Images from "../../../util/combineBase64Images";
import group from "../../../util/group";
import canvasLabel from "../../../util/labelBuild";

const unitToInch = (value, unit) => {
  switch (unit) {
    case "cm":
      return value / 2.54;
    case "mm":
      return value / 25.4;
    default:
      return value;
  }
};

const engineOptions = [
  { value: " ", label: "無" },
  { value: "code39", label: "sony code 39" },
];

const mdOptions = [
  { value: 30 },
  { value: 25 },
  { value: 20 },
  { value: 15 },
  { value: 10 },
  { value: 5 },
  { value: 0 },
  { value: -5 },
  { value: -10 },
  { value: -15 },
  { value: -20 },
  { value: -25 },
  { value: -30 },
];

const angleOptions = [{ value: 0 }, { value: 90 }, { value: 180 }, { value: 270 }];

const { labelPrinter, generalPrinter } = canvasLabel;
const { Panel } = Collapse;

// 重新整理keyParams 並且根據orderIndex重新產生OBJ
const reorganizeKeyParams = (s_keyParams = []) => {
  let newParams = {};

  for (const [index, item] of s_keyParams.entries()) {
    for (const key in item) {
      newParams[key + (index + 1)] = item[key];
    }
  }
  return newParams;
};
// 封裝 parseInt 函數
const toInt = (value) => parseInt(value, 10);
const tofloat = (value) => parseFloat(value);

export default function Printer({
  set_s_showModal,
  s_editData: s_editData,
  c_lightData: s_templateParams,
  s_recordSN, //紀錄條碼
}) {
  const [form] = Form.useForm();
  const f_tb = Form.useWatch("tb", form);
  const generalRef = useRef(null);

  const [s_dateHdOptions, set_s_dateHdOptions] = useState([]);
  const [s_dateTbOptions, set_s_dateTbOptions] = useState([]);
  const [s_wonOptions, set_s_wonOptions] = useState([]);
  const [s_paperOptions, set_s_paperOptions] = useState([]);
  const [s_choosePaper, set_s_choosePaper] = useState({});
  const [s_chooseMachines, set_s_chooseMachines] = useState({});
  const [s_spinning, set_s_spinning] = useState(false);
  const [s_spinningTip, set_s_spinningTip] = useState("");
  const [s_printType, set_s_printType] = useState("");
  const [s_alert, set_s_alert] = useState({
    message: "打印前請確認，後端伺服器與標籤機準備完成",
    type: "success",
  });

  const [s_allLabel, set_s_allLabel] = useState([]);
  const [s_formData, set_s_formData] = useState({});

  const call_getModelTb = useAPI(getModelTb);
  const call_getDaycodeHd = useAPI(getDaycodeHd);
  const call_getDaycodeTb = useAPI(getDaycodeTb);
  const call_print = useAPI(print);
  const call_getPrinter = useAPI(getPrinter);
  const call_getLabel = useAPI(getLabel);
  const call_printWoN = useAPI(printWoN);
  const call_reprint = useAPI(reprint);

  /**
   * 发送条码图像到服务器进行存储。
   *
   * @async
   * @param {Object} params - 参数对象。
   * @param {string} params.type - 编码类型。
   * @param {string} params.SAT - 合成的 Base64 图像。
   * @param {number} params.dpi - 打印分辨率。
   * @param {string} params.MD - 机器信息。
   * @param {number} params.top_gap - 顶部间距。
   * @param {number} params.left_gap - 左侧间距。
   * @param {number} params.width - 图像宽度。
   * @param {number} params.height - 图像高度。
   * @param {number} params.total - 总条码数。
   * @param {number} params.startSN - 开始序列号。
   * @param {number} params.endSN - 结束序列号。
   * @param {Function} set_s_alert - 设置警告信息的函数。
   * @param {Function} set_s_spinning - 设置加载状态的函数。
   *
   * @returns {Promise} 返回一个 Promise 对象，如果成功则 resolve，如果失败则 reject。
   * @throws 会抛出错误，如果与服务器的通信出现问题。
   */
  async function sendBarcodeToServer({
    postImgs,
    s_chooseMachines,
    variable,
    imgWidth,
    imgHeight,
    imgGap,
    s_choosePaper,
    imgGroup,
    startSN,
    endSN,
    set_s_alert,
    set_s_spinning,
    angle,
  }) {
    // const _conn = "http://192.168.0.139:59487/Printer/store";
    const _conn = "http://localhost:59487/Printer/store";
    try {
      await axios.post(_conn, {
        type: s_chooseMachines.encode,
        SAT: postImgs,
        dpi: s_chooseMachines.dpi,
        MD: variable.MD,
        top_gap: variable.top_gap,
        left_gap: variable.left_gap,
        width: imgWidth * s_choosePaper.col + imgGap * (s_choosePaper.col - 1),
        height: imgHeight,
        total: imgGroup.length,
        startSN: startSN,
        endSN: endSN,
        angle: angle,
      });
    } catch (error) {
      set_s_alert({
        message: "條碼打印失敗，請確認後端伺服器是否有開啟",
        type: "error",
      });
      set_s_spinning(false);
      console.log(`存儲條碼失敗：${error.message}`);
      message.error(`存儲條碼失敗：${error.message}`);
    }
  }

  /**
   * 监控条码打印进程并相应地更新UI元素。
   * @async
   * @function
   * @param {Object} options - 配置对象。
   * @param {Array} options.imgGroup - 待打印的图像数组。
   * @param {Function} options.set_s_spinning - 设置加载状态的函数。
   * @param {Function} options.set_s_spinningTip - 设置加载提示文本的函数。
   * @param {Function} options.set_s_alert - 设置警报消息的函数。
   * @throws 如果请求监视打印条码的计数失败，将抛出错误。
   */
  async function monitorBarcodePrinting({
    imgGroup,
    set_s_spinning,
    set_s_spinningTip,
    set_s_alert,
  }) {
    let nowcount = 0;

    try {
      while (nowcount !== imgGroup.length) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const _conn = "http://192.168.0.121:59487/Printer/nowcount";
        // const _conn = "http://localhost:59487/Printer/nowcount";

        const response = await axios.post(_conn);
        if (response.status !== 200) {
          message.error(`條碼列印不全，請通知開發人員，最後列印數：${nowcount}`);
          return;
        }
        nowcount = response.data.nowcount;
        set_s_spinningTip(`條碼列印中　${response.data.nowcount}　/　${imgGroup.length}`);
      }
      set_s_alert({
        message: "標籤打印成功",
        type: "success",
      });
    } catch (error) {
      set_s_spinning(false);
      set_s_spinningTip(`條碼列印不全，請通知開發人員，最後列印數：${nowcount}`);
      set_s_alert({
        message: `條碼列印不全，請通知開發人員，最後列印數：${nowcount}`,
        type: "error",
      });
      console.log(`條碼設備連線失敗：${error.message}`);
      message.error(`條碼設備連線失敗：${error.message}`);
    }
  }

  // 引用其他 item 固定字串或關鍵字串的內容
  // variable = 要帶入的變數
  const substituteTextVars = (data, item, variable) => {
    // 尋找${} 做成item index
    if (item?.type !== "keyText" && item?.type) {
      const reg_g = /\$\{(.+?)}/g;
      let result = null;
      let list = [];
      do {
        result = reg_g.exec(item.quoteText);
        result && list.push(result[1]);
      } while (result);

      let newStr = item.quoteText;
      for (const index of list) {
        // 將變數帶入 , 未帶入變數則以 ${} 顯示
        let varStr = () => {
          try {
            let result = variable[data[index - 1].keyText];
            if (!result) {
              result = "${" + data[index - 1].keyText + "}";
            }
            return result;
          } catch (e) {
            result = "${" + data[index - 1].keyText + "}";
            return result;
          }
        };
        newStr = newStr.replace(
          "${" + index + "}",
          data[index - 1].type === "text"
            ? data[index - 1].text
            : data[index - 1].type === "keyText"
            ? varStr()
            : ""
        );
      }

      return { ...item, quoteText: newStr };
    } else {
      return { ...item, keyText: variable[item.keyText] };
    }
  };

  const onSubmit = async (values) => {
    try {
      if (!s_templateParams.isvalid) {
        message.error("請選擇有效版本");
        return;
      }

      if (Object.keys(s_choosePaper).length === 0) {
        message.error("請重新選擇紙張");
        return;
      }

      if (!values.count || values.count <= 0) {
        message.error("請確認流水號產生數");
        return;
      }

      // 在補印時不需要轉換
      // if (s_recordSN.length === 0) {
      //   //date 因為options value label 的限制 提取出來
      //   values["timecode"] = values.date;
      //   values.date = values.date.split("_")[1];
      // }
      const keyParams = reorganizeKeyParams(values.tb);

      const modalsData = call_getModelTb.data?.map((x) => {
        const parseData = JSON.parse(x.variable);
        // 根據有幾張訂單 重新組合keyText的keyName
        if (parseData.type === "keyText") {
          const orderIndex = parseData.orderIndex;
          parseData["keyText" + orderIndex] = parseData.keyText;
        }
        return parseData;
      });

      const getMainCode = modalsData.filter(
        (x) =>
          x.type === "barcode" ||
          x.type === "datamatrix" ||
          x.type === "qrcode" ||
          x.type === "code128" ||
          x.type === "code39" ||
          x.type === "upca" ||
          x.type === "ean13"
      );

      // 先暫時不開啟
      if (getMainCode.length === 0) {
        message.error("查無圖形條碼");
        return;
      }

      // 進入補印環節
      if (s_recordSN.length > 0) {
        const formData = form.getFieldsValue();
        // 使用record s_recordSN[0] 代表只能用同一張工單進行列印
        const data = {
          SNs: s_recordSN.map((x) => x.SN),
          checkDigit: s_recordSN?.map((x) => x?.eg) || [],
          variable: {
            ...s_templateParams,
            ...keyParams,
            ...formData,
            ...s_recordSN[0],
            // date: s_recordSN[0].timecode,
            SN: undefined,
          },
        };
        call_reprint.request(
          s_recordSN.map((x) => ({
            ...x,
            printC: formData.printC,
            note: formData.note,
          }))
        );
        handleLabelPrint(data);
        return;
      }

      if (values.endSN.length > 0) {
        if (values.startSN.length === 0) {
          message.error("請確認手動起始號與手動起結束號，設定是否正確");
          return;
        }
      }

      // 轉換quoteText
      // 找到第一個有 {SN}
      // 如果都沒有找到 就用第1個
      let parseStr = "";
      for (const mainCodeItem of getMainCode) {
        let mainCode = substituteTextVars(modalsData, mainCodeItem, {
          ...values,
          ...keyParams,
          ...s_templateParams,
        }).quoteText;
        const result = mainCode.match("{SN}");
        if (result) {
          parseStr = result.input;
          break;
        }
      }

      if (parseStr === "") {
        parseStr = substituteTextVars(modalsData, getMainCode[0], {
          ...values,
          ...keyParams,
          ...s_templateParams,
        }).quoteText;
      }

      // 檢查 單綁需要SN
      // if (values.bindClass === "U") {
      //   // 檢查parseStr有沒有${SN}
      //   const result = parseStr.match("{SN}");
      //   if (!result) {
      //     message.error("請檢查模板 ${SN} 不存在");
      //     return;
      //   }
      // }

      // 防止超出限制長度
      // -5 ${SN}
      // 如果有檢驗碼${keyDigits} 在 -12 + 1 因為檢驗碼為1位 所以總共減11
      // let mainBarcodeLen =
      //   parseStr.length -
      //   (values.bindClass === "U" ? 5 : 0) -
      //   (values.engineID === " " ? 0 : 11) +
      //   (values.bindClass === "U" ? values.digits : 0);

      // if (mainBarcodeLen !== s_editData.length) {
      //   message.error("條碼不等於設定長度，請檢查圖形條碼組成，並且要檢查的條碼位於首位");
      //   return;
      // }
      // 給後端的quoteText
      const endStr = parseStr.replace("${SN}", "%sn").replace("${keyDigits}", "%eg");

      set_s_spinning(true);
      set_s_spinningTip("生成條碼流水號");

      let printParams = JSON.parse(JSON.stringify(values));
      printParams.count = 1;
      printParams.startSN = "1";

      call_print.request({
        ...values,
        startSN: values.startSN.toString(),
        height: unitToInch(s_choosePaper.high, s_choosePaper.highUnit) * 96, //存成px
        width: unitToInch(s_choosePaper.width, s_choosePaper.widthUnit) * 96, //存成px
        encode: parseInt(values.encode),
        codeUUID: keyParams.codeUUID,
        codeTemplate: endStr,
        count: values.count,
        printparams: JSON.stringify(printParams), //將列印參數存到後端
        tb: [],
      });
    } catch (e) {
      message.error("請確認資料完整性");
      console.log(e);
    }
  };

  // 標籤機列印
  const handleLabelPrint = async (props) => {
    const { labelData = [], variable = {} } = props;
    set_s_spinning(true); // 設置旋轉狀態為true，顯示旋轉圖示
    set_s_spinningTip(`條碼準備中...`); // 設置旋轉提示為「條碼準備中...」
    // 獲取條碼模板數據
    const modalsData = call_getModelTb.data?.map((x) => {
      const parseData = JSON.parse(x.variable);
      // 根據有幾張訂單 重新組合keyText的keyName
      if (parseData.type === "keyText" && parseData.keyText !== "SN") {
        const orderIndex = parseData.orderIndex;
        parseData["keyText" + orderIndex] = parseData.keyText;
      }
      return parseData;
    });

    const imgGroup = group(labelData, s_choosePaper.col); // 將實際的序列號分組
    const imgWidth = unitToInch(variable.width, variable.unit) * 96; // 單條碼的寬度
    const imgHeight = unitToInch(variable.height, variable.unit) * 96; // 單條碼的高度
    const imgGap = unitToInch(s_choosePaper.gap, s_choosePaper.gapUnit) * 96; // 多條碼之間的間隔
    const rowNormalImg = await labelPrinter({ ...labelData[0], ...variable }, modalsData, "normal"); // 生成一般條碼的圖像
    const batchSize = 50; // 批次處理大小

    for (let i = 0; i < imgGroup.length; i += batchSize) {
      const batch = imgGroup.slice(i, i + batchSize); // 按批次切分序列號分組
      set_s_spinningTip(
        `共列印　${imgGroup.length}　張，正在處理第 ${i + 1} - ${i + batch.length} 張條碼`
      ); // 設置旋轉提示為正在處理的條碼範圍

      // 按批次處理每個序列號分組
      const rowImgPromises = batch.map(async (x, idx) => {
        const rowKeyImg = await Promise.all(
          x.map((item) => labelPrinter({ ...variable, ...item }, modalsData, "key"))
        );
        return rowKeyImg;
      });

      const rowImgs = await Promise.all(rowImgPromises); // 等待所有批次處理完成的圖像

      const postImgs = combineBase64Images({
        col: s_choosePaper.col,
        imgWidth,
        imgHeight,
        imgGap,
        normalImgGroup: rowNormalImg,
        keyImgGroup: rowImgs,
        type: variable.woN,
      }); // 將圖像進行合併處理

      const params = {
        postImgs,
        s_chooseMachines,
        variable,
        imgWidth,
        imgHeight,
        imgGap,
        s_choosePaper,
        imgGroup,
        startSN: i + 1,
        endSN: i + batch.length,
        set_s_alert,
        set_s_spinning,
        angle: variable.angle,
      };
      //发送条码图像到服务器进行存储。
      await sendBarcodeToServer(params);
    }

    message.success("條碼成功發送至列印伺服器"); // 顯示成功消息

    await new Promise((resolve) => setTimeout(resolve, 3000));

    const monitorParams = {
      imgGroup,
      set_s_spinning,
      set_s_spinningTip,
      set_s_alert,
    };
    //监控条码打印进程并相应地更新UI元素。
    // await monitorBarcodePrinting(monitorParams);

    set_s_spinning(false); // 設置旋轉狀態為false，隱藏旋轉圖示
  };

  // 通用印表機列印
  const handleGeneralPrint = async (props) => {
    const { labelData = [], variable = {} } = props;
    console.log("6", labelData);
    set_s_spinning(true); // 設置旋轉狀態為true，顯示旋轉圖示
    set_s_spinningTip(`條碼準備中...`); // 設置旋轉提示為「條碼準備中...」
    // 獲取條碼模板數據
    const modalsData = call_getModelTb.data?.map((x) => {
      const parseData = JSON.parse(x.variable);
      // 根據有幾張訂單 重新組合keyText的keyName
      if (parseData.type === "keyText" && parseData.keyText !== "SN") {
        const orderIndex = parseData.orderIndex;
        parseData["keyText" + orderIndex] = parseData.keyText;
      }
      return parseData;
    });
    // 無需按批次，直接遍歷所有項目
    // 用于存放所有承諾（Promises）

    const allPromises = labelData.map((item) =>
      generalPrinter({ ...item, ...variable }, modalsData, "general")
    );

    console.log("7", allPromises);

    const allLabel = await Promise.all(allPromises)
      .then((rowImgs) => {
        return rowImgs.map((x, index) => (
          <img
            key={index}
            src={x}
            alt={`Image ${index}`}
            style={{
              width: variable.width + variable.unit,
              height: variable.height + variable.unit,
            }}
          />
        ));
      })
      .catch((error) => {
        message.error(error.message);
        set_s_alert({
          message: error.message ?? "條碼打印失敗，請確認模板是否正確以及後端伺服器是否有開啟",
          type: "error",
        });
        set_s_spinning(false);
        return [];
      });

    if (allLabel.length === 0) return;

    // 縮小1mm 防止紙張超出到下一頁
    const pageWidth =
      variable.direction === "portrait"
        ? variable.size.split("x")[0] - 1 + "mm"
        : variable.size.split("x")[1] - 1 + "mm";
    const pageHeight =
      variable.direction === "portrait"
        ? variable.size.split("x")[1] - 1 + "mm"
        : variable.size.split("x")[0] - 1 + "mm";

    const containerStyle = {
      width: pageWidth,
      height: pageHeight,
      // margin: "2mm",
      // border: "0.5px solid red",
    };

    const pageImage = group(allLabel, variable.row * variable.col);

    const final = pageImage.map((rowImg, i) => (
      <div
        key={i}
        style={containerStyle}
        className="break-before-column box-border whitespace-nowrap overflow-hidden "
      >
        {group(rowImg, variable.col).map((img, i) => {
          return (
            <div key={"row" + i} className="flex flex-row items-center ">
              {img}
            </div>
          );
        })}
      </div>
    ));

    set_s_allLabel(final);

    message.success("條碼生成完成"); // 顯示成功消息

    set_s_spinning(false); // 設置旋轉狀態為false，隱藏旋轉圖示
  };

  const handleCancel = () => set_s_showModal(false);

  const onPrint = useReactToPrint({
    content: () => generalRef.current,
    removeAfterPrint: true,
    onAfterPrint: () => set_s_allLabel([]),
    pageStyle: () => {
      const pageWidth =
        s_formData.direction === "portrait"
          ? s_formData.size.split("x")[0] + "mm"
          : s_formData.size.split("x")[1] + "mm";
      const pageHeight =
        s_formData.direction === "portrait"
          ? s_formData.size.split("x")[1] + "mm"
          : s_formData.size.split("x")[0] + "mm";

      return `
        body{   
        }
        @page {
          size:${pageWidth} ${pageHeight};
          margin:0cm;
        }
    `;
    },
    // .page-break{
    //   display: block;
    //   page-break-after: always
    // }
    // .page-break:last-child{
    //   display: block;
    //   page-break-after: auto
    // }
  });

  useEffect(() => {
    // 找當前 arr中對應的model
    let copy_s_templateParams = JSON.parse(JSON.stringify(s_templateParams));
    delete copy_s_templateParams.printparams;
    call_getModelTb.request(copy_s_templateParams);
    // getDate
    // call_getDaycodeHd.request();
    // 紙張選擇
    call_getLabel.request();
    // won
    // call_printWoN.request(s_keyParams);
    // 機器
    call_getPrinter.request();
    //如果有上次紀錄就帶入
  }, []);

  // 設定初始值
  useEffect(() => {
    // 抓第一筆訂單
    const keyParams = s_editData[0];
    if (
      Object.keys(keyParams).length > 0 &&
      Object.keys(s_templateParams).length > 0 &&
      s_templateParams.printparams === ""
    ) {
      console.log(1);
      form.setFieldsValue({
        ...s_templateParams,
        ...keyParams,
        count:
          keyParams?.["KWMENG"] === 0 || keyParams?.["TRGQTY"] === 0
            ? 0
            : Math.ceil(keyParams?.["KWMENG"] / keyParams?.["TRGQTY"]),
        printC: 1,
        digits: 0,
        angle: 0,
        encode: "10",
        bindClass: "U",
        engineID: " ",
        note: "",
        startSN: "1",
        endSN: "0",
        MD: 0,
        top_gap: 0,
        left_gap: 0,
        row: 1,
        col: 1,
        size: "210x297",
        direction: "portrait",
        tb: s_editData,
      });
    } else {
      console.log(2);
      const printParams = JSON.parse(s_templateParams.printparams);
      console.log(printParams);
      form.setFieldsValue({
        ...printParams,
        paper: printParams.labelID || s_templateParams.labelID,
        tb: s_editData,
      });
    }
  }, [s_editData, s_templateParams]);

  useEffect(() => {
    if (s_allLabel && s_allLabel.length > 0) {
      onPrint();
    }
  }, [s_allLabel]);

  // 標籤機下拉選單
  useEffect(() => {
    //如果有上次紀錄就帶入
    if (call_getPrinter.status === "suc") {
      const formData = form.getFieldsValue();
      if (formData?.machines && formData?.machines !== "") {
        let getmachines = call_getPrinter.data.filter((x) => x.printerID === formData.machines);
        set_s_chooseMachines(getmachines[0]);
      } else {
        // 自動帶入第一筆 未完成
        // printMethod: data.data.encode,
        // console.log(2, call_getPrinter.data[0]);
        // 否則預設帶入第1筆
        // form.setFieldsValue({
        //   dpi: call_getPrinter.data[0].dpi,
        //   machines: call_getPrinter.data[0].printerID,
        //   printMethod: call_getPrinter.data[0].encode,
        // });
        // set_s_chooseMachines({ ...call_getPrinter.data[0] });
      }
    }
  }, [call_getPrinter.status]);

  // 紙張下拉選單
  useEffect(() => {
    if (call_getLabel.status === "suc") {
      const options = call_getLabel.data.map((x) => ({
        label: x.labelID + "_" + x.labelNM,
        value: x.labelID,
        data: x,
      }));
      set_s_paperOptions(options);
      //如果有上次紀錄就帶入
      const formData = form.getFieldsValue();
      if (formData?.paper && formData?.paper !== "") {
        let getPaper = call_getLabel.data.filter((x) => x.labelID === formData.paper);
        set_s_choosePaper(getPaper[0]);
      }
    } else if (call_getLabel.status === "err") {
      message.error(call_getLabel.msg);
    }
  }, [call_getLabel.status]);

  useEffect(() => {
    if (call_printWoN.status === "suc") {
      let woNOptions = call_printWoN.data.map((curr) => ({
        label: curr.woN,
        value: curr.woN,
      }));
      woNOptions.unshift({
        label: "測試用-無工單號",
        value: " ",
      });
      set_s_wonOptions(woNOptions);
    } else if (call_printWoN.status === "err") {
      message.error(call_printWoN.msg);
      let woNOptions = [
        {
          label: "測試用-無工單號",
          value: " ",
        },
      ];
      set_s_wonOptions(woNOptions);
    }
  }, [call_printWoN.status]);

  useEffect(() => {
    if (call_getModelTb.status === "err") {
      message.error(call_getModelTb.msg);
    }
  }, [call_getModelTb.status]);

  useEffect(() => {
    if (call_getDaycodeHd.status === "suc") {
      set_s_dateHdOptions(
        call_getDaycodeHd.data.reduce(
          (data, current) => {
            data.push({
              label: current.custNM,
              value: current.custID,
              data: current,
            });
            return data;
          },
          [{ label: "無", value: " ", data: "" }]
        )
      );
    } else if (call_getDaycodeHd.status === "err") {
      message.error(call_getDaycodeHd.msg);
      set_s_dateHdOptions([{ label: "無", value: " ", data: "" }]);
    }
  }, [call_getDaycodeHd.status]);

  useEffect(() => {
    if (call_getDaycodeTb.status === "suc") {
      set_s_dateTbOptions(
        call_getDaycodeTb.data.map((x) => ({
          label: `${dayjs(x.date).format("YYYY-MM-DD")} || ${x.timecode}`,
          value: `${dayjs(x.date).format("YYYY-MM-DD")}_${x.timecode}`,
        }))
      );
    } else if (call_getDaycodeTb.status === "err") {
      message.error(call_getDaycodeTb.msg);
      set_s_dateTbOptions([{ label: "無", value: " " }]);
    }
  }, [call_getDaycodeTb.status]);

  useEffect(() => {
    if (call_print.status === "suc") {
      message.success(call_print.msg);
      // 這邊處理 Data
      // 獲取表單數據
      const formDataValues = form.getFieldsValue();
      set_s_formData(formDataValues);
      let { tb, ...otherFormData } = formDataValues;
      // 深拷貝 formData 中的 'tb' 以避免直接更改原始數據
      const clonedTableData = JSON.parse(JSON.stringify(tb));
      const realSNs = call_print.data.SN;
      const realKeyDigits = call_print.data.checkDigit;

      // 用於存儲最終結果的數組
      let preparedData = [];
      console.log(0);
      // 根據 realSNs 生成相應數量的條目
      for (let i = 1; i <= realSNs.length; i++) {
        let newParams = {};

        // 添加 SN 和 keyDigits 到當前條目
        newParams["SN"] = realSNs[i - 1];
        newParams["keyDigits"] = realKeyDigits[i - 1];

        // 遍歷 formDataTb 以建立新的參數鍵值對
        console.log(1);
        for (const [index, item] of clonedTableData.entries()) {
          // 這裡判斷 LFIMG TRGQTY 是否會被帶入 如果長度為0 LFIMG / TRGQTY / KWMENG 則 key i 不生成
          const itemLength =
            tb[index]["LFIMG"] === 0 || tb[index]["TRGQTY"] === 0
              ? 0
              : Math.ceil(tb[index]["LFIMG"] / tb[index]["TRGQTY"]);
          if (i <= itemLength) {
            console.log(2);
            const TRGQTY = toInt(tb[index]["TRGQTY"] || 0);
            const LFIMG = toInt(tb[index]["LFIMG"] || 0);
            const TOTLWEI = tofloat(tb[index]["TOTLWEI"] || 0);
            const LOADWEI = tofloat(tb[index]["LOADWEI"] || 0);
            //尾數箱為滿箱就不用ZZPARTIAL
            const LAST_TOTLWEI =
              LFIMG % TRGQTY === 0 ? TOTLWEI : tofloat(tb[index]["ZZPARTIAL_TOTLWEI"] || 0);
            const LAST_LOADWEI =
              LFIMG % TRGQTY === 0 ? LOADWEI : tofloat(tb[index]["ZZPARTIAL_LOADWEI"] || 0);
            item["ZZTOTAL_BOX"] = itemLength;
            // 以第一筆 每箱數量 作為基礎 計算最後一筆的每箱數量  LFIMG % TRGQTY
            // 判斷當前是否為最後一筆，並相應地設定 TRGQTY
            if (i === itemLength) {
              console.log(3);
              const remainder = LFIMG % TRGQTY === 0 ? TRGQTY : LFIMG % TRGQTY;
              item["TRGQTY"] = remainder;
              item["TOTLWEI"] = LAST_TOTLWEI.toFixed(2);
              item["LOADWEI"] = LAST_LOADWEI.toFixed(2);
              console.log("ZZPARTIAL_TOTLWEI", LAST_TOTLWEI);
              console.log("ZZPARTIAL_LOADWEI", LAST_LOADWEI);

              for (const key in item) {
                newParams[key + (index + 1)] = item[key];
              }
            } else {
              console.log(4);
              item["TRGQTY"] = TRGQTY;
              item["TOTLWEI"] = TOTLWEI.toFixed(2);
              item["LOADWEI"] = LOADWEI.toFixed(2);

              for (const key in item) {
                newParams[key + (index + 1)] = item[key];
              }
            }
          }
        }
        // 將當前條目添加到結果數組中
        preparedData.push(newParams);
      }

      //根據 printC 重複項次
      const finalDataArray = preparedData.reduce((data, item) => {
        for (let i = 0; i < parseInt(otherFormData.printC); i++) {
          data.push(item);
        }
        return data;
      }, []);

      if (finalDataArray.length === 0) {
        message.error("請確認流水號產生數");
        return;
      }
      console.log(5, finalDataArray);
      const data = {
        labelData: finalDataArray,
        variable: { ...s_templateParams, ...otherFormData },
      };

      if (s_printType === "label") {
        handleLabelPrint(data);
      } else {
        handleGeneralPrint(data);
      }
    } else if (call_print.status === "err") {
      if (call_print.msg === "re") {
        Modal.error({
          title: "重碼錯誤,請確認列印條碼",
          content: call_print.data.map((x) => x.barcode).toString(),
        });
      } else {
        message.error(call_print.msg);
      }
      set_s_spinning(false);
    }
  }, [call_print.status]);

  useEffect(() => {
    if (call_reprint.status === "suc") {
      message.success(call_reprint.msg);
    } else if (call_reprint.status === "err") {
      message.error(call_reprint.msg);
    }
  }, [call_reprint.status]);

  // 每次TB變更時 重新計算Count
  useEffect(() => {
    if (f_tb) {
      const data = f_tb[0];
      const count =
        data?.["KWMENG"] === 0 || data?.["TRGQTY"] === 0
          ? 0
          : Math.ceil(data?.["KWMENG"] / data?.["TRGQTY"]);

      const newTb = f_tb.map((x) => ({
        ...x,
        ZZTOTAL_BOX:
          x["LFIMG"] === 0 || x["TRGQTY"] === 0 ? 0 : Math.ceil(x["LFIMG"] / x["TRGQTY"]),
      }));

      form.setFieldsValue({ count: count, tb: newTb });
    }
  }, [f_tb]);

  return (
    <Spin spinning={s_spinning || call_print.status === "load"} tip={s_spinningTip}>
      <Form name="basic" form={form} autoComplete="off" layout="vertical" onFinish={onSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Alert message={s_alert.message} type={s_alert.type} />
          </Col>
          <Col span={4}>
            <Form.Item name="modelNM" label="模板名稱">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="SAP標籤類型" name="saptype">
              <Select allowClear showSearch options={sapTypeOptions} disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="SAP標籤ID" name="sapSNstr">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="modelID" label="模板ID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ver" label="版本">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4} />
          <Col span={4}>
            <Form.Item rules={[{ required: true }]} name="machines" label="設備">
              <Select
                onChange={(_, data) => {
                  set_s_chooseMachines(data.data);
                  form.setFieldsValue({
                    dpi: data.data.dpi,
                    printMethod: data.data.encode,
                  });
                }}
                options={
                  call_getPrinter?.data?.map((x) => ({
                    label: x.printerNM,
                    value: x.printerID,
                    data: x,
                  })) || []
                }
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item rules={[{ required: true }]} name="paper" label="紙張">
              <Select
                onChange={(_, data) => set_s_choosePaper(data.data)}
                options={s_paperOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="印表機參數調整" key="1">
                <Row gutter={[12, 0]}>
                  <Col span={6}>
                    <Form.Item name="dpi" label="DPI">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="printMethod" label="列印方式">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item rules={[{ required: true }]} name="MD" label="打印濃度">
                      <Select options={mdOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item rules={[{ required: true }]} name="angle" label="旋轉角度">
                      <Select options={angleOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item rules={[{ required: true }]} name="top_gap" label="上間隙">
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item rules={[{ required: true }]} name="left_gap" label="左間隙">
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="direction" label="紙張方向">
                      <Select
                        options={[
                          { label: "直向", value: "portrait" },
                          { label: "橫向", value: "landscape" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="size" label="紙張大小">
                      <Select
                        options={[
                          { label: "原始尺寸", value: "default" },
                          { label: "A4", value: "210x297" },
                          { label: "A5", value: "148x210" },
                          { label: "A6", value: "100x143" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="row" label="直向數量">
                      <InputNumber min={1} className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name="col" label="橫向數量">
                      <InputNumber min={1} className="w-full" />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Collapse defaultActiveKey={["2"]}>
              <Panel header="打印參數設置" key="2">
                <Row gutter={[12, 0]}>
                  {s_recordSN.length === 0 && (
                    <>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="encode" label="流水號進位">
                          <Select
                            options={[
                              { label: "10進位", value: "10" },
                              { label: "16進位", value: "16" },
                              { label: "32進位不含(I,O,U,V)", value: "32" },
                              { label: "36進位", value: "36" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          rules={[{ required: true }]}
                          name="digits"
                          label="流水號位數(0為自動累加)"
                        >
                          <InputNumber className="w-full" min={0} />
                        </Form.Item>
                      </Col>

                      {/* <Col span={12} /> */}
                      <Col span={6}>
                        <Form.Item
                          rules={[{ required: true }]}
                          name="count"
                          label="流水號產生數 (第一筆訂單 訂單數量 / 每箱數量)"
                        >
                          <InputNumber className="w-full" min={1} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="printC" label="連續列印數">
                          <InputNumber className="w-full" min={1} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          rules={[{ required: true }]}
                          name="startSN"
                          label="手動起始號(接續打印為0)"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name="endSN" label="手動結束號">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Panel>
            </Collapse>
          </Col>

          <Divider orientation="left">訂單變數管理</Divider>
          <Col span={24}>
            <Form.List name="tb" shouldUpdate initialValue={[]}>
              {(fields) => {
                // 從模板取得需要調整的欄位
                const modalsData = call_getModelTb.data
                  ?.map((x) => JSON.parse(x.variable))
                  .filter((x) => x.type === "keyText" && x.keyText !== "SN");

                return (
                  <Collapse defaultActiveKey={["3"]}>
                    {fields.map((field, index) => {
                      // 找存在模板中的keyText
                      const orderData = modalsData.filter((x) => field.name + 1 === x.orderIndex);
                      const rowData = form.getFieldValue(["tb", field.name]);

                      const check_KWMENG =
                        orderData.findIndex((x) => x.keyText === "KWMENG") !== -1;
                      const check_TRGQTY =
                        orderData.findIndex((x) => x.keyText === "TRGQTY") !== -1;

                      const check_LFIMG = orderData.findIndex((x) => x.keyText === "LFIMG") !== -1;

                      return (
                        <Panel key={field.name} header={rowData.VBELN}>
                          <Row gutter={[12, 12]}>
                            {/* 渲染有的KeyText */}
                            {!check_KWMENG && (
                              <>
                                <Col span={4} key={index}>
                                  <Form.Item
                                    label={<span className="text-red-400">訂單數量-KWMENG</span>}
                                    name={[field.name, "KWMENG"]}
                                  >
                                    <InputNumber className="w-full" />
                                  </Form.Item>
                                </Col>
                              </>
                            )}

                            {!check_TRGQTY && (
                              <>
                                <Col span={4} key={index}>
                                  <Form.Item
                                    label={<span className="text-red-400">每箱數量-TRGQTY</span>}
                                    name={[field.name, "TRGQTY"]}
                                  >
                                    <InputNumber className="w-full" />
                                  </Form.Item>
                                </Col>
                              </>
                            )}

                            {!check_LFIMG && (
                              <>
                                <Col span={4} key={index}>
                                  <Form.Item
                                    label={<span className="text-red-400">出貨數量-LFIMG</span>}
                                    name={[field.name, "LFIMG"]}
                                  >
                                    <InputNumber className="w-full" />
                                  </Form.Item>
                                </Col>
                              </>
                            )}

                            {orderData.map((x) => {
                              const matchLabel = keyTextOptions.filter(
                                (option) => option.key === x.keyText
                              );
                              return (
                                <>
                                  <Col span={4} key={index}>
                                    <Form.Item
                                      label={
                                        <span
                                          className={
                                            (matchLabel[0].key === "TRGQTY" ||
                                              matchLabel[0].key === "KWMENG" ||
                                              matchLabel[0].key === "LFIMG") &&
                                            "text-red-400"
                                          }
                                        >
                                          {matchLabel[0].text + "-" + matchLabel[0].key}
                                        </span>
                                      }
                                      name={[field.name, x.keyText]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </Panel>
                      );
                    })}
                  </Collapse>
                );
              }}
            </Form.List>
          </Col>

          <div className="hidden">
            <div ref={generalRef}>{s_allLabel}</div>
          </div>

          <Col span={24} className="flex justify-end">
            <Space>
              <Button onClick={handleCancel}>取消</Button>
              <Button
                type="primary"
                htmlType="submit"
                danger
                onClick={() => set_s_printType("general")}
                // disabled
              >
                印表機列印
              </Button>
              <Button type="primary" htmlType="submit" onClick={() => set_s_printType("label")}>
                標籤機列印
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
