// DroppableComponent.js
import React from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const XBarChartStyle = styled.div`
  padding: 0px 15px 15px 15px;
  width: 100%;

  max-height: 85vh;
  overflow-y: scroll;
`;

const DroppableComponent = ({ children, onDrop }) => {
  const [, drop] = useDrop({
    accept: "DIV",
    collect: monitor => ({
        targetId: monitor.getItem() ? monitor.getItem().id : null
      }),
    drop: (item, monitor) => {
      console.log(monitor);
      if (monitor.getItem()) {
        const draggedId = monitor.getItem().id; // 拖動元素的id
        const targetId = item.id; // 目標元素的id
        onDrop(draggedId, targetId); // Pass the dragged item id and the target item id
      }
    },

  });

  return <div ref={drop}>{children}</div>;
};

export default DroppableComponent;
