import { Button, Col, Form, Input, InputNumber, Row, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext } from "react";
import styled from "styled-components";

// context
import SystemContext from "@/components/Context/SystemContext";
import { updateQmsSopRec } from "@/service/apis/QMS/QMSManage";

const CustomForm = styled(Form)`
  .ant-input,
  .ant-picker,
  .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto;
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`;

const RecModalContainer = ({ s_editData, set_is_showModal, set_s_editData }) => {
  const [form] = Form.useForm();

  const { c_PNs } = useContext(SystemContext);
  const f_checkitem = Form.useWatch("checkitem", form);

  const handleCancel = () => {
    set_is_showModal(false);
    set_s_editData({});
  };

  const onSubmit = (values) => {
    const call_updateQmsSopRec = async () => {
      console.log("要傳給後端的資料 = ", {
        ...s_editData,
        ...values,
      });
      // return
      const res = await updateQmsSopRec({
        ...s_editData,
        ...values,
      });
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false);
      set_s_editData({});
    };
    call_updateQmsSopRec();
  };

  const checkitem_map = {
    "labor": "人員掃碼",
    "equip": "設備掃碼",
    "feed": "投料掃碼",
    "method": "方法掃碼",
    "request": "說明文字",
    "label": "輸入欄位",
    "select": "項目選擇",
    "value": "數值紀錄",
  };

  return (
    <Form.Provider
      onFormChange={(formName, info) => {
        const { changedFields } = info;
        console.log(changedFields);
        // 改變料號要自動帶入品名
        if (changedFields[0].name[0] === "itemanchor") {
          if (changedFields[0].value === "" || changedFields[0].value === undefined) {
            form.setFieldsValue({
              "pdtNM": "",
            });
          } else {
            const choosePN = c_PNs.find((item) => item.PN === changedFields[0].value);
            form.setFieldsValue({
              "pdtNM": choosePN ? choosePN.pdtNM : "",
            });
          }
        }
      }}
    >
      <CustomForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          ...s_editData,
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>掃碼類別</label>}
              name="checkitem"
            >
              <Select
                disabled
                options={Object.keys(checkitem_map).map((key) => ({
                  label: checkitem_map[key],
                  value: key,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>項序</label>}
              name="recno"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        {["labor", "equip", "feed", "method"].includes(f_checkitem) ? (
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>項目名稱</label>} name="itemNM">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item 
                label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>部品品名</label>}
                name="pdtNM"
              >
                <Input disabled />
              </Form.Item>
            </Col> */}

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>識別字串</label>}
                name="itemanchor"
                // rules={[{ required: true, message: '請輸入部品料號!' }]}
              >
                {/* <Select 
                  placeholder="選擇部品料號"
                  allowClear
                  // disabled={Object.keys(s_editData).length > 0 ? true : false}
                >
                  {c_PNs.length > 0 && c_PNs.map((item, index) => {
                    return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                  })}
                </Select> */}
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<label>防呆字串</label>} name="itemkw">
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>備註</label>} name="note">
                <Input.TextArea name="note" rows={3} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>標題</label>} name="label">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} />
            <Col span={8} />
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>說明內容</label>} name="input">
                <TextArea rows="1" disabled={f_checkitem !== "request"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>反饋文字</label>} name="value">
                <Input className="w-full" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>內容</label>} name="value">
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>量測單位</label>} name="UOM">
                <Input disabled={f_checkitem !== "value"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>標準值</label>} name="CL">
                <InputNumber className="w-full" disabled={f_checkitem !== "value"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>管制上限</label>} name="UCL">
                <InputNumber className="w-full" disabled={f_checkitem !== "value"} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<label style={{ color: "#6C6C6C" }}>管制下限</label>} name="LCL">
                <InputNumber className="w-full" disabled={f_checkitem !== "value"} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={18}>
            {Object.keys(s_editData).length > 0 ? (
              <Button
                size="large"
                style={{ marginRight: "20px", borderRadius: "5px", display: "none" }}
              >
                據此條目創建
              </Button>
            ) : null}
          </Col>
          <Col span={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="large"
                style={{ marginRight: "20px", borderRadius: "5px" }}
                onClick={handleCancel}
              >
                取消
              </Button>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: "5px" }}
                >
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </CustomForm>
    </Form.Provider>
  );
};

export default RecModalContainer;
