import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

//----------------------------------------- 新建作業科目管理(表頭) ----------------------------------------
export const addOpHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOpHd`, data);
};

//----------------------------------------- 取得所有作業科目管理(表頭)  ----------------------------------------
export const getOpHd = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOpHd`);
};

//----------------------------------------- 編輯作業科目管理(表頭) ----------------------------------------
export const modifyOpHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOpHd`, data);
};

//----------------------------------------- 刪除作業科目管理(表頭) ----------------------------------------
export const deleteOpHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOpHd`, data);
};

//----------------------------------------- 新建作業科目管理(表身) ----------------------------------------
export const addOpTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOpTb`, data);
};

//----------------------------------------- 取得所有作業科目管理(表身)  ----------------------------------------
export const getOpTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOpTb`, { params: data });
};

//----------------------------------------- 編輯作業科目管理(表身) ----------------------------------------
export const modifyOpTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOpTb`, data);
};

//----------------------------------------- 刪除作業科目管理(表身) ----------------------------------------
export const deleteOpTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOpTb`, data);
};
