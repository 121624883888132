  import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getBiQuerycat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiQuerycat`, {
    params: _params,
  });
};

export const getBiPN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiPN`, {
    params: _params,
  });
};

//
//取得入庫結案良率 機種
export const getBiInstockOkRateQuerycat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiInstockOkRateQuerycat`, {
    params: _params,
  });
};
export const getBiAllInstockOkRate = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiAllInstockOkRate`, {
    params: _params,
  });
};

//取得入庫結案良率 料號
export const getBiInstockOkRatePN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiInstockOkRatePN`, {
    params: _params,
  });
};

//取得各料工時良率 機種
export const getBiPdtwhmQuerycat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiPdtwhmQuerycat`, {
    params: _params,
  });
};
//取得各料工時良率 料號
export const getBiPdtwhmPN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiPdtwhmPN`, {
    params: _params,
  });
};

export const getBiMOMQuerycat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiMOMQuerycat`, {
    params: _params,
  });
};
export const getBiQuerycatPN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiQuerycatPN`, {
    params: _params,
  });
};

export const getBiMOMPN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiMOMPN`, {
    params: _params,
  });
};


export const getBiMOMAllQuerycat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiMOMAllQuerycat`, {
    params: _params,
  });
};


export const getBiParent = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBiParent`);
};