import { forwardRef } from "react";

import HD from "./HD";
import TB from "./TB";

const SIP = forwardRef((props, ref) => {
  const { c_lightData, set_c_lightData, call_addSpcN, s_isFixed } = props;
  return (
    <div>
      <HD
        ref={ref}
        call_addSpcN={call_addSpcN}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
        s_isFixed={s_isFixed}
        {...props}
      />
      {Object.keys(c_lightData).length > 0 && (
        <TB c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      )}
    </div>
  );
});

export default SIP;
