import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Row, Col, Input, Button, Alert, Table,Spin } from "antd";
import { fontSize } from "@/styles/BasicSetting";

import Cookies from "js-cookie";
import PageHeader from "@/components/PageHeader";

import ControlTable from "./Table";
import { useTranslation } from "react-i18next";
import QCList from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/WpcTable/index";
import XBarChart from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/ModalContainer/XBarChart";
import AlertCard from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/Alert/index";
import showDecimalPoint from "@/pages/TestPage/QMS/SPC/util/showDecimalPoint";
import { getSpcDataSocket, getSpcOverview } from "@/service/ws/ws";
import useWebSocket from "@/hooks/useWebSocket";

import InformationBoard from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/InformationBoard";
import Text from "@/components/MC/Text";
import eazyWpcContext from "@/pages/TestPage/QMS/SPC/ControlArea/ModalContainer/eazySPC/Context/Wpc";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
const WlWocStyle = styled.div`
  /* height: 100%; */
  //  background-color:gray;
  //每個區塊外面的邊框
  .normal-component {
    background-color: ${({ theme }) => theme.componentBg};
    /* padding: 8px; */

    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
    z-index: 10;
  }
  .nobg-normal-component {
    margin: 8px;
    border-radius: 8px;
    z-index: 10;
  }
  .special-component {
    margin: 8px;
    /* padding: 8px 16px 0px 16px; */
    border-radius: 8px;
    z-index: 10;
  }
`;
const CustomTableStyle = styled(Table)`
  /* table 表投靠中 */
  .ant-table-thead > tr > th {
    text-align: center !important;
    background-color: #e2e1ff;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: ${fontSize.h6};
    white-space: nowrap;
    font-weight: 700;
    color: #625ecf;
    font-size: 16px;
    /* border-radius: 8px; */
  }
  .ant-table-cell {
    font-size: ${fontSize.h5};
    font-weight: 600;
    border: none;
  }

  /* // table 顯示頁碼的地方 */
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.h4};
  }

  /* // table pagination center */
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  .ant-table {
    border-radius: 8px !important;

    /* margin: 15px 0px; */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
  .ant-table-row td {
    padding: 5px !important;
  }
  .ant-table-thead {
    border-radius: 8px !important;
    color: #625ecf;
  }
  .ant-table-container {
    border-radius: 8px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:first-child {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    // border-right: 1px solid #c4c4c4;
    border-right: none;
  }

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border: none !important;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: none !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    ):before {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .once {
    background-color: ${({ theme }) => theme.tableOnce};
  }
  .twoe {
    background-color: ${({ theme }) => theme.tableTwoe};
  }

  &:hover .clickRowStyle .ant-table-cell {
    background-color: #67349a !important;
  }

  .ant-table-row-selected {
    background-color: #67349a !important;
  }
  /* .ant-table-row-selected:hover {
    background-color: #67349a !important;
  } */
  .clickRowStyle {
    background-color: #67349a !important;
    color: white;
  }
  .ant-table-row ant-table-row-level-0 clickRowStyle {
    background-color: #67349a !important;
    color: white;
  }
`;

const tableColumns = [
  {
    title: "圖號",
    dataIndex: "drawno",
    align: "center",
    width: 30,
  },
  {
    title: "CL",
    dataIndex: "CL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "2"100px"px",
  },
  {
    title: "UCL",
    dataIndex: "UCL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "250px",
  },
  {
    title: "LCL",
    dataIndex: "LCL",
    align: "right",
    width: 50,
    render: (text) => showDecimalPoint(text, 2),
    // width: "250px",
  },
];

export default function SPC({ overViewDrawno, s_selectedDrawno }) {
  const { t } = useTranslation();
  const { s_DrawnoTableData } = useContext(WpcContext);
  const [s_eazythisDrawno, set_s_eazythisDrawno] = useState({});

  const call_getEazySpcDataSocket = useWebSocket(getSpcDataSocket);
  const [s_isLoading, set_s_isLoading] = useState(false);
  const [s_XBarChartData, set_s_XBarChartData] = useState([]);

  useEffect(() => {
    set_s_eazythisDrawno(overViewDrawno);
  }, []);

  useEffect(() => {
    // const {status,data}
    if (call_getEazySpcDataSocket.socketData.status) {
      const new_XBarChartData = [
        ...call_getEazySpcDataSocket.socketData.data.map((item) => ({
          ...item,
          CL: s_eazythisDrawno["CL"],
          LCL: s_eazythisDrawno["LCL"],
          UCL: s_eazythisDrawno["UCL"],
          LSL: s_eazythisDrawno["LSL"],
          USL: s_eazythisDrawno["USL"],
        })),
      ];

      set_s_XBarChartData([...new_XBarChartData]);
      set_s_isLoading(false);

      return;
    } else {
      // set_s_SPCtableData([]);
    }
  }, [call_getEazySpcDataSocket.socketData]);
  useEffect(() => {
    console.log(s_eazythisDrawno);
    if (Object.keys(s_eazythisDrawno).length === 0) {
      call_getEazySpcDataSocket.setIsOpen(false);
      set_s_XBarChartData([]);
      return;
    }
    set_s_isLoading(true);
    call_getEazySpcDataSocket.setIsOpen(true);
    call_getEazySpcDataSocket.changeParams({ ...s_eazythisDrawno });
  }, [s_eazythisDrawno]);
  console.log(s_DrawnoTableData);
  console.log(s_eazythisDrawno);
  return (
    <>
      <PageHeader
        title={<div className="bg-[#7a71f8]  text-white rounded-lg p-2">統計製程管制</div>}
        className={"mb-0 relative"}
      />
      <WlWocStyle>
        <div className="flex gap-2 bg-[#F5F5F5]  w-full  justify-between mt-2 p-0">
          <div className=" flex flex-col w-[20%] justify-between gap-[17px]  ">
            <div className="flex flex-col ">
              <CustomTableStyle
                scroll={{ y: 410 }}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      console.log(record.key);
                      return record.key === s_eazythisDrawno.key
                        ? set_s_eazythisDrawno({})
                        : set_s_eazythisDrawno(record);
                    },
                  };
                }}
                columns={tableColumns}
                dataSource={s_DrawnoTableData.filter((item) => {
                  return s_selectedDrawno.length === 0
                    ? item
                    : s_selectedDrawno.includes(item.drawno);
                })}
                rowClassName={(record, index) => {
                  if (record?.key === s_eazythisDrawno?.key) {
                    return "clickRowStyle";
                  } else if ((index + 1) % 2 === 1) {
                    return "bg-[#FFFFFF]"; // 奇數行
                  } else {
                    return "bg-[#F2F3F9]"; // 偶數行
                  }
                }}
              />
            </div>
            <AlertCard />
          </div>

          <div className="normal-component  w-[58%]  bg-white   flex  items-center">
           <Spin wrapperClassName="w-full" className="w-full" spinning={s_isLoading}>
            <XBarChart s_XBarChartData={s_XBarChartData} s_eazythisDrawno={s_eazythisDrawno} />
          </Spin>
            {/* <div></div> */}
          </div>
          <div className="flex flex-col flex-1   gap-[20px]">
            <InformationBoard s_eazythisDrawno={s_eazythisDrawno} />
          </div>
        </div>
      </WlWocStyle>
    </>
  );
}
