import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Input, Select, message, Space, Radio } from "antd";
import { addAdd, modifyAdd } from "@/service/apis/ADM/functionParamsManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal, type, arrMap }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addAdd = useAPI(addAdd);
  const call_modifyAdd = useAPI(modifyAdd);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addAdd.request(values);
    }
    if (type === "edit") {
      call_modifyAdd.request(values);
    }
  };

  useEffect(() => {
    if (call_addAdd.status === "suc") {
      message.success(call_addAdd.msg);
      set_s_isShowModal(false);
    } else if (call_addAdd.status === "err") {
      message.error(call_addAdd.msg);
    }
  }, [call_addAdd.status]);

  useEffect(() => {
    if (call_modifyAdd.status === "suc") {
      message.success(call_modifyAdd.msg);
      set_s_isShowModal(false);
    } else if (call_modifyAdd.status === "err") {
      message.error(call_modifyAdd.msg);
    }
  }, [call_modifyAdd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        "PMS": type === "PMS",
        "OMS": type === "OMS",
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("ADM.trading-pattern.addtype")}
            rules={[{ required: true }]}
            name="addtype"
          >
            <Select disabled={Object.keys(s_editData).length > 0} options={arrMap.addTypeMap} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("ADM.trading-pattern.additem")}
            rules={[{ required: true }]}
            name="additem"
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.itemNM")} name="itemNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.trading-pattern.PMS")} name="PMS" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.trading-pattern.OMS")} name="OMS" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
