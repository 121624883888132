import { message } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import useAPI from "@/hooks/useAPI";
import { getWoProcHd } from "@/service/apis/PIS/wonSearch";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_tabKey, s_searchData, set_s_searchData, c_lightData, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const call_getWoProcHd = useAPI(getWoProcHd);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => call_getWoProcHd.request({ ...s_searchData, page: 1, pageSize: 200 }),
    onCreate: () => {
      set_c_lightData({});
    },
  }));

  const tableColumns = [
    {
      title: "工單號",
      dataIndex: "wpcset",
      align: "center",
    },
    {
      title: "訂單號",
      dataIndex: "orderN",
      align: "center",
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      width: "15%",
    },
    {
      title: "規格",
      dataIndex: "pdtspec",
      width: "15%",
    },
    {
      title: "訂單項次",
      dataIndex: "orderitemno",
      align: "center",
    },
    {
      title: "目前工作中心",
      dataIndex: "pwcID",
      width: "15%",
      render: (_, rowData) => rowData.pwcID + "_" + rowData.pwcNM,
    },
    {
      title: "工序",
      dataIndex: "SAPwpc",
      align: "center",
    },
    {
      title: "工單總量",
      dataIndex: "run10planqty",
      align: "right",
    },
    {
      title: "計畫產量",
      dataIndex: "planqty",
      align: "right",
    },
    {
      title: "良品數量",
      dataIndex: "okC",
      align: "right",
    },
    {
      title: "不良數量",
      dataIndex: "ngC",
      align: "right",
    },
  ];

  useEffect(() => {
    call_getWoProcHd.request(s_searchData);
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getWoProcHd.status === "suc") {
      message.success(call_getWoProcHd.msg);
      set_s_tableData(
        call_getWoProcHd.data.tableData?.map((x, i) => {
          return {
            ...x,
            key: i,
          };
        })
      );
      set_s_pageInfo(call_getWoProcHd.data.pageInfo);
    } else if (call_getWoProcHd.status === "err") {
      message.error(call_getWoProcHd.msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getWoProcHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getWoProcHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
    </>
  );
});

export default HD;
