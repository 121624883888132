import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getInspmethod = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInspmethod`, {
    params: _params,
  });
};
export const addInspmethod = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInspmethod`, data);
};

export const updateInspmethod = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInspmethod`, data);
};

export const deleteInspmethod = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInspmethod`, data);
};
