import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const getStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStaff`, { params: data });
};

export const getWC = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWC`, { params: data });
};

export const deleteWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWC`, data);
};

export const addWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWC`, data);
};

export const modifyWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWC`, data);
};

//----------------------------------------- 搜尋車間資料 ----------------------------------------
export const getPS = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPS`, { params: data });
};

//------------------------------------------ 取得線別 ------------------------------------------
export const getLine = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getLine`, { params: data });
};
