import styled from "styled-components";
import { Tabs } from "antd";

const StyleTabs = styled(Tabs)`
  // 老Ａ新加入，目的是讓extra區域不要黏在底部
  .ant-tabs-extra-content {
    display: flex;
    justify-content: start;
  }
  // 老Ａ新加入，待討論
  .ant-tabs-tab {
    padding-bottom: 10px !important;
  }
`;

const CustomTabs = (props) => {
  let sortItems = props.items.filter((x) => !x.hidden);
  return (
    <StyleTabs
      // className="font-semibold"
      destroyInactiveTabPane
      {...props}
      items={sortItems}
    />
  );
};

export default CustomTabs;
