import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { getAqlHd } from "@/service/apis/QMS/aql";
import { addPlanTb, updatePlanTb } from "@/service/apis/QMS/plan";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  c_lightData,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const f_tb = Form.useWatch("tb", form);
  const call_addPlanTb = useAPI(addPlanTb);
  const call_updatePlanTb = useAPI(updatePlanTb);
  const call_getAqlHd = useAPI(getAqlHd);
  const AqlOptions = call_getAqlHd?.data?.map((item, index) => {
    console.log(call_getAqlHd?.data[index + 1]);
    const label = `${item.sizecode} : ${item.size} ~ ${call_getAqlHd?.data[index + 1]?.size ?? ""}`;

    return {
      label,
      value: item.sizecode,
    };
  });
  // const call_updateQmsSign = useAPI(updateQmsSign);

  const handleCancel = () => {
    set_s_isShowModal(false);
  };
  const onSubmit = (values) => {
    call_addPlanTb.request(values.tb);
  };
  useEffect(() => {
    call_getAqlHd.request();
  }, []);
  useEffect(() => {
    if (call_getAqlHd.status === "suc") {
      message.success(call_getAqlHd.msg);
    }
    if (call_getAqlHd.status === "err") {
      message.error(call_getAqlHd.msg);
    }
  }, [call_getAqlHd.status]);
  useEffect(() => {
    if (call_addPlanTb.status === "suc") {
      message.success(call_addPlanTb.msg);
      set_s_isShowModal(false);
    }
    if (call_addPlanTb.status === "err") {
      message.error(call_addPlanTb.msg);
    }
  }, [call_addPlanTb.status]);
  useEffect(() => {
    if (call_updatePlanTb.status === "suc") {
      message.success(call_updatePlanTb.msg);
      set_s_isShowModal(false);
    }
    if (call_updatePlanTb.status === "err") {
      message.error(call_updatePlanTb.msg);
    }
  }, [call_updatePlanTb.status]);
  console.log(123);
  const renderplane9X = ({ s_editData, disabled = false }) => {
    const pattern9X = /^plan9[0-9]$/;

    return (
      <Select
        disabled={disabled}
        // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}
        className="w-full "
        options={AqlOptions}
      />
    );

    // return false ? (
    //   <InputNumber disabled={disabled} className="w-full" />
    // ) : (
    //   <Select
    //     disabled={disabled}
    //     // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}
    //     className="w-full "
    //     options={AqlOptions}
    //   />
    // );
  };
  console.log(s_editData);
  return (
    <Form
      form={form}
      initialValues={{
        tb: type === "create" ? [] : s_editData?.map((item, index) => ({ ...item, SN: index + 1 })),
      }}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >
      {/* 隱藏欄位 */}
      <Row gutter={[12, 12]}></Row>

      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.List name="tb" shouldUpdate>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  // const rowData = _tb[field.name];

                  return (
                    <Row key={index} gutter={[12, 0]}>
                      {/* 假欄位 */}

                      {/* <Form.Item name={[field.name, "SN"]} hidden /> */}
                      <Col span={1}>
                        <Form.Item name={[field.name, "SN"]} label={t("util.util.SN")}>
                          <Input disabled value={index + 1} />
                        </Form.Item>
                      </Col>

                      <Col span={2}>
                        <Form.Item
                          label={"計劃ID"} // 料號
                          name={[field.name, "planID"]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>

                      <Col span={3}>
                        <Form.Item
                          label={"批量始值"} // 品名
                          name={[field.name, "batchstart"]}
                        >
                          <InputNumber className="w-full" disabled={index >= 1} />
                        </Form.Item>
                      </Col>

                      <Col span={3}>
                        <Form.Item
                          label={"批量迄值"} // 規格
                          name={[field.name, "batchend"]}
                        >
                          <InputNumber
                            className="w-full"
                            onChange={(value) => {
                              let formData = form.getFieldValue();

                              if (index + 1 >= formData["tb"].length) return;
                              formData["tb"][index + 1]["batchstart"] = value + 1;
                              form.setFieldsValue({ tb: formData });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={2}>
                        <Form.Item label={"正常計劃 I 級"} name={[field.name, "G1"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: false })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"正常計劃 II級"} name={[field.name, "G2"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: false })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"正常計劃 III級"} name={[field.name, "G3"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: false })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"特殊計劃 S1級"} name={[field.name, "S1"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: true })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"特殊計劃 S2級"} name={[field.name, "S2"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: true })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"特殊計劃 S3級"} name={[field.name, "S3"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: true })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label={"特殊計劃 S4級"} name={[field.name, "S4"]}>
                          {renderplane9X({ s_editData: s_editData[index], disabled: true })}
                          {/* <Select
                            // formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            className="w-full "
                            options={AqlOptions}
                          /> */}
                        </Form.Item>
                      </Col>

                      <Col span={1} className="flex justify-end">
                        <Form.Item label=" ">
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);

                              let formData = [...f_tb];
                              const removeIndex = index;

                              if (removeIndex === 0 && f_tb.length === 0) {
                                form.setFieldsValue({ tb: [] });
                                return;
                              }
                              if (removeIndex === 0) {
                                const newFormData = formData
                                  .filter((x, index) => {
                                    return index !== removeIndex;
                                  })
                                  .map((item, index) => {
                                    return { ...item, SN: index + 1 };
                                  });
                                form.setFieldsValue({ tb: newFormData });
                                return;
                              }
                              const previousBatchend = f_tb[removeIndex - 1].batchend;

                              formData[removeIndex + 1].batchstart = previousBatchend;

                              const newFormData = formData
                                .filter((x, index) => {
                                  return index !== removeIndex;
                                })
                                .map((item, index) => {
                                  return { ...item, SN: index + 1 };
                                });
                              form.setFieldsValue({ tb: newFormData });
                            }}
                            className="text-[30px] "
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
                <Form.Item>
                  <Button
                    className={"bg-[#4AC6F5] text-white"}
                    type="dashed"
                    // disabled={}
                    onClick={() => {
                      // TB第一項預設為 開單日期
                      // TB第二項以後預設為第一項日期

                      let formData = form.getFieldValue();
                      console.log(formData);
                      const latestIndex = formData?.tb?.length || 0;
                      console.log(latestIndex);
                      console.log(formData?.tb);
                      const latestBatchstart = latestIndex
                        ? formData?.tb[latestIndex - 1]["batchend"] + 1
                        : 0;
                      console.log(latestBatchstart);
                      add({
                        planID: c_lightData.planID,
                        SN: fields.length + 1,
                        sampleID: null,
                        batchstart: latestBatchstart,
                        batchend: 0,
                        samplesize: 0,
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    {t("util.util.add")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>

      <>
        <Divider />
        <Row gutter={[12, 12]}>
          <Col span={24} className="flex justify-end">
            <Space>
              <Button size="large" onClick={handleCancel}>
                {/* 取消 */}
                {t("util.util.cancel")}
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                // loading={call_addApsOutBoHd.status === "load" || call_updateApsOutBoHd.status === "load"}
              >
                {/* 確定 */}
                {t("util.util.ok")}
              </Button>
            </Space>
          </Col>
        </Row>
      </>
    </Form>
  );
}
