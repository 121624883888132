import { Button, Input, Space, Table, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getSDS003 } from "@/service/apis/LMS/LabelManage";
import { numberWithCommas } from "@/util/format";
import { PrinterOutlined } from "@ant-design/icons";

import ChooseLabel from "./ModalContainer/ChooseLabel";

const FORMAT_DATE = "YYYY.MM.DD";

export default function LabelOrderNPrinter() {
  const call_getSDS003 = useAPI(getSDS003);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  // 查詢用資料
  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 200,
    VBELN: undefined,
    KUNAG: undefined,
    BSTKD: undefined,
    MATNR: undefined,
    VBELN_VL: undefined,
    POSNR_VL: undefined,
    // pageSize: 3,
  });
  const [s_showChooseModal, set_s_showChooseModal] = useState(false);

  // const [c_lightData, set_c_lightData] = useState({});

  const [s_selectedRows, set_s_selectedRows] = useState([]);

  const [s_tableData, set_s_tableData] = useState([]);

  const onSelectChange = (_, newSelectedRows) => {
    set_s_selectedRows(newSelectedRows);
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      set_s_searchData((prev) => ({ ...prev, page: 1 }));
      call_getSDS003.request({ ...s_searchData, page: 1 });
    }
  };

  const tableColumns = [
    { title: "訂單號", dataIndex: "VBELN", align: "center" },
    { title: "訂單項次", dataIndex: "POSNR", align: "center" },
    { title: "出貨單號", dataIndex: "VBELN_VL", align: "center" },
    { title: "出貨項次", dataIndex: "POSNR_VL", align: "center" },
    { title: "物料號碼", dataIndex: "MATNR" },
    { title: "客戶ID", dataIndex: "KUNAG", align: "center" },
    { title: "客戶名稱", dataIndex: "ZZSOLDTO_BU_SORT1", align: "center" },
    { title: "客戶地址", dataIndex: "ZZSOLDTO_ADDR", align: "center" },
    { title: "客戶物料", dataIndex: "KDMAT", align: "center" },
    { title: "客戶單號", dataIndex: "BSTKD", align: "center" },
    { title: "客戶物料品名", dataIndex: "POSTX" },
    { title: "項目描述", dataIndex: "ARKTX", width: "350px" },
    {
      title: "訂單數量",
      dataIndex: "KWMENG",
      align: "right",
      width: "100px",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "出貨數量",
      dataIndex: "LFIMG",
      align: "right",
      width: "100px",
      render: (text) => numberWithCommas(text),
    },
    {
      title: "每箱數量",
      dataIndex: "TRGQTY",
      align: "right",
      width: "100px",
      render: (text) => numberWithCommas(text),
    },

    {
      title: "更新時間",
      dataIndex: "ZZMES_DATE",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
  ];

  const rowSelection = {
    selectedRowKeys: s_selectedRows.map((x) => x.key),
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  useEffect(() => {
    call_getSDS003.request(s_searchData);
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (call_getSDS003.status === "suc") {
      set_s_pageInfo(call_getSDS003.data.pageInfo);
      const newData =
        call_getSDS003.data?.tableData?.map((x, i) => {
          let rowData = {
            ...x,
            key: i + 1,
            index: i + 1,
            WADAT: dayjs(x.WADAT).format(FORMAT_DATE),
          };

          for (const index in x.class) {
            rowData[x.class[index].ATBEZ] =
              x.class[index].ATWTB !== " " ? x.class[index].ATWTB : x.class[index].ATWRT;
          }
          return rowData;
        }) || [];

      console.log(newData);
      set_s_tableData(newData);
    } else if (call_getSDS003.status === "err") {
      message.error(call_getSDS003.msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getSDS003.status]);

  return (
    <>
      <PageHeader
        title="出貨標籤列印"
        extra={
          <Space>
            <Input
              className="width-[300px]"
              placeholder="請輸入出貨單號"
              value={s_searchData.MATNR}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, VBELN_VL: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入出貨項次"
              value={s_searchData.MATNR}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, POSNR_VL: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入客戶物料"
              value={s_searchData.MATNR}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, MATNR: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入客戶單號"
              value={s_searchData.BSTKD}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, BSTKD: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入客戶ID"
              value={s_searchData.KUNAG}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, KUNAG: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />
            <Input
              className="width-[300px]"
              placeholder="請輸入訂單號"
              value={s_searchData.VBELN}
              onChange={(e) => set_s_searchData((prev) => ({ ...prev, VBELN: e.target.value }))}
              onKeyDown={handleKeyDownEnter}
            />

            <Button
              type="primary"
              onClick={() => {
                set_s_searchData((prev) => ({ ...prev, page: 1 }));
                call_getSDS003.request({ ...s_searchData, page: 1 });
              }}
            >
              查詢
            </Button>

            <Button
              disabled={s_selectedRows.length === 0}
              icon={<PrinterOutlined />}
              onClick={() => set_s_showChooseModal(true)}
            >
              列印
            </Button>
          </Space>
        }
      />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getSDS003.status === "load"}
        rowSelection={rowSelection}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />

      <CustomModal
        width="90%"
        title="選擇列印模板"
        open={s_showChooseModal}
        onCancel={() => set_s_showChooseModal(false)}
        afterClose={() => {
          set_s_selectedRows([]);
          call_getSDS003.request(s_searchData);
        }}
      >
        {s_showChooseModal && (
          <ChooseLabel set_s_showModal={set_s_showChooseModal} s_keyParams={s_selectedRows} />
        )}
      </CustomModal>
    </>
  );
}
