import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Divider,
  message,
  Radio,
  Collapse,
  Space,
  InputNumber,
} from "antd";
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import WpcSetting from "@/components/BDM/WpcSetting";
import { addProduct, modifyProduct, getSuppHd, getCustHd } from "@/service/apis/ADM/pnManage";
import { getOpUnit, getUnit } from "@/service/apis/ADM/systemParamsManage";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_unit = Form.useWatch("unit", form);
  const f_attribute = Form.useWatch("attribute", form);
  const [s_isShowWpc, set_s_isShowWpc] = useState(false);
  const wpcWrapperRef = useRef(null);

  const call_getMgmtcat74 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);
  const call_getOpUnit = useAPI(getOpUnit);
  const call_getUnit = useAPI(getUnit);
  const call_addProduct = useAPI(addProduct);
  const call_modifyProduct = useAPI(modifyProduct);
  const call_getSuppHd = useAPI(getSuppHd);
  const call_getCustHd = useAPI(getCustHd);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()

    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|") ?? "";
    }
    if (values.mtype && values.mtype.length > 0) {
      values.mtype = values.mtype?.join("|") ?? "";
    }
    if (values.querycat && values.querycat.length > 0) {
      values.querycat = values.querycat?.join("|") ?? "";
    }
    // if (values.attribute && values.attribute.length > 0) {
    //   values.attribute = values.attribute?.join("|") ?? "";
    // }
    // if (values.pdtclass && values.pdtclass.length > 0) {
    //   values.pdtclass = values.pdtclass?.join("|") ?? "";
    // }
    // if (values.mtype && values.mtype.length > 0) {
    //   values.mtype = values.mtype?.join("|") ?? "";
    // }
    // if (values.heirdealID && values.heirdealID.length > 0) {
    //   values.heirdealID = values.heirdealID?.join("|") ?? "";
    // }
    // if (values.wpcs && values.wpcs.length > 0) {
    //   values.wpcs.forEach((wpc) =>
    //     wpc.subWpcs.forEach((subwpc) => (subwpc.pwcID = subwpc.pwcID?.join("|")))
    //   );
    // }

    if (type === "create") {
      call_addProduct.request(values);
    }

    if (type === "edit") {
      call_modifyProduct.request(values);
    }
  };

  // 商品類別(pdtclass)選擇 "X" 時塞的固定值
  const onPdtclassChange = (value) => {
    if (value !== "X") return;
    form.setFieldsValue({
      attribute: "0",
      mgmtcat: ["All"], // 授權分類設為"全部"
      querycat: ["All"], // 查詢分類設為"全部"
      unit: "PCS",
      idmtype: "S",
    });
  };

  const handleChange = (key, data) => {
    let formData = form.getFieldValue();

    // 成品或半成品才有tb
    if (key === "attribute") {
      if (["4", "5"].includes(data.value)) {
        set_s_isShowWpc(true);
      } else {
        set_s_isShowWpc(false);
      }
    }

    form.setFieldsValue(formData);
  };

  useEffect(() => {
    // 取得管理分類
    call_getMgmtcat71.request({ pageType: "71", type: "pdt" });
    call_getMgmtcat74.request({ pageType: "74" });
    // 取得操作單位資料
    call_getOpUnit.request();
    // 取得單位資料
    call_getUnit.request();
    call_getSuppHd.request();
    call_getCustHd.request();
  }, []);

  useEffect(() => {
    const { status, msg } = call_addProduct;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_addProduct.status]);

  useEffect(() => {
    const { status, msg } = call_modifyProduct;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_modifyProduct.status]);

  console.log("arrMap.pdtclassMap = ", arrMap.pdtclassMap);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        setpack: false,
        setidm: false,
        setbatch: false,
        isfocus: false,
        isvalid: true,
        pbom: 0,
        wpcs: [
          {
            subWpcs: [
              {
                wpcSN: "1",
                nextSN: "T",
                stdwh: 20,
                stdmh: 20,
                stdlt: 3600,
                // 'stdcost': 0,
                planhead: 1,
                pfID: null,
                wsID: null,
                pwcID: null,
                itemno: 0,
                batchbom: 0,
                ieUUID: null,
                // wlID: null,
                warnyield: 90,
                alertyield: 80,
                alertspeed: 90,
                alertstop: 1200,
              },
            ],
          },
        ],
        attribute: "0",
        pdtclass: "X",
        mgmtcat: ["All"],
        querycat: ["All"],
        mtype: [],
        unit: "PCS",
        idmtype: "S",
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          {/* 料號 */}
          <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 品名 */}
          <Form.Item label={t("util.util.pdtNM")} name="pdtNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 規格 */}
          <Form.Item label={t("util.util.pdtspec")} name="pdtspec">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 機種 */}
          <Form.Item label={t("util.util.mtype")} name="mtype">
            <Select
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input) || (option?.value ?? "").includes(input)
              }
              options={arrMap.mgmtcat75?.reduce((accumulator, option) => {
                option.isvalid &&
                  accumulator.push({
                    label: `${option.mgmtitem}-${option.itemNM}`,
                    value: option.mgmtitem,
                  });
                return accumulator;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 屬性 */}
          <Form.Item label={t("util.util.attribute")} name="attribute" rules={[{ required: true }]}>
            <Select
              allowClear
              onChange={(_, data) => handleChange("attribute", data)}
              options={arrMap.attributeMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 商品 */}
          <Form.Item label={t("util.util.pdtclass")} name="pdtclass" rules={[{ required: true }]}>
            <Select allowClear options={arrMap.pdtclassMap} onChange={onPdtclassChange} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 授權分類 */}
          <Form.Item label={t("util.util.mgmtcat")} name="mgmtcat" rules={[{ required: true }]}>
            <Select
              allowClear
              mode="tags"
              options={arrMap.mgmtcat71?.reduce(
                (accumulator, option) => {
                  option.isvalid &&
                    accumulator.push({
                      label: `${option.mgmtitem}-${option.itemNM}`,
                      value: option.mgmtitem,
                    });
                  return accumulator;
                },
                [{ value: "All", label: t("util.util.all") }]
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 查詢分類 */}
          <Form.Item label={t("util.util.querycat")} name="querycat" rules={[{ required: true }]}>
            <Select
              allowClear
              mode="tags"
              options={arrMap.mgmtcat74?.reduce(
                (accumulator, option) => {
                  option.isvalid &&
                    accumulator.push({
                      label: `${option.mgmtitem}-${option.itemNM}`,
                      value: option.mgmtitem,
                    });
                  return accumulator;
                },
                [{ value: "All", label: t("util.util.all") }]
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 計量單位 */}
          <Form.Item label={t("util.util.unit")} name="unit">
            <Select
              allowClear
              options={call_getUnit.data?.reduce((accumulator, option) => {
                option.isvalid &&
                  accumulator.push({
                    label: option.unitNM,
                    value: option.unit,
                  });
                return accumulator;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 計數單重(g) */}
          <Form.Item label={t("ADM.pn-manage.unitwt")} name="unitwt">
            {/* <Input disabled={f_unit !== "PCS"} /> */}
            <InputNumber
              className="w-full"
              disabled={f_unit !== "PCS"}
              addonAfter={"g"}
              step="0.01"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 操作單位 */}
          <Form.Item label={t("util.util.opunit")} name="opunit">
            <Select
              allowClear
              options={call_getOpUnit.data?.reduce((accumulator, option) => {
                option.isvalid &&
                  accumulator.push({
                    label: option.opunitNM1,
                    value: option.opunit,
                  });
                return accumulator;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 單位轉換 */}
          <Form.Item label={t("ADM.pn-manage.bom")} name="pbom" rules={[{ required: true }]}>
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 過帳型態 */}
          <Form.Item label={t("util.util.idmtype")} name="idmtype">
            <Select
              allowClear
              options={[
                { label: t("util.util.idmtype_s"), value: "S" },
                { label: t("util.util.idmtype_d"), value: "D" },
                { label: t("util.util.idmtype_m"), value: "M" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.util.drawno")} name="drawno">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.util.matl")} name="matl">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={6}>
          <Form.Item label={t("util.util.heirPN")} name="heirPN">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* 繼承對象 */}
          <Form.Item label={t("util.util.heirdealID")} name="heirdealID">
            <Select
              allowClear
              options={
                f_attribute === "5" || f_attribute === "6"
                  ? call_getCustHd.data?.reduce((accumulator, option) => {
                      accumulator.push({
                        label: option.custID + "_" + option.custalias,
                        value: option.custID,
                      });
                      return accumulator;
                    }, [])
                  : call_getSuppHd.data?.reduce((accumulator, option) => {
                      accumulator.push({
                        label: option.suppID + "_" + option.suppalias,
                        value: option.suppID,
                      });
                      return accumulator;
                    }, [])
              }
            />
          </Form.Item>
        </Col>
        <Col span={6} />
        <Col span={6} />
        <Col span={5} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.setpack")} name="setpack">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={5} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.setidm")} name="setidm">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={5} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.setbatch")} name="setbatch">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={5} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.isfocus")} name="isfocus">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        {Object.keys(s_editData).length === 0 && s_isShowWpc && (
          <Col span={24}>
            <Divider className="m-0" />
            <WpcSetting form={form} wpcWrapperRef={wpcWrapperRef} />
          </Col>
        )}
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addProduct.status === "load" || call_modifyProduct.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
