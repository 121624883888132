import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

// component
import Pause from "./Pause";
import OkItem from "./OkItem/index";
import NgItem from "./NgItem/index";
import Dri from "./Dri/index";
import OffLine from "./OffLine/index";

const StringManage = () => {
  const { t } = useTranslation();
  const Pause_ref = useRef(null); // 停工項目
  const OkItem_ref = useRef(null); // 良品項目
  const NgItem_ref = useRef(null); // 不良品項目
  const Dri_ref = useRef(null); // 責任單位
  const OffLine_ref = useRef(null); // 下線項目

  // 利用ref 子傳父
  const stringManage_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 200,
  });
  const [s_tabKey, set_s_tabKey] = useState("Pause"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      item: undefined,
    });
  };

  const onCreate = () => {
    s_tabKey === "Pause" && Pause_ref.current.onCreate();
    s_tabKey === "OkItem" && OkItem_ref.current.onCreate();
    s_tabKey === "NgItem" && NgItem_ref.current.onCreate();
    s_tabKey === "Dri" && Dri_ref.current.onCreate();
    s_tabKey === "OffLine" && OffLine_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "Pause",
      label: t("ADM.string-manage.tabPause"), // 停工項目
      children: (
        <Pause
          ref={Pause_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "OkItem",
      label: t("ADM.string-manage.tabOkItem"), // 良品項目
      children: (
        <OkItem
          ref={OkItem_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "NgItem",
      label: t("ADM.string-manage.tabNgItem"), // 不良品項目
      children: (
        <NgItem
          ref={NgItem_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "Dri",
      label: t("ADM.string-manage.tabDri"), // 責任單位
      children: (
        <Dri
          ref={Dri_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "OffLine",
      label: t("ADM.string-manage.tabOffLine"), // 下線項目
      children: (
        <OffLine
          ref={OffLine_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.util.add")}
    // </Button>,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.string-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
};

export default StringManage;
