import React, { useState, useRef } from "react";
import { Button, DatePicker,Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader/index";
import { useTranslation } from "react-i18next";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import Run80Run90 from "./Run80Run90";

const OPCODE_LABELS = [
  { key: "4210", label: "IMS.util.opcode_4210" }, // 生產領用
  { key: "4260", label: "IMS.util.opcode_4260" }, // 生產超領
  { key: "4220", label: "IMS.util.opcode_4220" }, // 銷售發貨
  { key: "4230", label: "IMS.util.opcode_4230" }, // 進料退出
  { key: "4241", label: "IMS.util.opcode_4241" }, // 調撥出庫
  { key: "4242", label: "IMS.util.opcode_4242" }, // 庫房借出
  { key: "4243", label: "IMS.util.opcode_4243" }, // 借入歸還
];

const DATE_FORMAT = "YYYY-MM-DD";

const ProOutstock = () => {
  const { t } = useTranslation();
  const outRef = useRef();

  // 要搜尋的資料
  const [c_searchData, set_c_searchData] = useState({
    opcode: "4210",
    startDate: dayjs().startOf("month").format(DATE_FORMAT),
    endDate: dayjs().endOf("month").format(DATE_FORMAT),
    lifeF: "",
  });

  const opcodeData = OPCODE_LABELS.map((x) => ({
    key: x.key,
    label: t(x.label),
    children: <Run80Run90 ref={outRef} c_searchData={c_searchData} />,
  }));

  // 更改搜尋項目
  const handleChange = (type, value) => {
    set_c_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }
      return { ...prev };
    });
  };
  // 新增
  const handleClick = () => {
    switch (c_searchData.opcode) {
      case "4210":
        outRef.current.Create();
        break;
      default:
        outRef.current.Create();
        break;
    }
  };


  const searchItem = [
    { value: "", label: t("IMS.util.lifeF_A") },
    { value: "0", label: t("IMS.util.lifeF_0") },
    { value: "1", label: t("IMS.util.lifeF_1") },
    { value: "2", label: t("IMS.util.lifeF_2") },
    { value: "T", label: t("IMS.util.lifeF_T") },
  ].map((option) => (
    <Button
      key={option.value}
      type={c_searchData.lifeF === option.value ? "primary" : "default"}
      onClick={() => set_c_searchData((prev) => ({ ...prev, lifeF: option.value }))}
    >
      {option.label}
    </Button>
  ));

  return (
    <>
      <PageHeader
        title={t("util.util.nav_wms_pro-outstock")}
        extra={[
          ...searchItem,
          <DatePicker.RangePicker
            key="time"
            allowClear={false}
            value={[dayjs(c_searchData.startDate), dayjs(c_searchData.endDate)]}
            format={DATE_FORMAT}
            onChange={(dates) => handleChange("time", dates)}
          />,
          !OPCODE_LABELS[c_searchData.opcode] && (
            // <Button
            //   key="add"
            //   onClick={handleClick}
            //   type="primary"
            //   className="styleButton"
            //   icon={<PlusOutlined />}
            // >
            //   {t(`util.util.add`)}
            // </Button>
            <AddButton type="primary" key="add" onClick={handleClick} />
          ),
        ]}
      />
      <CustomTabs
        onChange={(activeKey) => handleChange("opcode", activeKey)}
        activeKey={c_searchData.opcode}
        items={opcodeData}
      />
    </>
  );
};

export default ProOutstock;
