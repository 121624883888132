import React, { useState, useEffect } from "react";
// page
import Dashboard from "../DashBoard/index";
import AbcBoard from "../AbcBoard/index";

const TestMarquee = () => {
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [displayText, setDisplayText] = useState("dashboard");

  useEffect(() => {
    let timeoutId;

    const handleMouseMove = () => {
      setIsMouseMoving(true);
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setIsMouseMoving(false);
      }, 3000);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isMouseMoving) {
        setDisplayText((prevText) => (prevText === "Dashboard" ? "AbcBoard" : "Dashboard"));
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    // <div>
    //   <p>Mouse moving: {isMouseMoving ? "true" : "false"}</p>
    //   <p>{displayText}</p>
    // </div>
    <>{displayText === "Dashboard" ? <Dashboard /> : <AbcBoard />}</>
  );
};

export default TestMarquee;
