import Highcharts from "highcharts";
import { render } from "react-dom";

//import HighchartsReact from "./HighchartsReact.min.js";
import HighchartsReact from "highcharts-react-official";

import { chartBasic } from "../ColumnChart";

export const produceColumnChartForMom = (
  chartWidth = 100,
  chartHeigh = 100,
  xAxisLable = [],
  data = [],
  title = "",
  backgroundColor="#FFFFFF"
) =>{
   return({
  ...chartBasic,
  chart: {
    ...chartBasic.chart,
    width: chartWidth,
    height: chartHeigh,
    zoomType: null,
    backgroundColor:backgroundColor
  },
  rangeSelector: {
    enabled: false, // 禁用日期选择器
  },
  title: {
    ...chartBasic.title,
    text: title,
    margin: 50,
  },
  series:data,
  plotOptions: {
    series: {
      marker: {
        enabled: true,
        symbol: "circle",
        radius: 4,
      },
    },
  },
  legend: {
    //圖表圖例設定
    enabled:true,
    align: "right",
    verticalAlign: "top",
    layout: "vertical",
    // symbolRadius: 0, //圖例圓角>>0會變成正方形
    symbolHeight: 14,
    symbolWidth: 14,
    itemStyle: {
      fontSize: '20px'
  }
    
  },
  xAxis: {
    categories: xAxisLable,
    type: "datetime",
    labels: {
      overflow: "justify",
      // style: {
      //   color: "#474a46",
      //   fontSize:16,
      // },
      format: "{value:%m/%d}",
    },
  },
  yAxis: [
    {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return (Highcharts.numberFormat( (this.value/1000).toFixed(2), 0, ",", ","))+"k";
        },
      },
      tickAmount: 5,
    },
    {
        labels: {
          format: '{value}%',
        },
        tickPositions: [0, 25, 50, 75, 100],
        title: {
          text: ' ',
        },
        opposite: true,
      },
  ],
  tooltip: {
    enabled: true,
    shared: true,
   
    formatter: function () {
      let formattedDate = Highcharts.dateFormat("%m/%d", this.x);
      let k =1000
      return (
        "<b>" +
        formattedDate +
        "</b><br/>" +
        this.points
          .map(function (point) {
            if(point.series.name ==="良率"){
              return point.series.name + ": " + point.y + '%'
            }else{
              return point.series.name + ": " +(Highcharts.numberFormat( (point.y/k).toFixed(2), 0, ",", ","))+"k"
            }
            
          })
          .join("<br/>")
      );
    },
  },
})};

// export const produceLineChartForPercent = (
//   chartWidth = 100,
//   chartHeigh = 100,
//   xAxisLable = [],
//   data = [],
//   title = ""
// ) => ({
//   ...chartBasic,
//   chart: {
//     ...chartBasic.chart,
//     width: chartWidth,
//     height: chartHeigh,
//     type: "line",
//     zoomType: null,
//   },
//   rangeSelector: {
//     enabled: false, // 禁用日期选择器
//   },
//   title: {
//     ...chartBasic.title,
//     text: title,
//     margin: 50,
//     // text:  '<button class="chart-button">点击我</button>',
//     // useHTML: true,
//     // align: 'center',
//     // style: {
//     //   cursor: 'pointer',
//     // },
//   },
//   series: data,
//   xAxis: {
//     // categories: xAxisLable,
//     type: "datetime",
//     labels: {
//       overflow: "justify",
//       // style: {
//       //   color: "#474a46",
//       //   fontSize:16,
//       // },
//       format: "{value:%m/%d}",
//     },
//   },
//   plotOptions: {
//     series: {
//       marker: {
//         enabled: true,
//         radius: 4,
//       },
//     },
//   },
//   yAxis: [
//     {
//       labels: {
//         format: "{text}%",
//       },
//       tickPositions: [0, 25, 50, 75, 100],
//       title: {
//         text: "",
//       },
//     },
//   ],
//   legend: {
//     //圖表圖例設定
//     enabled:true,
//     align: "right",
//     verticalAlign: "top",
//     layout: "horizontal",
//     // symbolRadius: 0, //圖例圓角>>以下設定會變成正方形
//     symbolHeight: 12,
//     symbolWidth: 12,
//   },
//   tooltip: {
//     enabled: true,
//     shared: true,
//     formatter: function () {
//       var formattedDate = Highcharts.dateFormat("%m/%d", this.x);
//       return (
//         "<b>" +
//         formattedDate +
//         "</b><br/>" +
//         this.points
//           .map(function (point) {
//             var percentage = point.y + "%";
//             return point.series.name + ": " + percentage;
//           })
//           .join("<br/>")
//       );
//     },
//   },
// });
