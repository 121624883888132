import { Button, Divider, Space, Transfer, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useAPI from "@/hooks/useAPI";
import { importInspmethod } from "@/service/apis/QMS/QMSManage";
import { getEqgpCompare } from "@/service/apis/QMS/eqgp";

const CustomTransfer = styled(Transfer)`
  justify-content: space-around !important;
  border-color: #d7d7d7 !important;

  .ant-transfer-list {
    width: 450px !important;
  }
  .ant-transfer-list-header {
    font-size: 23px;
    background-color: #def7ff !important;
  }

  /* Styles for unchecked items */
  .ant-transfer-list-content-item {
    /* background-color: green !important; */
    background-color: #ffffff;
    height: 55px;
  }
  .ant-transfer-list-content-item:nth-child(even) {
    /* background-color: green !important; */
    background-color: #d7d7d7;
    height: 55px;
  }

  /* Styles for checked items */
  .ant-transfer-list-content-item-checked {
    background-color: #d2eaff !important;
  }
  .ant-transfer-list-content-item-checked .ant-btn {
    width: 80px !important;
    height: 80px;
    background-color: #559ee2 !important;
    margin-top: 42px;
  }
  .ant-btn[disabled] {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #badbfa !important;
  }
  .ant-btn {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #4096ff !important;
  }
  .ant-btn .ant-btn-icon {
    /* display: block !important; */
    /* width:100px !important; */
    /* height:100px !important; */
  }
  .ant-btn .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
  }
  .ant-transfer-operation {
  }
  .ant-btn[disabled] .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
    color: #ffffff;
  }

  .ant-checkbox-inner {
    /* background-color: red !important; */
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #559ee2 !important;
  }
`;

const BatchImport = ({ set_s_batchImportModal, c_lightData }) => {
  const { t } = useTranslation();

  const call_getEqgpCompare = useAPI(getEqgpCompare);
  const call_importInspmethod = useAPI(importInspmethod);

  const [sourceData, setSourceData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  // try 註解 0708
  const [s_inspgroup, set_s_inspgroup] = useState("");

  useEffect(() => {
    call_getEqgpCompare.request();
  }, []);

  useEffect(() => {
    const { data, msg, status } = call_getEqgpCompare;
    if (status === "suc") {
      const mockOptions =
        data?.eqgp?.map((item, index) => ({
          key: index,
          data: { ...item, updtT: new Date(item.updtT) },
          title: `${item?.gpID}_${item?.gpNM}`,
        })) || [];
      const targetOptions =
        data?.method?.map((item, index) => ({
          key: mockOptions.length + index,
          data: { ...item, updtT: new Date(item.updtT) },
          title: `${item?.gpID}_${item?.gpNM}`,
        })) || [];

      setSourceData([...mockOptions, ...targetOptions]);
      setTargetKeys(targetOptions.map((item) => item.key));
      console.log("targetOptions =", targetOptions);
    }
    if (status === "err") {
      message.error(call_getEqgpCompare.msg);
    }
  }, [call_getEqgpCompare.status]);

  console.log("call_getEqgpCompare =", call_getEqgpCompare);

  // 全部資料
  console.log("setSourceData =", sourceData);

  useEffect(() => {
    const { data, msg, status } = call_importInspmethod;
    if (status === "suc") {
      message.success(msg);
      set_s_batchImportModal(false);
    }
    if (status === "err") {
      message.error(call_importInspmethod.msg);
    }
  }, [call_importInspmethod.status]);

  const handleChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
  };
  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };
  const onSubmit = () => {
    const payload = targetKeys.reduce((total, value) => {
      console.log(sourceData[value]);
      total.push({
        ...sourceData[value].data,
        // drawing: c_lightData.drawing,
        inspgroup: s_inspgroup,
      });
      return total;
    }, []);

    call_importInspmethod.request({
      method: payload,
    });
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-[95%] ">
        <CustomTransfer
          dataSource={sourceData}
          showSearch
          selectAllLabels={(selectedCount, totalCount) => {
            console.log(selectedCount, totalCount);
            return <div>123</div>;
          }}
          titles={["儀器工具群組", "檢驗方法"]}
          // filterOption={filterOption}
          targetKeys={targetKeys}
          onChange={handleChange}
          onSearch={handleSearch}
          listStyle={{
            width: "100%",
            height: 500,
          }}
          render={(item) => item.title}
        />
      </div>
      <Divider />
      <Space className=" self-end">
        <Button onClick={() => set_s_batchImportModal(false)}>{t("util.util.cancel")}</Button>
        <Button type="primary" onClick={onSubmit}>
          {t("util.util.ok")}
        </Button>
      </Space>
    </div>
  );
};

export default BatchImport;
