import React, { useState, useRef } from "react";
import { Button, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader/index";
import { useTranslation } from "react-i18next";
// components
import Wo from "./Wo";
import Mo from "./Mo";

const DATE_FORMAT = "YYYY-MM-DD";

const ApsManage = () => {
  const { t } = useTranslation();
  const woRef = useRef();
  const moRef = useRef();
  // 要搜尋的資料
  const [c_searchData, set_c_searchData] = useState({
    tabkey: "wo",
    startDate: dayjs().startOf("month").format(DATE_FORMAT),
    endDate: dayjs().endOf("month").format(DATE_FORMAT),
    lifeF: "",
  });

  const tabsData = [
    {
      key: "wo",
      label: t("APS.aps-manage.tabWo"), // 工單主檔
      children: <Wo ref={woRef} c_searchData={c_searchData} />,
    },
    {
      key: "mo",
      label: t("APS.aps-manage.tabMo"), // 料單主檔
      children: <Mo ref={moRef} c_searchData={c_searchData} />,
    },
  ];

  // 改變搜尋條件
  const handleChange = (type, value) => {
    set_c_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }
      return { ...prev };
    });
  };

  // 新增
  const handleClick = () => {
    console.log("c_searchData.tabkey = ", c_searchData.tabkey);
    switch (c_searchData.tabkey) {
      case "wo":
        woRef.current.Create();
        break;
      case "mo":
        moRef.current.Create();
        break;
      default:
        woRef.current.Create();
        break;
    }
  };
  return (
    <>
      <PageHeader
        title={t("APS.aps-manage.pageHeader")} // 生管派單作業
        extra={[
          <DatePicker.RangePicker
            key="time"
            allowClear={false}
            value={[dayjs(c_searchData.startDate), dayjs(c_searchData.endDate)]}
            format={DATE_FORMAT}
            onChange={(dates) => handleChange("time", dates)}
          />,

          <Button
            key="add"
            onClick={handleClick}
            type="primary"
            className="styleButton"
            icon={<PlusOutlined />}
          >
            {t(`util.util.add`)}
            {/* 新增的按鈕 */}
          </Button>,
        ]}
      />
      <CustomTabs
        onChange={(activeKey) => handleChange("tabkey", activeKey)}
        activeKey={c_searchData.opcode}
        items={tabsData}
      />
    </>
  );
};

export default ApsManage;
