import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  message,
  Space,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { addRun20Wh, updateRun20Wh, getWh } from "@/service/apis/WHM/CT";
import { getStaff } from "@/service/apis/ADM/userManage";
import useAPI from "@/hooks/useAPI";
import dayjs from "dayjs";

export default function ModalConintaer({ s_searchData, s_editData, set_is_showModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addRun20Wh = useAPI(addRun20Wh);
  const call_updateRun20Wh = useAPI(updateRun20Wh);
  const call_getStaff = useAPI(getStaff);
  const call_getWh = useAPI(getWh);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    let postData = {
      ...s_editData,
      ...values,
    };

    delete postData.createT;

    // console.log(postData);
    // return;
    if (type === "create") {
      call_addRun20Wh.request(postData);
    }

    if (type === "edit") {
      call_updateRun20Wh.request(postData);
    }
  };

  useEffect(() => {
    call_getStaff.request();
    call_getWh.request();
  }, []);

  useEffect(() => {
    if (call_addRun20Wh.status === "suc") {
      message.success(call_addRun20Wh.msg);
      set_is_showModal(false);
    } else if (call_addRun20Wh.status === "err") {
      message.error(call_addRun20Wh.msg);
    }
  }, [call_addRun20Wh.status]);

  useEffect(() => {
    if (call_updateRun20Wh.status === "suc") {
      message.success(call_updateRun20Wh.msg);
      set_is_showModal(false);
    } else if (call_updateRun20Wh.status === "err") {
      message.error(t(`error.error-code.${call_updateRun20Wh.msg}`));
    }
  }, [call_updateRun20Wh.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        wh: 0,
        stdmh: 0,
        workC: 0,
        mhC: 0,
        ...s_editData,
        stdmhH: s_editData.stdmh ? parseFloat(s_editData.stdmh / 3600).toFixed(2) : 0,
        createT: dayjs(s_editData.createT).format("YYYY-MM-DD"),
        workday: dayjs(s_editData.workday || s_searchData.workday),
      }}
    >
      <Form.Item name="SN" noStyle />
      <Form.Item name="pwcID" noStyle />
      <Form.Item name="peopleNM" noStyle />
      <Form.Item name="wh" noStyle />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="workday" label="工作日期" rules={[{ required: true }]}>
            <DatePicker className="w-full" format={"YYYY-MM-DD"} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="staffID" label="工作人員" rules={[{ required: true }]}>
            <Select
              showSearch
              optionFilterProp="label"
              options={
                call_getStaff?.data?.map((x) => ({
                  label: x.staffID + "_" + x.peopleNM,
                  value: x.staffID,
                  data: x,
                })) || []
              }
              onChange={(_, data) => {
                form.setFieldsValue({ peopleNM: data.data.peopleNM });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="itemNM" label="工作項目" rules={[{ required: true }]}>
            <Select
              showSearch
              optionFilterProp="label"
              options={
                call_getWh?.data?.map((x) => ({
                  label: x.SN + "_" + x.itemNM,
                  value: x.itemNM,
                  data: x,
                })) || []
              }
              onChange={(_, data) => {
                form.setFieldsValue({
                  pwcID: data.data.pwcID,
                  wh: data.data.wh,
                  stdmh: data.data.wh * form.getFieldValue("workC"),
                  stdmhH: parseFloat((data.data.wh * form.getFieldValue("workC")) / 3600).toFixed(
                    2
                  ),
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="workC" label="工作數量" rules={[{ required: true }]}>
            <InputNumber
              min={0}
              className="w-full"
              onChange={(e) => {
                form.setFieldsValue({
                  stdmh: e * form.getFieldValue("wh"),
                  stdmhH: parseFloat((e * form.getFieldValue("wh")) / 3600).toFixed(2),
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="mhC" label="工作時間(小時)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="stdmh" label="標準工時(S)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stdmhH" label="標準工時(H)" rules={[{ required: true }]}>
            <InputNumber min={0} className="w-full" disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="createT" label="建立時間">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addRun20Wh.status === "load" || call_updateRun20Wh.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
