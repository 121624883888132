import { Button, Form, Input, Spin, Typography, Upload, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
// API
import useAPI from "@/hooks/useAPI";
import {
  getQmsApSettingTb,
  getQmsApuiTb,
  updateQmsApuiTb,
  uploadQmsApuiImg,
} from "@/service/apis/QMS/Apui";
import { UploadOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    min-height: 40px;
    border-radius: 2px;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      const values = await form.validateFields();
      // console.log("values = ");
      // console.log("row = ", record.key);
      const tableColumns = Object.keys(record);
      const key = Object.keys(values)[0];
      const value = Object.values(values)[0];
      const column = tableColumns.findIndex((item) => item === key);
      // console.log("dataIndex = ", dataIndex);
      // const newRecord = { ...record, ...values };
      // console.log("newRecord = ", newRecord);
      toggleEdit();
      // 整條row最新值、目標row、目標column、 更新的value
      handleSave(record.key, column, value);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     // message: `${title} is required.`,
        //   },
        // ]}
      >
        <Input
          allowClear
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ width: "100%", minHeight: "40px" }}
          min={0}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TB = (props) => {
  const { t } = useTranslation();
  const { Link } = Typography;
  // useAPI 取得 Tabs 資料
  const call_getQmsApSettingTb = useAPI(getQmsApSettingTb);
  // useAPI 取得表身
  const call_getQmsApuiTb = useAPI(getQmsApuiTb);
  // useAPI 更新表身
  const call_updateQmsApuiTb = useAPI(updateQmsApuiTb);
  // useAPI 上傳圖片
  const call_uploadQmsApuiImg = useAPI(uploadQmsApuiImg);
  const { c_lightData } = props;
  const [s_spinning, set_s_spinning] = useState(false);
  // Tabs 的資料
  const [s_tabs, set_s_tabs] = useState([]);
  // 目前選中的 tab
  const [s_TabKey, set_s_TabKey] = useState("");
  const [s_tableColumns, set_s_tableColumns] = useState([]);
  const [s_tableData, set_s_tableData] = useState([]);

  // 改變 tab 時執行
  const handleChangeTab = (activeKey) => {
    set_s_TabKey(activeKey);
    const tab = s_tabs.find((item) => item.key === activeKey);
    // 後端需要完整的tab資料
    call_getQmsApuiTb.request({ PN: c_lightData.PN, ...tab });
  };

  // 第一次渲染及 c_lightData 改變時取得表身資料
  useEffect(() => {
    call_getQmsApSettingTb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得 Tabs
  useEffect(() => {
    const { status, data, msg } = call_getQmsApSettingTb;
    if (status === "suc") {
      message.success(msg);
      console.log("data = ", data);
      const tabs =
        data?.map((item, index) => ({
          ...item,
          key: item.sophdnote,
          label: item.sophdnote,
          disabled: index >= 4,
        })) || [];
      // console.log("tabs = ", tabs);
      set_s_tabs(tabs);
      set_s_TabKey(tabs[0]?.key);
      call_getQmsApuiTb.request({ PN: c_lightData.PN, ...tabs[0] });
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
      set_s_tabs([]);
      set_s_tableColumns([]);
      set_s_TabKey("");
    }
  }, [call_getQmsApSettingTb.status]);

  // useAPI 取得表身
  useEffect(() => {
    const { status, data, msg } = call_getQmsApuiTb;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      console.table("call_getQmsApuiTb.data = ", data);
      if (data.length === 0) return;

      // 取得 tableColumns
      const title = Object.keys(data[0]); // column格式範例: ["氣味", "莫耳數", "濃度"]
      const tableColumns = title.map((name, index) => ({
        title: name,
        dataIndex: name,
        key: name,
        editable: name !== "圖示貼圖",
        align: "center",
        render: (text, rowData, row) => {
          if (name === "圖示貼圖") {
            const onChange = async (info) => {
              const groupno = row + 1;
              const column = index + 1;
              const tabdata = s_tabs.find((item) => item.sophdnote === s_TabKey);
              // 新建
              let formData = new FormData();
              formData.append("file", info.file);

              formData.append("PN", c_lightData.PN);
              formData.append("uiID", c_lightData.uiID);
              formData.append("ver", c_lightData.ver);
              formData.append("itemno", tabdata?.itemno);
              formData.append("groupno", groupno);
              formData.append("column", column);

              call_uploadQmsApuiImg.request(formData);
            };
            return (
              <Upload
                name={"file"}
                maxCount={1}
                beforeUpload={() => false}
                listType="picture"
                fileList={
                  text !== ""
                    ? [
                        {
                          uid: "1",
                          name: text.slice(text.lastIndexOf("/") + 1),
                          thumbUrl: text,
                          url: text,
                        },
                      ]
                    : []
                }
                onChange={onChange}
              >
                <Button icon={<UploadOutlined />} block>
                  圖片上傳
                </Button>
              </Upload>
            );
          } else {
            return text;
          }
        },
      }));

      // 取得 tableData
      const _tableData = data?.map((item, index) => ({
        ...item,
        key: index,
      }));

      set_s_tableData(_tableData);
      set_s_tableColumns(tableColumns);
      set_s_spinning(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_spinning(false);
    }
  }, [call_getQmsApuiTb.status]);

  // 更新表尾
  useEffect(() => {
    const { status, data, msg } = call_updateQmsApuiTb;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      console.table("call_updateQmsApuiTb.data = ", data);
      call_getQmsApuiTb.request({ PN: c_lightData.PN, ...data });
    }
    if (status === "err") {
      message.error(msg);
      set_s_spinning(false);
    }
  }, [call_updateQmsApuiTb.status]);

  // 上傳圖片
  useEffect(() => {
    const { status, data, msg } = call_uploadQmsApuiImg;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      const tab = s_tabs.find((item) => item.key === s_TabKey);
      call_getQmsApuiTb.request({ PN: c_lightData.PN, ...tab });
    }
    if (status === "err") {
      message.error(msg);
      set_s_spinning(false);
    }
  }, [call_uploadQmsApuiImg.status]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row, column, value) => {
    console.log("row = ", row);
    console.log("column = ", column);
    console.log("value = ", value);
    console.log("s_TabKey = ", s_TabKey);
    console.log("s_tabs = ", s_tabs);
    // 激光印字 印字尺寸：3.6x1.4mm
    if (s_TabKey === "圖示資料(7)") {
      //  counter;
      const inputText = value;
      let charCount = 0;
      for (let i = 0; i < inputText.length; i++) {
        if (inputText[i].match(/[\u4e00-\u9fa5]/g)) {
          charCount++;
        }
        charCount++;
      }

      // console.log("counter=", charCount);
      if (charCount > 32) {
        message.error("超出最大字數");
        return;
      }
    }

    const tabdata = s_tabs.find((item) => item.sophdnote === s_TabKey);
    // update 格式為後端需要的資料
    const updateData = {
      PN: c_lightData.PN, // 表頭
      uiID: c_lightData.uiID, // 表頭
      ver: c_lightData.ver, // 表頭
      itemno: tabdata?.itemno, // 第幾個tab
      groupno: row + 1, // row從0開始，所以要加1
      column: column + 1, // column從0開始，所以要加1
      input: value,
    };
    // return;
    call_updateQmsApuiTb.request(updateData);
  };
  console.log(s_tabs);
  const columns =
    s_tableColumns?.map((col) => {
      if (!col.editable) return col;

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
        }),
      };
    }) || [];

  return (
    <Spin spinning={s_spinning}>
      <Wrapper>
        {s_tabs?.length > 0 && (
          <CustomTabs
            items={s_tabs}
            activeKey={s_TabKey}
            onChange={(activeKey) => handleChangeTab(activeKey)}
            disabled={true}
          />
        )}
        {columns.length > 0 && (
          <CustomTable
            components={components}
            columns={columns}
            dataSource={s_tableData}
            rowClassName="editable-row"
          />
        )}
      </Wrapper>
    </Spin>
  );
};

export default TB;
