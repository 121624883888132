import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, message, Row, Col, Select } from "antd";
import Cookies from "js-cookie";
// import logo from "@/assets/img/logo_company.png"; // relative path to image
import logo from "@/assets/img/ABCnavlogo.png"; // relative path to image
import loginSvg from "@/assets/img/ABCloginbackground.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "@/service/apis/ADM/publicAPI";
import { useLocation } from "react-router-dom"
const CustomButton = styled(Button)`
  background-color: ${(props) => (props.f_account && props.f_pwd ? "#5148C7" : "#2B57A2")};
  border: ${(props) => (props.f_account && props.f_pwd ? "#5148C7" : "#2B57A2")} 1px solid !important;
  margin-top: 2px;
  box-shadow: none;
  color: #fff;
  &:hover {
    background-color: #577ab5;
    border: #577ab5 1px solid !important;
  }
  border-radius: 9999px !important;
  text-align: center;
  height: 75px;
  font-size: 24px;
`;
function Login() {
  const [form] = Form.useForm();
  const f_account = Form.useWatch("account", form);
  const f_pwd = Form.useWatch("pwd", form);

  const Location = useLocation().pathname;
  const [buttonLodaing, setButtonLoading] = useState(false);
  console.log(Location)
  const onFinish = async (values) => {
    setButtonLoading(true);

    const res = await login(values);
    setButtonLoading(false);

    if (res.status) {
      window.location.assign("/bdm/pn-manage");
    } else {
      console.log("values = ", values);
      console.log("res = ", res);
      message.error(res.msg);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //form.resetFields();
  };
  useEffect(()=>{
    if(Location==="/login"){
      Cookies.set("plantID","abc", { expires: 365 * 99 });
      Cookies.set("plantNM", "正式區", { expires: 365 * 99 });
    }
  },[])
  return (
    <div
      className="w-full h-screen bg-cover  bg-center relative"
      style={{
        backgroundImage: `url(${loginSvg})`,
        backgroundPositionY: "110px",
        backgroundPositionX: "left",
      }}
    >
      {/* Other content */}
      <div className="top-[55%] left-12 transform -translate-y-1/2 w-[650px] h-[70%]  rounded-lg p-[100px] flex flex-col justify-center items-center relative">
        {/* <img src={logo} className="h-[176px] absolute top-[40px]" alt="系統資料管理" /> */}
        <div className="w-full text-[#2E2E2E] font-bold text-[30.5px] mb-[12px] mt-[180px] font-family[Microsoft JhengHei] text-center">
          Production DashBoard System
        </div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
          size="large"
          initialValues={{ plantID: "abc" }}
        >
          <Row gutter={[12, 12]}>
            <Col span={24} className="    mb-2 ">
              <p className="text-[#7E7E7E]">帳號</p>
              <Form.Item
                name="account"
                rules={[{ required: true, message: "請輸入正確的用戶名或郵件" }]}
              >
                <Input
                  placeholder="請輸入帳號"
                  suffix={<UserOutlined className="text-xl mr-5 text-[#A1A1AA]" />}
                  bordered={false}
                  className="border-b-2 border-solid border-t-0 border-x-0 rounded-none  border-gray-300"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-1  rounded-none ">
              <p className="text-[#7E7E7E]">密碼</p>
              <Form.Item name="pwd" rules={[{ required: true, message: "請輸入正確的密碼" }]}>
                <Input
                  type="password"
                  placeholder="請輸入密碼"
                  suffix={<LockOutlined className="text-xl mr-5 text-[#A1A1AA]" />}
                  bordered={false}
                  className="border-b-2 border-solid border-t-0 border-x-0 rounded-none  border-gray-300"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-2 ">
              <Row>
                <Col span={24}>
                  {/* <HomeOutlined className="ml-3 mt-2  text-xl text-[#A1A1AA]" /> */}
                  廠別
                </Col>
                <Col span={24}>
                  <Form.Item name="plant" rules={[{ required: true, message: "請選擇廠別" }]}>
                    <Select
                      placeholder="請選擇廠別"
                      options={[
                        { label: "全廠", value: "0000" },
                        { label: "科技廠_車圈", value: "1001" },
                        { label: "科技廠_輪組", value: "1002" },
                        { label: "科技廠_碳圈", value: "1003" },
                        { label: "科技廠_鋁擠", value: "1004" },
                        { label: "熔煉廠", value: "1005" },
                        { label: "擠型場_擠一二三", value: "1006" },
                        { label: "航太廠_航太一", value: "1011" },
                        { label: "航太廠_航太二", value: "1012" },
                        { label: "大造熊", value: "1021" },
                        { label: "鼎特力", value: "1031" },
                      ]}
                      onChange={(_, rowData) => {
                        Cookies.set("plant", rowData.value, { expires: 365 * 99 });
                      }}
                      bordered={false}
                      className=" border-b-2 border-solid border-t-0 border-x-0 rounded-none border-gray-300"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24} className="mb-2 ">
              <Row>
                <Col span={24}>
                  {/* <HomeOutlined className="ml-3 mt-2  text-xl text-[#A1A1AA]" /> */}
                  版本
                </Col>
                <Col span={24}>
                  <Form.Item name="plantID" rules={[{ required: true, message: "請選擇環境" }]}>
                    <Select
                      placeholder="請選擇版本"
                      options={[
                        { label: "測試版", value: "dev" },
                        { label: "正式版", value: "abc" },
                        // { label: "正式版", value: "prd" },
                      ]}
                      onChange={(_, rowData) => {
                        // console.log(rowData)
                        Cookies.set("plantID", rowData.value, { expires: 365 * 99 });
                        Cookies.set("plantNM", rowData.label, { expires: 365 * 99 });
                      }}
                      bordered={false}
                      className=" border-b-2 border-solid border-t-0 border-x-0 rounded-none border-gray-300"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <CustomButton
                type="primary"
                htmlType="submit"
                size="large"
                block
                loading={buttonLodaing}
                title=""
                f_account={f_account}
                f_pwd={f_pwd}
              >
                Log in
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
export default Login;
