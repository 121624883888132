const domain = {
  // conn: "http",
  // url: "localhost",

  // WAYNE外
  // conn: "http",
  // url: "192.168.0.114",

  // 亞獵士
  // conn: "http",
  // url: "192.168.0.122",

  // 亞獵士 外網
  conn: "https",
  url: "alex.mom.satdg.com",
};

const apiBasic = {
  conn: domain.conn,
  url:
    domain.url === "cpt.satdg.com"
      ? `${domain.url}${domain.conn === "http" ? ":5487" : ":22443/pds"}`
      : domain.url === "192.168.0.122"
      ? `${domain.url}${domain.conn === "http" ? ":7777/pds" : "/pds"}`
      : `${domain.url}${domain.conn === "http" ? ":5487" : "/pds"}`,
};

export const ims_apiBasic = {
  conn: domain.conn,
  url:
    domain.url === "cpt.satdg.com"
      ? `${domain.url}${domain.conn === "http" ? ":5487" : ":22443/ims"}`
      : `${domain.url}${domain.conn === "http" ? ":5487" : "/ims"}`,
};

export const pms_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":5485" : "/pms"}`,
};

export const oms_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":5486" : "/oms"}`,
};

export const bpm_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":8989" : "/bpm"}`,
};

export default apiBasic;
