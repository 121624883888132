import { useState, useEffect } from "react";
import { message, DatePicker, Space, Button } from "antd";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import exportExcel from "@/util/exportExcel";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { getDailyStaff } from "@/service/apis/PDT/dailyStaff";

const DATE_FORMAT = "YYYY-MM-DD";

const AirTransfer = () => {
  const { t } = useTranslation();

  const [s_searchData, set_s_searchData] = useState({
    startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getDailyStaff = useAPI(getDailyStaff);

  const onSearch = () => call_getDailyStaff.request(s_searchData);

  // 更改搜尋項目
  const handleChange = (type, value) => {
    set_s_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }
      return { ...prev };
    });
  };

  const tableColumns = [
    {
      title: "工作中心編號[工作中心]",
      dataIndex: "pwcIDNM",
      align: "center",
      width: 200,
      isExcel: true,
    },
    {
      title: "工單單號",
      dataIndex: "woN",
      align: "center",
      isExcel: true,
    },
    {
      title: "產品品名",
      dataIndex: "pdtNM",
      align: "left",
      width: 200,

      isExcel: true,
    },
    {
      title: "產品規格",
      dataIndex: "pdtspec",
      align: "left",
      width: 200,

      isExcel: true,
    },
    {
      title: "機器名稱",
      dataIndex: "wlNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "移轉日期",
      dataIndex: "lifeFT",
      align: "center",
      isExcel: true,
    },
    {
      title: "開工時間",
      dataIndex: "openT",
      align: "center",
      isExcel: true,
    },
    {
      title: "完工時間",
      dataIndex: "closeT",
      align: "center",
      isExcel: true,
    },
    {
      title: "投入量",
      dataIndex: "count",
      align: "right",
      isExcel: true,
    },
    {
      title: "移出工序",
      dataIndex: "SAPwpc",
      align: "center",
      isExcel: true,
    },
    {
      title: "移出製程",
      dataIndex: "pwcNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "不良數量",
      dataIndex: "ngC",
      align: "right",
      isExcel: true,
    },
    {
      title: "不良原因",
      dataIndex: "itemNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "作業員姓名",
      dataIndex: "peopleNM",
      align: "center",
      isExcel: true,
    },
    {
      title: "作業員編號",
      dataIndex: "staffID",
      align: "center",
      isExcel: true,
    },
    {
      title: "車次",
      dataIndex: "batchID",
      isExcel: true,
    },
  ];

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getDailyStaff.status === "suc") {
      message.success(call_getDailyStaff.msg);
      set_s_tableData(
        call_getDailyStaff.data.map((x, i) => ({
          ...x,
          lifeFT: dayjs(x.lifeFT).format("YYYY.MM.DD"),
          openT: dayjs(x.openT).format("YYYY.MM.DD HH:mm"),
          closeT: dayjs(x.closeT).format("YYYY.MM.DD HH:mm"),
          count: x.okC + x.ngC,
          pwcIDNM: x.pwcID + `( ${x.pwcNM} )`,
          key: i,
        }))
      );
    } else if (call_getDailyStaff.status === "err") {
      message.error(t(`error.error-code.${call_getDailyStaff.msg}`));
      set_s_tableData([]);
    }
  }, [call_getDailyStaff.status]);

  return (
    <Space direction="vertical">
      <div>
        轉移日期：
        <DatePicker.RangePicker
          key="time"
          allowClear={false}
          value={[dayjs(s_searchData.startDate), dayjs(s_searchData.endDate)]}
          format={DATE_FORMAT}
          onChange={(dates) => handleChange("time", dates)}
          onOpenChange={(value) => !value && onSearch()}
        />
        <Button
          type="primary"
          className="ml-2"
          danger
          onClick={() =>
            exportExcel(
              tableColumns,
              s_tableData,
              `航太移轉報表${s_searchData.startDate}-${s_searchData.endDate}.xlsx`
            )
          }
        >
          匯出Excel
        </Button>
      </div>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getDailyStaff.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
    </Space>
  );
};

export default AirTransfer;
