import { Button } from "antd";
import React, { useRef, useState } from "react";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import IMS from "@/pages/TestPage/meds/IMS/index";
import Symbol from "@/pages/TestPage/meds/Symbol/index";

// import SymbolTrain from "@/pages/TestPage/meds/Symbol/HD/indexTraning";
const Meds = () => {
  const [s_tabKey, set_s_tabKey] = useState("meds");
  const meds_ref = useRef(null);
  const symbol_ref = useRef(null);
  const [s_isHorizontalLayout, set_s_isHorizontalLayout] = useState(true);

  const tabsData = [
    {
      key: "meds",
      label: "檢驗規格維護",
      children: (
        <IMS
          ref={meds_ref}
          s_isHorizontalLayout={s_isHorizontalLayout}
          set_s_isHorizontalLayout={set_s_isHorizontalLayout}
        />
      ),
    },
    { key: "symbol", label: "公差符號說明", children: <Symbol ref={symbol_ref} /> },
    // { key: "symbol", label: "公差符號說明", children: <SymbolTrain ref={symbol_ref} /> },
  ];
  const changeHorizon = () => {
    s_tabKey === "meds" && meds_ref.current.changeHorizon();
  };
  const changeVertical = () => {
    s_tabKey === "meds" && meds_ref.current.changeVertical();
  };
  const onCreate = () => {
    s_tabKey === "meds" && meds_ref.current.onCreate();
    s_tabKey === "symbol" && symbol_ref.current.onCreate();
  };

  const controlMesd = [
    <Button
      type={`${s_isHorizontalLayout ? "primary" : "default"}`}
      key="horizon"
      onClick={() => {
        changeHorizon();
      }}
    >
      切換水平佈局
    </Button>,
    <Button
      type={`${s_isHorizontalLayout ? "default" : "primary"}`}
      key="vertical"
      onClick={() => {
        changeVertical();
      }}
    >
      切換垂直佈局
    </Button>,
    <AddButton
      type="primary"
      key="create"
      onClick={() => {
        onCreate();
      }}
    />,
  ];
  const controlSymbol = [
    <AddButton
      type="primary"
      key="create"
      onClick={() => {
        onCreate();
      }}
    />,
  ];

  const genControl = (s_tabKey) => {
    const pageControl = {
      meds: controlMesd,
      symbol: controlSymbol,
    };
    return pageControl[s_tabKey];
  };

  return (
    <>
      <PageHeader title={"檢驗規格模板｜工程圖面管理"} extra={genControl(s_tabKey)} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
        }}
      />
    </>
  );
};

export default Meds;
