import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//-----------------------------------------取得工站途單----------------------------------------
export const getWpcSearch = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcSearch`, {
    params: data,
  });
};

export const getWoPwc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoPwc`, {
    params: _params,
  });
};
