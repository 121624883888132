import Highcharts from "highcharts";

export const chartBasic = {
  chart: {
    type: "column", //選擇圖表型態
    spacing: [14, 25, 14, 25], //圖表與邊框距離
    backgroundColor: "#FFFFFF", //圖表背景顏色
    borderRadius: 8, //圖表邊框圓角
    borderColor: "#000000", //圖表邊框顏色
    shadow: {
      //圖表陰影設定
      color: "rgba(103,103,103,0.4)",
      offsetX: 2,
      offsetY: 3,
      blur: 7,
    },
  },
  colors: ["#BF5CF6", "#6D75D8", "#83C8E4", "#FFCA60", "#FF8647", "#9E4D4D]"],
  title: {
    //圖表標題設定
    align: "left",
    margin: 10,
    style: {
      color: "#1677FF",
    },
  },
  legend: {
    //圖表圖例設定
    align: "right",
    verticalAlign: "top",
    layout: "vertical",
    symbolRadius: 0, //圖例圓角>>以下設定會變成正方形
    symbolHeight: 12,
    symbolWidth: 12,
    itemStyle: {
      fontSize: '20px'
  }
  },
  exporting: {
    //圖表下載按鈕
    enabled: false,
  },
  credits: {
    //顯示 highchart.com
    enabled: false,
  },
};

export const produceColumnChartForValue = (
 
  chartHeigh = 100,
  xAxisLable = [],
  data = [],
  title = "",
  backgroundColor="#FFFFFF"
) => ({
  ...chartBasic,
  chart: {
    ...chartBasic.chart,
   
    height: chartHeigh,
    backgroundColor:backgroundColor
  },
  title: {
    ...chartBasic.title,
    text: title,
    margin: 50
  },
  series: data,
  xAxis: {
    categories: xAxisLable,
  },
  yAxis: [
    {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return (Highcharts.numberFormat( (this.value/1000).toFixed(2), 0, ",", ","))+"k"
        },
      },
      tickAmount: 5,
    },
    {
      labels: {
        format: '{value}%',
      },
      tickPositions: [0, 25, 50, 75, 100],
      title: {
        text: ' ',
      },
      opposite: true,
    },
  ],
  tooltip: {
    enabled: true,
    shared: true,
   
    formatter: function () {
      let formattedDate = Highcharts.dateFormat("%m/%d", this.x);
      let k =1000
      return (
        "<b>" +
        formattedDate +
        "</b><br/>" +
        this.points
          .map(function (point) {
            if(point.series.name ==="良率"){
              return point.series.name + ": " + point.y + '%'
            }else{
              return point.series.name + ": " +(Highcharts.numberFormat( (point.y/k).toFixed(2), 0, ",", ","))+"k"
            }
            
          })
          .join("<br/>")
      );
    },
  },
});

export const produceColumnChartForPercent = (
  chartWidth = 100,
  chartHeigh = 100,
  xAxisLable = [],
  data = [],
  title = "",
  backgroundColor="#FFFFFF"
) => ({
  ...chartBasic,
  chart: {
    ...chartBasic.chart,
    width: chartWidth,
    height: chartHeigh,
    backgroundColor:backgroundColor
  },
  title: {
    ...chartBasic.title,
    text: title,
  },
  series: data,
  xAxis: {
    categories: xAxisLable,
  },
  yAxis: [
    {
      labels: {
        format: "{text}%",
      },
      tickPositions: [0, 25, 50, 75, 100],
      title: {
        text: "",
      },
    },
  ],
});

//以下為 serires data的範例寫法

//數值圖
//  data.map(data=>(
//   {
//     name:`${data.mtypes}機種`,
//     data:[
//       parseInt(data.inC,10),parseInt(data.okC,10),data.ngC],
//     color:"#88CBFF",
//     dataLabels: {
//       enabled: true,
//       formatter: function() {
//         return Highcharts.numberFormat(this.y, 0, '.', ','); // 加上千分位符號
//       }

//   }}))

// const generateChartData = (chartData) => {
//   return chartData.map(data => ({

//     name: data?.mtypes ? `${data.mtypes}機種` :data.PN ,
//     data: [
//       parseInt(data.inC, 10),
//       parseInt(data.okC, 10),
//       data.ngC
//     ],
//     color: data.PN ?"":"#88CBFF",
//     dataLabels: {
//       enabled: true,
//       formatter: function () {
//         return Highcharts.numberFormat(this.y, 0, '.', ',');
//       }
//     }
//   }));
// };

//百分比
// data.map(data=>({
//   name:`${data.mtypes} 機種`,
//   data:[data.okRate],
//   // color:"#7cb5ec",
//   tooltip:{
//     valueSuffix: '%'
//   },
//   color:"#88CBFF",
//   dataLabels: {
//     enabled: true,
//     formatter: function() {
//       return Highcharts.numberFormat(this.y) + '%'; // 轉換為百分比形式
//     } // 百分比格式
//   }
//  }))

// const generatePercentChartData = (chartData) => {
//   return chartData.map(data => ({
//     name: data?.mtypes ? `${data.mtypes}機種` :data.PN,
//     data: [data.okRate],
//     tooltip: {
//       valueSuffix: '%'
//     },
//     color: data.PN ?"":"#88CBFF",
//     dataLabels: {
//       enabled: true,
//       formatter: function () {
//         return Highcharts.numberFormat(this.y) + '%';
//       }
//     }
//   }));
// };
