import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { message,Typography } from "antd";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import { getInvBatch } from "@/service/apis/WMS/StockSearch"
import ModalConintaer from "./ModalContainer";



const { Link } = Typography;

const searchBatchNo = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, set_s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  //獲得資料
  const call_getInvBatch = useAPI(getInvBatch);
  const tableColumns = [
    {
      title: t("util.util.PN"),//料號
      dataIndex: "PN",
      width: "15%",
    },
    {
      title: t("util.util.batchno"),//批號
      dataIndex: "batchno",
      width: "15%",
    },
    {
      title: t("util.util.whID"),//倉別
      dataIndex: "whNM",
      width: "15%",
      align: "center",
      render: (_, record) => `${record.whID} -${record.whNM}`,
    },
    {
      title: t("util.util.dqty"),//條碼掃入
      dataIndex: "dqty",
      width: "15%",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.sqty"),//人工輸入
      dataIndex: "sqty",
      width: "15%",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.tqty"),//當前總數
      dataIndex: "tqty",
      align: "right",
      width: "15%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.action"),
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => (
        <Link
          onClick={() => {
            
            set_s_editData(rowData);
            set_s_isShowModal(true);
          
          }}
        >
          {t("util.util.detail")}
        </Link>
      ),
    },
  ]
  // 傳上去查詢用的

  console.log(s_editData);
  const onSearch = (value) => {
    call_getInvBatch.request(value)
  };
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  useEffect(() => {
    call_getInvBatch.request(s_searchData);
  }, []);

  useEffect(() => {
    if (call_getInvBatch.status === "suc") {
      message.success(call_getInvBatch.msg);
      //整理分頁
      set_s_pageInfo(call_getInvBatch.data.pageInfo);
      // 整理資料
      set_s_tableData(
        call_getInvBatch.data.tableData.map((x, index) => ({
          ...x,
          key: index
        }))
      );
    } else if (call_getInvBatch.status === "err") {
      set_s_tableData([]);
      set_s_pageInfo(call_getInvBatch?.data?.pageInfo)
      message.error(call_getInvBatch.msg);
    }
  }, [call_getInvBatch.status]);

  console.log(s_pageInfo.totalItems);
  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getInvBatch.status === "load"}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
      <CustomModal
        title={`${t("util.util.batchno")}${t("util.util.detail")} `}
        width={"70%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
      </>  );
});

export default searchBatchNo;
