import { Button, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { addSpcN } from "@/service/apis/QMS/QMSManage";
import { VerticalLeftOutlined, VerticalRightOutlined } from "@ant-design/icons";

import SIP from "./SIP";

const QMSMAnage = () => {
  const { t } = useTranslation();
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  const sip_ref = useRef(null);
  const instrument_ref = useRef(null);
  const sampling_ref = useRef(null);
  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);

  const call_addSpcN = useAPI(addSpcN);
  const [s_searchData, set_s_searchData] = useState({
    ID: undefined,
    PN: undefined,
    pdtNM: undefined,
  });
  const [s_tabKey, set_s_tabKey] = useState("sip"); // 目前是在哪個Tab上

  // 欄位縮放
  const [s_isFixed, set_s_isFixed] = useState(false);

  const onSearch = () => {
    s_tabKey === "sip" && sip_ref.current.onSearch();
    s_tabKey === "instrument" && instrument_ref.current.onSearch();
    s_tabKey === "sampling" && sampling_ref.current.onSearch();
  };

  const onCreate = () => {
    s_tabKey === "sip" && sip_ref.current.onCreate();
    s_tabKey === "instrument" && instrument_ref.current.onCreate();
    s_tabKey === "sampling" && sampling_ref.current.onCreate();
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
  }, []);

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],
    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) || [],
    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    pwc:
      call_getPWC?.data.map((pwc) => ({ label: `${pwc.pwcID}_${pwc.pwcNM}`, value: pwc.pwcID })) ||
      [],
    ctrlrequire: [
      { value: "A", label: "每次抽樣創建(人工開立檢驗單)" },
      { value: "B", label: "每固定週期自創" },
      { value: "C", label: "物控覆核時自創" },
      { value: "D", label: "切換工班時自創" },
    ],
  };

  useEffect(() => {
    const { msg, status } = call_addSpcN;
    if (status === "suc") {
      message.success(msg);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_addSpcN.status]);

  const searchBasic = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,

    <Button
      key="s_isFixed"
      type="primary"
      onClick={() => set_s_isFixed(!s_isFixed)}
      icon={s_isFixed ? <VerticalLeftOutlined /> : <VerticalRightOutlined />}
    />,
  ];
  return (
    <>
      <PageHeader title="檢驗標準管理" extra={searchBasic} />

      <SIP
        call_addSpcN={call_addSpcN}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
        ref={sip_ref}
        s_arrMap={s_arrMap}
        s_searchData={s_searchData}
        set_s_searchData={set_s_searchData}
        s_isFixed={s_isFixed}
      />
    </>
  );
};

export default QMSMAnage;
