import { useState, useEffect } from "react";
import { message } from "antd";
import { getLine } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { multipleIDtoName } from "@/util/format";
import PageHeader from "@/components/PageHeader/index";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { useTranslation } from "react-i18next";
const TB = (props) => {
  const { c_lightData, arrMap } = props;

  const [s_tableData, set_s_tableData] = useState([]);

  const call_getLine = useAPI(getLine);
  const { t } = useTranslation();
  const tableColumns = [
    {
      title: t("ADM.production-line-manage.wlID"), //"工線ID",
      dataIndex: "wlID",
      align: "center",
    },
    {
      title: t("util.util.name"), //"工線名稱",
      dataIndex: "wlNM",
      align: "center",
    },
    {
      title: t("ADM.production-line-manage.ws"), //"工站名稱",
      dataIndex: "wsID",
      align: "center",
      render: (_, rowData) => `${rowData.wsID}_${rowData.wsNM}`,
    },
    {
      title: t("ADM.production-line-manage.ps"), //"車間名稱",
      dataIndex: "psID",
      align: "center",
      render: (text) => multipleIDtoName(arrMap.psArr, text, "psID", "psNM"),
    },
    {
      title: t("ADM.util.pf"), //"製程名稱",
      dataIndex: "pfID",
      align: "center",
      render: (text) => multipleIDtoName(arrMap.pfArr, text, "pfID", "pfNM"),
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
    },
  ];

  useEffect(() => {
    call_getLine.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getLine.status === "suc") {
      message.success(call_getLine.msg);
      set_s_tableData(
        call_getLine.data.map((x, i) => ({
          ...x,
          key: i,
          psID: x.psID.split("|").filter((x) => x !== ""),
        }))
      );
    } else if (call_getLine.status === "err") {
      message.error(t(`error.error-code.${call_getLine.msg}`));
      set_s_tableData([]);
    }
  }, [call_getLine.status]);

  return (
    <>
      <PageHeader title={t("ADM.production-line-manage.tbHeader_Station")} />
      <CustomTable
        columns={tableColumns.filter((x) => !x?.hidden)}
        dataSource={s_tableData}
        loading={call_getLine.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        // pagination={false}
      />
    </>
  );
};

export default TB;
