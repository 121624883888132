import { useState, useEffect } from "react";
import { Button, Space, message, DatePicker, Input } from "antd";
import { IconPen, IconCheck, IconClose, IconGarbage } from "@/components/Icon/Action";
import styled from "styled-components";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getZPPS007HEAD } from "@/service/apis/SAP/head.js";
import { useTranslation } from "react-i18next";
// import { sapTypeOptions } from "../../util/anyMap";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";
import { numberWithCommas } from "../../../../util/format";
const { RangePicker } = DatePicker;
const HD = (props) => {
  const { t } = useTranslation();
  // const { c_lightData, set_c_lightData } = props;

  const call_getZPPS007HEAD = useAPI(getZPPS007HEAD);

  const [s_tableData, set_s_tableData] = useState([]);
  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const [s_searchData, set_s_searchData] = useState({
    startDate: today,
    endDate: tomorrow,
    AUFNR: "",
  });

  const tableColumns = [
    {
      title: "用戶端",
      dataIndex: "CLIENT",
      align: "center",
      // ellipsis: true,
      fixed: true,

      // width:160
      width: "160px",
    },
    {
      title: "工廠",
      dataIndex: "WERKS",
      align: "center",
      // ellipsis: true,
      fixed: true,

      // width:160
      width: "160px",
    },
    {
      title: "工單號",
      dataIndex: "AUFNR",
      align: "center",
      // ellipsis: true,
      fixed: true,

      width: "300px",
      // width:300
    },

    { title: "料號", dataIndex: "MATNR", width: "300px", align: "left", fixed: true },
    {
      title: "計畫產量",
      dataIndex: "GAMNG",
      align: "center",
      // ellipsis: true,
      width: "160px",

      // width:120,
      render: (text) => numberWithCommas(text),
    },
    {
      title: "預計開始日期",
      dataIndex: "GSTRP",
      align: "center",
      // ellipsis: true,
      // width:160
      width: "160px",
    },
    {
      title: "預計完成日期",
      dataIndex: "GLTRP",
      align: "center",
      // ellipsis: true,
      // width:160
      width: "160px",
    },
    {
      title: "訂單號碼",
      dataIndex: "KDAUF",
      align: "center",
      // ellipsis: true,
      // width:160
      width: "160px",
    },
    {
      title: "訂單項次",
      dataIndex: "KDPOS",
      align: "center",
      // ellipsis: true,
      // width: "30%",
      // width:130
      width: "130px",
    },
    {
      title: "預交貨日",
      dataIndex: "VDATU",
      align: "center",
      // ellipsis: true,
      // width:160
      width: "160px",
    },
    {
      title: "批號",
      dataIndex: "CHARG",
      align: "center",
      // ellipsis: true,
      width: "250px",
      // width:250
    },

    {
      title: "生管註記",
      dataIndex: "ABLAD",
      align: "left",
      width: "300px",
    },
    {
      title: "交貨確認",
      dataIndex: "FIXMG",
      align: "center",
      // ellipsis: true,

      // width:150,

      width: `150px`,
      render: (text) => (text === "X" ? "已交貨" : "未交貨"),
    },
    {
      title: "時間",
      align: "center",
      // ellipsis: true,
      dataIndex: "ZZIFTIMESTAMP",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      // width:160
    },
  ];

  const fakeData = [
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "ABC Corp",
      WERKS: "Factory A",
      AUFNR: "A123456789",
      MATNR: "Product X",
      GAMNG: 1000,
      GSTRP: "2023-11-10",
      GLTRP: "2023-11-20",
      KDAUF: "Order-123",
      KDPOS: 1,
      VDATU: "2023-11-15",
      CHARG: "Batch-789",
      ABLAD: "Production in progress",
      FIXMG: "已交貨",
      ZZIFTIMESTAMP: "2023-11-05",
    },
    {
      CLIENT: "XYZ Inc",
      WERKS: "Factory B",
      AUFNR: "B987654321",
      MATNR: "Product Y",
      GAMNG: 800,
      GSTRP: "2023-11-12",
      GLTRP: "2023-11-25",
      KDAUF: "Order-456",
      KDPOS: 2,
      VDATU: "2023-11-18",
      CHARG: "Batch-456",
      ABLAD: "Ready for shipping",
      FIXMG: "未交貨",
      ZZIFTIMESTAMP: "2023-11-03",
    },
    // 添加更多假資料...
  ];
  const handleKeyDownEnter = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      call_getZPPS007HEAD.request({
        ...s_searchData,
        startDate: s_searchData.startDate.format("YYYY-MM-DD"),
        endDate: s_searchData.endDate.format("YYYY-MM-DD"),
      });
    }
  };
  const search = (e) => {
    // set_c_lightData({});

    call_getZPPS007HEAD.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  };
  useEffect(() => {
    call_getZPPS007HEAD.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  }, []);
  useEffect(() => {
    // if (
    //   (s_searchData.PN === undefined || s_searchData.PN === "") &&
    //   (s_searchData.modelID === undefined || s_searchData.modelID === "")
    // ) {
    //   call_getModelHd.request(s_searchData);
    // }
  }, [s_searchData]);

  useEffect(() => {
    if (call_getZPPS007HEAD.status === "suc") {
      set_s_tableData(call_getZPPS007HEAD.data.map((x, i) => ({ ...x, key: i, index: i })));
    } else if (call_getZPPS007HEAD.status === "err") {
      message.error(call_getZPPS007HEAD.msg);
      set_s_tableData([]);
    }
  }, [call_getZPPS007HEAD.status]);
  console.log(s_searchData);
  const searchTemp = [
    <Input
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.woN")}`}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, AUFNR: e.target.value };
        });
      }}
      onKeyDown={handleKeyDownEnter}
    />,
    <RangePicker
      allowClear={false}
      format="YYYY-MM-DD"
      value={[s_searchData.startDate, s_searchData.endDate]}
      className="w-full"
      onChange={(_, dateString) => {
        // console.log(date);
        console.log(dateString);

        set_s_searchData((prve) => {
          return { ...prve, startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) };
        });
      }}
    />,
    <Button type="primary" onClick={search} key="search">
      查詢
    </Button>,
  ];

  return (
    <>
      <PageHeader title="SAP工單表頭" extra={searchTemp} />

      <CustomTable
        rowClassName={`bg-slate-50`}
        scroll={{ x: 2800, y: 720 }}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        // dataSource={fakeData}
        loading={call_getZPPS007HEAD.status === "load"}
        // tbMode={Object.keys(c_lightData).length > 0}
        // c_lightData={c_lightData}
        // onRow={(record) => ({
        //   onClick: () =>
        //     record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        // })}
      />

      {/* <CustomModal
        width="90%"
        title="新建標籤樣板"
        open={s_showModal}
        centered
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getModelHd.request(s_searchData);
        }}
      >
      
      </CustomModal> */}
    </>
  );
};
export default HD;
