import { Tooltip } from "antd";

/**
 * 一個用於顯示工具提示(tooltip)的 React 組件，長文模式
 * 長文模式!!
 * 長文模式!!
 * 長文模式!!
 * 長文模式!!
 * 長文模式!!
 * @param {Object} props - 組件的屬性對象
 * @param {string} props.title - 要顯示的主要文本內容
 * @param {string} [props.toolTipText] - 可選的工具提示文本,如果未提供則使用 title 作為工具提示
 * @returns {JSX.Element} 一個包含工具提示的 JSX 元素
 */

const ShowTooltip = (props) => {
  const { title, toolTipText = "" } = props;
  return (
    <Tooltip title={toolTipText || title}>
      <div className="truncate  w-full">{title}</div>
    </Tooltip>
  );
};

export default ShowTooltip;
