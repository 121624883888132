import { FloatButton, Input, Select, Space, message, DatePicker, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { getAbcSelect, getAllAbc } from "@/service/apis/ADM/abcBoard";
import { ArrowUpOutlined } from "@ant-design/icons";

import Light from "./Light";
import { getLine, getPWC } from "@/service/apis/ADM/publicAPI";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
const { Title, Text, Link } = Typography;

const colorMap = {
  "W": "white",
  // "W": "success",
  "R": "red",
  "Y": "yellow",
};

const lightMap = {
  0: "人工解除",
  1: "系統解除",
  2: "異常停工",
  3: "異常提報",
};
const DATE_FORMAT = "YYYY-MM-DD";

export default function AbcBoard() {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_allWsWl, set_s_allWsWl] = useState([]);
  const [s_event, set_s_event] = useState([]);
  const [s_light, set_s_light] = useState([]);
  const [s_dri, set_s_dri] = useState([]);
  const [s_wlOptions, set_s_wlOptions] = useState([]);
  const [c_lightData, set_c_lightData] = useState({});
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });
  // 送給後端的資料
  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 200,
    abnSN: null,
    woN: null,
    PN: null,
    light: "All", //狀況
    event: "All", //項目
    wlID: "All", //機碼
    pwcID: "All", //工作中心
    startDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const call_getAllAbc = useAPI(getAllAbc);

  const call_getLine = useAPI(getLine);
  const call_getPWC = useAPI(getPWC);

  const LineOptions = call_getLine.data?.map((item) => ({
    label: `${item.wlNM}_${item.wlID}`,
    value: item.wlID,
  }));
  const PWCOptions = call_getPWC.data?.map((item) => ({
    label: `${item.pwcNM}_${item.pwcID}`,
    value: item.pwcID,
  }));

  const tableColumns = [
    {
      title: "事件名稱",
      dataIndex: "eventNM",
      align: "center",
    },
    {
      title: "燈號",
      dataIndex: "light",
      align: "center",
      render: (text) => <Light type={colorMap[text]} />,
      // render: (text) => <Light type={"error"} />,
    },
    {
      title: "工單號",
      dataIndex: "woN",
      align: "center",
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
      render: (text, rowData) => {
        return <ShowTooltip title={`${text}`} />  ;
      
      },
    },

    {
      title: "工作中心",
      dataIndex: "pwcID",
      align: "center",
      render: (text, rowData) => {
        return <ShowTooltip title={`${text}_${rowData?.pwcNM}`} />  ;
      },
    },
    {
      title: "機碼",
      dataIndex: "wlID",
      align: "center",
      render: (text, rowData) => {
        return <ShowTooltip title={`${text}_${rowData?.wlNM}`} />  ;
      
      },
    },
    {
      title: "異常工時(秒)",
      dataIndex: "cumworkH",
      align: "center",
    },
    {
      title: "提報時間",
      dataIndex: "rptT",
      align: "center",
      render: (value) => dayjs(value).format("MM-DD HH:mm:ss"),
    },

    {
      title: "提報人員",
      dataIndex: "rptNM",
      align: "center",
    },
    {
      title: "解除時間",
      dataIndex: "rlsT",
      align: "center",
      render: (value) => dayjs(value).format("MM-DD HH:mm:ss"),
    },
    {
      title: "解除人員",
      dataIndex: "rlsNM",
      align: "center",
    },
    // {
    //   title: "異常信息",
    //   dataIndex: "rpt_msg",
    //   align: "center",
    // },
  ];

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      set_s_searchData((prev) => ({ ...prev, page: 1 }));
      call_getAllAbc.request({ ...s_searchData, page: 1 });
    }
  };

  const handleChange = (type, value) => {
    // 每次變更都重第一頁開始

    set_s_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }

      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const searchBasic = [
    <Input
      key="s_searchData.woN"
      value={s_searchData.woN}
      onChange={(e) => handleChange("woN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}查詢製令工單`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handleChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,

    <Select
      key="s_searchData.pwcID"
      className="w-[220px]"
      options={[{ value: "All", label: "全部工作中心" }, ...LineOptions]}
      value={s_searchData.pwcID}
      onChange={(e) => handleChange("pwcID", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <Select
      key="s_searchData.wlID"
      className="w-[220px]"
      options={[{ value: "All", label: "全部機碼" }, ...PWCOptions]}
      value={s_searchData.wlID}
      onChange={(e) => handleChange("wlID", e)}
      onKeyDown={handleKeyDownEnter}
    />,
    <DatePicker.RangePicker
      key="time"
      allowClear={false}
      value={[dayjs(s_searchData.startDate), dayjs(s_searchData.endDate)]}
      format={DATE_FORMAT}
      onChange={(dates) => handleChange("time", dates)}
    />,
  ];

  useEffect(() => {
    call_getLine.request();
    call_getPWC.request();
  }, []);

  useEffect(() => {
    if (call_getAllAbc.status === "suc") {
      message.success(call_getAllAbc.msg);
      set_s_tableData(call_getAllAbc.data?.tableData.map((x, i) => ({ ...x, key: i })));
      set_s_pageInfo(call_getAllAbc.data?.pageInfo);
      set_c_lightData({});
    } else if (call_getAllAbc.status === "err") {
      message.error(call_getAllAbc.msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getAllAbc.status]);

  useEffect(() => {
    call_getAllAbc.request(s_searchData);
  }, [s_searchData]);

  return (
    <>
      <PageHeader title="異常管理看板" />
      <Space className="w-full flex justify-end mb-4">{searchBasic}</Space>
      <CustomTable
        loading={call_getAllAbc.status === "load"}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
      <FloatButton
        icon={<ArrowUpOutlined />}
        type="primary"
        style={{ right: 24 }}
        onClick={() => {
          const ele = document.querySelector(".ant-table-body");
          ele.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
    </>
  );
}
