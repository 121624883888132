const vi_VN = {
  "util": {
    "util": {
      "itemID": "ID hạng mục ",
      "itemno": "Đơn hàng ",
      "sign_out": "Đăng xuất",
      "add": "Thêm mới ",
      "edit": "Thay đổi ",
      "delete": "Xóa bỏ",
      "search": "Tìm kiếm",
      "cancel": "Hủy",
      "close": "Đóng",
      "ok": "Xác nhận ",
      "render": "Chọn ",
      "check": "Kiểm tra",
      "success": "Thành công ",
      "all": "Toàn bộ ",
      "yes": "Yes ",
      "no": "No ",
      "name": "Tên ",
      "total": "Tổng số",
      "SN": "Số thứ tự",
      "detail": "Chi tiết",
      "action": "Thao tác",
      "size_big": "Size lớn",
      "size_large": "Cỡ lớn",
      "size_middle": "Cỡ trung ",
      "size_small": "Cỡ nhỏ",
      "hide_detail": "Ẩn tệp chi tiết",
      "del_confirm": "Xác nhận xóa",
      "del_confirm_msg": "Vui lòng xác nhận dữ liệu không sai xót,  rồi tiến hành xóa",
      "note": "Ghi chú ",
      "no_data": "Không có dữ liệu",
      "data": "Dữ liệu",
      "header_cancel_create": "Hủy tạo dựa trên",
      "header_create": "Tạo mục từ này",
      "placeholder_select": "Vui lòng lựa chọn ",
      "placeholder_input": "Vui lòng nhập ",
      "or": "Hoặc ",
      "order": "Đơn hàng ",
      "showTotal": "Tổng có {{total}} mục, đang hiện thị từ {{rangeFirst}} đến mục {{rangeEnd}}",
      "itemNM": "Tên dự án ",
      "excluding_tax": "Chưa thuế",
      "start": "Bắt đầu ",
      "end": "Đến",
      "wl": "Chuyền làm việc ",
      "ws": "Trạm làm việc",
      "woN": "Số lệnh sản xuất ",
      "situF": "Trạng thái",
      "PN": "Số liệu",
      "whID": "ID kho hàng",
      "idmtype": "Loại nhập sổ",
      "custID": "ID khách hàng ",
      "custPN": "Số liệu khách hàng ",
      "pdtNM": "Tên sản phẩm",
      "pdtspec": "Thông số kỹ thuật ",
      "planqty": "Số lượng kế hoạch",
      "inqty": "Số lượng sản xuất ",
      "tqty": "Tổng số hiện tại ",
      "sqty": "Nhập thủ công ",
      "dqty": "Quét mã vạch ",
      "batchno": "Số lô",
      "okC": "Số lượng sản phẩm đạt ",
      "inC": "Số lượng sản xuất ",
      "ngC": "Số lượng không đạt ",
      "lifeF": "Lịch trình",
      "isvalid": "Có hiệu quả ",
      "isfocus": "Chú ý",
      "dept": "Bộ phận ",
      "updtT": "Cập nhật thời gian ",
      "pdtclass": "Hàng hóa",
      "mtype": "Loại máy",
      "ver": "Mã bản",
      "qty": "Số nhóm",
      "attribute": "Thuộc tính ",
      "unit": "Đơn vị đo lường",
      "opunit": "Đơn vị sản xuất",
      "unitwt": "Đơn vị trọng lượng ",
      "setting": "Cài đặt ",
      "mgmtcat": "Phân loại ủy quyền",
      "querycat": "Phân loại tìm kiếm ",
      "drawno": "Số bản vẽ kỹ thuật",
      "matl": "Chất liệu",
      "moldID": "ID khuôn",
      "heirPN": "Số liệu kế thừa",
      "heirdealID": "Đối tượng kế thừa",
      "moldC": "Số lần hợp khuôn ",
      "moldcavity": "Số mẫu trong khuôn",
      "searchPN": "Tìm kiếm số liệu",
      "dealPN": "Code No giao dịch",
      "edit_detect_msg1": "Hệ thống phát hiện bạn đã chỉnh sửa trường dữ liệu sau",
      "edit_detect_msg2": "Vui lòng lưu dữ liệu hệ thống trước khi chỉnh sửa các chi tiết",
      "msg_chose_data": "Vui lòng chọn dữ liệu để tham chiếu",
      "msg_chose_whichType_toThrow": "Vui lòng chọn loại tài liệu để chuyển đổi",
      "msg_chose_atleast_one_Detail": "Vui lòng chọn ít nhất một chi tiết",
      "UploadSignature": "Tải lên chữ ký",
      "UploadSign": "Tải lên chữ ký đã ký",
      "removeSearch": "Xoá lịch sử tìm kiếm ",
      "setAdvanced": "Cài đặt điều kiện tìm kiếm nâng cao ",
      "exportExcel": "Xuất ra Excel",
      "table_close": "Lệnh bắt buộc ",
      "table_more": "Tìm kiếm nhiều hơn ",
      "header_next": "Mục tiếp theo ",
      "header_prev": "Mục trước đó",
      "header_print": "In",
      "header_return": "Quay lại bảng tổng ",
      "header_save": "Lưu",
      "header_tour": "Quay về trang đầu ",
      "take": "Trích",
      "throw": "Chuyển đổi",
      "accord": "Dựa theo ",
      "create": "Tạo",
      "cancel_Check": "Hủy  ký và gửi lại ",
      "sysMsg": "Hệ thống tin nhắn ",
      "advanceSearch": "Tìm kiếm nâng cao ",
      "window": "Cửa sổ",
      "contact": "Người liên hệ",
      "tel": "Người liên hệ",
      "itemno_total": "Tổng số lượng ",
      "get_prev_data_suc": "Thành công lấy dữ liệu mục trước đó",
      "get_next_data_suc": "Thành công lấy mục dữ liệu tiếp theo ",
      "get_prev_data_err": "Lấy dữ liệu mục trước đó thất bại",
      "get_next_data_err": "Lấy dữ liệu mục tiếp theo thất bại ",
      "prev_data_errMsg":
        "The Current Information Is The First One, And There Is No Previous Information",
      "next_data_errMsg": "Hiện tại là mục dữ liệu cuối cùng, không có mục tiếp theo ",
      "opcode": "Loại đơn",
      "N": "Số đơn ",
      "currencytype": "Loại tiền ",
      "taxtype": "Loại thuế",
      "taxrate": "Mức thuế",
      "utype": "Loại đơn vị ",
      "custalias": "Tên viết tắt của khách hàng ",
      "custNM": "Tên khách hàng ",
      "exchangerate": "Tỷ giá hối đoái ",
      "exchangeD": "Tỷ giá hối đoái ngày ",
      "confirmID": "Nhân viên đánh giá ",
      "lifeFT": "Thời gian lịch trình ",
      "createID": "Nhân viên sáng tạo ",
      "createT": "Ngày sáng tạo ",
      "confirmT": "Ngày đánh giá",
      "signT": "Ngày ký và gửi lại ",
      "approveT": "Ngày phê duyệt ",
      "updtID": "Nhân viên cập nhật ",
      "EDT": "Dự tính ngày giao hàng ",
      "startEDT": "Dự tính ngày xuất hàng (bắt đầu)",
      "endEDT": "Dự tính ngày giao hàng (muộn nhất)",
      "pbom": "Số lượng kết hợp ",
      "punit": "Đơn vị sử dụng ",
      "tax": "Tổng số tiền ",
      "itemsum": "Số tiền mục đơn ",
      "itemsumtax": "Số thuế mục đơn ",
      "subtotal": "Tổng gồm thuế",
      "batchdate": "Số lô ngày tháng ",
      "balqty": "Số lượng còn lại ",
      "suppID": "ID nhà cung cấp ",
      "suppalias": "Tên viết tắt nhà cung cấp",
      "suppNM": "Tên nhà cung cấp",
      "stockinT": "Thời gian di chuyển ",
      "idmtype_s": "Đếm thủ công ",
      "idmtype_d": "Đếm mã vạch",
      "idmtype_m": "Đếm hỗn hợp ",
      "attribute_0": "0 Nguyên liệu",
      "attribute_1": "1 Vật liệu ",
      "attribute_2": "2 Linh kiện không tiêu chuẩn ",
      "attribute_3": "3 Linh kiện tiêu chuẩn ",
      "attribute_4": "4 Bán thành phẩm ",
      "attribute_5": "5 Thành phẩm ",
      "attribute_6": "6 Sản phẩm khách hàng cung cấp",
      "pdtclass_X": "N/A",
      "pdtclass_M": "Linh kiện chính ",
      "pdtclass_A": "Linh kiện phụ ",
      "pdtclass_P": "Vật liệu đóng gói",
      "packtype_0": "(0)Sản xuất đóng gói-sử dụng đơn vị sản xuất ",
      "packtype_1": "(1)Bao bì vận chuyển_Sử dụng cho đóng gói và vận chuyển",
      "packtype_2": "(2)Bao bì doanh thu -Sử dụng cho doang thu dây chuyền sản xuất ",
      "assetkind": "Phân loại tài sản ",
      "assetkind_TI": "Phun ",
      "assetkind_TS": "Dập",
      "assetkind_TD": "Đúc",
      "assetkind_TF": "Rèn",
      "fileType_SOP": "Quy trình hoạt động tiêu chuẩn SOP",
      "fileType_SIP": "Quy trình kiểm nghiệm tiêu chuẩn SIP",
      "fileType_PACK": "Quy trình đóng gói tiêu chuẩn PACK",
      "fileType_DRAW": "Bản vẽ kĩ thuật DWG",
      "fileType_PIC": "Tệp hinh ảnh PIC",
      "fileType_ECN": "Thông báo thay đổi công trình -ECN",
      "nav_pds_dashboard": "Kanban sản xuất kỹ thuật số",
      "nav_pds_abc-board": "Kanban quản lý bất thường",
      "nav_pds_overview-lines": "Bảng tóm tắt hoạt động dây chuyền",
      "nav_BDM": "Quản lý dữ liệu vật liệu",
      "nav_bdm_pn-manage": "Bảo trì dữ liệu cơ bản",
      "nav_bdm_edm": "Bảo trì dữ liệu kỹ thuật",
      "nav_EDM": "Quản lý dữ liệu khuôn ",
      "nav_edm_mold": "Bảo trì dữ liệu khuôn",
      "nav_TDM": "Quản lý dữ liệu xưởng",
      "nav_tdm_plant-manage": "Cài đặt tệp chính của nhà xưởng",
      "nav_tdm_procedure-manage": "Quy trình kế hoạch bảo trì",
      "nav_tdm_production-line-manage": "Lập kế hoạch và bảo trì dây chuyền sản xuất",
      "nav_tdm_warehouse-manage": "Bảo trì quy hoạch nhà kho",
      "nav_tdm_string-manage": "Quản lý chuỗi công năng",
      "nav_tdm_plant-params-manage": "Cài đặt tham số nhà xưởng",
      "nav_SDM": "Quản lý dữ liệu hệ thống",
      "nav_sdm_user-manage": "Bảo trì dữ liệu nhân viên",
      "nav_sdm_company": "Bảo trì dữ liệu nhà cung cấp",
      "nav_sdm_client": "Bảo trì dữ liệu khách hàng",
      "nav_sdm_category-manage": "Cài đặt phân loại tìm kiếm",
      "nav_sdm_data-auth-manage": "Cài đặt ủy quyền chức năng",
      "nav_sdm_trading-pattern": "Cài đặt chuỗi hạng mục ",
      "nav_sdm_function-params-manage": "Cài đặt tham số hệ thống ",
      "nav_WMS": "Hệ thống quản lý kho",
      "nav_wms_plan-in": "Hoạt động nhập kho",
      "nav_wms_plan-out": "Hoạt động xuất kho",
      "nav_wms_stock-search": "Kiểm tra tình hình hàng tồn kho",
      "nav_wms_inv-history": "Biên bản hàng tồn kho hàng tháng ",
      "nav_wms_pro-instock": "Hoạt động đại lý nhập kho",
      "nav_PMS": "Hệ thống quản lý thu mua",
      "nav_pms_plan": "Kế hoạch hỏi giá",
      "nav_pms_req": "Hoạt động yêu cầu thu mua",
      "nav_pms_pro": "Hoạt động thu mua",
      "nav_pms_instock": "Hoạt động nhập liệu",
      "nav_pms_gostock": "Tìm kiếm kiểm tra nhập liệu",
      "nav_pms_return": "Hoạt động hàng bán bị trả lại",
      "nav_pms_ap-manage": "Quản lý khoản phải thu",
      "nav_pms_report": "Truy vấn báo cáo",
      "nav_pms_report-unpurchase": "Danh sách mua hàng chưa nhập kho",
      "nav_pms_report-diff": "Bảng điều chỉnh giá thu mua ",
      "nav_pms_report-instocksummary": "Bảng tổng hợp hàng tồn kho ",
      "nav_pms_report-inventorydiff": "Sổ cái giao dịch hàng tồn kho",
      "nav_pms_report-psisummary": "Bảng tổng hợp hóa đơn ",
      "nav_pms_report-purchasesummary": "Bảng tóm tắt nhập trả hàng ",
      "nav_pms_report-purchaselist": "Danh sách chi tiết nhập và trả hàng ",
      "nav_pms_report-purchasestatistics": "Bảng thống kê nhập và trả hàng ",
      "nav_OMS": "Hệ thống quản lý bán hàng",
      "nav_oms_quo": "Hoạt động báo giá",
      "nav_oms_fod": "Hoạt động đặt trước",
      "nav_oms_ord": "Hoạt động bán hàng",
      "nav_oms_outstock": "Hoạt động phân phối",
      "nav_oms_check": "Tìm kiếm kiểm tra bán hàng",
      "nav_oms_return": "Hoạt động hàng bán bị trả lại",
      "nav_oms_ap-manage": "Quản lý khoản phải thu",
      "nav_oms_report": "Truy vấn báo cáo",
      "nav_oms_report-unshipped": "Danh sách đơn đặt hàng chưa xuất hóa đơn",
      "nav_oms_report-salesummary": "Bảng tổng hợp hàng bán bị trả lại",
      "nav_oms_report-salelist": "Danh sách hàng bán bị trả lại",
    },
  },
  "error": {
    "error-code": {
      "403": "無此權限",
      "404": "查無資料",
      "409": "資料重複",
      "500": "操作失敗",
    },
  },
  "WOS": {
    "util": {},
    "login": {
      "pageTitle": "Đăng nhập tài khoản",
    },
    "mc": {
      "pageTitle": "物料作業",
    },
    "parts-binding": {
      "pageTitle": "部品掃碼",
    },
    "ppidp": {
      "pageTitle": "成品綁定",
    },
    "qc": {},
    "tk": {
      "pageTitle": "生產看板",
    },
    "wl-woc": {
      "pageTitle": "生產控制",
    },
    "wpc-search": {
      "pageTitle": "途單查詢",
    },
  },
  "IMS": {
    "util": {
      "lifeF_0": "Đã tạo",
      "lifeF_1": "Đang tiến hành",
      "lifeF_2": "Đã gửi",
      "lifeF_A": "Toàn bộ ",
      "lifeF_T": "Đã di chuyển",
      "opcode_4110": "Nhập kho sản xuất",
      "opcode_4120": "Nhập kho thu mua",
      "opcode_4130": "Trả lại hàng",
      "opcode_4141": "Warehouse Transfer",
      "opcode_4142": "Mượn hàng( Phải trả)",
      "opcode_4143": "Mượn-Trả hàng",
      "opcode_4160": "Chỉnh sửa phiếu Mượn-Trả hàng",
      "opcode_4010": "Mở tài khoản kiểm kê",
      "opcode_4020": "Phiếu kiểm kê định kì  ",
      "opcode_4030": "Chỉ định kiểm kê",
      "opcode_4210": "生產領用",
      "opcode_4260": "生產超領",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4243": "借入歸還",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "來源單號",
      "preN_4120": "來源單號",
      "preN_4130": "來源單號",
      "preN_4220": "來源單號",
      "preN_4230": "來源單號",
      "preN_4141": "來源單號",
      "preN_4241": "來源單號",
      "preN_4242": "來源單號",
      "preN_4143": "來源單號",
      "preN_4142": "來源單號",
      "preN_4243": "來源單號",
      "preN_4210": "來源單號",
      "preN_4160": "來源單號",
      "preN_4260": "來源單號",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "operN_4299": "領用單號",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "dueday_4299": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "batchno_4299": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "Xác nhận di chuyển  ",
      "txn_confirm_msg": "Vui lòng xác nhận dữ liệu di chuyển không có sai xót, rồi xác nhận xóa",
      "opcode": "Hình thức hoạt động  ",
      "jobN": "Mã số hoạt động ",
      "planqty": "Số lượng kế hoạch",
      "realtqty": "Số lượng thực tế",
      "bal": "Chênh lệch",
    },
    "plan-in": {
      "pageHeader": "Hoạt động nhập kho ",
    },
    "plan-out": {
      "pageHeader": "Hoạt động xuất kho ",
    },
    "stock-search": {
      "searchBatchno": "批號查詢",
      "pageHeader": "Kiểm tra tình hình hàng tồn kho ",
      "searchPN": "Tìm kiếm số liệu",
      "searchWh": "Tìm kiếm từng kho hàng",
      "whDetails": "Phiếu phân kho hàng ",
      "changeDetails": "Phiếu di chuyển ",
      "borrow": "Mượn",
      "inTransit": "On going",
      "loan": "Vay ",
      "lockqty": "Số hàng niêm phong",
      "onhandqty": "Số hàng tồn còn hạn",
      "planin": "Kế hoạc nhập kho",
      "planout": "Kế hoạc xuất kho",
      "posted": "Đã nhập sổ ",
      "scanin": "Kiểm kê nhập kho ",
      "scanout": "Kiểm kê xuất kho",
      "timeSeries": "Thứ tự thời gian ",
      "total": "Ước tính số lượng có thể dùng",
      "totalIn": "Tổng nhập kho",
      "totalOut": "Tổng xuất kho ",
      "tqty": "Số hàng tồn",
    },
    "inv-history": {
      "pageHeader": "Biên bản tồn kho hàng tháng ",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "Dày",
      "inCBM": "進\n(CBM)",
      "l": "Dài ",
      "level": "Mức",
      "suppalias": "Nhà cung cấp",
      "w": "Rộng ",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "Hoạt động đại lý nhập kho ",
      "lifeF_T": "Đã quy hoạch ",
      "wmsopUUID": "Số đơn hoạt động lưu trữ",
      "inchargeID": "Nhân viên phụ trách ",
    },
  },
  "ADM": {
    "util": {
      "pfNM": "Tên quy trình ",
      "pushlayer": "Đẩy phân cấp",
      "pf": "Quy trình ",
      "pfID": "ID quy trình ",
      "pwc": "Trung tâm công việc ",
      "wpcSearch": "Tìm kiếm vận đơn",
      "dispachManage": "Quản lý công văn ",
      "ppidp": "Ràng buộc thành phẩm ",
      "partsBinding": "Mã quét bộ phận",
      "mail": "Mail",
      "postID": "Chức danh ",
      "sectID": "工類",
      "sectID_C": "間接人力",
      "sectID_D": "直接人力",
    },
    "oem-manage": {
      "type": "Danh mục thuê ngoài",
      "oem_item_manage": "Cài đặt hạng mục thuê ngoài",
      "oem_item": "Hạng mục thuê ngoài",
      "type_A": "Nhân lực",
      "type_B": "Bề ngoài",
      "type_C": "機工",
      "oem_supp_manage": "Cung cấp bảo trì thuê ngoài",
      "oem_supp": "Cung cấp thuê ngoài",
      "vendorcode": "Mã cung cấp",
      "oemitemNM": "Tên hạng mục thuê ngoài",
    },
    "function-params-manage": {
      "pageHeader": "Cài đặt tham số hệ thống ",
      "searchPlaceholder": "Tìm kiếm ID, đơn vị, mã số, mã chức năng, tên phân cấp",
      "functions": "Đẩy nhóm",
      "unitNM1": "Tên đơn vị ",
      "type": "Trạng thái đếm",
      "category": "Danh mục đếm",
      "countType": "Cách đếm",
      "quantitative": "Đếm",
      "count": "計數",
      "weight": "Trọng lượng ",
      "length": "Độ dài",
      "capacity": "Dung tích",
      "area": "Diện tích",
      "volume": "Thể tích",
      "sort": "Sắp xếp",
      "currencyitem": "Mã tiền tệ",
      "itemNM": "Tên tiền tệ ",
      "currency": "Loại tiền giao dịch ",
      "ismycurrency": "Có phải loại tiền này không?",
      "funcode": "Mã công năng tổ chức ",
      "funNM": "Tên công năng ",
      "function": "Cài đặt số nhóm đẩy",
      "lvlcode": "Mã số tầng",
      "lvlNM": "Tên số tầng",
      "team": "1(Nhóm đội)",
      "class": "2(Cấp tổ trưởng)",
      "management": "3(Cấp quản lý)",
      "total": "4(Cấp cao )",
      "eventID": "ID Sự kiện ",
      "eventNM": "Tên sự kiện ",
      "rptcode": "Danh mục đề cập",
      "pusher": "Hệ thống gửi",
      "situF": "Đèn cảnh báo ",
      "audience": "Đẩy đối tượng",
      "startlvl": "Cấp độ bắt đầu",
      "setlvl": "Số nhóm đẩy",
      "cruisebot": "Chương trình giám sát ",
      "msgmodel": "Đẩy tin nhắn bản mẫu",
      "pushEvent": "Đẩy sự việc",
      "rptcode_A0": "Nhân viên báo cáo ",
      "rptcode_B0": "Thông báo hệ thống(WOS)",
      "rptcode_C0": "Báo cáo thiết bị ",
      "rptcode_D0": "Báo cáo quản lý -Từ xa",
      "rptcode_E0": "Báo cáo quản lý -Đám mây",
      "situF_Y": "Vàng",
      "situF_R": "Đỏ",
      "startlvl_0": "Toàn bộ nhân viên ",
      "startlvl_1": "Cán bộ nhóm (tổ)",
      "startlvl_2": "Cán bộ tổ trưởng ",
      "startlvl_3": "Quản lý ",
      "startlvl_4": "Quản lý cấp cao ",
      "add_string": "Thêm chuỗi",
      "add_number": "Thêm số",
      "add_point": "Thêm dấu thập phân",
      "1_decimal_place": "Sau dấu thập phân 1 đơn vị",
      "2_decimal_place": "Sau dấu thập phân 2 đơn vị",
    },
    "procedure-manage": {
      "pageHeader": "Quy trình kế hoạch bảo trì",
      "pccID": "ID trung tâm chi phí",
      "pccNM": "Tên trung tâm chi phí",
      "pwcID": "ID trung tâm làm việc ",
      "pwcNM": "Tên trung tâm công việc ",
      "tabCost": "Cài đặt trung tâm chi phí",
      "tabFlow": "Quy trình cài đặt tệp chính ",
      "tabWork": "Cài đặt trung tâm làm việc ",
    },
    "user-manage": {
      "dashboard": "Kanban sản xuất kỹ thuật số",
      "abc-board": "Kanban quản lý bất thường",
      "BDM": "Quản lý dữ liệu vật liệu ",
      "SDM": "Quản lý dữ liệu hệ thống",
      "TDM": "Quản lý dữ liệu kỹ thuật",
      "EDM": "Quản lý dữ liệu thiết bị ",
      "WMS": "Hệ thống quản lý kho",
      "OMS": "Hệ thống quản lý bán hàng ",
      "PMS": "Hệ thống quản lý thu mua",
      "WOC": "Kiểm soát sản xuất ",
      "MC": "Hoạt động vật liệu ",
      "QC": "Hoạt động kiểm soát chất lượng ",
      "TK": "Kanban sản xuất ",
      "account": "Tài khoản nhân viên ",
      "pwd": "Mật mã",
      "role": "Quyền vai trò",
      "roleID": "ID vai trò",
      "roleNM": "Tên vai trò",
      "systemAuth": "Quyền đăng nhập hệ thống",
      "autoReport": "Nhận báo cáo tự động",
      "APP": "Ứng dụng di động",
      "PDA": "Quyền PDA",
      "dealmgmt": "Đối tượng phân loại ủy quyền",
      "pdtmgmt": "Vật liệu phân loại ủy quyền ",
      "whmgmt": "Kho phân loại ủy quyền ",
      "cardID": "Mã số thẻ ",
      "function": "Công năng tổ chức ",
      "pageHeader": "Bảo trì dữ liệu nhân viên ",
      "peopleNM": "Họ tên nhân viên ",
      "personalID": "Số căn cước công dân ",
      "proxyNM": "Họ và tên người liên lạc",
      "proxytel": "Số điện thoại liên hệ ",
      "staffAuth": "Cài đặt quyền của vai trò",
      "staffData": "Quản lý dữ liệu nhân viên ",
      "staffID": "Mã số nhân viên ",
    },
    "trading-pattern": {
      "OMS": "Hệ thống đơn hàng",
      "PMS": "Hệ thống thu mua ",
      "adda": "Đánh dấu điều khoản",
      "addb": "Khẩu trừ bổ sung ",
      "addc": "Hoạt động trả trước",
      "addd": "Phương thức thanh toán ",
      "adde": "Điều kiện giao dịch ",
      "addf": "Hoạt động trả hàng ",
      "addg": "Hủy đơn ",
      "addh": "Phương thức thanh toán ",
      "addi": "Điều khoản thanh toán ",
      "additem": "ID hạng mục ",
      "addr": "Địa chỉ liên lạc",
      "addtype": "Điều kiện giao dịch ",
      "contactitem": "Mã cửa sổ liên lạc ",
      "department": "Bộ phận ",
      "itemNM": "Tên cửa sổ liên lạc ",
      "mobile": "Điện thoại di động",
      "pageHeader": "Cài đặt chuỗi thường dùng ",
      "tabAdded": "Cài đặt mục bổ sung",
      "tabWindow": "Cài đặt cửa sổ đối ngoại",
      "tabPMSAdded": "Điều kiện thu mua bổ sung ",
      "tabOMSAdded": "Điều kiện bán hàng bổ sung",
    },
    "data-auth-manage": {
      "mgmtitem": "Danh mục ",
      "pageHeader": "Cài đặt ủy quyền chức năng",
      "tabCust": "Nhóm quyền dữ liệu khách hàng ",
      "tabPdt": "Nhóm ủy quyền vật liệu",
      "tabSupp": "Nhóm ủy quyền thông tin nhà xưởng ",
      "tabWh": "Nhóm ủy quyền kho ",
    },
    "category-manage": {
      "mgmtitem": "Hạng mục ",
      "pageHeader": "Cài đặt phân loại tìm kiếm ",
      "tabCust": "Phân loại tìm kiếm khách hàng ",
      "tabQuery": "Phân loại tìm kiếm vật liệu ",
      "tabSupp": "Phân loại tìm kiếm nhà cung cấp",
      "tabMtype": "Phân loại loại máy vật liệu",
    },
    "warehouse-manage": {
      "cancelClose": "Hủy đóng tài khoản ",
      "close": "Đóng tài khoản ",
      "isClose": "Đã đóng tài khoản chưa?",
      "mgmtcat": "Uỷ quyền kho ",
      "pageHeader": "Bảo trì quy hoạch nhà kho",
      "pdtclass": "Giai đoạn vật liệu",
      "ps": "Xưởng ",
      "tabWareHouse": "Cài đặt dữ liệu kho ",
      "whID": "ID Kho ",
      "whNM": "Tên kho",
      "psNM": "Tên xưởng",
    },
    "string-manage": {
      "pageHeader": "Quản lý chuỗi công năng ",
      "tabPause": "Dự án đình công ",
      "tabOkItem": "Sản phẩm tốt",
      "tabNgItem": "Sản phẩm lỗi",
      "tabDri": "Đơn vị chịu trách nhiệm ",
      "tabOffLine": "Dự án ngoại tuyến ",
      "driItemID": "ID đơn vị ",
      "driItemNM": "Tên đơn vị ",
    },
    "client": {
      "groupalias": "Tên tập đoàn viết tắt ",
      "location": "Khu vực thị trường",
      "account": "Tài khoản ngân hàng ",
      "addr": "Địa chỉ công ty ",
      "bankAddrOS": "Địa chỉ ngân hàng ",
      "bankCodeOS": "Mã số chi nhánh",
      "branchOS": "Tên chi nhánh ",
      "classificationOfRestrictions": "Quyền truy cập ",
      "country": "Quốc gia",
      "customerBasicInformation": "Thông tin khách hàng cơ bản ",
      "customerContactInformation": "Thông tin liên lạc khách hàng ",
      "department": "Bộ phận ",
      "domesticBankName": "Tên ngân hàng trong nước ",
      "domestic_OverseasBankInformation": "Thông tin ngân hàng nước ngoài/trong nước",
      "item": "10 điều/trang ",
      "itemNM": "Công năng cửa sổ ",
      "maxcredit": "Điều kiện tín dụng ",
      "mobile": "Số điện thoại di động ",
      "overseasBankName": "Tên ngân hàng nước ngoài ",
      "pageHeader": "Bảo trì dữ liệu khách hàng ",
      "pageHeader2": "Quản lý thông tin khách hàng ",
      "payterm": "Phương thức thanh toán ",
      "showTotal": "Tổng 1 mục, đang hiển thị từ 1 đến 1",
      "swiftCode": "Swift Code",
      "taxID": "Mã số thuế",
      "username": "Tên tài khoản",
    },
    "company": {
      "manufacturerBasicInformation": "Thông tin nhà cung cấp cơ bản ",
      "manufacturerContactInformation": "Thông tin liên lạc nhà cung cấp",
      "pageHeader": "Bảo trì dữ liệu nhà cung cấp ",
      "groupalias": "Tên tập đoàn viết tắt",
      "location": "Khu vực thị trường",
      "account": "Tài khoản ngân hàng ",
      "addr": "Địa chỉ công ty ",
      "bankAddrOS": "Địa chỉ ngân hàng ",
      "bankCodeOS": "Mã số chi nhánh",
      "branchOS": "Tên chi nhánh ",
      "classificationOfRestrictions": "Quyền truy cập ",
      "country": "Quốc gia ",
      "department": "Bộ phận ",
      "domesticBankName": "Tên ngân hàng trong nước ",
      "domestic_OverseasBankInformation": "Thông tin ngân hàng trong nước/nước ngoài",
      "item": "10 diều /trang ",
      "itemNM": "Công năng cửa sổ",
      "maxcredit": "Điều kiện tín dụng ",
      "mobile": "Số điện thoại di động ",
      "overseasBankName": "Tên ngân hàng nước ngoài",
      "payterm": "Phương thức thanh toán ",
      "showTotal": "Tổng 1 mục, đang hiển thị từ 1 đến 1 ",
      "swiftCode": "SWIFT CODE",
      "taxID": "Mã số thuế",
      "username": "Tên tài khoản",
    },
    "edm": {
      "alertspeed": "Báo động tốc độ sản xuất ",
      "alertstop": "Báo động quá mức",
      "warnyield": "Đèn vàng năng suất",
      "alertyield": "Đèn đỏ năng suất",
      "attribute": "Thuộc tính vật liệu ",
      "bom": "Hóa đơn bảo trì vật liệu ",
      "errorPleaseFull": "Vui lòng nhập dữ liệu hoàn chỉnh ",
      "ieUUID": "Số hối phiếu",
      "isrework": "Có bảng hành trình sản xuất làm lại không?",
      "level": "Giai đoạn vật liệu ",
      "nextSN": "Số hành trình thứ 2",
      "pPN": "Số hiệu mặt hàng gốc",
      "pageHeader": "Bảo trì dữ liệu kỹ thuật ",
      "pdtclass": "Danh mục sản phẩm ",
      "pfNM": "Quá trình",
      "planhead": "Số người kế hoạch ",
      "ppdtNM": "Tên sản phẩm gốc",
      "productionScheduleDetails": "Phiếu chi tiết lịch trình ",
      "qty": "Số nhóm",
      "stdlt": "Thời gian chuẩn bị sản xuất ",
      "stdmh": "Thời gian máy tiêu chuẩn",
      "stdwh": "Giờ công tiêu chuẩn",
      "batchbom": "Số lô đầy đủ gói",
      "ver": "Mã bản",
      "wpcManage": "Bảo trì lịch trình sản xuất ",
      "wpc": "Quy trình sản xuất",
      "wpcSN": "Số lịch trình ",
      "wpcSetting": "Cài đặt số lịch trình ",
      "wpcset": "Nhóm lịch trình ",
      "wpcsetNM": "Tên nhóm lịch trình",
      "whID": "Loại nhập kho",
      "alternative_wpc": "Quy trình thay thế",
      "main_wpc": "Quy trình chính ",
      "edit_all_wpc": "Thay đổi lịch trình sản xuất toàn cầu",
      "Pfc": "Kiểm soát văn kiện kỹ thuật ",
      "doc": "Phát hành văn kiện tiêu chuẩn ",
      "ECN": "Thông báo thay đổi kỹ thuật ",
      "pdftype": "Loại văn kiện ",
      "docUpload": "Tải văn kiện lên",
      "Upload": "Tải lên",
      "docName": "Tên văn kiện ",
      "docVer": "Bản văn kiện ",
    },
    "pn-manage": {
      "bom": "單位轉換",
      "basicProduct": "Dữ liệu sản phẩm cơ bản ",
      "dealPNSearch": "Tìm kiếm code no giao dịch",
      "boxH": "Kích thước đóng gói(cao)",
      "boxL": "Kích thước đóng gói(dài)",
      "boxW": "Kích thước đóng gói(rộng)",
      "dealID": "ID đối tượng ",
      "dealNM": "Tên giao dịch ",
      "dealPN": "Code No giao dịch/SKU",
      "dealalias": "Viết tắt đối tượng",
      "dealpid": "Mã vạch ",
      "idkw": "Từ khóa mã vạch bao bì",
      "inititalcost": "Lịch trình ban đầu",
      "isfocus": "Có chú ý không?",
      "isvalid": "Có khởi động không",
      "maxcost": "Giới hạn số lượng hàng tồn kho",
      "maxstock": "Giới hạn hàng tồn kho cao nhất",
      "minstock": "Giới hạn hàng tồn kho thấp nhất",
      "moq": "Lượng mua  tối thiểu",
      "newPN": "Bản mới code no",
      "nextpacksn": "Số nhóm đóng gói lớp bên ngoài",
      "pack": "Đơn vị đóng gói",
      "packsn": "Số lớp đóng gói",
      "packtype": "Mục đích đóng gói ",
      "pageHeader": "Bảo trì dữ liệu cơ bản",
      "palH": "Pallet (cao)",
      "palL": "Pallet (dài)",
      "palW": "Pallet (rộng)",
      "pbom": "Số nhóm đóng gói lớp bên trong",
      "pdtH": "Kích thước sản phẩm(cao)",
      "pdtL": "Kích thước sản phẩm(dài)",
      "pdtW": "Kích thước sản phẩm(rộng)",
      "punit": "Đơn vị sử dụng lớp bên trong ",
      "purchasecost": "Chi phí nhập liệu trong kỳ",
      "purchaseqty": "Lượng nhập liệu trong kỳ",
      "replacePN": "Code no thay thế",
      "safestock": "Số lượng hàng trong kho an toàn ",
      "salecurrency": "Tỷ giá tiền tệ ",
      "setbatch": "Số lô sử dụng ",
      "setidm": "Cài đặt mã vạch",
      "setpack": "Cài đặt đóng gói",
      "settlemonth": "Thay đổi tháng thanh toán",
      "shipV": "Khối lượng đóng gói Cuft",
      "shipW": "Trọng lượng vận chuyển ",
      "sizeunit": "Đơn vị đo kích thước",
      "stdcost": "Chi phí tiêu chuẩn ",
      "stdsale": "Giá cả tiêu chuẩn ",
      "stdstock": "Hàng tồn kho tiêu chuẩn ",
      "stocklife": "Hàng tồn kho định kì",
      "tabCost": "Cài đặt chi phí tiêu chuẩn ",
      "tabDeal": "Cài đặt code no giao dịch ",
      "tabExtra": "Cài đặt kích thước sản phẩm",
      "tabIdkw": "Cài đặt ràng buộc sản xuất ",
      "tabPack": "Cài đặt ràng buộc bao bì",
      "tabPrice": "Cài đặt gái cả tiêu chuẩn ",
      "tabStock": "Cài đặt điều kiện hàng tồn kho ",
      "unitwt": "Đếm trọng lượng đơn",
      "varcost": "Chi phí biến động ",
      "ver": "Bản tiêu chuẩn đóng gói ",
      "weightunit": "Đơn vị trọng lượng ",
    },
    "mold": {
      "pageHeader": "Bảo trì dữ liệu khuôn ",
      "mold": "Dữ liệu khuôn",
      "assetkind": "Loại khuôn ",
      "assetID": "ID khuôn ",
      "assetNM": "Tên tài sản ",
      "moldtype": "Mẫu khuôn chung ",
      "cavity": "Số lỗ thực tế",
      "stdcavity": "Số lỗ tiêu chuẩn ",
      "validcavity": "Số lỗ dùng được ",
      "wsID": "Trạm ",
      "property": "Thuộc về tài sản ",
      "company": "Công ty ",
      "gatesystem": "Loại cổng",
      "moldlife": "Tuổi thọ thiết kế",
      "draw": "Bản vẽ khuôn ",
      "pdtpic": "Hình ảnh thành phẩm",
      "malepic": "Hình ảnh khuôn công",
      "femalepic": "Hình ảnh khuôn mẫu",
      "wholepic": "HÌnh ảnh khuôn hỗn hợp",
      "advance": "Dữ liệu khuôn nâng cao ",
      "length": "Tổng chiều dài khuôn",
      "width": "Tổng chiều rộng khuôn ",
      "height": "Tổng chiều cao khuôn ",
      "weight": "Trọng lượng khuôn ",
      "openC": "Mở tài khoản Mô-đun ",
      "openT": " ",
      "cummoldC": "Mô đun sản xuất tích lũy cuối cùng",
      "precummoldC": "Mô đun sản xuất tích lũy của thời gian lần trước",
      "status": "Trạng thái đèn ",
      "note": "Ghi chú khuôn ",
    },
    "plant-manage": {
      "pageHeader": "Cài đặt tệp chính của nhà xưởng",
      "plantID": "Nhà xưởng",
      "deptID": "Bộ phận ",
      "push1": "Khoảng thời gian đẩy cấp 1(phút)",
      "push2": "Khoảng thời gian đẩy cấp 2(phút)",
      "push3": "Khoảng thời gian đẩy cấp 3(phút)",
      "tabDept": "Tệp tin tổng thể  bộ phận",
      "tabPlant": "Tệp chính khu vực xưởng",
      "tabPost": "File chức danh chính",
    },
    "production-line-manage": {
      "MC": "Hoạt động vật liệu",
      "QC": "Hoạt động kiểm soát chất lượng ",
      "TK": "Sản xuất Kanban",
      "WOC": "Kiểm soát sản xuất",
      "auth_setting": "Cài đặt quyền hạn ",
      "batch": "Quản lý số lô",
      "mold": "Quản lý khuôn ",
      "oem": "Báo công thuê ngoài",
      "pageHeader": "Lập kế hoạch và bảo trì dây chuyền sản xuất",
      "pcc": "Trung tâm chi phí",
      "ps": "Xưởng",
      "psID": "ID Xưởng ",
      "psNM": "Tên xưởng ",
      "tabLine": "Cài đặt dữ liệu dây chuyền làm việc ",
      "tabPS": "Cài đặt dữ liệu xưởng ",
      "tabStation": "Cài đặt dữ liệu trạm làm việc ",
      "tbHeader_PS": "Dữ liệu trạm làm việc",
      "tbHeader_Station": "Dữ liệu dây chuyền công việc",
      "whm": "Quản lý thời gian làm việc ",
      "wlID": "ID dây chuyền làm việc ",
      "wlNM": "Tên dây chuyền làm việc",
      "ws": "Trạm công việc",
      "wsID": "ID trạm làm việc ",
      "wsNM": "Tên trạm làm việc ",
    },
  },
  "APS": {
    "util": {
      "opcode": "Loại đơn hoạt động",
      "opcode_3310": "Lệnh sản xuất bình thường",
      "opcode_3320": "重工工單",
      "opcode_3330": "Lệnh sản xuất thử nghiệm",
      "opcode_3340": "Lệnh sản xuất kỹ thuật",
      "duestart": "Dự kiến ​​ngày hoàn thành",
      "dueend": "Ngày hoàn thành dự kiến",
      "realqty": "Số lượng thực tế",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "Hoạt động điều phối quản lý sản xuất",
      "wo": "Lệnh sản xuất",
      "tabWo": "Tệp chính lệnh sản xuất ",
      "mo": "Đơn liệu ",
      "jobN": "Số lệnh sản xuất ",
      "tabMo": "Tệp chính đơn liệu ",
      "orderN": "Số đơn đặt hàng khách hàng ",
      "ETDstart": "Dự kiến ngày giao hàng ",
      "ETDend": "Ngày giao hàng dự kiến",
      "issueID": "Nhân viên công bố",
      "issueday": "Ngày công bố",
    },
  },
  "PMS": {
    "util": {
      "opcode_2010": "Kế hoạch hỏi giá",
      "opcode_2210": "Yêu cầu thu mua trong nước",
      "opcode_2220": "Yêu cầu thu mua trong nước",
      "opcode_2230": "Xin thuê ngoài",
      "opcode_2310": "Thu mua trong nước",
      "opcode_2320": "Thu mua nước ngoài",
      "opcode_2330": "Thuê gia công ngoài",
      "opcode_2410": "Nhập liệu trong nước",
      "opcode_2420": "Nhập liệu từ nước ngoài",
      "opcode_2430": "Nhập liệu từ đơn vị bên ngoài",
      "opcode_2610": "Thoát kho ",
      "opcode_2620": "Thoát đảm bảo chất lượng ",
      "opcode_2710": " Mua hàng vật liệu",
      "opcode_2810": "Cân bằng thu chi phải trả",
      "opcode_4120": "Đơn nghiệm thu ",
      "opcode_4230": "Đơn nghiệm trả",
      "plan": "Yêu cầu báo giá",
      "req": "Đơn yêu cầu thu mua ",
      "pro": "Đơn thu mua ",
      "instock": "Đơn nhập liệu",
      "gostock": "Đơn kiểm nghiệm nhập liệu",
      "return": "Thoát nhập liệu",
      "pay": "Tìm kiểm tài khoản phải trả",
      "ap": "Đơn sao kê",
      "strike": "Thanh toán cân bằng thu chi ",
      "N22": "Số đơn yêu cầu thu mua",
      "N23": "Số đơn thu mua",
      "N24": "Số đơn nhập liệu ",
      "N27": "Số đơn sao kê",
      "N28": "Số đơn cân bằng thu chi",
      "outN": "Số đơn nghiệm trả",
      "planSearch": "Tìm kiếm biên bản yêu cầu báo giá",
      "reqSearch": "Tìm kiếm biên bản yêu cầu mua",
      "proSearch": "Tìm kiếm biên bản thu mua ",
      "instockSearch": "Tìm kiếm biên bản nhập liệu",
      "stockSearch": "Tìm kiếm biên bản nghiệm thu ",
      "strikeSearch": "Tìm kiếm biên bản cân bằng thu chi",
      "apSearch": "Tìm kiếm dữ liệu sao kê",
      "drawno": "Số bản vẽ",
      "unitprice": "採購單價",
      "localprice": "Đơn giá nội tệ ",
    },
    "plan": {
      "pageHeader": "Kế hoạch hỏi giá",
      "hd_delete_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn hỏi giá không sai xót, rồi tiến hành xóa ",
      "demandqty": "Số lượng yêu cầu",
      "tb": "Chi tiết kế hoạch ",
      "tb_drawer_title": "Chi tiết kế hoạch hỏi giá",
      "rec_drawer_title": "Đánh giá thông số kỹ thuật",
    },
    "req": {
      "pageHeader": "Hoạt động yêu cầu thu mua",
      "create_header": "Tạo mới tệp chính yêu cầu thu mua",
      "edit_header": "Thay đổi tệp chính yêu cầu thu mua",
      "hd_note": "Ghi chú yêu cầu thu mua",
      "driID": "Phụ trách yêu cầu thu mua",
      "punit": "Đơn vị yêu cầu thu mua",
      "unitprice": "Đơn giá yêu cầu thu mua(chưa thuế)",
      "demandqty": "Số lượng yêu cầu thu mua ",
      "demandDate": "Ngày yêu cầu",
      "itemtotal": "Tổng số tiền chưa thuế",
      "local_subtotal": "Tổng số tiền nội tệ gồm thuế",
      "total": "Tổng số tiền gồm thuế(Loại tiền giao dịch)",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá yêu cầu thu mua",
      "lifeF_7": "Hoàn thành phê duyệt gửi ký",
      "lifeF_T": "Kết án ",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn yêu cầu thu mua không sai xót, rồi tiến  hành xóa",
      "audit_start": "Phê duyệt chính thức gửi ký",
      "audit_finish": "Đã hoàn thành xét duyệt ",
      "reqCheck": "Đánh giá đơn yêu cầu thu mua",
      "cancel_reqCheck": "Hủy đánh giá yêu cầu thu mua",
      "check_confirm": "Xác nhận đánh giá yêu cầu thu mua ",
      "check_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá không sai xót, rồi tiến hành đánh giá ",
      "cancel_check_confirm": "Hủy xác nhận yêu cầu đánh giá thu mua ",
      "cancel_check_confirm_text":
        "Vui lòng xác nhận dữ liệu hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá",
      "reqCheck_lifeF_0_okText": "Xác nhận đánh giá ",
      "reqCheck_lifeF_2_okText": "Hủy đánh giá ",
      "audit_confirm": "Xét duyệt và đăng xuất",
      "audit_confirm_text":
        "Sau khi xét duyệt và đăng xuất, đơn yêu cầu thu mua không thể thay đổi , bạn có chắc chắn hoàn thành xét duyệt không?",
      "audit_okText": "Chắc chắn xét duyệt ",
      "driID_placeholder": "Vui lòng chọn nhân viên yêu cầu thu mua",
      "demandqty_placeholder": "Vui lòng nhập số lượng yêu cầu thu mua ",
      "unitprice_placeholder": "Vui lòng nhập đơn giá chưa thuế",
      "tb_delete_confirm": "Xác nhận xóa phiếu chi tiết yêu cầu thu mua ",
      "tb_title": "Phiếu chi tiết yêu cầu thu mua",
      "create_tb_title": "Thêm mới phiếu chi tiết yêu cầu thu mua",
      "edit_tb_title": "Thay đổi phiếu chi tiết yêu cầu thu mua",
      "throw_modal_submit": "Chắc chắn chuyển đổi(đơn thu mua)",
    },
    "pro": {
      "pageHeader": "Hoạt động thu mua ",
      "create_header": "Tạo mới tệp chính thu mua",
      "edit_header": "Thay đổi tệp chính thu mua ",
      "hd_drawer_title": "Tệp chính đơn thu mua",
      "driID": "Chịu trách nhiệm thu mua",
      "sampleID": "Nhân viên lấy mẫu ",
      "supp_history": "Tìm kiếm lịch sử giao dịch nhà cung cấp",
      "trade": "Điều khoản thương mại",
      "dealaddr": "Địa chỉ nhà cung cấp",
      "contact": "Cửa sổ giao hàng nhà cung cấp",
      "dealcontact": "Cửa sổ nghiệp vụ nhà cung cấp",
      "payment": "Hình thức thanh toán ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "local_subtotal": "Tổng nội tệ gồm thuế",
      "total": "Tổng số tiền bao gồm thuế",
      "local_total": "Tổng số tiền gồm thuế(loại tiền tệ giao dịch)",
      "note": "Ghi chú thu mua",
      "demandqty": "Số lượng thu mua ",
      "punit": "Đơn vị thu mua",
      "confirmqty": "Nhà xưởng xác nhận số lượng ",
      "dealN": "Số đơn nhà cung cấp",
      "cancelqty": "Số lượng hủy",
      "returnqty": "Số lượng trả hàng ",
      "charge": "Tổng khấu trừ bổ sung ",
      "ReviewProcess": "Quy trình phê duyệt",
      "tb_drawer_title": "Phiếu chi tiết đơn thu mua",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá thu mua",
      "lifeF_7": "Nhà cung cấp ký và gửi lại ",
      "lifeF_T": "Kết án",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn thu mua không sai xót, rồi tiến hành xóa",
      "hd_close_confirm_text":
        "Vui lòng xác nhận dữ liệu lệnh đơn thu mua không sai xót, rồi tiến hành lệnh bắt buộc",
      "proT": "Ngày thu mua ",
      "dealN_placeholder": "Vui lòng nhập số đơn nhà cung cấp",
      "payment_placeholder": "Vui lòng chọn hình thức thanh toán ",
      "trade_placeholder": "Vui lòng chọn điều kiện giao dịch ",
      "dealcontact_placeholder": "Chọn cửa sổ nghiệp vụ nhà cung cấp",
      "contact_placeholder": "Chọn cửa sổ nhà cung cấp giao hàng ",
      "unitprice_placeholder": "Vui lòng nhập đơn giá chưa thuế",
      "demandqty_placeholder": "Vui lòng điền số lượng thu mua ",
      "note_placeholder": "Vui lòng nhập ghi chú thu mua",
      "statement_placeholder": "Vui lòng nhập mô tả dự án ",
      "amount_placeholder": "Vui lòng nhập số tiền ",
      "proCheck": "Đánh giá thu mua(1)",
      "cancel_proCheck": "Hủy đánh giá",
      "suppCheck": "Nhà cung cấp  ký và gửi lại (2)",
      "proCheck_confirm": "Xác nhận đánh giá thu mua",
      "proCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá thu mua không sai xót, rồi tiến hành đánh giá thu mua",
      "cancel_proCheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu đánh giá thu mua không sai xót, rồi tiến hành hủy đánh giá thu mua",
      "suppCheck_confirm": "Xác nhận nhà cung cấp  ký và gửi lại ",
      "suppCheck_confirm_text":
        "Vui lòng xác nhận trước dữ liệu  ký và gửi lại không sai xót, rồi tiến hành  ký và gửi lại ",
      "cancel_suppCheck_confirm": "Xác nhận hủy nhà cung cấp  ký và gửi lại ",
      "cancel_suppCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại ",
      "tb": "Phiếu chi tiết thu mua",
      "adda": "Các điều khoản và điều kiện ",
      "adda_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu điều khoản cần xóa không sai xót, rồi tiến hành xóa",
      "addb": "Khấu trừ bổ sung",
      "addb_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu các khoản cần xóa không sai xót, rồi tiến hành xóa",
      "addc": "Hoạt động trả trước",
      "addc_delete_confirm_text":
        "Vui lòng xác nhận hoạt động trả trước cần xóa không sai xót, rồi tiến hành xóa",
      "statement": "Mô tả dự án ",
      "amount": "Số tiền chưa thuế",
      "selectstatement": "Vui lòng chọn các khoản khẩu trừ",
      "totalStart": "Giá trị tối thiểu ",
      "totalEnd": "Giá trị cực đại",
      "deal_history": "Tìm kiếm lịch sử giao dịch sản phẩm",
      "transferqty": "Số lượng đã chuyển đổi",
      "throwqty": "Số lượng chuyển đổi lần này ",
      "message_transfer_small_than_0": "拋轉數量不可小於0",
      "exit_Disposal": "Xử lý trả hàng ",
    },
    "instock": {
      "pageHeader": "Hoạt động nhập liệu ",
      "create_header": "Tạo mới tệp chính nhập liệu",
      "edit_header": "Thay đổi tệp chính nhập liệu",
      "hd_drawer_title": "Tệp chính đơn nhập liệu",
      "sampleID": "Nhân viên lấy mẫu",
      "sampleT": "Ngày lấy mẫu",
      "note": "Đánh dấu nhập liệu",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn nhập liệu không sai xót, rồi tiến hành xóa",
      "tb": "Phiếu chi tiết nhập liệu",
      "goqty": "Số lượng nghiệm thu ",
      "rejectqty": "Số lượng  nghiệm trả",
      "indqty": "Số lượng tạm bị đình chỉ",
      "inspectID": "Nhân viên kiểm nghiệm ",
      "inspectT": "Thời gian hoàn thành kiểm nghiệm ",
      "qalID": "Nhân viên đánh giá",
      "qalT": "Thời gian phát hành ",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá nhập liệu",
      "lifeF_3": "Kiểm tra lấy mẫu IQC",
      "lifeF_4": "Hoàn thành kiểm tra mẫu IQC",
      "lifeF_7": "Đánh giá đảm bảo chất lượng",
      "lifeF_T": "結案",
      "IQCstart": "Lấy mẫu kiểm nghiệm",
      "QAcheck": "Đảm bảo chất lượng phát hành",
      "punit": "Đơn vị nhập liệu",
      "inbatchno": "Số lô nhập liệu",
      "judgeT": "Ngày đánh giá đảm bảo chất lượng ",
      "instockCheck_confirm": "Xác nhận đánh giá nhập liệu",
      "instockCheck_confirm_text":
        "Vui lòng xác nhận  dữ liệu cần đánh giá nhập liệu không sai xót, rồi tiến hành đánh giá nhập liệu",
      "cancel_instockCheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu cần đánh giá nhập liệu không sai xót, rồi tiến hành hủy đánh giá nhập liệu",
      "IQCcheck_confirm": "Xác nhận lấy mẫu kiểm nghiệm",
      "IQCcheck_confirm_text":
        "Vui lòng xác nhận dữ liệu lấy mẫu kiểm nghiệm không sai xót, rồi tiền hành lấy mẫu",
      "cancel_IQCcheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu lấy mẫu kiểm nghiệm không sai xót, rồi tiền hành hủy",
      "IQCfinish_confirm": "Xác nhận hoàn thành kiểm nghiệm IQC",
      "IQCfinish_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hoàn thành kiểm nghiệm IQC không sai xót, rồi tiến hành kiểm nghiệm IQC",
      "cancel_IQCfinish_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu kiểm nghiệm IQC không sai xót, rồi tiến hành hủy kiểm nghiệm IQC",
      "QAcheck_confirm": "Xác nhận phát hành đảm bảo chất lượng",
      "QAcheck_confirm_text":
        "Vui lòng xác nhận dữ liệu phát hành đảm bảo chất lượng không sai xót, rồi tiến hành phát hành đảm bảo chất lượng",
      "cancel_QAcheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu phát hành đảm bảo chất lượng không sai xót, rồi tiến hành hủy phát hành đánh giá chất lượng ",
      "QAQTYcheck":
        "Chi tiết nhập liệu[Số lượng Từ chối Đảm bảo Chất lượng]+[Số lượng phát hành đảm bảo chất lương] phải bằng[số lượng nhập liệu]",
      "throwStock_message":
        "Chuẩn bị bước vào hoạt động chuyển đổi đơn kiểm tra nhập liệu, bạn có chắc chắn muốn chuyển đổi không?",
      "dealN": "Số đơn nhà cung cấp",
      "takeqty": "Số lượng trích dẫn",
      "unitprice_placeholder": "Vui lòng điền đơn giá chưa thuế",
      "Tooltip_disable_take_balqty_0": "Số lượng còn lại bằng 0 không thể trích dẫn ",
      "message_take_before_choose": "Vui lòng chọn trước trích dẫn rồi nhập số lượng trích dẫn ",
      "message_transfer_small_than_0": "Số lượng chuyển đổi không được nhỏ hơn 0",
      "message_take_same_detail": "Không thể trích dẫn chi tiết giống nhau ",
    },
    "gostock": {
      "pageHeader": "Tìm kiếm kiểm tra nhập liệu",
      "4120_header": "Tệp chính nghiệm thu ",
      "4230_header": "Tệp chính nghiệm trả",
      "total": "Tổng lượng di chuyển ",
      "planqty": "Số lượng di chuyển",
      "jobN": "Số đơn nguồn",
      "batchno_placeholder": "Vui lòng nhập số lô",
      "note": "Đánh dấu",
      "tb": "Phiếu chi tiết nghiệm thu ",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "Quản lý tài khoản phải trả",
      "ap_create_header": "Tạo mới tệp chính đơn sao kê",
      "ap_edit_header": "Thay đổi tệp chính đơn sao kê",
      "ap_sumtotal": "Tổng cộng số tiền",
      "strike_sumtotal": "Tổng số tiền cân bằng thu chi",
      "takeAp": "Trích dẫn phiếu sao kê",
      "contactWindow": "Thông tin liên lạc cửa sổ",
      "chooseWindow": "Chọn cửa sổ",
      "ap_tb": "Chi tiết đơn sao kê",
      "strike_tb": "Chi tiết phiếu cân bằng thu chi ",
      "inbatchno": "Số lô nhập liệu ",
      "itemtotal": "Số tiền gồm thuế phải trả",
      "hubqty": "Số lượng nhập kho ",
      "balance": "Chênh lệch tài khoản",
      "recon": "Dữ liệu sao kê",
      "addr": "Địa chỉ nhà cung cấp ",
      "addr_placeholder": "Vui lòng nhập địa chỉ liên lạc ",
      "strike_tb_delete_confirm": "Xác nhận xóa chi tiết cân bằng thu chi",
      "paytotal": "Số tiền đã cân bằng thu chi",
      "ap_total": "Tổng số tiền gồm thuế phải trả",
      "apCheck": "Đánh giá sao kê",
      "cancel_apCheck": "Hủy đánh giá ",
      "apCheck_confirm": "Xác nhận đánh giá sao kê",
      "apCheck_confirm_text":
        "Sắp bước vào thao tác duyệt sao kê, bạn có muốn lưu dữ liệu sao kê không?",
      "releaseAP": "Xuất đơn sao kê",
      "payment_placeholder": "Vui lòng chọn hình thức thanh toán ",
      "cancel_releaseAP": "Hủy xuất đơn sao kê",
      "releaseAP_confirm": "Xác nhận xuất đơn sao kê",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck": "Đánh giá cân bằng thu chi ",
      "strike_create_header": "Thêm mới tệp chính đơn cân bằng thu chi",
      "strike_edit_header": "Thay đổi tệp chính đơn cân bằng thu chi ",
      "hd_drawer_title": "Tệp chính dữ liệu sao kê",
      "itemNM": "Đánh dấu cửa sổ",
      "paytype": "Hình thức thanh toán ",
      "strikeCheck_confirm": "Xác nhận đánh giá cân bằng thu chi ",
      "strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá là chính xác, rồi tiến hành đánh giá",
      "cancel_strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu đánh giá cần hủy là chính xác, rồi tiền hành hủy đánh giá",
      "confirmStrike": "Xác nhận cân bằng thu chi ",
      "confirmStrike_confirm": "Xác nhận cân bằng thu chi ",
      "confirmStrike_confirm_text":
        "Sau khi xác nhận cân bằng thu chi và kết án đơn cân bằng thu chi. Vui lòng xác nhận có cân bằng thu chi không?",
      "strike_paytotal": "Số tiền thanh toán ",
      "payT": "Thời gian thanh toán ",
      "note": "Ghi chú phiếu cân bằng thu chi ",
      "PN": "Số nguyên liệu ERP",
      "inqty": "Số lượng di chuyển ",
      "ap_del_hd_confirm_text":
        "Vui lòng xác nhận dữ liệu đơn sao kê cần xóa là chính xác, rồi tiến hành xóa",
      "strike_del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn cân bằng thu chi không sai xót, rồi tiến hành xóa",
      "AP_lifeF_0": "Tạo lập",
      "AP_lifeF_1": "Đánh giá",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "Cân bằng thu chi(Thanh toán) chưa kết án ",
      "AP_lifeF_T": "Kết án ",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "Hoàn thành cân bằng thu chi ",
      "message_chose_take_which_ap": "Vui lòng chọn dữ liệu sao kê cần trích dẫn",
      "message_only_take_one_ap": "Chỉ có thể trích dẫn 1 bản sao kê",
    },
    "return": {
      "pageHeader": "Hoạt động thoát nhập liệu",
      "create_header": "Tạo mới nhập liệu thoát khỏi tệp chính",
      "edit_header": "Thay đổi tệp chính nhập liệu trả hàng ",
      "addr": "Địa chỉ liên lạc ",
      "del_hd_confirm_text":
        "Vui lòng xác nhận dữ liệu đơn nhập liệu trả hàng cần xóa không sai xót, rồi tiến hành xóa",
      "rtnqty": "Số lượng hoàn trả",
      "inbatchno": "Số lô nhập liệu ",
      "note": "Ghi chú nhập trả hàng ",
      "tb": "Phiếu chi tiết nhập liệu trả hàng ",
      "itemNM": "Đánh dấu cửa sổ",
      "itemNM_placeholder": "Vui lòng nhập ghi chú nhà xưởng",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án ",
      "message_at_least_chose_one": "Vui lòng chọn ít nhất 1 mục phiếu chi tiết đơn nghiệm trả",
      "planqty": "Số lượng nghiệm trả",
    },
  },
  "OMS": {
    "util": {
      "opcode_1110": "Báo giá trong nước",
      "opcode_1120": "Báo giá nước ngoài",
      "opcode_1210": "Đơn đặt hàng dự tính ",
      "opcode_1310": "Đơn hàng trong nước ",
      "opcode_1320": "Đơn hàng nước ngoài",
      "opcode_1410": "Đơn hàng trong nước",
      "opcode_1420": "Đơn hàng nước ngoài",
      "opcode_4220": "Đơn thử nghiệm ",
      "opcode_4130": "Đơn nghiệm trả",
      "opcode_1610": "Hoàn hàng ",
      "opcode_1710": "Hàng bán xuất kho",
      "opcode_1810": "Cân bằng thu chi phải thu ",
      "plan": "Đơn kế hoạch ",
      "quo": "Đơn báo giá",
      "fod": "Đơn đặt hàng ",
      "ord": "Đơn bán hàng ",
      "outstock": "Đơn phân phối hàng",
      "return": "Đơn hoàn hàng ",
      "ap": "Đơn đối chiếu",
      "outN": "Số đơn nghiệm trả",
      "N11": "Số đơn báo giá",
      "N12": "Số đơn đặt hàng ",
      "N13": "Số đơn bán hàng ",
      "N14": "Số đơn phân phối hàng ",
      "N17": "Số đơn nghiệm thu ",
      "N18": "Số đơn cân bằng thu chi ",
      "planSearch": "Tìm kiếm biên bản yêu cầu báo giá",
      "quoSearch": "Tìm kiếm biên bản báo giá ",
      "fodSearch": "Tìm kiếm biên bản đặt trước",
      "ordSearch": "Tìm kiếm biên bản bán hàng ",
      "stockSearch": "Tìm kiếm biên bản nghiệm thu ",
      "outstockSearch": "Tìm kiếm biên bản phân phối ",
      "apSearch": "Tìm kiếm dữ liệu sao kê",
      "strikeSearch": "Tìm kiếm biên bản cân bằng thu chi",
      "dealN": "Số đơn khách hàng ",
      "drawno": "Số bản vẽ",
      "attribute": "Thuộc tính vật liệu ",
      "pdtclass": "Loại sản phẩm ",
      "pdttype": "Phân loại quản lý ",
      "localprice": "Số tiền nội tệ ",
    },
    "quo": {
      "pageHeader": "Hoạt động báo giá ",
      "quoID": "Nhân viên báo giá",
      "quoT": "Ngày báo giá",
      "validdays": "Số ngày có hiệu lực ",
      "isvalid": "Có hiệu lực không?",
      "hd_drawer_title": "Tệp chính đơn báo giá",
      "tb_drawer_title": "Chi tiết đơn báo giá",
      "demand": "Số lượng báo giá ",
      "punit": "Đơn vị báo giá ",
      "unitprice": "Số tiền báo giá ",
      "isapprove": "Giao dịch mục đơn ",
      "itemtotal": "Tổng số tiền chưa thuế",
      "local_subtotal": "Tổng số tiền nội tệ gồm thuế",
      "total": "Tổng số tiền gồm thuế(loại tiền giao dịch)",
      "note": "Ghi chú báo giá",
      "hd_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu cần xóa đơn báo giá không sai xót, rồi tiến hành xóa",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá báo giá",
      "lifeF_7": "Phê duyệt và kí gửi ",
      "lifeF_T": "Kết án ",
      "quoCheck_confirm_Text":
        "Vui lòng xác nhận trước dữ liệu cần đánh giá không sai xót, rồi tiến hành đánh giá",
      "cancel_quoCheck_confirm_Text":
        "Vui lòng xác nhận trước hủy dữ liệu đánh giá không sai xót, rồi tiến hành hủy đánh giá",
      "Audit_confirm": "Xác nhận phê duyệt ký tên ",
      "Audit_confirm_text":
        "Không thể chỉnh sửa báo giá này sau khi xem xét và phê duyệt. Bạn có chắc chắn muốn hoàn thành việc xem xét không?",
      "tb": "Chi tiết báo giá",
    },
    "fod": {
      "pageHeader": "Hoạt động đặt trước ",
      "hd_drawer_title": "Tệp chính đơn đặt trước",
      "tb_drawer_title": "Phiếu chi tiết đơn đặt trước",
      "driID": "Phụ trách nghiệp vụ ",
      "trade": "Điều khoản giao dịch ",
      "dealcontact": "Cửa sổ nghiệp vụ khách hàng ",
      "payment": "Hình thức thanh toán",
      "dealaddr": "Địa chỉ khách hàng ",
      "custHistory": "Tìm kiếm lịch sử giao dịch khách hàng ",
      "contact": "Cửa sổ giao hàng khách hàng ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "note": "Đánh dấu đặt trước",
      "total": "Tổng số tiền gồm thuế",
      "demandqty": "Số lượng khách hàng đặt hàng",
      "punit": "Đơn vị đặt hàng ",
      "unitprice": "Đơn giá đặt hàng ",
      "confirmqty": "Xác nhận số lượng ",
      "cancelqty": "Số lượng hủy",
      "returnqty": "Số lượng hoàn trả",
      "transferqty": "Số lượng đã chuyển đổi",
      "qty": "Số lượng dự tính chuyển đổi",
      "hd_delete_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn đặt trước không sai xót, rồi tiến hành xóa",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá đặt trước",
      "lifeF_7": "Khách hàng  ký và gửi lại",
      "lifeF_T": "Kết án ",
      "local_total": "Tổng số tiền gồm thuế(nội tệ)",
      "fodCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu đánh giá đặt trước không sai xót, rồi tiến hành xóa đánh giá đặt trước",
      "cancel_fodCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy đánh giá đặt trước không sai xót, rồi tiến hành hủy đánh giá đặt trước",
      "custCheck_confirm": "Khách hàng  ký và gửi lại xác nhận ",
      "custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần  ký và gửi lại không sai xót, rồi tiền hành ký và gửi lại ",
      "cancel_custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại",
      "reviewFlow": "Phê duyệt quy trình (3)",
      "tb": "Phiếu đơn đặt trước",
      "deal_history": "Tìm kiếm lịch sử giao dịch sản phẩm",
    },
    "ord": {
      "pageHeader": "Hoạt động bán hàng ",
      "hd_drawer_title": "Tệp chính đơn bán hàng ",
      "tb": "Chi tiết đơn bán hàng ",
      "adda": "Các điều khoản và điều kiện",
      "addb": "Khấu trừ bổ sung ",
      "addc": "Hoạt động trả trước",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá bán hàng ",
      "lifeF_7": "Khách hàng ký và gửi lại",
      "lifeF_T": "Lệnh ",
      "driID": "Phụ trách nghiệp vụ ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "total": "Tổng số tiền gồm thuế(loại tiền tệ giao dịch )",
      "dealaddr": "Địa chỉ khách hàng ",
      "contact": "Cửa sổ giao hàng khách hàng ",
      "dealcontact": "Cửa sổ nghiệp vụ khách hàng ",
      "payment": "Hình thức thanh toán ",
      "trade": "Điều khoản giao dịch ",
      "note": "Ghi chú bán hàng ",
      "reviewFlow": "Phê duyệt quy trình(3)",
      "demandqty": "Số lượng bán hàng ",
      "transferqty": "Số lượng đã chuyển đổi",
      "qty": "Số lượng chuyển đổi lần này",
      "punit": "Đơn vị bán hàng ",
      "unitprice": "Đơn giá bán hàng ",
      "confirmqty": "Xác nhận số lượng khách hàng ",
      "cancelqty": "Hủy số lượng ",
      "returnqty": "Số lượng hoàn hàng ",
      "charge": "Tổng khấu trừ bổ sung",
      "statement": "Mô tả dự án ",
      "amount": "Số tiền chưa thuế",
      "local_total": "Tổng số tiền gồm thuế(nội tệ)",
      "closeData_confirm_text":
        "Vui lòng xác nhận trước dữ liệu đơn bán hàng không sai xót, rồi tiến hành lệnh bắt buộc ",
      "removeData_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn bán hàng không sai xót, rồi tiến hành xóa",
      "orderCheck_text":
        "Vui lòng xác nhận dữ liệu đánh giá bán hàng không sai xót, rồi tiến hành đánh giá bán hàng ",
      "cancel_orderCheck_text":
        "Vui lòng xác nhận trước hủy dữ liệu đánh giá bán hàng không sai xót, rồi tiến hành hủy đánh giá bán hàng ",
      "cancel_custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại",
      "custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần  ký và gửi lại không sai xót, rồi tiền hành  ký và gửi lại",
      "adda_delete_confirm_text":
        "Vui lòng xác nhận trước dữ liệu điền kiện cần xóa không sai xót, rồi tiến hành xóa",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text":
        "Vui lòng xác nhận trước hoạt động trả trước cần xóa không sai xót, rồi tiến hành xóa",
      "deal_history": "Tìm kiếm lịch sử giao dịch sản phẩm",
    },
    "out-stock": {
      "pageHeader": "Hoạt động phân phối",
      "hd_drawer_title": "Tệp chính đơn phân phối",
      "tb_drawer_title": " ",
      "tb": "Phiếu chi tiết phân phối",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá phân phối",
      "lifeF_3": "Lấy mẫu IQC",
      "lifeF_4": "Hoàn thành  kiểm nghiệm IQC",
      "lifeF_7": "Đánh giá đảm bảo chất lượng ",
      "lifeF_T": "Kết án ",
      "sampleID": "Nhân viên lấy mẫu ",
      "sampleT": "Ngày lấy mẫu ",
      "note": "Ghi chú phân phối",
      "punit": "Đơn vị phân phối",
      "indqty": "Số lượng phân phối",
      "goqty": "Số lượng nghiệm thu ",
      "unitprice": "Đơn giá bán hàng ",
      "rejectqty": "Số lượng nghiệm trả",
      "IQCcheck": "Lấy mẫu và kiểm tra",
      "inspectID": "Nhân viên kiểm nghiệm ",
      "inspectT": "Thời gian hoàn thành kiểm nghiệm ",
      "qalID": "Nhân viên đánh giá chất lượng ",
      "qalT": "Thời gian phát hành ",
      "judgeT": "Ngày tháng đánh giá chất lượng",
      "qty": "số lượng",
      "choose": "Chọn ",
      "choose_err": "Số lượng còn thừa bằng 0 không thể trích dẫn ",
      "throw_save_msg": "Vui lòng chọn trích dẫn trước sau đó nhập số lượng trích dẫn ",
      "throwQty_msg": "Số lượng chuyển đổi không được nhỏ hơn 0",
      "useDetail_msg": "Không thể trích dẫn dữ liệu giống nhau ",
      "IQCcheck_text":
        "Chuẩn bị bước vào hoạt động lấy mẫu kiểm tra, bạn có muốn lưu dữ liệu đơn phân phối không?",
      "closeData_confirm_text":
        "Vui lòng xác nhận dữ liệu cần xóa đơn phân phối không sai xót, rồi tiến hành xóa ",
      "outstockCheck_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá phân phối không sai xót, rồi tiến hành đánh giá phân phối",
      "cancel_outstockCheck_text":
        "Vui lòng xác nhận dữ liệu cần hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá ",
      "IQCfinish_text":
        "Chuẩn bị bước vào hoạt động hoàn thành kiểm nghiệm IQC, bạn có muốn lưu dữ liệu đơn phân phối không?",
      "QAcheck_err": " ",
      "QAcheck": "Đảm bảo chất lượng phát hành",
      "QAcheck_text":
        "Chuẩn bị bước vào hoạt động phát hành đảm bảo chất lượng, bạn có muốn lưu dữ liệu đơn phân phối không?",
      "throwStock_text":
        "Chuẩn bị bước vào hoạt động chuyển đổi đơn phân phối , bạn có chắc chắn muốn chuyển đổi không?",
    },
    "check": {
      "pageHeader": "Tìm kiếm kiểm tra bán hàng ",
      "4220_header": "Tệp chính đơn phân phối bán hàng ",
      "4220_tb": "Phiếu chi tiết phân phối",
      "4130_header": "Tệp chính đơn hàng bị trả lại ",
      "4130_tb": "Phiếu chi tiết hàng bị trả lại ",
      "jobN": "Số đơn nguồn",
      "note": "Đánh dấu",
      "total": "Tổng số lượng di chuyển ",
      "planqty": "Số lượng di chuyển ",
      "punit": "Đơn vị phân phối",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án ",
    },
    "return": {
      "pageHeader": "Hoạt động hàng bán bị trả lại ",
      "tb": "Phiếu chi tiết  hàng bán bị trả lại ",
      "hd_remove_text":
        "Vui lòng xác nhận trước dữ liệu  hàng bán bị trả lại cần xóa không sai xót, rồi tiến hành xóa",
      "unitprice": "Đơn giá xuất hàng ",
      "rtnqty": "Số lượng bị hoàn trả",
      "inbatchno": "Số lô nhập liệu ",
      "contact_window": "Cửa sổ nghiệp vụ khách hàng ",
      "note": "Ghi chú nhận trả hàng",
      "addr": "Địa chỉ liên lạc ",
      "itemNM": "Ghi chú cửa sổ",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án",
    },
    "ap-manage": {
      "pageHeader": " Quản lý khoản phải thu ",
      "rcv": "Tìm kiếm khoản phải thu ",
      "rcvData": "Dữ liệu phải thu ",
      "rcv_drawer_title": "Tệp chính khoản phải thu ",
      "inbatchno": "Số lô nhập liệu ",
      "contactWindow": "聯絡窗口資訊",
      "ap_tb": "Phiếu chi tiết sao kê",
      "strike": "Hoạt động cân bằng thu chi ",
      "strike_tb": "Phiếu chi tiết cân bằng thu chi ",
      "unitprice": "Đơn giá phân phối",
      "rcv_inqty": "Số lượng di chuyển ",
      "rcv_itemsum": "Số tiền các mục ",
      "rcv_itemsumtax": "Số tiền thuế các mục ",
      "rcv_subtotal": "Tổng gồm thuế",
      "ap_remove_text":
        "Vui lòng xác nhận dữ liệu đơn sao kê cần xóa không sai xót, rồi tiến hành xóa",
      "strike_remove_text":
        "Vui lòng xác nhận dữ liệu cân bằng thu chi cần xóa không sai xót, rồi tiến hành xóa",
      "ap_total": "Tổng số tiền phải thu bao gồm thuế",
      "ap_itemtotal": "Số tiền phải thu gồm thuế",
      "ap_paytotal": "Số tiền đã cân bằng thu chi ",
      "ap_sumtotal": "Tổng số tiền ",
      "strike_sumtotal": "Tổng số tiền cân bằng thu chi",
      "ap_hubqty": "Số lượng xuất kho ",
      "ap_addr": "Địa chỉ khách hàng ",
      "ap_balance": "Chênh lệch tài khoản",
      "ap_lifeF_0": "Tạo ",
      "ap_lifeF_1": "Đánh giá phải thu",
      "ap_lifeF_2": "Xuất đơn phải thu ",
      "ap_lifeF_3": " Cân bằng thu chi(thu khoản)chưa kết án ",
      "ap_lifeF_T": "結案",
      "ap_contact": "Cửa sổ nghiệp vụ khách hàng",
      "ap_itemNM": "Ghi chú cửa sổ",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "現金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "Ghi chú cân bằng thu chi ",
      "confirmStrike": "Xác nhận cân bằng thu chi ",
      "apCheck_confirm_text":
        "Sắp bước vào hoạt động đánh giá phải thu, bạn có muốn lưu dữ liệu phải thu không?",
      "releaseAP_confirm_text":
        "Sắp vào bước xác nhận chứng từ phải thu, bạn muốn lưu lại dữ liệu của chứng từ phải thu?",
      "strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá không sai xót, rồi tiến hành đánh giá ",
      "cancel_strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá ",
      "confirmStrike_confirm_text":
        "Sau khi xác nhận cân bằng thu chi ở đây  đơn cân bằng thu chi sẽ bị đóng,. Vui lòng xác nhận bạn có muốn cân bằng thu chi không?",
    },
  },
  "BPM": {
    "util": {
      "signmode": "簽核層級",
      "level": "階",
      "opfunction_1": "訂單系統",
      "opfunction_2": "採購系統",
      "opfunction_3": "生產系統",
      "opfunction_4": "庫儲系統",
      "opfunction_5": "財務系統",
      "optype_11": "訂單專案",
      "optype_12": "採購專案",
      "optype_40": "庫儲專案",
      "optype_41": "出庫專案",
      "permissions": "權限",
      "open": "立案",
      "check": "查權",
      "review": "審權",
      "approve": "核權",
    },
    "sign-level": {
      "pageHeader": "簽核權限設定",
      "opfunction": "營運功能",
      "optype": "作業類別",
    },
    "dsevent": {
      "system": "系統模組",
      "keynote": "主旨",
    },
  },
  "PDS": {
    "util": {
      "wlID": "Loại chuyền ",
    },
    "wpc-search": {
      "hdC": "高峰人數",
      "issueday": "發佈時間",
      "lifeF_0": "已發佈",
      "lifeF_1": "已登錄",
      "lifeF_2": "生產中",
      "lifeF_3": "報停工",
      "lifeF_4": "報復工",
      "lifeF_7": "報完工",
      "lifeF_B": "報開工",
      "lifeF_G": "過站",
      "lifeF_L": "物控覆核",
      "lifeF_P": "質控合格",
      "lifeF_aps": "生管發佈",
      "linemix": "混線",
      "pageHeader": "工站途單查詢",
      "wlID": "線別",
    },
    "overview-lines": {
      "pageHeader": "線體稼動總表",
      "act": "稼動率",
      "shiftD": "日班",
      "shiftN": "夜班",
      "allDay": "全天",
    },
    "dashboard": {
      "pageHeader": " Kanban sản xuất kỹ thuật số",
    },
    "abc-board": {
      "pageHeader": "Kanban quản lý bất thường",
    },
  },
};

export default vi_VN;
