import { Button, DatePicker, Select, Space, message } from "antd";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconExcelDownload } from "@/components/Icon/Action";
import { SearchButton } from "@/components/PDS/Buttons";
import useAPI from "@/hooks/useAPI";
import { getPWC } from "@/service/apis/ADM/publicAPI";
import { createWhmData, getWhReport } from "@/service/apis/WHM/Performance";
import { CalculatorOutlined } from "@ant-design/icons";

const Car = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [s_searchData, set_s_searchData] = useState({
    date: dayjs().format("YYYY-MM-DD"),
    pwcID: " ",
  });
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const call_getWhReport = useAPI(getWhReport);
  const call_createWhmData = useAPI(createWhmData);
  const call_getPWC = useAPI(getPWC);

  const cellProps = (fieldName, record, index, s_tableData) => {
    // 如果是第一行，或者当前行的指定字段的值不等于上一行的值，或者"SN"字段的值不同
    if (
      index === 0 ||
      s_tableData[index - 1][fieldName] !== record[fieldName] ||
      s_tableData[index - 1]["SN"] !== record["SN"]
    ) {
      // 寻找接下来的行中有多少行与当前行的指定字段和"SN"字段的值相同
      let rowSpan = 1;
      for (let i = index + 1; i < s_tableData.length; i++) {
        if (
          s_tableData[i][fieldName] === record[fieldName] &&
          s_tableData[i]["SN"] === record["SN"]
        ) {
          rowSpan++;
        } else {
          break;
        }
      }
      return {
        rowSpan: rowSpan,
      };
    } else {
      // 如果当前行的指定字段的值等于上一行的值，并且"SN"字段的值也相同，则设置rowSpan为0
      return {
        rowSpan: 0,
      };
    }
  };

  const exportExcel = async (columns, data, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    worksheet.columns = columns.reduce((data, x) => {
      x.isExcel &&
        data.push({
          header: x.title,
          key: x.dataIndex,
          width: x.excelWidth || 10,
          style: {
            // alignment: { vertical: "middle", horizontal: "center" },
            alignment: { vertical: "middle", horizontal: x.align || "left" },
          },
        });
      return data;
    }, []);

    worksheet.addRows(data);

    // 計算當前資料出現間隔
    const colLenArr = s_tableData.reduce((data, curr) => {
      const index = curr.SN - 1;
      data[index]++;
      return data;
    }, Array(call_getWhReport.data.length).fill(0));

    const mergeRow = ["A", "B", "C", "D", "E", "F", "G", "L"];

    const allRow = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];
    for (const rowKey of allRow) {
      worksheet.getCell(`${rowKey}1`).style = {
        alignment: { vertical: "middle", horizontal: "center" },
      };
    }

    for (const rowKey of mergeRow) {
      let initCol = 2;
      worksheet.getCell(`${rowKey}1`).style = {
        alignment: { vertical: "middle", horizontal: "center" },
      };

      for (const colKey of colLenArr) {
        if (colKey == 1) {
          initCol = initCol + 1;
        } else {
          worksheet.mergeCells(`${rowKey}${initCol}:${rowKey}${colKey + initCol - 1}`);
          initCol = initCol + colKey;
        }
      }
    }

    // worksheet.insertRow(1, {});
    // // 合併第一行
    // worksheet.mergeCells("A1:Q1");
    // // 設定標頭
    // worksheet.getCell("Q1").value = `三次元檢驗報表(${s_searchData.inStartDate.format(
    //   "YYYY-MM-DD HH:mm"
    // )}~${s_searchData.inEndDate.format("YYYY-MM-DD HH:mm")})`;

    // worksheet.getCell("A1").style = {
    //   alignment: { vertical: "middle", horizontal: "center" },
    //   font: { bold: true, size: 18 },
    // };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tableColumns = [
    {
      title: "排序",
      dataIndex: "SN",
      align: "center",
      isExcel: true,
      onCell: (record, index) => cellProps("SN", record, index, s_tableData),
    },
    {
      title: "評比",
      dataIndex: "rate",
      isExcel: true,
      width: 140,
      excelWidth: 17,
      onCell: (record, index) => cellProps("rate", record, index, s_tableData),
    },
    {
      title: "員工姓名",
      dataIndex: "peopleNM",
      isExcel: true,
      onCell: (record, index) => cellProps("peopleNM", record, index, s_tableData),
    },
    {
      title: "生產總數",
      dataIndex: "totalC",
      isExcel: true,
      onCell: (record, index) => cellProps("totalC", record, index, s_tableData),
      align: "right",
    },
    {
      title: "出勤工時",
      dataIndex: "erpwh",
      align: "right",
      isExcel: true,
      onCell: (record, index) => cellProps("erpwh", record, index, s_tableData),
    },
    {
      title: "總標準工時\n(小時)",
      dataIndex: "totalStdwh",
      align: "right",
      isExcel: true,
      excelWidth: 19,
      onCell: (record, index) => cellProps("totalStdwh", record, index, s_tableData),
    },
    {
      title: "有效工時",
      dataIndex: "validwh",
      align: "right",
      isExcel: true,
      onCell: (record, index) => cellProps("validwh", record, index, s_tableData),
    },
    {
      title: "績效",
      dataIndex: "perfP",
      isExcel: true,
      onCell: (record, index) => cellProps("perfP", record, index, s_tableData),
      align: "right",
    },
    {
      title: "製程",
      dataIndex: "pwcID",
      isExcel: true,
      width: 200,
      excelWidth: 20,
    },
    {
      title: "規格/型號",
      dataIndex: "spec",
      isExcel: true,
      width: 200,
      excelWidth: 20,
    },
    {
      title: "生產數量\n(IPCS)",
      dataIndex: "workC",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "是否算績效",
      dataIndex: "ispref",
      align: "center",
      width: 100,
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "標準工時\n(小時)",
      dataIndex: "stdwh",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "實際工時\n(小時)",
      dataIndex: "realwh",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "工單號",
      dataIndex: "woN",
    },
  ];

  const excelColumns = [
    {
      title: "排序",
      dataIndex: "SN",
      align: "center",
      isExcel: true,
      onCell: (record, index) => cellProps("SN", record, index, s_tableData),
    },
    {
      title: "評比",
      dataIndex: "rate",
      isExcel: true,
      width: 140,
      excelWidth: 17,
      onCell: (record, index) => cellProps("rate", record, index, s_tableData),
    },
    {
      title: "員工姓名",
      dataIndex: "peopleNM",
      isExcel: true,
      onCell: (record, index) => cellProps("peopleNM", record, index, s_tableData),
    },
    {
      title: "生產總數",
      dataIndex: "totalC",
      isExcel: true,
      onCell: (record, index) => cellProps("totalC", record, index, s_tableData),
      align: "right",
    },
    {
      title: "出勤工時",
      dataIndex: "erpwh",
      align: "right",
      isExcel: true,
      onCell: (record, index) => cellProps("erpwh", record, index, s_tableData),
    },

    {
      title: "有效工時",
      dataIndex: "validwh",
      align: "right",
      isExcel: true,
      onCell: (record, index) => cellProps("validwh", record, index, s_tableData),
    },
    {
      title: "績效",
      dataIndex: "perfP",
      isExcel: true,
      onCell: (record, index) => cellProps("perfP", record, index, s_tableData),
      align: "right",
    },
    {
      title: "製程",
      dataIndex: "pwcID",
      isExcel: true,
      width: 200,
      excelWidth: 20,
    },
    {
      title: "規格/型號",
      dataIndex: "spec",
      isExcel: true,
      width: 200,
      excelWidth: 20,
    },
    {
      title: "生產數量\n(IPCS)",
      dataIndex: "workC",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "是否算績效",
      dataIndex: "ispref",
      align: "center",
      width: 100,
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "總標準工時\n(小時)",
      dataIndex: "totalStdwh",
      align: "right",
      isExcel: true,
      excelWidth: 19,
      onCell: (record, index) => cellProps("totalStdwh", record, index, s_tableData),
    },
    {
      title: "標準工時\n(小時)",
      dataIndex: "stdwh",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "實際工時\n(小時)",
      dataIndex: "realwh",
      align: "right",
      isExcel: true,
      excelWidth: 17,
    },
    {
      title: "工單號",
      dataIndex: "woN",
    },
  ];

  const onSearch = () => {
    call_getWhReport.request(s_searchData);
  };

  const onCalculate = () => {
    call_createWhmData.request(s_searchData);
  };

  const onExcelDownload = () => {
    exportExcel(excelColumns, s_tableData, `${s_searchData.date}車圈績效報表.xlsx`);
  };

  useEffect(() => {
    call_getPWC.request();
  }, []);

  useEffect(() => {
    onSearch();
  }, [s_searchData.date]);

  useEffect(() => {
    if (call_getWhReport.status === "suc") {
      message.success(call_getWhReport.msg);
      // 首先按照 erpwh 降序排序
      // 根據績效排序
      let sourceData = call_getWhReport.data.sort((a, b) =>
        parseInt(a.erpwh) > parseInt(b.erpwh) ? -1 : 1
      );
      sourceData = sourceData.sort((a, b) => (parseInt(a.perfP) > parseInt(b.perfP) ? -1 : 1));

      //資料組成
      let realData = [];
      for (const [index, x] of sourceData.entries()) {
        if (x.detail.length > 0) {
          for (const item of x.detail) {
            realData.push({ SN: index + 1, ...x, ...item });
          }
        } else {
          realData.push({ SN: index + 1, ...x });
        }
      }
      // 重新整理資料 & 排序
      realData = realData.map((x, i) => ({
        ...x,
        key: i,
        perfP: parseFloat(x.perfP).toFixed(2) + "%",
        ispref: x.ispref ? "Y" : "N",
        rate: Array(x.rate)
          .fill(x.star ? "\u2606 " : "X")
          .join(""),
      }));

      set_s_tableData(realData);
    } else if (call_getWhReport.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getWhReport.msg}`));
    }
  }, [call_getWhReport.status]);

  useEffect(() => {
    if (call_createWhmData.status === "suc") {
      message.success(call_createWhmData.msg);
      onSearch();
    } else if (call_createWhmData.status === "err") {
      message.error(t(`error.error-code.${call_createWhmData.msg}`));
      onSearch();
    }
  }, [call_createWhmData.status]);

  return (
    <>
      <Space className="flex justify-end">
        <Select
          showSearch
          className="w-[250px]"
          value={s_searchData.pwcID}
          optionFilterProp="label"
          options={
            call_getPWC?.data?.reduce(
              (data, curr) => {
                data.push({ label: curr.pwcID + "_" + curr.pwcNM, value: curr.pwcID });
                return data;
              },
              [{ label: "全部", value: " " }]
            ) || [{ label: "全部", value: " " }]
          }
          onChange={(value) => set_s_searchData((prev) => ({ ...prev, pwcID: value }))}
        />
        <DatePicker
          value={dayjs(s_searchData.date)}
          onChange={(e) =>
            set_s_searchData((prev) => ({
              ...prev,
              date: e ? dayjs(e).format("YYYY-MM-DD") : prev.date,
            }))
          }
          placeholder="請選擇日期"
        />
        <SearchButton type="primary" key="search" onClick={onSearch} />
        <Button
          danger
          icon={<CalculatorOutlined />}
          type="primary"
          key="Calculate"
          onClick={onCalculate}
          loading={call_createWhmData.status === "load"}
        >
          重新計算
        </Button>
        <IconExcelDownload className="text-[45px]" onClick={onExcelDownload} />
      </Space>

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        c_lightData={s_editData}
        loading={call_getWhReport.status === "load"}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
    </>
  );
});

export default Car;
