import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

import AuthManage from "./AuthManage";

export default function CategoryManage() {
  const { t } = useTranslation();
  // 利用ref 子傳父
  const authManage_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    pageType: "75",
    item: null,
  });
  const [s_tabKey, set_s_tabKey] = useState("mtype"); // 目前是在哪個Tab上

  const resetSearchData = (type) => {
    set_s_searchData((prev) => ({
      ...prev,
      pageType: type,
      item: undefined,
    }));
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    authManage_ref.current.onSearch();
  };
  const onCreate = () => {
    authManage_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "mtype",
      label: t("ADM.category-manage.tabMtype"),
      pageType: "75",
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "pdt",
      label: t("ADM.category-manage.tabQuery"),
      pageType: "74",
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "supp",
      label: t("ADM.category-manage.tabSupp"),
      pageType: "72",
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "cust",
      label: t("ADM.category-manage.tabCust"),
      pageType: "73",
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];
  console.log(1);
  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("item", e.target.value)}
      placeholder={t("util.util.search") + t("ADM.category-manage.mgmtitem")}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.util.add")}
    // </Button>,
    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.category-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          const getPageType = tabsData.filter((x) => x.key === activeKey);
          resetSearchData(getPageType[0].pageType);
        }}
      />
    </>
  );
}
