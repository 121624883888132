import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

// 新增停工項目
export const addPause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPause`, data);
};
// 取得停工項目
export const getPause = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPause`, {
    params: data,
  });
};
// 更新停工項目
export const updatePause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePause`, data);
};
// 刪除停工項目
export const deletePause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePause`, data);
};

// ===============================================================================
// 新增良品項目
export const addOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOkItem`, data);
};
// 取得良品項目
export const getOkItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOkItem`, {
    params: data,
  });
};
// 更新良品項目
export const updateOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOkItem`, data);
};
// 刪除良品項目
export const deleteOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOkItem`, data);
};

// ===============================================================================
// 新增不良品項目
export const addNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addNgItem`, data);
};
// 取得不良品項目
export const getNgItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getNgItem`, {
    params: data,
  });
};
// 更新不良品項目
export const updateNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateNgItem`, data);
};
// 刪除不良品項目
export const deleteNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteNgItem`, data);
};

// ===============================================================================
// 新增責任單位
export const addDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDri`, data);
};
// 取得責任單位
export const getDri = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDri`, {
    params: data,
  });
};
// 更新責任單位
export const updateDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDri`, data);
};
// 刪除責任單位
export const deleteDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDri`, data);
};

// ===============================================================================
// 新增下線項目
export const addOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOffline`, data);
};
// 取得下線項目
export const getOffline = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOffline`, {
    params: data,
  });
};
// 更新下線項目
export const updateOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOffline`, data);
};
// 刪除下線項目
export const deleteOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOffline`, data);
};
