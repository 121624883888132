import { useState, useRef, useEffect } from "react";
import { Input, Space } from "antd";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";

import Air from "./Air";
import Tech from "./Tech";
import Wl from "./Wl";
import Feature from "./Feature";
import ComponentFeature from "./ComponentFeature";

const Sheet = () => {
  const air_ref = useRef(null);
  const tech_ref = useRef(null);
  const wl_ref = useRef(null);
  const feature_ref = useRef(null);
  const componentFeature_ref = useRef(null);

  const [s_tabKey, set_s_tabKey] = useState("air"); // 目前是在哪個Tab上
  const [s_searchData, set_s_searchData] = useState({
    PN: undefined,
  });

  const tabsData = [
    {
      key: "air",
      label: "航太廠",
      children: <Air ref={air_ref} s_searchData={s_searchData} />,
    },
    {
      key: "tech",
      label: "科技廠",
      children: <Tech ref={tech_ref} />,
    },
    {
      key: "wl",
      label: "機碼",
      children: <Wl ref={wl_ref} />,
    },
    {
      key: "feature",
      label: "料號特性",
      children: <Feature ref={feature_ref} />,
    },
    {
      key: "componentFeature",
      label: "元件特性",
      children: <ComponentFeature ref={componentFeature_ref} />,
    },
  ];

  const onCreate = () => {
    s_tabKey === "air" && air_ref.current.onCreate();
    s_tabKey === "tech" && tech_ref.current.onCreate();
    s_tabKey === "wl" && wl_ref.current.onCreate();
    s_tabKey === "feature" && feature_ref.current.onCreate();
    s_tabKey === "componentFeature" && componentFeature_ref.current.onCreate();
  };
  const onSearch = () => {
    s_tabKey === "air" && air_ref.current.onSearch();
  };

  const Air_Search = (
    <Space>
      <Input
        value={s_searchData.PN}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, PN: e.target.value }))}
        placeholder="請輸入料號"
        key="PN"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
    </Space>
  );

  const pageHeaderExtra = [s_tabKey === "air" && Air_Search];

  return (
    <>
      <PageHeader
        title="客製表格維護"
        extra={[...pageHeaderExtra, <AddButton type="primary" key="create" onClick={onCreate} />]}
      />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          set_s_searchData({
            PN: undefined,
          });
        }}
      />
    </>
  );
};

export default Sheet;
