import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  InputNumber,
  message,
  Radio,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";

import { addCost, updateCost } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";
import dayjs from "dayjs";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addCost = useAPI(addCost);
  const call_updateCost = useAPI(updateCost);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addCost.request(postData);
    }

    if (type === "edit") {
      call_updateCost.request(postData);
    }
  };

  useEffect(() => {
    if (call_addCost.status === "suc") {
      message.success(call_addCost.msg);
      set_s_isShowModal(false);
    } else if (call_addCost.status === "err") {
      message.error(t(`error.error-code.${call_addCost.msg}`));
    }
  }, [call_addCost.status]);

  useEffect(() => {
    if (call_updateCost.status === "suc") {
      message.success(call_updateCost.msg);
      set_s_isShowModal(false);
    } else if (call_updateCost.status === "err") {
      message.error(t(`error.error-code.${call_updateCost.msg}`));
    }
  }, [call_updateCost.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              ...c_lightData,
              stdcost: 0,
              varcost: 0,
              inititalcost: 0,
              purchaseqty: 0,
              purchasecost: 0,
              note: "",
            }
          : { ...s_editData, settlemonth: dayjs(s_editData.settlemonth) }
      }
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label={t("util.util.PN")}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="stdcost" label={t("ADM.pn-manage.stdcost")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="varcost" label={t("ADM.pn-manage.varcost")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="inititalcost" label={t("ADM.pn-manage.inititalcost")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchaseqty" label={t("ADM.pn-manage.purchaseqty")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="purchasecost" label={t("ADM.pn-manage.purchasecost")}>
            <InputNumber className="w-full" min={0} initialValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("ADM.pn-manage.settlemonth")} name="settlemonth">
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
