import { Table } from "antd";
import styled from "styled-components";
import { fontSize } from "@/styles/BasicSetting";

const CustomTableStyle = styled(Table)`
  border-radius: 8px !important;
  /* border: 1px solid #bcbcbc !important; */
  /* table 表投靠中 */
  .ant-table-thead > tr > th {
    text-align: center !important;
    background-color: #f3f4ff;
    border: none;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: ${fontSize.h5};
    white-space: nowrap;
    font-weight: 400;
  }
  .ant-table-cell {
    font-size: ${fontSize.h6};
    font-weight: 600;
    /* background-color: red; */
    /* border: none; */
    border-bottom: 1px solid #bcbcbc !important;
    border-right: none !important;
  }
  .ant-table {
    border-radius: 8px !important;
    border: 1px solid #bcbcbc !important;
  }

  /* // table 顯示頁碼的地方 */
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.h4};
  }

  /* // table pagination center */
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    // border-right: 1px solid #c4c4c4;
    border-right: none;
  }

  /* pagination 靠左 */
  /* .ant-pagination-total-text {
    flex: 1;
  } */

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    /* border: none !important; */
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: none !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    ):before {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .ant-table-header {
    border-bottom: 1px solid #bcbcbc !important;
  }
  .once {
    background-color: #f7f7f7 !important;
  }
  .twoe {
    background-color: #ffffff !important;
  }

  .clickRowStyle {
    background-color: #dde2ff !important;
  }

  .ant-table-row ant-table-row-level-0 clickRowStyle {
    background-color: #dde2ff !important;
  }
  &:hover .clickRowStyle .ant-table-cell {
    background-color: #dde2ff !important;
  }
`;

const Qclist = (props) => {
  return <CustomTableStyle {...props} />;
};

export default Qclist;
