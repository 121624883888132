import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getClass, deleteClass } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer";

const ComponentFeature = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getFeature = useAPI(getClass);
  const call_deleteFeature = useAPI(deleteClass);

  useImperativeHandle(ref, () => ({
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteFeature.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getFeature.status === "suc") {
      message.success(call_getFeature.msg);
      set_s_tableData(
        call_getFeature.data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getFeature.status === "err") {
      message.error(t(`error.error-code.${call_getFeature.msg}`));
      set_s_tableData([]);
    }
  }, [call_getFeature.status]);

  useEffect(() => {
    if (call_deleteFeature.status === "suc") {
      message.success(call_deleteFeature.msg);
      // delete之後 重call表格資料
      call_getFeature.request({
        class: "B010",
        atnam: "",
      });
    } else if (call_deleteFeature.status === "err") {
      message.error(t(`error.error-code.${call_deleteFeature.msg}`));
      call_getFeature.request({
        class: "B010",
        atnam: "",
      });
    }
  }, [call_deleteFeature.status]);

  useEffect(() => {
    call_getFeature.request({
      class: "B010",
      atnam: "",
    });
  }, []);

  const tableColumns = [
    {
      title: "群組碼",
      dataIndex: "class",
      align: "center",
      // width: "4%",
    },
    {
      title: "特性代碼",
      dataIndex: "atnam",
      align: "center",
      // width: "4%",
    },
    {
      title: "特性說明",
      dataIndex: "atbez",
      align: "left",
      // width: "4%",
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          {/* <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          /> */}
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getFeature.status === "load"}
        s_editData={s_editData}
        indentSize={50}
      />

      <CustomModal
        title={Object.keys(s_editData).length > 0 ? "編輯機種特性" : "新增機種特性"}
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getFeature.request({
            class: "B010",
            atnam: "",
          });
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default ComponentFeature;
