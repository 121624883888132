import styled from "styled-components";
import { theme } from "antd";

const { useToken } = theme;

const Light = (props) => {
  // , { className, type, size = "18px", disabled = false }
  const { token } = useToken();
  console.log("type = ", props.type);
  return (
    <LightStyle token={token} size={props.size || "18px" || token.fontSize} {...props}>
      <span className="badge-status-dot badge-status-processing" />
    </LightStyle>
  );
};

/**
 * @param {string} type "main , error , warning , success"
 * @param {string} size "default 18px , input any px"
 * @param {Bool} disabled - description
 */

const LightStyle = styled.span`
  .badge-status-dot {
    position: relative;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border: ${(props) => props.type === "white" && "1px solid rgb(161 161 170)"};
    background-color: ${(props) => {
      return props.type === "white"
        ? props.token.colorWhite
        : props.disabled
        ? props.token.colorWhite
        : props.type === "main"
        ? props.token.colorPrimary
        : props.type === "error"
        ? props.token.colorError
        : props.type === "warning"
        ? props.token.colorWarning
        : props.type === "success"
        ? props.token.colorSuccess
        : props.token.colorPrimary;
    }};
  }
  .badge-status-processing {
    top: -1px;
    display: ${(props) => (props.disabled ? "none" : "inline-block")};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    vertical-align: middle;
    border-radius: 50%;
  }
  .badge-status-dot:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => {
      return props.type === "white"
        ? "none"
        : props.type === "main"
        ? props.token.colorPrimary
        : props.type === "error"
        ? props.token.colorError
        : props.type === "warning"
        ? props.token.colorWarning
        : props.type === "success"
        ? props.token.colorSuccess
        : props.token.colorPrimary;
    }};
    border: ${(props) => {
      return `1px solid ${
        props.type === "white"
          ? "none"
          : props.type === "main"
          ? props.token.colorPrimary
          : props.type === "error"
          ? props.token.colorError
          : props.type === "warning"
          ? props.token.colorWarning
          : props.type === "success"
          ? props.token.colorSuccess
          : props.token.colorPrimary
      }`;
    }};
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    content: "";
  }

  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.4);
      opacity: 0;
    }
  }
`;

export default Light;
