import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, message, Space, Upload, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { UploadOutlined } from "@ant-design/icons";

import { addDeviceDoc } from "@/service/apis/ADM/measurementEquipment";

import useAPI from "@/hooks/useAPI";

const { Link } = Typography;
export default function ModalConintaer({ s_editData, set_s_editData, set_s_showModal }) {
  const { t } = useTranslation();

  const call_addDeviceDoc = useAPI(addDeviceDoc);

  const drawLink = s_editData.draw.split("/") || null;
  const picLink = s_editData.pic.split("/") || null;
  const manualLink = s_editData.manual.split("/") || null;
  console.log(drawLink, picLink, manualLink);
  // const [s_doc,set_s_doc]=useState(null);

  const handleChange = (props) => {
    const { file, doctype } = props;
    // 刪除

    // 新建
    let formData = new FormData();
    formData.append("file", file);
    formData.append("assetID", s_editData.assetID);
    formData.append("assetNM", s_editData.assetNM);

    formData.append("type", doctype);

    call_addDeviceDoc.request(formData);
  };

  useEffect(() => {
    if (call_addDeviceDoc.status === "suc") {
      message.success(call_addDeviceDoc.msg);
      // set_s_showModal(false);
    } else if (call_addDeviceDoc.status === "err") {
      message.error(call_addDeviceDoc.msg);
    }
  }, [call_addDeviceDoc.status]);
  console.log(s_editData);
  return (
    <Row gutter={[24, 12]}>
      <Col span={24}>
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Upload
              // disabled={Object.keys(s_editData).length > 0}
              listType="picture"
              beforeUpload={() => false}
              onChange={(props) => handleChange({ ...props, doctype: "draw" })}
            >
              <Button
                icon={<UploadOutlined />}
                size="large"
                style={{ width: "250px" }}

                // disabled={Object.keys(s_editData).length > 0}
              >
                {"資產圖面" + t("ADM.edm.Upload")}
              </Button>
            </Upload>
            <Link
            onClick={() => {
              window.open(s_editData.draw, "_block");
            }}
          >
           
            {drawLink[drawLink.length - 1]}
          </Link>
          </Col>
          <Col span={8}>
            <Upload
              listType="picture"
              beforeUpload={() => false}
              onChange={(props) => handleChange({ ...props, doctype: "pic" })}
            >
              <Button
                icon={<UploadOutlined />}
                size="large"
                style={{ width: "250px" }}
                // disabled={Object.keys(s_editData).length > 0}
              >
                {"資產照片" + t("ADM.edm.Upload")}
              </Button>
            </Upload>
            <Link
            onClick={() => {
              window.open(s_editData.pic, "_block");
            }}
          >
           
            {picLink[picLink.length - 1]}
          </Link>
          </Col>
          <Col span={8}>
            <Upload
              listType="picture"
              beforeUpload={() => false}
              // disabled={Object.keys(s_editData).length > 0}
              onChange={(props) => handleChange({ ...props, doctype: "manual" })}
            >
              <Button
                icon={<UploadOutlined />}
                size="large"
                style={{ width: "250px" }}
                // disabled={Object.keys(s_editData).length > 0}
              >
                {"設備手冊" + t("ADM.edm.Upload")}
              </Button>
            </Upload>
            <Link
            onClick={() => {
              window.open(s_editData.manualLink, "_block");
            }}
          >
           
            {manualLink[manualLink.length - 1]}
          </Link>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="flex justify-end">
        <Space>
          <Button onClick={() => set_s_showModal(false)}>{t("util.util.close")}</Button>
        </Space>
      </Col>
    </Row>
  );
}
