import "./index.css";

import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Table, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

import useAPI from "@/hooks/useAPI";
import { getWpcHd, getWpcTb } from "@/service/apis/ADM/edm";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addQmsSopHd, copyQmsSop, updateQmsSopHd } from "@/service/apis/QMS/QMSManage";
import { MenuOutlined } from "@ant-design/icons";

const innerWidth = window.innerWidth;

const CustomForm = styled(Form)`
  .ant-input,
  .ant-picker,
  .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto;
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color: green;
  }

  .row-dragging td {
    padding: 16px;
    color: pink;
  }

  .row-dragging .drag-visible {
    visibility: visible;
    color: red;
  }
`;
const StyleTable = styled(Table)`
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }

  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  // 勾勾的顏色
  .successIcon {
    color: ${({ theme }) => theme.Color1};
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: 0.5px solid rgb(217, 217, 217);
    border-left: 0.5px solid rgb(217, 217, 217);
    border-right: 0.5px solid rgb(217, 217, 217);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 100px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .checkIcon {
    text-align: center;
    font-size: 20px;
  }

  /* .checkIcon > * {
    color: ${({ theme }) => theme.Color3};
  } */

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${innerWidth <= 1440 ? "12px" : "16px"};
    white-space: nowrap;
    background-color: #edf2f6;
  }
  // .ant-table-thead {
  //   background-color:#fff8d5;
  // }

  .center {
    text-align: center;
  }

  .Icon {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }
  .clickRowStyle {
    background-color: rgba(253, 255, 203, 0.5);
  }

  .row-dragging {
    background: red;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#CCC", fontSize: "25px" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const StandardModalContainer = ({ s_editData, set_s_editData, set_s_showModal, s_arrMap }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const f_PN = Form.useWatch("PN", form);
  const f_soptype = Form.useWatch("soptype", form);
  const f_ctrlspot = Form.useWatch("ctrlspot", form);
  const f_spotID = Form.useWatch("spotID", form);
  const f_assyno = Form.useWatch("assyno", form);
  const f_inspno = Form.useWatch("inspno", form);
  const f_packno = Form.useWatch("packno", form);
  const [s_ctrlspotOptions, set_s_ctrlspotOptions] = useState([]); //
  const [s_isCopy, set_s_isCopy] = useState(false); //
  const [s_pnOptions, set_s_pnOptions] = useState([]); // 料號

  const call_getProduct = useAPI(getProductSelect);
  const call_copyQmsSop = useAPI(copyQmsSop);
  const call_addQmsSopHd = useAPI(addQmsSopHd);
  const call_updateQmsSopHd = useAPI(updateQmsSopHd);
  const call_getWpcHd = useAPI(getWpcHd);
  const call_getWpcTb = useAPI(getWpcTb);

  const [s_tableData, set_s_tableData] = useState([{ itemno: 1, assyno: 1, inspno: 0, packno: 0 }]);

  const tableCol = [
    {
      title: "排序",
      dataIndex: "sort",
      width: 30,
      classopnoame: "drag-visible",
      align: "center",
      render: () => Object.keys(s_editData).length === 0 && <DragHandle />,
    },
    {
      title: "工位控程",
      dataIndex: "itemno",
      align: "center",
      onCell: (record) => {
        return {
          style: {
            color: "#0000FF",
          },
        };
      },
    },
    { title: "組裝序", dataIndex: "assyno", align: "center" },
    { title: "檢測序", dataIndex: "inspno", align: "center" },
    { title: "包裝序", dataIndex: "packno", align: "center" },
  ];

  const wpcTableCol = [
    {
      title: "途程序",
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      onCell: (record) => {
        return {
          style: {
            color: "#0000FF",
          },
        };
      },
    },
    {
      title: t("ADM.edm.pfNM"),
      dataIndex: "pfID",
      align: "left",
      render: (_, rowData) => `${rowData.pfID}_${rowData.pfNM}`,
    },
    {
      title: t("util.util.ws"),
      dataIndex: "wsID",
      align: "left",
      render: (_, rowData) => `${rowData.wsID}_${rowData.wsNM}`,
    },
    {
      title: t("ADM.util.pwc"),
      dataIndex: "pwcID",
      lign: "left",
      render: (_, rowData) => `${rowData.pwcID}_${rowData.pwcNM}`,
    },
  ];

  // 拖曳結束時更新資料順序，並重新排序itemno
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // 更改陣列順序
    function arrayMoveMutable(array, fromIndex, toIndex) {
      const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

      if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

        const [item] = array.splice(fromIndex, 1);
        array.splice(endIndex, 0, item);
      }
    }

    // 更改陣列順序
    function arrayMoveImmutable(array, fromIndex, toIndex) {
      array = [...array];
      arrayMoveMutable(array, fromIndex, toIndex);

      const tmpArray = array.map((item, index) => {
        return {
          ...item,
          wpcitemno: (index + 1).toString(),
          nextitemno: array.length === index + 1 ? "T" : (index + 2).toString(),
          wsID: item.wsID ? item.wsID : "WS01",
        };
      });

      return tmpArray;
    }

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(s_tableData), oldIndex, newIndex).filter(
        (el) => !!el
      );
      const newData1 = newData.map((item, index) => {
        return {
          ...item,
          key: index.toString(),
          index: index,
          // sopno: index + 1,
          itemno: index + 1,
        };
      });
      set_s_tableData(newData1);
    }
  };

  const DraggableContainer = (props) => {
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const DraggableBodyRow = ({ classopnoame, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = s_tableData.findIndex((x) => x.key === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  // 點擊確定送出時執行
  const onSubmit = async (values) => {
    const type = s_isCopy ? "copy" : Object.keys(s_editData).length === 0 ? "create" : "update";

    // 只在新增時使用
    const tmpValues = {
      hd: values,
      tb: s_tableData,
    };

    const updateVal = { ...values };

    // 據此條目創建
    if (type === "copy") {
      call_copyQmsSop.request(tmpValues);
    }

    // 建立
    if (type === "create") {
      call_addQmsSopHd.request(tmpValues);
    }

    // 修改
    if (type === "update") {
      const updateValues = Object.assign(updateVal, {
        sopID: s_editData.sopID,
        version: s_editData.version,
      });

      call_updateQmsSopHd.request(updateValues);
    }
  };

  // 據此條目創建
  const copy = () => {
    const template = { ...s_editData };

    // 清空被編輯的資料 視為新增
    set_s_editData({});

    // 塞資料
    form.setFieldsValue({
      PN: template.PN,
      pdtNM: template.pdtNM,
      isvalid: template.isvalid,
      assyno: template.assyno,
      inspno: template.inspno,
      packno: template.packno,
      sopno: template.sopno,
      note: null,
    });

    set_s_isCopy(true);
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  // 第一次渲染取得料號、ws、wl、pf、pwc 下拉選單
  useEffect(() => {
    call_getProduct.request();
  }, []);

  useEffect(() => {
    const product = call_getProduct.data?.find((x) => x.PN === f_PN);
    if (!product) return;

    form.setFieldsValue({
      pdtNM: product.pdtNM,
    });
  }, [f_PN]);

  useEffect(() => {
    if (f_soptype === "OPC") {
      // 表頭
      form.setFieldsValue({
        sopno: f_assyno + f_inspno + f_packno,
      });

      // 表身
      let tableArr = [];

      for (let i = 1; i <= f_assyno; i++) {
        const singleData = s_tableData.filter((item) => item.assyno === i);

        if (singleData.length > 0) {
          tableArr.push(singleData[0]);
        } else {
          tableArr.push({ assyno: i, inspno: 0, packno: 0 });
        }
      }
      for (let i = 1; i <= f_inspno; i++) {
        const singleData = s_tableData.filter((item) => item.inspno === i);

        if (singleData.length > 0) {
          tableArr.push(singleData[0]);
        } else {
          tableArr.push({ assyno: 0, inspno: i, packno: 0 });
        }
      }
      for (let i = 1; i <= f_packno; i++) {
        const singleData = s_tableData.filter((item) => item.packno === i);

        if (singleData.length > 0) {
          tableArr.push(singleData[0]);
        } else {
          tableArr.push({ assyno: 0, inspno: 0, packno: i });
        }
      }

      tableArr = tableArr.map((x, i) => {
        return { ...x, key: i.toString(), itemno: i + 1 };
      });
      set_s_tableData(tableArr);
    }
  }, [f_assyno, f_inspno, f_packno]);

  useEffect(() => {
    if (f_soptype === "OPE") {
      // 表頭
      form.setFieldsValue({
        sopno: 1,
        assyno: 1,
        inspno: 0,
        packno: 0,
      });
      // 表身
      set_s_tableData([{ itemno: 1, assyno: 1, inspno: 0, packno: 0 }]);
    }
    if (f_soptype === "WPC") {
      // 表頭
      form.setFieldsValue({
        ctrlspot: "wpc",
      });
    } else if (Object.keys(s_editData).length === 0) {
      // 表頭
      form.setFieldsValue({
        ctrlspot: null,
      });
    }
  }, [f_soptype]);

  useEffect(() => {
    if (Object.keys(s_editData).length === 0) {
      form.setFieldsValue({
        spotID: null,
      });
    }
  }, [f_ctrlspot]);

  useEffect(() => {
    if (f_soptype === "WPC" && Object.keys(s_editData).length === 0) {
      call_getWpcHd.request({ PN: f_PN });
      form.setFieldsValue({
        spotID: null,
      });
    }
  }, [f_PN, f_soptype]);

  useEffect(() => {
    if (f_soptype === "WPC") {
      call_getWpcTb.request({ PN: f_PN, wpcset: f_spotID, sop: true });
    }
  }, [f_spotID]);

  useEffect(() => {
    if (call_getProduct.status === "suc") {
      set_s_pnOptions([
        { label: "ALL", value: "all" },
        ...call_getProduct.data?.map((x) => ({ label: x.PN, value: x.PN })),
      ]);
    } else if (call_getProduct.status === "err") {
      set_s_pnOptions([]);
    }
  }, [call_getProduct.status]);

  useEffect(() => {
    if (call_getWpcHd.status === "suc") {
      set_s_ctrlspotOptions(
        call_getWpcHd.data?.map((x) => ({ label: x.wpcset + "_" + x.wpcsetNM, value: x.wpcset }))
      );
    } else if (call_getWpcHd.status === "err") {
      set_s_ctrlspotOptions([]);
    }
  }, [call_getWpcHd.status]);

  useEffect(() => {
    if (call_getWpcTb.status === "suc") {
      set_s_tableData(
        call_getWpcTb.data.map((item) => ({
          ...item,
          itemno: parseInt(item.wpcSN),
          assyno: item.wpcSN,
        }))
      );
      form.setFieldsValue({
        sopno: call_getWpcTb.data.length,
        assyno: call_getWpcTb.data.length,
      });
    }
  }, [call_getWpcTb.status]);

  useEffect(() => {
    if (call_addQmsSopHd.status === "suc") {
      message.success(call_addQmsSopHd.msg);
      set_s_showModal(false);
    } else if (call_addQmsSopHd.status === "err") {
      message.error(call_addQmsSopHd.msg);
    }
  }, [call_addQmsSopHd.status]);

  useEffect(() => {
    if (call_updateQmsSopHd.status === "suc") {
      message.success(call_updateQmsSopHd.msg);
      set_s_showModal(false);
    } else if (call_updateQmsSopHd.status === "err") {
      message.error(call_updateQmsSopHd.msg);
    }
  }, [call_updateQmsSopHd.status]);

  useEffect(() => {
    if (call_copyQmsSop.status === "suc") {
      message.success(call_copyQmsSop.msg);
      set_s_showModal(false);
    } else if (call_copyQmsSop.status === "err") {
      message.error(call_copyQmsSop.msg);
    }
  }, [call_copyQmsSop.status]);

  // 卸載時清除被編輯的資料
  useEffect(() => {
    return () => {
      set_s_editData({});
    };
  }, []);

  return (
    <>
      <Form.Provider>
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            isvalid: true,
            assyno: 1,
            inspno: 0,
            packno: 0,
            sopno: 1,
            ...s_editData,
          }}
        >
          <Row gutter={[24, 12]}>
            <Form.Item name="dcUUID" nostyle />

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>SOP ID</label>}
                name="sopID"
              >
                <Input disabled placeholder="系統自動維護" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>版碼</label>}
                name="version"
              >
                <Input disabled placeholder="系統自動維護" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>{t("util.util.note")}</label>}
                name="note"
                // rules={[{ required: true, message: "請選擇是否有效!" }]}
              >
                <Input.TextArea name="note" rows={1} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>料號</label>}
                name="PN"
                rules={[{ required: true, message: "請選擇料號!" }]}
              >
                <Select
                  placeholder="選擇料號"
                  allowClear
                  showSearch
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                  options={s_pnOptions}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>品名</label>}
                name="pdtNM"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>控制型態</label>}
                name="soptype"
                rules={[{ required: true, message: "請選擇控制型態!" }]}
              >
                <Select
                  placeholder="選擇控制型態"
                  allowClear
                  showSearch
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                  options={[
                    { label: "操作型", value: "OPE" },
                    { label: "流控型", value: "OPC" },
                    { label: "途控型", value: "WPC" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>控點</label>}
                name="ctrlspot"
                rules={[{ required: true, message: "請選擇控點!" }]}
              >
                <Select
                  placeholder="選擇控點"
                  allowClear
                  showSearch
                  disabled={!f_soptype || f_soptype === "WPC" || Object.keys(s_editData).length > 0}
                  options={[
                    { label: "WP全品比對", value: "wp" },
                    { label: "WO工單比對", value: "wo" },
                    { label: "PF製程比對", value: "pf" },
                    { label: "WS工站比對", value: "ws" },
                    { label: "WL工線比對", value: "wl" },
                    { label: "WC工作中心比對", value: "pwc" },
                    { label: "QA品管控制比對", value: "qa" },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "#6C6C6C" }}>
                    {f_soptype === "WPC" ? "途程組" : "控點ID"}
                  </label>
                }
                name="spotID"
                rules={[{ required: f_soptype === "WPC", message: "請選擇控點ID!" }]}
              >
                <Select
                  placeholder={f_soptype === "WPC" ? "選擇途程組" : "選擇控點ID"}
                  allowClear
                  showSearch
                  disabled={
                    Object.keys(s_editData).length > 0 ||
                    !f_ctrlspot ||
                    ["wp", "wo", "qa"].includes(f_ctrlspot) ||
                    (f_ctrlspot === "wpc" && !f_PN)
                  }
                  options={s_arrMap[f_ctrlspot] || s_ctrlspotOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>{t("util.util.isvalid")}</label>}
                name="isvalid"
                // rules={[{ required: true, message: "請選擇是否有效!" }]}
              >
                <Radio.Group name="isvalid">
                  <Radio value={true}>{t("util.util.yes")}</Radio>
                  <Radio value={false}>{t("util.util.no")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[24, 12]}
            hidden={f_soptype === "OPE" || (f_soptype === "WPC" && !f_spotID) || !f_soptype}
            // className="bg-[#EDF2F6]"
          >
            <Col span={8}>
              <Form.Item
                label={
                  <label style={{ color: "#6C6C6C" }}>
                    {f_soptype === "WPC" ? "總途程數" : "總工位數"}
                  </label>
                }
                name="sopno"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[24, 12]}
            hidden={f_soptype !== "OPC"}
            // className="bg-[#EDF2F6]"
          >
            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>組裝序</label>}
                name="assyno"
                type="number"
                rules={[
                  { required: true, message: "請輸入組裝序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <InputNumber
                  name="assyno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>檢測序</label>}
                name="inspno"
                rules={[
                  { required: true, message: "請輸入品檢序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                type="number"
              >
                <InputNumber
                  name="inspno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>包裝序</label>}
                name="packno"
                rules={[
                  { required: true, message: "請輸入包裝序!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < 0) {
                        return Promise.reject(new Error("請輸入大於0的數字!"));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                type="number"
              >
                <InputNumber
                  name="packno"
                  style={{ width: "100%" }}
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[24, 12]}
            hidden={f_soptype === "OPE" || (f_soptype === "WPC" && !f_spotID) || !f_soptype}
          >
            <Col span={24} className="px-0">
              <StyleTable
                dataSource={s_tableData}
                columns={f_soptype === "WPC" ? wpcTableCol : tableCol}
                pagination={false}
                rowKey="key"
                className={`editable-row ${f_soptype !== "WPC" ? "bg-[#fff8d5]" : ""}`}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                    // cell: EditableCell,
                  },
                }}
              />
            </Col>

            <Col span={24}>
              <Form.Item
                label={<label style={{ color: "#6C6C6C" }}>{t("util.util.note")}</label>}
                name="note"
              >
                <Input.TextArea name="note" rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            {/* <Col span={18}>
              {Object.keys(s_editData).length > 0 ? (
                <Button
                  size="large"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={copy}
                >
                  據此條目創建
                </Button>
              ) : null}
            </Col> */}
            <Col span={18} />
            <Col span={6}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="large"
                  style={{ marginRight: "20px", borderRadius: "5px" }}
                  onClick={handleCancel}
                >
                  取消
                </Button>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ borderRadius: "5px" }}
                  >
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </CustomForm>
      </Form.Provider>
    </>
  );
};

export default StandardModalContainer;
