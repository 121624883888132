import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getRun20Wh, deleteRun20Wh, countRun20Wh } from "@/service/apis/WHM/CT";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import ModalConintaer from "./ModalContainer";
import dayjs from "dayjs";

const Wheel_Miscellaneous = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const { t } = useTranslation();

  const [is_showModal, set_is_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  const call_getRun20Wh = useAPI(getRun20Wh);
  const call_deleteRun20Wh = useAPI(deleteRun20Wh);
  const call_countRun20Wh = useAPI(countRun20Wh);

  const tableColumns = [
    {
      title: "工作日期",
      dataIndex: "workday",
      align: "center",
      render: (value) => dayjs(value).format("YYYY-MM-DD"),
    },
    {
      title: "工作人員",
      dataIndex: "peopleNM",
      align: "center",
      render: (_, rowData) => rowData.staffID + "_" + rowData.peopleNM,
    },
    {
      title: "工作項目",
      dataIndex: "itemNM",
      align: "center",
      render: (_, rowData) => rowData.pwcID + "_" + rowData.itemNM,
    },
    {
      title: "工作數量",
      dataIndex: "workC",
      align: "right",
    },
    {
      title: "工作時間(小時)",
      dataIndex: "mhC",
      align: "right",
    },
    {
      title: "標準工時(秒)",
      dataIndex: "stdmh",
      align: "right",
    },
    {
      title: "建立時間",
      dataIndex: "createT",
      align: "center",
      render: (value) => dayjs(value).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              e.stopPropagation();
              set_is_showModal(true);
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteRun20Wh.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const onCalculate = () => {
    call_countRun20Wh.request(s_searchData);
  };

  const onSearch = () => {
    call_getRun20Wh.request(s_searchData);
  };
  const onCreate = () => {
    set_is_showModal(true);
    set_s_editData({});
  };

  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: onCreate,
    onCalculate: onCalculate,
  }));

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    if (call_getRun20Wh.status === "suc") {
      set_s_tableData(
        call_getRun20Wh.data.map((x, i) => {
          return { ...x, key: i };
        })
      );
      message.success(call_getRun20Wh.msg);
    } else if (call_getRun20Wh.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getRun20Wh.msg}`));
    }
  }, [call_getRun20Wh.status]);

  useEffect(() => {
    if (call_deleteRun20Wh.status === "suc") {
      message.success(call_deleteRun20Wh.msg);
      onSearch();
    } else if (call_deleteRun20Wh.status === "err") {
      message.error(t(`error.error-code.${call_deleteRun20Wh.msg}`));
      onSearch();
    }
  }, [call_deleteRun20Wh.status]);

  useEffect(() => {
    if (call_countRun20Wh.status === "suc") {
      message.success(call_countRun20Wh.msg);
      onSearch();
    } else if (call_countRun20Wh.status === "err") {
      message.error(t(`error.error-code.${call_countRun20Wh.msg}`));
      onSearch();
    }
  }, [call_countRun20Wh.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        c_lightData={s_editData}
        loading={call_getRun20Wh.status === "load" || call_countRun20Wh.status === "load"}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        width={"60%"}
        open={is_showModal}
        onCancel={() => set_is_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_is_showModal={set_is_showModal}
          s_searchData={s_searchData}
        />
      </CustomModal>
    </>
  );
});

export default Wheel_Miscellaneous;
