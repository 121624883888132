import Icon from "@ant-design/icons";

export default function IconBox(props) {
  return (
    <Icon
      {...props}
      className={
        props.disabled
          ? ` cursor-not-allowed opacity-1 text-xl text-zinc-300${props.className}`
          : ` cursor-pointer text-xl ${props.className}`
      }
      onClick={!props.disabled ? props.onClick : null}
    />
  );
}
