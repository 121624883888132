import { useState, useEffect, useRef } from "react";
import { Row, Col, Space, Button, Form, message } from "antd";
import { ArrowsAltOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";

import useAPI from "@/hooks/useAPI";
import { addModelHd, updateModelHd, getModelTb } from "@/service/apis/LMS/LabelManage";

import FormHD from "./components/FormHD";
import CreateGruup from "./components/CreateGroup";
import DrawingArea from "./components/DrawingArea";
import FormTB from "./components/FormTB";

import SortTb from "./components/ModalContainer/SortTb";

export default function CanvasCreate({ c_lightData, set_s_showModal, set_c_lightData }) {
  const { t } = useTranslation();

  const [form_tb_ref] = Form.useForm();
  const [form_hd_ref] = Form.useForm();
  const drawinArea_ref = useRef(null);
  const tb_ref = useRef(null);

  const [s_copyHD, set_s_copyHD] = useState({});
  const [is_sortTbModal, set_is_sortTbModal] = useState(false);

  const call_addModelHd = useAPI(addModelHd);
  const call_updateModelHd = useAPI(updateModelHd);
  const call_getModelTb = useAPI(getModelTb);

  // 重新繪製畫布
  const renderDraw = () => drawinArea_ref?.current?.renderDraw();

  // 打開所有標籤組的折疊面板
  const openAllTbCollapseKey = async () => {
    // await renderDraw();
    tb_ref?.current?.openAllTbCollapseKey();
  };

  // 打開所有標籤組的折疊面板
  const closeAllTbCollapseKey = async () => {
    // await renderDraw();
    tb_ref?.current?.closeAllTbCollapseKey();
  };

  // 關閉彈窗
  const handleCancel = () => set_s_showModal(false);

  // 打開指定的標籤組折疊面板
  const openTbCollapseKey = (arr) => tb_ref?.current?.openTbCollapseKey(arr);

  // 將標籤設定資料保存到後端資料庫
  const saveLabel = async () => {
    // 等待表單驗證欄位
    let formHdData = await form_hd_ref.validateFields();
    // 取得表單值
    let formTbData = await form_tb_ref.validateFields();

    let VarCount = [];

    // 格式化表單資料以符合後端 API 的要求
    const req = {
      hd: { ...formHdData, encode: "canvas" },
      tb: formTbData.details.map((x, i) => {
        if (x?.keyText?.includes("var")) VarCount.push(x.keyText);
        return {
          ...x,
          itemID: i + 1 + "",
          modelID: formHdData.modelID,
          variable: JSON.stringify(x),
          path: VarCount.join("|"),
        };
      }),
    };
    // 根據 c_lightData 是否為空物件，決定使用 addModelHd 或 updateModelHd API
    if (Object.keys(c_lightData).length === 0) {
      call_addModelHd.request(req);
    } else {
      call_updateModelHd.request(req);
    }
  };

  useEffect(() => {
    // 如果 c_lightData 不為空物件，將其值設定到表單中並呼叫 getModelTb API 獲取標籤資料
    if (Object.keys(c_lightData).length > 0) {
      form_hd_ref.setFieldsValue({ ...c_lightData });

      let copy_s_templateParams = JSON.parse(JSON.stringify(c_lightData));
      delete copy_s_templateParams.printparams;

      call_getModelTb.request(copy_s_templateParams);
    } else {
      renderDraw();
    }
  }, []);

  useEffect(() => {
    // 監聽 getModelTb API 的狀態，如果狀態為成功，則設定表單值並重新繪製畫布，如果狀態為錯誤，則顯示錯誤訊息
    if (call_getModelTb.status === "suc") {
      const formData = call_getModelTb.data.map((x) => {
        let parseData = { ...JSON.parse(x.variable) };
        if (!parseData.orderIndex) {
          parseData["orderIndex"] = 1;
        }
        return parseData;
      });
      form_tb_ref.setFieldsValue({ details: formData });
      renderDraw();
    } else if (call_getModelTb.status === "err") {
      message.error(call_getModelTb.msg);
    }
  }, [call_getModelTb.status]);

  useEffect(() => {
    // 監聽 addModelHd API 的狀態，如果狀態為成功，則關閉彈窗，如果狀態為錯誤，則顯示錯誤訊息
    if (call_addModelHd.status === "suc") {
      handleCancel();
    } else if (call_addModelHd.status === "err") {
      message.error(call_addModelHd.msg);
    }
  }, [call_addModelHd.status]);

  useEffect(() => {
    // 監聽 updateModelHd API 的狀態，如果狀態為成功，則關閉彈窗，如果狀態為錯誤，則顯示錯誤訊息
    if (call_updateModelHd.status === "suc") {
      handleCancel();
    } else if (call_updateModelHd.status === "err") {
      message.error(call_updateModelHd.msg);
    }
  }, [call_updateModelHd.status]);

  return (
    <Row gutter={[0, 12]}>
      <Col span={24} className="bg-[#eff2f5] p-6">
        <FormHD form_hd_ref={form_hd_ref} c_lightData={c_lightData} />
      </Col>
      <Col span={14} className="pl-6 pr-6">
        <CreateGruup form_tb_ref={form_tb_ref} form_hd_ref={form_hd_ref} renderDraw={renderDraw} />
      </Col>
      <Col span={10} className="pl-6 pr-6 flex justify-end">
        <Space>
          <Button
            type="primary"
            size="large"
            icon={<FullscreenExitOutlined />}
            onClick={closeAllTbCollapseKey}
            danger
          />
          <Button
            type="primary"
            size="large"
            icon={<ArrowsAltOutlined />}
            onClick={openAllTbCollapseKey}
          />
          <Button type="primary" size="large" onClick={() => set_is_sortTbModal(true)}>
            重新排序
          </Button>
        </Space>
      </Col>
      <Col span={14} className="pl-6 pr-6">
        <DrawingArea
          openTbCollapseKey={openTbCollapseKey}
          form_tb_ref={form_tb_ref}
          form_hd_ref={form_hd_ref}
          ref={drawinArea_ref}
          renderDraw={renderDraw}
        />
      </Col>
      <Col span={10} className="pr-6">
        <FormTB
          form_tb_ref={form_tb_ref}
          c_lightData={c_lightData}
          ref={tb_ref}
          renderDraw={renderDraw}
        />
      </Col>
      <Col span={24} className="pl-6 pr-6 pb-6 flex justify-between">
        <Space>
          {/* 據此創建 */}
          {Object.keys(s_copyHD).length === 0 ? (
            <Button
              type="primary"
              onClick={() => {
                set_c_lightData({});
                set_s_copyHD(form_hd_ref.getFieldsValue());
                form_hd_ref.setFieldsValue({ modelID: undefined, modelNM: undefined });
              }}
            >
              {t("util.util.header_create")}
            </Button>
          ) : (
            <Button
              type="primary"
              danger
              onClick={() => {
                set_c_lightData(s_copyHD);
                set_s_copyHD({});
                form_hd_ref.setFieldsValue(s_copyHD);
              }}
            >
              {t("util.util.header_cancel_create")}
            </Button>
          )}
        </Space>
        <Space>
          <Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            type="primary"
            onClick={saveLabel}
            loading={call_addModelHd.status === "load" || call_updateModelHd.status === "load"}
          >
            {t("util.util.ok")}
          </Button>
        </Space>
      </Col>

      <CustomModal
        width="40%"
        title="重新排序"
        open={is_sortTbModal}
        centered
        onCancel={() => set_is_sortTbModal(false)}
        afterClose={() => renderDraw()}
      >
        <SortTb set_s_showModal={set_is_sortTbModal} form_tb_ref={form_tb_ref} />
      </CustomModal>
    </Row>
  );
}
