import { Button, Col, Divider, Form, Input, Radio, Row, Select, Space, message } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addInspHd, getInspHd, updateInspHd } from "@/service/apis/QMS/meds";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  s_arrMap,
  set_s_isShowModal,
  arrMap,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const f_group = Form.useWatch("group", form);

  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_addInspHd = useAPI(addInspHd);
  const call_updateInspHd = useAPI(updateInspHd);
  const call_getInspHd = useAPI(getInspHd);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const PN = values.PN?.value ?? values.PN;
    console.log(values);
    const payload = { ...values, PN };

    if (type === "create") {
      console.log(payload);
      call_addInspHd.request(payload);
    }

    if (type === "edit") {
      console.log(payload);
      call_updateInspHd.request(payload);
    }
  };
  const datafmOptions = call_getInspHd.data
    ?.filter((item) => item.group === "from")
    ?.map((data, index) => {
      return {
        label: `${data?.specID} 版本:${data?.version}`,
        value: data?.specID,
        version: data?.version,
        drawing: data?.drawing,
      };
    });
  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then((e) => {
        console.log(e);
        return e?.data.map((x) => {
          console.log(x.pdtspec);

          return (
            {
              label: x.PN,
              value: x.PN,
              title: `${x.pdtNM} | ${x.pdtspec}`,
            } || []
          );
        });
      })
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    call_getInspHd.request();
  }, []);

  useEffect(() => {
    if (call_addInspHd.status === "suc") {
      message.success(call_addInspHd.msg);
      set_s_isShowModal(false);
    }
    if (call_addInspHd.status === "err") {
      message.error(call_addInspHd.msg);
    }
  }, [call_addInspHd.status]);
  useEffect(() => {
    if (call_updateInspHd.status === "suc") {
      message.success(call_updateInspHd.msg);
      set_s_isShowModal(false);
    }
    if (call_updateInspHd.status === "err") {
      message.error(call_updateInspHd.msg);
    }
  }, [call_updateInspHd.status]);
  console.log(s_editData);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
    >
      <div>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="drawing" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label={"spec文編"} name="specID">
            <Input placeholder="系統預設" disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"版次"} name="version">
            <Input placeholder="系統預設" disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="群組" name="group">
            <Select
              className="w-full"
              allowClear
              disabled={type === "edit"}
              options={[
                { label: "工程圖面", value: "draw" },
                { label: "引用規格", value: "from" },
              ]}
              onChange={(value) => {
                console.log(value);
                const resetFields = { PN: null, pdtNM: null, pdtspec: null };
                const groupNMValue = value === "draw" ? "圖面尺寸" : null;
                form.setFieldsValue({ ...resetFields, groupNM: groupNMValue });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={"群組說明"}
            name="groupNM"
            rules={[
              {
                required: f_group === "from",
                message: "请输入内容",
              },
              {
                max: 40,
                message: "最多只能输入40個字符",
              },
            ]}
          >
            <Input disabled={f_group === "draw"} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t("util.util.PN")} name="PN">
            <DebounceSelect
              disabled={type === "edit" || f_group === "from"}
              fetchOptions={fetchPNList}
              maxTagCount={10}
              showSearch
              onChange={(value) => {
                console.log(value);
                form.setFieldsValue({
                  pdtNM: value.title.split("|")[0],
                  pdtspec: value.title.split("|")[1],
                  drawing: "",
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 品名 */}
          <Form.Item label={t("util.util.pdtNM")} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>
        {/* 規格 */}
        <Col span={6}>
          <Form.Item label={t("util.util.pdtspec")} name="pdtspec">
            <Input disabled className="w-full" />
          </Form.Item>
        </Col>

        {/* <Col span={6} /> */}

        <Col span={4} className="flex justify-start">
          <Form.Item label={t("ADM.pn-manage.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="引用檢驗規格" name="datafm">
            <Select
              options={datafmOptions}
              onChange={(value, data) => {
                console.log("tiramisu cake s~~~~~~");
                console.log(value);
                console.log(data);
                form.setFieldsValue({
                  fmversion: data?.version,
                  drawing: data?.drawing,
                });
              }}
              disabled={type === "edit"}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="引用版本" name="fmversion">
            <Input
              options={[]}
              // disabled={f_ctrlrequire === "D" || f_siptype === "SPC" ? false : true}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={1} autoSize={{ minRows: 1 }} />
          </Form.Item>
        </Col>

        <Col span={4}>
          <CreateFromItem
            // afterCopy={() => {
            //   form.setFieldsValue({  drawing: "" });
            // }}
            set_s_editData={set_s_editData}
            s_editData={s_editData}
            form={form}
          />
        </Col>
        <Col span={20} className="flex justify-end">
          <Space>
            <Button
              onClick={() => set_s_isShowModal(false)}
              loading={call_addInspHd.status === "load" || call_updateInspHd.status === "load"}
            >
              {t("util.util.cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addInspHd.status === "load" || call_updateInspHd.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
