import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getErpWh = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/whm/getErpWh`, {
    params: data,
  });
};

export const addErpWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/whm/addErpWh`, data);
};
