import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//停工項目
//----------------------------------------- 取得全部停工資料 ----------------------------------------
export const getPause = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPause`, {
    params: data,
  });
};
//----------------------------------------- 新增停工資料 ----------------------------------------
export const addPause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPause`, data);
};

//----------------------------------------- 編輯停工資料 ----------------------------------------
export const modifyPause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPause`, data);
};

//----------------------------------------- 刪除停工資料 ----------------------------------------
export const deletePause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePause`, data);
};

//良品項目
//----------------------------------------- 取得全部良品資料 ----------------------------------------
export const getOkItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOkItem`, {
    params: data,
  });
};

//----------------------------------------- 新增良品資料 ----------------------------------------
export const addOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOkItem`, data);
};

//----------------------------------------- 編輯良品資料 ----------------------------------------
export const modifyOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOkItem`, data);
};

//----------------------------------------- 刪除良品資料 ----------------------------------------
export const deleteOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOkItem`, data);
};

//不良品項目
//----------------------------------------- 取得全部不良品資料 ----------------------------------------
export const getNgItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getNgItem`, {
    params: data,
  });
};

//----------------------------------------- 新增不良品資料 ----------------------------------------
export const addNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addNgItem`, data);
};

//----------------------------------------- 編輯不良品資料 ----------------------------------------
export const modifyNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyNgItem`, data);
};

//----------------------------------------- 刪除不良品資料 ----------------------------------------
export const deleteNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteNgItem`, data);
};

//責任單位
//----------------------------------------- 取得全部責任單位資料 ----------------------------------------
export const getDri = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDri`, {
    params: data,
  });
};

//----------------------------------------- 新增責任單位資料 ----------------------------------------
export const addDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDri`, data);
};

//----------------------------------------- 編輯責任單位資料 ----------------------------------------
export const modifyDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyDri`, data);
};

//----------------------------------------- 刪除責任單位資料 ----------------------------------------
export const deleteDri = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDri`, data);
};

//外包項目
//----------------------------------------- 取得全部外包項目 ----------------------------------------
export const getOEMItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOEMItem`, {
    params: data,
  });
};

//----------------------------------------- 新增外包項目  ----------------------------------------
export const addOEMItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOEMItem`, data);
};

//----------------------------------------- 編輯外包項目 ----------------------------------------
export const modifyOEMItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOEMItem`, data);
};

//----------------------------------------- 刪除外包項目  ----------------------------------------
export const deleteOEMItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOEMItem`, data);
};

//部門主檔
//-----------------------------------------取得全部部門主檔----------------------------------------
export const getDept = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDept`, {
    params: data,
  });
};
//-----------------------------------------新增部門主檔管理----------------------------------------
export const addDept = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDept`, data);
};
//-----------------------------------------編輯部門主檔管理----------------------------------------
export const modifyDept = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyDept`, data);
};
//-----------------------------------------刪除部門主檔管理----------------------------------------
export const deleteDept = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDept`, data);
};

//職稱主檔
//----------------------------------------- 搜尋職稱資料 ----------------------------------------
export const getPost = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPost?postID`, {
    params: _params,
  });
};

//----------------------------------------- 刪除職稱資料 ----------------------------------------
export const deletePost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePost`, data);
};

//----------------------------------------- 新增職稱資料 ----------------------------------------
export const addPost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPost`, data);
};

//----------------------------------------- 編輯職稱資料 ----------------------------------------
export const modifyPost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPost`, data);
};
//----------------------------------------- 取得推播層級資料 ----------------------------------------
export const getPush = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPush`, {
    params: _params,
  });
};

//幣別項目
//-----------------------------------------取得全部幣別項目----------------------------------------
export const getCurrency = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCurrency`, {
    params: _params,
  });
};
//-----------------------------------------新增幣別項目管理----------------------------------------
export const addCurrency = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addCurrency`, data);
};
//----------------------------------------- 排序幣別資料 ----------------------------------------
export const sortCurrency = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/sortCurrency`, data);
};
//-----------------------------------------編輯幣別項目管理----------------------------------------
export const modifyCurrency = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyCurrency`, data);
};
//-----------------------------------------刪除幣別項目管理----------------------------------------
export const deleteCurrency = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteCurrency`, data);
};

//附加項目
//-----------------------------------------取得全部附加項目----------------------------------------
export const getAdd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAdd`, {
    params: data,
  });
};
//-----------------------------------------新增附加項目----------------------------------------
export const addAdd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addAdd`, data);
};
//-----------------------------------------編輯附加項目----------------------------------------
export const modifyAdd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyAdd`, data);
};
//-----------------------------------------刪除附加項目----------------------------------------
export const deleteAdd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteAdd`, data);
};

//下線項目
//----------------------------------------- 取得全部下線資料 ----------------------------------------
export const getOffline = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOffline`, {
    params: data,
  });
};

//----------------------------------------- 新增下線資料 ----------------------------------------
export const addOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOffline`, data);
};

//----------------------------------------- 編輯下線資料 ----------------------------------------
export const modifyOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOffline`, data);
};

//----------------------------------------- 刪除下線資料 ----------------------------------------
export const deleteOffline = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOffline`, data);
};
