import { Modal, Space, message } from "antd";
import dayjs from "dayjs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deletePlanHd, getPlanHd } from "@/service/apis/QMS/plan";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  // useAPI 取得表頭資料
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const call_getPlanHd = useAPI(getPlanHd);
  // useAPI 刪除表頭資料
  const call_deletePlanHd = useAPI(deletePlanHd);
  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  // 搜尋
  const onSearch = () => {
    call_getPlanHd.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deletePlanHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "計畫ID",
      dataIndex: "planID",
      align: "center",
    },
    {
      title: "計畫說明",
      dataIndex: "planNM",
      align: "center",
    },
    {
      title: "計畫說明",
      dataIndex: "plantype",
      align: "center",
      render: (text) => {
        const data = [
          { value: "0", label: "國際標準" },
          { value: "9", label: "自定義標準" },
        ];
        return data?.find((x) => x.value === text)?.label;
      },
    },
    // {
    //   title: t("util.util.note"),
    //   dataIndex: "note",
    //   align: "center",
    // },

    // {
    //   title: t("util.util.isvalid"),
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: 60,
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },

    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    onSearch();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getPlanHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getPlanHd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deletePlanHd;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deletePlanHd.status]);

  return (
    <>
      <CustomTable
        scroll={{ x: 0 }}
        columns={tableColumns.filter((x) => !x.hidden)}
        // dataSource={Object.keys(c_lightData).length > 0 ? [c_lightData] : s_tableData}
        dataSource={s_tableData}
        // dataSource={[]}
        loading={call_getPlanHd.status === "load" || call_deletePlanHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={type === "create" ? "新增抽樣計劃主檔" : "編輯抽樣計劃主檔"}
        width={"70%"}
        // style={{ top: 150 }}
        centered={true}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
