import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import { getUnit, deleteUnit } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const Unit = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getUnit = useAPI(getUnit);
  const call_deleteUnit = useAPI(deleteUnit);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getUnit.request(s_searchData);

  const tableColumns = [
    {
      title: t("util.util.unit"), //"計量單位",
      dataIndex: "unit",
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.unitNM1"), //"單位名稱",
      dataIndex: "unitNM1",
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.type"), //"計量型態",
      dataIndex: "type",
      render:(text)=> text ===  "count" ? t(`ADM.function-params-manage.countType`):t(`ADM.function-params-manage.quantitative`),
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.category"), //"計量類別",
      dataIndex: "category",
      render:(text)=> t(`ADM.function-params-manage.${text}`),
      align: "center",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
      align: "left",
    },
    {
      title: t("util.util.isvalid"), //"是否啟用",
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => {call_deleteUnit.request(rowData)},
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getUnit.status === "suc") {
      message.success(call_getUnit.msg);
      set_s_tableData(
        call_getUnit.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getUnit.status === "err") {
      message.error(t(`error.error-code.${call_getUnit.msg}`));
      set_s_tableData([]);
    }
  }, [call_getUnit.status]);

  useEffect(() => {
    if (call_deleteUnit.status === "suc") {
      message.success(call_deleteUnit.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteUnit.status === "err") {
      message.error(t(`error.error-code.${call_deleteUnit.msg}`));
    }
  }, [call_deleteUnit.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getUnit.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.util.edit") + t("util.util.unit")
            : t("util.util.add") + t("util.util.unit")
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Unit;
