import { Modal, Space, message } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { deleteMnsFunGp, getMnsFunGp } from "@/service/apis/MNS/notificationSetting";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer/index";

const Functions = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const call_getMnsFunGp = useAPI(getMnsFunGp);
  const call_deleteMnsFunGp = useAPI(deleteMnsFunGp);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getMnsFunGp.request(s_searchData);

  const tableColumns = [
    {
      title: t("util.util.SN"),
      dataIndex: "SN",
      align: "center",
    },

    {
      title: t("ADM.function-params-manage.funcode"), //"組織功能碼",
      dataIndex: "funcode",
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.funNM"), //"功能名稱",
      dataIndex: "funNM",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
      align: "left",
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "120px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: async () => {
        call_deleteMnsFunGp.request(rowData);
      },
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getMnsFunGp.status === "suc") {
      message.success(call_getMnsFunGp.msg);
      set_s_tableData(
        call_getMnsFunGp.data.map((x, i) => ({
          ...x,
          key: i,
          SN: i + 1,
        }))
      );
    } else if (call_getMnsFunGp.status === "err") {
      message.error(call_getMnsFunGp.msg);
      set_s_tableData([]);
    }
  }, [call_getMnsFunGp.status]);

  useEffect(() => {
    if (call_deleteMnsFunGp.status === "suc") {
      message.success(call_deleteMnsFunGp.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteMnsFunGp.status === "err") {
      message.error(call_deleteMnsFunGp.msg);
    }
  }, [call_deleteMnsFunGp.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getMnsFunGp.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.util.edit") + t("ADM.function-params-manage.functions")
            : t("util.util.add") + t("ADM.function-params-manage.functions")
        }
        width={"50%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Functions;
