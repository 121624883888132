import React, { useState, useEffect } from "react";
import { Button, Row, Col, message, Space, Upload, Alert, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { read, utils } from "xlsx";
import { addErpWh } from "@/service/apis/WHM/AttendanceHours";
import useAPI from "@/hooks/useAPI";
import Demo from "@/assets/demo/AttendanceHoursDemo.xlsx";

export default function ModalConintaer({ set_is_showModal, tableColumns }) {
  const { t } = useTranslation();

  const call_addErpWh = useAPI(addErpWh);

  function transformData(data, cols) {
    try {
      // 映射原始數據鍵到新的鍵
      const keyMap = {};

      for (const col of cols) {
        keyMap[col.title] = col.dataIndex;
      }

      const newData = data.map((item) => {
        const newItem = {};
        for (const key in item) {
          const newKey = keyMap[key];

          if (newKey) {
            // 特別處理日期和工時
            if (newKey === "atddate") {
              console.log(newKey, item[key], item, key);
              // 將Excel日期轉換為JS日期。注意：這假設“日期”是一個Excel日期序列號
              const excelDate = new Date((item[key] - 25569) * 86400 * 1000); // 25569是190-01-01
              newItem[newKey] = excelDate;
            } else if (newKey === "wh") {
              // 如果你需要將小數工時轉換為 hh:mm 的格式，你可以在這裡添加代碼
              const excelDate = item[key].split(":");
              newItem[newKey] = parseInt(excelDate[0]) * 60 + parseInt(excelDate[1]);
            } else {
              newItem[newKey] = item[key].toString();
            }
          }
        }
        return newItem;
      });
      console.log(newData);
      // 轉換函數
      return { status: 200, data: newData };
    } catch (error) {
      console.log(error);
      return { status: 400, data: "轉換錯誤，請檢查檔案" };
    }
  }

  const props = {
    name: "file",
    multiple: false,
    fileList: [],
    beforeUpload() {
      return false;
    },
    async onChange(info) {
      const { status } = info.file;
      console.log(status, info.file);

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        return;
      }
      if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        return;
      }

      const f = await info.file.arrayBuffer();
      const wb = read(f);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

      const ans = transformData(data, tableColumns);

      if (ans.status === 200) {
        console.log("@@@");
        call_addErpWh.request(ans.data);
      } else {
        message.error(ans.data);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (call_addErpWh.status === "suc") {
      message.success(call_addErpWh.msg);
      set_is_showModal(false);
    } else if (call_addErpWh.status === "err") {
      console.log(111);
      message.error(call_addErpWh.msg);
    }
  }, [call_addErpWh.status]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Alert type="warning" message="請注意，匯入時將取代現有資料" />
      </Col>
      <Col span={12}>
        <Button type="link" block className="h-[55px] text-[28px]" href={Demo}>
          範例下載
        </Button>
      </Col>
      <Col span={12}>
        <Upload {...props}>
          <Button type="primary" block className="h-[55px] text-[28px]">
            匯入
          </Button>
        </Upload>
      </Col>
      <Divider className="m-0" />
      <Col span={24} className="flex justify-end">
        <Space>
          <Button onClick={() => set_is_showModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.util.ok")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
