import { useState, useEffect } from "react";
import { Button, Space, message, DatePicker, Input } from "antd";
import { IconPen, IconCheck, IconClose, IconGarbage } from "@/components/Icon/Action";
import styled from "styled-components";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getZSDS003 } from "@/service/apis/SAP/order.js";
import { useTranslation } from "react-i18next";
// import { sapTypeOptions } from "../../util/anyMap";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";
import { numberWithCommas } from "../../../../util/format";

const { RangePicker } = DatePicker;

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;

  const call_getZSDS003 = useAPI(getZSDS003);

  const [s_tableData, set_s_tableData] = useState([]);
  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const [s_searchData, set_s_searchData] = useState({
    startDate: today,
    endDate: tomorrow,
    VBELN: "",
    POSNR: "",
    VBELN_VL: "",
  });

  const columnWidth = "300px";

  const tableColumns = [
    {
      title: "用戶端",
      dataIndex: "CLIENT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
      fixed: true,
    },
    {
      title: "訂單號",
      dataIndex: "VBELN",
      align: "center",
      width: columnWidth,
      ellipsis: true,
      fixed: true,
    },
    {
      title: "訂單項次",
      dataIndex: "POSNR",
      align: "center",
      width: columnWidth,
      ellipsis: true,
      fixed: true,
    },
    {
      title: "出貨單號",
      dataIndex: "VBELN_VL",
      width: columnWidth,
      ellipsis: true,
      align: "center",
    },
    {
      title: "出貨項次",
      dataIndex: "POSNR_VL",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // render: (text) => numberWithCommas(text),
    },
    {
      title: "客戶我方帳號",
      dataIndex: "EIKTO",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "買方",
      dataIndex: "KUNAG",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "買方名稱",
      dataIndex: "ZZSOLDTO_NANE",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "買方簡稱",
      dataIndex: "ZZSOLDTO_BU_SORT1",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "買方國家名稱",
      dataIndex: "ZZSOLDTO_LANDX",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "買方地址",
      dataIndex: "ZZSOLDTO_ADDR",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "客戶單號",
      dataIndex: "BSTKD",
      align: "center",
      width: columnWidth,
      ellipsis: true,

      // width: "30%",
    },
    {
      title: "收貨方單號",
      dataIndex: "BSTKD_E",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方",
      dataIndex: "KUNWE",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方名稱",
      dataIndex: "ZZSHIPTO_NAME",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方簡稱",
      dataIndex: "ZZSHIPTO_SORT1",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方國家名稱",
      dataIndex: "ZZSHIPTO_LANDX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方城市1",
      dataIndex: "CITY1",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "收貨方地址",
      dataIndex: "ZZSHIPTO_ADDR",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端買主",
      dataIndex: "ZZENDCUST",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端買主名稱",
      dataIndex: "ZZENDCUST_NAME",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端買主簡稱",
      dataIndex: "ZZENDCUST_BU_SORT1",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端買主國家名稱",
      dataIndex: "ZZENDCUST_LANDX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "物料",
      dataIndex: "MATNR",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "項目描述",
      dataIndex: "ARKTX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "客戶物料",
      dataIndex: "KDMAT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "客戶物料品名",
      dataIndex: "POSTX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "客戶物料型號",
      dataIndex: "ZZKDMAT_MODEL",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端客戶物料",
      dataIndex: "ZZCUST_MAT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端客戶物料品名",
      dataIndex: "ZZCUST_DESC",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "終端客戶物料型號",
      dataIndex: "ZZCUST_MODEL",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "計畫出貨日",
      dataIndex: "WADAT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "出貨批次",
      dataIndex: "CHARG",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "訂單數量",
      dataIndex: "KWMENG",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "出貨數量",
      dataIndex: "LFIMG",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "每箱數量",
      dataIndex: "TRGQTY",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "每箱毛重",
      dataIndex: "TOTLWEI",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "每箱淨重",
      dataIndex: "LOADWEI",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "重量單位",
      dataIndex: "UNITWEI_MAX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "每箱材積",
      dataIndex: "TAREVOL",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "材積單位",
      dataIndex: "UNITVOL",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "尾箱數量",
      dataIndex: "ZZPARTIAL_QTY",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "尾箱淨重",
      dataIndex: "ZZPARTIAL_LOADWEI",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "總箱數/件數",
      dataIndex: "ZZTOTAL_BOX",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "紙箱長",
      dataIndex: "LENGTH",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "紙箱寬",
      dataIndex: "WIDTH",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "紙箱高",
      dataIndex: "HEIGHT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "長度單位",
      dataIndex: "UNITDIM",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "客戶物料規格",
      dataIndex: "ZZKDMAT_SPEC",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "包裝註記",
      dataIndex: "ZZPACK_NOTE",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "指定標籤欄位1",
      dataIndex: "KVGR1",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "指定標籤欄位2",
      dataIndex: "KVGR2",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "指定標籤欄位3",
      dataIndex: "KVGR3",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "指定標籤欄位4",
      dataIndex: "KVGR4",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "指定標籤欄位5",
      dataIndex: "KVGR5",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "dot number",
      dataIndex: "ZZDDT",
      align: "center",
      width: columnWidth,
      ellipsis: true,
    },
    {
      title: "時間",
      align: "center",
      dataIndex: "ZZIFTIMESTAMP",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      width: columnWidth,
      ellipsis: true,
    },
  ];
  const search = (e) => {
    set_c_lightData({});

    call_getZSDS003.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  };
  const handleKeyDownEnter = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      call_getZSDS003.request({
        ...s_searchData,
        startDate: s_searchData.startDate.format("YYYY-MM-DD"),
        endDate: s_searchData.endDate.format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    call_getZSDS003.request({
      ...s_searchData,
      startDate: s_searchData.startDate.format("YYYY-MM-DD"),
      endDate: s_searchData.endDate.format("YYYY-MM-DD"),
    });
  }, []);

  useEffect(() => {
    // if (
    //   (s_searchData.PN === undefined || s_searchData.PN === "") &&
    //   (s_searchData.modelID === undefined || s_searchData.modelID === "")
    // ) {
    //   call_getModelHd.request(s_searchData);
    // }
  }, [s_searchData]);

  useEffect(() => {
    if (call_getZSDS003.status === "suc") {
      set_s_tableData(call_getZSDS003.data.map((x, i) => ({ ...x, key: i, index: i })));
    } else if (call_getZSDS003.status === "err") {
      message.error(call_getZSDS003.msg);
      set_s_tableData([]);
    }
  }, [call_getZSDS003.status]);

  const searchTemp = [
    <Input
      placeholder={`${t("util.util.placeholder_input")}訂單號`}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, VBELN: e.target.value };
        });
      }}
      onKeyDown={handleKeyDownEnter}
    />,
    <Input
      placeholder={`${t("util.util.placeholder_input")}訂單項次`}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, POSNR: e.target.value };
        });
      }}
      onKeyDown={handleKeyDownEnter}
    />,
    <Input
      placeholder={`${t("util.util.placeholder_input")}出貨單號`}
      onChange={(e) => {
        set_s_searchData((prve) => {
          return { ...prve, VBELN_VL: e.target.value };
        });
      }}
      onKeyDown={handleKeyDownEnter}
    />,
    <RangePicker
      allowClear={false}
      format="YYYY-MM-DD"
      value={[s_searchData.startDate, s_searchData.endDate]}
      className="w-full"
      onChange={(_, dateString) => {
        // console.log(date);
        console.log(dateString);

        set_s_searchData((prve) => {
          return { ...prve, startDate: dayjs(dateString[0]), endDate: dayjs(dateString[1]) };
        });
      }}
    />,
    <Button type="primary" onClick={search} key="search">
      查詢
    </Button>,
  ];

  return (
    <>
      <PageHeader title="SAP訂單資料" extra={searchTemp} />

      <CustomTable
        rowClassName={`bg-slate-50`}
        columns={tableColumns.filter((x) => !x.hidden)}
        scroll={{ x: 6000, y: 720 }}
        size="large"
        dataSource={s_tableData}
        // dataSource={tableData}
        loading={call_getZSDS003.status === "load"}
        // tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      {/* <CustomModal
        width="90%"
        title="新建標籤樣板"
        open={s_showModal}
        centered
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getModelHd.request(s_searchData);
        }}
      >
      
      </CustomModal> */}
    </>
  );
};
export default HD;
