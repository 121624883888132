import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { message } from "antd";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { getAllInv } from "@/service/apis/WMS/StockSearch";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const SearchWh = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, set_s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const call_getAllInv = useAPI(getAllInv);

  const tableColumns = [
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      width: "25%",
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      width: "25%",
    },
    {
      title: t("util.util.pdtspec"),
      dataIndex: "pdtspec",
      width: "25%",
    },
    {
      title: t("util.util.whID"),
      dataIndex: "whID",
    },
    {
      title: t("util.util.sqty"),
      dataIndex: "sumsqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.dqty"),
      dataIndex: "sumdqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.tqty"),
      dataIndex: "sumtqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_editData({});
    },
  }));

  const onSearch = () => {
    call_getAllInv.request(s_searchData);
    // let newData = call_getAllInv.data.map((x, i) => ({ ...x, key: i }));
    // if (!value.PN && value.wh.length === 0) {
    //   set_s_tableData(newData);
    //   return;
    // }
    // const Data = newData.filter((item) =>
    //   Object.entries(value).every(([key, val]) =>
    //     key === "PN" ? item[key].includes(val) : key === "whID" ? val.includes(item[key]) : true
    //   )
    // );
    // set_s_tableData(Data);
  };

  useEffect(() => {
    call_getAllInv.request(s_searchData);
  }, []);

  useEffect(() => {
    if (call_getAllInv.status === "suc") {
      message.success(call_getAllInv.msg);
      // 整理資料
      set_s_pageInfo(call_getAllInv.data.pageInfo);
      set_s_tableData(call_getAllInv.data?.tableData?.map((x, i) => ({ ...x, key: i, index: i })));
      // set_s_tableData(
      //   call_getAllInv.data.map((x) => ({
      //     ...x,
      //     key: x.PN + x.whID,
      //   }))
      // );
    } else if (call_getAllInv.status === "err") {
      set_s_tableData([]);
      message.error(call_getAllInv.msg);
    }
  }, [call_getAllInv.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getAllInv.status === "load"}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) => {
            // 如果有更動pageSize 回第一頁
            let checkPage = s_searchData.pageSize !== size ? 1 : current;
            const tmpSearchData = {
              ...s_searchData,
              page: checkPage,
              pageSize: size,
            };
            call_getAllInv.request({ ...tmpSearchData, queryType: "All" });
            set_s_searchData(tmpSearchData);
          },
        }}
      />
    </>
  );
});

export default SearchWh;
