import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Tooltip, Select, Space, message, DatePicker } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Cookies from "js-cookie";

import dayjs from "dayjs";

const CustomHighchartsReact = styled(HighchartsReact)`
  height: 100% !important;
  width: 100% !important;
  .highcharts-container {
    height: 100% !important;
    width: 100% !important;
  }
`;

export default function LocaltionChart({ boardData, s_eazythisDrawno }) {
  const defaultChartOptions = {
    chart: {
      // 设置图表顶部边距
      marginTop: 60,
      // 启用 reflow 自动调整大小以适应容器
      reflow: true,
      // 设置图表高度
      height: 550,
      // 设置图表底部间距
      spacingBottom: 5,
      // 设置图表底部边距
      marginBottom: 50,
    },

    // 禁用范围选择器
    rangeSelector: {
      inputEnabled: false,
      enabled: false,
    },

    // 禁用图表标识
    credits: {
      enabled: false,
    },

    // 设置时间配置以使用本地时间而非 UTC
    time: {
      useUTC: false,
    },

    // 主要 yAxis 的配置
    yAxis: [
      {
        // 设置 yAxis 的标题
        title: {
          text: "X BAR(平均值)",
          align: "high",
          rotation: 0,
          y: -20,
          offset: -600,
          style: {
            color: "#0E6BD3",
            fontSize: 18,
          },
        },
        labels: {
          align: "center",
          x: 0,
        },
        // 设置 yAxis 的刻度数量
        tickAmount: 5,
        // 设置 yAxis 的高度
        height: "50%",
        lineWidth: 2,
        lineColor: "#fff",
        // 启用 yAxis 的调整大小功能
        resize: {
          enabled: true,
        },
        // 添加用于参考线的绘图线
        plotLines: [
          {
            value: 10,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "USL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
          {
            value: 190,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "LSL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
        ],
      },
      {
        // 第二個 yAxis 的配置
        labels: {
          align: "center",
          x: -3,
        },
        title: {
          text: "R (全距)",
          align: "high",
          rotation: 0,
          y: 20,
          offset: -575,
          style: {
            color: "#0E6BD3",
            fontSize: 18,
          },
        },
        tickAmount: 5,
        top: "50%",
        height: "40%",
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        lineWidth: 2,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
      },
    ],

    // xAxis 的配置
    xAxis: {
      min: null,
      max: null,
    },

    // 图例的配置
    legend: {
      enabled: true,
      align: "right",
      padding: 5,
    },

    // 系列数据的配置
    series: [
      {
        name: "xbar",
        data: [0],
        marker: {
          radius: 0,
        },
        height: 10,
      },
      {
        name: "R",
        data: [0],
        yAxis: 1,
        height: 200,
      },
    ],
  };
  const [s_heightChart, set_s_heightChart] = useState(Highcharts);

  const [chartOptions, setChartOptions] = useState(JSON.parse(JSON.stringify(defaultChartOptions)));

  const chartRef = useRef(null);

  useEffect(() => {
    // Ensure chart ref exists before calling reflow

    if (chartRef.current && chartRef.current.chart) {
      const interval = setInterval(() => {
        chartRef.current.chart.reflow();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    // if (boardData.length === 0) return;
    // if (!boardData || boardData.length === 0) return;
    if (!boardData) return;
    let xData = [],
      xCL = [],
      xLCL = [],
      xUCL = [],
      USL = [],
      LSL = [],
      // xMax = boardData[0].average,
      // xMin = boardData[0].average,
      rData = [],
      rCL = [],
      rLCL = [],
      rUCL = [];
    // rMax = boardData[0].range,
    // rMin = boardData[0].range;
    for (const item of boardData) {
      // console.log(item)
      // X
      xData.push([new Date(item.measureT).getTime(), item.avg]);
      xCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.CL]);
      xLCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.LCL]);
      xUCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.UCL]);
      USL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.USL]);
      LSL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.LSL]);

      // R
      rData.push([new Date(item.measureT).getTime(), item.range]);
      rCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.rangeCL]);
      rLCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.rangeLCL]);
      rUCL.push([new Date(item.measureT).getTime(), s_eazythisDrawno.rangeUCL]);
    }

    let series = [
      {
        name: "X_AVG",
        data: xData,
        lineWidth: 4,
        yAxis: 0,

        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },

        zones: [
          {
            value: s_eazythisDrawno.LCL,
            color: "#f75c5c",
          },
          {
            value: s_eazythisDrawno.UCL,
            color: "#a8a8a8",
          },
          {
            color: "#f75c5c",
          },
        ],
      },

      {
        name: "X_CL",
        data: xCL,
        color: "#40ad36",
        yAxis: 0,
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_LCL",
        data: xLCL,
        yAxis: 0,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "X_UCL",
        data: xUCL,
        yAxis: 0,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "LSL",
        data: LSL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "USL",
        data: USL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_RANGE",
        data: rData,
        lineWidth: 4,
        yAxis: 1,
        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },
      },
      {
        name: "R_CL",
        data: rCL,
        color: "#40ad36",
        yAxis: 1,
        tooltip: {
          pointFormat: "",
        },
      },

      {
        name: "R_LCL",
        data: rLCL,
        yAxis: 1,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_UCL",
        data: rUCL,
        yAxis: 1,
        color: "#0E6BD3",
        dashStyle: "longdash",
        tooltip: {
          pointFormat: "",
        },
      },
    ];

    setChartOptions((prev) => {
      prev.series = series;
      // prev.series = boardData.length === 0 ? [] : series;
      prev.yAxis[0].plotLines[0].value = s_eazythisDrawno.USL;
      prev.yAxis[0].plotLines[1].value = s_eazythisDrawno.LSL;
      return { ...prev };
    });
  }, [boardData]);
  console.log(Highcharts);
  return (
    // <div ref={chartRef} className=" p-5  bg-red-700 " style={{ width: "100%", height: "500px" }}>

    <CustomHighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={chartOptions}
      constructorType={"stockChart"}
      style={{ display: "block", height: "100%", width: "100%" }}
    />
    // </div>
  );
}
