import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Spin, message } from "antd";

import DetailCard from "./XbarContainer/DetailCard";
import LocaltionChart from "./XbarContainer/LocaltionChart";

const XBarChartStyle = styled.div`
  padding: 0px 15px 15px 15px;
  width: 100%;
  height: 100%;
  /* min-width: 889px;
  min-height: 500px; */
  /* background-color: red; */
`;

export default function XBarChart({ s_XBarChartData }) {
  const [thisWonData, setThisWonData] = useState("");

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  return (
    <XBarChartStyle ref={parentRef}>
      <LocaltionChart
        boardData={s_XBarChartData} //socket Data
        parentWidth={parentWidth}
        // detailData={data.detailData} //
        thisWonData={thisWonData}
        setThisWonData={setThisWonData}
      />
    </XBarChartStyle>
  );
}
