import { useState, useEffect } from "react";
import { message } from "antd";
import { getStation } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import PageHeader from "@/components/PageHeader/index";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { useTranslation } from "react-i18next";
const TB = (props) => {
  const { c_lightData } = props;

  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getStation = useAPI(getStation);

  const tableColumns = [
    {
      title: t("ADM.production-line-manage.wsID"), //"工站ID",
      dataIndex: "wsID",
    },
    {
      title: t("ADM.production-line-manage.wsNM"), //"工站名稱",
      dataIndex: "wsNM",
    },
    {
      title: t("ADM.production-line-manage.psNM"), //"車間名稱",
      dataIndex: "psNM",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
    },
  ];

  useEffect(() => {
    call_getStation.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getStation.status === "suc") {
      message.success(call_getStation.msg);
      set_s_tableData(call_getStation.data.map((x, i) => ({ ...x, key: i })));
    } else if (call_getStation.status === "err") {
      message.error(t(`error.error-code.${call_getStation.msg}`));
      set_s_tableData([]);
    }
  }, [call_getStation.status]);

  return (
    <>
      <PageHeader title={t("ADM.production-line-manage.tbHeader_PS")} />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getStation.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
      />
    </>
  );
};

export default TB;
