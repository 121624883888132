const id_ID = {
  "util": {
    "util": {
      "itemID": "No. ID Proyek",
      "itemno": "Urutan",
      "sign_out": "Keluar ",
      "add": "Tambah",
      "edit": "Sunting ",
      "delete": "Hapus ",
      "search": "Cari ",
      "cancel": "Batal",
      "close": "Tutup ",
      "ok": "Yakin ",
      "render": "Pilih ",
      "check": "Tinjau",
      "success": "Sukses",
      "all": "Semua ",
      "yes": "Ya ",
      "no": "Tidak",
      "name": "Nama",
      "total": "Total ",
      "SN": "No.",
      "detail": "Detail ",
      "action": "Pengoperasian ",
      "size_big": "Ukuran XL",
      "size_large": "Ukuran L",
      "size_middle": "Ukuran M",
      "size_small": "Ukuran S",
      "hide_detail": "Sembunyikan Rincian Dokumen  ",
      "del_confirm": "Hapus Konfirmasi ",
      "del_confirm_msg": "Konfirmasi Apakah Data yang Mau Dihapus Sudah Benar  ",
      "note": "Catatan",
      "no_data": "Data Tidak Ditemukan",
      "data": "Data ",
      "header_cancel_create": "Batalkan Pembuatan Data",
      "header_create": "Dibuat Berdasarkan Ini ",
      "placeholder_select": "Pilih ",
      "placeholder_input": "Silahkan Masukkan",
      "or": "Atau",
      "order": "Pesanan",
      "showTotal":
        "Total {{total}} Baris， Sedang Melihat Barang ke {{rangeFirst}} sampai {{rangeEnd}} ",
      "itemNM": "Nama Barang",
      "excluding_tax": "(Tanpa Pajak)",
      "start": "Mulai",
      "end": "Sampai",
      "wl": "Lane Kerja",
      "ws": "Stasiun Kerja ",
      "woN": "No. Lembar Kerja",
      "situF": "Kondisi",
      "PN": "No.Bahan ",
      "whID": "Jenis Gudang ",
      "idmtype": "Jenis Postingan  ",
      "custID": "No. ID Pelanggan",
      "custPN": "No. Bahan Pelanggan",
      "pdtNM": "Nama Produk",
      "pdtspec": "Spesifikasi ",
      "planqty": "Jumlah yang Direncanakan ",
      "inqty": "Jumlah Produksi",
      "tqty": "Jumlah Persediaan ",
      "sqty": "Input Manual ",
      "dqty": "Pindai Kode Batang",
      "batchno": "No. Batch",
      "okC": "Jumlah Barang Bagus",
      "inC": "Jumlah Produksi yang Dihasilkan",
      "ngC": "Jumlah Barang Rusak",
      "lifeF": "Kelas",
      "isvalid": "Validasi",
      "isfocus": "Fokus Pada ",
      "dept": "Departemen ",
      "updtT": "Waktu Pembaruan ",
      "pdtclass": "Produk ",
      "mtype": "Tipe Mesin",
      "ver": "Kode Versi ",
      "qty": "No. BOM ",
      "attribute": "Sifat Bahan ",
      "unit": "Satuan Pengukuran",
      "opunit": "Unit Pengoperasian",
      "unitwt": "Satuan Berat",
      "setting": "Pengaturan",
      "mgmtcat": "Akses Kelompok",
      "querycat": "Kategori Pencarian ",
      "drawno": "No. Gambar ",
      "matl": "Bahan Baku",
      "moldID": "ID Cetakan",
      "heirPN": "No. Bahan Tujuan ",
      "heirdealID": "Tujuan Pewarisan ",
      "moldC": "Berapa kali Cetakan Dilakukan",
      "moldcavity": "Jumlah Lubang Cetakan",
      "searchPN": "Cari No.Bahan",
      "dealPN": "Nomor Bahan Transaksi",
      "edit_detect_msg1": "Sistem Mendeteksi Anda Telah Mengedit Kolom Di Bawah",
      "edit_detect_msg2": "Simpan Dulu Data Sistem yang Ada, Baru Edit Rincian",
      "msg_chose_data": "Silakan Pilih Data Yang Mau Dikutip",
      "msg_chose_whichType_toThrow": "Silakan Pilih Jenis Dokumen Yang Akan Ditransfer",
      "msg_chose_atleast_one_Detail": "Silakan Pilih Minimal Satu Rincian",
      "UploadSignature": "Unggah Tandatangan",
      "UploadSign": "Unggah Tandatangan Pengembalian",
      "removeSearch": "Bersihkan Catatan Pencarian",
      "setAdvanced": "Pengaturan Kriteria Penyaringan Tingkat Lanjut",
      "exportExcel": "Ekspor Ke Excel",
      "table_close": "Perintah Wajib",
      "table_more": "Lihat Lebih Banyak",
      "header_next": "Selanjutnya",
      "header_prev": "Sebelumnya",
      "header_print": "Cetak",
      "header_return": "Kembali ke Tabel Rangkuman",
      "header_save": "Simpan",
      "header_tour": "Kembali ke Panduan",
      "take": "Kutipan",
      "throw": "Transfer",
      "accord": "Berdasarkan",
      "create": "Pembuatan",
      "cancel_Check": "Batalkan Tandatangan",
      "sysMsg": "Pesan Sistem",
      "advanceSearch": "Pencarian Tingkat Lanjut",
      "window": "Penanggungjawab",
      "contact": "Orang yang Dapat Dihubungi",
      "tel": "No. Telp yang Dapat Dihubungi",
      "itemno_total": "Jumlah Total",
      "get_prev_data_suc": "Berhasil Memperoleh Data Sebelumnya",
      "get_next_data_suc": "Berhasil Memperoleh Data Selanjutnya",
      "get_prev_data_err": "Gagal Mendapatkan Data Sebelumnya",
      "get_next_data_err": "Gagal Mendapatkan Data Selanjutnya",
      "prev_data_errMsg": "Sedang Melihat Data Pertama, Tidak Ada Data Sebelumnya",
      "next_data_errMsg": "Sedang Melihat Data Terakhir, Tidak Ada Data Selanjutnya",
      "opcode": "Bagian",
      "N": "No. Lembaran",
      "currencytype": "Mata Uang",
      "taxtype": "Kategori Pajak",
      "taxrate": "%Pajak",
      "utype": "Tipe Unit",
      "custalias": "Singkatan Nama Pelanggan",
      "custNM": "Nama Pelanggan",
      "exchangerate": "%Kurs",
      "exchangeD": "%Kurs/Tanggal",
      "confirmID": "Peninjau",
      "lifeFT": "Waktu yang Berlalu",
      "createID": "Pembuat",
      "createT": "Tanggal Pembuatan",
      "confirmT": "Tanggal Peninjauan",
      "signT": "Tanggal Tandatangan",
      "approveT": "Tanggal Peninjauan",
      "updtID": "Karyawan yang Memperbarui",
      "EDT": "Tanggal Perkiraan Pengiriman",
      "startEDT": "Perencanaan Tanggal Pengiriman (Mulai)",
      "endEDT": "Tanggal Perkiraan Pengiriman (Sampai)",
      "pbom": "Jumlah Gabungan",
      "punit": "Satuan yang Digunakan",
      "tax": "Total Pajak",
      "itemsum": "Harga/Satuan",
      "itemsumtax": "Pajak/Satuan",
      "subtotal": "Total Termasuk Pajak",
      "batchdate": "Tanggal No. Batch",
      "balqty": "Jumlah Sisa",
      "suppID": "No. ID Pabrik",
      "suppalias": "Nama Singkat Pabrik",
      "suppNM": "Nama Pabrik",
      "stockinT": "Tanggal Transfer",
      "idmtype_s": "Jumlah dengan Perhitungan Manual",
      "idmtype_d": "Jumlah Menggunakan Barcode",
      "idmtype_m": "Jumlah Campuran",
      "attribute_0": "0 Bahan Baku",
      "attribute_1": "1 Bahan Habis Pakai",
      "attribute_2": "2 Bagian Non Standar ",
      "attribute_3": "3 Bagian Standar",
      "attribute_4": "4 Barang Setengah Jadi",
      "attribute_5": "5 Produk Jadi",
      "attribute_6": "6 Produk Pemberian Pelanggan",
      "pdtclass_X": "N/A",
      "pdtclass_M": "Bagian Utama",
      "pdtclass_A": "Bagian Cadangan",
      "pdtclass_P": "Bahan Kemasan",
      "packtype_0": "(0)Pengoperasian Pengemasan_Unit yang Digunakan",
      "packtype_1": "(1)Pengiriman Kemasan_Digunakan Untuk Pengemasan dan Pengiriman",
      "packtype_2": "(2)Pengemasan Bolak-Balik_Digunakan Untuk Line Kerja Pengemasan Bolak Balik",
      "assetkind": "Kategori Aset",
      "assetkind_TI": "Injeksi",
      "assetkind_TS": "Percetakan",
      "assetkind_TD": "Pengecoran Mati'",
      "assetkind_TF": "Penempaan",
      "fileType_SOP": "Standar Kerja SOP",
      "fileType_SIP": "Standar Pemeriksaan SIP",
      "fileType_PACK": "Standar Pengemasan PACK",
      "fileType_DRAW": "Gambar Proses Kerja DWG",
      "fileType_PIC": "Arsip Foto PIC",
      "fileType_ECN": "ECN_工程變更通知",
      "nav_pds_dashboard": "Produksi Digital Kanban",
      "nav_pds_abc-board": "Manajemen Kanban Tidak Normal",
      "nav_pds_overview-lines": "Tabel Ringkasan Operasi /Lane",
      "nav_BDM": "Manajemen Data Bahan",
      "nav_bdm_pn-manage": "Informasi Dasar Dalam Pemeliharaan",
      "nav_bdm_edm": "Data Teknis Dalam Pemeliharaan",
      "nav_EDM": "Manajemen Data Peralatan",
      "nav_edm_mold": "Data Cetakan Dalam Pemeliharaan",
      "nav_TDM": "Manajemen Data Pabrik",
      "nav_tdm_plant-manage": "Pengaturan Dokumen Induk Pabrik",
      "nav_tdm_procedure-manage": "Perencanaan Proses Kerja Dalam Pemeliharaan",
      "nav_tdm_production-line-manage": "Perencanaan dan Pemeliharaan Lane Produksi",
      "nav_tdm_warehouse-manage": "Data Perencanaan Gudang Pemeliharaan",
      "nav_tdm_string-manage": "Manajemen Rantai Fungsi",
      "nav_tdm_plant-params-manage": "Pengaturan Parameter Pabrik",
      "nav_SDM": "Manajemen Sistem Data",
      "nav_sdm_user-manage": "Data Karyawan Dalam Pemeliharaan",
      "nav_sdm_company": "Data Mengenai Pabrik Sedang Dalam Pemeliharaan",
      "nav_sdm_client": "Data Pelanggan Dalam Pemeliharaan",
      "nav_sdm_category-manage": "Pengaturan Kategori Pencarian",
      "nav_sdm_data-auth-manage": "Pengaturan Fungsi Otorisasi",
      "nav_sdm_trading-pattern": "Pengaturan Rangkaian yang Sering Digunakan",
      "nav_sdm_function-params-manage": "Pengaturan Sistem Parameter",
      "nav_WMS": "Sistem Manajemen Gudang",
      "nav_wms_plan-in": "Tugas Penyimpanan Barang di Gudang",
      "nav_wms_plan-out": "Tugas Pengeluaran Barang dari Gudang",
      "nav_wms_stock-search": "Pengecekan Persediaan Barang",
      "nav_wms_inv-history": "Catatan Penyimpanan Bulanan",
      "nav_wms_pro-instock": "Tugas Agen Masuk Gudang",
      "nav_wms_pro-outstock": "代理出庫作業",
      "nav_wms_inventory-inquiry": "現有庫存查詢",
      "nav_PMS": "Sistem Manajemen Pembelian",
      "nav_pms_plan": "Perencanaan Penawaran",
      "nav_pms_req": "Tugas Permintaan Pembelian",
      "nav_pms_pro": "Tugas Pembelian",
      "nav_pms_instock": "Tugas Masuk Bahan",
      "nav_pms_gostock": "Pencarian Mengenai Pengecekan Bahan yang Masuk",
      "nav_pms_return": "Operasi Pengembalian Bahan",
      "nav_pms_ap-manage": "Manajemen Hutang",
      "nav_pms_report": "Pencarian Mengenai Laporan",
      "nav_pms_report-unpurchase": "Tabel Rincian Pembelian yang Tidak Masuk",
      "nav_pms_report-diff": "Tabel Penyesuaian Harga Pembelian",
      "nav_pms_report-instocksummary": "Tabel Ringkasan Penyimpanan",
      "nav_pms_report-inventorydiff": "Buku Catatan Pembantu Transaksi Gudang",
      "nav_pms_report-psisummary": "Tabel Ringkasan Tagihan",
      "nav_pms_report-purchasesummary": "Tabel Ringkasan Masuk-Keluar Barang",
      "nav_pms_report-purchaselist": "Tabel Rincian Masuk-Keluar Barang",
      "nav_pms_report-purchasestatistics": "Tabel Statistik Masuk-Keluar Barang",
      "nav_OMS": "Sistem Manajemen Penjualan",
      "nav_oms_quo": "Penawaran",
      "nav_oms_fod": "Pekerjaan Pembuatan Rencana",
      "nav_oms_ord": "Operasi Penjualan",
      "nav_oms_outstock": "Pengiriman",
      "nav_oms_check": "Pemeriksaan Pengiriman",
      "nav_oms_return": "Operasi Pengembalian Barang",
      "nav_oms_ap-manage": "Manajemen Pembayaran Hutang",
      "nav_oms_report": "Pencarian Mengenai Laporan",
      "nav_oms_report-unshipped": "Tabel Rincian Pesanan yang Tidak Keluar",
      "nav_oms_report-salesummary": "Tabel Ringkasan Pengembalian Penjualan",
      "nav_oms_report-salelist": "Daftar Pengembalian Penjualan",
    },
  },
  "error": {
    "error-code": {
      "403": "無此權限",
      "404": "查無資料",
      "409": "資料重複",
      "500": "操作失敗",
    },
  },
  "WOS": {
    "util": {},
    "login": {
      "pageTitle": "Login Akun",
    },
    "mc": {
      "pageTitle": "Pekerjaan Bahan",
    },
    "parts-binding": {
      "pageTitle": "Scan Bagian Produk",
    },
    "ppidp": {
      "pageTitle": "成品綁定",
    },
    "qc": {},
    "tk": {
      "pageTitle": "生產看板",
    },
    "wl-woc": {
      "pageTitle": "生產控制",
    },
    "wpc-search": {
      "pageTitle": "途單查詢",
    },
  },
  "IMS": {
    "util": {
      "lifeF_0": "Sudah Dibuat",
      "lifeF_1": "Dalam Proses",
      "lifeF_2": "Sudah Dikirim",
      "lifeF_A": "Semua ",
      "lifeF_T": "Sudah Diubah",
      "opcode_4110": "Simpanan Produksi",
      "opcode_4120": "Penyimpanan Barang yang Dibeli",
      "opcode_4130": "Pengembalian Penjualan",
      "opcode_4141": "Barang Masuk Gudang",
      "opcode_4142": "Peminjaman Masuk Gudang",
      "opcode_4143": "Pengembalian Pinjaman",
      "opcode_4160": "Pengembalian Bahan Produksi",
      "opcode_4010": "Buka Akun Inventaris",
      "opcode_4020": "Periksa Stok Berdasarkan Periode",
      "opcode_4030": "Periksa Persediaan Berdasarkan Proyek",
      "opcode_4210": "Diambil dan Digunakan untuk Produksi",
      "opcode_4260": "Produksi Melewati Batas",
      "opcode_4220": "Pengiriman Penjualan",
      "opcode_4230": "Pengembalian Bahan yang Masuk",
      "opcode_4241": "Trasnfer Keluar Gudang",
      "opcode_4242": "Meminjamkan dari Gudang",
      "opcode_4243": "Pengembalian Barang yang Dipinjam",
      "opcode_4299": "Manajemen Produksi Berlebih",
      "opcode_4150": "Proses Barang Masuk Gudang",
      "opcode_4170": "Lainnya",
      "preN_4010": "No. Inventaris",
      "preN_4020": " No. Inventaris",
      "preN_4030": "No. Inventaris",
      "preN_4110": "No. Asal Barang",
      "preN_4120": "No. Asal Barang",
      "preN_4130": "No. Asal Barang",
      "preN_4220": "No. Asal Barang",
      "preN_4230": "No. Asal Barang",
      "preN_4141": "No. Asal Barang",
      "preN_4241": "No. Asal Barang",
      "preN_4242": "No. Asal Barang",
      "preN_4143": "No. Asal Barang",
      "preN_4142": "No. Asal Barang",
      "preN_4243": "No. Asal Barang",
      "preN_4210": "No. Asal Barang",
      "preN_4160": "No. Asal Barang",
      "preN_4260": "No. Asal Barang",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "No. Daftar Asal Bahan",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "operN_4299": "領用單號",
      "dueday_4010": "Tgl Barang Masuk Gudang",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "Tanggal Perkiraan Barang Masuk Gudang",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "Tanggal Barang Diambil Untuk Digunakan",
      "dueday_4160": "Tanggal Perkiraan Barang Keluar Gudang",
      "dueday_4260": "領用日期",
      "dueday_4299": "領用日期",
      "batchno_4010": "No. Batch Barang Masuk Gudang",
      "batchno_4020": "No. Batch Barang Masuk Gudang",
      "batchno_4030": "No. Batch Barang Masuk Gudang",
      "batchno_4110": "No. Batch Barang Masuk Gudang",
      "batchno_4120": "No. Batch Barang Masuk Gudang",
      "batchno_4130": "No. Batch Barang Masuk Gudang",
      "batchno_4141": "No. Batch Barang Masuk Gudang",
      "batchno_4143": "No. Batch Barang Masuk Gudang",
      "batchno_4142": "No. Batch Barang Masuk Gudang",
      "batchno_4243": "No. Batch Barang Keluar Gudang",
      "batchno_4220": "No. Batch Barang Keluar Gudang",
      "batchno_4242": "No. Batch Barang Keluar Gudang",
      "batchno_4230": "No. Batch Barang Keluar Gudang",
      "batchno_4241": "No. Batch Barang Keluar Gudang",
      "batchno_4210": "No. Batch Barang Keluar Gudang",
      "batchno_4160": "No. Batch Barang Masuk Gudang",
      "batchno_4260": "No. Batch Barang Keluar Gudang",
      "batchno_4299": "出庫批號",
      "dealID_4120": "ID Pabrik",
      "dealID_4110": "廠商ID",
      "dealID_4220": "ID Pelanggan",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "Nama Pabrik",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "Nama Pelanggan",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "Konfirmasi Pengajuan ",
      "txn_confirm_msg": "Konfirmasi Apakah Data yang Mau Dihapus Sudah Benar ",
      "opcode": "Jenis Lembar Kerja",
      "jobN": "No. Lembar Kerja",
      "planqty": "Jumlah yang Direncanakan",
      "realtqty": "Jumlah Sebenarnya",
      "bal": "Perbedaan Akun",
    },
    "plan-in": {
      "pageHeader": "Tugas Penyimpanan Barang di Gudang",
    },
    "plan-out": {
      "pageHeader": "Tugas Pengeluaran Barang dari Gudang",
    },
    "stock-search": {
      "searchBatchno": "批號查詢",
      "pageHeader": "Pengecekan Persediaan Barang",
      "searchPN": "Pengecekan No.Bahan",
      "searchWh": "Pengecekan Masing2 Gudang",
      "whDetails": "Rincian Gudang Cadangan",
      "changeDetails": "Rincian Transaksi",
      "borrow": "Meminjam",
      "inTransit": "Dalam Perjalanan",
      "loan": "Pinjaman",
      "lockqty": "Jumlah yang Diblokir",
      "onhandqty": "Jumlah Persediaan yang Bisa Digunakan",
      "planin": "Perencanaan Penyimpanan Barang",
      "planout": "Perencanaan Barang Keluar Gudang",
      "posted": "Sudah Dihitung",
      "scanin": "Pemesanan Bahan Masuk Gudang",
      "scanout": "Pengecekan Bahan Keluar Gudang",
      "timeSeries": "Urutan Waktu",
      "total": "Jumlah Perkiraan Barang yang Dapat Digunakan",
      "totalIn": "Total Barang yang Masuk Gudang",
      "totalOut": "Total Barang yang Keluar Gudang",
      "tqty": "Jumlah Persediaan",
    },
    "inv-history": {
      "pageHeader": "Catatan Penyimpanan Bulanan",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "Tebal",
      "inCBM": "進\n(CBM)",
      "l": "Panjang",
      "level": "Level",
      "suppalias": "Pemasok",
      "w": "Lebar",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "代理入庫作業",
      "lifeF_T": "已規劃",
      "wmsopUUID": "庫儲作業單號",
      "inchargeID": "負責人員",
    },
  },
  "ADM": {
    "util": {
      "pfNM": "Nama Proses",
      "pushlayer": "Level Notifikasi",
      "pf": "Prosses Produksi",
      "pfID": "No.ID Proses ",
      "pwc": "Pusat Kerja",
      "wpcSearch": "Pencarian Surat Jalan",
      "dispachManage": "Manajemen Pengiriman",
      "ppidp": "Pengikatan Produk Jadi",
      "partsBinding": "Kode Batang Suku Cadang",
      "mail": "Email",
      "postID": "Jabatan Kerja",
      "sectID": "工類",
      "sectID_C": "間接人力",
      "sectID_D": "直接人力",
    },
    "oem-manage": {
      "type": "Kategori Outscoring",
      "oem_item_manage": "Pengaturan Proyek Outscoring",
      "oem_item": "Proyek Outscoring",
      "type_A": "Tenaga Kerja",
      "type_B": "Tampilan",
      "type_C": "Tenaga Mesin",
      "oem_supp_manage": "Pengalihdayaan Dalam Pemeliharaan",
      "oem_supp": "Pengalihdayaan",
      "vendorcode": "Kode Pasokan",
      "oemitemNM": "Nama Proyek Outscoring",
    },
    "function-params-manage": {
      "pageHeader": "Pengaturan Sistem Parameter]",
      "searchPlaceholder": "Cari ID, Bagian Kerja, No.Kode, Kode Fungsi, Nama Level Jabatan",
      "functions": "Kelompok Notifikasi",
      "unitNM1": "Nama Bagian",
      "type": "Tipe Pengukuran",
      "category": "Kategori Pengukuran",
      "countType": "Tipe Perhitungan",
      "quantitative": "Kuantitatif",
      "count": "計數",
      "weight": "Berat",
      "length": "Panjang",
      "capacity": "Volume",
      "area": "Luas",
      "volume": "Volume",
      "sort": "Urutkan",
      "currencyitem": "Kode Mata Uang",
      "itemNM": "Nama Mata Uang",
      "currency": "Mata Uang Transaksi",
      "ismycurrency": "Mata Uang Lokal",
      "funcode": "Kode Fungsi Organisasi",
      "funNM": "Nama Fungsi",
      "function": "Pengaturan Grup Notifikasi",
      "lvlcode": "Kode Level",
      "lvlNM": "Nama Level",
      "team": "1 (Tim Kerja)",
      "class": "2 (Kepala Mandor)",
      "management": "3(Manajer)",
      "total": "4 (Bos)",
      "eventID": "ID Acara",
      "eventNM": "Nama Acara",
      "rptcode": "Kategori Laporan",
      "pusher": "Sistem Pengiriman",
      "situF": "Lampu Peringatan",
      "audience": "Penerima Notifikasi",
      "startlvl": "Tingkat Awal",
      "setlvl": "Seri Notifikasi",
      "cruisebot": "Program Pemantauan",
      "msgmodel": "Contoh Pesan Notifikasi",
      "pushEvent": "Notifikasi Acara",
      "rptcode_A0": "Laporan Karyawan",
      "rptcode_B0": "Sistem Notifikasi (Wos)",
      "rptcode_C0": "Laporan Perlengkapan",
      "rptcode_D0": "Laporan Manajemen_Remot",
      "rptcode_E0": "Laporan Manajemen_Penyimpanan",
      "situF_Y": "Kuning",
      "situF_R": "Merah",
      "startlvl_0": "Semua Karyawan",
      "startlvl_1": "Pemimpin Tim",
      "startlvl_2": "Departemen Kepala Mandor",
      "startlvl_3": "Manager",
      "startlvl_4": "Kepala Supervisor ",
      "add_string": "Rangkaian",
      "add_number": "Nomor Baru",
      "add_point": "Tambahkan Titik Desimal",
      "1_decimal_place": "Angka desimal mundur 1",
      "2_decimal_place": "Angka desimal mundur 2",
    },
    "procedure-manage": {
      "pageHeader": "Perencanaan Proses Dalam Pemeliharaan",
      "pccID": "No. ID Pusat Modal",
      "pccNM": "Nama Pusat Modal",
      "pwcID": "No. ID Pusat Kerja",
      "pwcNM": "Nama Pusat Kerja",
      "tabCost": "Pengaturan Pusat Modal",
      "tabFlow": "Pengaturan Proses Dokumen Induk ",
      "tabWork": "Pengaturan Pusat Kerja",
    },
    "user-manage": {
      "dashboard": "Produksi Digital Kanban",
      "abc-board": "Manajemen Tidak Biasa Kanban",
      "BDM": "Manajemen Data Bahan",
      "SDM": "Manajemen Data Sistem",
      "TDM": "Manajemen Data Teknis",
      "EDM": "Manajemen Data Peralatan",
      "WMS": "Sistem Manajemen Gudang",
      "OMS": "Sistem Manajemen Penjualan",
      "PMS": "Sistem Manajemen Pembelian",
      "WOC": "Kontrol Produksi",
      "MC": "Pekerjaan Bahan",
      "QC": "Pekerjaan Kontrol Kualitas Barang",
      "TK": "Produksi Kanban",
      "account": "No. Akun Karyawan",
      "pwd": "Kata Sandi",
      "role": "Izin Peran",
      "roleID": "ID Peran",
      "roleNM": "Nama Peran",
      "systemAuth": "Izin Masuk Sistem",
      "autoReport": "Terima Laporan Otomatis",
      "APP": "Apilkasi Seluler",
      "PDA": "Izin PDA",
      "dealmgmt": "Kategori Izin Tujuan",
      "pdtmgmt": "Akses Grup Bahan",
      "whmgmt": "Kategori Izin Gudang",
      "cardID": "No. Kartu",
      "function": "Fungsi Organisasi",
      "pageHeader": "Data Karyawan Dalam Pemeliharaan",
      "peopleNM": "Nama Karyawan",
      "personalID": "No. ID ",
      "proxyNM": "Nama Orang yang Dapat Dihubungi",
      "proxytel": "No. Telp Orang yang Dapat Dihubungi",
      "staffAuth": "Pengaturan Izin Peran",
      "staffData": "Manajemen Data Karyawan",
      "staffID": "No. Karyawan",
    },
    "trading-pattern": {
      "OMS": "Sistem Pemesanan",
      "PMS": "Sistem Pembelian",
      "adda": "Syarat & Ketentuan",
      "addb": "Potongan Tambahan",
      "addc": "Pekerjaan  Prabayar",
      "addd": "Cara Pembayaran",
      "adde": "Syarat Perdagangan",
      "addf": "Pengembalian Barang",
      "addg": "Batalkan Pesanan",
      "addh": "Syarat & Ketentuan Pembayaran",
      "addi": "Syarat & Ketentuan Pengambilan Biaya",
      "additem": "No. ID Proyek",
      "addr": "Alamat yang Dapat Dihubungi",
      "addtype": "Syarat & Ketentuan Transaksi",
      "contactitem": "Kode Pengurus yang Dapat Dihubungi",
      "department": "Departemen",
      "itemNM": "Nama Pengurus yang Dapat Dihubungi",
      "mobile": "No. HP",
      "pageHeader": "Pengaturan Rangkaian yang Sering Digunakan",
      "tabAdded": "Pengaturan Proyek Tambahan",
      "tabWindow": "Pengaturan Pengurus Eksternal",
      "tabPMSAdded": "Ketentuan Tambahan Pembelian",
      "tabOMSAdded": "Ketentuan Tambahan Penjualan",
    },
    "data-auth-manage": {
      "mgmtitem": "Kategori ",
      "pageHeader": "Pengaturan Fungsi Otorisasi",
      "tabCust": "Grup Otorisasi Pelanggan",
      "tabPdt": "Grup Otorisasi Bahan",
      "tabSupp": "Grup Otorisasi Modal Pabrik",
      "tabWh": "Grup Otorisasi Gudang",
    },
    "category-manage": {
      "mgmtitem": "Kategori",
      "pageHeader": "Pengaturan Kategori Pencarian",
      "tabCust": "Pencarian Mengenai Kategori Pelanggan",
      "tabQuery": "Pencarian Mengenai Kategori Bahan",
      "tabSupp": "Pencarian Kategori Pabrik",
      "tabMtype": "Klasifikasi Jenis Bahan",
    },
    "warehouse-manage": {
      "cancelClose": "Batalkan Penutupan Akun",
      "close": "Tutup Akun",
      "isClose": "Tutup Akun?",
      "mgmtcat": "Perizinan Gudang",
      "pageHeader": "Gudang Dalam Perencanaan dan Pemeliharaan",
      "pdtclass": "Level BOM",
      "ps": "Bengkel",
      "tabWareHouse": "Pengaturan Data Gudang",
      "whID": "No. ID Gudang",
      "whNM": "Nama Gudang",
      "psNM": "Nama Bengkel",
    },
    "string-manage": {
      "pageHeader": "Manajemen Rantai Fungsi",
      "tabPause": "Penghentian Prioyek",
      "tabOkItem": "Produk Bagus",
      "tabNgItem": "Produk Rusak",
      "tabDri": "Bagian yang Bertanggungjawab",
      "tabOffLine": "Proyek Offline",
      "driItemID": "ID Bagian",
      "driItemNM": "Nama Bagian",
    },
    "client": {
      "groupalias": "Nama Panggilan",
      "location": "Negara",
      "account": "Akun Bank",
      "addr": "Alamat Pabrik",
      "bankAddrOS": "Alamat Bank",
      "bankCodeOS": "No. Telp Bank Cabang",
      "branchOS": "Nama Bank Cabang",
      "classificationOfRestrictions": "Kategori Pembatasan",
      "country": "Kewarganegaraan",
      "customerBasicInformation": "Informasi Dasar Pelanggan",
      "customerContactInformation": "Informasi Kontak Pelanggan",
      "department": "Departemen",
      "domesticBankName": "Nama Bank Dalam Negeri",
      "domestic_OverseasBankInformation": "Informasi Bank Dalam/ Luar Negeri",
      "item": "10 Baris/ Halaman",
      "itemNM": "Fitur Pengurus yang Dapat Dihubungi",
      "maxcredit": "Syarat & Ketentuan Pembayaran Kredit",
      "mobile": "No. HP",
      "overseasBankName": "Nama Bank Luar Negeri",
      "pageHeader": "Data Pelanggan Dalam Pemeliharaan",
      "pageHeader2": "Pengelolaan Profil Pelanggan",
      "payterm": "Syarat & Ketentuan Pembayaran",
      "showTotal": "Total 1 Barang， Sedang Melihat Barang ke-1 sampai 1",
      "swiftCode": "Kode Swift",
      "taxID": "No. Induk",
      "username": "Nama Akun",
    },
    "company": {
      "manufacturerBasicInformation": "Informasi Dasar Mengenai Pabrik",
      "manufacturerContactInformation": "Informasi Orang Pabrik yang Dapat Dihubungi",
      "pageHeader": "Data Mengenai Pabrik Sedang Dalam Pemeliharaan",
      "groupalias": "Nama Singkatan Grup",
      "location": "Negara",
      "account": "Akun Bank",
      "addr": "Alamat Pabrik",
      "bankAddrOS": "Alamat Bank",
      "bankCodeOS": "No. ID Bank Cabang",
      "branchOS": "Nama Bank Cabang",
      "classificationOfRestrictions": "Klasifikasi Larangan",
      "country": "Negara",
      "department": "Departemen",
      "domesticBankName": "Nama Bank Dalam Negeri",
      "domestic_OverseasBankInformation": "Nama Bank Dalam/ Luar Negeri",
      "item": "10 Baris/ Halaman",
      "itemNM": "Fitur Pengurus yang Dapat Dihubungi",
      "maxcredit": "Ketentuan Pembayaran Kredit",
      "mobile": "No. HP",
      "overseasBankName": "Nama Bank Luar Negeri",
      "payterm": "Syarat Pembayaran",
      "showTotal": "Total 1 Baris, Sedang Melihat Baris ke-1 sampai 1",
      "swiftCode": "Kode Swift",
      "taxID": "No. Induk",
      "username": "Nama Akun",
    },
    "edm": {
      "alertspeed": "Alarm Laju Produksi",
      "alertstop": "Alarm Berhenti",
      "warnyield": "Menghaslkan Alarm Warna Kuning",
      "alertyield": "Menghasilkan Alarm Berwarna Merah",
      "attribute": "Sifat Bahan",
      "bom": "BOM dalam pemeliharaan",
      "errorPleaseFull": "Tuliskan Informasi Lengkap",
      "ieUUID": "No. Lembar Perhitungan",
      "isrework": "Apakah Proses Pengerjaan Harus Diulang?",
      "level": "Level BOM",
      "nextSN": "No. Proses Kerja Selanjutnya",
      "pPN": "No. Barang Induk",
      "pageHeader": "Data Teknis Dalam Pemeliharaan",
      "pdtclass": "Kategori Produk",
      "pfNM": "Proses",
      "planhead": "Jumlah Pekerja yang Direncanakan",
      "ppdtNM": "Nama Produk Induk",
      "productionScheduleDetails": "Rincian Jadwal Produksi",
      "qty": "No. BOM",
      "stdlt": "Waktu Persiapan Produksi",
      "stdmh": "Waktu Standar Pengerjaan Mesin",
      "stdwh": "Waktu Standar",
      "batchbom": "No. Patch Kemasan Lengkap",
      "ver": "Versi",
      "wpcManage": "Rute Proses Dalam Pemeliharaan",
      "wpc": "Proses Produksi",
      "wpcSN": "No. Proses Kerja",
      "wpcSetting": "Pengaturan No. Proses Kerja",
      "wpcset": "Grup Proses Kerja",
      "wpcsetNM": "Nama Proses Kerja",
      "whID": "Jenis Gudang",
      "alternative_wpc": "Proses Alternatif",
      "main_wpc": "Proses Utama",
      "edit_all_wpc": "Edit Seluruh Proses",
      "Pfc": "Kontrol Versi Dokumen Proyek",
      "doc": "Masalah Standar Dokumen ",
      "ECN": "Ubah Notifikasi Teknis",
      "pdftype": "Tipe Dokumen",
      "docUpload": "Unggah Dokumen",
      "Upload": "Unggah",
      "docName": "Nama Dokumen",
      "docVer": "Versi Dokumen",
      "img": "圖像",
    },
    "pn-manage": {
      "bom": "單位轉換",
      "basicProduct": "Informasi Dasar Mengenai Produk",
      "dealPNSearch": "Pencarian No. Bahan Transaksi",
      "boxH": "Ukuran Kemasan (Tinggi)",
      "boxL": "Ukuran Kemasan (Panjang)",
      "boxW": "Ukuran Kemasan (Lebar)",
      "dealID": "No. ID Tujuan",
      "dealNM": "Nama Produk Transaksi",
      "dealPN": "Nomor Bahan Transaksi/SKU",
      "dealalias": "Nama Singkatan Tujuan",
      "dealpid": "Barcode",
      "idkw": "Barcode Kemasan",
      "inititalcost": "Jadwal Awal",
      "isfocus": "Perhatikan Secara Khusus?",
      "isvalid": "Apakah Berfungsi?",
      "maxcost": "Batas Atas Harga Penyimpanan",
      "maxstock": "Batas Atas Penyimpanan",
      "minstock": "Batas Bawah Penyimpanan",
      "moq": "Jumlah Pembelian Minimum",
      "newPN": "No. Versi Baru",
      "nextpacksn": "Lapisan Luar Pengemasan\\",
      "pack": "Satuan yang Digunakan Dalam Pengemasan",
      "packsn": "Lapisan Pengemasan",
      "packtype": "Tujuan Pengemasan",
      "pageHeader": "Data Dasar Dalam Pemeliharaan",
      "palH": "Tinggi Palet",
      "palL": "Panjang Palet",
      "palW": "Lebar Palet",
      "pbom": "No. Grup Lapisan Bagian Dalam Pengemasan",
      "pdtH": "Ukuran Produk (Tinggi) ",
      "pdtL": "Ukuran Produk (Panjang)",
      "pdtW": "Ukuran Produk (Lebar)",
      "punit": "Unit Penggunaan Lapisan Bagian Dalam",
      "purchasecost": "Modal Pembelian Bahan Dalam Periode Ini",
      "purchaseqty": "Jumlah Bahan yang Masuk Dalam Periode Ini",
      "replacePN": "Nomor Bagian Cadangan",
      "safestock": "Persediaan Keselamatan",
      "salecurrency": "Mata Uang yang Digunakan",
      "setbatch": "Menggunakan Nomor Lot",
      "setidm": "Pengaturan Barcode",
      "setpack": "Pengaturan Pengemasan",
      "settlemonth": "Ubah Bulan Perhitungan Dilakukan",
      "shipV": "Volume Kemasan",
      "shipW": "Berat Barang yang Dikirim",
      "sizeunit": "Satuan Pengukuran",
      "stdcost": "Modal Standar",
      "stdsale": "Harga Standar",
      "stdstock": "Persediaan Standar",
      "stocklife": "Periode Penyimpanan",
      "tabCost": "Pengaturan Modal Standar",
      "tabDeal": "Pengaturan No. Bahan Transaksi",
      "tabExtra": "Pengaturan Ukuran Produk",
      "tabIdkw": "Pengaturan Pengikatan Produksi",
      "tabPack": "Pengaturan Pengikatan Kemasan",
      "tabPrice": "Pengaturan Harga Standar",
      "tabStock": "Pengaturan Kondisi Gudang",
      "unitwt": "Perhitungan Berat Tunggal",
      "varcost": "Ubah Harga Modal",
      "ver": "Versi Standar Pengemasan",
      "weightunit": "Satuan Berat",
    },
    "mold": {
      "pageHeader": "Data Cetakan Dalam Pemeliharaan",
      "mold": "Data Mengenai Cetakan",
      "assetkind": "Tipe Cetakan",
      "assetID": "No.ID Cetakan",
      "assetNM": "Nama Aset",
      "moldtype": "Mode Umum",
      "cavity": "No.Lubang Sebenarnya",
      "stdcavity": "No. Lubang Sesuai Standar",
      "validcavity": "No. Lubang yang Bisa Digunakan",
      "wsID": "Stasiun Kerja",
      "property": "Bentuk Kepemilikan Properti",
      "company": "Perusahaan",
      "gatesystem": "Tipe Gerbang",
      "moldlife": "Merancang Hidup",
      "draw": "Gambar Cetakan",
      "pdtpic": "Foto Barang Jadi",
      "malepic": "Foto Cetakan Utama",
      "femalepic": "Foto Cetakan Induk",
      "wholepic": "Foto Campuran",
      "advance": "Data Cetakan Tingkat Lanjut",
      "length": "Panjang Cetakan",
      "width": "Lebar Cetakan",
      "height": "Tinggi Cetakan",
      "weight": "Berat Cetakan",
      "openC": "Buka Akun Modul",
      "openT": "Mendapatkan Tanggal",
      "cummoldC": "Modulus Kumulatif  Produksi Akhir",
      "precummoldC": "Modulus Kumulatif Produksi Sebelumnya",
      "status": "Lampu Status",
      "note": "Catatan Cetakan",
    },
    "plant-manage": {
      "pageHeader": "Pengaturan Dokumen Induk Pabrik",
      "plantID": "Pabrik",
      "deptID": "Departemen",
      "push1": "Interval Level Notifikasi 1 (Menit)",
      "push2": "Interval Level Notifikasi 2 (Menit)",
      "push3": "Interval Level Notifikasi 3 (Menit)",
      "tabDept": "File Induk Departemen",
      "tabPlant": "Dokumen Induk Mengenai Area Pabrik",
      "tabPost": "Dokumen Induk Jabatan",
    },
    "production-line-manage": {
      "MC": "Pekerjaan Bahan",
      "QC": "Pekerjaan Kontrol Kualitas ",
      "TK": "Produksi Kanban",
      "WOC": "Kontrol Produksi",
      "auth_setting": "Pengaturan Izin",
      "batch": "Manajemen No. Batch",
      "mold": "Manajemen Cetakan",
      "oem": "Ourscoring",
      "pageHeader": "Perencanaan dan Pemeliharaan Line Produksi",
      "pcc": "Pusat Modal",
      "ps": "Bengkel",
      "psID": "No. ID Bengkel",
      "psNM": "Nama Bengkel",
      "tabLine": "Pengaturan Data Line Kerja",
      "tabPS": "Pengaturan Data Mengenai Bengkel",
      "tabStation": "Pengaturan Data Stasiun Kerja",
      "tbHeader_PS": "Data Stasiun Kerja",
      "tbHeader_Station": "Data Stasiun Kerja'",
      "whm": "Manajemen Waktu",
      "wlID": "ID Line Kerja (WL)",
      "wlNM": "Nama Line Kerja (WL)",
      "ws": "Stasiun Kerja",
      "wsID": "No. ID Stasiun Kerja",
      "wsNM": "Nama Stasiun Kerja",
    },
  },
  "APS": {
    "util": {
      "opcode": "Jenis Lembar Kerja",
      "opcode_3310": "Lembar Kerja Normal",
      "opcode_3320": "Lembar Kerja Berat",
      "opcode_3330": "Lembar Kerja Barang Sampel",
      "opcode_3340": "Lembar Kerja Mesin",
      "duestart": "Perkiraan Tanggal Selesai (Mulai)",
      "dueend": "Perkiraan Tanggal Selesai (Sampai)",
      "realqty": "Jumlah Sebenarnya",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "Pekerjaan Pengiriman Manajemen Produksi",
      "wo": "Lembar Kerja",
      "tabWo": "Dokumen Induk Lembar Kerja",
      "mo": "No. Bahan",
      "jobN": "No. Lembar Kerja",
      "tabMo": "Dokumen Induk No. Bahan",
      "orderN": "No. Lembar Pemesanan Pelanggan",
      "ETDstart": "Perkiraan Tanggal Mulai Pengiriman",
      "ETDend": "Perkiraan Tanggal Selesai Pengiriman",
      "issueID": "Penerbit",
      "issueday": "Tanggal Terbit",
    },
  },
  "PMS": {
    "util": {
      "opcode_2010": "Rencana Harga",
      "opcode_2210": "Permintaan Pembelian Dalam Negeri",
      "opcode_2220": "Permintaan Pembelian Luar Negeri",
      "opcode_2230": "Aplikasi Outscoring",
      "opcode_2310": "Pembelian Dalam Negeri",
      "opcode_2320": "Pembelian Luar Negeri",
      "opcode_2330": "Penambahan Pekerja Outscoring",
      "opcode_2410": "Pembelian Bahan Dalam Negeri",
      "opcode_2420": "Masuk Bahan Asal Luar Negeri",
      "opcode_2430": "Outscoring",
      "opcode_2610": "Pengembalian Barang Gudang",
      "opcode_2620": "Pengembalian Barang dari QC",
      "opcode_2710": " Pembelian bahan baku",
      "opcode_2810": "Saldo Pendapatan dan Hutang'",
      "opcode_4120": "Lembar Penerimaan",
      "opcode_4230": "Lembar Pengembalian Barang yang Selesai Diperiksa",
      "plan": "Daftar Permintaan Penawaran",
      "req": "Lembar Permintaan",
      "pro": "Daftar Pembelian",
      "instock": "Lembar Masuk Bahan",
      "gostock": "Lembar Pengecekan Bahan yang Masuk",
      "return": "Pengembalian Bahan yang Masuk",
      "pay": "Manajemen Hutang",
      "ap": "Lembar Rekonsiliasi",
      "strike": "Pendapatan Saldo Pembayaran",
      "N22": "No. Lembar Permintaan",
      "N23": "No. Lembar Pembelian",
      "N24": "No. Daftar Bahan",
      "N27": "No. Lembar Pernyataan",
      "N28": "Jumlah Tagihan",
      "outN": "No. Lembar Pengembalian Setelah Pengecekan",
      "planSearch": "Pencarian Catatan Penawaran",
      "reqSearch": "Pencarian Catatan Pembelian",
      "proSearch": "Pencarian Catatan Pembelian",
      "instockSearch": "Pencarian Catatan Masuk Bahan",
      "stockSearch": "Pencarian Catatan Pengecekan",
      "strikeSearch": "Pencarian Catatan Penyeimbangan",
      "apSearch": "Pencarian Data Rekonsiliasi",
      "drawno": "No. Gambar",
      "unitprice": "Harga/Pembelian",
      "localprice": "Harga/Barang Dalam Mata Uang Saat Ini",
    },
    "plan": {
      "pageHeader": "Rencana Harga",
      "hd_delete_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data yang Mau Dihapus Sudah Benar",
      "demandqty": "Jumlah Permintaan",
      "tb": "Rincian Perencanaan",
      "tb_drawer_title": "Rincian Rencana Harga",
      "rec_drawer_title": "Evaluasi Spesifikasi",
    },
    "req": {
      "pageHeader": "Pekerjaan Permintaan Pembelian",
      "create_header": "Tambah Dokumen Induk Permintaan Pembelian",
      "edit_header": "Edit Dokumen Induk Permintaan Pembelian",
      "hd_note": "Catatan Permintaan Pembelian",
      "driID": "Penanggungjawab Permintaan Pembelian",
      "punit": "Unit Permintaan Pembelian",
      "unitprice": "Harga/Permintaan (Tanpa Pajak)",
      "demandqty": "Jumlah Permintaan Pembelian",
      "demandDate": "Tanggal Permintaan",
      "itemtotal": "Jumlah Total Tanpa Pajak",
      "local_subtotal": "Jumlah Total Termasuk Pajak Dalam Mata Uang Saat Ini",
      "total": "Jumlah Tptal Termasuk Pajak (Mata Uang Transaksi)",
      "lifeF_0": "Rilis",
      "lifeF_2": "Tinjauan Permintaan Pembelian",
      "lifeF_7": "Tinjauan dan Tandatangan Selesai",
      "lifeF_T": "Tutup Kasus",
      "del_hd_confirm_text":
        "Sebelum Menghapus, Konfiramsi Apakah Daftar Permintaan Pembelian yang Mau Dihapus Sudah Benar",
      "audit_start": "Tinjau Tandatangan Resmi",
      "audit_finish": "Tinjauan Berhasil",
      "reqCheck": "Tinjauan Daftar Permintaan Pembelian",
      "cancel_reqCheck": "Batalka Tinjauan Permintaan Pembelian",
      "check_confirm": "Konfirmasi Tinjauan Permintaan Pembelian",
      "check_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_check_confirm": "Batalkan Tinjauan Permintaan Pembelian",
      "cancel_check_confirm_text": "Sebelum Membatalkan Tinjauan, Konfirmasi Apakah ",
      "reqCheck_lifeF_0_okText": "Konfirmasi Tinjauan",
      "reqCheck_lifeF_2_okText": "Batalkan Tinjauan",
      "audit_confirm": "Konfirmasi Tandatangan Tinjauan",
      "audit_confirm_text":
        "Setelah Ditinjau, Daftar Pembelian Tidak Dapat Lagi Diedit, Apakah Anda Yakin Untuk Melakukan Tinjauan?",
      "audit_okText": "Konfirmasi Tinjauan",
      "driID_placeholder": "Pilih Karyawan yang Melakukan Permintaan Pembelian",
      "demandqty_placeholder": "Tuliskan Jumlah Barang yang Diminta Untuk Pembelian",
      "unitprice_placeholder": "Tuliskan Harga/Satuan Tanpa Pajak",
      "tb_delete_confirm": "Konfirmasi Penghapusan Rincian Permintaan Pembelian",
      "tb_title": "Rincian Pembelian",
      "create_tb_title": "Tambah Rincian Permintaan Pembelian",
      "edit_tb_title": "Edit Rincian Permintaan Pembelian",
      "throw_modal_submit": "Konfirmasi Transfer (Daftar Pembelian)",
    },
    "pro": {
      "pageHeader": "Pembelian",
      "create_header": "Buat Dokumen Induk Pembelian",
      "edit_header": "Edit Dokumen Induk Pembelian",
      "hd_drawer_title": "Dokumen Induk Lembar Pembelian",
      "driID": "Penanggungjawab Pembelian",
      "sampleID": "Pengambil Sampel",
      "supp_history": "Pencarian Riwayat Transaksi Pabrik",
      "trade": "Ketentuan Pertukaran",
      "dealaddr": "Alamat Pabrik",
      "contact": "Penanggungjawab Pabrik Bagian Pengiriman",
      "dealcontact": "Penanggungjawab Pabrik Bagian Bisnis",
      "payment": "Ketentuan Pembayaran",
      "sumtotal": "Harga Tanpa Pajak",
      "local_subtotal": "Total Dalam Mata Uang Saat Ini Termasuk Pajak",
      "total": "Jumlah Termasuk Pajak",
      "local_total": "Total Termasuk Pajak (Mata Uang Transaksi)",
      "note": "Catatan Pembelian",
      "demandqty": "Jumlah Pembelian",
      "punit": "Unit Pembelian",
      "confirmqty": "Jumlah Konfirmasi Pabrik",
      "dealN": "No. Pesanan Pabrik",
      "cancelqty": "Batalkan Jumlah",
      "returnqty": "Jumlah Barang yang Dikembalikan",
      "charge": "Total Potongan Tambahan",
      "ReviewProcess": "Proses Tinjauan",
      "tb_drawer_title": "Rincian Pesanan Pembelian",
      "lifeF_0": "Rilis",
      "lifeF_2": "Tinjauan Pembelian",
      "lifeF_7": "Tandatangan Pabrik",
      "lifeF_T": "Tutup Kasus",
      "del_hd_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Daftar Pesanan Pembelian yang Mau Dihapus Sudah Benar",
      "hd_close_confirm_text":
        "Sebelum Tutup Pesanan, Konfirmasi Apakah Pesanan yang Mau Ditutup Sudah Benar",
      "proT": "Tanggal Pembelian",
      "dealN_placeholder": "Masukkan No. Daftar Pabrik",
      "payment_placeholder": "Pilih Cara Pembayaran",
      "trade_placeholder": "Pilih Ketentuan Perdagangan",
      "dealcontact_placeholder": "Pilih Penanggungjawab Bisnis Pabrik",
      "contact_placeholder": "Pilih Penanggungjawab Pabrik Untuk Pengiriman Barang ",
      "unitprice_placeholder": "Tuliskan Harga/Barang Tanpa Pajak",
      "demandqty_placeholder": "Tuliskan Jumlah Pembelian",
      "note_placeholder": "Masukkan Catatan Pembelian",
      "statement_placeholder": "Masukkan Penjelasan Proyek",
      "amount_placeholder": "Masukkan Jumlah",
      "proCheck": "Tinjauan Pembelian(1)",
      "cancel_proCheck": "Batalkan Tinjauan",
      "suppCheck": "Tandatangan Pabrik (2)",
      "proCheck_confirm": "Konfirmasi Tinjauan Pembelian",
      "proCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Pembelian yang Mau Ditinjau Sudah Benar",
      "cancel_proCheck_confirm_text":
        "Sebelum Membatalkan Tinjauan Pembelian, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "suppCheck_confirm": "Konfirmasi Tandatangan Pabrik",
      "suppCheck_confirm_text":
        "Sebelum Tandatangan, Konfirmasi Apakah Data yang Mau Ditandatangani Sudah Benar",
      "cancel_suppCheck_confirm": "Konfirmasi Pembatalan Tandatangan Pabrik",
      "cancel_suppCheck_confirm_text":
        "Sebelum Membatalkan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "tb": "Rincian Pembelian",
      "adda": "Syarat & Ketentuan",
      "adda_delete_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Ketentuan yang Mau Dihapus Sudah Benar",
      "addb": "Potongan Tambahan",
      "addb_delete_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Pembayaran yang Mau Dihapus Sudah Benar",
      "addc": "Tugas Prabayar",
      "addc_delete_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Pekerjaan Prabayar yang Mau Dihapus Sudah Benar",
      "statement": "Penjelasan Proyek",
      "amount": "Jumlah Tanpa Pajak",
      "selectstatement": "Pilih Jenis Tambahan Potongan",
      "totalStart": "Nilai Minimum",
      "totalEnd": "Nilai Maksimum",
      "deal_history": "Pencarian Riwayat Transaksi Produk",
      "transferqty": "Jumlah yang Sudah Ditransfer",
      "throwqty": "Jumlah yang Ditransfer Kali Ini",
      "message_transfer_small_than_0": "Jumlah yang Ditransfer Tidak Boleh Di Bawah 0",
      "exit_Disposal": "Pembuangan Keluar",
    },
    "instock": {
      "pageHeader": " Pembelian Masuk",
      "create_header": "Tambah Dokumen Induk Masuk Bahan",
      "edit_header": "Edit Dokumen Induk Masuk Bahan",
      "hd_drawer_title": "Dokumen Induk Lembar Masuk Bahan",
      "sampleID": "Pengambil Sampel",
      "sampleT": "Data Pengambilan Sampel",
      "note": "Catatan Masuk Bahan",
      "del_hd_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Unit Masuk Bahan yang Mau Dihapus Sudah Benar",
      "tb": "Rincian Masuk Bahan",
      "goqty": "Jumlah Barang yang Diterima untuk Pemeriksaan",
      "rejectqty": "Jumlah Pengecekan yang Dikembalikan",
      "indqty": "Jumlah Penangguhan",
      "inspectID": "Pemeriksa",
      "inspectT": "Waktu Pemeriksaan Selesai",
      "qalID": "Pekerja Bagian QC",
      "qalT": "Waktu Rilis",
      "lifeF_0": "Bagikan",
      "lifeF_2": "Tinjau Pemasukan Bahan",
      "lifeF_3": "Pemeriksaan Sampel IQC",
      "lifeF_4": "Pemeriksaan IQC  Selesai",
      "lifeF_7": "Tinjauan Kualitas Produk",
      "lifeF_T": "Tutup Kasus",
      "IQCstart": "Pengambilan Sampel untuk Diperiksa",
      "QAcheck": "Rilis Jaminan Kualitas ",
      "punit": "Satuan Masuk Bahan",
      "inbatchno": "No. Batch Pemasukan Bahan",
      "judgeT": "Tanggal Penilaian Jaminan Kualitas",
      "instockCheck_confirm": "Konfiramsi Peninjauan Masuk Bahan",
      "instockCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_instockCheck_confirm_text":
        "Sebelum Batalkan Peninjauan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "IQCcheck_confirm": "Konfirmasi Pengecekan Sampel ",
      "IQCcheck_confirm_text":
        "Sebelum Pengambilan Sampel, Konfirmasi Apakah Data Pengambilan Sampel Sudah Benar",
      "cancel_IQCcheck_confirm_text":
        "Sebelum Batalkan Pengambilan Sampel, Konfirmasi Apakah Data Sampel Sudah Benar",
      "IQCfinish_confirm": "Konfirmasi Penyelesaian Pengecekan IQC",
      "IQCfinish_confirm_text":
        "Sebelum Selesai Pengecekan IQC, Konfirmasi Apakah Data yang Mau Dicek Sudah Benar",
      "cancel_IQCfinish_confirm_text":
        "Sebelum Membatalkan Pengecekan IQC, Konfirmasi Apakah Data Sudah Benar",
      "QAcheck_confirm": "Konfirmasi Perilisan Jaminan Kualitas",
      "QAcheck_confirm_text":
        "Sebelum Merilis Jaminan Kualitas, Konfirmasi Apakah Data Sudah Benar",
      "cancel_QAcheck_confirm_text":
        "Sebelum Batalkan Perilisan Jaminan Kualitas, Konfirmasi Apakah Data Sudah Benar",
      "QAQTYcheck":
        "Rincian Masuk Bahan (Jumlah Penolakan Jaminan Kualitas)+(Jumlah Rilis Jaminan Kualitas) Harus Menunggu (Jumlah Masuk Bahan)",
      "throwStock_message":
        "Anda Akan Memasuki Operasi Transfer Daftar Pemeriksaan Bahan yang Masuk, Apakah Anda yakin untuk Transfer?",
      "dealN": "No Pesanan Pabrik",
      "takeqty": "No. Kutipan",
      "unitprice_placeholder": "Tuliskan Harga/Barang Tanpa Pajak",
      "Tooltip_disable_take_balqty_0": "Jumlah Sisa 0, Tidak Bisa Lanjutkan Kutipan",
      "message_take_before_choose": "Pilih Kutipan, Baru Masukkan Jumlah Kutipan",
      "message_transfer_small_than_0": "Jumlah yang Ditransfer Tidak Boleh Di Bawah 0",
      "message_take_same_detail": "Tidak Boleh Mengutip Rincian yang Sama",
    },
    "gostock": {
      "pageHeader": "Pencarian Mengenai Pengecekan Bahan yang Masuk ",
      "4120_header": "Dokumen Induk Barang yang Diterima untuk Pemeriksaan",
      "4230_header": "Dokumen Induk Barang yang Dikembalikan Setelah Pemeriksaan",
      "total": "Total Uang Yang Ditransfer",
      "planqty": "Jumlah yang Ditransfer",
      "jobN": "No. Lembar Asal Barang",
      "batchno_placeholder": "Masukkan No. Batch",
      "note": "Catatan",
      "tb": "Rincian Barang yang Diterima Untuk Pemeriksaan",
      "lifeF_0": "Rilis",
      "lifeF_T": "Tutup Kasus",
    },
    "ap-manage": {
      "pageHeader": "Manajemen Hutang",
      "ap_create_header": "Buat Data Induk Pernyataan Baru",
      "ap_edit_header": "Edit Pernyataan Induk",
      "ap_sumtotal": "Jumlah Total ",
      "strike_sumtotal": "Total Tagihan",
      "takeAp": "Kutipan Lembar Pernyataan",
      "contactWindow": "Data Kontak Pengurus yang Dapat Dihubungi",
      "chooseWindow": "Pilih Pengurus",
      "ap_tb": "Rincian Pernyataan",
      "strike_tb": "Rincian Lembar Tagihan",
      "inbatchno": "No. Batch Bahan Masuk",
      "itemtotal": "Jumlah yang Harus Dibayar (+Pajak)",
      "hubqty": "Jumlah Barang Masuk Gudang",
      "balance": "Perbedaan Akun",
      "recon": "Data Rekonsiliasi",
      "addr": "Alamat Pabrik",
      "addr_placeholder": "Masukkan Alamat yang Dapat Dihubungi",
      "strike_tb_delete_confirm": "Konfirmasi Rincian Keseimbangan",
      "paytotal": "Jumlah yang Sudah Diseimbangkan",
      "ap_total": "Jumlah Total (Termasuk Pajak)",
      "apCheck": "Tinjauan Rekonsiliasi",
      "cancel_apCheck": "Batalkan Tinjauan",
      "apCheck_confirm": "Konfirmasi Tinjauan Rekonsiliasi",
      "apCheck_confirm_text": "Memasuki Operasi Tinjauan Rekonsiliasi, Simpan Data Pernyataan?",
      "releaseAP": "Lembar Rekonsiliasi",
      "payment_placeholder": "Pilih Metode Pembayaran",
      "cancel_releaseAP": "Batalkan Pernyataan",
      "releaseAP_confirm": "Konfirmasi Pernyataan",
      "releaseAP_confirm_text": "即將進入出應付單確認，是否儲存應付單資料?",
      "strikeCheck": "Tinjauan Tagihan",
      "strike_create_header": "Buat Dokumen Induk Tagihan Baru",
      "strike_edit_header": "Edit Dokumen Induk Keseimbangan Pembayaran",
      "hd_drawer_title": "Data Induk Rekonsiliasi",
      "itemNM": "Tandai Pengurus",
      "paytype": "Metode Pembayaran",
      "strikeCheck_confirm": "Konfirmasi Peninjauan Tagihan",
      "strikeCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_strikeCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Dibatalkan Ada Kesalahan",
      "confirmStrike": "Konfirmasi Tagihan",
      "confirmStrike_confirm": "Konfirmasi Keseimbangan Pembayaran",
      "confirmStrike_confirm_text": "Sebelum Ditutup, Konfirmasi Apakah Tagihan Dapat Dilakukan",
      "strike_paytotal": "Jumlah yang Dibayarkan",
      "payT": "Waktu Pembayaran",
      "note": "Catatan Tagihan",
      "PN": "No. Bahan ERP",
      "inqty": "Jumlah yang Dikirim",
      "ap_del_hd_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Lembar Pernyataan yang Mau Dihapus Sudah Benar",
      "strike_del_hd_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Lembar Keseimbangan Tagihan yang Mau Dihapus Sudah Benar",
      "AP_lifeF_0": "Buat",
      "AP_lifeF_1": "Tinjauan",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "Keseimbangan (Pembayaran) Belum Tutup Kasus",
      "AP_lifeF_T": "Tutup Kasus",
      "Strike_lifeF_0": "Buat",
      "Strike_lifeF_1": "Tinjauan Tagihan",
      "Strike_lifeF_T": "Keseimbangan Berhasil",
      "message_chose_take_which_ap": "Pilih Data Rekonsiliasi yang Akan Dikutip",
      "message_only_take_one_ap": "Hanya 1 Permintaan yang Dapat Dikutip",
    },
    "return": {
      "pageHeader": "Operasi Pengembalian Bahan",
      "create_header": "Tambah Dokumen Induk Pengembalian Bahan",
      "edit_header": "Edit Dokumen Induk Pengembalian Bahan",
      "addr": "Alamat yang Dapat Dihubungi",
      "del_hd_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Daftar Pengembalian Bahan Masuk yang Mau Dihapus Sudah Benar",
      "rtnqty": "Jumlah yang Dikembalikan",
      "inbatchno": "Masukkan No. Batch",
      "note": "Catatan Pengembalian Bahan",
      "tb": "Rincian Pengembalian Bahan yang Masuk",
      "itemNM": "Catatan Pengurus",
      "itemNM_placeholder": "Pilih Catatan Pengurus",
      "lifeF_0": "Rilis",
      "lifeF_T": "Tutup Kasus",
      "message_at_least_chose_one": "Pilih Setidaknya Satu Daftar Pengembalian Setelah Pengecekan",
      "planqty": "Jumlah yang Dikembalikan Setelah Pengecekan",
    },
  },
  "OMS": {
    "util": {
      "opcode_1110": "Kutipan Dalam Negeri",
      "opcode_1120": "Kutipan Luar Negeri",
      "opcode_1210": "Perkiraan Pesanan",
      "opcode_1310": "Pesanan Dalam Negeri",
      "opcode_1320": "Pesanan Luar Negeri",
      "opcode_1410": "Pesanan Dalam Negeri",
      "opcode_1420": "\t Pesanan Luar Negeri",
      "opcode_4220": "Formulir Pengeluaran Barang Setelah Pengecekan",
      "opcode_4130": "Formulir Pengembalian Barang Setelah Pengecekan",
      "opcode_1610": "Pengembalian Penjualan",
      "opcode_1710": "Pengiriman Barang",
      "opcode_1810": "Tagihan",
      "plan": "Formulir Perencanaan",
      "quo": "Formulir Kutipan",
      "fod": "Fornulir Rencana Pesanan ",
      "ord": "Formulir Penjualan",
      "outstock": "Formulir Pengiriman",
      "return": "Formulir Pengembalian Penjualan",
      "ap": "Formulir Rekonsiliasi",
      "outN": "No. Pengembalian Barang Setelah Pengecekan",
      "N11": "No. Daftar Penawaran",
      "N12": "No. Pesanan",
      "N13": "No. Daftar Penjualan",
      "N14": "No. Daftar Pengiriman",
      "N17": "No. Lembar Tagihan",
      "N18": "No. Lembar Tagihan",
      "planSearch": "Pencarian Catatan Rencana",
      "quoSearch": "Pencarian Catatan Penawaran",
      "fodSearch": "Pencarian Catatan Pesanan",
      "ordSearch": "Pencarian Catatan Penjualan",
      "stockSearch": "Pencarian Catatan Pengecekan",
      "outstockSearch": "Permintaan catatan pengiriman",
      "apSearch": "Pencarian Data Rekonsiliasi",
      "strikeSearch": "Pencarian Catatan Penyeimbangan",
      "dealN": "No. Pesanan Pelanggan",
      "drawno": "No. Gambar",
      "attribute": "Sifat Bahan",
      "pdtclass": "Kategori Pabrik",
      "pdttype": "Kategori Manajemen",
      "localprice": "Mata Uang Lokal",
    },
    "quo": {
      "pageHeader": "Penawaran",
      "quoID": "Staff Penawaran",
      "quoT": "Tanggal Penawaran",
      "validdays": "Jumlah Hari Berlaku",
      "isvalid": "Validasi",
      "hd_drawer_title": "Dokumen Induk Daftar Penawaran",
      "tb_drawer_title": "Rincian Penawaran",
      "demand": "Jumlah Penawaran",
      "punit": "Unit Penawaran",
      "unitprice": "Harga Penawaran",
      "isapprove": "Transaksi Tunggal",
      "itemtotal": "Total Tanpa Pajak",
      "local_subtotal": "Total Harga Dalam Mata Uang Saat Ini Termasuk Pajak",
      "total": "Total Harga Termasuk Pajak(Mata Uang Transaksi)",
      "note": "Catatan Penawaran",
      "hd_delete_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Daftar Penawaran yang Mau Dihapus Sudah Benar",
      "lifeF_0": "Rilis",
      "lifeF_2": "Tinjauan Penawaran",
      "lifeF_7": "Tinjau Tandatangan Resmi",
      "lifeF_T": "Tutup Kasus",
      "quoCheck_confirm_Text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_quoCheck_confirm_Text":
        "Sebelum Batalkan Tinjauan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "Audit_confirm": "Tandatangan Tinjauan untuk Konfirmasi ",
      "Audit_confirm_text":
        "Setelah Tandatangan Tinjauan, Daftar Penawaran Sudah Tidak Dapat Diedit, Konfirmasi Selesai Peninjauan?",
      "tb": "Rincian Penawaran",
    },
    "fod": {
      "pageHeader": "Pekerjaan Pembuatan Rencana",
      "hd_drawer_title": "Dokumen Induk Pesanan",
      "tb_drawer_title": "Rincian Daftar Pesanan",
      "driID": "Penanggungjawab Bagian Bisnis",
      "trade": "Syarat&Ketentuan Perdagangan",
      "dealcontact": "Pengurus Layanan Pelanggan",
      "payment": "Metode Pembayaran",
      "dealaddr": "Alamat Pelanggan",
      "custHistory": "Pencarian Riwayat Tansaksi Pelanggan",
      "contact": "Penanggungjawab Pelanggan Untuk Pengiriman",
      "sumtotal": "Total (Tanpa Pajak)",
      "note": "Catatan Perencanaan",
      "total": "Total Termasuk Pajak",
      "demandqty": "Jumlah Pesanan Pelanggan",
      "punit": "Unit Pemesanan",
      "unitprice": "Harga/ Pesanan",
      "confirmqty": "Konfirmasi Jumlah",
      "cancelqty": "Batalkan Jumlah",
      "returnqty": "Jumlah Barang yang Dikembalikan",
      "transferqty": "Jumlah yang Sudah Ditransfer",
      "qty": "Jumlah Transfer yang Direncanakan",
      "hd_delete_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Pemesanan yang Mau Dihapus Sudah Benar",
      "lifeF_0": "Rilis",
      "lifeF_2": "Tinjauan Perencanaan",
      "lifeF_7": "Tandatangan Pelanggan",
      "lifeF_T": "Tutup Kasus",
      "local_total": "Total Termasuk Pajak (Mata Uang Saat Ini)",
      "fodCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_fodCheck_confirm_text":
        "Sebelum Batal Meninjau, Konfirmasi Apakah Data yang Batal Ditinjau Sudah Benar",
      "custCheck_confirm": "Konfiramsi Tandatangan Pelanggan",
      "custCheck_confirm_text":
        "Sebelum Menandatangani, Konfiramsi Apakah Data yang Mau Ditandatangani Sudah Benar",
      "cancel_custCheck_confirm_text":
        "Sebelum Batal Mengembalikan Tandatangan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "reviewFlow": "Proses Tinjauan (3)",
      "tb": "Rincian Pemesanan",
      "deal_history": "Pencarian Riwayat Transaksi Produk",
    },
    "ord": {
      "pageHeader": "Operasi Penjualan",
      "hd_drawer_title": "Dokumen Induk Penjualan",
      "tb": "Rincian Daftar Penjualan",
      "adda": "Catatan Syarat&Ketentuan",
      "addb": "Potongan Tambahan",
      "addc": "Pekerjaan Prabayar",
      "lifeF_0": "Rilis",
      "lifeF_2": "TInjauan Penjualan",
      "lifeF_7": "Tandatangan Pelanggan",
      "lifeF_T": "Perintah",
      "driID": "Penanggungjawab Bagian Bisnis",
      "sumtotal": "Total Tanpa Pajak",
      "total": "Total Termasuk Pajak (Mata Uang Transaksi)",
      "dealaddr": "Alamat Pelanggan",
      "contact": "Penanggungjawab Pelanggan Untuk Pengiriman",
      "dealcontact": "Penanggungjawab Pelanggan Bagian Bisnis",
      "payment": "Metode Pembayaran",
      "trade": "Syarat&Ketentuan Perdagangan",
      "note": "Catatan Penjualan",
      "reviewFlow": "Proses Tinjauan (3)",
      "demandqty": "Jumlah Penjualan",
      "transferqty": "Jumlah Barang yang Ditransfer",
      "qty": "Jumlah Transfer Kali Ini",
      "punit": "Unit Penjualan",
      "unitprice": "Harga Satuan Penjualan",
      "confirmqty": "Jumlah yang Dikonfirmasi Pelanggan",
      "cancelqty": "Jumlah Pembatalan",
      "returnqty": "Jumlah yang Dikembalikan",
      "charge": "Total Pemotongan",
      "statement": "Penjelasan Proyek",
      "amount": "Jumlah Tanpa Pajak",
      "local_total": "Total Termasuk Pajak (Mata Uang Lokal)",
      "closeData_confirm_text":
        "Sebelum Masuk Pesanan Wajib, Konfirmasi Apakah Data Pesanan Wajib Sudah Benar",
      "removeData_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Penjualan yang Mau Dihapus Sudah Benar",
      "orderCheck_text": "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_orderCheck_text":
        "Sebelum Batalkan Tinjauan Penjualan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "cancel_custCheck_confirm_text":
        "Sebelum Batalkan Pengembalian Tandatangan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "custCheck_confirm_text":
        "Sebelum Pengembalian Tandatangan, Konfirmasi Apakah Data yang Ditandatangan Sudah Benar",
      "adda_delete_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Ketentuan yang Mau Dihapus Sudah Benar",
      "addb_delete_confirm_text":
        "Sebelum Menghapus, Konfirmasi Apakah Data Pembayaran yang Mau Dihapus Sudah Benar",
      "addc_delete_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Perkiraan yang Mau Dihapus Sudah Benar",
      "deal_history": "Pencarian Riwayat Transaksi Produk",
    },
    "out-stock": {
      "pageHeader": "Pengiriman",
      "hd_drawer_title": "Dokumen Induk Pengiriman",
      "tb_drawer_title": " ",
      "tb": "Rincian Faktur",
      "lifeF_0": "Rilis",
      "lifeF_2": "Tinjauan Faktur",
      "lifeF_3": "Pengambilan Sampel IQC",
      "lifeF_4": "Pengecekan IQC Selesai",
      "lifeF_7": "Tinjauan Jaminan Kualitas Produk",
      "lifeF_T": "Tutup Kasus",
      "sampleID": "Pengambil Sampel",
      "sampleT": "Tanggal Pengambilan Sampel",
      "note": "Catatan Pengiriman",
      "punit": "Unit Pengiriman",
      "indqty": "Jumlah Barang yang Dikirim",
      "goqty": "Jumlah Barang yang Melalui Pengecekan",
      "unitprice": "Harga Satuan Penjualan",
      "rejectqty": "Jumlah Barang yang Dikembalikan Setelah Melalui Pengecekan",
      "IQCcheck": "Pengambilan Sampel untuk Pemeriksaan",
      "inspectID": "Karyawan yang Melakukan Pemeriksaan",
      "inspectT": "Waktu Pemeriksaan Selesai",
      "qalID": "Juri",
      "qalT": "Waktu Rilis",
      "judgeT": "Pilih Tanggal Penjurian",
      "qty": "Jumlah",
      "choose": "Pilih",
      "choose_err": "Jumlah Sisa Adalah 0, Tidak Bisa Mengutip",
      "throw_save_msg": "Pilih Dulu Kutipan Baru Masukkan Jumlah Kutipan",
      "throwQty_msg": "Jumlah Transfer Tidak Boleh Di Bawah 0",
      "useDetail_msg": "Tidak Boleh Mengutip Rincian yang Sama",
      "IQCcheck_text": "Akan Memasuki Pemeriksaan Sampel, Simpan Data/",
      "closeData_confirm_text":
        "Sebelum Hapus, Konfirmasi Apakah Daftar Pengiriman yang Mau Dihapus Sudah Benar",
      "outstockCheck_text":
        "Sebelum Meninjau Pengiriman, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_outstockCheck_text":
        "Sebelum Batal Meninjau, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "IQCfinish_text": "Akan Memasuki Pengecekan IQC, Simpan Data Pengiriman?",
      "QAcheck_err": " ",
      "QAcheck": "Rilis Jaminan Kualitas",
      "QAcheck_text": "Akan Memasuki Rilis Jaminan Kualitas Produk, Simpan Data Pengiriman?",
      "throwStock_text": "Akan Segera Memasuki Transfer Daftar Pengiriman, Konfirmasi Transfer?",
    },
    "check": {
      "pageHeader": "Pemeriksaan Pengiriman",
      "4220_header": "Dokumen Induk Daftar Pengiriman Penjualan",
      "4220_tb": "Rincian Pengiriman",
      "4130_header": "Dokumen Induk Penjualan yang Dikembalikan",
      "4130_tb": "Catatan Pengembalian",
      "jobN": "No. Daftar Asal Bahan",
      "note": "Tandai",
      "total": "Total Uang yang Ditransfer",
      "planqty": "Jumlah yang Ditransfer",
      "punit": "Unit Penjualan",
      "lifeF_0": "Rilis",
      "lifeF_T": "Tutup Kasus",
    },
    "return": {
      "pageHeader": "Operasi Pengembalian Barang",
      "tb": "Rincian Pengembalian Barang",
      "hd_remove_text":
        "Sebelum Hapus, Konfirmasi Apakah  Lembar Pengembalian Produk Penjualan yang Mau Dihapus Sudah Benar",
      "unitprice": "Harga Satuan Pengiriman",
      "rtnqty": "Jumlah yang Dikembalikan",
      "inbatchno": "No. Batch Masuk Bahan",
      "contact_window": "Pengurus Bagian Bisnis Pelanggan",
      "note": "Catatan Masuk Bahan",
      "addr": "Alamat yang Dapat Dihubungi",
      "itemNM": "Tandai Pengurus",
      "lifeF_0": "Perilisan",
      "lifeF_T": "Tutup Kasus",
    },
    "ap-manage": {
      "pageHeader": "Manajemen Pembayaran Hutang",
      "rcv": "Pencarian Tagihan ",
      "rcvData": "Data Tagihan",
      "rcv_drawer_title": "Dokumen Induk Tagihan",
      "inbatchno": "No. Batch Bahan Masuk",
      "contactWindow": "Data Kontak Pengurus yang Dapat Dihubungi",
      "ap_tb": "Rincian Rekonsiliasi",
      "strike": "Pekerjaan Penyeimbangan",
      "strike_tb": "Rincian Keseimbangan Tagihan",
      "unitprice": "Harga/Penjualan",
      "rcv_inqty": "Jumlah yang Ditransfer",
      "rcv_itemsum": "Harga/Barang",
      "rcv_itemsumtax": "Pajak/Barang",
      "rcv_subtotal": "Jumlah Termasuk Pajak",
      "ap_remove_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Rekonsiliasi yang Mau Dihapus Sudah Benar",
      "strike_remove_text":
        "Sebelum Hapus, Konfirmasi Apakah Data Lembar Rekonsiliasi yang Mau Dihapus Sudah Benar",
      "ap_total": "Total  Termasuk Pajak yang Harus Diambil",
      "ap_itemtotal": "Jumlah Tagihan Termasuk Pajak",
      "ap_paytotal": "Jumlah Keseimbangan",
      "ap_sumtotal": "Total Harga",
      "strike_sumtotal": "Jumlah Total Keseimbangan Tagihan",
      "ap_hubqty": "Jumlah Barang Keluar Gedung",
      "ap_addr": "Alamat Pelanggan",
      "ap_balance": "Perbedaan Akun",
      "ap_lifeF_0": "Pembuatan",
      "ap_lifeF_1": "Tinjauan Tagihan",
      "ap_lifeF_2": "Lembar Tagihan",
      "ap_lifeF_3": " Keseimbangan Tagihan (Pembayaran) Belum Tutup Kasus ",
      "ap_lifeF_T": "Tutup Kasus",
      "ap_contact": "Penanggungjawab Pelanggan Bagian Bisnis",
      "ap_itemNM": "Catatan Penanggungjawab",
      "strike_lifeF_0": "Pembuatan",
      "strike_lifeF_1": "Tinjauan Tagihan",
      "strike_lifeF_T": "Keseimbangan Berhasil",
      "rcvtype": "Cara Pembayaran",
      "rcvtype_1": "Transfer Melalui Bank (T/T)",
      "rcvtype_2": "Melalui Wesel (Cek)",
      "rcvtype_3": "Tunai (CASH)",
      "rcvtotal": "Jumlah yang Harus Dibayarkan",
      "rcvT": "Waktu Pembayaran",
      "strike_note": "Catatan Keseimbangan Tagihan",
      "confirmStrike": "Konfirmasi Keseimbangan Tagihan",
      "apCheck_confirm_text": "Akan Memasuki Peninjauan Tagihan, Simpan Data?",
      "releaseAP_confirm_text": "Akan Memasuki Konfimasi Tagihan, Simpan Data?",
      "strikeCheck_confirm_text":
        "Sebelum Meninjau, Konfirmasi Apakah Data yang Mau Ditinjau Sudah Benar",
      "cancel_strikeCheck_confirm_text":
        "Sebelum Membatalkan Tinjauan, Konfirmasi Apakah Data yang Mau Dibatalkan Sudah Benar",
      "confirmStrike_confirm_text":
        "Sebelum Konfirmasi Keseimbangan Tagihan, Konfirmasi Apakah Data Keseimbangan Tagihan yang Mau Ditutup Kasusnya Sudah Benar ",
    },
  },
  "BPM": {
    "util": {
      "signmode": "簽核層級",
      "level": "階",
      "opfunction_1": "訂單系統",
      "opfunction_2": "採購系統",
      "opfunction_3": "生產系統",
      "opfunction_4": "庫儲系統",
      "opfunction_5": "財務系統",
      "optype_11": "訂單專案",
      "optype_12": "採購專案",
      "optype_40": "庫儲專案",
      "optype_41": "出庫專案",
      "permissions": "權限",
      "open": "立案",
      "check": "查權",
      "review": "審權",
      "approve": "核權",
    },
    "sign-level": {
      "pageHeader": "簽核權限設定",
      "opfunction": "營運功能",
      "optype": "作業類別",
    },
    "dsevent": {
      "system": "Modul Sistem",
      "keynote": "Subjek",
    },
  },
  "PDS": {
    "util": {
      "wlID": "Jenis Lane",
    },
    "wpc-search": {
      "hdC": "高峰人數",
      "issueday": "發佈時間",
      "lifeF_0": "已發佈",
      "lifeF_1": "已登錄",
      "lifeF_2": "生產中",
      "lifeF_3": "報停工",
      "lifeF_4": "報復工",
      "lifeF_7": "報完工",
      "lifeF_B": "報開工",
      "lifeF_G": "過站",
      "lifeF_L": "物控覆核",
      "lifeF_P": "質控合格",
      "lifeF_aps": "生管發佈",
      "linemix": "混線",
      "pageHeader": "工站途單查詢",
      "wlID": "線別",
    },
    "overview-lines": {
      "pageHeader": "線體稼動總表",
      "act": "Ketersediaan",
      "shiftD": "Shift Pagi",
      "shiftN": "Shift Malam",
      "allDay": "Kiri",
    },
    "dashboard": {
      "pageHeader": "Produksi Digital Kanban",
    },
    "abc-board": {
      "pageHeader": "Manajemen Tidak Biasa Kanban",
    },
  },
};

export default id_ID;
