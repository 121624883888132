import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  Space,
  Collapse,
  message,
} from "antd";
import { getStation, getPF, getPWC, getLine } from "@/service/apis/ADM/publicAPI";
import { getWpcIe, getWh } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import { IconGarbage, IconShrink, IconExpand } from "@/components/Icon/Action";
import { PlusOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export default function WpcSetting({ form, wpcWrapperRef, isEdit }) {
  const maxHeight = isEdit ? `max-h-[76vh]` : `max-h-[60vh]`;

  const { t } = useTranslation();
  const { Panel } = Collapse;

  const call_getStation = useAPI(getStation);
  const call_getPF = useAPI(getPF);
  const call_getPWC = useAPI(getPWC);
  const call_getWpcIe = useAPI(getWpcIe);
  const call_getWh = useAPI(getWh);
  const [s_activeKey, set_s_activeKey] = useState([]);
  const [s_isPanelOpen, set_s_isPanelOpen] = useState(false);

  const handlePanelClick = (key) => {
    set_s_activeKey(key);
  };

  const handleChange = (key, index, subIndex, data) => {
    let formData = form.getFieldValue();
    let workClass = ["pwcID", "wsID", "pfID"];
    let i = 0;

    if (workClass.includes(key)) {
      // 將當前階級以下的工作環境都清空
      while (workClass[i] !== key) {
        formData.wpcs[index].subWpcs[subIndex][workClass[i]] = null;
        // formData.wpcs[index].subWpcs[subIndex][workClass[i]] = workClass[i] === "pwcID" ? [] : null;
        i++;
      }
    }

    if (key === "ieUUID" && data) {
      formData.wpcs[index].subWpcs[subIndex].stdwh = data.data.stdwh;
      formData.wpcs[index].subWpcs[subIndex].stdmh = data.data.stdmh;
      formData.wpcs[index].subWpcs[subIndex].planhead = data.data.planhead;
      // formData.wpcs[index].subWpcs[subIndex].'stdcost' = data.data.stdcost
      formData.wpcs[index].subWpcs[subIndex].stdlt = data.data.stdlt;
    }

    if (["planhead", "stdwh", "stdmh", "stdlt"].includes(key)) {
      formData.wpcs[index].subWpcs[subIndex].ieUUID = null;
    }

    form.setFieldsValue(formData);
  };

  // 新增主途程
  const addWpc = () => {
    let formData = form.getFieldValue();
    // 還未新增前的最後一筆wpcSN
    const wpcSN = formData.wpcs.length;

    const lastWpc =
      formData.wpcs[formData.wpcs.length - 1].subWpcs[
        formData.wpcs[formData.wpcs.length - 1].subWpcs.length - 1
      ];

    const batchbom = lastWpc.batchbom;
    const ieUUID = lastWpc.ieUUID;
    const planhead = lastWpc.planhead;
    const stdmh = lastWpc.stdmh;
    const stdwh = lastWpc.stdwh;
    const stdlt = lastWpc.stdlt;

    // 將先前填的倉別清空
    // 並將未新增前的最後一筆主途程內的nextSN改為wpcSN+1
    for (var i = 0; i < formData.wpcs.length; i++) {
      formData.wpcs[i].subWpcs.forEach((item) => {
        item.whID = "";
      });
      if (i === formData.wpcs.length - 1) {
        formData.wpcs[i].subWpcs.forEach((item) => {
          item.nextSN = `${wpcSN + 1}`;
        });
      }
    }

    // 新增主途程
    formData.wpcs.push({
      subWpcs: [
        {
          wpcSN: `${parseInt(wpcSN) + 1}`,
          nextSN: "T",
          stdwh: stdwh,
          stdmh: stdmh,
          stdlt: stdlt,
          // 'stdcost': 0,
          planhead: planhead,
          pfID: null,
          wsID: null,
          pwcID: null,
          // wlID: null,
          ieUUID: ieUUID,
          itemno: 0,
          batchbom: batchbom,
          warnyield: 90,
          alertyield: 80,
          alertspeed: 90,
          alertstop: 1200,
        },
      ],
    });
    form.setFieldsValue(formData);
    // 展開最後一筆主途程
    set_s_activeKey([...s_activeKey, formData.wpcs.length - 1]);

    setTimeout(() => {
      // 增加、刪減途程後滾動條要出現在最下方
      wpcWrapperRef.current.scrollTo({ top: wpcWrapperRef.current.scrollHeight });
    }, 100);
  };

  // 新增(據此創建)替代途程
  const copySubWpc = (index, subIndex) => {
    let formData = form.getFieldValue();

    // 在Wpcs[index](主途程)的subWpcs[subIndex](此替代途程)後
    // 插入當前subWpcs[subIndex](itemno+1)
    formData.wpcs[index].subWpcs.splice(subIndex + 1, 0, {
      ...formData.wpcs[index].subWpcs[subIndex],
      itemno: formData.wpcs[index].subWpcs[subIndex].itemno + 1,
    });

    // 新增完之後的替代途程itemno都要+1(新的替代途程已經被新增在subIndex+1)
    for (var i = subIndex + 2; i < formData.wpcs[index].subWpcs.length; i++) {
      formData.wpcs[index].subWpcs[i].itemno += 1;
    }

    form.setFieldsValue(formData);

    setTimeout(() => {
      // 複製途程後移動滾動條
      wpcWrapperRef.current.scrollTo({ top: wpcWrapperRef.current.scrollTop + 369 });
    }, 100);
  };

  // 刪除主途程
  const removeWpc = (index) => {
    let formData = form.getFieldValue();
    formData.wpcs.splice(index, 1);

    // 將被刪除的主途程之下的途程號都-1，如果刪的是最後一筆此行不會執行
    for (var i = index; i < formData.wpcs.length; i++) {
      formData.wpcs[i].subWpcs.forEach((item) => {
        item.wpcSN = `${item.wpcSN - 1}`;
        if (item.nextSN !== "T") item.nextSN = `${item.nextSN - 1}`;
      });
    }

    // 更新最後一筆次途程號為T
    formData.wpcs[formData.wpcs.length - 1].subWpcs.forEach((item) => {
      item.nextSN = `T`;
    });

    form.setFieldsValue(formData);
  };

  // 刪除替代途程
  const removeSubWpcs = (index, subIndex) => {
    let formData = form.getFieldValue();
    formData.wpcs[index].subWpcs.splice(subIndex, 1);

    // 刪除完之後的替代途程itemno都要-1(subIndex那筆已經被刪)
    for (var i = subIndex; i < formData.wpcs[index].subWpcs.length; i++) {
      formData.wpcs[index].subWpcs[i].itemno -= 1;
    }

    form.setFieldsValue(formData);
  };

  useEffect(() => {
    call_getPF.request();
    call_getPWC.request();
    call_getStation.request("");
    call_getWpcIe.request();
    call_getWh.request();
  }, []);

  useEffect(() => {
    if (call_getStation.status === "err") {
      message.error(call_getStation.msg);
    }
  }, [call_getStation.status]);

  useEffect(() => {
    if (call_getPF.status === "err") {
      message.error(call_getPF.msg);
    }
  }, [call_getPF.status]);

  useEffect(() => {
    if (call_getPWC.status === "err") {
      message.error(call_getPWC.msg);
    }
  }, [call_getPWC.status]);

  useEffect(() => {
    if (s_isPanelOpen) {
      let formData = form.getFieldValue();
      set_s_activeKey(formData.wpcs.map((_, index) => index.toString()));
    } else {
      set_s_activeKey([]);
    }
  }, [s_isPanelOpen]);

  return (
    <Form.List name="wpcs" shouldUpdate>
      {(fields) => (
        <div>
          <Row gutter={[24, 12]}>
            <Col span={8} className="text-xl mx-0 my-[10px]">
              {t("ADM.edm.wpcSetting")}
            </Col>
            <Col span={16} className="flex justify-end">
              <Space>
                <Button type="primary" onClick={() => set_s_isPanelOpen(!s_isPanelOpen)}>
                  {s_isPanelOpen ? <IconShrink /> : <IconExpand />}
                </Button>
                <Button type="primary" onClick={() => addWpc()} icon={<PlusOutlined />}>
                  {t("util.util.add") + t("ADM.edm.main_wpc")}
                </Button>
              </Space>
            </Col>
          </Row>
          <Collapse
            ref={wpcWrapperRef}
            className={`wpcWrapper ${maxHeight} overflow-auto`}
            activeKey={s_activeKey}
            onChange={handlePanelClick}
          >
            {fields.map((field, index) => {
              let formData = form.getFieldValue();
              const firstSubWpcs = formData.wpcs[index].subWpcs[0];
              console.log(firstSubWpcs);
              const pfNM = firstSubWpcs.pfID
                ? `_【製程: ${call_getPF.data?.find((item) => item.pfID === firstSubWpcs.pfID)?.pfNM}】`
                : "";
              const wsNM = firstSubWpcs.wsID
                ? `_【工站: ${call_getStation.data?.find((item) => item.wsID === firstSubWpcs.wsID)?.wsNM}】`
                : "";
              const pwc = call_getPWC.data?.find((item) => item.pwcID === firstSubWpcs?.pwcID);
              const pwcID = pwc
                ? `_【工作中心: ${call_getPWC.data?.find((item) => item.pwcID === firstSubWpcs.pwcID)?.pwcID}`
                : "";
              const pwcNM = pwc
                ? `_${call_getPWC.data?.find((item) => item.pwcID === firstSubWpcs.pwcID)?.pwcNM}】`
                : "";
              return (
                <Panel
                  header={
                    <span>
                      {t("ADM.edm.wpcSN")}
                      {firstSubWpcs.wpcSN}
                      <span className={formData.wpcs[index].subWpcs.length > 1 && "text-blue-500"}>
                        {pfNM}
                        {wsNM}
                        {pwcID}
                        {pwcNM}
                      </span>
                    </span>
                  }
                  key={index}
                  extra={<IconGarbage onClick={() => removeWpc(index)} />}
                  className="bg-[#C0DCF0]"
                >
                  <Form.List
                    {...field}
                    name={[field.name, "subWpcs"]}
                    fieldKey={[field.fieldKey, "subWpcs"]}
                  >
                    {(subFields) => (
                      <>
                        {subFields.map((subField, subIndex) => {
                          const choosedPF = formData.wpcs[index].subWpcs[subIndex].pfID;
                          const choosedWS = formData.wpcs[index].subWpcs[subIndex].wsID;
                          // const choosedPWC = formData.wpcs[index].subWpcs[subIndex].pwcID;
                          // 替代途程才需要變黃色
                          const backgroundColor =
                            subIndex === 0 ? "bg-[#EDF2F7]" : "bg-[#FFFF0020]";

                          return (
                            <>
                              <Row
                                key={`info${subIndex}`}
                                gutter={[24, 0]}
                                className={backgroundColor}
                              >
                                <Form.Item {...subField} name={[subField.name, "itemno"]} nostyle />
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.wpcSN")}
                                    name={[subField.name, "wpcSN"]}
                                  >
                                    <Input disabled className="w-full" />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.nextSN")}
                                    name={[subField.name, "nextSN"]}
                                  >
                                    <Input disabled className="w-full" />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.whID")}
                                    name={[subField.name, "whID"]}
                                  >
                                    <Select
                                      className="w-full"
                                      allowClear
                                      disabled={firstSubWpcs.nextSN !== "T"}
                                      options={call_getWh.data?.map((x) => {
                                        return {
                                          label: `${x.whID}_${x.whNM}`,
                                          value: x.whID,
                                          data: x,
                                        };
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.ieUUID")}
                                    name={[subField.name, "ieUUID"]}
                                  >
                                    <Select
                                      className="w-full"
                                      allowClear
                                      onChange={(_, data) =>
                                        handleChange("ieUUID", index, subIndex, data)
                                      }
                                      options={call_getWpcIe.data?.map((x) => {
                                        return {
                                          label: `${x.planhead} / ${x.stdwh} / ${x.stdmh} / ${x.stdlt}`,
                                          value: x.ieUUID,
                                          data: x,
                                        };
                                      })}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.pfNM")}
                                    name={[subField.name, "pfID"]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      className="w-full"
                                      onChange={() => handleChange("pfID", index, subIndex)}
                                      allowClear
                                      options={
                                        call_getPF.data?.filter(x => x.isvalid)?.map((x) => {
                                          return {
                                            label: `${x.pfID}_${x.pfNM}`,
                                            value: x.pfID,
                                            data: x,
                                          };
                                        }) ?? []
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("util.util.ws")}
                                    name={[subField.name, "wsID"]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      className="w-full"
                                      disabled={!choosedPF}
                                      onChange={() => handleChange("wsID", index, subIndex)}
                                      allowClear
                                      options={call_getStation.data?.reduce((options, curr) => {
                                        curr.pfID.split("|").includes(choosedPF) &&
                                          options.push({
                                            label: curr.wsID + "_" + curr.wsNM,
                                            value: curr.wsID,
                                            data: curr,
                                          });
                                        return options;
                                      }, [])}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.util.pwc")}
                                    name={[subField.name, "pwcID"]}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      className="w-full"
                                      disabled={!choosedWS}
                                      // mode="multiple"
                                      onChange={() => handleChange("pwcID", index, subIndex)}
                                      allowClear
                                      options={call_getPWC.data?.filter(x => x.isvalid).reduce((options, curr) => {
                                        const isChoosedPWC = call_getStation.data
                                          ?.find((item) => item.wsID === choosedWS)
                                          ?.pwcID.split("|")
                                          ?.find((item) => item === curr.pwcID);
                                        isChoosedPWC &&
                                          options.push({
                                            label: curr.pwcID + "_" + curr.pwcNM,
                                            value: curr.pwcID,
                                            data: curr,
                                          });
                                        return options;
                                      }, [])}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.batchbom")}
                                    name={[subField.name, "batchbom"]}
                                  >
                                    <InputNumber
                                      min={0}
                                      className="w-full"
                                      disabled={
                                        !JSON.parse(
                                          call_getStation.data?.find(
                                            (item) => item.wsID === choosedWS
                                          )?.auth || null
                                        )?.batch
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row key={`data${subIndex}`} gutter={[24, 0]}>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.planhead")}
                                    name={[subField.name, "planhead"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      min={0}
                                      onChange={() => handleChange("planhead", index, subIndex)}
                                      className="w-full"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.stdwh")}
                                    name={[subField.name, "stdwh"]}
                                    rules={[{ required: true }]}
                                    // rules={[
                                    //   {
                                    //     validator: (rule, value, callback) => {
                                    //       const stdmh = formData.wpcs[index].subWpcs[subIndex].stdmh
                                    //       if (stdmh + value <= 0)
                                    //         return callback("需填寫其中一項");
                                    //       callback();
                                    //     },
                                    //     required: true,
                                    //   },
                                    // ]}
                                  >
                                    <InputNumber
                                      onChange={() => handleChange("stdwh", index, subIndex)}
                                      min={0}
                                      className="w-full"
                                      addonAfter="s"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.stdmh")}
                                    name={[subField.name, "stdmh"]}
                                    rules={[{ required: true }]}
                                    // rules={[
                                    //   {
                                    //     validator: (rule, value, callback) => {
                                    //       const stdwh = formData.wpcs[index].subWpcs[subIndex].stdwh
                                    //       if (stdwh + value <= 0)
                                    //         return callback("需填寫其中一項");
                                    //       callback();
                                    //     },
                                    //     required: true,
                                    //   },
                                    // ]}
                                  >
                                    <InputNumber
                                      onChange={() => handleChange("stdmh", index, subIndex)}
                                      min={0}
                                      className="w-full"
                                      addonAfter="s"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.stdlt")}
                                    name={[subField.name, "stdlt"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      onChange={() => handleChange("stdlt", index, subIndex)}
                                      min={0}
                                      className="w-full"
                                      addonAfter="s"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.warnyield")}
                                    name={[subField.name, "warnyield"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      className="w-full"
                                      addonAfter="%"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.alertyield")}
                                    name={[subField.name, "alertyield"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      className="w-full"
                                      addonAfter="%"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.alertspeed")}
                                    name={[subField.name, "alertspeed"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      className="w-full"
                                      addonAfter="%"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...subField}
                                    label={t("ADM.edm.alertstop")}
                                    name={[subField.name, "alertstop"]}
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber min={0} className="w-full" addonAfter="s" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row key={`button${subIndex}`} gutter={[24, 0]}>
                                <Col span={12} />
                                <Col span={12}>
                                  <Space className="flex w-full justify-end align-middle">
                                    <Button
                                      className="flex justify-center items-center w-44 text-lg bg-[#DDEBFC] rounded-3xl"
                                      onClick={() => copySubWpc(index, subIndex)}
                                      icon={
                                        <PlusCircleOutlined className="flex items-center text-2xl text-[#7ABAF4]" />
                                      }
                                    >
                                      {t("util.util.add") + t("ADM.edm.alternative_wpc")}
                                    </Button>
                                    <Button
                                      className={`flex justify-center items-center w-44 text-lg ${
                                        formData.wpcs[index].subWpcs.length > 1 && "bg-[#FFE8E8]"
                                      } rounded-3xl`}
                                      onClick={() => removeSubWpcs(index, subIndex)}
                                      icon={
                                        <CloseCircleOutlined
                                          className={`flex items-center text-2xl ${
                                            formData.wpcs[index].subWpcs.length > 1 &&
                                            "text-[#F46F6F]"
                                          }`}
                                        />
                                      }
                                      disabled={formData.wpcs[index].subWpcs.length === 1}
                                    >
                                      {t("util.util.delete") + t("ADM.edm.alternative_wpc")}
                                    </Button>
                                  </Space>
                                </Col>
                                <Col span={24}>
                                  <Divider className="mx-0 my-[10px] p-0" />
                                </Col>
                              </Row>
                            </>
                          );
                        })}
                      </>
                    )}
                  </Form.List>
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </Form.List>
  );
}
