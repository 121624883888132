import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";
//-----------------------------------------管理分類----------------------------------------
export const getMgmtcat = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMgmtcat`, {
    params: _params,
  });
};

export const addMgmtcat = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addMgmtcat`,
    data
  );
};

export const modifyMgmtcat = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifyMgmtcat`,
    data
  );
};

export const deleteMgmtcat = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteMgmtcat`,
    data
  );
};
