import { Button, Col, Row, message } from "antd";
import { useEffect, useMemo, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import { getCorp } from "@/service/apis/ADM/plantManage";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { getMnsEventSet } from "@/service/apis/MNS/notificationSetting";
import calculateTotalTextWidth from "@/util/calculateTotalTextWidth";

import ModalConintaer from "./ModalContainer";

export default function TB({ c_lightData }) {
  const [s_tabKey, set_s_tabKey] = useState("basic"); // 目前是在哪個Tab上
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_tableData, set_s_tableData] = useState([{}]); //沒有資料畫面顯示假資料

  const call_getCorp = useAPI(getCorp);
  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);

  const call_getMnsEventSet = useAPI(getMnsEventSet);

  const tableColumns = useMemo(() => {
    if (c_lightData.ctrlspot === "pf") {
      return call_getPF.data?.map(
        (x) =>
          ({
            title: `${x.pfID}\n${x.pfNM}`,
            formTitle: `${x.pfID}_${x.pfNM}`,
            dataIndex: x.pfID,
            align: "center",
            width: calculateTotalTextWidth(x.pfNM),
            render: (value) => (value ? <IconCheck /> : <IconClose className="!text-red-600" />),
          } ?? [])
      );
    }
    if (c_lightData.ctrlspot === "ws") {
      return call_getStation.data?.map(
        (x) =>
          ({
            title: `${x.wsID}\n${x.wsNM}`,
            formTitle: `${x.wsID}_${x.wsNM}`,
            dataIndex: x.wsID,
            align: "center",
            width: calculateTotalTextWidth(x.wsNM),
            render: (value) => (value ? <IconCheck /> : <IconClose className="!text-red-600" />),
          } ?? [])
      );
    }
    if (c_lightData.ctrlspot === "wl") {
      return call_getLine.data?.map(
        (x) =>
          ({
            title: `${x.wlID}\n${x.wlNM}`,
            formTitle: `${x.wlID}_${x.wlNM}`,
            dataIndex: x.wlID,
            align: "center",
            width: calculateTotalTextWidth(x.wlNM),
            render: (value) => (value ? <IconCheck /> : <IconClose className="!text-red-600" />),
          } ?? [])
      );
    }
    if (c_lightData.ctrlspot === "pwcID") {
      return call_getPWC.data?.map(
        (x) =>
          ({
            title: `${x.pwcID}\n${x.pwcNM}`,
            formTitle: `${x.pwcID}_${x.pwcNM}`,
            dataIndex: x.pwcID,
            align: "center",
            width: calculateTotalTextWidth(x.pwcNM),
            render: (value) => (value ? <IconCheck /> : <IconClose className="!text-red-600" />),
          } ?? [])
      );
    }
  }, [
    c_lightData,
    call_getPF.status,
    call_getLine.status,
    call_getStation.status,
    call_getPWC.status,
  ]);

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
    call_getCorp.request();
  }, []);

  useEffect(() => {
    call_getMnsEventSet.request({ ...c_lightData, plantID: s_tabKey });
  }, [c_lightData, s_tabKey]);

  useEffect(() => {
    if (call_getCorp.status === "suc") {
      const plantID = call_getCorp.data[0].plantID;
      set_s_tabKey(plantID);
    }
    if (call_getCorp.status === "err") {
      message.error(call_getCorp.msg);
    }
  }, [call_getCorp.status]);

  useEffect(() => {
    if (call_getMnsEventSet.status === "suc") {
      const data = call_getMnsEventSet.data;
      let values = {};

      for (const item of data) {
        values[item.spotID] = item.isvalid;
      }
      set_s_tableData([values]);
    }
    if (call_getMnsEventSet.status === "err") {
      set_s_tableData([{}]);
    }
  }, [call_getMnsEventSet.status]);

  useEffect(() => {
    if (call_getPF.status === "err") {
      message.error(call_getPF.msg);
    }
  }, [call_getPF.status]);

  useEffect(() => {
    if (call_getStation.status === "err") {
      message.error(call_getStation.msg);
    }
  }, [call_getStation.status]);

  useEffect(() => {
    if (call_getLine.status === "err") {
      message.error(call_getLine.msg);
    }
  }, [call_getLine.status]);

  useEffect(() => {
    if (call_getPWC.status === "err") {
      message.error(call_getPWC.msg);
    }
  }, [call_getPWC.status]);

  return (
    <>
      {Object.keys(c_lightData).length > 0 ? (
        c_lightData.ctrlspot && call_getCorp?.data?.length === 1 ? (
          <Row gutter={[12, 12]}>
            <Col span={24} className="flex justify-end">
              <Button type="primary" icon={<IconPen />} onClick={() => set_s_isShowModal(true)}>
                修改
              </Button>
            </Col>
            <Col span={24}>
              <CustomTable
                columns={tableColumns}
                dataSource={s_tableData}
                loading={
                  call_getPF.status === "load" ||
                  call_getStation.status === "load" ||
                  call_getLine.status === "load" ||
                  call_getPWC.status === "load" ||
                  call_getMnsEventSet.status === "load"
                }
              />
            </Col>
          </Row>
        ) : (
          <CustomTabs
            items={
              call_getCorp?.data?.map((x) => ({
                key: x.plantID,
                label: x.plantNM,
                children: (
                  <CustomTable
                    columns={tableColumns}
                    dataSource={s_tableData}
                    loading={
                      call_getPF.status === "load" ||
                      call_getStation.status === "load" ||
                      call_getLine.status === "load" ||
                      call_getPWC.status === "load" ||
                      call_getMnsEventSet.status === "load"
                    }
                  />
                ),
              })) || []
            }
            activeKey={s_tabKey}
            onChange={(plantID) => {
              set_s_tabKey(plantID);
              call_getMnsEventSet.request({ ...c_lightData, plantID: plantID });
            }}
            tabBarExtraContent={
              <Button type="primary" icon={<IconPen />} onClick={() => set_s_isShowModal(true)}>
                修改
              </Button>
            }
          />
        )
      ) : (
        <></>
      )}

      <CustomModal
        title="修改"
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          call_getMnsEventSet.request({ ...c_lightData, plantID: s_tabKey });
        }}
      >
        <ModalConintaer
          s_editData={s_tableData}
          c_lightData={c_lightData}
          tableColumns={tableColumns}
          set_s_isShowModal={set_s_isShowModal}
          plantID={s_tabKey}
        />
      </CustomModal>
    </>
  );
}
