import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getStaff, freezeStaff, getFunctions, getPushlvl } from "@/service/apis/ADM/userManage";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import ModalConintaer from "./ModalContainer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import checkUndefined from "@/util/checkUndefined";

const StaffData = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getStaff = useAPI(getStaff);
  const call_freezeStaff = useAPI(freezeStaff);
  const call_getFunctions = useAPI(getFunctions); // 取得組織功能
  const call_getPushlvl = useAPI(getPushlvl); // 取得推播層級
  const call_getMgmtcat = useAPI(getMgmtcat); // 取得推播層級

  const tableColumns = [
    {
      title: "廠別", //廠別
      dataIndex: "plantID",
      width: "9%",
      render: (value) => value.join(","),
    },
    {
      title: t("ADM.user-manage.staffID"), //員工工號
      dataIndex: "staffID",
      align: "center",
      width: "10%",
    },
    {
      title: t("ADM.user-manage.peopleNM"), //員工姓名
      dataIndex: "peopleNM",
      width: "9%",
    },
    {
      title: t("ADM.util.sectID"), //工類
      dataIndex: "sectID",
      width: "9%",
      render: (text) => (text ? t(`ADM.util.sectID_${text}`) : ""),
    },
    {
      title: t("ADM.util.mail"), //郵址
      dataIndex: "mail",
      width: "15%",
    },
    {
      title: t("ADM.user-manage.cardID"), //卡勤識別碼
      dataIndex: "cardID",
      width: "6%",
      align: "center",
    },
    {
      title: t("util.util.tel"), //電話
      dataIndex: "tel",
      width: "8%",
    },
    {
      title: t("util.util.dept"), //部門
      dataIndex: "deptNM",
      width: "10%",
    },
    {
      title: "工作中心", //部門
      dataIndex: "pwcID",
      width: "10%",
      render: (value) => value.join(","),
    },
    {
      title: t("util.util.action"), //操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];
  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getStaff.request({ staffID: s_searchData.query });

  const joinColMgmtcat = (text, type) => {
    return text
      .reduce((data, current) => {
        current === "All" && data.push(t("util.util.all"));
        const res = call_getMgmtcat.data.find(
          (x) => x.mgmtitem === current && type.includes(x.type)
        );
        res && data.push(res.itemNM);
        return data;
      }, [])
      .join("，");
  };

  const remove = (rowData) => {
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "請確認您希望删除此資料",
      okText: "刪除",
      onOk: () => call_freezeStaff.request(rowData),
      cancelText: "取消",
    });
  };

  useEffect(() => {
    call_getStaff.request();
    call_getPushlvl.request();
    call_getFunctions.request();
    call_getMgmtcat.request({ pageType: 71 });
  }, []);

  useEffect(() => {
    if (call_getStaff.status === "suc") {
      message.success(call_getStaff.msg);

      // 整理資料
      set_s_tableData(
        call_getStaff.data.map((x) => ({
          ...x,
          key: x.staffID,
          function: x.function?.split("|").filter((x) => x !== "") || [],
          pdtmgmt: x.pdtmgmt?.split("|").filter((x) => x !== "") || [],
          dealmgmt: x.dealmgmt?.split("|").filter((x) => x !== "") || [],
          whmgmt: x.whmgmt?.split("|").filter((x) => x !== "") || [],
          plantID: x.plantID?.split("|").filter((x) => x !== "") || [],
          pwcID: x.pwcID?.split("|").filter((x) => x !== "") || [],
          enterT: dayjs(x.enterT),
          quitT: dayjs(checkUndefined(dayjs(x.quitT).format("YYYY-MM-DD"))).isValid()
            ? dayjs(x.quitT)
            : null,
        }))
      );
    } else if (call_getStaff.status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${call_getStaff.msg}`));
    }
  }, [call_getStaff.status]);

  useEffect(() => {
    if (call_freezeStaff.status === "suc") {
      message.success(call_freezeStaff.msg);
    } else if (call_freezeStaff.status === "err") {
      message.error(t(`error.error-code.${call_freezeStaff.msg}`));
    }
    call_getStaff.request();
  }, [call_freezeStaff.status]);

  useEffect(() => {
    if (call_getFunctions.status === "err") {
      message.error(t(`error.error-code.${call_getFunctions.msg}`));
    }
  }, [call_getFunctions.status]);

  useEffect(() => {
    if (call_getPushlvl.status === "err") {
      message.error(t(`error.error-code.${call_getPushlvl.msg}`));
    }
  }, [call_getPushlvl.status]);

  useEffect(() => {
    if (call_getMgmtcat.status === "err") {
      message.error(t(`error.error-code.${call_getMgmtcat.msg}`));
    }
  }, [call_getMgmtcat.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getStaff.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.user-manage.pageHeader")}`
            : `${t("util.util.add")}${t("ADM.user-manage.pageHeader")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getStaff.request();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
    </>
  );
});

export default StaffData;
