import { Button, Col, Divider, Form, Input, InputNumber, Row, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { addAqlHd, updateAqlHd } from "@/service/apis/QMS/aql";

export default function ModalConintaer({ s_editData, set_s_isShowModal, s_arrMap }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  // const f_sampletype = Form.useWatch("sampletype", form);

  // 新增表頭資料
  const call_addAqlHd = useAPI(addAqlHd);
  // 編輯表頭資料
  const call_updateAqlHd = useAPI(updateAqlHd);

  const onSubmit = (values) => {
    if (type === "create") {
      console.log(values);
      call_addAqlHd.request({ ...values });
    }
    if (type === "edit") {
      console.log(values);

      call_updateAqlHd.request({ ...values });
    }
  };

  const handleEnterKey = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      // 检查是否按下 Enter 键
      e.preventDefault();
    }
  };
  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addAqlHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addAqlHd.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateAqlHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateAqlHd.status]);

  useEffect(() => {}, []);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...s_editData,
        updtT: s_editData.updtT ? dayjs(s_editData?.updtT) : null,
      }}
      onKeyDown={handleEnterKey}
      onFinish={onSubmit}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]} label={"樣本數代碼"} name="sizecode">
            <Input disabled={type === "edit"} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item rules={[{ required: true }]} label={"樣本大小"} name="size">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row> */}

      <Divider />

      <Row gutter={[12, 12]}>
        {/* <Col span={4}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col> */}
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
          <Button
            loading={call_addAqlHd.status === "load" || call_updateAqlHd.status === "load"}
            type="primary"
            htmlType="submit"
          >
            {t("util.util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
