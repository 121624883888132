const unitArr = [{ value: "inch" }, { value: "cm" }, { value: "mm" }];
// 出貨標籤用
const alexKeyOptions = [
  { key: "t4batchID", text: "T4批號" },
  { key: "t4in", text: "T4進爐時間" },
  { key: "t4out", text: "T4出爐時間" },
  { key: "t6in", text: "T6進爐時間" },
  { key: "t6out", text: "T6出爐時間" },
  { key: "BISMT", text: "舊料號" },
  {
    "key": "inT",
    "text": "送件時間",
  },
  {
    "key": "peopleNM",
    "text": "作業員",
  },
  {
    "key": "staffID",
    "text": "工號",
  },
  {
    "key": "ALPN",
    "text": "批號",
  },
  {
    "key": "wlID1",
    "text": "OP1",
  },
  {
    "key": "wlID2",
    "text": "OP2",
  },

  {
    "key": "wlID1NM",
    "text": "OP1NM",
  },
  {
    "key": "wlID2NM",
    "text": "OP2NM",
  },

  {
    "key": "inspC",
    "text": "累計送檢量",
  },
  {
    "key": "insptype",
    "text": "分類",
  },
  {
    "key": "insptypeNM",
    "text": "分類名稱",
  },
  {
    "key": "VTWEG",
    "text": "配銷通路",
  },
  {
    "key": "VBELN",
    "text": "訂單號",
  },
  {
    "key": "POSNR",
    "text": "訂單項次",
  },
  {
    "key": "VBELN_VL",
    "text": "出貨單號",
  },
  {
    "key": "POSNR_VL",
    "text": "出貨項次",
  },
  {
    "key": "EIKTO",
    "text": "客戶我方帳號",
  },
  {
    "key": "KUNAG",
    "text": "買方",
  },
  {
    "key": "ZZSOLDTO_NANE",
    "text": "買方名稱",
  },
  {
    "key": "ZZSOLDTO_BU_SORT1",
    "text": "買方簡稱",
  },
  {
    "key": "ZZSOLDTO_LANDX",
    "text": "買方國家名稱",
  },
  {
    "key": "BSTKD",
    "text": "客戶單號",
  },
  {
    "key": "BSTKD_E",
    "text": "收貨方單號",
  },
  {
    "key": "KUNWE",
    "text": "收貨方",
  },
  {
    "key": "ZZSHIPTO_NAME",
    "text": "收貨方名稱",
  },
  {
    "key": "ZZSHIPTO_SORT1",
    "text": "收貨方簡稱",
  },
  {
    "key": "ZZSHIPTO_LANDX",
    "text": "出貨方國家名稱",
  },
  {
    "key": "CITY1",
    "text": "出貨方城市1",
  },
  {
    "key": "ZZENDCUST",
    "text": "終端買主",
  },
  {
    "key": "ZZENDCUST_NAME",
    "text": "終端買主名稱",
  },
  {
    "key": "ZZENDCUST_BU_SORT1",
    "text": "終端買主簡稱",
  },
  {
    "key": "ZZENDCUST_LANDX",
    "text": "終端買主國家名稱",
  },
  {
    "key": "MATNR",
    "text": "物料",
  },
  {
    "key": "ARKTX",
    "text": "項目描述",
  },
  {
    "key": "KDMAT",
    "text": "客戶物料",
  },
  {
    "key": "ALRow",
    "text": "鋁棒料號",
  },
  {
    "key": "POSTX",
    "text": "客戶物料品名",
  },
  {
    "key": "ZZKDMAT_MODEL",
    "text": "客戶物料型號",
  },
  {
    "key": "ZZCUST_MAT",
    "text": "終端客戶物料",
  },
  {
    "key": "ZZCUST_DESC",
    "text": "終端客戶物料品名",
  },
  {
    "key": "ZZCUST_MODEL",
    "text": "終端客戶物料型號",
  },
  {
    "key": "ZZLABEL",
    "text": "訂單標籤備註",
  },
  {
    "key": "ZZBATCH_NO",
    "text": "客戶物料批號",
  },
  {
    "key": "MATKL",
    "text": "物料群組",
  },
  {
    "key": "WGBEZ",
    "text": "物料群組描述",
  },
  {
    "key": "ZZWGBEZ_EN",
    "text": "物料群組描述英文",
  },
  {
    "key": "WADAT",
    "text": "計畫出貨日",
  },
  {
    "key": "CHARG",
    "text": "出貨批次",
  },
  {
    "key": "LFIMG",
    "text": "出貨數量",
  },
  {
    "key": "TRGQTY",
    "text": "每箱數量",
  },
  {
    "key": "TOTLWEI",
    "text": "每箱毛重",
  },
  {
    "key": "LOADWEI",
    "text": "每箱淨重",
  },
  {
    "key": "LENGTH",
    "text": "紙箱長",
  },
  {
    "key": "WIDTH",
    "text": "紙箱寬",
  },
  {
    "key": "HEIGHT",
    "text": "紙箱高",
  },

  {
    "key": "ZZTOTAL_BOX",
    "text": "總箱數或總件數 (出貨數量/每箱數量)",
  },
  {
    "key": "UNITWEI_MAX",
    "text": "重量單位",
  },
  {
    "key": "TAREVOL",
    "text": "每箱材積",
  },
  {
    "key": "UNITVOL",
    "text": "材積單位",
  },
  {
    "key": "ZZSOLDTO_ADDR",
    "text": "買方地址",
  },
  {
    "key": "ZZSHIPTO_ADDR",
    "text": "收貨方地址",
  },
  {
    "key": "ailas",
    "text": "航太廠物料型號",
  },
  {
    "key": "KWMENG",
    "text": "訂單數量",
  },
  {
    "key": "ZSDT003-ZZDDT",
    "text": "Dot Number",
  },
];
// 內箱標籤用
const alexKeyOptions2 = [
  { key: "型號", text: "alex-型號" },
  { key: "直徑", text: "alex-直徑" },
  { key: "寬度", text: "alex-寬度" },
  { key: "PCD值", text: "alex-PCD值" },
  { key: "孔數", text: "alex-孔數" },
  { key: "孔徑", text: "alex-孔徑" },
  { key: "顏色加工", text: "alex-顏色加工" },
  { key: "眼色", text: "alex-眼色" },
  { key: "表面處理", text: "alex-表面處理" },
  { key: "A510_Z01", text: "型號" },
  { key: "A510_Z04", text: "直徑" },
  { key: "A510_Z05", text: "寬度" },
  { key: "A510_Z11", text: "PCD值" },
  { key: "A510_Z12", text: "孔數" },
  { key: "A510_Z13", text: "孔徑" },
  { key: "A510_Z06", text: "顏色加工" },
  { key: "A510_Z07", text: "眼色" },
  { key: "A510_Z08", text: "表面處理" },
  { key: "B522_Z01", text: "型號" },
  { key: "B522_Z03", text: "直徑" },
  { key: "B522_Z04", text: "寬度" },
];

const keyTextOptions = [
  ...alexKeyOptions,
  ...alexKeyOptions2,
  { key: "PN", text: "工單料號" },
  { key: "custPN", text: "客戶料號" },
  { key: "custNM", text: "客戶名稱" },
  { key: "batchID", text: "批號" },
  { key: "SN", text: "流水號" },
  { key: "date", text: "日期" },
  { key: "woN", text: "工單號" },
  { key: "orderN", text: "工單訂單號" },
  { key: "orderitemno", text: "工單訂單項次" },
  { key: "pdtNM", text: "機種" },
  { key: "pdtspec", text: "規格" },
  { key: "keyDigits", text: "檢驗碼" }, //因為跟其他地方命名重複 所以取名為 keyDigits !!!需要付錢才能開啟
  { key: "var1", text: "自訂關鍵字1" },
  { key: "var2", text: "自訂關鍵字2" },
  { key: "var3", text: "自訂關鍵字3" },
  { key: "var4", text: "自訂關鍵字4" },
  { key: "var5", text: "自訂關鍵字5" },
  { key: "var6", text: "自訂關鍵字6" },
  { key: "var7", text: "自訂關鍵字7" },
  { key: "var8", text: "自訂關鍵字8" },
];

const sapTypeOptions = [
  { label: "正嘜標籤", value: "1" },
  { label: "側嘜標籤", value: "2" },
  { label: "外箱標籤", value: "3" },
  { label: "棧板標籤", value: "4" },
  { label: "其他標籤", value: "5" },
];

const typeMapping = {
  "text": "文字",
  "keyText": "關鍵文字",
  "quoteText": "引用文字",
  "border": "線條",
  "rect": "四方型",
  "round": "圓型",
  "img": "圖片",
};

export { unitArr, keyTextOptions, sapTypeOptions, typeMapping };
