import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  message,
  Radio,
  Space,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import { addMgmtcat, modifyMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal, s_tabKey }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addMgmtcat = useAPI(addMgmtcat);
  const call_modifyMgmtcat = useAPI(modifyMgmtcat);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addMgmtcat.request({ ...values, pageType: "71", type: s_tabKey });
    }

    if (type === "edit") {
      call_modifyMgmtcat.request({ ...values, pageType: "71", type: s_tabKey });
    }
  };

  useEffect(() => {
    if (call_addMgmtcat.status === "suc") {
      message.success(call_addMgmtcat.msg);
      set_s_isShowModal(false);
    } else if (call_addMgmtcat.status === "err") {
      message.error(call_addMgmtcat.msg);
    }
  }, [call_addMgmtcat.status]);

  useEffect(() => {
    if (call_modifyMgmtcat.status === "suc") {
      message.success(call_modifyMgmtcat.msg);
      set_s_isShowModal(false);
    } else if (call_modifyMgmtcat.status === "err") {
      message.error(call_modifyMgmtcat.msg);
    }
  }, [call_modifyMgmtcat.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={t("ADM.data-auth-manage.mgmtitem")}
            name="mgmtitem"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("util.util.placeholder_input")}${t("ADM.data-auth-manage.mgmtitem")}`}
              disabled={Object.keys(s_editData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.itemNM")} name="itemNM" rules={[{ required: true }]}>
            <Input placeholder={`${t("util.util.placeholder_input")}${t("util.util.itemNM")}`} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group>
              <Radio value={true}>{t("util.util.yes")}</Radio>
              <Radio value={false}>{t("util.util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
