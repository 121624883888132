import { forwardRef, useState } from "react";

import HD from "./HD";

const SampleSet = forwardRef((props, ref) => {
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  return (
    <div>
      <HD ref={ref} c_lightData={c_lightData} set_c_lightData={set_c_lightData} {...props} />
      {/* {Object.keys(c_lightData).length > 0 && (
        <TB c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      )} */}
    </div>
  );
});

export default SampleSet;
