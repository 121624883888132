import { useState, useEffect } from "react";
import { Modal, message, Button } from "antd";
import {
  ExclamationCircleOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { multipleIDtoName } from "@/util/format";
import { getWpcTb, deleteWpcTb } from "@/service/apis/ADM/edm";
import { getPWC } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import PageHeader from "@/components/PageHeader/index";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer/index";

import Edit from "./ModalContainer/Edit";

const TB = (props) => {
  const { t } = useTranslation();

  const { c_lightData, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isShowEdit, set_s_isShowEdit] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const call_getWpcTb = useAPI(getWpcTb);
  const call_deleteWpcTb = useAPI(deleteWpcTb);
  const call_getPWC = useAPI(getPWC);

  const tableColumns = [
    {
      title: t("ADM.edm.wpcSN"),
      dataIndex: "wpcSN",
      align: "center",
      // className: "bg-[#f7e7af]",
      width: "5%",
    },
    {
      title: t("ADM.edm.nextSN"),
      dataIndex: "nextSN",
      align: "center",
      // className: "bg-[#f7e7af]",
      width: "5%",
    },
    {
      title: t("ADM.edm.pfNM"),
      dataIndex: "pfID",
      // className: "bg-[#f7e7af]",
      width: "8%",
      align: "center",
      render: (_, rowData) => `${rowData.pfID}_${rowData.pfNM}`,
    },
    {
      title: t("util.util.ws"),
      dataIndex: "wsID",
      // className: "bg-[#f7e7af]",
      width: "8%",
      align: "center",
      render: (_, rowData) => `${rowData.wsID}_${rowData.wsNM}`,
    },
    {
      title: t("ADM.util.pwc"),
      dataIndex: "pwcID",
      // className: "bg-[#f7e7af]",
      width: "8%",
      lign: "center",
      render: (text) => multipleIDtoName(call_getPWC.data, text, "pwcID", "pwcNM"),
    },
    {
      title: t("ADM.edm.batchbom"),
      dataIndex: "batchbom",
      // className: "bg-[#f7e7af]",
      width: "6%",
      align: "center",
    },
    {
      title: t("ADM.edm.whID"),
      dataIndex: "whNM",
      // className: "bg-[#f7e7af]",
      width: "8%",
      align: "center",
    },
    {
      title: t("ADM.edm.warnyield"),
      dataIndex: "warnyield",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "6%",
      render: (text) => `${text}%`,
    },
    {
      title: t("ADM.edm.alertyield"),
      dataIndex: "alertyield",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "6%",
      render: (text) => `${text}%`,
    },
    {
      title: t("ADM.edm.alertspeed"),
      dataIndex: "alertspeed",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
      render: (text) => `${text}%`,
    },
    {
      title: t("ADM.edm.alertstop"),
      dataIndex: "alertstop",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
      render: (text) => `${text}s`,
    },
    {
      title: t("ADM.edm.stdwh"),
      dataIndex: "stdwh",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
      render: (text) => `${text}s`,
    },
    {
      title: t("ADM.edm.stdmh"),
      dataIndex: "stdmh",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
      render: (text) => `${text}s`,
    },
    {
      title: t("ADM.edm.stdlt"),
      dataIndex: "stdlt",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "5%",
      render: (text) => `${text}s`,
    },
    {
      title: t("ADM.edm.planhead"),
      dataIndex: "planhead",
      className: "bg-[#f9e9d7ac]",
      width: "5%",
      align: "center",
    },
    {
      title: t("util.util.note"),
      dataIndex: "note",
      align: "left",
    },
    // {
    //   title: t("util.util.action"),
    //   dataIndex: "action",
    //   align: "center",
    //   width: "5%",
    //   render: (_, rowData) => (
    //     <Space>
    //       <IconPen
    //         onClick={(e) => {
    //           set_s_editData(rowData);
    //           set_s_isShowModal(true);
    //           e.stopPropagation();
    //         }}
    //       />
    //       <IconGarbage
    //         onClick={(e) => {
    //           removeData(rowData);
    //           e.stopPropagation();
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    call_getWpcTb.request(c_lightData);
    call_getPWC.request();
  }, [c_lightData]);

  useEffect(() => {
    if (call_getWpcTb.status === "suc") {
      message.success(call_getWpcTb.msg);
      set_s_tableData(
        call_getWpcTb.data.map((x, i) => ({
          ...x,
          // pwcID: x.pwcID.split("|").filter((x) => x !== ""),
          key: x.wpcSN + x.itemno,
        }))
      );
    } else if (call_getWpcTb.status === "err") {
      message.error(t(`error.error-code.${call_getWpcTb.msg}`));
      set_s_tableData([]);
    }
  }, [call_getWpcTb.status]);

  useEffect(() => {
    if (call_deleteWpcTb.status === "suc") {
      message.success(call_deleteWpcTb.msg);
      call_getWpcTb.request(c_lightData);
    } else if (call_deleteWpcTb.status === "err") {
      message.error(t(`error.error-code.${call_deleteWpcTb.msg}`));
      call_getWpcTb.request(c_lightData);
    }
  }, [call_deleteWpcTb.status]);

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      cancelText: t("util.util.cancel"),
      onOk: () => call_deleteWpcTb.request(rowData),
    });
  };

  return (
    <>
      <PageHeader
        title={
          <span>
            {t("ADM.edm.productionScheduleDetails")}_
            <span className="text-blue-500">
              {c_lightData.PN}_{c_lightData.wpcset}
            </span>
          </span>
        }
        extra={[
          // <Button
          //   type="primary"
          //   icon={<FullscreenOutlined />}
          //   key="modify"
          //   onClick={() => set_s_isShowModal(true)}
          // >
          //   {t("util.util.add")}
          // </Button>,
          // <AddButton type="primary" key="create" onClick={() => set_s_isShowModal(true)}/>,
          <Button
            type="primary"
            icon={<FullscreenOutlined />}
            key="edit"
            onClick={() => set_s_isShowEdit(true)}
          >
            {t("ADM.edm.edit_all_wpc")}
          </Button>,
          <Button
            icon={<FullscreenExitOutlined />}
            danger
            onClick={() => set_c_lightData({})}
            key="hidden"
          >
            {t("util.util.hide_detail")}
          </Button>,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getWpcTb.status === "load"}
        c_lightData={c_lightData}
        tbMode={Object.keys(s_editData).length > 0}
        pagination={false}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0 ? (
            <span>
              {t("util.util.edit")}
              {t("ADM.edm.productionScheduleDetails")}_
              <span className="text-blue-500">
                {c_lightData.PN}_{c_lightData.wpcset}
              </span>
            </span>
          ) : (
            <span>
              {t("util.util.add")}
              {t("ADM.edm.productionScheduleDetails")}_
              <span className="text-blue-500">
                {c_lightData.PN}_{c_lightData.wpcset}
              </span>
            </span>
          )
        }
        width={"50%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getWpcTb.request(c_lightData);
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
      <CustomModal
        key="edit"
        title={t("ADM.edm.edit_all_wpc")}
        centered
        width={"60%"}
        open={s_isShowEdit}
        onCancel={() => set_s_isShowEdit(false)}
        afterClose={() => {
          call_getWpcTb.request(c_lightData);
        }}
      >
        <Edit
          set_s_isShowEdit={set_s_isShowEdit}
          s_tableData={s_tableData}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
