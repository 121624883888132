import React from "react";

/**
 * 一個渲染可自訂尺寸視訊播放器的 React 組件。
 *
 * @param {string} props.filePath - 要播放的視訊文件路徑。
 * @param {string} [props.videoWidth="1080"] - 視訊播放器寬度(單位: 像素)，預設為 1080。
 * @param {string} [props.videoHeight="720"] - 視訊播放器高度(單位: 像素)，預設為 720。
 * @returns {JSX.Element} 一個渲染視訊播放器的 React 組件。
 */
const Video = ({ filePath, videoWidth = "1080", videoHeight = "720" }) => {
  return (
    <div className="flex justify-center">
      <video autoPlay width={videoWidth} height={videoHeight} src={filePath} controls="true" />
    </div>
  );
};

export default React.memo(Video);
