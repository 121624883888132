import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Button, Space, message, Transfer, Divider } from "antd";

import useAPI from "@/hooks/useAPI";

import { getQmsSipCompare, importQmsSipTb } from "@/service/apis/QMS/QMSManage";
import styled from "styled-components";

const CustomTransfer = styled(Transfer)`
  justify-content: space-around !important;
  border-color: #d7d7d7 !important;

  .ant-transfer-list {
    width: 450px !important;
  }
  .ant-transfer-list-header {
    background-color: #def7ff !important;
  }

  /* Styles for unchecked items */
  .ant-transfer-list-content-item {
    /* background-color: green !important; */
    background-color: #ffffff;
    height: 55px;
  }
  .ant-transfer-list-content-item:nth-child(even) {
    /* background-color: green !important; */
    background-color: #d7d7d7;
    height: 55px;
  }

  /* Styles for checked items */
  .ant-transfer-list-content-item-checked {
    background-color: #d2eaff !important;
  }
  .ant-transfer-list-content-item-checked .ant-btn {
    width: 80px !important;
    height: 80px;
    background-color: #559ee2 !important;
    margin-top: 42px;
  }
  .ant-btn[disabled] {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #badbfa !important;
  }
  .ant-btn {
    width: 80px !important;
    height: 80px;
    margin-top: 42px;
    background-color: #4096ff !important;
  }
  .ant-btn .ant-btn-icon {
    /* display: block !important; */
    /* width:100px !important; */
    /* height:100px !important; */
  }
  .ant-btn .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
  }
  .ant-transfer-operation {
  }
  .ant-btn[disabled] .ant-btn-icon .anticon svg {
    display: block !important;
    width: 46px !important;
    height: 46px !important;
    color: #ffffff;
  }

  .ant-checkbox-inner {
    /* background-color: red !important; */
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #559ee2 !important;
  }
`;

const BatchImport = ({
  set_modal,
  call_getSpcOverviewSocket,
  set_s_selectedDrawno,
  s_selectedDrawno,
}) => {
  const { t } = useTranslation();

  const data = call_getSpcOverviewSocket?.socketData?.data;
  const dataSource =
    data?.map((item, index) => ({
      key: item.drawno,
      data: { ...item },
      title: `${item?.drawno}_${item?.inspitem}_${item?.SL}`,
    })) || [];
  const initialTargetKeys =
    s_selectedDrawno.length === 0
      ? data?.map((item) => item.drawno)
      : data?.reduce((acc, item) => {
          if (s_selectedDrawno.includes(item.drawno)) {
            acc.push(item.drawno);
          }
          return acc;
        }, []);
  // const initialTargetKeys = data.map((item) => item.drawno);

  // const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState(initialTargetKeys);

  const [selectedData, setSelectData] = useState([]);

  const handleChange = (targetKeys, direction, moveKeys) => {
    console.log(targetKeys);
    // setSelectData(selectedData);
    set_s_selectedDrawno(targetKeys);
    setTargetKeys(targetKeys);
  };
  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };
  const onSubmit = () => {
    // const payload = selectedData.map((item) => ({ ...item.data }));
    // console.log(payload);
    // set_s_selectedDrawno(payload);
    set_modal(false);
    // console.log({ dcUUID: c_lightData.dcUUID, SIP: payload, drawing: c_lightData.drawing });
  };
  console.log(s_selectedDrawno);
  return (
    <div className="w-full flex flex-col  justify-center items-center">
      <div className="w-[95%] ">
        <CustomTransfer
          dataSource={dataSource}
          showSearch={false}
          targetKeys={targetKeys}
          onChange={handleChange}
          onSearch={handleSearch}
          listStyle={{
            width: "100%",
            height: 500,
          }}
          render={(item) => item.title}
        />
      </div>

      <Divider />

      <Space className=" self-end">
        <Button onClick={() => set_modal(false)}>{t("util.util.cancel")}</Button>
        <Button type="primary" onClick={onSubmit}>
          {t("util.util.ok")}
        </Button>
      </Space>
    </div>
  );
};

export default BatchImport;
