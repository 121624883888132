import { useState, useRef, useContext, useEffect } from "react";
import SystemContext from "@/components/Context/SystemContext";
import { DatePicker, Input, Space, Button } from "antd";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import dayjs from "dayjs";

import Technology from "./Technology";
import Wheel_BeforeAndAfter from "./Wheel_BeforeAndAfter";
import Wheel_PN from "./Wheel_PN";
import Miscellaneous from "./Miscellaneous";
import Car_PN from "./Car_PN";
import Car_wl from "./Cal_wl";
import Car_label from "./Car_label";

export default function CT() {
  const { c_systemConfig } = useContext(SystemContext);

  const Technology_ref = useRef(null);
  const Wheel_BeforeAndAfter_ref = useRef(null);
  const Wheel_PN_ref = useRef(null);
  const Wheel_Miscellaneous_ref = useRef(null);
  const Car_PN_ref = useRef(null);
  const Car_wl_ref = useRef(null);
  const Car_label_ref = useRef(null);
  const Car_Miscellaneous_ref = useRef(null);

  const [s_tabKey, set_s_tabKey] = useState("Technology"); // 目前是在哪個Tab上
  const [s_searchData, set_s_searchData] = useState({
    itemNM: undefined,
    ID: undefined,
    spec: undefined,
    PN: undefined,
    staffID: undefined,
    workday: dayjs().format("YYYY-MM-DD"),
  });

  const onSearch = () => {
    s_tabKey === "Technology" && Technology_ref.current.onSearch();
    s_tabKey === "Wheel_BeforeAndAfter" && Wheel_BeforeAndAfter_ref.current.onSearch();
    s_tabKey === "Wheel_PN" && Wheel_PN_ref.current.onSearch();
    s_tabKey === "Wheel_Miscellaneous" && Wheel_Miscellaneous_ref.current.onSearch();
    s_tabKey === "Car_Miscellaneous" && Car_Miscellaneous_ref.current.onSearch();
    s_tabKey === "Car_PN" && Car_PN_ref.current.onSearch();
    s_tabKey === "Car_wl" && Car_wl_ref.current.onSearch();
    s_tabKey === "Car_label" && Car_label_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "Technology" && Technology_ref.current.onCreate();
    s_tabKey === "Wheel_BeforeAndAfter" && Wheel_BeforeAndAfter_ref.current.onCreate();
    s_tabKey === "Wheel_PN" && Wheel_PN_ref.current.onCreate();
    s_tabKey === "Wheel_Miscellaneous" && Wheel_Miscellaneous_ref.current.onCreate();
    s_tabKey === "Car_Miscellaneous" && Car_Miscellaneous_ref.current.onCreate();
    s_tabKey === "Car_PN" && Car_PN_ref.current.onCreate();
    s_tabKey === "Car_wl" && Car_wl_ref.current.onCreate();
    s_tabKey === "Car_label" && Car_label_ref.current.onCreate();
  };

  const onCalculate = () => {
    s_tabKey === "Wheel_Miscellaneous" && Wheel_Miscellaneous_ref.current.onCalculate();
    s_tabKey === "Car_Miscellaneous" && Car_Miscellaneous_ref.current.onCalculate();
  };

  const tabsData = [
    {
      key: "Technology",
      label: "科技廠",
      children: <Technology ref={Technology_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Technology"],
    },
    {
      key: "Wheel_BeforeAndAfter",
      label: "輪組前後輪",
      children: <Wheel_BeforeAndAfter ref={Wheel_BeforeAndAfter_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Wheel_BeforeAndAfter"],
    },
    {
      key: "Wheel_PN",
      label: "輪組貼標料號",
      children: <Wheel_PN ref={Wheel_PN_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Wheel_PN"],
    },
    {
      key: "Wheel_Miscellaneous",
      label: "輪組每日其他項目",
      children: <Miscellaneous ref={Wheel_Miscellaneous_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Wheel_Miscellaneous"],
    },

    {
      key: "Car_PN",
      label: "車圈料號機台維護",
      children: <Car_PN ref={Car_PN_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Car_PN"],
    },
    {
      key: "Car_wl",
      label: "車圈機台維護",
      children: <Car_wl ref={Car_wl_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Car_wl"],
    },
    {
      key: "Car_label",
      label: "車圈標籤維護",
      children: <Car_label ref={Car_label_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Car_label"],
    },
    {
      key: "Car_Miscellaneous",
      label: "車圈每日其他項目",
      children: <Miscellaneous ref={Car_Miscellaneous_ref} s_searchData={s_searchData} />,
      auth: c_systemConfig.auth["WHM-ct-Car_Miscellaneous"],
    },
  ];

  const TechnologySearch = (
    <Space>
      <Input
        value={s_searchData.itemNM}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, itemNM: e.target.value }))}
        placeholder="請輸入項目名稱"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const Wheel_BeforeAndAfterSearch = (
    <Space>
      <Input
        value={s_searchData.ID}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, ID: e.target.value }))}
        placeholder="請輸入輪組代碼"
      />
      <Input
        value={s_searchData.spec}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, spec: e.target.value }))}
        placeholder="請輸入輪組型號"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const Wheel_PNSearch = (
    <Space>
      <Input
        value={s_searchData.PN}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, PN: e.target.value }))}
        placeholder="請輸入貼標料號"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const Wheel_Miscellaneous = (
    <Space>
      <DatePicker
        value={dayjs(s_searchData.workday)}
        onChange={(e) =>
          set_s_searchData((prev) => ({
            ...prev,
            workday: dayjs(e || prev.workday).format("YYYY-MM-DD"),
          }))
        }
        placeholder="請選擇日期"
      />
      <Input
        value={s_searchData.staffID}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, staffID: e.target.value }))}
        placeholder="請輸入員工編號"
      />
      <Input
        value={s_searchData.itemNM}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, itemNM: e.target.value }))}
        placeholder="請輸入項目名稱"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />

      <Button type="primary" danger onClick={onCalculate}>
        重新計算
      </Button>
    </Space>
  );

  const Car_PNSearch = (
    <Space>
      <Input
        value={s_searchData.PN}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, PN: e.target.value }))}
        placeholder="請輸入料號"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const Car_wlSearch = (
    <Space>
      <Input
        value={s_searchData.wlID}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, wlID: e.target.value }))}
        placeholder="請輸入機碼或機名"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const Car_labelSearch = (
    <Space>
      <Input
        value={s_searchData.PN}
        onChange={(e) => set_s_searchData((prev) => ({ ...prev, PN: e.target.value }))}
        placeholder="請輸入料號"
      />
      <SearchButton type="primary" key="search" onClick={onSearch} />
      <AddButton type="primary" key="create" onClick={onCreate} />
    </Space>
  );

  const tabBarExtraContent =
    (s_tabKey === "Technology" && TechnologySearch) ||
    (s_tabKey === "Wheel_BeforeAndAfter" && Wheel_BeforeAndAfterSearch) ||
    (s_tabKey === "Wheel_PN" && Wheel_PNSearch) ||
    (s_tabKey === "Wheel_Miscellaneous" && Wheel_Miscellaneous) ||
    (s_tabKey === "Car_Miscellaneous" && Wheel_Miscellaneous) ||
    (s_tabKey === "Car_PN" && Car_PNSearch) ||
    (s_tabKey === "Car_wl" && Car_wlSearch) ||
    (s_tabKey === "Car_label" && Car_labelSearch);

  return (
    <>
      <PageHeader title="CT維護" extra={<Space key="CT">{tabBarExtraContent}</Space>} />

      <CustomTabs
        items={tabsData.filter((x) => x.auth)}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          set_s_searchData({
            itemNM: undefined,
            ID: undefined,
            spec: undefined,
            PN: undefined,
            staffID: undefined,
            workday: dayjs().format("YYYY-MM-DD"),
          });
        }}
      />
    </>
  );
}
