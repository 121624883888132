import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const getWoNSearch = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoNSearch`, {
    params: data,
  });
};

export const getWoNDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoNDetail`, {
    params: data,
  });
};

export const getWoProcHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoProcHd`, {
    params: data,
  });
};

export const getWoProcTab = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoProcTab`, {
    params: data,
  });
};

export const getWoProcTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWoProcTb`, {
    params: data,
  });
};
