/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
import { deleteOpTb, getOpTb } from "@/service/apis/ADM/optype";

import { DataContext } from "../index";
import FirstModalContainer from "./FirstModalContainer/index";
import Header from "./Header";

const innerWidth = window.innerWidth;

const TB = () => {
  const [scrollY, setScrollY] = useState("");
  const [s_showModal, set_s_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);

  const call_getOpTb = useAPI(getOpTb);

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  };

  // 全局項目編輯
  const handleClick = () => {
    set_s_showModal(true);
  };

  useEffect(() => {
    call_getOpTb.request({ optype: c_lightData.optype });
  }, [c_lightData]);

  useEffect(() => {
    const { status, msg, data } = call_getOpTb;
    if (status === "suc") {
      set_s_tableData(data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getOpTb.status]);

  const tableColumns = [
    {
      title: "作業類別",
      dataIndex: "optype",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: "作業單別",
      dataIndex: "opcode",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${300}px`,
    },
    {
      title: "單別名稱",
      dataIndex: "opNM",
      align: "center",
      width: innerWidth <= 1440 ? `${130}px` : `${300}px`,
    },
    {
      title: "過帳類型",
      dataIndex: "postype",
      align: "center",
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
      render: (text, rowData, index) => {
        const map = {
          "1": "借",
          "2": "貸",
          "3": "先借後貸",
        };
        return map[text];
      },
    },
    // {
    //   title: '操作',
    //   dataIndex: 'render',
    //   key: 'render',
    //   width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
    //   align: 'center',
    //   render: (text, rowData, index) => (
    //     <OperateArea
    //       onEditClick={() => editTbdata(rowData)}
    //       onDeleteClick={() => deleteTbdata(rowData)}
    //     />
    //     // <div style={{display: 'flex', justifyContent: 'space-around'}}>
    //     //   <div style={{display: 'flex'}}>
    //     //     <IconPen onClick={() => editWpc(rowData)} className='icon' />
    //     //     <IconTrash onClick={() => deleteWpc(rowData)} className='icon' />
    //     //   </div>
    //     // </div>
    //   )
    // }
  ];

  const title = `${
    Object.keys(c_lightData).length > 0 ? `作業科目明細__${c_lightData.optype}` : ""
  }`;

  return (
    <>
      <PageHeader
        title={title}
        extra={<Header handleClick={handleClick} handleClose={handleClose} />}
      />

      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content", y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return { ...item, key: index };
        })}
      />

      <CustomModal
        width="60%"
        // title={ Object.keys(s_editData).length === 0 ? "新增生產途程明細" : "編輯生產途程明細" }
        title="全局項目編輯"
        visible={s_showModal}
        maskClosable={false}
        destroyOnClose
        footer={null}
        forceRender
        getContainer={false}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          call_getOpTb.request({ optype: c_lightData.optype });
        }}
      >
        {s_showModal ? (
          <FirstModalContainer s_tableData={s_tableData} set_s_showModal={set_s_showModal} />
        ) : null}
      </CustomModal>
    </>
  );
};

export default TB;
