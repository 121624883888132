import { useState, useEffect, useRef } from "react";
import { Row, Col, Space, Button, Form, message } from "antd";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
import { useTranslation } from "react-i18next";
import { typeMapping, keyTextOptions } from "../../../../util/anyMap";
export default function SortTb({ set_s_showModal, form_tb_ref }) {
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: 120,
    },
    {
      title: "屬性",
      dataIndex: "type",
      width: 120,
      render: (value) => typeMapping[value] ?? "條碼",
    },
    {
      title: "內容",
      dataIndex: "title",
      render: (_, rowData) => {
        const keyText = rowData.quoteText ?? rowData.text ?? rowData.keyText;
        const ans = keyTextOptions.filter((x) => x.key === keyText)?.[0]?.text;
        return ans ?? keyText;
      },
    },
  ];

  useEffect(() => {
    const formTbData = form_tb_ref.getFieldValue("details");
    set_s_tableData(formTbData);
  }, []);

  const onSave = () => {
    form_tb_ref.setFieldsValue({
      details: s_tableData.map((x, i) => ({ ...x, key: i + 1, index: i + 1, choose: false })),
    });
    set_s_showModal(false);
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <DragSortingTable
          columns={tableColumns.filter((x) => !x.hidden)}
          dataSource={s_tableData}
          set_s_tableData={set_s_tableData}
          pagination={false}
          scroll={{ y: window.innerHeight * 0.7 }}
          afterSorting={(data) => data}
        />
      </Col>
      <Col span={24} className=" flex justify-end">
        <Space>
          <Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
          <Button type="primary" onClick={onSave}>
            {t("util.util.ok")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
