import React, { useState, useContext, useEffect } from "react";
import MyCard from "@/pages/TestPage/QMS/SPC/Alert/Card/index";
import { Row, Col, PageHeader, Input, Button, Divider, message } from "antd";
import { PlayCircleFilled, PauseCircleFilled, FullscreenExitOutlined } from "@ant-design/icons";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import displayString from "../util/displayString";
import { updateSpcN } from "@/service/apis/QMS/spc";
import useAPI from "@/hooks/useAPI";
import { addSpcN } from "@/service/apis/QMS/QMSManage";

// const buttonStyle = "text-[#7C79D2] border-[1px] border-[#7C79D2]  text-[16px] font-bold";

const ControlArea = ({ s_checked, set_s_checked ,call_getQmsSipHd}) => {
  const { s_thisSpcNS } = useContext(WpcContext);

  const { t } = useTranslation();

  const [s_cardModal, set_s_cardModal] = useState(false);

  //決定按鈕的顯示顏色
  const display = "text-[#7C79D2]";
  const disabled = "text-[#BCBCBC]";

  const call_updateSpcN = useAPI(updateSpcN);
  const call_addSpcN = useAPI(addSpcN);

  const runButtonDisplay = () => {
    if (Object.keys(s_thisSpcNS).length === 0) return disabled;
    if (!s_thisSpcNS.spcN) return disabled;
    return s_checked ? disabled : display;
  };
  const stopButtonDisplay = () => {
    if (Object.keys(s_thisSpcNS).length === 0) return disabled;
    if (!s_thisSpcNS.spcN) return disabled;
    return s_checked ? display : disabled;
  };
  const addButtonDisplay = () => {
    if (Object.keys(s_thisSpcNS).length === 0) return disabled;
    if (s_thisSpcNS.spcN) return disabled;
    return display
  };
  
  useEffect(() => {
    set_s_checked(s_thisSpcNS.isvalid);
  }, [s_thisSpcNS]);

  useEffect(() => {
    const { msg, status } = call_addSpcN;
    if (status === "suc") {
      message.success(msg);
      call_getQmsSipHd.request({siptype: "SPC"})
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_addSpcN.status]);

  return (
    <>
      <div className=" rounded-lg bg-white">
        <div className="w-full flex flex-col justify-center items-center  ">
          <div className="px-[15px] w-full py-[15px] flex-1 text-white flex flex-col  items-center  gap-[21px] justify-center shadow-md bg-[#8D8BF5] rounded-t-[8px]">
            <div className="flex justify-center py-[8px] rounded-3xl text-[14px] w-[120px] bg-[#5A59B9]">
              控制標準
            </div>
            <div className="text-[16px] w-full flex justify-center">
              {displayString(s_thisSpcNS.sipID)}{" "}
            </div>
          </div>
          <div className="flex  justify-around w-full items-center ">
            <Button
              className={`text-[20px] ${runButtonDisplay()}  
               flex  justify-center items-center flex-1 h-[55px]`}
              size="large"
              type="text"
              disabled={
                Object.keys(s_thisSpcNS).length === 0 || !s_checked === false || !s_thisSpcNS.spcN
              }
              // disabled={!s_checked}
              icon={<PlayCircleFilled className={`text-[20px] ${runButtonDisplay()}`} />}
              onClick={() => {
                set_s_checked(true);
                call_updateSpcN.request({ ...s_thisSpcNS, isvalid: true });
              }}
            >
              運行
            </Button>
            <Divider className=" h-[30px] border-[#BCBCBC] border-2" type="vertical" />

            <Button
              className={`text-[20px] ${stopButtonDisplay()}  flex justify-center  items-center flex-1 h-[55px]`}
              size="large"
              type="text"
              disabled={
                Object.keys(s_thisSpcNS).length === 0 || !s_checked === true || !s_thisSpcNS.spcN
              }
              icon={<PauseCircleFilled className={`text-[20px]  ${stopButtonDisplay()}`} />}
              // disabled={s_checked}
              onClick={() => {
                set_s_checked(false);
                call_updateSpcN.request({ ...s_thisSpcNS, isvalid: false });
              }}
            >
              暫停
            </Button>
            <Divider className=" h-[30px] border-[#BCBCBC] border-2" type="vertical" />

            <Button
              className={`text-[20px] ${addButtonDisplay()}  
               flex  justify-center items-center flex-1 h-[55px]`}
              size="large"
              type="text"
              disabled={
                Object.keys(s_thisSpcNS).length === 0 || !s_checked === true || s_thisSpcNS.spcN
              }
              // disabled={!s_checked}

              onClick={() => {
                call_addSpcN.request({ ...s_thisSpcNS });
              }}
            >
              啟動
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ControlArea;
// ${
//   Object.keys(s_thisSpcNS).length === 0 && "invisible"}

//   ${
//     Object.keys(s_thisSpcNS).length === 0 && "invisible"
//   }
