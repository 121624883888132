/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Form, Input, Row, Select, message } from "antd";
import React, { useEffect } from "react";

import useAPI from "@/hooks/useAPI";
import { addOpHd, modifyOpHd } from "@/service/apis/ADM/optype";

const ModalContainer = ({ s_editData, set_s_editData, set_s_showModal }) => {
  const [form] = Form.useForm();

  const call_addOpHd = useAPI(addOpHd);
  const call_modifyOpHd = useAPI(modifyOpHd);

  useEffect(() => {
    form.resetFields();
  }, [form, s_editData]);

  const handleCancel = () => {
    set_s_editData({});
    set_s_showModal(false);
  };

  useEffect(() => {
    const { status, msg } = call_addOpHd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_addOpHd.status]);

  useEffect(() => {
    const { status, msg } = call_modifyOpHd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_modifyOpHd.status]);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "modify";

    if (type === "create") {
      call_addOpHd.request(values);
    }

    if (type === "modify") {
      call_modifyOpHd.request(values);
    }
  };

  return (
    <Form.Provider
      onFormChange={(formName, info) => {
        const { changedFields, forms } = info;
      }}
    >
      <Form
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          optype: Object.keys(s_editData).length > 0 ? s_editData.optype : "", // 作業類別
          typeNM: Object.keys(s_editData).length > 0 ? s_editData.typeNM : "", // 類別說明
          opfunction: Object.keys(s_editData).length > 0 ? s_editData.opfunction : null, // 營運功能
          note: Object.keys(s_editData).length > 0 ? s_editData.note : "", // 備註
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              label="作業類別"
              name="optype"
              rules={[
                { required: true, message: "請輸入作業類別!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value.length !== 2) {
                      return Promise.reject(new Error("作業單別只可為2位數"));
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input disabled={Object.keys(s_editData).length > 0 ? true : false} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="類別說明"
              name="typeNM"
              rules={[{ required: true, message: "請輸入類別說明!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="營運功能" name="opfunction">
              <Select
                placeholder="請選擇營運功能"
                options={[
                  { label: "訂單系統", value: "1" },
                  { label: "採購系統", value: "2" },
                  { label: "生產系統", value: "3" },
                  { label: "庫儲系統", value: "4" },
                  { label: "財務系統", value: "5" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item label="備註" name="note">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ margin: "6px 0px 12px 0px" }} />

        <Row>
          <Col offset={12} span={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="large"
                style={{ marginRight: "20px", borderRadius: "5px" }}
                onClick={handleCancel}
              >
                取消
              </Button>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ borderRadius: "5px" }}
                >
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Form.Provider>
  );
};

export default ModalContainer;
