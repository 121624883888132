import { useState, useEffect } from "react";
import { message, DatePicker, Space, Button, Input } from "antd";
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";
import useAPI from "@/hooks/useAPI";
import { getWoPwc } from "@/service/apis/PIS/wpcSearch";
import Cookies from "js-cookie";
const WpcSearch = () => {
  const [s_plant, set_s_plant] = useState(
    Cookies.get("plant") === "1011" ||
      Cookies.get("plant") === "1012" ||
      Cookies.get("plant") === "0000"
      ? false
      : true
  ); // 所有工站

  const [s_searchData, set_s_searchData] = useState({
    woN: undefined,
    orderitemno: undefined,
  });
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getWoPwc = useAPI(getWoPwc);

  const tableColumns = [
    {
      title: "工單號",
      dataIndex: "wpcset",
      align: "center",
      width:120,
    },
    {
      title: "訂單號",
      dataIndex: "orderN",
      align: "center",
      width:120,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      width: 250,
    },
    {
      title: "規格",
      dataIndex: "pdtspec",
      width: 250,
    },
    {
      title: "訂單項次",
      dataIndex: "orderitemno",
      align: "center",
    },
    {
      title: "工作中心",
      dataIndex: "pwcID",
      width:175,
      render: (_, rowData) => rowData.pwcID + "_" + rowData.pwcNM,
    },
    {
      title: "鋁棒料號",
      dataIndex: "ALPN",
      align: "center",
      hidden: s_plant,
      width: "7%",
    },
    {
      title: "鍛胚",
      dataIndex: "forging",
      align: "center",
      hidden: s_plant,
      // width: "4%",
    },
    {
      title: "鏇胚",
      dataIndex: "spin",
      align: "center",
      hidden: s_plant,
      // width: "4%",
    },
    {
      title: "工序",
      dataIndex: "SAPwpc",
      align: "center",
    },
    {
      title: "工單總量",
      dataIndex: "run10planqty",
      align: "right",
    },
    {
      title: "計畫產量",
      dataIndex: "planqty",
      align: "right",
    },
    {
      title: "良品數量",
      dataIndex: "okC",
      align: "right",
    },
    {
      title: "不良數量",
      dataIndex: "ngC",
      align: "right",
    },
    {
      title: "排程日期",
      dataIndex: "schDate",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "排程順序",
      dataIndex: "schNo",
      align: "center",
    },
    {
      title: "不列印",
      dataIndex: "print",
      align: "center",
    },
  ];

  const handlerSearch = () => {
    call_getWoPwc.request(s_searchData);
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      handlerSearch();
    }
  };

  // useEffect(() => {
  //   handlerSearch();
  // }, []);
  useEffect(() => {
    if (call_getWoPwc.status === "suc") {
      message.success(call_getWoPwc.msg);
      set_s_tableData(
        call_getWoPwc.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getWoPwc.status === "err") {
      message.error(call_getWoPwc.msg);
      set_s_tableData([]);
    }
  }, [call_getWoPwc.status]);

  return (
    <>
      <PageHeader
        title={"製程進度查詢"}
        extra={[
          <Input
            key="woN"
            value={s_searchData.woN}
            onChange={(e) => set_s_searchData((prev) => ({ ...prev, woN: e.target.value }))}
            onKeyDown={handleKeyDownEnter}
            placeholder="請輸入工單號"
          />,
          <Input
            key="orderN"
            value={s_searchData.orderN}
            onChange={(e) => set_s_searchData((prev) => ({ ...prev, orderN: e.target.value }))}
            onKeyDown={handleKeyDownEnter}
            placeholder="請輸入訂單號"
          />,

          <Input
            key="orderitemno"
            value={s_searchData.orderitemno}
            onChange={(e) => set_s_searchData((prev) => ({ ...prev, orderitemno: e.target.value }))}
            onKeyDown={handleKeyDownEnter}
            placeholder="請輸入訂單項次"
          />,
          <Button key="search" type="primary" onClick={handlerSearch}>
            查詢
          </Button>,
        ]}
      />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        pagination={false}
        loading={call_getWoPwc.status === "load"}
      />
    </>
  );
};

export default WpcSearch;
