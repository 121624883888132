import { Button, Col, Divider, Form, Input, Radio, Row, Select, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { addInspmethod, updateInspmethod } from "@/service/apis/QMS/Instrument";
import { getEqgp } from "@/service/apis/QMS/eqgp";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

  const [s_eq, set_s_eq] = useState([]);

  // 新增表頭資料
  const call_addInspmethod = useAPI(addInspmethod);
  // 編輯表頭資料
  const call_updateInspmethod = useAPI(updateInspmethod);

  const call_getEqgp = useAPI(getEqgp);

  const onSubmit = (values) => {
    if (type === "create") {
      call_addInspmethod.request(values);
    }
    if (type === "edit") {
      call_updateInspmethod.request(values);
    }
  };

  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg, data } = call_addInspmethod;

    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addInspmethod.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateInspmethod;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateInspmethod.status]);

  useEffect(() => {
    if (type === "edit") {
      call_getEqgp.request({ assetkind: s_editData.assetkind });
    }
  }, []);

  useEffect(() => {
    if (call_getEqgp.status === "suc") {
      const options =
        call_getEqgp.data
          ?.filter((item) => item.isvalid)
          ?.map((item, index) => ({
            label: `${item.gpNM}`,
            value: `${item.gpID}`,
          })) || [];
      set_s_eq([...options]);
    }
    if (call_getEqgp.status === "err") {
      message.error(call_getEqgp.msg);
      set_s_eq([]);
    }
  }, [call_getEqgp.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        insptype: "measure",
        ...s_editData,
        UOM: s_editData.UOM,
      }}
      onFinish={onSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="方法ID" name="methodID">
            <Input disabled />
          </Form.Item>
        </Col>

        {/* 檢驗方法 */}
        <Col span={8}>
          <Form.Item label="檢驗方法" name="gpID">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t("util.util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.util.yes") },
                { value: false, label: t("util.util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        {/* 檢驗類型 */}
        <Col span={8}>
          <Form.Item label="檢驗類型" name="insptype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "計數型(定性)", value: "count" },
                { label: "計量型(定量)", value: "measure" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"單位"} name="UOM">
            <Select
              className="w-full "
              allowClear
              options={[
                { label: "mm", value: "mm" },
                { label: "µm", value: "µm" },
                { label: "Kg", value: "Kg" },
                { label: "g", value: "g" },
                { label: "°C", value: "°C" },
                { label: "°F", value: "°F" },
                { label: "Mpa", value: "Mpa" },
                { label: "HB", value: "HB" },
                { label: "HR", value: "HR" },
                { label: "HV", value: "HV" },
                { label: "%", value: "%" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="註記" name="note">
            <Input.TextArea
              autoSize={{
                minRows: 1,
                maxRows: 5,
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
