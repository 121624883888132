import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getContact = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getContact`, {
    params: data,
  });
};

export const addContact = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addContact`,
    data
  );
};

export const modifyContact = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifyContact`,
    data
  );
};

export const deleteContact = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteContact`,
    data
  );
};
