import apiBasic from "../APIBasic";

const wsConn = apiBasic.conn === "http" ? "ws" : "wss";

export const dashboardSocket = `${wsConn}://${apiBasic.url}/api/dashboard/socket`;

export const actSocket = `${wsConn}://${apiBasic.url}/api/act/socket`;

export const getSpcDataSocket = `${wsConn}://${apiBasic.url}/api/spc/getSpcData`;

export const getSpcDetailSocket = `${wsConn}://${apiBasic.url}/api/spc/getSpcDetail`;

export const efficiencySocket = `${wsConn}://${apiBasic.url}/api/efficiency/socket`;

export const custSocket = `${wsConn}://${apiBasic.url}/api/dashboard/custSocket`;

export const dashboardNewSocket = `${wsConn}://${apiBasic.url}/api/dashboard/newsocket`;

export const getSpcOverview = `${wsConn}://${apiBasic.url}/api/spc/getSpcOverview`;

export const focusSocket = `${wsConn}://${apiBasic.url}/api/focus/socket`;
