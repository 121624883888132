import { useState, useEffect } from "react";
import { Button, Space, message, Input, Popconfirm } from "antd";
import { IconPen, IconCheck, IconClose, IconGarbage } from "@/components/Icon/Action";
import styled from "styled-components";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getModelHd, deleteModelHd } from "@/service/apis/LMS/LabelManage";
import { useTranslation } from "react-i18next";
import { sapTypeOptions } from "../../util/anyMap";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import CanvasCreate from "../CanvaseCreate/index";

const LabelCustomModal = styled(CustomModal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const handleKeyDownEnter = (e, fun) => {
  if (e.key === "Enter") fun();
};

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;

  const call_getModelHd = useAPI(getModelHd);
  const call_deleteModelHd = useAPI(deleteModelHd);

  const [s_showModal, set_s_showModal] = useState(false);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_searchData, set_s_searchData] = useState({
    PN: undefined,
    modelID: undefined,
  });

  const tableColumns = [
    {
      title: "標籤名稱",
      dataIndex: "modelNM",
    },
    {
      title: "SAP標籤類型",
      dataIndex: "saptype",
      align: "center",
      render: (text) => text && sapTypeOptions.filter((x) => x.value === text)[0].label,
    },
    {
      title: "SAP標籤ID",
      dataIndex: "sapSNstr",
      align: "center",
    },
    {
      title: "標籤ID",
      dataIndex: "modelID",
    },
    { title: "版本", dataIndex: "ver", width: "180px", align: "center" },

    {
      title: "寬*長",
      dataIndex: "size",
      render: (_, rowData) =>
        `(${rowData.width}${rowData.unit} x ${rowData.height}${rowData.unit})`,
    },
    {
      title: "備註",
      dataIndex: "note",
      width: "30%",
    },
    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "90px",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_showModal(true);
              e.stopPropagation();
            }}
          />
          <Popconfirm
            title="刪除模板"
            description="請確認是否刪除當前模板?"
            onConfirm={() => call_deleteModelHd.request(c_lightData)}
            okText="確認"
            cancelText="取消"
            okButtonProps={{ loading: deleteModelHd.status === "load" }}
          >
            <IconGarbage
              onClick={(e) => {
                set_c_lightData(rowData);
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const search = (e) => {
    set_c_lightData({});
    call_getModelHd.request(s_searchData);
  };

  useEffect(() => {
    if (
      (s_searchData.PN === undefined || s_searchData.PN === "") &&
      (s_searchData.modelID === undefined || s_searchData.modelID === "")
    ) {
      call_getModelHd.request(s_searchData);
    }
  }, [s_searchData]);

  useEffect(() => {
    !s_showModal && call_getModelHd.request(s_searchData);
  }, [s_showModal]);

  useEffect(() => {
    if (call_getModelHd.status === "suc") {
      set_s_tableData(call_getModelHd.data.map((x, i) => ({ ...x, key: i, index: i })));
    } else if (call_getModelHd.status === "err") {
      message.error(call_getModelHd.msg);
      set_s_tableData([]);
    }
  }, [call_getModelHd.status]);

  useEffect(() => {
    if (call_deleteModelHd.status === "suc") {
      call_getModelHd.request(s_searchData);
      set_c_lightData({});
    } else if (call_deleteModelHd.status === "err") {
      message.error(call_deleteModelHd.msg);
    }
  }, [call_deleteModelHd.status]);

  const searchTemp = [
    <Input
      key="PN"
      placeholder="請輸入料號或客戶料號"
      value={s_searchData.PN}
      className="w-[300px]"
      allowClear
      onChange={(e) =>
        set_s_searchData((prev) => ({
          ...prev,
          PN: e.target.value,
        }))
      }
      onPressEnter={(e) => handleKeyDownEnter(e, search)}
    />,
    <Input
      key="modelID"
      className="w-[300px]"
      allowClear
      placeholder="請輸入標籤ID或標籤名稱"
      value={s_searchData.modelID}
      onChange={(e) =>
        set_s_searchData((prev) => ({
          ...prev,
          modelID: e.target.value,
        }))
      }
      onPressEnter={(e) => handleKeyDownEnter(e, search)}
    />,
    <Button type="primary" onClick={search} key="search">
      查詢
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={() => {
        set_s_showModal(true);
        set_c_lightData({});
      }}
    >
      新建標籤樣板
    </Button>,
  ];

  return (
    <>
      <PageHeader title="條碼模板管理" extra={searchTemp} />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getModelHd.status === "load"}
        // tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      <LabelCustomModal
        width="90%"
        title="新建標籤樣板"
        open={s_showModal}
        centered
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getModelHd.request(s_searchData);
        }}
      >
        <CanvasCreate
          set_s_showModal={set_s_showModal}
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
        />
      </LabelCustomModal>
    </>
  );
};
export default HD;
