import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { getPWC, getPF, getCustHd, getProductSelect } from "@/service/apis/ADM/publicAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";

import useAPI from "@/hooks/useAPI";

import { AddButton } from "@/components/PDS/Buttons";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

import PageHeader from "@/components/PageHeader/index";

import MeasuringEquipment from "./MeasuringEquipment";

const MeasurementEquipment = () => {
  const { t } = useTranslation();
  const ME_ref = useRef(null);
  const [s_tabKey, set_s_tabKey] = useState("A"); // 目前是在哪個Tab上

  const call_getMgmtcat75 = useAPI(getMgmtcat);
  const call_getPWC = useAPI(getPWC);
  const call_getPF = useAPI(getPF);
  const call_getCustHd = useAPI(getCustHd);
  const call_getProduct = useAPI(getProductSelect);

  const arrMap = {
    assetkind: [
      { label: t("util.util.assetkind_TI"), value: "TI" },
      { label: t("util.util.assetkind_TS"), value: "TS" },
      { label: t("util.util.assetkind_TD"), value: "TD" },
      { label: t("util.util.assetkind_TF"), value: "TF" },
    ],
    property: [
      { label: t("ADM.mold.company"), value: "company" },
      { label: t("util.util.custID"), value: "customer" },
    ],
    pfArr: call_getPF.data,
    psArr: call_getPWC.data,
    custArr: call_getCustHd.data,
    pdtArr: call_getProduct.data,
  };

  const tabsData = [
    {
      key: "A",
      label: "量秤設備",
    },
    {
      key: "B",
      label: "測量設備",
    },
    {
      key: "C",
      label: "儀器設備",
    },
  ];

  const onCreate = () => {
    // s_tabKey === "qualityAssuranceEquipment" && ME_ref.current.onCreate();
    ME_ref.current.onCreate();
  };
  // 確認是否刪資料
  useEffect(() => {
    call_getPWC.request();
    call_getPF.request();
    call_getCustHd.request();
    call_getProduct.request();
    call_getMgmtcat75.request({ pageType: "75" });
  }, []);

  return (
    <>
      <PageHeader
        title="儀器設備維護"
        extra={[
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              onCreate();
            }}
          />,
        ]}
      />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
        }}
      />
      <MeasuringEquipment ref={ME_ref} arrMap={arrMap} s_tabKey={s_tabKey} />
    </>
  );
};

export default MeasurementEquipment;
