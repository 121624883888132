/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";

import { getBL, deleteBL, getWh } from "@/service/apis/ADM/publicAPI";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
// util
import { multipleIDtoName } from "@/util/format";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const BL = forwardRef((props, ref) => {
  const { s_searchData } = props;
  // 編輯的資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否開啟編輯畫面
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 倉別下拉選單
  const [s_whOptions, set_s_whOptions] = useState([]);
  // useAPI 取得倉別
  const call_getWh = useAPI(getWh);
  // useAPI 取得儲位
  const call_getBL = useAPI(getBL);
  // useAPI 刪除儲位
  const call_deleteBL = useAPI(deleteBL);

  const { t } = useTranslation();

  // 將 s_whOptions 轉成 Table 的格式
  const wh_obj = useMemo(() => {
    return (
      s_whOptions?.reduce((prev, curr) => {
        prev[curr.value] = curr.label;
        return prev;
      }, {}) || {}
    );
  }, [s_whOptions]);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  // 搜尋
  const onSearch = () => {
    // alert(s_searchData.query);
    call_getBL.request({ whID: s_searchData.query });
  };

  const tableColumns = [
    {
      title: t("util.util.blID"), //"儲位碼",
      dataIndex: "blID",
    },
    {
      title: t("ADM.warehouse-manage.whNM"), //"倉別名稱",
      dataIndex: "whID",
      width: "10%",
      render: (text) => wh_obj[text],
    },
    {
      title: t("util.util.building"), //"棟",
      dataIndex: "building",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.floor"), //"樓",
      dataIndex: "floor",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.block"), //"儲區",
      dataIndex: "block",
      align: "center",
      width: "7%",
      render: (text) => t(`util.util.block_${text}`),
    },
    {
      title: t("ADM.BL.shelf"), //"儲架",
      dataIndex: "shelf",
      align: "center",
      // width: "15%",
    },
    {
      title: t("ADM.BL.column"), //"排序",
      dataIndex: "column",
      align: "center",
      width: "7%",
    },
    {
      title: t("ADM.BL.layer"), //"層序",
      dataIndex: "layer",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      // width: "25%",
    },
    {
      title: t("util.util.isvalid"), // 有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => {
        call_deleteBL.request(rowData);
      },
      cancelText: t("util.util.cancel"),
    });
  };

  // 第一次渲染
  useEffect(() => {
    onSearch();
    call_getWh.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getBL.status === "suc") {
      message.success(call_getBL.msg);
      set_s_tableData(
        call_getBL.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getBL.status === "err") {
      message.error(t(`error.error-code.${call_getBL.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBL.status]);

  // useAPI 取得倉別
  useEffect(() => {
    const { status, data, msg } = call_getWh;
    if (status === "suc") {
      message.success(msg);
      const options =
        data?.map((item) => ({
          label: `${item.whID}_${item.whNM}`,
          value: item.whID,
        })) || [];

      set_s_whOptions(options);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getWh.status]);

  // useAPI 刪除儲位
  useEffect(() => {
    if (call_deleteBL.status === "suc") {
      message.success(call_deleteBL.msg);
    } else if (call_deleteBL.status === "err") {
      message.error(t(`error.error-code.${call_deleteBL.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deleteBL.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBL.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.warehouse-manage.tabBL")}`
            : `${t("util.util.add")}${t("ADM.warehouse-manage.tabBL")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_whOptions={s_whOptions}
        />
      </CustomModal>
    </>
  );
});

export default BL;
