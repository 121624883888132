import React, { useState, useEffect } from "react";
import { Modal, message, Space, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";

//component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import ModalConintaer from "./ModalContainer";
import { AddButton } from "@/components/PDS/Buttons";
//套件、hook
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";

//api
import { getOEM, deleteOEM } from "@/service/apis/ADM/oem";

const OEMSuppManage = () => {
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getOEM = useAPI(getOEM);
  const call_deleteOEM = useAPI(deleteOEM);

  const tableColumns = [
    {
      title: t("ADM.oem-manage.vendorcode"), //"供應代碼",
      dataIndex: "vendorcode",
      align: "center",
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("ADM.util.pfID"), //"製程名稱",
      dataIndex: "pfNM",
      align: "center",
    },
    {
      title: t("ADM.oem-manage.oemitemNM"), //"外包項目名稱",
      dataIndex: "oemitemNM",
      align: "center",
    },
    {
      title: t("util.util.suppNM"), //"廠商名稱",
      dataIndex: "suppNM",
      align: "center",
    },
    {
      title: t("util.util.isvalid"), //"是否有效",
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.util.updtT"), //"更新時間",
      dataIndex: "updtT",
      align: "center",
      width: "8%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("util.util.action"), //"操作",
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () =>
        call_deleteOEM.request({
          ...rowData,
        }),
      cancelText: t("util.util.cancel"),
    });
  };

  useEffect(() => {
    call_getOEM.request();
  }, []);

  //取得OEM供應
  useEffect(() => {
    if (call_getOEM.status === "suc") {
      message.success(call_getOEM.msg);
      set_s_tableData(call_getOEM.data);
    } else if (call_getOEM.status === "err") {
      message.error(call_getOEM.msg);
    }
  }, [call_getOEM.status]);

  //刪除OEM供應
  useEffect(() => {
    if (call_deleteOEM.status === "suc") {
      message.success(call_deleteOEM.msg);
      set_s_editData({});
      call_getOEM.request();
    } else if (call_deleteOEM.status === "err") {
      message.error(call_deleteOEM.msg);
    }
  }, [call_deleteOEM.status]);

  return (
    <>
      <PageHeader
        title={t("ADM.oem-manage.oem_supp_manage")}
        extra={[
          // <Button
          //   type="primary"
          //   key="create"
          //   onClick={() => {
          //     set_s_isShowModal(true);
          //   }}
          // >
          //   {t("util.util.add")}
          // </Button>,
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.map((item, index) => {
          return { ...item, key: index };
        })}
        c_lightData={s_editData}
        loading={call_getOEM.status === "load"}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.edit")}${t("ADM.oem-manage.oem_supp")}`
            : `${t("util.util.add")}${t("ADM.oem-manage.oem_supp")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getOEM.request();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
    </>
  );
};

export default OEMSuppManage;
