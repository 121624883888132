import styled, { useTheme, keyframes } from "styled-components";
import { fontSize } from "@/styles/BasicSetting";
import { Tooltip, Space } from "antd";

const CardIcon = styled.span`
  height: 45px;
  width: 45px;
  svg {
    height: 45px;
    width: 45px;
    font-size: 30px;
  }
`;

const MyCardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
  /* border-top: 10px solid red; */
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.componentBg};
  white-space: nowrap;
  /* 控制邊框大border */
  ${({ theme, borderAlign, color }) => {
    return `border-${borderAlign}:10px solid ${color ? color : theme.primary}`;
  }}
`;

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative; /* 创建一个相对定位的容器 */
  justify-content: ${({ titleAlign }) =>
    titleAlign === "left"
      ? "flex-start"
      : titleAlign === "right"
      ? "flex-end"
      : titleAlign === "center"
      ? "center"
      : "flex-start"};
  color: ${({ theme }) => theme.text};
  padding: 0px 20px;
  font-size: ${({ type }) => (type === "QualityControl" ? `${fontSize.h3}` : `${fontSize.h4}`)};
  font-weight: 600;
  white-space: nowrap; /*//强制文本在一行内输出 */
  overflow: hidden; /*隐藏溢出部分*/
  text-overflow: ellipsis; /*//對溢出部分加上...*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  .extra {
    position: absolute; /* 使用绝对定位 */
    top: 0px; /* 距离容器顶部50px */
    left: 0px; /* 距离容器左侧100px */
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    color: ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) =>
    align === "left" ? "start" : align === "right" ? "end" : "center"};
  /* padding: 0px 20px; */
  font-weight: 600;
  white-space: nowrap; /*//强制文本在一行内输出 */
  overflow: hidden; /*隐藏溢出部分*/
  text-overflow: ellipsis; /*//對溢出部分加上...*/
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
  .main {
    font-size: ${fontSize.h4};
    color: ${({ theme }) => theme.text};
    display: flex;
    align-items: center;
    justify-content: ${({ align }) =>
      align === "left" ? "start" : align === "right" ? "end" : "center"};
    width: 100%;
  }
  .sub {
    font-size: ${fontSize.h5};
    color: ${({ theme }) => theme.text};
  }
`;

const MyCard = (props) => {
  return (
    <MyCardStyle {...props}>
      {/* <Tooltip title={props.title}> */}
      {/* <CardHeader {...props}>
          <Space>
            {props.icon && <CardIcon>{props.icon}</CardIcon>}
            {props.title}
          </Space>
          <span className="extra">{props.extra}</span>
        </CardHeader> */}
      {/* </Tooltip> */}
      <CardContent {...props} open={false}>
        {/* <Tooltip title={props.content}> */}
        <div className="main">{props.content}</div>
        {/* </Tooltip> */}
      </CardContent>
      {props.subContent && (
        // <Tooltip title={props.subContent}>
        <CardContent className="sub" {...props}>
          <div className="sub">{props.subContent}</div>
        </CardContent>
        // </Tooltip>
      )}
    </MyCardStyle>
  );
};

export default MyCard;
