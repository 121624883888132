import { Input, Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
// api
import useAPI from "@/hooks/useAPI";
import { getLine, getPF, getPWC, getStation } from "@/service/apis/ADM/publicAPI";
import { deleteRcphd, getRcphd } from "@/service/apis/QMS/QMSManage";
// util
import { formatAmount } from "@/util/format";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import FileModal from "../../File/FileModal";
// component
import ModalContainer from "./ModalContainer";

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  const [s_searchData, set_s_searchData] = useState({
    ID: undefined,
    PN: undefined,
    pdtNM: undefined,
  });
  // useAPI 取得表頭資料
  const call_getRcphd = useAPI(getRcphd);
  // useAPI 刪除表頭資料
  const call_deleteRcphd = useAPI(deleteRcphd);
  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);
  // 工作中心
  const call_getPWC = useAPI(getPWC);

  const onCreate = () => set_s_isShowModal(true);

  // 搜尋
  const onSearch = () => {
    call_getRcphd.request(s_searchData);
    set_c_lightData({});
  };

  // 刪除表頭資料
  const removeData = (rowData) => {
    set_c_lightData({});
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteRcphd.request(rowData),
      cancelText: t("util.util.cancel"),
    });
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev };
    });
  };

  const tableColumns = [
    {
      title: "RCP ID",
      dataIndex: "rcpID",
      align: "left",
      width: "18%",
      // sorter: (a, b) => a.sipID.localeCompare(b.sipID),
    },
    {
      title: "版次",
      dataIndex: "version",
      align: "center",
      width: "5%",
    },
    // {
    //   title: "文編碼",
    //   dataIndex: "dcUUID",
    //   width: "25%",
    //   // sorter: (a, b) => a.sipID.localeCompare(b.sipID),
    // },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "12%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      width: "12%",
      // sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: "記錄類型",
      dataIndex: "rcptype",
      width: "8%",
      align: "center",
      // sorter: (a, b) => a.siptype.localeCompare(b.siptype),
      // 等i18N寫法
      render: (text) => {
        const map = {
          "nwt": "固定量",
          "pct": "百分比",
        };
        return map[text];
      },
    },

    {
      title: "控點",
      dataIndex: "ctrlspot",
      width: "15%",
      align: "center",
      // sorter: (a, b) => a.ctrlspot.localeCompare(b.ctrlspot),
      render: (text) => {
        const map = {
          wp: "全品比對",
          wo: "工單比對",
          pf: "製程比對",
          ws: "工站比對",
          wl: "工線比對",
          pwc: "工作中心比對",
          qa: "品管控制比對",
        };
        return map[text];
      },
    },
    {
      title: "控點ID",
      dataIndex: "spotID",
      width: "18%",
      // sorter: (a, b) => a.spotID.localeCompare(b.spotID),
      render: (text, rowData, _) => {
        // console.log(s_arrMap[rowData.ctrlspot]?.find(x => x.value === text));
        return s_arrMap[rowData.ctrlspot]?.find((x) => x.value === text)?.label;
      },
    },

    {
      title: "分量總重",
      dataIndex: "nwt",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "使用單位",
      dataIndex: "UOM",
      width: "8%",
      align: "center",
      render: (text) => {
        if (text === "pct") {
          return "%";
        }
        return text;
      },
      // sorter: (a, b) => a.siptype.localeCompare(b.siptype),
    },
    {
      title: "總百分比(%)",
      dataIndex: "pct",
      align: "right",
      width: "8%",
    },
    {
      title: t("util.util.note"), //"備註",
      dataIndex: "note",
      align: "center",
      width: "10%",
      // render: (text) => ctrlrequire_map[text],
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      width: "90px",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    // {
    //   title: "檢驗週期",
    //   dataIndex: "ctrlCT",
    //   align: "center",
    //   width: "10%",
    //   render: (text) => formatAmount(text) + " hr",
    // },

    // {
    //   title: "工程圖檔",
    //   dataIndex: "drawing",
    //   width: "8%",
    //   align: "center",
    //   render: (text) => text && <Preview url={text} />,
    // },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Space>
          {/* <IconFile
            onClick={(e) => {
              set_s_showFileModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          /> */}
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              // console.log(rowData);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}_${pf.pfNM}`, value: pf.pfID })) || [],
    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}_${ws.wsNM}`, value: ws.wsID })) || [],
    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}_${wl.wlNM}`, value: wl.wlID })) || [],
    pwc:
      call_getPWC?.data.map((pwc) => ({ label: `${pwc.pwcID}_${pwc.pwcNM}`, value: pwc.pwcID })) ||
      [],
    ctrlrequire: [
      { value: "A", label: "每次取樣創建檢驗單" },
      { value: "B", label: "固定週期自創檢驗單" },
      { value: "C", label: "物控覆核後自創檢單" },
      { value: "D", label: "自動班別創建檢檢單" },
    ],
  };

  const searchBasic = [
    <Input
      key="s_searchData.sopID"
      value={s_searchData.ID}
      onChange={(e) => handlChange("ID", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}ID`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <Input
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  useEffect(() => {
    onSearch();
  }, []);

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
    call_getPWC.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getRcphd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          index: i,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getRcphd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteRcphd;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteRcphd.status]);

  return (
    <>
      <PageHeader title="配方標準主檔(RCP)" extra={searchBasic} />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getRcphd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.rcpID === c_lightData.rcpID ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          <span>
            {Object.keys(s_editData).length > 0
              ? `${t("util.util.edit")}配方標準`
              : `${t("util.util.add")}配方標準`}
            {Object.keys(s_editData).length > 0 && (
              <>
                <span className="ml-2 text-blue-500 font-semibold">SIP ID: {s_editData.sipID}</span>
                <span className="ml-2 text-blue-500 font-semibold">版次: {s_editData.version}</span>
              </>
            )}
          </span>
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
        />
      </CustomModal>

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <FileModal type="sipHd" s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
};

export default HD;
