import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  IconBasicData,
  IconDigitalKanban,
  IconException,
  IconOMS,
  IconSystemInformation,
  IconTechData,
} from "@/components/Icon/Navbar";

// 這個函數接受一個菜單項目的陣列和一個驗證對象，並返回過濾後的菜單項目陣列
export const filterMenuItem = (menuItem) =>
  menuItem
    // 過濾出 auth 屬性為  true 的項目
    .filter((item) => item.auth === true)
    // 將每個項目複製一份並將 auth 屬性設為 true
    .map((item) => {
      const { icon, label, ...other } = item;
      let newItem = { ...item, auth: true, icon: <item.icon {...other} /> };

      // newItem.icon.props = newItem;
      // 如果這個項目有子菜單，過濾出子菜單中 auth 屬性為  true 的項目
      if (Array.isArray(item.children)) {
        newItem.children = item.children
          .filter((child) => child.auth === true)
          // 將每個子項目複製一份並將 auth 屬性設為 true
          .map((child) => {
            let newChild = { ...child, auth: true };

            // 检查并设置disabled属性
            if (child.disabled === true) {
              newChild.label = React.cloneElement(child.label, { disabled: true });
            }

            return newChild;
          });
      }
      return newItem;
    });

// 篩選出第一個 auth 屬性為 true 的項目
export const findFirstTrueItem = (menuItem) => {
  for (const item of menuItem) {
    if (item.auth === true) {
      return item.key;
    }

    if (Array.isArray(item.children)) {
      const firstTrueChild = item.children.find((child) => child.auth === true);
      if (firstTrueChild) {
        return firstTrueChild.key;
      }
    }
  }

  return null;
};

const MainItem = ({ auth, c_userData }) => {
  const { t } = useTranslation();
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  const plantID = getCookie("plantID");
  console.log(plantID);
  let menuItem = [
    {
      label: (
        <NavLink to="/pds/dashboard">
          {/* 數位生產看板 */}
          {t("util.util.nav_pds_dashboard")}
        </NavLink>
      ),
      key: "/pds/dashboard",
      icon: IconDigitalKanban,
      auth: auth["PDS-dashboard"],
      title: "",
    },
    // {
    //   label: (
    //     <NavLink to="/pds/dashboard-card">
    //       {/* 數位生產圖匡 */}
    //       數位生產圖框
    //     </NavLink>
    //   ),
    //   key: "/pds/dashboard-card",
    //   icon: IconDigital,
    //   auth: auth["PDS-dashboard-card"],
    //   title: "",
    // },
    {
      label: (
        <NavLink to="/pds/abc-board">
          {/* 異常管理看板 */}
          {t("util.util.nav_pds_abc-board")}
        </NavLink>
      ),
      key: "/pds/abc-board",
      icon: IconException,
      auth: auth["PDS-abcBoard"],
      title: "",
    },
    {
      label: (
        <NavLink to="/pds/overview-lines">
          {/* 線體稼動總表 */}
          {t("util.util.nav_pds_overview-lines")}
        </NavLink>
      ),
      key: "/pds/overview-lines",
      icon: IconDigitalKanban,
      auth: auth["PDS-overview-lines"],
      title: "",
    },
    {
      label: <NavLink to="/pds/efficiency">綜合效能總表</NavLink>,
      key: "/pds/efficiency",
      icon: IconDigitalKanban,
      auth: auth["PDS-efficiency"],
      title: "",
    },
    {
      label: <NavLink to="/pds/ticker-dashboard">線體生產狀況</NavLink>,
      key: "/pds/ticker-dashboard",
      icon: IconDigitalKanban,
      auth: auth["PDS-ticker-dashboard"], //auth["PDS-ticker-dashboard"],
      title: "",
    },
    {
      // 物料資料管理
      label: <span>1. {t("util.util.nav_BDM")}</span>,
      key: "bdm",
      icon: IconBasicData,
      auth: auth?.BDM,
      children: [
        {
          // 基本資料維護
          label: <NavLink to="/bdm/pn-manage">1-A. {t("util.util.nav_bdm_pn-manage")}</NavLink>,
          key: "/bdm/pn-manage",
          auth: auth?.["BDM-pn-manage"],
        },
        {
          // 技術資料維護
          label: <NavLink to="/bdm/edm">1-B. {t("util.util.nav_bdm_edm")}</NavLink>,
          key: "/bdm/edm",
          auth: auth?.["BDM-edm"],
        },
        {
          label: <NavLink to="/bdm/sheet">1-C. 客製表格維護</NavLink>,
          key: "/bdm/sheet",
          auth: auth?.["BDM-sheet"],
        },
      ],
    },
    {
      // 廠區資料管理
      label: <span>2. {t("util.util.nav_TDM")}</span>,
      key: "tdm",
      icon: IconTechData,
      auth: auth?.TDM,
      children: [
        {
          label: (
            <NavLink to="/tdm/plant-manage">
              {/* 廠別主檔設定 */}
              2-A. {t("util.util.nav_tdm_plant-manage")}
            </NavLink>
          ),
          key: "/tdm/plant-manage",
          auth: auth?.["TDM-plant-manage"],
        },
        {
          label: (
            <NavLink to="/tdm/procedure-manage">
              {/* 製程規劃維護 */}
              2-B. {t("util.util.nav_tdm_procedure-manage")}
            </NavLink>
          ),
          key: "/tdm/procedure-manage",
          auth: auth?.["TDM-procedure-manage"],
        },
        {
          label: (
            <NavLink to="/tdm/production-line-manage">
              {/* 產線規劃維護 */}
              2-C. {t("util.util.nav_tdm_production-line-manage")}
            </NavLink>
          ),
          key: "/tdm/production-line-manage",
          auth: auth?.["TDM-production-line-manage"],
        },
        // {
        //   label: (
        //     <NavLink to="/tdm/warehouse-manage">
        //       {/* 庫房規劃維護 */}
        //       2-D. {t("util.util.nav_tdm_warehouse-manage")}
        //     </NavLink>
        //   ),
        //   key: "/tdm/warehouse-manage",
        //   auth: auth?.["TDM-warehouse-manage"],
        // },
        {
          label: (
            <NavLink to="/tdm/string-manage">
              {/* 工程字串管理 */}
              2-D. {t("util.util.nav_tdm_string-manage")}
            </NavLink>
          ),
          key: "/tdm/string-manage",
          auth: auth?.["TDM-string-manage"],
        },
        // {
        //   label: (
        //     <NavLink to="/tdm/plant-params-manage">
        //       {/* 廠別參數設定 */}
        //       2-F. {t("util.util.nav_tdm_plant-params-manage")}
        //     </NavLink>
        //   ),
        //   key: "/tdm/plant-params-manage",
        //   // auth: auth?.["TDM-plant-params-manage"],
        //   auth: c_userData.peopleNM === "superman",
        // },
      ],
    },
    {
      label: (
        <span>
          {/* 系統資料管理 */}
          3. {t("util.util.nav_SDM")}
        </span>
      ),
      key: "sdm",
      icon: IconSystemInformation,
      auth: auth?.SDM,
      children: [
        {
          label: (
            <NavLink to="/sdm/user-manage">
              {/* 員工資料維護 */}
              3-A. {t("util.util.nav_sdm_user-manage")}
            </NavLink>
          ),
          key: "/sdm/user-manage",
          auth: auth?.["SDM-user-manage"],
          // auth: true,
        },
        {
          label: <NavLink to="/pis/captain-line-manage">3-B. 領班線別管理</NavLink>,
          key: "/pis/captain-line-manage",
          auth: auth?.["SDM-captain-line-manage"],
        },
        {
          label: <NavLink to="/sdm/optype">3-C. 作業科目管理</NavLink>,
          key: "/sdm/optype",
          auth: auth?.["SDM-optype"],
          // auth: true,
        },
        // {
        //   label: (
        //     <NavLink to="/sdm/company">
        //       {/* 廠商資料維護 */}
        //       3-B. {t("util.util.nav_sdm_company")}
        //     </NavLink>
        //   ),
        //   key: "/sdm/company",
        //   auth: auth?.["SDM-company"],
        // },
        // {
        //   label: (
        //     <NavLink to="/sdm/client">
        //       {/* 客戶資料維護 */}
        //       3-C. {t("util.util.nav_sdm_client")}
        //     </NavLink>
        //   ),
        //   key: "/sdm/client",
        //   auth: auth?.["SDM-client"],
        // },
        // {
        //   label: (
        //     <NavLink to="/sdm/category-manage">
        //       {/* 查詢分類設定 */}
        //       3-D. {t("util.util.nav_sdm_category-manage")}
        //     </NavLink>
        //   ),
        //   key: "/sdm/category-manage",
        //   auth: auth?.["SDM-category-manage"],
        // },
        // {
        //   label: (
        //     <NavLink to="/sdm/data-auth-manage">
        //       {/* 功能授權設定 */}
        //       3-E. {t("util.util.nav_sdm_data-auth-manage")}
        //     </NavLink>
        //   ),
        //   key: "/sdm/data-auth-manage",
        //   auth: auth?.["SDM-data-auth-manage"],
        // },
        // {
        //   label: (
        //     <NavLink to="/sdm/trading-pattern">
        //       {/* 項目字串設定 */}
        //       3-F. {t("util.util.nav_sdm_trading-pattern")}
        //     </NavLink>
        //   ),
        //   key: "/sdm/trading-pattern",
        //   auth: auth?.["SDM-trading-pattern"],
        // },
        {
          label: (
            <NavLink to="/sdm/function-params-manage">
              {/* 系統參數設定 */}
              3-D. {t("util.util.nav_sdm_function-params-manage")}
            </NavLink>
          ),
          key: "/sdm/function-params-manage",
          // auth: true,
          auth: auth?.["SDM-function-params-manage"],
        },
      ],
    },
    {
      label: <span>4. 條碼設備管理</span>,
      key: "lms",
      icon: IconOMS,
      auth: auth.LMS,
      children: [
        {
          label: <NavLink to="/lms/label-orderN-printer">4-A1. 出貨標籤列印</NavLink>,
          key: "/lms/label-orderN-printer",
          auth: auth?.["LMS-label-orderN-printer"],
        },
        {
          label: <NavLink to="/lms/label-instock-printer">4-A2. 入庫標籤列印</NavLink>,
          key: "/lms/label-instock-printer",
          auth: auth?.["LMS-label-instock-printer"],
        },
        {
          label: <NavLink to="/lms/label-template">4-B. 條碼模板管理</NavLink>,
          key: "/lms/label-template",
          auth: auth?.["LMS-label-template"],
        },
        {
          label: <NavLink to="/lms/label-paper">4-C. 條碼紙版管理</NavLink>,
          key: "/lms/label-paper",
          auth: auth?.["LMS-label-paper"],
        },
        {
          label: <NavLink to="/lms/label-machines">4-D. 列印設備管理</NavLink>,
          key: "/lms/label-machines",
          auth: auth?.["LMS-label-machines"],
        },
        {
          label: <NavLink to="/lms/label-search">4-E. 輸出標籤查詢</NavLink>,
          key: "/lms/label-search",
          auth: auth?.["LMS-label-search"],
        },
        // {
        //   label: <NavLink to="/lms/label-date">4-D. 條碼日期管理</NavLink>,
        //   key: "/lms/label-date",
        //   auth: auth?.["LMS-label-data"],
        // },
      ],
    },
    {
      label: <span>5. 生產資訊查詢</span>,
      key: "pis",
      icon: IconOMS,
      auth: auth.PIS,
      children: [
        {
          label: <NavLink to="/pis/daily-report">5-A. 每日報表查詢</NavLink>,
          key: "/pis/daily-report",
          auth: auth?.["PIS-daily-report"],
        },
        {
          label: <NavLink to="/pis/wpc-search">5-B. 製程進度查詢</NavLink>,
          key: "/pis/wpc-search",
          auth: auth?.["PIS-wpc-search"],
        },
        {
          label: <NavLink to="/pis/won-search">5-C. 工單進度查詢</NavLink>,
          key: "/pis/won-search",
          auth: auth?.["PIS-won-search"],
        },
        {
          label: <NavLink to="/pis/dal-report">5-D. 暫置量報表</NavLink>,
          key: "/pis/dal-report",
          auth: auth?.["PIS-dal-report"],
        },
        {
          label: <NavLink to="/pis/pdtcode">5-E. 產品條碼查詢</NavLink>,
          key: "/pis/pdtcode",
          auth: auth?.["PIS-pdtcode"],
        },
        {
          label: <NavLink to="/pis/pdtdata">5-F. 產品數據查詢</NavLink>,
          key: "/pis/pdtdata",
          auth: auth?.["PIS-pdtdata"],
        },
      ],
    },
    {
      label: <span>6. 中介資料查詢</span>,
      key: "sap",
      icon: IconOMS,
      auth: auth.SAP,
      children: [
        {
          label: <NavLink to="/sap/head">6-A. SAP工單表頭</NavLink>,
          key: "/sap/head",
          auth: auth?.["SAP-head"],
        },
        {
          label: <NavLink to="/sap/wpc">6-B. SAP工單途程</NavLink>,
          key: "/sap/wpc",
          auth: auth?.["SAP-wpc"],
        },
        {
          label: <NavLink to="/sap/order">6-C. SAP訂單資料</NavLink>,
          key: "/sap/order",
          auth: auth?.["SAP-order"],
        },
        {
          label: <NavLink to="/sap/qcdata">6-D. QC資料查詢</NavLink>,
          key: "/sap/qcdata",
          auth: auth?.["SAP-qcdata"],
        },
      ],
    },
    {
      label: <span>7. 工時績效管理</span>,
      key: "whm",
      icon: IconOMS,
      auth: auth.WHM,
      children: [
        {
          label: <NavLink to="/whm/ct">7-A. CT維護</NavLink>,
          key: "/whm/ct",
          auth: auth?.["WHM-ct"],
        },
        {
          label: <NavLink to="/whm/atte1ndance-hours">7-B. 出勤工時維護</NavLink>,
          key: "/whm/attendance-hours",
          auth: auth?.["WHM-attendance-hours"],
        },
        {
          label: <NavLink to="/whm/performance">7-C. 員工績效報表</NavLink>,
          key: "/whm/performance",
          auth: auth?.["WHM-performance"],
        },
      ],
    },
    {
      label: (
        <span>
          {/* 系統資料管理 */}
          8. 品質管理系統(QMS)
        </span>
      ),
      key: "8",
      icon: IconSystemInformation,
      auth: plantID === "dev",
      children: [
        {
          label: <NavLink to="/testpage/qms/qmfm">8-A. {"品質主檔維護"}</NavLink>,
          key: "/testpage/qms/qmfm",
          // auth: plantID === "dev",
          auth: auth?.["QMS-qmfm"],
          // icon: <IconReport />,
        },
        {
          label: <NavLink to="/testpage/qms/qms-manage">8-B. {"檢驗標準管理"}</NavLink>,
          key: "/testpage/qms/qms-manage",
          auth: auth?.["QMS-qms-manage"],
          // icon: <IconReport />,
        },
        {
          label: <NavLink to="/testpage/meds/spec">8-C. {"檢驗規格模板"}</NavLink>,
          key: "/testpage/meds/spec",
          auth: auth?.["QMS-spec"],
          // icon: <IconReport />,
          // disabled: true,
        },
        // {
        //   // label: <NavLink to="/testpage/qms/inspeq" disabled>8-D. {"儀器設備管理"}</NavLink>,
        //   label: <NavLink disabled>8-D. {"儀器設備管理"}</NavLink>,
        //   // key: "/testpage/qms/inspeq",
        //   auth: auth?.["QMS-cmje"],
        //   disabled: true,
        //   // icon: <IconReport />,
        // },

        // {
        //   label: (
        //     // <NavLink to="/testpage/mms/sop" disabled>
        //     <NavLink disabled>8-E. {"儀器校正管理"}</NavLink>
        //   ),
        //   key: "/testpage/mms/sop",
        //   auth: auth?.["QMS-cmjeq"],
        //   // icon: <IconReport />,
        //   disabled: true,
        // },
        {
          label: <NavLink to="/testpage/qms/spc">8-D. {"統計製程管理"}</NavLink>,
          key: "/testpage/qms/spc",
          auth: auth?.["QMS-spc"],
          // icon: <IconReport />,
        },
      ],
    },
    {
      label: <span>9. 管理推播系統(MNS)</span>,
      key: "mns",
      icon: IconSystemInformation,
      auth: auth.MNS,
      children: [
        {
          label: <NavLink to="/mns/notification-setting">9-A. 推播設定管理</NavLink>,
          key: "/mns/notification-setting",
          auth: auth?.["MNS-notification-setting"],
          // auth: plantID === "dev",
        },
        // {
        //   label: (
        //     <NavLink to="/9-B" disabled>
        //       9-B. 推播事件看板
        //     </NavLink>
        //   ),
        //   key: "/9-B",
        //   auth: plantID === "dev",
        //   disabled: true,
        // },
        // {
        //   label: (
        //     <NavLink to="/9-C" disabled>
        //       9-C. 推播資料查詢_IoT
        //     </NavLink>
        //   ),
        //   key: "/9-C",
        //   auth: plantID === "dev",
        //   disabled: true,
        // },
        // {
        //   label: (
        //     <NavLink to="/9-D" disabled>
        //       9-D. 推播資料查詢_WOC
        //     </NavLink>
        //   ),
        //   key: "/9-D",
        //   auth: plantID === "dev",
        //   disabled: true,
        // },
        // {
        //   label: (
        //     <NavLink to="/9-E" disabled>
        //       9-E. 推播資料查詢_SFC
        //     </NavLink>
        //   ),
        //   key: "/9-E",
        //   auth: plantID === "dev",
        //   disabled: true,
        // },
      ],
    },
  ];

  return filterMenuItem(menuItem);
};

export default MainItem;
