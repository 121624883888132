import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getZSDS003 = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getZSDS003`, {
    params: data,
  });
};


