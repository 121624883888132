import * as XLSX from "xlsx/xlsx.mjs";

// 导出Excel文件的函数
const exportExcel = async (columns, data, fileName) => {
  console.log("columns = ", columns);
  // 获取数据的顺序和名称
  const filterToExcelData = data.map((curr) => {
    let excelObj = {};
    columns.forEach((x) => {
      x.isExcel && (excelObj[x.title] = curr[x.dataIndex]);
    });
    return excelObj;
  });

  console.log("filterToExcelData = ", filterToExcelData);

  // 将数据转换为工作表
  const worksheet = XLSX.utils.json_to_sheet(filterToExcelData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  // 设置工作表的列宽
  worksheet["!cols"] = filterToExcelData.map((item) => {
    console.log("columns[item] = ", item);
    return {
      ...item,
      wch: getcolLength(data, columns[item], item),
    };
  });

  // 将工作簿写入文件
  XLSX.writeFile(workbook, fileName);
};

// 获取每一列的宽度
const getcolLength = (data, NM, dataIndex) => {
  if (!NM) return 10;
  let headerLength = NM.toString().length;
  const cells = [];
  // 遍历每一行，获取每一列的行宽
  for (let i = 0; i < data.length; i++) {
    cells.push(data[i][dataIndex]?.toString().length ?? 0);
  }
  // 获取各行宽的最大值
  const max = Math.max(...cells);

  return headerLength > max ? headerLength + 10 : max + 10;
};

export default exportExcel;
