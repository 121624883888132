import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

export const getInspreport = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInspreport`, {
    params: _params,
  });
};
export const addInspreport = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInspreport`, data);
};

export const updateInspreport = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInspreport`, data);
};

export const deleteInspreport = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInspreport`, data);
};
