import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//--------------新增工單--------------
export const addApsOutWo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addApsOutWo`, data);
};

//--------------取得工單--------------
export const getApsOutWo = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApsOutWo`, {
    params: data,
  });
};

//--------------取得工單(下拉選單)--------------
export const getApsOutWoSelect = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApsOutWoSelect`, {
    params: data,
  });
};

//--------------編輯工單--------------
export const updateApsOutWo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateApsOutWo`, data);
};

//--------------刪除工單--------------
export const deleteApsOutWo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApsOutWo`, data);
};

//--------------新增料單表頭--------------
export const addApsOutMoHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addApsOutMoHd`, data);
};

//--------------取得料單表頭--------------
export const getApsOutMoHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApsOutMoHd`, {
    params: data,
  });
};

//--------------編輯料單表頭--------------
export const updateApsOutMoHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateApsOutMoHd`, data);
};

//--------------刪除料單表頭--------------
export const deleteApsOutMoHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApsOutMoHd`, data);
};

//--------------新增料單表身--------------
export const addApsOutMoTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addApsOutMoTb`, data);
};

//--------------取得料單表身--------------
export const getApsOutMoTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApsOutMoTb`, {
    params: data,
  });
};

//--------------編輯料單表身--------------
export const updateApsOutMoTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateApsOutMoTb`, data);
};

//--------------刪除料單表身--------------
export const deleteApsOutMoTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApsOutMoTb`, data);
};
