import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, InputNumber, message, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { getUnit } from "@/service/apis/ADM/systemParamsManage";
import { addExtra, updateExtra } from "@/service/apis/ADM/pnManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, c_lightData, set_s_isShowModal }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_addExtra = useAPI(addExtra);
  const call_updateExtra = useAPI(updateExtra);
  const call_getUnit = useAPI(getUnit);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      call_addExtra.request(postData);
    }

    if (type === "edit") {
      call_updateExtra.request(postData);
    }
  };

  useEffect(() => {
    call_getUnit.request();
  }, []);

  useEffect(() => {
    if (call_addExtra.status === "suc") {
      message.success(call_addExtra.msg);
      set_s_isShowModal(false);
    } else if (call_addExtra.status === "err") {
      message.error(t(`error.error-code.${call_addExtra.msg}`));
    }
  }, [call_addExtra.status]);

  useEffect(() => {
    if (call_updateExtra.status === "suc") {
      message.success(call_updateExtra.msg);
      set_s_isShowModal(false);
    } else if (call_updateExtra.status === "err") {
      message.error(t(`error.error-code.${call_updateExtra.msg}`));
    }
  }, [call_updateExtra.status]);

  useEffect(() => {
    if (call_getUnit.status === "err") {
      message.error(t(`error.error-code.${call_getUnit.msg}`));
    }
  }, [call_getUnit.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={(_, all) => {
        if (all?.boxH && all?.boxL && all?.boxW) {
          form.setFieldsValue({
            shipV: parseFloat(((all?.boxH * all?.boxL * all.boxW) / 1728).toFixed(5)),
          });
        } else {
          form.setFieldsValue({ shipV: 0 });
        }
      }}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              ...c_lightData,
              note: "",
            }
          : s_editData
      }
    >
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item name="PN" label="料號">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="尺寸單位" name="sizeunit">
            <Select
              className="w-full"
              options={call_getUnit.data.map((x) => {
                return { label: x.unitNM2, value: x.unit };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="重量單位" name="weightunit">
            <Select
              className="w-full"
              options={call_getUnit.data.map((x) => {
                return { label: x.unitNM2, value: x.unit };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="包裝材積CUFT" name="shipV">
            <InputNumber className="w-full" min={0} disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="運裝重量 (kg)" name="shipW">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}></Col>

        <Col span={8}>
          <Form.Item label="產品尺寸 (長)" name="pdtL">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="產品尺寸 (寬)" name="pdtW">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="產品尺寸 (高)" name="pdtH">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="包裝尺寸 (長)" name="boxL">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="包裝尺寸 (寬)" name="boxW">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="包裝尺寸 (高)" name="boxH">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="棧板 (長)" name="palL">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="棧板 (寬)" name="palW">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="棧板 (高)" name="palH">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="是否啟用" name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: "是" },
                { value: false, label: "否" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="註記" name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
