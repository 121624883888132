import { Button, Col, Form, Radio, Row, Space, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { updateMnsEventSet } from "@/service/apis/MNS/notificationSetting";

export default function ModalConintaer({
  s_editData = {},
  set_s_isShowModal,
  c_lightData,
  tableColumns = [],
  plantID = "",
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const call_updateMnsEventSet = useAPI(updateMnsEventSet);

  const options = [
    { value: true, label: t("util.util.yes") },
    { value: false, label: t("util.util.no") },
  ];

  const initialValues = (() => {
    let values = {};

    for (const col of tableColumns) {
      values[col.dataIndex] = false;
    }

    return { ...values, ...s_editData[0] };
  })();

  const onSubmit = (values) => {
    let postData = [];
    for (const item in values) {
      postData.push({
        eventID: c_lightData.eventID,
        plantID: plantID,
        spotID: item,
        isvalid: values[item],
      });
    }
    call_updateMnsEventSet.request(postData);
  };

  useEffect(() => {
    if (call_updateMnsEventSet.status === "suc") {
      message.success(call_updateMnsEventSet.msg);
      set_s_isShowModal(false);
    } else if (call_updateMnsEventSet.status === "err") {
      message.error(call_updateMnsEventSet.msg);
    }
  }, [call_updateMnsEventSet.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={initialValues}>
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                let values = {};
                for (const col of tableColumns) {
                  values[col.dataIndex] = true;
                }
                form.setFieldsValue(values);
              }}
            >
              全部開啟
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                let values = {};
                for (const col of tableColumns) {
                  values[col.dataIndex] = false;
                }
                form.setFieldsValue(values);
              }}
            >
              全部關閉
            </Button>
          </Space>
        </Col>
        {tableColumns.map((x, i) => (
          <Col span={6} key={i}>
            <Form.Item label={x.formTitle} rules={[{ required: true }]} name={x.dataIndex}>
              <Radio.Group options={options} />
            </Form.Item>
          </Col>
        ))}
        <Col span={24} className="flex justify-end mt-4">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_updateMnsEventSet.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
