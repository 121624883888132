import { Input, Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util component
import { IconGarbage, IconPen } from "@/components/Icon/Action";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader/index";
// API
import useAPI from "@/hooks/useAPI";
import { deleteQmsApuiHd, getQmsApuiHd } from "@/service/apis/QMS/Apui";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// component
import ModalConintaer from "./ModalContainer/index";

const HD = (props) => {
  const { c_lightData, set_c_lightData } = props;
  const { t } = useTranslation();
  const call_getQmsApuiHd = useAPI(getQmsApuiHd);
  const call_deleteQmsApuiHd = useAPI(deleteQmsApuiHd);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const ctrlspotOptions = {
    "PN": "料號",
    "GP": "群組",
    "PG": "料號+群組",
    "WC": "工作中心",
  };

  const [s_searchData, set_s_searchData] = useState({
    PN: null,
    page: 1,
    pageSize: 200,
  });

  const onSearch = () => {
    call_getQmsApuiHd.request({ ...s_searchData, page: 1, pageSize: 200 });
  };

  // 刪除資料
  const remove = (rowData) => {
    set_c_lightData({});
    Modal.confirm({
      title: "現品票資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的現品票資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteQmsApuiHd.request(rowData),
      cancelText: "取消",
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    console.log(type, value);
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      call_getQmsApuiHd.request({ ...s_searchData, page: 1, pageSize: 200 });
      // Basic_ref.current.onSearch();
    }
  };

  const tableColumns = [
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.PN.localeCompare(b.PN),
    },
    {
      title: t("util.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      width: "20%",
    },
    // {
    //   title: "介面ID",
    //   dataIndex: "uiID",
    //   width: "20%",
    // },
    // {
    //   title: "版次",
    //   dataIndex: "ver",
    //   width: "7%",
    //   align: "center",
    // },
    // {
    //   title: "介面名稱",
    //   dataIndex: "uiNM",
    //   width: "15%",
    // },
    // {
    //   title: "控點",
    //   dataIndex: "ctrlspot",
    //   align: "center",
    //   width: "7%",
    //   render: (text) => (ctrlspotOptions[text]),
    // },
    // {
    //   title: "控點編號",
    //   dataIndex: "spotID",
    //   width: "8%",
    //   align: "center",
    //   // render: (text) => {
    //   //   const map = {
    //   //     "model": "機種",
    //   //     "querycat": "查詢分類",
    //   //     "attribute": "物料屬性",
    //   //     "mgmtcat": "授權分類",
    //   //   };
    //   //   return map[text];
    //   // },
    // },
    {
      title: t("util.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      width: "20%",
    },
    // {
    //   title: "群組明細", // 群組明細
    //   dataIndex: "gpitem",
    //   width: "10%",
    // },
    // {
    //   title: "量產試作記錄", // 群組明細
    //   dataIndex: "testN",
    //   width: "10%",
    // },
    {
      title: t("util.util.note"), //"註記",
      dataIndex: "note",
      width: "20%",
    },
    // {
    //   title: t("util.util.isvalid"), // 是否有效
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: "5%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    {
      title: "更新日期", // ,
      dataIndex: "updtT",
      width: "10%",
      align: "center",
      render: (text) => (text?.includes("0001-01-01") ? "" : dayjs(text).format("YYYY-MM-DD")),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              remove(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.util.placeholder_input")}${t("util.util.PN")}`}
      onKeyDown={handleKeyDownEnter}
      allowClear
    />,
    // <Input
    //   key="s_searchData.testN"
    //   value={s_searchData.testN}
    //   onChange={(e) => handlChange("testN", e.target.value)}
    //   placeholder={`${t("util.util.placeholder_input")}量試${t("IMS.util.operN_4110")}`}
    //   onKeyDown={handleKeyDownEnter}
    //   allowClear
    // />,

    <SearchButton type="primary" key="search" onClick={onSearch} />,
    // <AddButton type="primary" key="create" onClick={onCreate} />,
  ];
  console.log(c_lightData);
  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getQmsApuiHd.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getQmsApuiHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: i,
          index: i,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getQmsApuiHd.status]);

  useEffect(() => {
    if (call_deleteQmsApuiHd.status === "suc") {
      message.success(call_deleteQmsApuiHd.msg);
      //刪除後要更新資料
      call_getQmsApuiHd.request();
    }
    if (call_deleteQmsApuiHd.status === "err") {
      message.error(call_deleteQmsApuiHd.msg);
    }
  }, [call_deleteQmsApuiHd.status]);

  return (
    <>
      <PageHeader
        // title={t("ADM.client.pageHeader")}
        title="現品票資料設定"
        extra={[
          <Space className="w-full flex justify-end">{searchBasic}</Space>,
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getQmsApuiHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key
              ? set_c_lightData({})
              : set_c_lightData({ ...record, itemno: 1 }),
        })}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}現品票資料設定`
            : `${t("util.util.add")}現品票資料設定
            `
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          set_s_editData({});
          call_getQmsApuiHd.request();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default HD;
