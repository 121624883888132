import { useState, useEffect } from "react";
import { Row, Col, Input, message, Button } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";

import styled from "styled-components";

import CustomTable from "@/pages/TestPage/QMS/SPC/WpcTable/Table/Qclist";
import Light from "@/components/Light";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import Text from "@/components/MC/Text";

import dayjs from "dayjs";

const CustomSearch = styled(Input.Search)`
  .ant-input-search-button {
    background-color: #7c79d2;
    border: #7c79d2;
  }
  .ant-input-group-addon {
    background-color: #7c79d2;
    border: #7c79d2;
  }
`;
export default function QCList({ thisWpc, setThisWpc, className, wpcType }) {
  const { t } = useTranslation();

  const [queryString, setQueryString] = useState("");

  const [tableData, setTableData] = useState([]);

  const [isWpcDatailModal, setIsWpcDatailModal] = useState(false);

  const tableColumns = [
    // {
    //   title: t("WOS.wlwoc.inspN"), // "檢驗單號",
    //   dataIndex: "situF",
    //   width: "90px",
    //   align: "center",
    //   render: (str) => {
    //     switch (str) {
    //       case "B":
    //         return <Light size="20px" type="main" />;
    //       case "G":
    //         return <Light size="20px" type="success" />;
    //       case "Y":
    //         return <Light size="20px" type="warning" />;
    //       case "R":
    //         return <Light size="20px" type="error" />;
    //       default:
    //         return <Light size="20px" disabled />;
    //     }
    //   },
    // },
    {
      title: t("WOS.util.typeInspection"), // "線別",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },

    {
      title: t("util.util.result"), //"結果",
      dataIndex: "batchID",
      width: "60px",
    },
    {
      title: t("util.util.woN"), //"工單號"
      dataIndex: "lifeFstr",
      align: "center",
    },
    {
      title: t("util.util.PN"), //"料號",
      dataIndex: "PN",
      width: "260px",
      align: "center",
      className: "row-min-width col-white-space",
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      width: "280px",
      className: "row-min-width col-white-space",
    },
    {
      title: t("util.util.wl"), //"工線"
      dataIndex: "wl",
      align: "center",
    },

    {
      title: t("WOS.util.pwcID"), //"工作中心",
      dataIndex: "planqty",
      align: "right",
    },
    {
      title: t("util.util.ws"), //"工站"
      dataIndex: "goalqty",
      align: "right",
    },
    {
      title: t("WOS.util.pfID"), //"製程",
      dataIndex: "inC",
      align: "right",
    },
    {
      title: t("util.util.wlID"), // "線別",
      dataIndex: "batchC",
      align: "right",
    },
    {
      title: t("WOS.util.sampleT"), //"抽樣時間",
      dataIndex: "qty",
      align: "right",
      onCell: (rowData) => ({
        style: { background: "#f9e9d7ac", color: rowData.qty === 0 ? "red" : "none" },
      }),
    },
    // {
    //   title: t("WOS.util.sampleID"), //"抽樣人員",
    //   dataIndex: "action",
    //   width: "90px",
    //   // className: "row-last-col-width",
    //   align: "center",
    //   render: (_, recode) => (
    //     <DetailsButton
    //       // 點擊 "途程" 應該阻止冒泡
    //       onClick={(event) => {
    //         thisWpc?.UUID === recode.UUID && event.stopPropagation();
    //         // setIsWpcDatailModal(true);
    //       }}
    //     />
    //   ),
    // },
    // {
    //   title: t("WOS.util.sampleID"), //"抽樣人員",
    //   dataIndex: "action",
    //   width: "90px",
    //   // className: "row-last-col-width",
    //   align: "center",
    //   render: (_, recode) => (
    //     <DetailsButton
    //       // 點擊 "途程" 應該阻止冒泡
    //       onClick={(event) => {
    //         thisWpc?.UUID === recode.UUID && event.stopPropagation();
    //         // setIsWpcDatailModal(true);
    //       }}
    //     />
    //   ),
    // },
  ];

  return (
    <Row className={className}>
      <Col span={20}>
        <Text level="h2" type="primary" className=" text-[#7c79d2]">
          {t("WOS.util.checklist") + t("WOS.util.index")}
          {/* 檢單索引 */}
        </Text>
      </Col>
      <Col span={4} className="flex-center mb-2 ">
        <CustomSearch
          placeholder={
            t("util.util.placeholder_input") +
            t("WOS.util.complete") +
            t("util.util.wo") +
            "/" +
            t("util.util.batchno")
            //請輸入完整個工單/批號
          }
          allowClear
          enterButton
          size="large"
          // onChange={(e) => setQueryString(e.target.value)}
        />
        <Button
          className="bg-[#7c79d2] border-[#7c79d2] ml-2"
          size="large"
          type="primary"
          icon={<FullscreenOutlined />}
          onClick={() => {}}
        />
      </Col>
      {/* <Col span={1} className="flex-end ">
        <Button className="bg-[#7c79d2] border-[#7c79d2]" size="large" type="primary" icon={<FullscreenOutlined />} onClick={() => {}} />
      </Col> */}
      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          dataSource={tableData}
          size="small"
          bordered={true}
          // loading={call_getAllWpc.status === "load"}
          scroll={{ y: 185 }}
          rowClassName={(record, index) =>
            record.UUID === thisWpc?.UUID
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
        />
      </Col>
      {/* detailModal */}
      {/* <CustomModal
        key="isWpcDatailModal"
        title={t("WOS.util.wpcList") + t("util.util.detail")}
        // title="途單索引詳情"
        visible={isWpcDatailModal}
        onCancel={() => setIsWpcDatailModal(false)}
        footer={null}
        width="80%"
      ></CustomModal> */}
    </Row>
  );
}
