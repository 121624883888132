/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button, Divider } from "antd";
import useAPI from "@/hooks/useAPI";
import { addDeviceHdDoc } from "@/service/apis//ADM/measurementEquipment";

const FileModal = ({ c_lightData, set_s_showFileModal }) => {
  // 上傳工程圖檔
  const call_addOrdDoc = useAPI(addDeviceHdDoc);

  const props = {
    // name: "file",
    multiple: true,
    beforeUpload: () => false,
    fileList: [],
    onChange(info) {
      // // 新建
      let formData = new FormData();
      formData.append("assetID", c_lightData.assetID);
      formData.append("assetNM", c_lightData.assetNM);
      formData.append("file", info.file);
      call_addOrdDoc.request(formData);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // console.log("hd_values = ", hd_values);
  useEffect(() => {
    const { status, msg, data } = call_addOrdDoc;
    if (status === "suc") {
      // set_s_showFileModal(false);
      message.success(msg);
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addOrdDoc.status]);
  return (
    <div className="flex flex-col">
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">點擊或拖拽文件到此區域上傳</p>
      </Upload.Dragger>
    </div>
  );
};

export default FileModal;
