/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, InputNumber, Divider, message, Space, Form } from "antd";
import { IconPlus, IconMinus } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addBdmAir, updateBdmAir } from "@/service/apis/ADM/sheet";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addBdmAir = useAPI(addBdmAir);
  const call_updateBdmAir = useAPI(updateBdmAir);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat.join("|");
    }

    if (type === "create") {
      call_addBdmAir.request(values);
    }

    if (type === "edit") {
      call_updateBdmAir.request(values);
    }
  };

  useEffect(() => {
    console.log(s_editData);
  }, []);

  useEffect(() => {
    if (call_addBdmAir.status === "suc") {
      message.success(call_addBdmAir.msg);
      set_s_isShowModal(false);
    } else if (call_addBdmAir.status === "err") {
      message.error(t(`error.error-code.${call_addBdmAir.msg}`));
    }
  }, [call_addBdmAir.status]);

  useEffect(() => {
    if (call_updateBdmAir.status === "suc") {
      message.success(call_updateBdmAir.msg);
      set_s_isShowModal(false);
    } else if (call_updateBdmAir.status === "err") {
      message.error(t(`error.error-code.${call_updateBdmAir.msg}`));
    }
  }, [call_updateBdmAir.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label={t("util.util.PN")} name="PN" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.util.pdtNM")} name="pdtNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="品名簡碼" name="ailas" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="圖面版次" name="version" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="鍛胚" name="forging" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="鏇胚" name="spin" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.util.note")} name="note">
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addBdmAir.status === "load" || call_updateBdmAir.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
