import { Modal, Space, Spin, message } from "antd";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconCheck, IconClose, IconFile, IconGarbage, IconPen } from "@/components/Icon/Action";
import useAPI from "@/hooks/useAPI";
import Preview from "@/pages/TestPage/QMS/QMSManage/File/Preview";
import apiBasic from "@/service/APIBasic";
import { deleteQmsSign, getQmsSign } from "@/service/apis/QMS/meds";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalConintaer from "./ModalContainer";
import UploadModalConintaer from "./ModalContainer/FileModalContainer";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();

  // arrMap = 把各種ID轉成NM所需要的資料
  const { c_lightData, set_s_isShowModal, s_isShowModal, set_c_lightData } = props;

  const [s_tableData, set_s_tableData] = useState([]);
  // 後端回來的資料

  const call_getQmsSign = useAPI(getQmsSign);
  const call_deleteQmsSign = useAPI(deleteQmsSign);

  const [s_isShowUploadModal, set_s_isShowUploadModal] = useState(false);

  const deleteHd = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteQmsSign.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  const tableColumns = [
    {
      title: "符號ID",
      dataIndex: "signID",
      width: 100,
      align: "center",
      render: (text) => {
        return text;
      },
    },
    {
      title: "群組",
      dataIndex: "group",
      width: 100,
      align: "center",
    },

    {
      title: "本文說明",
      dataIndex: "twNM",
      align: "center",
      // width: 150,
    },
    {
      title: "英文說明",
      dataIndex: "enNM",
      align: "center",
      // width: 150,
    },

    {
      title: "印文說明",
      dataIndex: "idNM",
      align: "center",
      // width: 150,
    },
    {
      title: "越文說明",
      dataIndex: "viNM",
      align: "center",
      // width: 150,
    },
    {
      title: "泰文說明",
      dataIndex: "thNM",
      align: "center",
      // width: 150,
    },
    {
      title: "工程圖檔", // "線別",
      dataIndex: "icon",
      align: "center",
      width: 300,
      render: (text) => text && <Preview url={`${apiBasic.conn}://${apiBasic.url}/${text}`} />,
    },

    {
      title: t("util.util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: 90,
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "5%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "action",
      align: "center",
      width: "4.5%",
      render: (_, rowData) => (
        <Space>
          <IconFile
            onClick={(e) => {
              set_c_lightData({ ...rowData });
              set_s_isShowUploadModal(true);
              e.stopPropagation();
            }}
          />
          <IconPen
            onClick={(e) => {
              set_c_lightData({ ...rowData });
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              deleteHd(e, rowData);
            }}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    call_getQmsSign.request();
  }, []);

  useEffect(() => {
    if (call_deleteQmsSign.status === "suc") {
      message.success(call_deleteQmsSign.msg);
      call_getQmsSign.request();
    }
    if (call_deleteQmsSign.status === "err") {
      message.error(call_deleteQmsSign.msg);
    }
  }, [call_deleteQmsSign.status]);
  useEffect(() => {
    if (call_getQmsSign.status === "suc") {
      message.success(call_getQmsSign.msg);
      set_s_tableData(
        call_getQmsSign.data.map((item) => ({
          ...item,
          updtT: dayjs(item.updtT),
          key: item.signID,
        }))
      );
    }
    if (call_getQmsSign.status === "err") {
      message.error(call_getQmsSign.msg);
      set_s_tableData([]);
    }
  }, [call_getQmsSign.status]);

  return (
    <>
      {/* <PageHeader
        title={"檢驗規格管理｜工程圖面管理"}
        extra={[
          <Button
            type={`${s_isHorizontalLayout ? "primary" : "default"}`}
            key="horizon"
            onClick={() => {
              set_s_isHorizontalLayout(true);
            }}
          >
            切換水平佈局
          </Button>,
          <Button
            type={`${s_isHorizontalLayout ? "default" : "primary"}`}
            key="vertical"
            onClick={() => {
              set_s_isHorizontalLayout(false);
            }}
          >
            切換垂直佈局
          </Button>,
          <AddButton
            type="primary"
            key="create"
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      /> */}
      <Spin spinning={call_deleteQmsSign.status === "load" || call_getQmsSign.status === "load"}>
        <CustomTable
          columns={tableColumns}
          dataSource={s_tableData}
          // tbMode={Object.keys(c_lightData).length > 0}
          c_lightData={c_lightData}
          onRow={(record) => {
            return {
              onClick: () =>
                record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
            };
          }}
        />
      </Spin>
      <CustomModal
        title={`上傳檔案`}
        width={"60%"}
        open={s_isShowUploadModal}
        onCancel={() => set_s_isShowUploadModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getQmsSign.request();
        }}
      >
        <UploadModalConintaer
          s_editData={c_lightData}
          set_s_isShowModal={set_s_isShowUploadModal}
        />
      </CustomModal>
      <CustomModal
        title={
          Object.keys(c_lightData).length > 0 ? `${t("util.util.edit")}` : `${t("util.util.add")}`
        }
        style={{ top: "30%" }}
        // centered
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getQmsSign.request();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default HD;
