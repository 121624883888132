import qs from "qs";

const getColorClass = (actData) => {
  const { AllACT } = actData;
  if (AllACT <= 0) return "bg-[#e3e3e3]";
  if (AllACT === 100) return "bg-[#50C9A4]";
  if (AllACT >= 70) return "bg-[#F9B27B]";
  return "bg-[#FC9191]";
};

const WsButton = ({ actData }) => {
  const bgColor = getColorClass(actData);

  const openPage = (wlID) => {
    const params_obj = {
      wlID: wlID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pds/header-line?${params}`, "_blank");
  };
  return (
    <div
      onClick={() => openPage(actData.wlID)}
      className={`${bgColor} px-[16px] py-[6px] text-white cursor-pointer rounded-[100px] shadow-[0px_3px_2px_-1px_rgba(0,0,0,0.3)]`}
    >
      {actData.wlID}
    </div>
  );
};

export default WsButton;
