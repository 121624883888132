import apiBasic from "@/service/APIBasic";
import React, { useState, useMemo } from "react";
import PDF from "./Viewers/PDF";
import Image from "./Viewers/Image";
import Video from "./Viewers/Video";
import Error from "./Viewers/Error";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";

/**
 * 預覽文件的 React 組件。
 *
 * @param {Object} props - 組件屬性。
 * @param {string} props.url - 文件的 URL 路徑。
 * @param {string} props.type - 文件的類型，如 "PDF"、"Image" 或 "Video"。
 * @param {string} [props.modalTitle="Preview"] - 預覽模態框的標題。
 * @param {boolean} [props.useCustomModal] - 是否使用自定義模態框。
 * @param {number} [props.arg.maxHeight] - 圖像預覽的最大高度。
 * @param {string} [props.arg.objectfit] - 圖像在預覽框中的顯示方式。
 * @param {string} [props.arg.videoWidth] - 視訊預覽的寬度。
 * @param {string} [props.arg.videoHeight] - 視訊預覽的高度。
 * @returns {JSX.Element} 預覽文件的 React 組件。
 */
const Preview = ({ url, type, modalTitle = "Preview", useCustomModal, ...arg }) => {
  const [s_showModal, set_s_showModal] = useState(false);
  const fileName = url ? url.substring(url.lastIndexOf("/") + 1) : "";
  const filePath = `${apiBasic.conn}://${apiBasic.url}/${url}`; // 這才是正確的網址，在 yuju_mom V 1.19.36 可用
  
  const genViewer = useMemo(() => {
    const TYPE = {
      "PDF": <PDF filePath={filePath} />,
      "Image": <Image filePath={filePath} maxHeight={arg.maxHeight} objectfit={arg.objectfit} />,
      "Video": (
        <Video filePath={filePath} videoWidth={arg.videoWidth} videoHeight={arg.videoHeight} />
      ),
    };
    return TYPE[type] || <Error />
    // Error;
  }, [type, filePath]);
  
  // const Component = genViewer;
  
  if(url==="")return
  return (
    <>
      <div
        className="underline text-blue-500 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          set_s_showModal(true);
        }}
      >
        {fileName}
      </div>
      <CustomModal
        open={s_showModal}
        title={modalTitle}
        width="80%"
        footer={null}
        onCancel={() => set_s_showModal(false)}
        destroyOnClose
        maskClosable={false}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {useCustomModal || <div className="w-full h-full">{genViewer}</div>}
      </CustomModal>
    </>
  );
};

export default Preview;
