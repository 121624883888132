import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { Alert, Col, Row, Space, Typography } from "antd";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import MyButton from "@/components/Button/PdfButton";
// import TestPDF from "@/assets/pdf/pdfTest.pdf";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const { Title } = Typography;

export default function PDFview({ set_s_pdfModal, url, doctype }) {
  const [s_pdfInfo, set_s_pdfInfo] = useState({
    pageNumber: 1,
    pdfData: {},
    size: 800,
    path: url,
    // path: TestPDF,
  });
  const [s_rotate, set_s_rotate] = useState(0);
  const handleRotate = () => {
    let newRotation = s_rotate + 90;
    if (newRotation >= 360) {
      newRotation = 0;
    }
    set_s_rotate(newRotation);
  };
  const onDocumentLoadSuccess = ({ _pdfInfo }) => {
    set_s_pdfInfo((prevInfo) => ({ ...prevInfo, pdfData: _pdfInfo }));
  };

  const adjustPage = (adjustment) => {
    set_s_pdfInfo((prev) => ({
      ...prev,
      pageNumber: Math.max(prev.pageNumber + adjustment, 1),
    }));
  };

  const adjustSize = (adjustment) => {
    set_s_pdfInfo((prev) => ({
      ...prev,
      size: Math.max(prev.size + adjustment, 1),
    }));
  };

  return (
    <Row gutter={[12, 12]} className="w-full h-full    ">
      <Col span={24} className=" flex justify-center items-center">
        <Space>
          <MyButton
            width={37.5}
            height={37.5}
            color="#3f51b5"
            size="large"
            type="primary"
            onClick={() => adjustPage(-1)}
            disabled={s_pdfInfo.pageNumber === 1}
          >
            <ArrowLeftOutlined className="text-[26.25px]" />
          </MyButton>
          <MyButton
            width={37.5}
            height={37.5}
            color="#3f51b5"
            size="large"
            type="primary"
            onClick={() => adjustPage(1)}
            disabled={s_pdfInfo.pageNumber === s_pdfInfo.pdfData.numPages}
          >
            <ArrowRightOutlined className="text-[26.25px]" />
          </MyButton>
          <MyButton
            width={37.5}
            height={37.5}
            color="#f34545"
            size="large"
            type="primary"
            onClick={() => adjustSize(-100)}
          >
            <MinusCircleOutlined className="text-[26.25px]" />
          </MyButton>
          <Title className="m-0" level={3}>
            {s_pdfInfo.pageNumber}/{s_pdfInfo.pdfData.numPages}
          </Title>
          <MyButton
            width={37.5}
            height={37.5}
            color="#3f51b5"
            size="large"
            type="primary"
            onClick={() => adjustSize(100)}
          >
            <PlusCircleOutlined className="text-[26.25px]" />
          </MyButton>
          <MyButton
            onClick={handleRotate}
            width={37.5}
            height={37.5}
            color="#3f51b5"
            size="large"
            type="primary"
          >
            <RedoOutlined className="text-[26.25px]" />
          </MyButton>
        </Space>
      </Col>
      {s_pdfInfo.path === "" ? (
        <Col span={24}>
          <Alert message="No PDF file." type="error" size="large" />
        </Col>
      ) : (
        <div
          style={{ width: s_pdfInfo.size + 100, height: s_pdfInfo.size }}
          className="h-full overflow-auto mx-auto     "
        >
          {/* <div style={{ width: s_pdfInfo.size }} className="flex  justify-center "> */}
          <Document file={s_pdfInfo.path} onLoadSuccess={onDocumentLoadSuccess} rotate={s_rotate}>
            <Page
              width={s_pdfInfo.size}
              pageNumber={s_pdfInfo.pageNumber}
              className="border-solid border-2 border-gray-500/75 drop-shadow-2xl shadow-lg"
            />
          </Document>
          {/* </div> */}
        </div>
      )}
    </Row>
  );
}
