import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// component
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// hook
import useAPI from "@/hooks/useAPI";
import { getAllWhID, getHdSelect, getTb } from "@/service/apis/WMS/WMS";
import { addrun8090Hd, updaterun8090Hd } from "@/service/apis/WMS/ProInstock";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
const ModalContainer = ({ opcode, s_editData, set_s_showModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_getAllWhID = useAPI(getAllWhID);
  const call_getTb = useAPI(getTb);
  const call_addrun8090Hd = useAPI(addrun8090Hd);
  const call_updaterun8090Hd = useAPI(updaterun8090Hd);
  const call_getHdSelect = useAPI(getHdSelect);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "modify";
    if (type === "create") {
      // console.log(values);
      call_addrun8090Hd.request({
        opcode: values.opcode,
        hd: values,
        tb: values.details,
      });
    }
    if (type === "modify") {
      call_updaterun8090Hd.request({
        opcode: values.opcode,
        hd: values,
      });
    }
  };
  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.util.no_data"));
        return [];
      });
  };
  useEffect(() => {
    call_getAllWhID.request();
    // 這邊配合後端，opcode 從 4243 變成 4142
    // 如果是 4143 就要給 4242
    console.log(`opcode${opcode}`);


    console.warn(opcode);
    call_getHdSelect.request({ opcode: opcode === '4143' ? '4242' : opcode });
    console.log("s_editData = ", s_editData);
    form.setFieldsValue({
      opcode: opcode,
      ...s_editData,
    });
  }, []);

  useEffect(() => {
    if (call_addrun8090Hd.status === "suc") {
      message.success(call_addrun8090Hd.msg);
      set_s_showModal(false);
    } else if (call_addrun8090Hd.status === "err") {
      message.error(call_addrun8090Hd.msg);
    }
  }, [call_addrun8090Hd.status]);

  useEffect(() => {
    if (call_updaterun8090Hd.status === "suc") {
      message.success(call_updaterun8090Hd.msg);
      set_s_showModal(false);
    } else if (call_updaterun8090Hd.status === "err") {
      message.error(call_updaterun8090Hd.msg);
    }
  }, [call_updaterun8090Hd.status]);

  useEffect(() => {
    if (call_getAllWhID.status === "suc") {

    } else if (call_getAllWhID.status === "err") {
      message.error(call_getAllWhID.msg);
    }
  }, [call_getAllWhID.status]);

  useEffect(() => {
    console.log("call_getHdSelect = ", call_getHdSelect);
    if (call_getHdSelect.status === "suc") {

      console.log("call_getHdSelect.data = ", call_getHdSelect.data);
    } else if (call_getHdSelect.status === "err") {
      message.error(call_getHdSelect.msg);
    }
  }, [call_getHdSelect.status]);

  useEffect(() => {
    if (call_getTb.status === "suc") {
      let formData = form.getFieldValue();
      formData.total = 0;
      for (const x of call_getTb.data) {
        formData.total = formData.total + x.planqty;
      }
      formData["details"] = call_getTb.data.map((x) => {
        return { ...x, whID: formData.whID };
      });

      form.setFieldsValue({ ...formData });
    } else if (call_getTb.status === "err") {
      message.error(call_getTb.msg);
    }
  }, [call_getTb.status]);

  const TYPE = Object.keys(s_editData).length === 0 ? "create" : "edit";

  return (
    <Form
      form={form}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Row gutter={[12, 12]}>
        {/* 額外不顯示欄位 */}
        <Form.Item name="opUUID" noStyle hidden>
          <Input />
        </Form.Item>

        <Form.Item name="opcode" noStyle hidden>
          <Input />
        </Form.Item>

        <Col span={8}>
          <Form.Item
            label={t(`IMS.util.opcode_${opcode}`) + t(`util.util.order`)}
            name="operN"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={
                call_getHdSelect.data?.map((x) => ({
                  ...x,
                  label: x.operN,
                  value: x.operN,
                })) || []
              }
              onChange={(_, rowData) => {
                // opcode === '4143' ? '4242' : form.getFieldValue('opcode')
                // 取得表身資料
                call_getTb.request({
                  opUUID: rowData.opUUID,
                  opcode: opcode === '4143' ? '4242' : form.getFieldValue('opcode')
                });
                // 自動帶入倉別
                form.setFieldsValue({ opUUID: rowData.opUUID, whID: rowData?.whID || "" });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 倉別 */}
          <Form.Item label={t(`util.util.whID`)} name="whID">
            <Select

              allowClear
              options={
                call_getAllWhID.data?.map((x) => ({
                  label: x.jobN,
                  value: x.whID,
                })) || []
              }
            // onChange={(whID) => {
            //   if (!whID) return;
            //   let formData = form.getFieldValue();
            //   if (!formData["details"]) {
            //     message.error("請先選擇入庫單");
            //     return;
            //   }
            //   formData["details"] = formData["details"].map((x) => {
            //     return { ...x, whID: whID };
            //   });
            //   form.setFieldsValue(formData);
            // }}
            />
          </Form.Item>
        </Col>

        {opcode === "4241" && (
          <Col span={8}>
            {/* 對倉 */}
            <Form.Item label="對倉" name="targetwhID">
              <Select
                allowClear
                options={
                  call_getAllWhID.data?.map((x) => ({
                    label: x.whNM,
                    value: x.whID,
                  })) || []
                }
              />
            </Form.Item>
          </Col>
        )}

        {/* 總數 */}
        {/* <Col span={8}>
          <Form.Item label={t(`util.util.total`)} name="total" shouldUpdate>
            <InputNumber className="w-full" />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          {TYPE === "create" && (
            <div className="tbWrapper">
              <Divider style={{ margin: "6px" }} />
              <Row gutter={[36, 12]}>
                <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
                  {t(`IMS.util.opcode_${opcode}`) + "明細"}
                </Col>
              </Row>

              <Form.List name="details" shouldUpdate>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} gutter={[12, 12]}>
                        <Col span={2} className="mb12">
                          <Form.Item {...field} label="序" name={[field.name, "itemno"]}>
                            <Input disabled value={index + 1} />
                          </Form.Item>
                        </Col>

                        <Col span={5} className="mb12">
                          <Form.Item
                            {...field}
                            label={t("util.util.PN")} // 料號
                            rules={[
                              {
                                required: true,
                                message: t("util.util.placeholder_select") + t("util.util.PN"),
                              },
                            ]}
                            name={[field.name, "PN"]}
                          >
                            <DebounceSelect
                              key="DebounceSelect"
                              // className="w-[300px]"
                              placeholder={t("util.util.placeholder_input") + " " + t("util.util.PN")}
                              allowClear
                              fetchOptions={fetchPNList}
                              showSearch
                              maxTagCount={10}
                              onChange={(value, option) => {
                                let _details = form.getFieldValue("details");
                                _details[field.name].PN = option.data.PN;
                                _details[field.name].idmtype = option.data.idmtype;
                                form.setFieldsValue({ details: _details });
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={3} className="mb12">
                          <Form.Item
                            {...field}
                            label={t("util.util.idmtype")} // 過帳型態
                            shouldUpdate
                            name={[field.name, "idmtype"]}
                          >
                            <Select disabled>
                              <Select.Option value="S">{t("util.util.idmtype_s")}</Select.Option>
                              <Select.Option value="D">{t("util.util.idmtype_d")}</Select.Option>
                              <Select.Option value="M">{t("util.util.idmtype_m")}</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...field}
                            label={t("util.util.whID")} // 倉別
                            name={[field.name, "whID"]}
                          >
                            <Select
                              placeholder={t("util.util.placeholder_select") + t("util.util.whID")}
                              onChange={(e) => {
                                let formData = form.getFieldValue();
                                formData["whID"] = undefined;
                                form.setFieldsValue(formData);
                              }}
                            >
                              {call_getAllWhID.data.map((x) => (
                                <Select.Option key={x.whID} value={x.whID}>
                                  {x.whNM}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={4} className="mb12">
                          <Form.Item
                            {...field}
                            label={t(`IMS.util.batchno_${opcode}`)}
                            name={[field.name, "batchno"]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>

                        <Col span={4} className="mb12">
                          <Form.Item
                            {...field}
                            label={t("util.util.planqty")} // 計劃數量
                            rules={[
                              {
                                required: true,
                                message: "請輸入大於0的數量!",
                                validator: (_, value) => {
                                  return new Promise(async (resolve, reject) => {
                                    if (!value) {
                                      await reject("請輸入數量");
                                    } else if (value - 0 === NaN) {
                                      await reject("請輸入大於0的數字");
                                    } else {
                                      await resolve();
                                    }
                                  });
                                },
                              },
                            ]}
                            name={[field.name, "planqty"]}
                          >
                            <InputNumber min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>

                        <Col span={2} className="flex-center">
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                              let formData = form.getFieldValue();
                              formData.details = formData.details.map((x, index) => {
                                return { ...x, itemno: index + 1 };
                              });
                              form.setFieldsValue(formData);
                            }}
                            style={{ fontSize: "20px", marginLeft: "5px" }}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          let formData = form.getFieldsValue();
                          // console.clear();
                          console.table(formData);
                          let whID = formData?.whID;
                          add({ itemno: fields.length + 1, whID: whID });
                        }}
                        block
                        icon={<PlusOutlined />}
                        size="large"
                      >
                        {t("util.util.add") + t(`IMS.util.opcode_${opcode}`) + t("util.util.detail")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          )}
          <Divider style={{ margin: "0px 0px 12px 0px" }} />
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_showModal(false)}>取消</Button>
            <Button type="primary" htmlType="submit"
            loading={call_addrun8090Hd.status==="load" || call_updaterun8090Hd.status==="load"}>
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
