import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import Text from "@/components/MC/Text";
const Error = (props) => {
  return (
    <div>
      <FrownOutlined className="text-[50px] text-red-500" twoToneColor="#ee2626" spin />
      <Text level="h2">沒指定 type</Text>
      <Text level="h1"> 範例</Text>
      <div className="flex ">
        <Text level="h1">{`<Preview url={text}`}</Text>
        <Text className="text-red-500" level="h1">{`type`}</Text>
        <Text level="h1">{`={"Image"}/>  `}</Text>
      </div>
    </div>
  );
};

export default Error;
