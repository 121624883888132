import { LabelItemObj, PrinterParams } from "../types/type";

/**
替換字串中的變數的函數
@param {String} text - 要替換的字串
@param {Array} variables - 包含變數的陣列
@returns {String} - 回傳替換後的字串
*/
function replaceVariable(text: string, data: LabelItemObj[], variables: PrinterParams): string {
  // 使用正則表達式找到所有變數
  const reg_g = /\$\{(.+?)}/g;
  let result: RegExpExecArray | null = null;
  let list: string[] = [];
  do {
    result = reg_g.exec(text.toString());
    result && list.push(result[1]);
  } while (result);

  let newText = text;

  // 將每個變數替換為對應的值
  for (const index of list) {
    // 將索引值轉換為數字
    const indexNum = parseInt(index, 10);
    // 從變數陣列中取得對應的值

    let variableValue: string | undefined | number = data[indexNum - 1]?.text;

    if (data[indexNum - 1]?.keyText && !variableValue) {
      // SN 不參與轉換
      const getItem = data[indexNum - 1];
      let orderkeyText: string = "";
      if (getItem.keyText !== "SN") {
        orderkeyText = getItem.keyText + getItem.orderIndex.toString();
      } else {
        orderkeyText = getItem.keyText;
      }

      let value = variables[orderkeyText];
      if (typeof value === "string" || typeof value === "number") {
        variableValue = value.toString();
      }
    }
    //  || variables[data[indexNum - 1]?.keyText];

    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      // 0應該保留
      variableValue = typeof variableValue === "number" ? variableValue : "";
      // variableValue = "${" + index + "}";
    }
    // 將變數替換為對應的值
    newText = newText.replace("${" + index + "}", variableValue);
  }
  return newText;
}

/**
 * 引用其他項目固定字串或關鍵字串的內容的函數
 * @param data 包含替換的數據的物件
 * @param item 要引用的 item 物件
 * @param variable 包含變數的物件
 * @returns 回傳修改後的 item 物件
 */
function replaceItemVars(
  data: LabelItemObj[],
  item: LabelItemObj,
  variables: PrinterParams
): LabelItemObj {
  if (item.type === "keyText") {
    // 如果是 keyText，則從 variables 中取得對應的值並替換掉 keyText 的值
    let variableValue: string | undefined | number = undefined;
    if (typeof item.keyText === "string" || typeof item.keyText === "number") {
      let orderkeyText = item.keyText;
      if (item.keyText !== "SN") {
        orderkeyText = item.keyText + item.orderIndex.toString();
      }
      const value = variables[orderkeyText as keyof typeof variables];
      if (typeof value === "string" || typeof value === "number") {
        variableValue = value.toString();
      }
    }
    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      variableValue = typeof variableValue === "number" ? variableValue : "";
      // variableValue = "${" + item.keyText + "}";
    }
    return { ...item, keyText: variableValue };
  }
  // 替換掉該 item 的 quoteText 中的變數
  const replacedQuoteText = replaceVariable(item.quoteText || "no Data", data, variables);
  // 如果不是 keyText，則將 quoteText 替換為新的值
  return { ...item, quoteText: replacedQuoteText };
}

export { replaceVariable, replaceItemVars };
