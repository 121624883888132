import { useState, useEffect } from "react";
import { message } from "antd";
import { getWg } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/PageHeader/index";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

const TB = (props) => {
  const { c_lightData } = props;
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getWg = useAPI(getWg);

  const tableColumns = [
    {
      title: "工段ID",
      dataIndex: "wgID",
    },
    {
      title: "工段名稱",
      dataIndex: "wgNM",
    },
    {
      title: "工線名稱",
      dataIndex: "wlNM",
    },
    {
      title: "工站名稱",
      dataIndex: "wsNM",
    },
    {
      title: "車間名稱",
      dataIndex: "psNM",
    },
    {
      title: "客戶名稱",
      dataIndex: "custNM",
    },
    {
      title: "客戶簡稱",
      dataIndex: "custalias",
    },
    {
      title: "註記",
      dataIndex: "note",
      width: "55%",
    },
  ];

  useEffect(() => {
    call_getWg.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getWg.status === "suc") {
      message.success(call_getWg.msg);
      set_s_tableData(call_getWg.data.map((x, i) => ({ ...x, key: i })));
    } else if (call_getWg.status === "err") {
      message.error(t(`error.error-code.${call_getWg.msg}`));
      set_s_tableData([]);
    }
  }, [call_getWg.status]);

  return (
    <>
      <PageHeader title="工段資料" />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getWg.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        pagination={false}
      />
    </>
  );
};

export default TB;
