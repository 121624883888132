import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
import { getAllWhID, getWosOut, addTxnHd, getHdSelect, getTb } from "@/service/apis/WMS/WMS";
const ModalContainer = ({ opcode, s_editData, set_s_showModal }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [s_AllWh, set_s_AllWh] = useState([]); // 全部倉別資料 options

  const [s_wosout, set_s_wosout] = useState([]); // wosout selct options

  const call_getAllWhID = useAPI(getAllWhID);
  const call_getWosOut = useAPI(getWosOut);
  const call_getHdSelect = useAPI(getHdSelect);
  const call_getTb = useAPI(getTb);
  const call_addTxnHd = useAPI(addTxnHd);

  const onSubmit = async (values) => {
    let temp = {
      opcode: opcode,
      opUUID: values.opUUID,
      whID: values.whID,
      qty: values.total,
      tb: values.details.map((x, index) => {
        return { ...x, planqty: x.planqty, itemno: index + 1 };
      }),
    };

    console.log(temp);

    if (Object.keys(s_editData).length === 0) {
      call_addTxnHd.request(temp);
    }
  };

  useEffect(() => {
    call_getAllWhID.request();
    // call_getWosOut.request();
    call_getHdSelect.request({ opcode: opcode });
  }, []);

  useEffect(() => {
    if (call_getAllWhID.status === "err") {
      message.error(call_getAllWhID.msg);
    }
  }, [call_getAllWhID.status]);

  useEffect(() => {
    console.log("call_getHdSelect = ", call_getHdSelect);
    if (call_getHdSelect.status === "suc") {
      message.success(call_getHdSelect.msg);
      console.log("call_getHdSelect.data = ", call_getHdSelect.data);
    } else if (call_getHdSelect.status === "err") {
      message.error(call_getHdSelect.msg);
    }
  }, [call_getHdSelect.status]);

  useEffect(() => {
    if (call_getTb.status === "suc") {
      let formData = form.getFieldValue();
      formData.total = 0;
      for (const x of call_getTb.data) {
        formData.total = formData.total + x.planqty;
      }
      formData["details"] = call_getTb.data.map((x) => {
        return { ...x, whID: formData.whID };
      });

      form.setFieldsValue({ ...formData });
    } else if (call_getTb.status === "err") {
      message.error(call_getTb.msg);
    }
  }, [call_getTb.status]);

  useEffect(() => {
    if (call_getWosOut.status === "suc") {
      set_s_wosout(
        call_getWosOut.data.map((x) => (
          <Select.Option key={x.opUUID} value={`${x.woN}-${x.opUUID}`} data={x}>
            {x.woN}
          </Select.Option>
        ))
      );
      message.success(call_getWosOut.msg);
    } else if (call_getWosOut.status === "err") {
      message.error(call_getWosOut.msg);
    }
  }, [call_getWosOut.status]);

  useEffect(() => {
    if (call_addTxnHd.status === "suc") {
      message.success(call_addTxnHd.msg);
      set_s_showModal(false);
    } else if (call_addTxnHd.status === "err") {
      message.error(call_addTxnHd.msg);
    }
  }, [call_addTxnHd.status]);

  return (
    <Form
      form={form}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Row gutter={[12, 12]}>
        {/* 額外不顯示欄位 */}
        <Form.Item name="opUUID" noStyle />

        <Col span={8}>
          <Form.Item label={t(`IMS.util.opcode_${opcode}`)} name="woN" rules={[{ required: true }]}>
            <Select
              // showSearch
              // onChange={(_, data) => {
              //   if (Object.keys(data.data).length === 0) return;
              //   let formData = form.getFieldValue();
              //   formData["opUUID"] = data.data.opUUID;
              //   formData["total"] = data.data.inqty;
              //   formData["details"] = [data.data].map((x) => {
              //     return { ...x, whID: formData.whID };
              //   });
              //   form.setFieldsValue(formData);
              // }}
              onChange={(_, rowData) => {
                console.log("rowData.data = ", rowData);
                call_getTb.request(rowData.data);
                form.setFieldsValue({ opUUID: rowData.data.opUUID });
              }}
              options={
                call_getHdSelect.data?.map((x) => ({
                  label: x.jobN,
                  value: x.jobN,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t(`util.util.whID`)} name="whID">
            <Select
              allowClear
              options={
                call_getAllWhID.data?.map((x) => ({
                  label: x.whNM,
                  value: x.whID,
                })) || []
              }
              onChange={(whID) => {
                if (!whID) return;
                let formData = form.getFieldValue();
                if (!formData["details"]) {
                  // message.error("請先選擇入庫單");
                  return;
                }
                formData["details"] = formData["details"].map((x) => {
                  return { ...x, whID: whID };
                });
                form.setFieldsValue(formData);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={t(`util.util.total`)} name="total" shouldUpdate>
            <InputNumber className="w-full" disabled />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Divider className="p-0 m-0" />
        </Col>

        <Col span={24}>
          <Form.List name="details" shouldUpdate>
            {(fields) => (
              <>
                {fields.map((field) => (
                  <Row key={field.key} gutter={[12, 12]}>
                    <Col span={5} className="mb12">
                      <Form.Item {...field} label={t(`util.util.PN`)} name={[field.name, "PN"]}>
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col span={5} className="mb12">
                      <Form.Item
                        {...field}
                        label={t(`util.util.idmtype`)}
                        shouldUpdate
                        name={[field.name, "idmtype"]}
                      >
                        <Select
                          disabled
                          options={[
                            { label: t("util.util.idmtype_s"), value: "S" },
                            { label: t("util.util.idmtype_d"), value: "D" },
                            { label: t("util.util.idmtype_m"), value: "M" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        {...field}
                        label={t(`util.util.whID`)}
                        name={[field.name, "whID"]}
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={
                            call_getAllWhID.data?.map((x) => ({
                              label: x.whNM,
                              value: x.whID,
                            })) || []
                          }
                          onChange={(e) => {
                            let formData = form.getFieldValue();
                            formData["whID"] = undefined;
                            form.setFieldsValue(formData);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5} className="mb12">
                      <Form.Item
                        {...field}
                        label={t(`util.util.planqty`)}
                        name={[field.name, "planqty"]}
                      >
                        <InputNumber min={0} className="w-full" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        {...field}
                        //入庫批號
                        label={t(`IMS.util.batchno_${opcode}`)}
                        name={[field.name, "batchno"]}
                      >
                        <Input className="w-full" />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button size="large" onClick={() => set_s_showModal(false)}>
              {t("util.util.cancel")}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={call_addTxnHd.status === "load"}
            >
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
