import { Button, Input } from "antd";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";

import RoleAuth from "./RoleAuth";
import StaffData from "./StaffData";
import SystemAuth from "./SystemAuth";

export default function UserManage() {
  const { t } = useTranslation();
  const { c_systemConfig } = useContext(SystemContext);

  // 利用ref 子傳父
  const staffData_ref = useRef(null);
  const roleAuth_ref = useRef(null);
  const systemAuth_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 200,
  });
  const [s_tabKey, set_s_tabKey] = useState("staffData"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      query: undefined,
      page: 1,
      pageSize: 200,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 200 };
    });
  };

  const onSearch = () => {
    s_tabKey === "staffData" && staffData_ref.current.onSearch();
    s_tabKey === "roleAuth" && roleAuth_ref.current.onSearch();
    s_tabKey === "systemAuth" && systemAuth_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "staffData" && staffData_ref.current.onCreate();
    s_tabKey === "roleAuth" && roleAuth_ref.current.onCreate();
    s_tabKey === "systemAuth" && systemAuth_ref.current.onCreate();
  };
  const tabsData = [
    {
      key: "staffData",
      label: t("ADM.user-manage.staffData"), //員工資料管理
      // auth: c_systemConfig.auth["SDM-user-manage-staff"],
      auth: true,
      children: (
        <StaffData
          ref={staffData_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "roleAuth",
      label: t("ADM.user-manage.staffAuth"), //角色權限設定
      // auth: c_systemConfig.auth["SDM-user-manage-auth"],
      auth: true,
      children: (
        <RoleAuth
          ref={roleAuth_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "systemAuth",
      label: t("ADM.user-manage.systemAuth"), //系統登入權限
      // auth: c_systemConfig.auth["SDM-user-manage-auth"],
      auth: true,
      children: (
        <SystemAuth
          ref={systemAuth_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    s_tabKey === "staffData" && (
      <Input
        key="s_searchData.query"
        value={s_searchData.query}
        onPressEnter={onSearch}
        onChange={(e) => handlChange("query", e.target.value)}
        placeholder={`${t("util.util.placeholder_input")}${t("ADM.user-manage.staffID")}`}
      />
    ),
    s_tabKey === "staffData" && <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.user-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData.filter((x) => x.auth)}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
