import React from "react";

import { Table } from "antd";
import styled from "styled-components";
import { fontSize } from "@/styles/BasicSetting";

const CustomTableStyle = styled(Table)`
  /* table 表投靠中 */
  .ant-table-thead > tr > th {
    text-align: center !important;
    background-color: #e2e1ff;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: ${fontSize.h6};
    white-space: nowrap;
    font-weight: 700;
    color: #625ecf;
    font-size: 16px;
    /* border-radius: 8px; */
  }
  .ant-table-cell {
    font-size: ${fontSize.h5};
    font-weight: 600;
    border: none;
  }

  /* // table 顯示頁碼的地方 */
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.h4};
  }

  /* // table pagination center */
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  /* .ant-table-content {
    border-radius: 50px !important;
  }
  .ant-table-wrapper {
    border-radius: 50px !important;
  } */
  .ant-table {
    border-radius: 8px !important;

    /* margin: 15px 0px; */
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
  .ant-table-row td {
    padding: 5px !important;
  }
  .ant-table-thead {
    border-radius: 8px !important;
    color: #625ecf;
  }
  .ant-table-container {
    border-radius: 8px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:first-child {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }

  .monitorTableStyle
    .ant-table-container
    .ant-table-content
    table
    .ant-table-thead
    tr
    th:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    // border-right: 1px solid #c4c4c4;
    border-right: none;
  }

  /* pagination 靠左 */
  /* .ant-pagination-total-text {
    flex: 1;
  } */

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border: none !important;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: none !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    ):before {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .once {
    background-color: ${({ theme }) => theme.tableOnce};
  }
  .twoe {
    background-color: ${({ theme }) => theme.tableTwoe};
  }

  /* .ant-table-row-selected .clickRowStyle {
    background-color: "#8D8BF5" !important;
  } */
  .ant-table-row-selected {
    background-color: #67349a !important; /* 设置选定行的背景色 */

    /* 添加其他样式属性 */
  }
  .ant-table-row-selected:hover {
    background-color: #67349a !important; /* 设置选定行的背景色 */

    /* 添加其他样式属性 */
  }
  .clickRowStyle {
    background-color: #67349a !important;
    color: white; /* 设置选定行的背景色 */

  }
  .ant-table-row ant-table-row-level-0 clickRowStyle {
    background-color: #67349a !important;
    color: white; /* 设置选定行的背景色 */
  }
  /* .ant-table-tbody .ant-table-row-selected {
    background-color: #67349A !important;
  }
  .ant-table-tbody .ant-table-row-hover,
  .ant-table-tbody.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody .ant-table-row-hover.ant-table-row-selected > td {
    background-color: "
      #67349a !important;
  }
  .ant-table-tbody .ant-table-row ant-table-row-level-0 ant-table-row-selected {
    background-color: "#67349A !important;
  }
  .ant-table-tbody .ant-table-row ant-table-row-level-0 ant-table-row-selected .clickRowStyle {
    background-color: "
      #67349a !important;
  } */
`;

const ControlTable = (props) => {
  return <CustomTableStyle bordered={props.Bordered} {...props} />;
};

export default ControlTable;
