/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingModule from "highcharts/modules/exporting";

import { produceColumnChartForValue, produceColumnChartForPercent } from "./ChartBasic/ColumnChart";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

exportingModule(Highcharts);
const InstockOkRate = (props) => {
  const { s_PNChartData, s_chartData,type } = props;

  //X軸標題
  const xAxisDataForAmount = ["投入數量", "良品數", "報廢品"];
  const xAxisDataForPercent = ["目標良率"];

  //  各圖外觀設定
  const chartAppearance = {
    value: { width: null, height: "20%" },
    percent: { width: null, height: "40%" },
  };

  // PN圖 與 機種  純數值圖 的data
  const generateChartData = (chartData) => {
    return chartData?.map((data) => ({
      name: data?.mtypes ? `${data.mtypes}機種` : data.PN,
      data: [parseInt(data.inC, 10), parseInt(data.okC, 10), data.ngC],
      // color: data.PN ? "" : "#88CBFF",
      dataLabels: {
        enabled: true,
        formatter: function () {
          return (Highcharts.numberFormat( (this.y/1000).toFixed(2), 0, ",", ","))+"k"
        },
      },
    }));
  };

  // PN圖 與 機種  百分比圖 的data
  const generatePercentChartData = (chartData) => {
    return chartData?.map((data) => ({
      name: data?.mtypes ? `${data.mtypes}機種` : data.PN,
      data: [data.okRate],
      tooltip: {
        valueSuffix: "%",
      },
      // color: data.PN ? "" : "#88CBFF",
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.y) + "%";
        },
      },
    }));
  };

  //數值圖
  const optionsA = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmount,
    generateChartData(s_chartData),
    type==="parent"?"主分類":"次分類",
    "#FFFFF3"
  );
  const optionsPNvalue = produceColumnChartForValue(
    chartAppearance.value.height,
    xAxisDataForAmount,
    generateChartData(s_PNChartData),
    "各料"
  );

  //百分比圖

  const optionsB = produceColumnChartForPercent(
    chartAppearance.percent.width,
    chartAppearance.percent.height,
    xAxisDataForPercent,
    generatePercentChartData(s_chartData),
    type==="parent"?"主分類":"次分類",
    "#FFFFF3"
  );

  const optionsPNpercent = produceColumnChartForPercent(
    chartAppearance.percent.width,
    chartAppearance.percent.height,
    xAxisDataForPercent,
    generatePercentChartData(s_PNChartData),
    "各料"
  );

  return (
    <>
      <Row className="">
        {s_chartData?.length !== 0 && (
          <>
            <Col span={16} className=" "> 
              <HighchartsReact highcharts={Highcharts} options={optionsA} />
            </Col>
            <Col span={8} >
              <HighchartsReact highcharts={Highcharts} options={optionsB} />
            </Col>
          </>
        )}
        {s_PNChartData.length !== 0 && s_PNChartData[0].PN !== "" && (
          <>
            <Col span={16}>
              <HighchartsReact highcharts={Highcharts} options={optionsPNvalue} />
            </Col>
            <Col span={8}>
              <HighchartsReact highcharts={Highcharts} options={optionsPNpercent} />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default InstockOkRate;
