import zhTW from "antd/lib/locale/zh_TW";
import viVN from "antd/locale/vi_VN";
import enUS from "antd/locale/en_US";
import zhCN from "antd/locale/zh_CN";
import idID from "antd/locale/id_ID";
import thTH from "antd/locale/th_TH";
// import thTH from "antd/locale/id_ID";

import "dayjs/locale/en";
import "dayjs/locale/vi";
import "dayjs/locale/zh-tw";
import "dayjs/locale/zh-cn";
import "dayjs/locale/id";
import "dayjs/locale/th";

const packageLan = {
  "en-US": enUS,
  "zh-TW": zhTW,
  "zh-CN": zhCN,
  "vi-VN": viVN,
  "id-ID": idID,
  "th-TH": thTH,
};

export default packageLan;
