import { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import WpcContext from "@/pages/TestPage/QMS/SPC/Context/Wpc";

const CustomHighchartsReact = styled(HighchartsReact)`
  height: 100% !important;
  width: 100% !important;
  .highcharts-container {
    height: 100% !important;
    width: 100% !important;
  }
`;

export default function LocaltionChart({ boardData, chartSize }) {
  const { s_thisDrawno, s_thisSpcNS } = useContext(WpcContext);
  const default_Y1_offset = -950;
  const default_Y2_offset = default_Y1_offset + 50;
  const defaultChartOptions = {
    chart: {
      // 设置图表顶部边距
      marginTop: 60,
      // 启用 reflow 自动调整大小以适应容器
      reflow: true,
      // 设置图表高度
      height: 550,
      // 设置图表底部间距
      spacingBottom: 5,
      // 设置图表底部边距
      marginBottom: 50,
    },

    // 禁用范围选择器
    rangeSelector: {
      inputEnabled: false,
      enabled: false,
    },

    // 禁用图表标识
    credits: {
      enabled: false,
    },

    // 设置时间配置以使用本地时间而非 UTC
    time: {
      useUTC: false,
    },

    // 主要 yAxis 的配置
    yAxis: [
      {
        // x̄
        // opposite: false,
        // 设置 yAxis 的标题
        title: {
          text: "X BAR(平均值)",
          align: "high",
          textAlign: "center",
          rotation: 0,
          // x: 0,
          y: -10,
          offset: -70,
          style: {
            color: "#0E6BD3",
            fontSize: 18,
          },
        },
        labels: {
          align: "left",
          x: 0,
        },
        // 设置 yAxis 的刻度数量
        tickAmount: 5,
        // 设置 yAxis 的高度
        height: "50%",
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        top: "0.5%",
        lineWidth: 2,
        lineColor: "#fff",
        // 启用 yAxis 的调整大小功能
        resize: {
          enabled: true,
        },
        // 添加用于参考线的绘图线
        plotLines: [
          {
            value: 10,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "USL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
          {
            value: 190,
            color: "rgba(162,29,33,.75)",
            dashStyle: "longdashdot",
            width: 1,
            zIndex: 3,
            label: {
              text: "LSL",
              style: {
                color: "rgba(162,29,33,.75)",
              },
            },
          },
        ],
      },
      {
        // opposite: false,
        title: {
          text: "R (全距)",
          align: "high",
          textAlign: "left",
          y: -10,
          offset: -70,
          rotation: 0,
          // x: 0,
          // y: 0,
          style: {
            color: "#0E6BD3",
            fontSize: 18,
          },
        },
        // 第二個 yAxis 的配置
        labels: {
          align: "center",
          x: -3,
        },

        tickAmount: 5,
        top: "50%",
        height: "40%",
        minPadding: 0,
        maxPadding: 0,
        offset: 0,
        lineWidth: 2,
        lineColor: "#fff",
        resize: {
          enabled: true,
        },
      },
    ],

    // xAxis 的配置
    xAxis: {
      min: null,
      max: null,
    },

    // 图例的配置
    legend: {
      enabled: true,
      align: "right",
      padding: 5,
    },

    // 系列数据的配置
    series: [
      {
        name: "xbar",
        data: [0],
        marker: {
          radius: 0,
        },
        height: 10,
      },
      {
        name: "R",
        data: [0],
        yAxis: 1,
        height: 200,
      },
    ],
  };

  const [chartOptions, setChartOptions] = useState(JSON.parse(JSON.stringify(defaultChartOptions)));

  const chartRef = useRef(null);

  useEffect(() => {
    //讓圖表重新定義大小
    if (chartRef.current && chartRef.current.chart) {
      const interval = setInterval(() => {
        chartRef.current.chart.reflow();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    // if (boardData.length === 0) return;
    // if (!boardData || boardData.length === 0) return;
    if (!boardData) return;
    let xData = [],
      xCL = [],
      xLCL = [],
      xUCL = [],
      USL = [],
      LSL = [],
      // xMax = boardData[0].average,
      // xMin = boardData[0].average,
      rData = [],
      rCL = [],
      rLCL = [],
      rUCL = [];
    // rMax = boardData[0].range,
    // rMin = boardData[0].range;
    for (const item of boardData) {
      // console.log(item)
      // X
      xData.push([new Date(item.measureT).getTime(), item.avg]);
      xCL.push([new Date(item.measureT).getTime(), s_thisDrawno.CL]);
      xLCL.push([new Date(item.measureT).getTime(), s_thisDrawno.LCL]);
      xUCL.push([new Date(item.measureT).getTime(), s_thisDrawno.UCL]);
      USL.push([new Date(item.measureT).getTime(), s_thisDrawno.USL]);
      LSL.push([new Date(item.measureT).getTime(), s_thisDrawno.LSL]);

      // R
      rData.push([new Date(item.measureT).getTime(), item.range]);
      rCL.push([new Date(item.measureT).getTime(), s_thisDrawno.rangeCL]);
      rLCL.push([new Date(item.measureT).getTime(), s_thisDrawno.rangeLCL]);
      rUCL.push([new Date(item.measureT).getTime(), s_thisDrawno.rangeUCL]);
    }

    let series = [
      {
        name: "X_AVG",
        data: xData,
        lineWidth: 4,
        yAxis: 0,

        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },

        zones: [
          {
            value: s_thisDrawno.LCL,
            color: "#f75c5c",
          },
          {
            value: s_thisDrawno.UCL,
            color: "#a8a8a8",
          },
          {
            color: "#f75c5c",
          },
        ],
      },

      {
        name: "X_CL",
        data: xCL,
        color: "#40ad36",
        yAxis: 0,
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
      {
        name: "X_LCL",
        data: xLCL,
        yAxis: 0,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
      {
        name: "X_UCL",
        data: xUCL,
        yAxis: 0,
        color: "#84bdff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
      {
        name: "LSL",
        data: LSL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "USL",
        data: USL,
        yAxis: 0,
        color: "rgba(162,29,33,.75)",
        dashStyle: "longdashdot",
        tooltip: {
          pointFormat: "",
        },
      },
      {
        name: "R_RANGE",
        data: rData,
        lineWidth: 4,
        yAxis: 1,
        marker: {
          enabled: true,
          fillColor: "#FFFFFF",
          radius: 4,
          lineWidth: 2,
          lineColor: null, // inherit from series
          symbol: "circle",
        },
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
      {
        name: "R_CL",
        data: rCL,
        color: "#40ad36",
        yAxis: 1,
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },

      {
        name: "R_LCL",
        data: rLCL,
        yAxis: 1,
        color: "#b700ff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
      {
        name: "R_UCL",
        data: rUCL,
        yAxis: 1,
        color: "#b700ff",
        dashStyle: "longdash",
        tooltip: {
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
        },
      },
    ];

    setChartOptions((prev) => {
      prev.series = series;
      // prev.series = boardData.length === 0 ? [] : series;
      prev.yAxis[0].plotLines[0].value = s_thisDrawno.USL;
      prev.yAxis[0].plotLines[1].value = s_thisDrawno.LSL;
      return { ...prev };
    });
  }, [boardData]);

  useEffect(() => {
    if (!chartSize) {
      return;
    }
    const resiez = {
      "w-full": { offset1: default_Y1_offset, offset2: default_Y2_offset },
      "w-[50%]": { offset1: default_Y1_offset / 2, offset2: default_Y2_offset / 2 },
      "w-[33%]": { offset1: default_Y1_offset / 3, offset2: default_Y2_offset / 3 },
    };
    console.log(resiez[chartSize]);
    //   setChartOptions((prev) => {
    //     return {
    //       ...prev,
    //       yAxis: [
    //         {
    //           ...prev.yAxis[0],
    //           title: { ...prev.yAxis[0].title, offset: resiez[chartSize].offset1 },
    //         },
    //         {
    //           ...prev.yAxis[1],
    //           title: { ...prev.yAxis[1].title, offset: resiez[chartSize].offset2 },
    //         },
    //       ],
    //     };
    //   }
    // );
  }, [chartSize]);
  console.log(chartOptions);
  return (
    <CustomHighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={chartOptions}
      constructorType={"stockChart"}
      style={{ display: "block", height: "100%", width: "100%" }}
    />
  );
}
