import React, { useState, useRef } from "react";
import { Button, DatePicker ,Drawer} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader/index";
import { useTranslation } from "react-i18next";
import { AddButton } from "@/components/PDS/Buttons";
// component
import Run80Run90 from "./Run80Run90";

const DATE_FORMAT = "YYYY-MM-DD";

const OPCODE_LABELS = [
  { key: "4110", label: "IMS.util.opcode_4110" }, // 生產入庫
  { key: "4120", label: "IMS.util.opcode_4120" }, // 採購入庫
  { key: "4130", label: "IMS.util.opcode_4130" }, // 銷售退回
  { key: "4141", label: "IMS.util.opcode_4141" }, // 庫房調入
  { key: "4142", label: "IMS.util.opcode_4142" }, // 庫房借入
  { key: "4143", label: "IMS.util.opcode_4143" }, // 借出歸還
  { key: "4160", label: "IMS.util.opcode_4160" }, // 生產退料
];

const ProInstock = () => {
  const proInstockRef = useRef();
  const { t } = useTranslation();

  // 要搜尋的資料
  const [c_searchData, set_c_searchData] = useState({
    opcode: "4110",
    startDate: dayjs().startOf("month").format(DATE_FORMAT),
    endDate: dayjs().endOf("month").format(DATE_FORMAT),
    lifeF: "",
  });

  // 更改搜尋項目
  const handleChange = (type, value) => {
    set_c_searchData((prev) => {
      if (type === "time") {
        prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
        prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
      } else {
        prev[type] = value;
      }
      return { ...prev };
    });
  };

  // 新增
  const handleClick = () => {
    switch (c_searchData.opcode) {
      case "4110":
        proInstockRef.current.Create();
        break;
      default:
        proInstockRef.current.Create();
        break;
    }
  };

  const opcodeData = OPCODE_LABELS.map((x) => ({
    key: x.key,
    label: t(x.label),
    children: <Run80Run90 ref={proInstockRef} c_searchData={c_searchData} />,
  }));

  const searchItem = [
    { value: "", label: t("IMS.util.lifeF_A") }, // 全部
    { value: "0", label: t("IMS.util.lifeF_0") }, // 已創建
    { value: "T", label: t("IMS.pro-instock.lifeF_T") }, // 已規劃
  ].map((option) => (
    <Button
      key={option.value}
      type={c_searchData.lifeF === option.value ? "primary" : "default"}
      onClick={() => set_c_searchData((prev) => ({ ...prev, lifeF: option.value }))}
    >
      {option.label}
    </Button>
  ));

  return (
    <>
      <PageHeader
        title={t("util.util.nav_wms_pro-instock")}
        extra={[
          ...searchItem,
          <DatePicker.RangePicker
            key="time"
            allowClear={false}
            value={[dayjs(c_searchData.startDate), dayjs(c_searchData.endDate)]}
            format={DATE_FORMAT}
            onChange={(dates) => handleChange("time", dates)}
          />,
          // <Button
          //   key="add"
          //   onClick={handleClick}
          //   type="primary"
          //   className="styleButton"
          //   icon={<PlusOutlined />}
          // >
          //   {t(`util.util.add`)}
          // </Button>,
          <AddButton
            type="primary"
            key="create"
            // 只有庫房調入不可編輯
            disabled={c_searchData.opcode === "4141"}
            onClick={handleClick}
          />,
        ]}
      />

      <CustomTabs
        onChange={(activeKey) => handleChange("opcode", activeKey)}
        activeKey={c_searchData.opcode}
        items={opcodeData}
      />
    </>
  );
};

export default ProInstock;
